<template>
    <div id="personalinsights">
        <InsightsPage :insightView="'user'" />
    </div>
</template>

<script>
import InsightsPage from "@/components/InsightsPage";
export default {
    components: {
        InsightsPage,
    },
};
</script>

<style scoped></style>
