export default {
    data: () => ({
        noticeKeys: {
            status: { val_key: 'status', walltype: 'changed_status' },
            date: { val_key: 'date', walltype: 'changed_start_date' },
            startdate: { val_key: 'startdate', walltype: 'changed_start_date' },
            venueid: { val_key: 'name', walltype: 'changed_venue' },

        },
        allowedViews: ['concertpage'],
        noticeObj: null,
    }),
    methods: {
        async created(){
            this.noticeObj = await Notice.Build();
        },
        async addNoticeAndWallItems(new_data){
            console.log('addNoticeAndWallItems');
            const apiObj = this.apiObject;

            /**
             * Go over the notice keys and 
             * look for them inside the api object
             */
            for(const key in this.noticeKeys){
                if(apiObj[key] != new_data[key]){
                    console.log(`found that ${key} is changed`);
                    console.log('apiObj[key]');
                    console.log(apiObj[key]);
                    console.log('new_data[key]')
                    console.log(new_data[key])
                    const val_key = this.noticeKeys[key].val_key;
                    const walltype = this.noticeKeys[key].walltype;
                    const new_val = apiObj[key][val_key] ? apiObj[key][val_key] : new_data[key];

                    console.log('val_key', val_key);
                    console.log('walltype', walltype);
                    console.log('new_val', new_val);

                    console.log(`TEST: changed ${key} to new data ${new_val}`)
                    //await this.addNotice(`changed ${key} to new_data ${out}`);
                    const wall_item = {
                        walltype: walltype,
                        item: new_val,
                        route: this.$route
                    };
                    console.log('NEW WALL ITEM');
                    console.log(wall_item);
                    // await this.$wallHandler({
                    //     walltype: walltype,
                    //     item: new_val,
                    //     route: this.$route
                    // })
                }
            }
        },

        async addNotice(msg) {
            if(!this.allowedViews.includes(this.view)) return;

            const ID = this.$route.params.id;
            const ROUTE = this.view.replace("page", "s");

            let userids = [];
            let objectName = "";

            const apiObj = this.apiObject;

            userids = [...new Set(apiObj.getValuesInObjects("userid"))];
            objectName = apiObj.title;

            for (let user of userids)
                this.noticeObj.postNotice({
                    ownerid: user,
                    type: msg,
                    creator: this.userid,
                    object: objectName,
                    link: `${ROUTE}/edit/${ID}`,
                });
        },
    }
}