<template>
    <v-col cols="12" md="12" class="py-0 float-left" style="overflow:hidden;">
		<ShareFilter
			v-if="shareFilterDialog"
			:shareFilterDialog="shareFilterDialog"
			:shareTo="shareTo"
			:selectedFilterToBeShared="selectedFilterToBeShared"
			@closeShareFilterDialog="shareFilterDialog = false"

		/>
        <v-row style="background:#00000008;">
            <div class="header">Saved filters</div>
            <v-col cols="12" md="12" class="pa-0 custom-filter-container">
                <Loader v-if="isSavedFiltersPending" :loaderStyle="'linear'" />
                <NoDataPlaceholder
                    v-if="savedfilters.length === 0 && !isSavedFiltersPending"
                    :text="'No filter saved'"
                />
                <v-item-group active-class="primary">
                    <v-container class="filter-container pt-0 pb-0">
                        <v-row>
                            <CustomFilter
                                v-for="(filter, index) in savedfilters"
                                :key="filter.user_filterid"
                                :filter="filter"
                                :class="{
                                    'being-edited':
                                        index == highlightFilterIndex,
                                }"
                                :activeFiltersNames="activeFiltersNames"
                                :index="index"
                                @editFilter="editFilter"
								@showShareFilterDialog="showShareFilterDialog"
                                @applyCustomFilter="applyCustomFilter"
                                @deleteFilter="deleteFilter"
                            />
                        </v-row>
                    </v-container>
                </v-item-group>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import Loader from "@/components/Loader";
import NoDataPlaceholder from "@/components/NoDataPlaceholder";
import CustomFilter from "./CustomFilter";
import ShareFilter from "./ShareFilter";

import { mapGetters } from "vuex";

export default {
    name: "FilterList",
    components: {
        CustomFilter,
        Loader,
		NoDataPlaceholder,
		ShareFilter
    },
    props: {
        isSavedFiltersPending: Boolean,
        savedfilters: Array,
    },
    data: () => ({
		highlightFilterIndex: null,
		shareTo:null,
		shareFilterDialog:false,
		selectedFilterToBeShared: null,
        isRemoveFilterPending: false,
        inspectedFilter: {
            description: null,
            name: null,
        },
    }),
    created() {
		this.$bus.$on("clearEditFilterHighlight", this.clearHighlight)
	},
    methods: {
		clearHighlight(){
			this.highlightFilterIndex = null
		},
        deleteFilter(filterid, index) {
            this.$emit("deleteFilter", filterid, index);
            this.$bus.$emit("setLoading");
		},
		showShareFilterDialog(objects, filterName, filterString, shareTo){
			this.selectedFilterToBeShared = { objects, filterName, filterString }
			this.shareTo = shareTo
			this.shareFilterDialog = true
		},
        editFilter(objects, index, filterName, filterString) {
			if(this.highlightFilterIndex == index){
				this.highlightFilterIndex = null
				this.$emit("editFilter", objects, index, filterName, filterString);
			} else {
				this.highlightFilterIndex = index
				this.$emit("editFilter", objects, index, filterName, filterString);
			}
        },
        applyCustomFilter(selectedFilter, filtername) {
            this.$emit("applyCustomFilter", selectedFilter, filtername);
        },
    },
    computed: {
        ...mapGetters({
            activeFiltersNames: "filters/activeFiltersNames",
        }),
    },
};
</script>

<style lang="scss" scoped>
.header {
    font-size: 13px;
    font-weight: 500;
    color: #6a7a8c;
    width: 100%;
    padding: 10px 20px;
    position: relative;
    z-index: 10;
	background:#fff;
    border-top: 1px solid #e2e2e2;
    text-transform: uppercase;
    box-shadow: 0px 0px 5px #00000024;
}
.custom-filter-container {
    max-height: 400px;
    overflow: auto;
    position: relative;
    min-height: 70px;
}


.v-item-group{
    overflow: hidden;
}
</style>