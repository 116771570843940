import ApiObject from './ApiObject';
import ApiHandler from './ApiHandler';
import { getCostCodes } from '@/models/venue_budget_template_rows.js';
import store from './../store/modules/metadata';

export default class ApiObjectCollection extends ApiHandler {
    constructor(endpoint) {
        super(endpoint);
        this.collection = [];
		this.dummy = null;
        this.labelList = {};
    }

    addToCollection(data) {
        if (Array.isArray(data)) {
            console.log(data)
            for (let item of data) {
                var obj = Object.assign(Object.create(Object.getPrototypeOf(this.dummy)), this.dummy)
                obj.setObjectData(item);
                this.collection.push(obj);
            }
        } else {
            var obj = Object.assign(Object.create(Object.getPrototypeOf(this.dummy)), this.dummy)
            obj.setObjectData(data);
            this.collection.push(obj);
        }
    }
    
    removeFromCollection(data) {
        const index = this.collection.indexOf(data);
        console.log(index);
        this.collection.splice(index, 1);
    }

    removeByPkField(pk_value) {
        const index = this.getIndexOfObject(pk_value);
        if (index != -1)
            this.collection.splice(index, 1);
    }
	
	/**
	 *  Added by Locust 2020-12-01
	 */
	async getLabels() {
        let metaBrowse = store.getters.meta_data(store.state)[this.endpoint].browse;
		for (let metaKey in metaBrowse) {
			this.labelList[metaBrowse[metaKey].COLUMN_NAME] = metaBrowse[metaKey].LABEL;
		}
	}

	getLabel(columnName) {
		return this.labelList[columnName] ? this.labelList[columnName] : 'NO LABEL FOUND';
	}
	getColumnName(label) {
		console.log(label)
		for (let key in this.labelList) {
			if (this.labelList[key] == label)
				return key
		}
	}

    getCollection() {
        return this.collection;
    }

    clear() {
        this.collection = []
    }

    length() {
        return this.collection.length;
    }

    setFieldOnAll(key, value) {
        console.log('setFieldOnAll');
        console.log(key, value);
        for (let data of this.collection){
            data[key] = value;
            console.log(data);
        }
    }

    async postAndUpdateCollection(){
        let postArr = [];
        let putArr = [];
        let ids = [];
        for(let data of this.collection){
            if (!data[data.pk_field] || data[data.pk_field] == '')
                postArr.push(data);
            else
                putArr.push(data)
        }
        console.log('POST ARR')
        console.log(postArr);
        console.log('PUT ARR');
        console.log(putArr);
        if(postArr.length > 0)
            ids = await this.postCollection(postArr);
        if(putArr.length > 0)
            await this.updateCollection(putArr);
        
        for(let i = 0; i < ids.length; i++){
            postArr[i][this.dummy.pk_field] = ids[i];
        }
    }

    async updateCollection(indata = null) {
        let metaData = null;
        if (indata == null)
            indata = this.collection;

        var outdata = []
        for (let data of indata) {
            if (!data[data.pk_field] || data[data.pk_field] == '') 
                throw Error('Missing primary key (id)')
            outdata.push(await data.setUpdateData(data, metaData))
            if (metaData == null)
                metaData = data.updateFields;
        }
        console.log('outdata');
        console.log(outdata);
        if (outdata)
            return await this.putData(outdata);
    }

    async postCollection(indata = null) {
        let metaData = null;
        if (!indata)
            indata = this.collection;

        var outdata = []
        for (let data of indata) {
            outdata.push(await data.setCreateData(data, metaData))
            if (metaData == null)
                metaData = data.createFields;
        }

        if (outdata)
            return await this.postData(outdata);
    }

    async fetchAll({ route = '', extraProperties = [] } = {}) {
        let dataArray = await this.getData(route);
        if (!dataArray) return false;

        for (let data of dataArray) {
            //console.log(dataArray.indexOf(data), data)
            var obj = Object.assign(Object.create(Object.getPrototypeOf(this.dummy)), this.dummy)
            await obj.setObjectData(data);


            var oldObj = this.isInCollection(obj, true);
            if (oldObj === undefined) {
                for (const prop of extraProperties)
                    obj[prop.key] = prop.value;
                this.collection.push(obj);
            } else {
                for (const prop of extraProperties)
                    oldObj[prop.key] = prop.value;
            }
        }
        return true;
    }
    async fetchAllOneByOne({ filterRoute = '', route = ''} = {}){
        if(await this.fetchAll({route: filterRoute})){
            for(let obj of this.collection){
                let data = await this.getData(`${route}${route == '' ? '?' : '&'}id=${obj[obj.pk_field]}`);
                await obj.setObjectData(data);
            }
            return true;
        }
        return false;
    }
    isInCollection(obj, giveObject) {
        const object = this.collection.find(x => x[x.pk_field] == obj[x.pk_field]);
        if (giveObject)
            return object
        else
            return object !== undefined
    }

    getIndexOfObject(pk_value) {
        const obj = this.collection.find(x => x[x.pk_field] == pk_value);
        return this.collection.indexOf(obj);
    }

    getCopy() {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    getCsvData(data_type){
        const costs = getCostCodes();
        let dataStr = '';
        let data = {};
        let i = 0;
        for(let obj of this.collection){
            data = obj.getCsvData(data_type, costs);
            dataStr += obj.objArrToCsvString({data: data, delimiter: ',', addHeaders: true })
        }
        
        return dataStr;
    }
}