<template>
    <v-card
        width="250"
        class="elevation-0 float-left"
        tile
        style="height: 100%; border-right: 1px solid #e2e2e2"
    >
        <v-list shaped dense>
            <div class="header">Help</div>
            <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-content @click="goToSection(item.section)">
                        <v-list-item-title class="pl-3"
                            v-text="item.section.replace(/_/g, ' ')"
                        ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        selectedItem: 0,
        items: [
            { section: "Login_and_user_Administration" },
            { section: "General_functionality" },
            { section: "Start_Page" },
            { section: "Browse" },
            { section: "New_Concert"},
            { section: "Concert_Insights"},
            { section: "Budget_History"},
            { section: "Concert_Budget"},
            { section: "Tour_Insights"},
            { section: "Tour_Budget"},
            { section: "Artist_Insights"},
            { section: "Venues"},
            { section: "Organizations"},
            { section: "People"},
            { section: "Invoices" },
            { section: "Insights"},
        ],
    }),
    methods:{
        goToSection(section){
            this.$emit('goToSection', section)
        }
    }
};
</script>

<style lang="scss" scoped>
.header {
    padding: 15px;
}
</style>