<template>
    <div v-if="first_init" style="position:relative;font-size:13px;">
        <div style="display:flex;position:relative;" v-if="field.DATA_TYPE == 'varchar' && isPhoneNumber">
            <national-codes-dropdown v-model="dialCode" :dialCode="dialCode" />
            <v-text-field
                :label="field.LABEL"
                class="fix-phone-input"
                v-if="numberKeys.includes(field.COLUMN_NAME)"
                hide-details=""
                outlined
                v-model="phoneNumber"
                :rules="field.rules"
                :required="field.REQUIRED"
                dense
            ></v-text-field>
        </div>
        <!-- VARCHAR (TEXT FIELD) -->
        <v-text-field
            v-if="
                (field.DATA_TYPE == 'varchar' || field.DATA_TYPE == 'int' || field.DATA_TYPE == 'float') &&
                    !field.autocomplete &&
                    !numberKeys.includes(field.COLUMN_NAME)
            "
            :type="field.DATA_TYPE != 'varchar' ? 'number' : 'text'"
            :readonly="field.READ_ONLY"
            :value="form_item_local"
            v-on:blur="form_item_local = $event.target.value"
            :label="field.LABEL"
            hide-details
            outlined
            dense
            required
            :rules="field.rules"
            :disabled="isDisabled"
        />
        <!-- FK_OBJECT (LIST SELECTION) -->
        <v-autocomplete
            :id="`${field.COLUMN_NAME}_id`"
            v-if="field.autocomplete"
            v-model.lazy="form_item_local"
            :items="field.items"
            :label="field.LABEL"
            :item-text="field.autoTextAndValue.text"
            :item-value="field.autoTextAndValue.value"
            :readonly="field.READ_ONLY"
            hide-details
            outlined
            dense
            filled
            clearable
            required
            :rules="field.rules"
            :disabled="isDisabled"
        >
            <div class="pa-3 pt-2" slot="prepend-item" style="border-bottom:1px solid #e2e2e2">
                <v-btn
                    v-if="field.showCreateButton"
                    block
                    depressed
                    small
                    color="primary"
                    @click.stop="$emit('openCreateNewWindow', field)"
                >
                    <v-icon left small>add</v-icon>
                    create new
                </v-btn>
            </div>
        </v-autocomplete>
        <!-- TEXT (FREE TEXT) -->
        <v-textarea
            v-if="field.DATA_TYPE == 'text'"
            :readonly="field.READ_ONLY"
            :value="form_item_local"
            v-on:blur="form_item_local = $event.target.value"
            :label="field.LABEL"
            style="width: 100%"
            hide-details
            outlined
            dense
            required
            :rules="field.rules"
            :disabled="isDisabled"
        />
        <!-- DATE -->
        <v-menu
            v-if="field.DATA_TYPE == 'date'"
            v-model.lazy="dateMenu[field.COLUMN_NAME]"
            :close-on-content-click="false"
            :nudge-right="40"
            offset-y
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    v-on="on"
                    v-model.lazy="form_item_local"
                    :readonly="field.READ_ONLY"
                    :label="field.LABEL"
                    prepend-inner-icon="event"
                    outlined
                    hide-details
                    dense
                    :rules="field.rules"
                    :disabled="isDisabled"
                ></v-text-field>
            </template>
            <v-date-picker v-model.lazy="form_item_local" :readonly="field.READ_ONLY" no-title @input="dateMenu[field.COLUMN_NAME] = false" />
        </v-menu>
        <!-- TINYINT (TOGGLE SELECTION) -->
        <v-switch
            v-if="field.DATA_TYPE == 'tinyint'"
            style="margin-top: 5px"
            v-model.lazy="form_item_local"
            :label="`${field.LABEL} - ${form_item_local === '1' ? 'Yes' : 'No'}`"
            false-value="0"
            true-value="1"
            :disabled="isDisabled"
        />
        <v-switch
            v-if="field.DATA_TYPE == 'privatetype'"
            style="margin-top: 5px"
            v-model.lazy="form_item_local"
            :label="`${field.LABEL} - ${form_item_local != '' ? 'Yes' : 'No'}`"
            :false-value="''"
            :true-value="userInfo.userid"
            :disabled="isDisabled"
        />
        <!-- TIME -->
        <v-text-field
            v-if="field.DATA_TYPE == 'time'"
            :rules="field.rules"
            :value="form_item_local"
            v-on:blur="form_item_local = $event.target.value"
            :label="`${field.LABEL} (hh:mm)`"
            prepend-inner-icon="access_time"
            outlined
            hide-details
            dense
            maxlength="5"
            :disabled="isDisabled"
        />
        <!-- FK_TABLE MULTISELECT -->
        <v-col cols="12" class="float-left pa-0" v-if="field.DATA_TYPE == 'multiselect'">
            <v-autocomplete
                outlined
                required
                :items="field.items"
                v-model="fk_table_selected_item"
                @change="emitMultiselectAdd()"
                dense
                hide-details
                filled
                label="Select show"
                :item-text="field.DROPDOWN_LABEL"
                :item-value="field.DROPDOWN_VALUE"
                return-object
                class="float-left"
                style="width: 100%"
            ></v-autocomplete>
        </v-col>
        <!-- FK_TABLE MULTISELECT ITEMS SELECTED -->
        <v-col
            cols="12"
            md="12"
            id="linked-concerts-wrapper"
            class="float-left mt-3 pa-0"
            v-if="field.DATA_TYPE == 'multiselect'"
            :key="fk_tables[field.FK_TABLE]"
        >
            <div v-for="item in fk_tables[field.FK_TABLE].collection" :key="item[field.DROPDOWN_VALUE]" class="">
                <div class="linked-concert elevation-1 float-left d-flex align-center">
                    <span class="concert-name"><v-icon>speaker</v-icon> {{ item[field.DROPDOWN_LABEL] }}</span>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="ml-5 elevation-0" x-small fab @click="emitMultiselectRemove(item)">
                        <v-icon>close</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-col>
    </div>
</template>

<script>
import NationalCodesDropdown from "./NationalCodesDropdown";
import { mapGetters } from "vuex";

export default {
    name: "generalformtemplate",
    props: {
        isDisabled: Boolean,
        field: Array,
        fk_tables: Object,
        formItem: Object,
        field_md: Number,
    },
    components: {
        NationalCodesDropdown,
    },
    data: () => ({
        form_item_local: null,
        fk_table_selected_item: null,
        fk_tables_local: {},
        dialCode: "+46",
        isValid: true,
        first_init: false,
        phone2: "",
        dateMenu: {},
        phoneNumber: "",
        isPhoneNumber: false,
        numberKeys: ["tel", "tel_cell", "tel_home", "tel_work", "telephone"],
    }),
    created() {
        this.form_item_local = this.formItem;
        this.fk_tables_local = { ...this.fk_tables };
        /**
         *  Phonenumber check, we use this variable below to split the numbers and remove the
         *  dial code which is already shown in the NationalCodesDropdown component.
         *  We also use this to show the correct field.
         */
        this.checkIfPhoneNumber();
        this.$nextTick(() => {
            this.first_init = true;
        });
    },
    watch: {
        form_item_local: {
            handler: function() {
                if (this.changesMade()) {
                    this.$emit("form_updated", this.form_item_local);
                }
            },
            deep: true,
        },
        phoneNumber(number) {
            this.form_item_local = `${this.dialCode}%${number}`;
        },
        dialCode(code) {
            this.form_item_local = `${code}%${this.phoneNumber}`;
        },
    },
    methods: {
        checkIfPhoneNumber() {
            /**
             * The numbersKeys array keeps track of which keys are suppose to be have
             * a phone numbers as value.
             */
            if (this.numberKeys.includes(this.field.COLUMN_NAME)) {
                /**
                 * This is to show the correct field in the html.
                 */
                this.isPhoneNumber = true;
                /**
                 * Before we parse out the phonenumber with out the dialcode we make sure that
                 * there is a phone number added in the first place.
                 */
                if (this.form_item_local) {
                    /**
                     * When adding a phone number with dialcode the seperator '%' is used to differentiate between the dialcode
                     * and the actual number. We split on the '%' and get the second index.
                     */
                    const [dialCode, phoneNumber] = this.form_item_local.split`%`;
                    /**
                     * Redeclare the local variables.
                     */
                    this.dialCode = dialCode;
                    this.phoneNumber = phoneNumber;
                }
            }
        },
        changesMade() {
            return this.first_init && this.formItem != this.form_item_local && !(this.formItem == null && this.form_item_local == "");
        },
        emitMultiselectAdd() {
            this.$emit("setFkTableAdd", this.field.FK_TABLE, this.fk_table_selected_item);
        },
        emitMultiselectRemove(item) {
            this.$emit("setFkTableRemove", this.field.FK_TABLE, item);
        },
    },
    computed: {
        ...mapGetters({
            userInfo: "user/userInfo",
        }),
    },
};
</script>

<style lang="scss" scoped>
.fix-phone-input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.linked-concert {
    width: 100%;
    background: #eeeeee;
    color: $font-color-pale;
    border-radius: 4px;
    padding: 5px 10px;
    margin-bottom: 15px;
    font-weight: 600;
    .concert-name {
        font-size: 14px;
        i {
            color: $primary;
            margin-right: 10px;
        }
    }
}
</style>
