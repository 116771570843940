<template>
	<div id="artists">
		<v-col cols="12" md="12" class="pa-0 elevation-1">
			<viewHeader :header="header" />
			<BrowseTable :view="this.$options.name" :idpath="this.idpath" />
		</v-col>
	</div>
</template>

<script>
import viewHeader from "../components/ViewHeader";
import BrowseTable from "@/components/Tables/BrowseTable/";
export default {
	name: "artists",
	components: {
		viewHeader,
		BrowseTable
	},
	data: () => ({
		header: "Browse Artists",
		idpath: "artistid"
	})
};
</script>

<style scoped>
#artists {
	width: 100%;
	padding: 10px;
	height: 100%;
}
</style>