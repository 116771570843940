<template>
    <v-container fluid class="pl-0 pt-0 pr-0 pb-0 browse-table">
        <update-alert
            :updateFound="updateFound"
            @getTableData="getTableData"
            @closeUpdateAlert="updateFound = false"
            :isRefreshPending="isRefreshPending"
        />
        <v-data-table
            :headers="tableHeaders"
            :items="items"
            :items-per-page="10"
            class="elevation no-hover"
            :item-key="itemKey"
            :search="search"
            style="position: relative"
            :loading="items.length === 0"
            @click:row="(item) => goTo(item)"
        >
            <template v-slot:top>
                <v-col
                    cols="12"
                    md="12"
                    class="pt-5 pb-0 pl-5 pr-5"
                    style="border-bottom: 1px solid #e0e0e0"
                >
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                outlined
                                v-model="search"
                                label="Search"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="8" class="d-flex align-center">
                            <v-spacer></v-spacer>
                            <v-btn
                                :to="{ path: `${view}/create` }"
                                color="success"
                                depressed
                                small
                            >
                                <v-icon left small>add</v-icon> Create new
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template slot="no-data" style="position: relative">
                <Loader :loaderStyle="'free'"></Loader>
            </template>
            <template v-slot:item.actions="{ item }">
                <router-link
                    style="text-decoration: none"
                    :to="{ path: `${view}/edit/${item[idpath]}` }"
                >
                    <v-btn
                        x-small
                        fab
                        depressed
                        color="primary"
                        class="edit-btn"
                    >
                        <v-icon>chevron_right</v-icon>
                    </v-btn>
                </router-link>
            </template>
            <template v-slot:item.progress="{ item }">
                <v-progress-circular
                    :indeterminate="progressBar.indeterminate"
                    :rotate="progressBar.rotate"
                    :size="progressBar.size"
                    :value="item.progress"
                    :width="progressBar.width"
                    color="primary"
                >
                    {{ item.progress }}%
                </v-progress-circular>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import UpdateAlert from "./BrowseTable/UpdateAlert.vue";

export default {
    name: "simplebrowsetable",
    props: {
        idpath: String,
        view: String,
        itemKey: String,
    },

    components: {
        UpdateAlert,
    },

    data: () => ({
        items: [],
        originalData: [],
        tableHeaders: [],
        search: "",
        updateFound: false,
        isRefreshPending: false,
    }),

    async created() {
        await this.getTableData();
        await this.getTableHeaders();
        // await this.checkForUpdates();
    },

    methods: {
        goTo(item) {
            this.$router.push({
                path: `${this.view}/edit/${item[this.idpath]}`,
            });
        },
        async getTableData() {
            this.isRefreshPending = true;
            let data = await this.getData(this.$getRoute(this.view).main_route);
            if (data.length > 0) {
                this.items = data;
                this.originalData = JSON.parse(JSON.stringify(this.items));

                this.updateFound = false;
                this.isRefreshPending = false;
            }
        },

        async checkForUpdates() {
            let data = await this.getData(this.$getRoute(this.view).main_route);
            if (JSON.stringify(this.originalData) === JSON.stringify(data)) {
                this.updateFound = false;
            } else {
                this.updateFound = true;
            }
            setTimeout(this.checkForUpdates, 10000);
        },

        async getTableHeaders() {
            if (this.items.length > 0) {
                let headersArr = Object.keys(this.items[0]);
                this.tableHeaders = [];
                headersArr.forEach((header) => {
                    if (header != this.idpath) {
                        let headers = {
                            text: "",
                            align: "left",
                            sortable: true,
                            value: header,
                        };
                        headers.text = header
                            .replace(/_/g, " ")
                            .replace(/(^|\s)\S/g, (l) => l.toUpperCase());

                        this.tableHeaders.push(headers);
                    }
                });
            }
        },

        async getData(route) {
            try {
                let response = await this.$GetService(route);
                if (response.status == 200) return response.data;
            } catch (error) {
                console.log(error);
            }
            return [];
        },

        refreshTableData() {
            this.isRefreshPending = true;
            this.getTableData();
        },
    },
};
</script>

<style lang="scss" scoped>
.v-data-table {
    &::v-deep .v-data-table__wrapper {
        .v-data-table__progress th {
            padding: 0px !important;
        }
    }
}

.browse-table{
    &::v-deep tr:hover{
            background:#eee!important;
    }
}


.reset-btn {
    border-radius: 40px;
}
.active-filter-wrapper {
    float: left;
    display: flex;
    align-items: center;
    font-size: 14px;
    .title {
        float: left;
        margin-right: 10px;
        font-size: 14px !important;
        text-transform: uppercase;
    }
    .filter-list {
        float: left;
        color: #222;
        background: #e0e0e0;
        border-radius: 40px;
        padding: 7px 16px 7px 10px;
        margin-right: 10px;
        font-weight: 500;
        display: flex;
        align-items: center;
        i {
            color: $accent-color;
            font-size: 18px;
            margin-right: 10px;
        }
    }
}
.table-btn {
    min-height: 100%;
    min-width: 200px;
}

.v-tooltip__content {
    padding: 5px 2px 5px 10px !important;
    i {
        margin-left: 10px;
        color: #fff;
    }
}
.v-data-table {
    padding-top: 10px !important;
}
.v-text-field.v-text-field--enclosed {
    &::v-deep .v-text-field__details {
        display: none !important;
    }
}

.v-progress-circular {
    &::v-deep .v-progress-circular__info {
        font-size: 12px !important;
        color: #666;
    }
}
.statuschip {
    text-transform: uppercase;
    font-size: 12px;
    padding: 0px 25px;
    font-weight: 600;
    letter-spacing: 1px;
}
.v-btn:not(.v-btn--round).v-size--x-large {
    height: 56px !important;
}

.selection-btn {
    height: 40px !important;
}
.filter-btn {
    height: 40px !important;
}

.edit-btn {
    margin-left: 5px;
}

.theme--light {
    ::v-deep .v-data-table {
        tbody {
            tr {
                &:hover {
                    &::not(.v-data-table__expanded__content) {
                        background: #fff !important;
                    }
                }
            }
        }
    }
}

tr:hover:not(.v-data-table__expanded__content) {
    background: #fff !important;
}

</style>