<template>
    <v-expansion-panels accordion>
        <div class="edit-slider-header d-flex">
            <v-btn
                class="mr-5 close-drawer"
                @click="closeEditDrawer"
                depressed
                icon
            >
                <v-icon color="primary" dark>menu_open</v-icon>
            </v-btn>
            <div class="header">
                <span>Edit</span>
            </div>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :loading="isSliderDataSavePending"
                small
                :disabled="this.noChangesMade"
                @click="setData"
                depressed
                >Save changes</v-btn
            >
        </div>
        <v-expansion-panel>
            <v-expansion-panel-header>General</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Name"
                            v-model="items.name"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.country"
                            :items="country_list"
                            dense
                            hide-details
                            filled
                            label="Country"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Email"
                            v-model="items.email"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Artist website"
                            v-model="items.artist_homepage"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Link to PR material"
                            v-model="items.pr_link"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Project number"
                            v-model="items.project_number"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="BPC Talent ID"
                            v-model="items.bpc_talentid"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>External links</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Website"
                            v-model="items.homepage"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Spotify"
                            v-model="items.spotify"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Facebook"
                            v-model="items.facebook"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Instagram"
                            v-model="items.instagram"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-textarea
                            outlined
                            dense
                            hide-details
                            label="Other"
                            v-model="items.other"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header
                >Important contacts</v-expansion-panel-header
            >
            <v-expansion-panel-content>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            :items="items.dropdowns.people"
                            v-model="items.external_agent"
                            dense
                            hide-details
                            filled
                            label="External agent"
                            item-text="name"
                            item-value="personid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            :items="items.dropdowns.people"
                            v-model="items.ticket_sales_to"
                            dense
                            hide-details
                            filled
                            label="Tickets sales to"
                            item-text="name"
                            item-value="personid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.label"
                            :items="items.dropdowns.organizations"
                            dense
                            hide-details
                            filled
                            label="Label"
                            item-text="name"
                            item-value="organizationid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            :items="items.dropdowns.people"
                            v-model="items.press_contact"
                            dense
                            hide-details
                            filled
                            label="Press contact"
                            item-text="name"
                            item-value="personid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.manager"
                            :items="items.dropdowns.organizations"
                            dense
                            hide-details
                            filled
                            label="Manager"
                            item-text="name"
                            item-value="organizationid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.artist_company"
                            :items="items.dropdowns.organizations"
                            dense
                            hide-details
                            filled
                            label="Artist company"
                            item-text="name"
                            item-value="organizationid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>Agency</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.company"
                            :items="items.dropdowns.organizations"
                            dense
                            hide-details
                            filled
                            label="Company"
                            item-text="name"
                            item-value="organizationid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.our_agent"
                            :items="items.dropdowns.users"
                            dense
                            hide-details
                            filled
                            label="Our agent"
                            item-text="fullname"
                            item-value="userid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.production_manager"
                            :items="items.dropdowns.users"
                            dense
                            hide-details
                            filled
                            label="Production manager"
                            item-text="fullname"
                            item-value="userid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.production_coordinator"
                            :items="items.dropdowns.users"
                            dense
                            hide-details
                            filled
                            label="Production coordinator"
                            item-text="fullname"
                            item-value="userid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.our_economy_representative"
                            :items="items.dropdowns.users"
                            dense
                            hide-details
                            filled
                            label="Our economy representative"
                            item-text="fullname"
                            item-value="userid"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            outlined
                            v-model="items.territory"
                            :items="territory"
                            dense
                            hide-details
                            filled
                            label="Territory"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Commission(Percentage)"
                            v-model="items['commission']"
                            required
                            type="number"
                            suffix="%"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-switch
                            style="margin-top: 5px"
                            v-model="items.environment_consious"
                            :label="`Environment consious - ${
                                items.environment_consious === '1'
                                    ? 'Yes'
                                    : 'No'
                            }`"
                            false-value="0"
                            true-value="1"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" md="12" v-if="items.agent_commission">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Agent commission"
                            v-model="items.agent_commission"
                            required
                            type="number"
                            suffix="%"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" v-if="items.agency_commission">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Agency commission"
                            v-model="items.agency_commission"
                            required
                            type="number"
                            suffix="%"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>Split info</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    
                    <split-info-form
                        :fillWith="items.artist_split_info"
                        :instantSave="true"
                        :id="this.items.artistid"
                    />

                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import SplitInfoForm from "@/components/Tables/EditTableItem/forms/FormExtensions/SplitInfoForm";

export default {
    name: "artistexpandcollapse",
    components: {
        SplitInfoForm
    },
    props: {
        isSliderDataSavePending: Boolean,
        items: Object,
        originalData: Object,
    },
    data: () => ({
        noChangesMade: false,
        territory: [
            "Sverige",
            "Skandinavien",
            "Europa(inkl.Skandinavien)",
            "Europa(exkl.Skandinavien)",
            "Internationellt",
        ],
        splitInfo: {
            organizationid: null,
            personid: null,
            role: "",
            split_info: "",
        },
        splitInfoCollection: {},
        country_list: [
            "Afghanistan",
            "Albania",
            "Algeria",
            "American Samoa",
            "Andorra",
            "Angola",
            "Anguilla",
            "Antarctica",
            "Antigua and Barbuda",
            "Argentina",
            "Armenia",
            "Aruba",
            "Australia",
            "Austria",
            "Azerbaijan",
            "Bahamas (the)",
            "Bahrain",
            "Bangladesh",
            "Barbados",
            "Belarus",
            "Belgium",
            "Belize",
            "Benin",
            "Bermuda",
            "Bhutan",
            "Bolivia (Plurinational State of)",
            "Bonaire, Sint Eustatius and Saba",
            "Bosnia and Herzegovina",
            "Botswana",
            "Bouvet Island",
            "Brazil",
            "British Indian Ocean Territory (the)",
            "Brunei Darussalam",
            "Bulgaria",
            "Burkina Faso",
            "Burundi",
            "Cabo Verde",
            "Cambodia",
            "Cameroon",
            "Canada",
            "Cayman Islands (the)",
            "Central African Republic (the)",
            "Chad",
            "Chile",
            "China",
            "Christmas Island",
            "Cocos (Keeling) Islands (the)",
            "Colombia",
            "Comoros (the)",
            "Congo (the Democratic Republic of the)",
            "Congo (the)",
            "Cook Islands (the)",
            "Costa Rica",
            "Croatia",
            "Cuba",
            "Curaçao",
            "Cyprus",
            "Czechia",
            "Côte d'Ivoire",
            "Denmark",
            "Djibouti",
            "Dominica",
            "Dominican Republic (the)",
            "Ecuador",
            "Egypt",
            "El Salvador",
            "Equatorial Guinea",
            "Eritrea",
            "Estonia",
            "Eswatini",
            "Ethiopia",
            "Falkland Islands (the) [Malvinas]",
            "Faroe Islands (the)",
            "Fiji",
            "Finland",
            "France",
            "French Guiana",
            "French Polynesia",
            "French Southern Territories (the)",
            "Gabon",
            "Gambia (the)",
            "Georgia",
            "Germany",
            "Ghana",
            "Gibraltar",
            "Greece",
            "Greenland",
            "Grenada",
            "Guadeloupe",
            "Guam",
            "Guatemala",
            "Guernsey",
            "Guinea",
            "Guinea-Bissau",
            "Guyana",
            "Haiti",
            "Heard Island and McDonald Islands",
            "Holy See (the)",
            "Honduras",
            "Hong Kong",
            "Hungary",
            "Iceland",
            "India",
            "Indonesia",
            "Iran (Islamic Republic of)",
            "Iraq",
            "Ireland",
            "Isle of Man",
            "Israel",
            "Italy",
            "Jamaica",
            "Japan",
            "Jersey",
            "Jordan",
            "Kazakhstan",
            "Kenya",
            "Kiribati",
            "Korea (the Democratic People's Republic of)",
            "Korea (the Republic of)",
            "Kuwait",
            "Kyrgyzstan",
            "Lao People's Democratic Republic (the)",
            "Latvia",
            "Lebanon",
            "Lesotho",
            "Liberia",
            "Libya",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Macao",
            "Madagascar",
            "Malawi",
            "Malaysia",
            "Maldives",
            "Mali",
            "Malta",
            "Marshall Islands (the)",
            "Martinique",
            "Mauritania",
            "Mauritius",
            "Mayotte",
            "Mexico",
            "Micronesia (Federated States of)",
            "Moldova (the Republic of)",
            "Monaco",
            "Mongolia",
            "Montenegro",
            "Montserrat",
            "Morocco",
            "Mozambique",
            "Myanmar",
            "Namibia",
            "Nauru",
            "Nepal",
            "Netherlands (the)",
            "New Caledonia",
            "New Zealand",
            "Nicaragua",
            "Niger (the)",
            "Nigeria",
            "Niue",
            "Norfolk Island",
            "Northern Mariana Islands (the)",
            "Norway",
            "Oman",
            "Pakistan",
            "Palau",
            "Palestine, State of",
            "Panama",
            "Papua New Guinea",
            "Paraguay",
            "Peru",
            "Philippines (the)",
            "Pitcairn",
            "Poland",
            "Portugal",
            "Puerto Rico",
            "Qatar",
            "Republic of North Macedonia",
            "Romania",
            "Russian Federation (the)",
            "Rwanda",
            "Réunion",
            "Saint Barthélemy",
            "Saint Helena, Ascension and Tristan da Cunha",
            "Saint Kitts and Nevis",
            "Saint Lucia",
            "Saint Martin (French part)",
            "Saint Pierre and Miquelon",
            "Saint Vincent and the Grenadines",
            "Samoa",
            "San Marino",
            "Sao Tome and Principe",
            "Saudi Arabia",
            "Senegal",
            "Serbia",
            "Seychelles",
            "Sierra Leone",
            "Singapore",
            "Sint Maarten (Dutch part)",
            "Slovakia",
            "Slovenia",
            "Solomon Islands",
            "Somalia",
            "South Africa",
            "South Georgia and the South Sandwich Islands",
            "South Sudan",
            "Spain",
            "Sri Lanka",
            "Sudan (the)",
            "Suriname",
            "Svalbard and Jan Mayen",
            "Sweden",
            "Switzerland",
            "Syrian Arab Republic",
            "Taiwan (Province of China)",
            "Tajikistan",
            "Tanzania, United Republic of",
            "Thailand",
            "Timor-Leste",
            "Togo",
            "Tokelau",
            "Tonga",
            "Trinidad and Tobago",
            "Tunisia",
            "Turkey",
            "Turkmenistan",
            "Turks and Caicos Islands (the)",
            "Tuvalu",
            "Uganda",
            "Ukraine",
            "United Arab Emirates (the)",
            "United Kingdom of Great Britain and Northern Ireland (the)",
            "United States Minor Outlying Islands (the)",
            "United States of America (the)",
            "Uruguay",
            "Uzbekistan",
            "Vanuatu",
            "Venezuela (Bolivarian Republic of)",
            "Viet Nam",
            "Virgin Islands (British)",
            "Virgin Islands (U.S.)",
            "Wallis and Futuna",
            "Western Sahara",
            "Yemen",
            "Zambia",
            "Zimbabwe",
            "Åland Islands",
        ],
    }),
    watch: {
        items: {
            handler: function (v) {
                for (let key in this.items) {
                    if (this.items[key] === undefined) {
                        this.items[key] = "";
                    }
                }
                if (
                    JSON.stringify(this.items) ===
                    JSON.stringify(this.originalData)
                ) {
                    this.noChangesMade = true;
                } else {
                    this.noChangesMade = false;
                }
            },
            deep: true,
        },
        // splitInfo(v){
        // 	console.log(this.split)
        // 	const splitInfo = {
        // 		organizationid:this.splitInfo.organizationid.d
        // 		organizationid:this.splitInfo.organizationid

        // 	}
        // }
    },
    async created() {
        // this.splitInfoCollection = await this.$ObjectFactory.BuildCollection({
        //     endpoint: "artist_split_info",
        // });
		// console.log(this.items);
        // for (let split of this.items.artist_split_info) {
		// 	let item = Object.assign(
        //         Object.create(Object.getPrototypeOf(split)),
        //         split
		// 	);
		// 	item.organizationid = item.organizationid?.organizationid;
		// 	item.personid = item.personid?.personid;
			
        //     this.splitInfoCollection.addToCollection(item);
        // }
        // console.log(this.splitInfoCollection);
    },
    methods: {
		// async deleteSplitInfo(item, index){
		// 	this.$emit('removeSplitInfoCollection', index)
		// 	const apiItem = this.splitInfoCollection.collection[index]

		// 	console.log(apiItem)
			
		// 	await apiItem.deleteObject()
		// 	this.splitInfoCollection.removeFromCollection(apiItem)
		// },
        // async addSplitInfo() {
        //     this.items.artist_split_info.push(this.splitInfo);

        //     const splitInfo = {
        //         organizationid: this.splitInfo.organizationid?.organizationid,
        //         personid: this.splitInfo.personid?.personid,
        //         role: this.splitInfo.role,
		// 		split_info: this.splitInfo.split_info,
		// 		artistid: this.items.artistid
        //     };

		// 	await this.splitInfoCollection.addToCollection(splitInfo);
		// 	await this.splitInfoCollection.postAndUpdateCollection();
        // },
        async fixDropDownValues() {
            for (let fk of this.items.fk_objects) {
                let table_fk_key = fk.table_fk_key;
                let fk_key = fk.fk_key;
                if (
                    typeof this.items[table_fk_key] === "object" &&
                    this.items[table_fk_key] !== null
                ) {
                    this.items[table_fk_key] = this.items[table_fk_key][fk_key];
                }
            }
        },
        closeEditDrawer() {
            this.$emit("closeEditDrawer");
        },
        async setData() {
            await this.fixDropDownValues();
            this.$emit("setData", this.items);
        },
    },
};
</script>

<style lang="scss" scoped>
.close-drawer {
    i {
        transform: rotate(180deg);
        margin-right: 3px;
    }
    &:hover {
        background: $border-color !important;
    }
}
.edit-slider-header {
    width: 100%;
    float: left;
    padding: 12px 20px;
    z-index: 1;
    display: flex;
    align-items: center;
    background: $card-title-bg;

    .header {
        font-weight: 600;
        font-size: 20px;
        color: $font-color-pale;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.expansion-panel-container {
    width: 100%;
    float: left;
}

.v-expansion-panel::before {
    box-shadow: none;
}

.v-expansion-panels {
    border-radius: 0px;
}

.v-item-group {
    border-top: 1px solid $border-color !important;
    border-bottom: 1px solid $border-color !important;
}

.v-expansion-panel-header {
    text-transform: uppercase;
    font-weight: 500;
    color: #666;
    height: 60px;
    transition: all 0.2s ease;
    &:hover {
        color: $accent-color;
    }
}

.split-info-card-container {
    background: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    .split-info-card {
        color: #306aa1;
        font-weight: 600;
    }
	.remove-split-info-btn {
		margin-left: auto;
		margin-right: 10px;
		font-size: 1.2em;
		background: rgb(223, 223, 223);
	}
}
</style>