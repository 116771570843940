import FormOptions from './FormOptions';
import { labelRules, concertFormLists } from './Shared/SharedObjects.js';
export default class ConcertOptions extends FormOptions {
    constructor(endpoint, otherTitle = '') {
        super(endpoint, otherTitle);
    }

    static async Build(endpoint, metaParam) {
        let concertOpt = new ConcertOptions(endpoint);
        concertOpt = await FormOptions.BuildOptions(endpoint, metaParam, concertOpt);
        return concertOpt;
    }

    getFormList(param) {
        var generalList = this.getGeneralFormList(param);
        if (generalList)
            return generalList;

        if (concertFormLists[param])
            return concertFormLists[param];


        if (presaleFields.indexOf(param) != -1)
            return concertFormLists.presale;
    }

    showAutoComplete(field) {
        const autoCompletes = [
            'department', 'status', 'presale_spotify', 'presale_amex', 'type_of_event', 'percent_deal', 'payment_condition', 'show_type', 'dinner',
            'load_out', 'taxes_sold', 'presale_sas', 'presale_album'
        ];
        let DATA_TYPE = field.COLUMN_NAME ? field.COLUMN_NAME : field.FK_TABLE;
        return this.showGeneralAutoComplete(DATA_TYPE, autoCompletes);
    }
    isSelect(COLUMN_NAME) {
        const selects = [];
        return this.isGeneralSelect(COLUMN_NAME, selects); // || (custom concert COLUMN_NAMEs)
    }
    getRules(COLUMN_NAME) {
        return this.getGeneralRules(COLUMN_NAME, specialRules);
    }

    getLabelRule(COLUMN_NAME) {
        if (labelRules[COLUMN_NAME]) {
            return labelRules[COLUMN_NAME];
        }

        return this.getGeneralLabelRules().default(COLUMN_NAME);
    }

    isDisabled(COLUMN_NAME, formItems) {
        // if(COLUMN_NAME == 'tourid')
        //     return true;
        // else
            return this.isGeneralDisabled(disabledObj, COLUMN_NAME, formItems);
    }
    getAutoTextAndValue(field) {
        const autoTextVal = { 'department': { text: 'name', value: 'name' } }
        if (autoTextVal[field.COLUMN_NAME])
            return autoTextVal[field.COLUMN_NAME]
        else
            return this.getGeneralAutoTextAndValue(field)
    }
}


var specialRules = [
    // {
    //     fields: ['title', 'description'],
    //     rule: v => (v || '').length <= 5 || 'Max 5 chars',
    // },
    // {
    //     fields: ['tickets_hold'],
    //     rule: v => v > 0 || 'Need to be > 0',
    // }
]

var presaleFields = [
    'amex_allotment', 'collectors_tickets', 'vip_package', 'presale_spotify', 'presale_local_presale', 'presale_amex', 'presale_fan_club',
    'presale_sas', 'presale_album'
];

var disabledObj = [
    {
        key: 'department',
        value: ['Swedish', 'Luger'],
        disabledFields: [
            'support2', 'project_manager', 'settlement_representative', 'production_manager', 'venue_manager', 'artwork', 'pr', 'press_tickets',
            'ticket_responsible', 'presale_fan_club', 'presale_amex', 'presale_local_presale', 'presale_spotify', 'platinum', 'vip_package', 'collectors_tickets', 'amex_allotment'
        ]
    },
    {
        key: 'department',
        value: ['Concert', 'Luger Promoter'],
        disabledFields: [
            'date_end', 'offer_end_date', 'rider_sent', 'arrival_time', 'loading', 'soundcheck_start', 'soundcheck_end',
            'dinner', 'change_over', 'off_stage', 'load_out', 'venue_representative', 'tech_responsible', 'prod_coordinator',
            'accommodation', 'transport', 'production_done', 'miscellaneous_accomodation', 'miscellaneous_transport', 'miscellaneous_prod',
            'publish_on_website', 'luger_release_tickets', 'ln_release_tickets', 'ticket_release_date', 'organizer_ok_pre_sale',
            'tele2_ticket_release_date', 'link_sent', 'taxes_sold', 'percent_deal', 'payment_condition'
        ]
    },
    {
        key: 'presale_fan_club',
        value: ['No'],
        disabledFields: ['startdate_fan_club', 'starttime_fan_club', 'enddate_fan_club', 'endtime_fan_club', 'max_tickets_fan_club', 'startdate_fan_club']
    },
    {
        key: 'presale_amex',
        value: ['No'],
        disabledFields: ['stardate_amex', 'starttime_amex', 'enddate_amex', 'endtime_amex', 'max_tickets_amex']
    },
    {
        key: 'presale_local_presale',
        value: ['No'],
        disabledFields: ['startdate_local_presale', 'starttime_local_presale', 'enddate_local_presale', 'endtime_local_presale', 'max_tickets_local_presale']
    },
    {
        key: 'presale_spotify',
        value: ['No'],
        disabledFields: ['startdate_spotify', 'starttime_spotify', 'enddate_spotify', 'endtime_spotify', 'max_tickets_spotify']
    },
    {
        key: 'platinum',
        value: ['No'],
        disabledFields: ['platinum_amount']
    },
    {
        key: 'vip_package',
        value: ['No'],
        disabledFields: ['other_platinum_vip']
    }
]