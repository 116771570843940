<template>
    <div :id="view">
        <v-col cols="12" md="12" class="elevation-1 pa-0">
            <ViewHeader :header="header" />
            <BrowseTable :idpath="idPath" />
        </v-col>
    </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import BrowseTable from "@/components/Tables/BrowseTable/";
import { mapGetters } from "vuex";
export default {
    name: "concerts",
    components: {
        BrowseTable,
        ViewHeader,
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            view: "globalstates/view",
        }),
        header() {
            return `Browse ${this.$getRoute(this.view).label}`;
        },
        idPath() {
            return `${this.$getRoute(this.view).idPath}`;
        },
    },
};
</script>

<style lang="scss" scoped></style>
