<template>
    <v-row>
        <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
                dense
                required
                filled
                label="Type"
                v-model="name"
                hide-details
            />
        </v-col>


        <v-col cols="12" md="5">
            <v-text-field
                type="number"
                label="Cost"
                filled
                dense
                required
                :suffix="currency"
                v-model="value"
                hide-details
            />
        </v-col>
        <v-col cols="12" md="1" class="d-flex align-center">
            <v-spacer></v-spacer>
            <v-btn small icon color="error" @click.stop="$bus.$emit('deleteBudgetItem', costItem)">
                <v-icon>remove_circle_outline</v-icon>
            </v-btn>
            <comment-button
                class="ml-1"
                :name="costItem.name"
                :comment="!costItem.comment ? '' : costItem.comment"
                :idObj="{ budget_rowid: costItem.budget_rowid }"
                :route="$getSubRoute('budgets','rows')"
                @commentSaved="emitUpdate"
            />
        </v-col>
    </v-row>
</template>

<script>
import CommentButton from "@/components/Dialogs/Comment/CommentButtonPropSafe";

export default {
    name: "tourfixedcostform",

    components: {
        CommentButton,
    },

    props: {
        costItem: Object,
    },

    data: () => ({
        currency: "",
        valueSuffix: "",
        name: "",
        value: 0,
        dataCreated: false,
    }),

    created() {
        this.currency = this.$config.CURRENCY;
        this.name = this.costItem.name;
        this.valueSuffix = this.currency;

        // wait until data is set, then set value to trigger an global update of calculated_cost
        setTimeout(() => {
            this.dataCreated = true;
            this.value = this.costItem.value;
        }, 1);
    },

    computed: {},

    watch: {
        name(val) {
            this.emitUpdate({ name: val });
        },

        value(val) {
            this.emitUpdate({ value: val, calculated_cost: val });
        },
    },

    methods: {
        emitUpdate(item) {
            if (!this.dataCreated) return;

            item.budget_rowid = this.costItem.budget_rowid;
            item.step = this.costItem.step;

            this.$bus.$emit("tourBudgetUpdatedData", item);
        },
    },
};
</script>

<style lang="scss" scoped>
.row-wrapper {
}
</style>