<template>
    <div id="menu">
        <v-navigation-drawer v-model="editDrawer" width="400" app clipped right temporary fixed stateless>
            <ExpandCollapse v-if="editDrawer" :isSliderDataPending="isSliderDataPending" :view="view" @closeEditDrawer="closeEditDrawer" />

            <!-- <edit-table-item :expandVersion="true"></edit-table-item> -->
        </v-navigation-drawer>
        <v-app-bar app clipped-right class="elevation-0 align-center" style="z-index: 5">
            <v-icon color="primary" class="mr-3" :class="minivariant ? 'rotated' : ''" @click.stop="toggleDrawer">menu_open</v-icon>
            <span class="company-name">Live nation</span>
            <v-spacer></v-spacer>
            <Search :showSearchField="showSearchField" @hideSearchField="showSearchField = false" />
            <v-spacer></v-spacer>
            <v-btn class="menu-action-icon" v-if="isMobile" color="primary" icon @click="showSearchField = !showSearchField">
                <v-icon>search</v-icon>
            </v-btn>
            <v-btn class="menu-action-icon" v-if="!isDrawersDisabled" color="primary" @click="getEditSliderData()" icon>
                <v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            <Wall :isDrawersDisabled="isDrawersDisabled" />
            <Notifications />
        </v-app-bar>
        <v-app-bar id="navigation-history" app clipped-right class="elevation-0" style="z-index: 3" v-if="$vuetify.breakpoint.lgAndUp">
            <v-icon class="mr-4" @click="$router.go(-1)">mdi-history</v-icon>
            <div v-for="item in visited" :key="item" class="chipp-wrapper float-left">
                <router-link :to="item === 'Home' ? '/' : item.route">
                    <v-chip class="visited-chip" v-ripple :color="chipColorRules(item)" small depressed :title="item.display">
                        <span>{{ item.display }}</span>
                        <v-icon @click.prevent="removeVisited(item)" :class="{ chipactive: item.route == $route.path }" class="removevisited"
                            >mdi-close-circle</v-icon
                        >
                    </v-chip>
                </router-link>
            </div>
        </v-app-bar>
        <v-navigation-drawer
            v-model="menuDrawer"
            :mini-variant="minivariant"
            mini-variant-width="65"
            app
            static
            fixed
            class="elevation-0 nav-border"
            color="#11283e"
        >
            <div id="menulabel">
                <div class="logo-text d-flex" v-if="!minivariant">
                    <div class="d-flex align-center">
                        <img src="@/assets/logo.png" class="mr-3" />
                        <span>Applaud</span>
                    </div>
                </div>
                <div class="logo" v-if="minivariant">
                    <img src="@/assets/logo.png" />
                </div>
            </div>
            <v-list dense>
                <v-list-item v-for="item in menuitems" :key="item.text">
                    <router-link
                        :to="item.path"
                        v-ripple
                        :class="$route.path.includes(item.text.replace(/ /g, '').toLowerCase()) ? 'router-link-exact-active' : ''"
                    >
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </router-link>
                </v-list-item>
                <v-list-item>
                    <a href="https://s3.eu-north-1.amazonaws.com/bbrains.io/docs/Binary+Brains+-+Live+Nation+User+Guide+1.0.pdf" target="_blank">
                        <v-list-item-action>
                            <v-icon>mdi-help</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Download documents</v-list-item-title>
                        </v-list-item-content>
                    </a>
                </v-list-item>
                <v-list-item @click="logout()">
                    <a>
                        <v-list-item-action>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </a>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
import EditTableItem from "@/components/Tables/EditTableItem/";
import { mapGetters, mapState, mapActions } from "vuex";
import ExpandCollapse from "./ExpandCollapse/";
import Wall from "./Wall/";
import Search from "./Search/";
import Notifications from "./Notifications";

import scrollLockMixin from "@/mixins/lockscrollbar";

export default {
    mixins: [scrollLockMixin],
    components: {
        ExpandCollapse,
        Wall,
        Search,
        Notifications,
        EditTableItem,
    },
    props: {
        view: String,
    },
    data: () => ({
        menuDrawer: true,
        newWallDataFound: false,
        showSearchField: true,
        newDataAmount: 0,
        editDrawer: false,
        wallDrawer: false,
        right: false,
        left: false,
        desktop: true,
        minivariant: true,
        windowWidth: 0,
        menuitems: [
            { icon: "home", text: "Home", path: "/" },
            { icon: "speaker", text: "Shows", path: "/concerts" },
            { icon: "directions_bus", text: "Tours", path: "/tours" },
            { icon: "mdi-account-music", text: "Artists", path: "/artists" },
            { icon: "mdi-stadium", text: "Venues", path: "/venues" },
            { icon: "group", text: "People", path: "/people" },
            { icon: "mdi-cash-multiple", text: "Sales", path: "/sales" },
            {
                icon: "mdi-account-group",
                text: "Organizations",
                path: "/organizations",
            },
            { icon: "mdi-ticket", text: "Ticketing", path: "/ticketing" },
            {
                icon: "compare_arrows",
                text: "Comparable Projects",
                path: "/comparableprojects",
            },
            { icon: "mdi-brain", text: "Insights", path: "/insights" },
            { icon: "mdi-receipt", text: "Invoices", path: "/invoices" },
        ],
    }),
    mounted() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
        });
    },
    created() {
        // this.$bus.$on('newWallDataFound', this.wallDataFoud)

        /**
         * Saves the setting of the minivariant menu.
         * If theres no minivariant variable in the localstorage
         * we default to the mini menu.
         *
         */
        if (localStorage.getItem("miniVariant")) {
            if (JSON.parse(localStorage.getItem("miniVariant"))) {
                this.minivariant = true;
                this.setExpandedHeader();
            } else {
                this.minivariant = false;
                this.unsetExpandedHeader();
            }
        }
        this.route = this.$route.path;
        if (this.$vuetify.breakpoint.name === "md" || this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm") {
            this.menuDrawer = false;
        }
        this.onResize();
        if (this.isMobile) {
            this.showSearchField = false;
        }
    },
    watch: {
        isMobile(v) {
            if (!v) {
                this.showSearchField = true;
            } else {
                this.showSearchField = false;
            }
        },
        "$route.path": {
            handler() {
                this.closeEditDrawer();
            },
        },
    },
    methods: {
        async logout() {
            try {
                await this.$auth.logout();
                await this.isAuthenticated();
            } catch (err) {
                console.log(err);
            }
        },
        // getUserInitials() {
        // 	const [first, last] = this.userName.split` `;
        // 	console.log(first[0], last[0])
        // 	return first[0] + last[0];
        // },
        toggleDrawer() {
            console.log("asd");
            if (this.windowWidth > 1285) {
                this.minivariant = !this.minivariant;
            } else {
                this.minivariant = false;
                this.menuDrawer = true;
            }
            if (!this.isMobile) this.toggleExpandedHeader();
            localStorage.setItem("miniVariant", this.minivariant);
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        async getEditSliderData() {
            this.isSliderDataPending = true;
            this.editDrawer = true;
            await this.$bus.$emit("getEditSliderData");
        },
        closeEditDrawer() {
            this.editDrawer = false;
        },
        ...mapActions({
            removeVisited: "user/removeVisited",
            toggleExpandedHeader: "globalstates/toggleExpandedHeader",
        }),
        chipColorRules(item) {
            if (item.route === this.$route.path) {
                return "primary";
            }
            if (item === "Home" && this.$route.path === "/") {
                return "primary";
            }
            return "$border-color";
        },
        iconColorRules(item) {
            if (this.$route.path === item) {
                return "rgba(255,255,255,0.9)";
            }
        },
        ...mapActions({
            setExpandedHeader: "globalstates/setExpandedHeader",
            unsetExpandedHeader: "globalstates/unsetExpandedHeader",
        }),
    },
    computed: {
        ...mapGetters({
            isDrawersDisabled: "globalstates/isDrawersDisabled",
            visited: "user/visited",
            userInfo: "user/userInfo",
            currentView: "user/currentView",
            isMobile: "globalstates/isMobile",
        }),
        getUserInitials() {
            const [first, last] = this.userInfo.username.split` `;
            return first[0] + last[0];
        },
    },
};
</script>

<style scoped lang="scss">
.user-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid $border-color;
    padding-right: 15px;
    margin-right: 15px;

    .user-icon {
        width: 28px;
        height: 28px;
        border-radius: 100%;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #394c5f;
        color: #fff;
        font-size: 10px;
        text-transform: uppercase;
    }
    .user-name {
        font-size: 14px;
        color: #306aa1;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
    }
}
#navigation-history {
    height: 40px !important;
    background: #fff;
    top: 50px;
    display: flex;
    align-items: center;
    padding: 0px 0px !important;
    a {
        padding: 0px;
        margin-right: 10px;
        font-weight: 500 !important;
    }
    &::v-deep .v-toolbar__content {
        width: 100% !important;
        height: 100% !important;
        float: left !important;
    }
    .chipactive {
        color: rgba(255, 255, 255, 0.8) !important;
        &:hover {
            color: #fff !important;
        }
    }
    .chipp-wrapper {
        height: 45px;
        position: relative;
        display: flex;
        padding: 10px 0px;
        .v-chip {
            &::v-deep .v-chip__content {
                margin-bottom: 1px;
            }
        }
        .visited-chip {
            float: left;
            margin-right: 10px;
            margin: 0;
            padding: 0px 5px 0px 10px;
        }
        .v-chip.v-size--small {
            height: 25px !important;
            float: left;
            cursor: pointer;
            display: flex;
            align-items: center;
            &::v-deep:not(:last-child) span {
                float: left;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 2;
                max-width: 200px;
                margin-right: 10px;
            }
        }
        .removevisited {
            z-index: 2;
            color: rgba(0, 0, 0, 0.7);
            font-size: 18px;
            height: 100%;
            padding: 5px 1px 5px 5px;
            &:hover {
                color: rgba(0, 0, 0);
            }
        }
    }
}

header {
    &::v-deep .v-toolbar__content {
        height: 50px !important;
    }
}
.v-toolbar__content {
    .v-text-field--outlined.v-input--is-focused {
        &::v-deep fieldset {
            border: 1px solid $accent-color !important;
        }
    }
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
    background: #fff;
    height: 50px !important;
}
.search-field {
    height: 35px;
    float: left;
    &:hover {
        &::v-deep fieldset {
            border: 1px solid #ccc !important;
        }
    }
    &::v-deep .v-input__control {
        height: 35px;
        min-height: 35px;
        .v-input__slot {
            min-height: 35px !important;
        }
    }
    &::v-deep .v-input__slot {
        min-height: 35px;
        .v-input__prepend-inner {
            margin: auto !important;
        }
        fieldset {
            border: 1px solid $border-color;
        }
    }

    &::v-deep .v-input__slot {
        min-height: 40px;
        float: left;
        margin: 0;
    }
    &::v-deep .v-input__prepend-inner {
        margin: auto;
    }
}

.rotated {
    transform: rotate(180deg);
    transition: all 0.2s ease;
}

.divider-border {
    border-bottom: 1px solid #ffffff2b;
    float: left;
    width: 100%;
    margin: 25px 0px 0px 0px;
}
.menu-subheader {
    margin: 25px 0px 10px 0px;
    width: 100%;
    padding: 0px 20px;
    color: #fff;
    font-weight: 500;
    float: left;
}

.drawer-header {
    display: flex;
    width: 100%;
    height: 63px;
    span {
        margin: auto;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        color: $accent-color;
    }
}

.v-app-bar {
    border-bottom: 1px solid $border-color !important;
}
.v-toolbar {
    border-radius: 0px;
}
nav a.router-link-exact-active {
    color: #ffffff !important;
    background: #ffffff12;
}
nav a.router-link-exact-active i {
    color: #ffffff !important;
}

.v-list {
    padding: 0 !important;
}

.elevation-1 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.2) !important;
}

.data-found-dot {
    width: 16px;
    height: 16px;
    background: #1886ee;
    border-radius: 16px;
    position: absolute;
    bottom: -10px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #fff;
    font-size: 11px;
}

.company-name {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 20px;
    letter-spacing: 0.0892857143em;
    margin-left: 5px;
    color: $font-color-pale;
}

#menulabel {
    width: 100%;
    height: 50px;
    display: flex;
    border-bottom: 1px solid #ffffff2b;
    background-color: #0f2133;
    .logo {
        display: flex;
        width: 100%;
        height: 100%;
        img {
            object-fit: contain;
            width: 30px;
            height: 30px;
            margin: auto;
        }
    }
    .logo-text {
        width: 100%;
        display: flex;
        padding-left: 17px;
        img {
            object-fit: contain;
            height: 25px;
            margin: auto;
        }
        span {
            font-weight: 400;
            color: #fff;
            font-size: 1.2rem;
        }
    }
}
.notice {
    color: red !important;
}
a {
    text-decoration: none !important;
    display: flex !important;
    width: 100%;
    text-transform: uppercase;
    color: inherit !important;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7) !important;
    padding: 0px 16px;
}

a:hover {
    background: rgba(255, 255, 255, 0.022);
}
a i {
    color: #a0a0a0 !important;
    font-size: 21px !important;
}
.v-list-item a {
    padding: 0px 20px;
}

.menu-action-icon {
    margin-right: 10px;
    width: 35px !important;
    height: 35px !important;
    i {
        font-size: 25px !important;
    }
    &:last-child {
        margin-right: 0px !important;
    }
}
>>> .v-input__slot {
    background: rgba(0, 0, 0, 0.05) !important;
}

>>> .v-input--is-focused .v-label {
    color: rgba(0, 0, 0, 0.87) !important;
}
>>> .v-input--is-focused input {
    color: rgba(0, 0, 0, 0.87) !important;
}

.v-list-item {
    padding: 0px;
    display: block;
}

>>> .v-input__control {
    min-height: 40px !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 13px !important;
}

@media only screen and (max-width: 960px) {
    #navigation-history {
        top: 50px !important;
    }
    .search-field {
        display: none;
    }
}
</style>
