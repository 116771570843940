<template>
    <v-dialog
        v-model="EditMarketingTypeDialog"
        v-if="EditMarketingTypeDialog"
        persistent
        max-width="750"
    >
        <v-card>
            <v-card-title>
                <span class="header"
                    >Add dates and amount for
                    {{ localMarketingInfo.name }}</span
                >
            </v-card-title>
            <v-card-text class="pa-0">
                <v-container :class="{ 'pa-3': isMobile }">
                    <v-row>
                        <v-col cols="12" md="12" class="pa-0">
                            <v-col cols="12" md="12" class="pa-5 pb-3 pt-0">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        :class="{ 'pa-0': isMobile }"
                                    >
                                        <v-text-field
                                            label="Name"
                                            outlined
                                            dense
                                            v-model="localMarketingInfo.name"
                                            hide-details
                                            filled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        class="d-flex align-center"
                                    >
                                        <v-text-field
                                            label="Estimate"
                                            type="number"
                                            outlined
                                            dense
                                            v-model="
                                                localMarketingInfo.estimate
                                            "
                                            :suffix="$config.CURRENCY"
                                            hide-details
                                            filled
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-divider></v-divider>
                            <v-col cols="12" md="12" class="pa-5 pb-0 pt-3">
                                <div class="add-row-header">Add row</div>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="5"
                                        class="d-flex align-center"
                                    >
                                        <v-text-field
                                            label="Amount"
                                            type="number"
                                            outlined
                                            dense
                                            v-model="amount"
                                            :suffix="$config.CURRENCY"
                                            hide-details
                                            filled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="5"
                                        class="d-flex justify-center"
                                        :class="{ 'py-5': isMobile }"
                                    >
                                        <DatePicker
                                            class="pa-0"
                                            :showButton="false"
                                            :defaultDates="defaultDates"
                                            @setStartDate="setStartDate"
                                            @setEndDate="setEndDate"
                                            :style="
                                                isMobile
                                                    ? 'float:left!important;padding:0px;'
                                                    : ''
                                            "
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="2"
                                        :style="
                                            isMobile
                                                ? 'padding-left:0px!important;'
                                                : ''
                                        "
                                    >
                                        <v-btn
                                            class="add-btn"
                                            :disabled="this.amount === ''"
                                            @click="addRow"
                                            block
                                            depressed
                                            color="primary"
                                            >Add</v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-container>
                <v-col cols="12" md="12" class="added-rows-header d-flex px-6">
                    <span class="remove-placeholder"></span>
                    <span>Date span</span>
                    <v-spacer />
                    <span>Amount</span>
                </v-col>
                <v-col cols="12" md="12" class="added-rows-wrapper pa-0">
                    <div
                        class="added-row d-flex align-center"
                        v-for="(row, i) in localMarketingInfo.marketing_data"
                        :key="row.id"
                    >
                        <v-btn
                            icon
                            color="error"
                            class="remove-row-btn"
                            @click="deleteRow(row.marketing_dataid, i)"
                        >
                            <v-icon>remove_circle_outline</v-icon>
                        </v-btn>

                        <span class="startdate">{{ row.startdate }}</span>
                        <v-icon color="primary" class="mr-2 ml-2"
                            >arrow_right_alt</v-icon
                        >
                        <span class="enddate">{{ row.enddate }}</span>
                        <v-spacer></v-spacer>
                        <span class="amount"
                            >{{ row.amount }} {{ $config.CURRENCY }}</span
                        >
                    </div>
                    <div
                        class="no-row-placeholder"
                        v-if="
                            currentMarketingInfo.marketing_data.length === 0 &&
                            rowsToBePosted.length === 0
                        "
                    >
                        <span>No rows added</span>
                    </div>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <span
                    class="unsaved-changes-alert"
                    v-if="
                        rowsToBePosted.length != 0 ||
                        nameChangesMade ||
                        estimateChangesMade
                    "
                    ><v-icon>mdi-information-outline</v-icon> Unsaved changes,
                    press the "Save" button to save your work.</span
                >
                <v-btn text @click="$emit('closeEditMarketingType')"
                    >close</v-btn
                >
                <v-btn
                    color="primary"
                    depressed
                    @click="saveRows"
                    :disabled="
                        rowsToBePosted.length === 0 &&
                        !nameChangesMade &&
                        !estimateChangesMade
                    "
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePicker from "@/components/DatePicker/";
import { mapGetters } from "vuex";
export default {
    name: "Addmarketingtypedialog",
    components: {
        DatePicker,
    },
    props: {
        EditMarketingTypeDialog: Boolean,
        marketingTypeName: String,
        currentMarketingInfo: Object,
    },
    data: () => ({
        defaultDates: {
            startDate: new Date().toISOString().substr(0, 10),
            endDate: new Date().toISOString().substr(0, 10),
        },
        isSaveNewMarketingTypePending: false,
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        amount: "0",
        rowsToBePosted: [],
        marketingName: "",
        estimate: "",
        originalData: [],
        isRowsPending: true,
        localMarketingInfo: null,
        estimateChangesMade: false,
        nameChangesMade: false,
    }),
    watch: {
        currentMarketingInfo(v) {
            if (!this.currentMarketingInfo.marketing_data)
                this.currentMarketingInfo.marketing_data = [];
            this.localMarketingInfo = JSON.parse(
                JSON.stringify(this.currentMarketingInfo)
            );

            this.marketingName = this.currentMarketingInfo.name;
            this.estimate = this.currentMarketingInfo.estimate;
        },
        "localMarketingInfo.name": {
            handler(name) {
                if (name !== this.marketingName) {
                    this.nameChangesMade = true;
                } else {
                    this.nameChangesMade = false;
                }
            },
            deep: true,
        },
        "localMarketingInfo.estimate": {
            handler(estimate) {
                if (estimate !== this.estimate) {
                    this.estimateChangesMade = true;
                } else {
                    this.estimateChangesMade = false;
                }
            },
            deep: true,
        },
    },
    created() {
        // this.checkForData();
    },
    methods: {
        async closeEditMarketingTypeDialog() {
            this.$emit("closeEditMarketingTypeDialog");
        },
        async addRow() {
            /**
             *   Declare a generic postObject which is used below.
             */
            const postObject = {
                startdate: this.startDate,
                enddate: this.endDate,
                amount: this.amount,
                marketingid: this.localMarketingInfo.marketingid,
            };

            /**
             *   Push in the rows recently added into the local 'marketing_data'
             *   so the component renders the changes.
             */
            this.localMarketingInfo.marketing_data.push({
                ...postObject,
            });

            /**
             *   We also push the same object to the 'rowsToBePosted' array,
             *   which is later used in the post function.
             */
            this.rowsToBePosted.push({
                ...postObject,
            });

            /**
             *   Reset the amount if user wants to add another row.
             */
            this.amount = 0;
        },
        async saveRows() {
            try {
                /**
                 *   Only post if new rows has been added.
                 */
                if (this.rowsToBePosted.length > 0) {
                    const postResponse = await this.$PostService(
                        this.$getRoute("marketing").marketing_data,
                        this.rowsToBePosted
                    );

                    /**
                     *   Add the marketing_dataid to all new rows recenly saved.
                     */
                    this.rowsToBePosted.forEach((row, index) => {
                        row.marketing_dataid =
                            postResponse.data.marketing_dataid[index];
                    });

                    /**
                     *   All rows added also need to be updated in the parent table.
                     */
                    this.$emit("addRowsLocallyAfterSave", this.rowsToBePosted);

                    /**
                     *   Reset the array so the user can post new rows
                     *   directly after save without making duplicates.
                     */
                    this.rowsToBePosted = [];
                }

                /**
                 *   Always PUT in case user has changed the name or the estimate.
                 */
                const putResponse = await this.$PutService(
                    this.$getRoute("marketing").main_route,
                    {
                        name: this.localMarketingInfo.name,
                        estimate: this.localMarketingInfo.estimate,
                        marketingid: this.localMarketingInfo.marketingid,
                    }
                );
                if (putResponse.status == 200) {
                    /**
                     *   Reset all the changes made if post is successfull.
                     *   We do this so the watcher can react on new changes.
                     */
                    this.estimateChangesMade = false;
                    this.nameChangesMade = false;

                    /**
                     *   Emit back the 'localMarketingInfo' (name, estimate) to the parent component.
                     */
                    this.$emit(
                        "updateLocalMarketingData",
                        this.localMarketingInfo
                    );

                    /**
                     *   Update the current marketingname and estimate
                     *   so that the watcher can react if they have been changed.
                     */
                    this.marketingName = this.currentMarketingInfo.name;
                    this.estimate = this.currentMarketingInfo.estimate;
                }
            } catch (err) {
                console.log(err);
            }
        },
        async deleteRow(dataid, i) {
            /**
             *   If a 'dataid' is provided, it means that the row is fetched
             *   from the database. In that case we need to use the DeleteService
             *   to delete it.
             */
            if (dataid) {
                try {
                    let response = await this.$DeleteService(
                        this.$getRoute("marketing").marketing_data,
                        { marketing_dataid: dataid }
                    );
                    if (response.status === 200) {
                        /**
                         *   If delete is successfull we delete the row locally
                         *   to mirror the database.
                         */
                        this.$delete(this.localMarketingInfo.marketing_data, i);
                    }

                    /**
                     *   Emit back the dataid to the parent component to
                     *   also be deleted locally
                     */
                    this.$emit("deleteMarketingDataLocally", dataid);
                } catch (error) {
                    console.log(error);
                }
            } else {
                /**
                 *   If no 'dataid' is provided we need know that row
                 *   only has been added locally and yet saved.
                 *   In that case we only need to remove it locally.
                 */
                this.$delete(this.localMarketingInfo.marketing_data, i);
                this.$delete(this.rowsToBePosted, i - 1);
            }
        },
        setStartDate(date) {
            this.startDate = date;
        },
        setEndDate(date) {
            this.endDate = date;
        },
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
.header {
    font-size: 15px !important;
    text-transform: uppercase;
    color: $accent-color;
    font-weight: 600 !important;
}

.mobile .header {
    font-size: 0.65em !important;
}

.add-btn {
    height: 40px !important;
}

.mobile {
    .add-btn {
        margin-top: 15px !important;
    }
}

.added-rows-wrapper {
    float: left;
    font-size: 14px;
    font-weight: 500;
    min-height: 50px;
    position: relative;
}

thead {
    box-shadow: 0px 3px 8px #00000024;
    transform: translate(0, 0);
}

.added-rows-header {
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 3px 8px #00000024;
    border-top: 1px solid #c7c7c7;
    color: $accent-color;
    z-index: 1;
    position: relative;
}

.added-row {
    float: left;
    width: 100%;
    padding: 20px 20px 20px 10px;
    border-bottom: 1px solid $border-color;
    background: #f8f8f8;
    &:last-child {
        border-bottom: none;
    }
    &:hover {
        background: #e9e9e970 !important;
    }
}

.remove-row-btn {
    margin-right: 15px;
}

.remove-placeholder {
    min-width: 30px;
    margin-right: 5px;
}

.no-row-placeholder {
    width: 100%;
    float: left;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.v-card__actions {
    border-top: none !important;
    box-shadow: 0px -3px 8px #00000021;
    position: relative;
    z-index: 1;
}

.add-row-header {
    font-weight: 600;
    color: $font-color-pale;
    text-transform: uppercase;
}

.v-input {
    &::v-deep .v-text-field__suffix {
        margin: 0px !important;
    }
}

.unsaved-changes-alert {
    font-size: 15px;
    color: $font-color;
    border-radius: 3px;
    background: #e344442c;
    padding: 6px 12px 6px 6px;
    display: flex;
    margin-right: 15px;
    align-items: center;
    i {
        color: #e34444;
        font-size: 22px;
        margin-right: 10px;
    }
}
</style>