<template>
    <div id="email-menu">
        <div id="email-form" class="elevation-1">
            <div class="header">
                <v-icon>mail_outline</v-icon> Generate email
            </div>
            <v-form ref="form" v-model="valid">
                <div class="form-header">Add recipient</div>
                <v-row>
                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                            outlined
                            dense
                            label="Recipient"
                            :rules="emailRules"
                            v-model="email"
                            class="mb-4"
                            filled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                            outlined
                            hide-details
                            dense
                            label="Subject"
                            v-model="subject"
                            filled
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-btn
                    dense
                    color="primary float-right"
                    small
                    @click="addRecipient"
                    depressed
                    >Add recipient</v-btn
                >
            </v-form>
            <div id="recipients">
                <div class="recipients-header">
                    <span class="mdi mdi-email-send"></span> Recipients
                </div>
                <transition-group name="slider" tag="div" mode="out-in">
                    <div
                        v-for="(recipient, index) in recipients"
                        :key="recipient"
                        class="recipient-container"
                    >
                        <div class="recipient">
                            <div class="remove-recipient-container">
                                <span
                                    class="mdi mdi-close-circle remove"
                                    @click="removeRecipient(recipient, index)"
                                ></span>
                            </div>
                            <span class="email">{{ recipient }}</span>
                        </div>
                    </div>
                </transition-group>
                <div
                    v-if="recipients.length === 0"
                    class="no-recipients-placeholder"
                >
                    No recipients added
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "emailmenurow",
    props: {
        recipients: Array,
    },
    data: () => ({
        email: "",
        valid: true,
        subject: "",
        recipients: [],
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
    }),
    methods: {
        addRecipient() {
            const subject = this.subject;
            if (this.$refs.form.validate()) {
                if (this.recipients.includes(this.email)) {
                    alert("Email already added to recipients");
                    return;
                }
                this.$emit("addRecipient", {
                    recipient: this.email,
                    subject: this.subject,
                });
                this.$refs.form.reset();
            }
            this.$nextTick(() => (this.subject = subject));
        },
        removeRecipient(recipient, index) {
            this.$emit("removeRecipient", { recipient, index });
        },
    },
};
</script>

<style lang="scss">
#email-menu {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    background: #f8f8f8;
    padding: 20px;
    float: left;
    width: 100%;
    .header {
        padding: 20px;
        font-weight: 500;
        color: #666;
        i {
            color: $primary;
            margin-right: 10px;
        }
    }
    #email-form {
        background: #fff;
        width: 100%;
        float: Left;
        border-radius: 3px;
    }
    form {
        padding: 20px;
        float: left;
        width: 100%;
        .form-header{
            font-size:14px;
            font-weight:600;
            margin-bottom:10px;
            color:#666;
        }
    }
    #recipients {
        padding: 20px 20px 20px 20px;
        width: 100%;
        float: left;
        border-top: 1px solid $border-color;
        background: #fff;
        .recipients-header {
            width: 100%;
            float: left;
            margin-bottom: 15px;
            font-weight: 500;
            color: #666;
        }
        .recipient-container {
            padding: 5px;
            float: left;
            .recipient {
                background: $accent-color;
                float: left;
                color: #fff;
                padding: 0px 15px 0px 5px;
                font-size: 16px;
                border-radius: 30px;
                min-width: 100px;
                display: flex;
                align-items: center;

                .email {
                    font-size: 14px;
                    color: #fff !important;
                    font-family: "roboto" !important;
                }
                .remove {
                    margin: auto;
                    float: left;
                    font-size: 20px;
                    margin-right: 10px;
                    color: rgba(255, 255, 255, 0.7);
                    cursor: pointer;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    .no-recipients-placeholder {
        font-size: 14px;
        color: #666;
    }
    .v-input {
        margin-bottom: 0px !important;
    }

    .slider-enter-active,
    .slider-leave-active {
        transition: all 0.3s;
    }

    .slider-enter-active {
        transition-delay: 0.1s;
    }

    .slider-enter,
    .slider-leave-to {
        opacity: 0;
        transform: translate(-30px);
    }
}
</style>
