<template>
    <div class="logout-wrapper">
        <div class="logout-message-wrapper elevation-1" v-if="!authenticated">
            <div class="card-title">User logged out</div>
            <div class="message-wrapper">
                <div class="message">You have successfully logged out. You will be redirected to the login page shortly.</div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'logout',
        data:()=>({
            authenticated:true
        }),
        async mounted(){
            this.authenticated = await this.$auth.isAuthenticated()
            console.log(this.authenticated)
            if(this.authenticated){
                this.$router.push('/')
           }else{
                setTimeout(() => {
                    this.$router.push('/login')
                }, 5000);
            }

        }
    }
    
</script>


<style lang="scss" scoped>

.logout-wrapper{
    height:100%;
    display:flex;
    background: #11283e08;
    .logout-message-wrapper{
        width:40%;
        min-width:600px;
        background:#fff;
        height:100px;
        margin:auto;
        border-radius:3px;
        display:flex;
        flex-direction: column;
        .message-wrapper{
            display:flex;
            height:100%;
            .message{
                margin:auto;
            }
        }
    }
}

</style>