<template>
    <div id="edit-slider-wrapper">
        <Loader :loaderStyle="'fill'" v-if="isTableDataPending" />
        <div class="edit-slider-header d-flex">
            <v-btn class="mr-5 close-drawer" @click="closeEditDrawer" icon depressed>
                <v-icon color="primary" dark style="transform:rotate(180deg)">menu_open</v-icon>
            </v-btn>
            <div class="header">
                <span>Edit</span>
            </div>
            <v-spacer></v-spacer>
            <v-btn style="min-width:126px;" :color="confirmation ? 'success' : 'primary'" :loading="isUpdatePending" small depressed @click="setExpandData()">
                <span v-if="!confirmation">Save changes</span>
                <span v-if="confirmation"><v-icon>check</v-icon></span>
            </v-btn>
        </div>
        <handle-general-form
            v-if="!isTableDataPending"
            ref="handleGeneralFormRef"
            :apiObject="apiObject"
            :view="view"
            :metaParam="metaParam"
            :dropdown_lists="dropdown_lists"
            :expandVersion="true"
            @generalFormUpdate="generalFormUpdate"
            @requiredFieldsError="showRequiredFieldsError = true"
            @refresh_dropdown_list="refreshDropdown"
        />
        <handle-extra
            v-if="ref_list.includes(view) && !isTableDataPending"
            ref="extraFormRef"
            :metaParam="metaParam"
            :stack="isMobile"
            :dropdown_lists="dropdown_lists"
            :expansionPanel="true"
        />
    </div>
</template>

<script>
import EditItemMixin from "@/components/Tables/EditTableItem/EditItemMixin.js";
import EditItemNoticeMixin from "@/components/Menu/ExpandCollapse/EditItemNoticeMixin.js";
import HandleGeneralForm from "@/components/Tables/EditTableItem/HandleGeneralForm.vue";
import Artist from "./Artist.vue";
import Concert from "./Concert.vue";
import Tour from "./Tour.vue";
import Loader from "@/components/Loader";
import Notice from "@/models/Notice";
import { mapGetters } from "vuex";
import GeneralExpandForm from "@/components/Tables/EditTableItem/forms/GeneralExpandForm.vue";
import ApiObject from "@/models/ApiObject";
import ObjectFactory from "@/models/ObjectFactory";
import ConcertOptions from "@/components/Tables/EditTableItem/forms/FormOptions/ConcertOptions";
import ArtistOptions from "@/components/Tables/EditTableItem/forms/FormOptions/ArtistOptions";
import TourOptions from "@/components/Tables/EditTableItem/forms/FormOptions/TourOptions";
import GeneralOptions from "@/components/Tables/EditTableItem/forms/FormOptions/GeneralOptions";

export default {
    name: "ExpandCollapse",
    mixins: [EditItemMixin, EditItemNoticeMixin],
    components: {
        Artist,
        Concert,
        Tour,
        Loader,
        GeneralExpandForm,
        HandleGeneralForm,
    },
    props: {
        isSliderDataPending: Boolean,
    },
    data: () => ({
        isUpdatePending: false,
        confirmation:false,
    }),
    async created() {
        console.log("expandcollapse create");
    },
    methods: {
        async setExpandData() {
            /**
             * Call mixin setData
             */
            this.isUpdatePending = true;
            await this.setData({ stayOnPage: true });
            this.isUpdatePending = false;
            this.confirmation = true
            setTimeout(() => {
                this.confirmation = false;
            }, 1500);

            /**
             * Call notice/wall mixin and
             * send out relevant notices and wall items
             */
            if (this.updatedApiObject != null) await this.addNoticeAndWallItems(this.updatedApiObject);

            if (this.view == "concerts") {
                this.fixTicketingDates(this.updatedApiObject);
                await this.$bus.$emit("updateConcertDataFromSlider");
            }
            if (this.view == "tours") {
                await this.$bus.$emit("updateTourDataFromSlider");
            }
        },
        /**
         * Clear out ticket sales in db that is outside of concert date range
         */
        async fixTicketingDates(apiObj) {
            if (!apiObj.tickets) return;

            let mindate = new Date(
                Math.min(
                    ...this.pushDateIfSet(
                        apiObj.on_sale,
                        apiObj.startdate_spotify,
                        apiObj.startdate_fan_club,
                        apiObj.startdate_local_presale,
                        apiObj.startdate_amex
                    )
                )
            ).setHours(12, 0, 0, 0);
            let maxdate = new Date(Math.max(...this.pushDateIfSet(apiObj.date, apiObj.date_end))).setHours(12, 0, 0, 0);

            let salesToDel = [];
            if (!apiObj.tickets) return;

            apiObj.tickets.map((ticket) => {
                if (ticket.ticket_sales) {
                    ticket.ticket_sales.map((sale) => {
                        let date = new Date(sale.date + " 12:00:00");
                        if (date < mindate || date > maxdate)
                            salesToDel.push({
                                ticket_salesid: sale.ticket_salesid,
                            });
                    });
                }
            });

            if (salesToDel.length > 0) {
                try {
                    let response = await this.$DeleteService(this.$getRoute("tickets").sales, salesToDel);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        pushDateIfSet(...dates) {
            let dateArray = [];
            dates.map((date) => (date ? dateArray.push(new Date(date)) : null));
            return dateArray;
        },
        closeEditDrawer() {
            this.items = {};
            this.$emit("closeEditDrawer");
        },
    },
};
</script>

<style lang="scss">
#edit-slider-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    .v-chip {
        background: $accent-color !important;
        color: #fff !important;
    }
}
.edit-slider-header {
    position: fixed;
    background: #fff;
    top: 0px;
    width: 100%;
    float: left;
    padding: 12px 20px;
    z-index: 2;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color;
    .header {
        font-weight: 600;
        font-size: 20px;
        color: $font-color-pale;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
}
</style>
