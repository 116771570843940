import Vue from 'vue'
import VueRouter from 'vue-router'
import Logout from '../views/Logout.vue'
import Login from '../views/Login.vue'
import LandingPage from '../views/LandingPage.vue'
import EditTableItem from '../components/Tables/EditTableItem/'
import ArtistPage from '../components/ArtistPage'
import ConcertPage from '../components/ConcertPage'
import TourPage from '../components/TourPage'
import Home from '../views/Home.vue'
import Concerts from '../views/Concerts.vue'
import BudgetPage from '../components/BudgetPage'
import BudgetHistory from '../components/BudgetPage/BudgetHistory'
import Festivals from '../views/Festivals.vue'
import Tours from '../views/Tours.vue'
import Artists from '../views/Artists.vue'
import Venues from '../views/Venues.vue'
import Sales from '../views/Sales.vue'
import People from '../views/People.vue'
import Organizations from '../views/Organizations.vue'
import ComparableProjects from '../views/ComparableProjects.vue'
import Insights from '../views/Insights.vue'
import Ticketing from '../views/Ticketing.vue'
import HandleComparableProj from '../components/ComparableProjectsPage/HandleComparableProj.vue';
import Invoices from '../views/Invoices.vue';
import Help from '../views/Help.vue';
import Testing from '../views/Testing.vue';

import axios from 'axios'
import VueAxios from 'vue-axios'

import Auth from '@okta/okta-vue'

Vue.use(VueAxios, axios)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name:'landingpage',
        path: '',
        component: LandingPage,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'testing',
        path: '/testing',
        component: Testing,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name: 'concerts',
        path: '/concerts',
        component: Concerts,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'concertpage',
        path: '/concerts/edit/:id',
        component: ConcertPage,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'createconcert',
        path: '/concerts/create',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'concertbudgethistory',
        path: '/concerts/:id/budgets/',
        component: BudgetHistory,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'budgets',
        path: '/budgets/:id',
        component: BudgetPage,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'festivals',
        path: '/festivals',
        component: Festivals,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'tours',
        path: '/tours',
        component: Tours,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'tourpage',
        path: '/tours/edit/:id',
        component: TourPage,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'tourpage',
        path: '/tours/create/',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'tourbudgethistory',
        path: '/tours/:id/budgets/',
        component: BudgetHistory,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'artists',
        path: '/artists',
        component: Artists,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'venues',
        path: '/venues',
        component: Venues,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'sales',
        path: '/sales',
        component: Sales,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'edittableview',
        path: '/venues/edit/:id',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'edittableview',
        path: '/venues/create',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'people',
        path: '/people',
        component: People,
        meta: {
          requiresAuth: true
        }
      },
      ,
      { 
        name:'edittableview',
        path: '/people/edit/:id',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'edittablecreate',
        path: '/people/create',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'organizations',
        path: '/organizations',
        component: Organizations,
        meta: {
          requiresAuth: true
        }
      },
      ,
      { 
        name:'edittableview',
        path: '/organizations/edit/:id',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'edittablecreate',
        path: '/organizations/create',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'artistpage',
        path: '/artists/edit/:id',
        component: ArtistPage,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'edittablecreate',
        path: '/artists/create',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'comparableprojects',
        path: '/comparableprojects',
        component: ComparableProjects,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'editcomparableprojects',
        path: '/comparableprojects/edit/:id',
        component: HandleComparableProj,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'editcomparableprojects',
        path: '/comparableprojects/create',
        component: HandleComparableProj,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'insights',
        path: '/insights',
        component: Insights,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'invoices',
        path: '/invoices',
        component: Invoices,
        meta: {
          requiresAuth: true
        }
      },
      { 
        name:'edittableview',
        path: '/invoices/edit/:id',
        component: EditTableItem,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'ticketing',
        path: '/ticketing',
        component: Ticketing,
        meta: {
          requiresAuth: true
        }
      },
      {
        name:'help',
        path: '/help',
        component: Help,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/logout',
    component: Logout
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/implicit/callback',
    component: Auth.handleCallback()
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



const onAuthRequired = async (from, to, next) => {
  if (from.matched.some(record => record.meta.requiresAuth) && !(await Vue.prototype.$auth.isAuthenticated())) {
    // Navigate to custom login page
    next({ path: '/login' })
  } else {
    next()
  }
}

router.beforeEach(onAuthRequired)

export default router
