<template>
    <div id="#app" :class="{ mobile:isMobile }">
        <v-app id="inspire">
            <router-view></router-view>
        </v-app>
    </div>
</template>
 
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "app",
    data: function () {
        return {
            authenticated: false,
        };
    },
    created() {
        this.isAuthenticated();
        this.checkIfMobile();
    },
    watch: {
        // Everytime the route changes, check for auth status
        $route: "isAuthenticated",
        "$vuetify.breakpoint.mdAndDown": {
            handler: function (v) {
            
                if (v) {
                    this.setIsMobile(true);
                } else {
                    this.setIsMobile(false);
                }
            },
        },
    },
    methods: {
        async isAuthenticated() {
            this.authenticated = await this.$auth.isAuthenticated();
        },
        checkIfMobile() {
            if (this.$vuetify.breakpoint.mdAndDown) {
                this.setIsMobile(true);
            } else {
                this.setIsMobile(false);
            }
        },
        ...mapActions({
            setIsMobile: "globalstates/setIsMobile",
        }),
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>
<style lang="scss">
// CSS VARIABLES

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    background: #eef0f3;
}

ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.theme--light.v-application {
    background: #f5f7f9 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
}

</style>