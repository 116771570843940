<template>
  <div id="tours">
		<v-col cols="12" md="12" class="elevation-1 pa-0">
			<ViewHeader :header="header"/>
			<BrowseTable  :idpath="idpath" :view="this.$options.name"/>
		</v-col>
  </div>
</template>

<script>

import ViewHeader from '@/components/ViewHeader/'
import BrowseTable from '@/components/Tables/BrowseTable/'
export default {
	name:'tours',
	components:{
		ViewHeader,
		BrowseTable
	},
	data:()=>({
      header:'Browse Tours',
      idpath:'tourid'
	})
}
</script>

<style scoped>



</style>