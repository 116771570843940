<template>
    <div id="marketinginfocard" class="float-left pa-0">
        <v-col
            cols="12"
            md="12"
            class="px-8"
            style="position: relative; float: left"
        >
            <NoDataPlaceholder
                v-if="marketingBars.length === 0"
                :text="'no market data'"
                style="z-index: 1"
            />
            <v-col cols="12" md="12" class="float-left">
                <DonutProgress
                    :value="marketingPercent"
                    :sum="
                        sekpercent == $config.CURRENCY
                            ? $thousandSeperator(marketingCost)
                            : undefined
                    "
                    :sumType="$config.CURRENCY"
                    :donutSubtext="donutSubText"
                />
            </v-col>
            <v-col cols="12" md="12" class="float-left">
                <div class="radio-btn-wrapper">
                    <v-radio-group v-model="sekpercent" row>
                        <v-radio
                            :label="$config.CURRENCY"
                            color="primary"
                            :value="$config.CURRENCY"
                        ></v-radio>
                        <v-radio
                            label="Percent"
                            color="primary"
                            value="percent"
                        ></v-radio>
                    </v-radio-group>
                </div>
            </v-col>
            <div class="linear-progress d-flex">
                <v-col col="12" md="12">
                    <v-row>
                        <v-col cols="12" md="12">
                            <div class="title-wrapper">
                                <div class="title">Type</div>
                                <v-spacer></v-spacer>
                                <div
                                    class="title"
                                    v-if="sekpercent === 'percent'"
                                >
                                    Percent of total
                                </div>
                                <div class="title" v-else>Total spent</div>
                            </div>
                        </v-col>
                        <LinearProgressBars
                            :bars="sortedBars"
                            :sumType="
                                sekpercent == 'percent' ? '%' : $config.CURRENCY
                            "
                            :choice="sekpercent"
                        />
                    </v-row>
                </v-col>
            </div>
        </v-col>
    </div>
</template>

<script>
import LinearProgressBars from "@/components/LinearProgressBars/";
import NoDataPlaceholder from "@/components/NoDataPlaceholder";
import DonutProgress from "@/components/DonutProgress";

export default {
    name: "MarketingInfoCard",
    components: {
        LinearProgressBars,
        NoDataPlaceholder,
        DonutProgress,
    },
    props: {
        marketingBars: Array,
        budgetMarketingSum: String,
        marketingCost: Number,
        budgetData: Object,
        view: String,
    },
    data: () => ({
        sekpercent: "",
        marketingPercent: 0,
        donutSubText: "",
    }),
    watch: {
        marketingCost() {
            this.getMarketingPercent();
        },
        sekpercent(v) {
            console.log(v)
            console.log(this.$config.CURRENCY)
                if (v === this.$config.CURRENCY)
                    this.donutSubText = "Spent";
                if (v === "percent")
                    this.donutSubText = "Of budget";
        },
    },
    created() {
        this.sekpercent = this.$config.CURRENCY;
        this.getMarketingPercent();

        console.log(this.marketingBars);
    },
    methods: {
        getMarketingPercent() {
            if (!this.budgetMarketingSum) {
                this.marketingPercent = 0;
                this.donutSubText = "No Budget set";
            } else {
                this.marketingPercent = Math.round(
                    (this.marketingCost / +this.budgetMarketingSum) * 100
                );
            }
        },
    },

    computed: {
        sortedBars() {
            console.log(this.marketingBars);
            let bars = JSON.parse(JSON.stringify(this.marketingBars));
            if (bars != undefined) {
                bars.sort((a, b) => {
                    return b.total - a.total;
                });
            }
            return bars;
        },
    },
};
</script>

<style lang="scss" scoped>
#marketinginfocard {
    background: #fff;
    float: left;
    width: 100%;
    position: relative;
    border-radius: 3px;
}
.radio-btn-wrapper {
    display: flex;
    justify-content: center;
}
.linear-progress {
    color: $font-color;
    width: 100%;
    float: left;
    .title-wrapper {
        border-bottom: 1px solid #6a7a8c;
        float: left;
        width: 100%;
        display: flex;
        .title {
            font-size: 1rem !important;
            text-transform: uppercase;
            font-weight: 600;
            color: $font-color;
        }
    }
}
</style>