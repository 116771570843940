<template>
    <v-dialog v-model="dialog" persistent width="800">
        <v-card>
            <v-card-title class="header">
                Add a new ticket revenue
            </v-card-title>

            <v-card-text>
                <ticket-revenue-form :ticketItem="ticketItem"></ticket-revenue-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text small @click="cancelDialog">Cancel</v-btn>
                <v-btn color="primary" depressed small @click="addTicketRevenue">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import TicketRevenueForm from "@/components/VenueBudgetTemplate/TicketRevenueForm";

export default {
    props: {
        ticketItem: Object,
        dialog: Boolean,
    },

    components: {
        TicketRevenueForm,
    },

    created() {},

    data: () => ({}),

    methods: {
        addTicketRevenue() {
            this.$emit("addTicketToArr", this.ticketItem);
        },

        cancelDialog() {
            this.$emit("cancelTicketDialog");
        },
    },
};
</script>

<style></style>
