<template>
    <div id="people">
        <v-col cols="12" md="12" class="elevation-1 pa-0">
            <ViewHeader :header="header" />
            <BrowseTable :view="this.$options.name" :idpath="this.idpath" />
        </v-col>
    </div>
</template>

<script>
import BrowseTable from "@/components/Tables/BrowseTable";
import ViewHeader from "@/components/ViewHeader";
import { mapGetters } from "vuex";
export default {
    name: "people",
    components: {
        BrowseTable,
        ViewHeader,
    },
    data: () => ({
        idpath: "personid",
    }),
    computed: {
        ...mapGetters({
            view: "globalstates/view",
        }),
        header() {
            return `Browse ${this.$getRoute(this.view).label}`;
        },
    },
};
</script>

<style scoped lang="scss"></style>
