<template>
   <span>
      <v-row class="row-wrapper">
         <v-col cols="12" md="3">
            <v-text-field 
               v-if="!costItem.editableName"
               dense
               filled
               hide-details
               required
               label="Type"
               v-model="costItem.display_name"
               disabled
            >
            </v-text-field>
            <v-text-field 
               v-if="costItem.editableName"
               label="Cost name"
               dense
               filled
               hide-details
               required
               v-model="costItem.display_name"
            />
         </v-col>
         <v-col cols="12" md="2">
            <v-text-field
               :disabled="globalRate"
               filled
               hide-details
               dense
               required
               label="Currency name"
               v-model="costItem.currency_name"
            />
         </v-col>

         <v-col cols="12" md="2">
            <v-text-field 
               :disabled="globalRate"
               filled
               hide-details
               style="text-align:right"
               type="number"
               dense
               required
               label="Exchange rate"
               v-model="costItem.currency_rate"
            />
         </v-col>         

         <v-col cols="12" md="2">
            <v-text-field 
               filled
               hide-details
               style="text-align:right"
               label="Amount"
               type="number"
               dense
               required
               :suffix="valueSuffix"
               v-model="costItem.currency_amount" 
            />
         </v-col>


         <v-col cols="12" md="2">
            <v-text-field
               filled
               hide-details
               :readonly="costItem.currency_amount > 0 && costItem.currency_rate > 0"
               type="number"
               label="Cost"
               dense
               required
               :suffix="currency"
               v-model="costItem.value"
            />
         </v-col>

         <v-col cols="12" md="1" class="d-flex align-center" >
            <v-spacer></v-spacer>
            <v-btn
               :disabled="costItem.sticky"
               small
               icon 
               color="error"
               @click.stop="openCostDialog"
            >
               <v-icon>remove_circle_outline</v-icon>
            </v-btn>
            <comment-button
               class="ml-1"
               :name="costItem.display_name"
               :data="{ comment: costItem.comment, budget_rowid: costItem.budget_rowid }"
               :route="this.$getSubRoute('budgets','rows')"
               @setComment="(comment) => costItem.comment = comment"
            />
         </v-col>
          
      </v-row>
     

      <delete-dialog 
         @deleteFromDialog="deleteCost"
         @cancelDialog="cancelCostDialog"
         :dialog="deleteCostDialog"
         :item="costItem"
         :name="costItem.display_name"
      />
      
   </span>
</template>

<script>
import DeleteDialog from '@/components/Dialogs/DeleteDialog'
import CommentButton from '@/components/Dialogs/Comment/CommentButton'

export default {
   name: 'BudgetSixCostForm',

   components: {
      DeleteDialog,
      CommentButton,
   },

   props: {
      costItem: Object,
      valueType: String,
      globalRate: Boolean,
      totalRevenues: Number
   },

   data: ()=>({
      currency: '',
      deleteCostDialog: false,
      valueSuffix: '',
   }),

   async created() {
      this.currency = this.$config.CURRENCY;

      if(this.costItem.comment == null)
         this.costItem.comment = ''
      
      this.valueSuffix = this.valueType
   },

   computed: {
   },
   
   watch: {
      'costItem.display_name': {
         handler: function(val){
            this.costItem.name = val
         },
         deep: true
      },
      'costItem.currency_amount':{
         handler(val){
            console.log(`currency_amount watch: ${val}`)
            this.getLocalRate(val, this.costItem.currency_rate)
         },
         deep: true
      },
      'costItem.currency_rate':{
         handler(val){
            console.log(`currency_rate watch: ${val}`)
            this.getLocalRate(val, this.costItem.currency_amount)
         },
         deep: true
      },
      'costItem.value':{
         handler(val){
            console.log(`calced cost ${val}`)
         }
      }
   },

   async updated(){
   },

   methods: {
      getLocalRate(val1, val2){
         if(val1 > 0 || val2 > 0) {
           // this.$emit('getLocalRate', this.costItem)
            this.costItem.value = Math.round(this.costItem.currency_amount * this.costItem.currency_rate)
         } else {
            this.costItem.currency_rate = ''
            this.costItem.currency_amount = ''
            //this.costItem.value = 0
         }
      },

      openCostDialog(){
         this.deleteCostDialog = true
      },

      cancelCostDialog(){
         this.deleteCostDialog = false
      },

      async deleteCost(){
         this.$emit('deleteCost', this.costItem, this.index)
         this.deleteCostDialog = false
      },
   },

}
</script>

<style lang="scss" scoped>

.row-wrapper{
   padding:0px 0px;
}


</style>