import ConcertObject from './ConcertObject';
import ObjectFactory from './ObjectFactory';

export default class TicketingObject extends ConcertObject {
    constructor() {
        super('concerts');
        this.sumSales = null;
        this.on_sale = ''
        this.final_date = ''
        this.day = '';
    }

    async addTicket(name, price, capacity, available, platinum) {
        let ticket = await this.objFactory.BuildTicket({ ticket: { name, price, capacity, available, platinum }, concertid: this.concertid });
        let newID = await ticket.postObject();
        ticket[ticket.pk_field] = newID;
        ticket.ticket_sales = [];
        ticket.ticket_sales = await this.setMissingDates(this.MIN_DATE, this.MAX_DATE, ticket.ticket_sales, ticket.ticketid);
        ticket.ticket_sales.reverse();
        let i = 0;
        ticket.ticket_sales_with_date_keys = {};

        for(let sale of ticket.ticket_sales){
            ticket.ticket_sales_with_date_keys[sale.date] = sale;
            ticket.ticket_sales_with_date_keys[sale.date]['index'] = i++;
        }
        await ticket.setSumSales();
        this.ticketObjects.push(ticket);
    }

    getDeepCopy() {
        const temp = Object.assign(
            Object.create(Object.getPrototypeOf(this)),
            this
        );
        let objects = [];
        for(let ticket of this.ticketObjects){
            console.log(ticket)
            objects.push(Object.assign(Object.create(Object.getPrototypeOf(ticket)), ticket));
        }
        temp.ticketObjects = objects;
        temp.defineProperties()
        return temp;
    }
    defineProperties() {
        Object.defineProperty(this, 'capacity', { get: () => this.getTotalCapacity(), set: value => capacity = value });
        Object.defineProperty(this, 'change', { get: () => this.getTotalChange() });
        Object.defineProperty(this, 'available', { get: () => this.getTotalTicketsAvailable() });
        Object.defineProperty(this, 'sold', { get: () => this.getTotalSoldPercentage() });
        Object.defineProperty(this, 'gross_sales', { get: () => this.getTotalGross() });
        Object.defineProperty(this, 'total_sold', { get: () => this.getTotalSold() });
        Object.defineProperty(this, 'total_sold_yesterday', { get: () => this.getTotalSoldYesterday() });
    }
    setDayName(date) {
        var options = { weekday: 'long' };
        this.day = new Date(date).toLocaleDateString('en-US', options).substr(0, 3);
    }

    setTotalSumSales() {
        this.sumSales = [];
        for (let ticket of this.ticketObjects) {
            let i = 0;
            for (let amount of ticket.sum_sales) {
                if (!this.sumSales[i]) this.sumSales.push(0);
                this.sumSales[i++] += amount;
            }
        }
    }

    getTotalSumSales() {
        if (this.sumSales == null) {
            this.setTotalSumSales();
        }
        return this.sumSales;
    }

    getTotalSold() {
        return this.getTotalSumSales()[0];

    }
    getTotalSoldYesterday() {
        return this.getTotalSumSales()[1];
    }
}