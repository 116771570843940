<template>
  <div>
      <v-row>
         <v-col cols="12" md="1" class="d-flex align-center pl-8">
            <h1 class="float-left">PRS</h1>
         </v-col>
         <v-col cols="12" md="2">
            <v-switch
               :label="isActive ? 'Yes' : 'No'"
               v-model="isActive"
            />
         </v-col>
         <v-col cols="12" md="3" class="pt-5">
            <v-select
               :disabled="!isActive"
               class="float-left wizard-form-text"
               dense
               :items="prsTypes"
               label="Country"
               v-model="selectedPrs"
               filled
               hide-details
            />
         </v-col>
         <v-col cols="12" md="2"></v-col>
         <v-col cols="12" md="3" class="pt-6">
            <v-text-field
               :disabled="!isActive"
               class="wizard-form-text"
               readonly
               type="number"
               label="Calculated cost"
               dense
               required
               :suffix="currency"
               :value="calculated_cost"
               filled
               hide-details
            />
         </v-col>
         <v-col cols="12" md="1" class="pt-3" >
            <comment-button
               class="pt-3 float-right"
               :name="'PRS'"
               :data="{ comment: prs.comment, budget_rowid: prs.budget_rowid }"
               :route="this.$getSubRoute('budgets','rows')"
               @setComment="(comment) => prs.comment = comment"
            />
         </v-col>
      </v-row>
   </div>         
</template>

<script>
import CommentButton from '@/components/Dialogs/Comment/CommentButton'
export default {
   name: 'budgetprsform',
   components: {
      CommentButton
   },
   props: {
      prs: Object,
      calculated_cost: Number,
      prsTypes: [],
   },

   data: ()=>({
      isActive: false,
      selectedPrs: '',
      currency: '',
      calculated_cost: 0,
   }),

   watch: {
      isActive(val){
         this.$emit('prsToggle', this.isActive ? 1 : 0)
      },
      selectedPrs(val){
         //console.log(val)
         this.$emit('prsChange', this.prsTypes.indexOf(this.selectedPrs))
      },
      prs: {
         handler(val){
            this.calculated_cost = val.calculated_cost
         },
         deep: true
      }
   },
   created(){
      this.currency = this.$config.CURRENCY;

      this.isActive = this.prs.value != -1 ? true : false
      //console.log(`prsvalue ${this.prs.value}`)
      if(this.prs.comment == undefined)
         this.prs.comment = ''

      this.selectedPrs = this.prsTypes[this.prs.value]
   },
}
</script>

<style>

</style>