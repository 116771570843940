<template>
	<v-row>
		<v-col cols="12" md="6">
			<v-text-field 
				v-model="name"
				dense
				required
				filled
				hide-details
				label="Revenue name"
			/>
		</v-col>
		<v-col cols="12" md="5">
			<v-text-field 
				v-model="value" 
				type="number"
				dense
				required
				:suffix="$config.CURRENCY"
				filled
				hide-details
				label="Revenue"
			/>
		</v-col>
		
		<v-col cols="12" md="1" class="d-flex align-center">
			<v-spacer></v-spacer>
			<v-btn
				small
				icon 
				color="error"
				@click.stop="$bus.$emit('deleteBudgetItem', item)"
			>
				<v-icon>remove_circle_outline</v-icon>
			</v-btn>
			<comment-button
				class="ml-1"
				:name="name"
				:comment="!item.comment ? '' : item.comment"
				:idObj="{ budget_rowid: item.budget_rowid }"
				:route="$getSubRoute('budgets','rows')"
				@commentSaved="emitUpdate"
			/>
		</v-col>
		<!-- { comment: this.item.comment, budget_rowid: this.item.budget_rowid } -->
	</v-row>
</template>

<script>
import CommentButton from '@/components/Dialogs/Comment/CommentButtonPropSafe';
export default {
	name: 'tourrevenueform',

	components: {
		CommentButton,
	},

	props: {
		item: Object,
	},

	data: ()=>({
		name: '',
		value: 0,
		rowid: '',
		dataCreated: false,
	}),

	created(){
		this.name = this.item.name;
		
		// wait until data is set, then set value to trigger an global update value
		setTimeout(()=>{
			this.dataCreated = true;
			this.value = this.item.value;
		}, 1)
	},

	watch: {
		name(val){
			this.emitUpdate( { name: val } )
		},
		
		value(val){
			this.emitUpdate( { value: val } )
		}
	},

	methods: {
		emitUpdate(item){
			if(!this.dataCreated) return;
			
			item.budget_rowid = this.item.budget_rowid;
			item.step = this.item.step;
			this.$bus.$emit('tourBudgetUpdatedData', item)
		}
	}
}
</script>

<style>

</style>