<template>
    <div>
		<FormSkeleton v-if="type==='text-form'"/>
    </div>
</template>

<script>
	import FormSkeleton from './FormSkeleton.vue'
    export default {
		name:'Skeletonloaders',
		components:{
			FormSkeleton
		},
        props:{
            type:String
        }
    }
</script>

<style lang="scss">

.skeleton-wrapper{
	float:left;
	width:100%;
	.skeleton-field{
		margin-bottom:50px;
		float:left;
		width:100%;
	}	
}

</style>