import TabTable from './TabTable';
export default class TabBrowser {

    constructor({ title, rowType = '' }) {
        this.title = title;
        this.rowType = rowType;
        this.tabTables = {};
    }

    /**
     * Seperate the raw items into categories depending on the item filetype property.
     * Then it creates TabItem objects from these categorized items.
     */
    // generateTabTables(){
    //     var tables = this.rawTables;
    //     for(const TAB_NAME in tables){
    //         var inclHead = tables[TAB_NAME].includedHeaders ? tables[TAB_NAME].includedHeaders : [];
    //         var exclHead = tables[TAB_NAME].excludedHeaders ? tables[TAB_NAME].excludedHeaders : [];

    //         this.tabTables[TAB_NAME] = new TabTable(TAB_NAME, tables[TAB_NAME].rows, inclHead, exclHead, this.rowType);            
    //     }
    // }

    hasTable(tabName) {
        if (this.tabTables[tabName])
            return true;
        return false;
    }
    generateTablesFromKeystring({ keystring, items, inclHeaders = [], exclHeaders = [] }) {
        if (keystring.length == 0) throw Error('Missing keystring');
        var tables = {};

        if (!items) return false;

        for (const ITEM of items) {

            const KEY = ITEM[keystring];

            if (!tables[KEY])
                tables[KEY] = [];

            tables[KEY].push(ITEM);
        }

        for (const KEY in tables)
            this.addTable(
                {
                    tabName: KEY,
                    rows: tables[KEY],
                    inclHeaders: inclHeaders,
                    exclHeaders: exclHeaders
                }
            );


        return true;
    }
    addTable({ tabName, rows, inclHeaders = [], exclHeaders = [] }) {
        this.tabTables[tabName] = new TabTable(tabName, rows, inclHeaders, exclHeaders, this.rowType);
    }
    deleteTableRow(tableName, rowIndex) {
        this.tabTables[tableName].deleteRow(rowIndex)
    }
}