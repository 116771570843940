<template>
   <e-charts
      id="echarts"
      :options="options"
      :autoresize="true"
   />
</template>

<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/legendScroll';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/markLine';

export default {
   name: 'echartcard',
   components: {
      ECharts,
   },
   props: {
      options: Array,
   }

}
</script>

<style>
   #echarts {
      width: 100%;
      height: 100%;
   }
</style>