
<script>

import { Line } from 'vue-chartjs' 
import chartzoom from 'chartjs-plugin-zoom'


export default {
   extends: Line,

   props: {
      data: Array,
      label: String,
      options: Object,
      minX: Number,
   },

   mounted() {
      this.addPlugin(chartzoom)
      
      this.renderLineChart()
   },

   created() {
      //console.log(window.$data)
      //this.$bus.$on('resetZoom', this.reset)
      //this.$bus.$on('toggleZoom', this.toggleZoom)
      if(this.options != null)   
         this.chartOptions = this.options

      this.datasets = this.data
      this.chartOptions.scales.yAxes[0].scaleLabel.labelString = this.label
      //this.chartOptions.scales.yAxes[1].scaleLabel.labelString = this.label
      //this.chartOptions.scales.yAxes[1].ticks = this.chartOptions.scales.yAxes[0].ticks
      console.log(this)
   },

   updated(){
      console.log('updated')
   },

   data: ()=> ({
      isZoomEnabled: false,
      datasets: [
         {
            label: '',
            borderColor: '#4152bf',
            data: [],
            fill: "start",
            yAxisID: 'yAxis1',
            lineTension: 0.2,
         }, 
         // {
         //    label: '',
         //    borderColor: '#cc4545',
         //    data: [],
         //    fill: "start",
         //    yAxisID: 'yAxis2',
         //    lineTension: 0.2,
         // }
      ], 
      chartOptions: {
            responsive: true,
            maintainAspectRatio: false,

            scales: {
               yAxes: [
                  {
                     position: 'left',
                     id: 'yAxis1',
                     ticks: {
                        min: 0,
                        maxTicksLimit: 6,
                     },
                     scaleLabel: {
                        display: true,
                        labelString: ''
                     }
                  },
                  // {
                  //    position: 'right',
                  //    id: 'yAxis2',
                     
                  //    ticks: {
                  //       //maxTicksLimit: 6,
                  //       //beginAtZero: true,
                  //       max: null,
                  //       //display: false,
                  //    },
                  //    scaleLabel: {
                  //       display: true,
                  //       labelString: ''
                  //    }
                  // }
               ], 
               xAxes: [{
                  type: 'time',
                  // time: {
                  //    unit: 'month'
                  // }
                   ticks: {
                      autoSkip: true,
                      maxTicksLimit: 20
                   },
                  //labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
               }]
            },
            pan: {
               enabled: false,
               mode: "xy",
               speed: 10,
               threshold: 10,

               // Function called while the user is panning
               //onPan: function({chart}) { console.log(`I'm panning`); },
               // Function called once panning is completed
               //onPanComplete: function({chart}) { console.log(`I was panned`); }
            },
            zoom: {
               enabled: false,
               drag: false,
               mode: "x",
               limits: {
                  max: 10,
                  min: 0.5,
               },
               // Function called while the user is zooming
               // onZoom: function({chart}) { 

               // },
               // Function called once zooming is completed
               //onZoomComplete: function({chart}) { console.log(`I was zoomed!!!`); }
            },
            animation: {

            },
            // hover: {
            //    mode: 'x',
            //    intersect: false,
            // },
            tooltips: {
               // include lines of same x index
               mode: 'nearest',
               intersect: false,
            },
            legend: {
               labels: {
                  // This more specific font property overrides the global property
                  fontColor: 'black'
               }
            }
            
         },
   }),

   methods: {
      renderLineChart() {
         if (this.$data._chart) 
            this.$data._chart.destroy()

         this.renderChart({datasets: this.datasets}, this.chartOptions);      
      },
      reset() {
         this.$data._chart.resetZoom()
      },
      toggleZoom() {
         this.isZoomEnabled = !this.isZoomEnabled
         this.chartOptions.pan.enabled = this.isZoomEnabled
         this.chartOptions.zoom.enabled = this.isZoomEnabled
         this.renderLineChart()
      }
   },

   watch: {
      'data': {
         handler(val){
            //console.log('data')
           // console.log(val)
            this.datasets = val
            this.renderLineChart()
         },
         deep: true,
      },
   },
}

</script>