<template>
    <v-col cols="12" md="12" class="pa-0">
        <v-row class="summary-text">
            <v-btn class="btn-text" block icon-left text @click="show = !show">
                <v-icon>{{ show ? 'expand_less' : 'expand_more' }}</v-icon>
                {{ title }}
                <v-spacer />
                {{ formattedValue(value) }}
            </v-btn>
        </v-row>

        <div v-show="show" class="items summary-text">
            <div v-for="item of items" :key="item.id" class="dropdown-items">
                <v-row class="ma-0">
                    <v-col cols="12" md="6" class>
                        <span>{{ item.name }}</span>
                    </v-col>
                    <v-col cols="12" md="6">
                        <span class="float-right">{{ formattedValue(item.value) }}</span>
                    </v-col>
                </v-row>
                <!-- <v-divider v-if="i < items.length-1 " class="mx-2"/> -->
            </div>
        </div>
    </v-col>
</template>

<script>
export default {
    name: "summaryitem",

    props: {
        revenue: Boolean,
        title: String,
        value: String,
        currency: String,
        items: Array,
    },

    data: () => ({
        show: false,
    }),

    created() {
        //console.log('item created')
        //console.log(this.revenue)
        if (this.revenue == undefined) this.revenue = false;
    },

    methods: {
        formattedValue(value) {
            let val = this.$thousandSeperator(Math.round(value));
            let presufVal = `${
                this.currency.match(/[$€£]/) ? this.currency : ""
            } ${val} ${this.currency.match(/[^$€£]/) ? this.currency : ""}`;
            return !this.revenue ? `${value > 0 ? '-' : ''} ${presufVal}` : presufVal;
        },
    },
};
</script>

<style lang="scss">
.btn-text {
    font-size: 0.8rem !important;
    font-weight: 350;
    text-transform: none;
    letter-spacing: 0px;
}

.dropdown-items {
    &:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.144);
    }
    background: rgba(0, 0, 0, 0.541);
}
</style>