<template>
	<tr>
		<td>{{ item.name }}</td>
		<td class="text-right">
			<v-btn color="primary" icon @click="editMarketingType(item.name, item.marketingid)">
				<v-icon>edit</v-icon>
			</v-btn>
			<v-btn
				color="error"
				:loading="isDeleteMarketingTypePending"
				@click="deleteMarketingType(item.marketingid, index)"
				icon
			>
				<v-icon>remove_circle_outline</v-icon>
			</v-btn>
		</td>
	</tr>
</template>

<script>
export default {
	name: "marketingtype",
	props: {
        item: Object,
        index:Number
    },
    data:()=>({
        isDeleteMarketingTypePending:false
    }),
    created(){
        this.$bus.$on('unSetIsDeleteMarketingTypePending', function(){ this.isDeleteMarketingTypePending = false})
    },
    methods:{
        deleteMarketingType(id, index){
            this.isDeleteMarketingTypePending = true
            this.$emit('deleteMarketingType', id, index)
        },
        editMarketingType(name, marketingid){
            this.$emit('editMarketingType', name, marketingid)
        }
    }
};
</script>

<style lang="scss" scoped>

tr:hover{
    background:#eeeeee70!important;
}

</style>