<template>
	<div>
		<v-overlay v-if="isCurrentlyUpdating">
			<v-card  color="" min-width="250">
				<v-card-text class="wizard-overlay-title pa-5">
					{{ savingText }}
					<v-icon color="success" v-if="savingText.includes('saved')">done</v-icon>
				</v-card-text>
				<v-progress-linear v-if="savingText.includes('saving')" indeterminate></v-progress-linear>
			</v-card>
		</v-overlay>
		<v-col class="float-left" cols="12" md="12">
			<v-progress-linear :active="!isStepLoaded" indeterminate />
			<div class="float-left" style="width:100%;" v-show="isStepLoaded">
				<budget-step-one 
					v-if="page == 1 && isStepFiveInit"
					:stepData="stepOneData"
					@isLoaded="isStepLoaded = true"
					@addNewShow="addNewShowToStepOne"
					@addTicketRevenue="addTicketRevenue"
					@addOtherRevenue="addOtherRevenue"
				/>
				<budget-step-two
					v-if="page == 2 && isStepFiveInit"
					:stepData="stepTwoData"
					@isLoaded="isStepLoaded = true"
					@addCostItem="addCostItem"
				/>
				<budget-step-three
					v-if="page == 3 && isStepFiveInit && concertDate "
					:stepData="stepThreeData"
					:concertDate="concertDate"
					@isLoaded="isStepLoaded = true"
					@addCostItem="addCostItem"
					@crewCallsUpdated="updateCrewCalls"
				/>
				<budget-step-four
					v-if="page == 4 && isStepFiveInit"
					:stepData="stepFourData"
					@isLoaded="isStepLoaded = true"
					@addCostItem="addCostItem"
				/>
				<budget-step-five
					v-if="page == 5 && isStepFiveInit"
					:stepData="stepFiveData"
					@isLoaded="isStepLoaded = true"
					@addCostItem="addCostItem"
				/>
				<budget-step-six
					v-if="page == 6 && isStepFiveInit"
					:stepData="stepSixData"
					@isLoaded="isStepLoaded = true"
					@addCostItem="addCostItem"
				/>
			</div>
		</v-col>
		<v-col class="pa-3 mb-5 float-left" cols="12" md="12">
			<v-row>
				<v-col cols="12" md="12" lg="12" class="d-flex align-center justify-center">
					<v-pagination
						class="float-left"
						:class="{'mx-auto':$vuetify.breakpoint.mdAndDown}"
						v-model="pager"
						:length="length"
					/>
				</v-col>
				<v-col cols="12" md="12" lg="12" class="d-flex">
					<budget-button-menu 
						:budgetData="budgetData"
						class="float-left"
						:exportTitle="budgetData.concertid.title"
						:userid="userid"
						:savingAllowed="true"
						:exportEnabled="true"
						@saveBudget="saveBudget"
						@saveNewBudget="saveNewBudget"
						@sendForApproval="sendForApproval"
						@setApprovedOrDeclined="setApprovedOrDeclined"
					/>
				</v-col>
			</v-row>
		</v-col>
	</div>
</template>

<script>
import { getStepArray, stickyCosts, getAllRows } from '@/models/venue_budget_template_rows.js';
import BudgetButtonMenu from '../Shared/BudgetButtonMenu';
import BudgetStepOne from './Step1/ConcertStepOne';
import BudgetStepTwo from './Step2/ConcertStepTwo';
import BudgetStepThree from './Step3/ConcertStepThree';
import BudgetStepFour from './Step4/ConcertStepFour';
import BudgetStepFive from './Step5/ConcertStepFive';
import BudgetStepSix from './Step6/ConcertStepSix';
import { BudgetPostItem } from '@/models/BudgetObjects.js';
import { calcPrs } from '../ConcertBudgetWizard/Shared/PrsTypes.js';
import { step3 } from '../../TourPage/tour_budget_template_rows';

export default {
	name: 'FormWizard',

	components:{
		BudgetButtonMenu,
		BudgetStepOne,
		BudgetStepTwo,
		BudgetStepThree,
		BudgetStepFour,
		BudgetStepFive,
		BudgetStepSix,
	},

	props: {
		budgetData:Array,
		concertDate:String,
		dataInSteps: Object,
		savingAllowed: Boolean,
		calculationsDone: Boolean,
		userid: String,
	},
	data: () => ({
		page: 1,
		pager: 1,
		length: 6,

		firstLoad: true,
		
		isStepTwoInit: false,
		isStepFiveInit: false,

		isCurrentlyUpdating: false,
		savingText: 'Budget is saving...',

		stepOneData: {
			tickets: [],
			otherRevenues: [],
			deletedTickets: [],
			deletedOtherRevenues: [],
			generalData: {
				nbrOfShows: 0,
				coSplit: {
					active: 0,
					value: 0
				}
			},
		},
		stepTwoData: {
			costs: [],
			deletedCosts: [],
			tickets: [],
		},
		stepThreeData: {
			budget_crew_calls: {},
			deletedCrewCalls: [],
			costs: [],
			deletedCosts: [],
		},
		stepFourData: {
			costs: [],
			deletedCosts: [],
		},
		stepFiveData: {
			costs: [],
			deletedCosts: [],
			userGroups: [],
			isPrivate: false,
			selectedGroup: '',
		},
		stepSixData: {
			costs: [],
			specialCosts: [],
			deletedCosts: []
		},

		isChanged: [false,false,false,false,false,false],
		dataIsReady: false,
		updateSaveButton: 0,

		stepOneOriginal: '',
		stepTwoOriginal: '',
		stepThreeOriginal: '',
		stepFourOriginal: '',
		stepFiveOriginal: '',
		stepSixOriginal: '',

		totalTickets: 0,
		ticketSales: 0,
		netRevenue: 0,
		grossRevenue: 0,
		netGross: 0,
		totalCosts: 0,
		totalVat: 0,
		totalTicketCommission: 0,

		showConfirmDialog: false,
		confirmDialogText: '',
		confirmDialogSubText: '', 
		confirmButtonText: '',
		confirmSecondaryButtonText: '',
		confirmDenyButtonText: '',
		showApprovalDialog: false,

		approvedUsers: [],

		ticketsToBreakEven: 0,
		step3InitDone: false,
		isStepLoaded: true,
	}),

	async created(){
		// console.log(this.concertDate)
		this.approvedUsers = this.budgetData.dropdowns.users

		this.$bus.$on('requestBudgetData', this.sendBudgetData)

		if(this.wizardPages != undefined) {
			this.pages = this.wizardPages.length
			this.length = this.wizardPages.length
		}
		
		//// console.log('budgetData:')
		//// console.log(this.budgetData)
		//this.budgetData.budget_rows.map(c => c.display_name = c.name)
		await this.initStepOneData(this.budgetData)
		await this.initStepTwoToSixData(this.budgetData)
		
		////// console.log('wizard created done')
		

		this.isStepTwoInit = true
		this.isStepFiveInit = true
		this.triggerUpdates()

		// copy over originals
		this.stepOneOriginal = JSON.stringify(this.stepOneData)
		this.stepTwoOriginal = JSON.stringify(this.stepTwoData)
		this.stepThreeOriginal = JSON.stringify(this.stepThreeData)
		this.stepFourOriginal = JSON.stringify(this.stepFourData)
		this.stepFiveOriginal = JSON.stringify(this.stepFiveData)
		this.stepSixOriginal = JSON.stringify(this.stepSixData)
		this.dataIsReady = true

	},

	mounted(){
		
	},

	watch: {
		pager(n){
			console.log('page change')
			this.isStepLoaded = false;
			setTimeout(() => {
				this.page = n;
				console.log('changing page', n);
			}, 100);
		},
		// 'budgetData':{
		//    handler(val){
		//       // console.log('budgetData Watcher')
		//       this.triggerUpdates()
		//    },
		//    deep: true
		// },
		'stepOneData':{
			handler(val){
				JSON.stringify(val) != this.stepOneOriginal && this.dataIsReady ? this.isChanged[0] = true : this.isChanged[0] = false
				this.triggerUpdates()
			},
			deep: true
		},
		'stepTwoData':{
			handler(val){
				JSON.stringify(val) != this.stepTwoOriginal && this.dataIsReady ? this.isChanged[1] = true : this.isChanged[1] = false
				this.triggerUpdates()
			},
			deep: true
		},
		'stepThreeData':{
			handler(val){
				console.log('step3 trigger')
				JSON.stringify(val) != this.stepThreeOriginal && this.dataIsReady ? this.isChanged[2] = true : this.isChanged[3] = false
				//if(this.step3InitDone)
				this.triggerUpdates()
			},
			deep: true
		},
		'stepFourData':{
			handler(val){
				JSON.stringify(val) != this.stepFourOriginal && this.dataIsReady ? this.isChanged[3] = true : this.isChanged[3] = false
				this.triggerUpdates()
			},
			deep: true
		},
		'stepFiveData':{
			handler(val){
				JSON.stringify(val) != this.stepFiveOriginal && this.dataIsReady ? this.isChanged[4] = true : this.isChanged[4] = false
				this.triggerUpdates()
			},
			deep: true
		},
		'stepSixData':{
			handler(val){
				JSON.stringify(val) != this.stepSixOriginal && this.dataIsReady ? this.isChanged[5] = true : this.isChanged[5] = false
				this.triggerUpdates()
			},
			deep: true
		}
	},

	methods: {
		/** 
		 * Initializes the data for the first step in the budget wizard.
		 * This is ticket data + other revenues
		 */
		async initStepOneData(data){
			if(data.budget_tickets){
				for(let ticket of data.budget_tickets){
					
					ticket = await this.initTicket(ticket)
	
					if(ticket.show_number > 0){
						if(this.stepOneData.tickets[ticket.show_number-1] == undefined)
							this.stepOneData.tickets.push([])
						
						this.stepOneData.tickets[ticket.show_number-1].push(ticket)
					}
				}
			}else
				this.stepOneData.tickets.push([])
			
			for(let otherRevenue of data.budget_rows){
				if(otherRevenue.budget_row_typeid == 0)
					this.stepOneData.otherRevenues.push(otherRevenue)
			}
			this.stepOneData.generalData.nbrOfShows = this.stepOneData.tickets.length
			this.stepOneData.generalData.coSplit.value = data.co_produced_split
			if(data.co_produced_split > 0)
				this.stepOneData.generalData.coSplit.active = 1
		},

		checkForMissingDefaultCosts(data){
			let defaultRows = getAllRows();
			let newObj = {};
			let finalRows = [];
			for(let row of data.budget_rows){
				newObj[row.name] = row;
				if(row.account_code == null && row.name == row.display_name){
					// check if a default should replace row
					let found = defaultRows.find(defRow => defRow.name == row.name || defRow.name == row.name.toLowerCase() || defRow.name.includes(row.name.toLowerCase()) || row.name == 'Crew calls');
					if(!found)
						finalRows.push(row);
				}
			}

			for (let row of defaultRows) {
				const real = newObj[row.name];
				if(real){
					for(const KEY in real)
						row[KEY] = real[KEY];
				}
				finalRows.push(row);
			}
			return finalRows;
		},

		async initStepTwoToSixData(data){
			let foundCrewCall = false;
			let stickyC = stickyCosts;


			// check if new default budget rows should be present
			let budget_rows = this.checkForMissingDefaultCosts(data);

			budget_rows.map(myCost => {
				let cost = JSON.parse(JSON.stringify(myCost))
				if(stickyCosts.includes(cost.name)){
					cost.sticky = true
					stickyC.splice(stickyC.indexOf(cost.name), 1)
				}
				
				switch (+cost.step) {
					case 2:
						this.findCostDisplayName(cost, getStepArray(2))
						this.stepTwoData.costs.push(cost)
						break;
					case 3:
						this.findCostDisplayName(cost, getStepArray(3))
						this.stepThreeData.costs.push(cost)
						if(cost.header == 'Crew call')
							foundCrewCall = true;
						break;
					case 4:
						this.findCostDisplayName(cost, getStepArray(4))
						this.stepFourData.costs.push(cost)
						break;
					case 5:
						this.findCostDisplayName(cost, getStepArray(5))
						this.stepFiveData.costs.push(cost)
						break;
					case 6:
						this.findCostDisplayName(cost, getStepArray(6))
						if(cost.name == 'production_invoices' || cost.name == 'promoter_profit_leveller_on_production_invoices')
							this.stepSixData.specialCosts.push(cost)
						else
							this.stepSixData.costs.push(cost)
						break;
					default:
						break;
				}
			})

			this.initCrewCalls(data, foundCrewCall);

			// give tickets to step2 for PRS
			this.stepTwoData.tickets = this.stepOneData.tickets

			this.addMissingStickyCosts(stickyC)

			if(this.budgetData.private_userid.length > 0)
				this.stepFiveData.isPrivate = true

			for(let group of this.budgetData.dropdowns.accessgroups)
				this.stepFiveData.userGroups.push(group.accessgroup)

			if(this.budgetData.private_accessgroup.accessgroup != undefined)
				this.stepFiveData.selectedGroup = this.budgetData.private_accessgroup.accessgroup

			this.sortForProfitAndLossStatement()
			//// console.log(this.getFullCostsList())
		},

		sortForProfitAndLossStatement(){
			// sort step 2 for profit and loss statement
			this.shiftInsideArr(this.stepTwoData.costs, 'vat', 0)
			this.shiftInsideArr(this.stepTwoData.costs, 'ticket_commission', 1)
			this.shiftInsideArr(this.stepTwoData.costs, 'royalty', 2)


			// sort step 3 for profit and loss statement
			let tmpThree = [[],[],[],[],[],[]]
			for(let cost of this.stepThreeData.costs){
				if(cost.header == 'Crew call')
					tmpThree[0].push(cost);
				if(cost.header == 'Venue related')
					tmpThree[1].push(cost);
				if(cost.header == 'Staff related')
					tmpThree[2].push(cost);
				if(cost.header == 'Production related')
					tmpThree[3].push(cost);
				if(cost.header == 'Catering')
					tmpThree[4].push(cost);
				if(cost.header == 'Authority costs')
					tmpThree[5].push(cost);
			}

			this.stepThreeData.costs = tmpThree[0].concat(tmpThree[1].concat(tmpThree[2].concat(tmpThree[3].concat(tmpThree[4].concat(tmpThree[5])))))
		},

		shiftInsideArr(arr, name, to) {
			let ticketCom = this.stepTwoData.costs.find(c => c.name == name)
			if(ticketCom){
				let cutOut = arr.splice(arr.indexOf(ticketCom), 1) [0];
				arr.splice(to, 0, cutOut); 
			}
		},

		addMissingStickyCosts(stickyC){
			let allSteps = []
			for(let i = 2; i < 7; i++){
				getStepArray(i).map(c => allSteps.push(c))
			}
			  // allSteps.concat(getStepArray(i))
			stickyC.map(c => {
				let foundCost = allSteps.find(cost => c == cost.name)
				if(foundCost != undefined) {
					// console.log(foundCost)
					let newCost = new BudgetPostItem(foundCost.display_name, foundCost.name, 1, 0, foundCost.step, foundCost.header, foundCost.sticky, 0, 0)

					switch (+newCost.step) {
						case 2:
							this.stepTwoData.costs.push(newCost)
							break;
						case 3: // no stickys here for now
							this.stepThreeData.costs.push(newCost)
							break;
						case 4: // no stickys here for now
							this.stepFourData.costs.push(newCost)
							break;
						case 5:
							this.stepFiveData.costs.push(newCost)
							break;
						case 6:
							if(newCost.name == 'production_invoices' || newCost.name == 'promoter_profit_leveller_on_production_invoices')
								this.stepSixData.specialCosts.push(newCost)
							else
								this.stepSixData.costs.push(newCost)
							break;
						default:
							break;
					}
				}

			})
		},


		/**
		 * Find and set the display_name of an official cost
		 * If the cost is a custom one, just use its db name
		 */
		async findCostDisplayName(cost, realCostArr){
			for(let realCost of realCostArr){
				if(realCost.name == cost.name){
					cost.display_name = realCost.display_name
					cost.editableName = false
				}
			}
			if(cost.editableName == undefined)
				cost.editableName = true
			// if(cost.display_name == undefined)
			//    cost.display_name = cost.name

			//return cost
		},

		/**
		 * Initializes a ticket object. 
		 * Will handle both an existing ticket from the database,
		 * but also a brand new ticket created from the wizard
		 */
		async initTicket(ticket){
			let newTicket = ticket
			if(ticket.venue_ticketid == undefined){

				if(ticket.sellable_percentage == undefined)
					newTicket.sellable_percentage = '1.0'
				
				newTicket.sellable_capacity = ticket.capacity - ticket.kills - ticket.comps - ticket.other
				newTicket.kills = (ticket.kills == null) ? 0 : ticket.kills
				newTicket.comps = (ticket.comps == null) ? 0 : ticket.comps
				newTicket.other = (ticket.other == null) ? 0 : ticket.other
				newTicket.vat = (ticket.vat == null) ? 0 : ticket.vat
				newTicket.commission = (ticket.commission == null) ? 0 : ticket.commission
				newTicket.comment = (ticket.comment == null) ? '' : ticket.comment
				newTicket.revenues = ticket.sellable_capacity * ticket.sellable_percentage * ticket.price
			} else {

				newTicket = {
					section_name: ticket.section_name,
					price: ticket.default_price,
					capacity: ticket.default_capacity,
					kills: 0,
					comps: 0,
					other: 0,
					sellable_capacity: 0,
					sellable_percentage: '1.0',
					vat: 0,
					commission: 0,
					comment: '',
					show_number: this.stepOneData.tickets.length+1,
				}

			}
			return newTicket
		},

		async addNewShowToStepOne(){
			let venueid = await this.getVenueId()

			let i = this.stepOneData.tickets.length
			//this.stepOneData.tickets[i] = []
			
			if(i > 0){
				let copyTickets = [];
				for(let ticket of this.stepOneData.tickets[0]){
					let copy = JSON.parse(JSON.stringify(ticket));
					copy.budget_ticketid = undefined;
					copy.budgetid = undefined;
					copy.show_number = i+1,
					copyTickets.push(JSON.parse(JSON.stringify(copy)));
				}
				this.$set(this.stepOneData.tickets, i, copyTickets);
				this.stepOneData.generalData.nbrOfShows++

			}else{
				try{
					let response = await this.$GetService(this.$getSubRoute('venues', 'tickets') + '?venueid=' + venueid)
					if(response.status == 200){
						//this.stepOneData.tickets[i] = []
						let venueTickets = []
						for(let ticket of response.data){
							venueTickets.push(await this.initTicket(ticket))
						}
						this.$set(this.stepOneData.tickets, i, venueTickets)
						this.stepOneData.generalData.nbrOfShows++
					}else
						this.stepOneData.tickets.push([]);
				}catch(error){
					// console.log(error)
				}
			}
			
			
		},

		addTicketRevenue(showNumber){
			let ticket = {
				section_name: '',
				price: 0,
				capacity: 0,
				kills: 0,
				comps: 0,
				other: 0,
				sellable_percentage: '1.0',
				sellable_capacity: 0,
				comment: '',
				show_number: showNumber,
				vat: 0,
				commission: 0,
				platinum: 0
			}
			this.stepOneData.tickets[showNumber-1].push(ticket)
			//this.triggerUpdates()
		},

		addOtherRevenue(){
			let otherRevenue = {
				name: '',
				value: 0,
				step: '1',
				header: 'Other revenues',
				comment: '',
				budget_row_typeid: '0',
				vat: 0,
			}
			this.stepOneData.otherRevenues.push(otherRevenue)
		},

		addCostItem(step, header){
			let costItem = new BudgetPostItem()
			costItem.step = step
			costItem.header = header
			costItem.editableName = true

			switch (+step) {
				case 2:
					this.stepTwoData.costs.push(costItem)
					break;
				case 3: // no stickys here for now
					this.stepThreeData.costs.push(costItem)
					break;
				case 4: // no stickys here for now
					this.stepFourData.costs.push(costItem)
					break;
				case 5:
					this.stepFiveData.costs.push(costItem)
					break;
				case 6:
					this.stepSixData.costs.push(costItem)
					break;
				default:
					break;
			}
			// console.log(this.stepThreeData)
		},

		initCrewCalls(data, skipNewCost){

			let step3Crew = this.stepThreeData.budget_crew_calls;
			let i = 0;

			if(data.budget_crew_calls){
				for(let crew of data.budget_crew_calls){
					if(!step3Crew[crew.type])
						step3Crew[crew.type] = [];
					let crewCopy = JSON.parse(JSON.stringify(crew));
					crewCopy.crew_per_day = crewCopy.crew_per_day.split(';');
					crewCopy.id = i++;
					step3Crew[crew.type].push(crewCopy);
				}
			}

			if(skipNewCost) return;

			let crewCost = new BudgetPostItem();
			crewCost.step = 3;
			crewCost.header = 'Crew call';
			crewCost.name = crewCost.display_name = 'Crew calls';
			crewCost.budget_row_typeid = 5;
			delete crewCost.venueid;
			delete crewCost.venue_budget_rowid;
			delete crewCost.active;
			this.stepThreeData.costs.push(crewCost);
			// console.log(this.stepThreeData);
		},

		triggerUpdates(){
			this.updateSaveButton == 0 ? this.updateSaveButton = 1 : this.updateSaveButton = 0
			if(this.dataIsReady){
				this.$emit('changesMade', true)
			}
			if(this.isStepFiveInit) {
				this.ticketCapacityUpdate()
				this.ticketSalesUpdate()
				this.revenuesUpdate()
				//this.netGrossUpdate()
				this.costsUpdate()
				this.artistShare()
				this.calcBreakEven()

				if(this.firstLoad){
					this.$emit('isLoaded')
					this.firstLoad = false
				}
			}
		},

		updateCrewCalls(crewCalls, type){
			// console.log('updating crew calls');
			// console.log(crewCalls);
			this.stepThreeData.budget_crew_calls[type] = [];
			for(let call of crewCalls){

				call.sum = 0;
				for(let day of call.crew_per_day)
					if(day > 0) call.sum += +day * +call.price;

				this.stepThreeData.budget_crew_calls[type].push(JSON.parse(JSON.stringify(call)));
			}
			// console.log(this.stepThreeData);
			//this.stepThreeData.budget_crew_calls[type] = JSON.parse(JSON.stringify(crewCalls));
			
			this.triggerUpdates();
			this.$bus.$emit('updateBudgetSummary', false);
		},

		ticketCapacityUpdate(){
			//// console.log('tickets updated')
			this.totalTickets = 0;
			this.sellable_capacity_budget = 0;
			for(let show of this.stepOneData.tickets){
				// console.log('ticketCapcityUpdate')
				// console.log(show);
				
				show.map(ticket => {
					ticket.sellable_capacity = ticket.capacity - ticket.kills - ticket.comps - ticket.other;
					this.sellable_capacity_budget += ticket.sellable_capacity;
					this.totalTickets += ticket.sellable_capacity * ticket.sellable_percentage
				})
				
				//////// console.log(`+${ticket.sellable_capacity} (${totalTickets})`)
			}
			////// console.log(`this.totalTickets: ${this.totalTickets}`)
			//// console.log('tickets updated DONE')
		},

		ticketSalesUpdate(){
			//// console.log('ticketSales updated')
			this.ticketSales = 0
			// console.log('ticketSalesUpdate 2')
			for(let show of this.stepOneData.tickets){
				show.map(ticket => {
					ticket.revenues = ticket.sellable_capacity * ticket.sellable_percentage * ticket.price
					this.ticketSales += +ticket.revenues
				})
				
				
				//////// console.log(`+${ticket.sellable_capacity} (${totalTickets})`)
			}
			////// console.log(`this.ticketSales: ${this.ticketSales}`)
			//// console.log('ticketSales updated DONE')
		},

		revenuesUpdate(taxed = true){
			// console.log('####revenues updated####')
			this.grossRevenue = 0
			this.netRevenue = 0
			this.totalVat = 0
			this.totalTicketCommission = 0

			for(let show of this.stepOneData.tickets){
				show.map(ticket => {
					if(!ticket.revenues)
						ticket.revenues = 0
					this.grossRevenue += +ticket.revenues
					this.netRevenue += +ticket.revenues - this.getVatValue(ticket.revenues, ticket.vat)
					ticket.price_ex_vat = +ticket.price - this.getVatValue(ticket.price, ticket.vat);
					this.totalVat += this.getVatValue(ticket.revenues, ticket.vat)
					// console.log('#### ticket commission calc')
					// console.log(ticket);
					// console.log(ticket.section_name);
					// console.log(ticket.revenues);
					// console.log(this.getVatValue(ticket.revenues, ticket.vat));
					// console.log(ticket.commission / 100);
					this.totalTicketCommission += (ticket.revenues - this.getVatValue(ticket.revenues, ticket.vat)) * (ticket.commission / 100)
				})
				//////// console.log(`+${ticket.revenues} (${netRevenue})`)
			}
			for(let rev of this.stepOneData.otherRevenues){
				if(rev.budget_row_typeid == 0){
					this.grossRevenue += +rev.value
					this.netRevenue += +rev.value - this.getVatValue(rev.value, rev.vat)
					this.totalVat += this.getVatValue(rev.value, rev.vat)
					//////// console.log(`+${rev.value} (${netRevenue})`)
				}
			}
		},

		costsUpdate(){
		  // // console.log('#### costsUpdate ####')
			this.totalCosts = 0
			let costs = this.stepTwoData.costs.concat(
				this.stepThreeData.costs).concat(
					this.stepFourData.costs).concat(
							this.stepSixData.costs).concat(
								this.stepSixData.specialCosts)
			
			// console.log('all costs');
			// console.log(costs)
			this.totalCosts = this.calcCost(costs)
		  // // console.log(`totalCosts: ${+this.totalCosts}`)
			//step5
			this.totalCosts += this.calcGage(this.stepFiveData.costs)
			//this.totalCosts += this.calcCost(this.stepFiveData.costs)

		  // this.totalCosts += this.calcCost(this.stepTwoData.costs)
			
		  // // console.log(`costsUpdate DONE`) 
			

		},

		netGrossUpdate(){
			this.netGross = 0
			let variableCosts = 0
			//this.stepTwoData.costs.map(c => c.name != 'prs' ? variableCosts += c.calculated_cost : null)
			this.stepTwoData.costs.map(c => {
				variableCosts += c.calculated_cost
				// console.log('variable cost');
				// console.log(c);
			} )
			// // console.log(`grossRevenue: ${+this.grossRevenue}`)
			// // console.log(`variableCost: ${+variableCosts}`)
			this.netGross = this.grossRevenue - variableCosts
			//// console.log(`netGross: ${+this.netGross}`)
		},

		calcCost(costs, skip = -1){
			let tmpCosts = 0
			let ticketCom = 0;

			// console.log('calcCost')

			costs.map(c => {
				let val = 0

				if(c.name == 'vat') {
					val = this.totalVat
					// console.log('TOTAL VAT')
					// console.log(val);
					c.value = 0; // not used
				}
				else if(c.name == 'ticket_commission'){
					//// console.log('ticketCom ###########')
				  // val = (+c.value / 100) * this.netRevenue
					//ticketCom = c
					val = this.totalTicketCommission
				}
				else if(c.name == 'royalty'){
					//// console.log('royalty')
					//// console.log(`(${c.value / 100}) * (${this.netRevenue} - ${ticketCom.calculated_cost})`)
					val = (+c.value / 100) * (this.netRevenue - this.totalTicketCommission)
				}
				// else if(c.name == 'variable_gage_share_of_net'){
				// 	this.$logger('grossRevenue - this.totalCosts', this.grossRevenue - this.totalCosts)
				// 	val = (c.value/100) * (this.grossRevenue - this.totalCosts) // calc on global totalCosts  SHOULD VARIABLE COST BE EXCLUDED HERE (CURRENTLY IT IS NOT)
				// 	//// console.log((c.value/100) * (this.netGross - this.totalCosts))
				// } 
				else if(c.name == 'prs'){
					val = calcPrs(c.value, this.stepOneData.tickets)
					c.calculated_cost = Math.round(val)
					this.netGrossUpdate() // do this now because step 5 calcCost needs this.netGross
				}
				else {
					switch (+c.budget_row_typeid) {
						case 1:
							val = +c.value
							break;
						case 2:
							val = +c.value * +this.totalTickets
							break;
						case 3:
							val = (+c.value / 100) * this.netRevenue
							break;
						case 4: // maybe remove
							val = (+c.value / 100) * this.ticketSales
							break;
						case 5: // crew call
							val = this.calcCrewCostSum();
							c.value = val;
							break;
						default:
							break;
					}
				}

				// optional skip to add budget_row values to returned cost. Is probably not needed
				if(c.step != skip)
					tmpCosts += val
				

				c.calculated_cost = Math.round(val)
			})

			return tmpCosts
		},

		calcCrewCostSum(){
			// crew calls
			let step3Crew = this.stepThreeData.budget_crew_calls;
			let crewCallCost = 0;
			for(const KEY in step3Crew){
				// console.log('## crew calls ##########')
				// console.log(KEY)
				for(let crew of step3Crew[KEY]){
					crewCallCost += +crew.sum;
				}

			}
			// console.log('TOTAL CREW CALL COST', crewCallCost);

			return crewCallCost;
		},

		calcGage(gages){
			let fixed = 0;
			let shareOfNet = 0;
			let shareOfGross = 0;
			let shareOfTickets = 0;
			for(const GAGE of gages){
				switch(GAGE.name){
					case 'fixed_gage_minimum_gage':
						fixed = GAGE.value;
						GAGE.calculated_cost = Math.round(fixed);
						break;
					case 'variable_gage_share_of_net':
						shareOfNet = (GAGE.value/100) * (this.grossRevenue - this.totalCosts)
						GAGE.calculated_cost = Math.round(shareOfNet);
						break;
					case 'variable_gage_share_of_gross':
						shareOfGross = (+GAGE.value / 100) * this.netRevenue;
						GAGE.calculated_cost = Math.round(shareOfGross);
						break;
					case 'variable_gage_sek_ticket':
						shareOfTickets = +GAGE.value * +this.totalTickets;
						GAGE.calculated_cost = Math.round(shareOfTickets);
						break;
				}
			}
			let largest = fixed;
			if(largest < shareOfNet)
				largest = shareOfNet;
			else if(largest < shareOfGross)
				largest = shareOfGross;
			else if(largest < shareOfTickets)
				largest = shareOfTickets;

			return largest;
		},

		calcBreakEven(){
			let rows = this.getFullCostsList();
			let tickets = [];
			this.stepOneData.tickets.map(show => {
				show.map(ticket => tickets.push(ticket));
			})
			// console.log(rows);
			// console.log(tickets)
			
			let totalTicketCapacity = 0
			let totalTicketPrice = 0
			let totalTicketGross = 0
			let commission = 0;
			let ticketCapacity = 0;
			tickets.map(t => {
				totalTicketCapacity += (t.capacity - t.kills - t.comps - t.other) * t.sellable_percentage
				totalTicketPrice += t.price_ex_vat;
				commission = t.price_ex_vat * (t.commission / 100)
				ticketCapacity = t.capacity - t.kills - t.comps - t.other
				totalTicketGross += (t.price_ex_vat - commission) * ticketCapacity * t.sellable_percentage
			})

			let avgTicketPrice =  totalTicketGross / totalTicketCapacity
			let artistFixedShare = 0
			let artistShareOfNet = 0
			let artistShareOfNetPercentage = 0
			let artistShareOfGross = 0
			let artistShareOfGrossPercentage = 0
			let artistShareOfTicket = 0
			let variableCostPerTicket = 0
			let fixedCosts = 0

			rows.map(cost => {
				if(cost.name == 'vat' || cost.name == 'ticket_commission') return;

				if(cost.name == 'fixed_gage_minimum_gage')
					artistFixedShare = +cost.value
				else if(cost.name == 'variable_gage_share_of_net'){
					artistShareOfNet = (+cost.value / 100) * (+this.budgetData.net_gross - +this.budgetData.total_costs)
					artistShareOfNetPercentage = +cost.value / 100
				}
				else if(cost.name == 'variable_gage_share_of_gross'){
					artistShareOfGross = (+cost.value / 100) * +this.budgetData.gross_ex_vat
					artistShareOfGrossPercentage = (+cost.value / 100)
				}
				else if(cost.name == 'variable_gage_sek_ticket')
					artistShareOfTicket = +cost.value
				else if(cost.name == 'prs'){
					let prsCost = calcPrs(cost.value, tickets)
					variableCostPerTicket += prsCost / totalTicketCapacity
				}
				else if(+cost.budget_row_typeid == 1 && +cost.step != 2){
					fixedCosts += +cost.value
				}
				else if(+cost.budget_row_typeid == 2)
					variableCostPerTicket += +cost.value
				else if(+cost.budget_row_typeid == 3)
					variableCostPerTicket += (+cost.value / 100) * avgTicketPrice
			})
			// without variable artist deal
			let ticketMargin = avgTicketPrice - variableCostPerTicket;
			let ticketsToBreakEvenFixedShare = (fixedCosts + artistFixedShare) / ticketMargin
			let fixedCostPerTicket = fixedCosts / totalTicketCapacity
			let ticketsToEnableVariableShareOfGross = 0;
			let ticketsToEnableVariableShareOfTickets = 0;
			this.$logger('artistFixedShare', artistFixedShare);
			this.$logger('fixedCosts', fixedCosts);

			if(artistFixedShare > 0){
				ticketsToEnableVariableShareOfGross = artistFixedShare / ((avgTicketPrice - variableCostPerTicket) * artistShareOfGrossPercentage)
				ticketsToEnableVariableShareOfTickets = artistFixedShare / artistShareOfTicket;
			} else {
				ticketsToEnableVariableShareOfGross = fixedCosts / (ticketMargin * artistShareOfGrossPercentage)
				ticketsToEnableVariableShareOfTickets = fixedCosts / (ticketMargin - artistShareOfTicket);
			}
			this.$logger('ticketsToBreakEvenFixedShare', ticketsToBreakEvenFixedShare);
			this.$logger('ticketsToEnableVariableShareOfGross', ticketsToEnableVariableShareOfGross);
			this.$logger('ticketsToEnableVariableShareOfTickets', ticketsToEnableVariableShareOfTickets);
			let highestVariable = ticketsToEnableVariableShareOfGross

			if(highestVariable < ticketsToEnableVariableShareOfTickets){
				highestVariable = ticketsToEnableVariableShareOfTickets
			}
			
			this.ticketsToBreakEven = highestVariable
			// only check with breakEvenFixedShare if artistFixedShare is set
			if(artistFixedShare > 0){
				if(highestVariable > ticketsToBreakEvenFixedShare)
					this.ticketsToBreakEven = ticketsToBreakEvenFixedShare
				else
					this.ticketsToBreakEven = highestVariable
			}

			this.$logger('this.ticketsToBreakEven', this.ticketsToBreakEven)

			this.ticketsToBreakEven = Math.round(this.ticketsToBreakEven);
		},

		sendBudgetData(){
			// console.log('sending budget data')
			
			this.$bus.$emit('receiveBudgetData', {
					stepOne: this.stepOneData, stepTwo: this.stepTwoData,
					stepThree: this.stepThreeData, stepFour: this.stepFourData,
					stepFive: this.stepFiveData, stepSix: this.stepSixData
				}
			)
			//this.$bus.$emit('updateBudgetSummary')
		},

		getVatValue(value, rate){
			//// console.log(`getTaxed(${value}, ${rate})`)
			return +value * (1-1/(1+rate/100))
		},

		getTaxPercent(){
			return this.stepTwoData.costs.find(c => c.name == 'vat').value
		},

		artistShare(){
			let cost = this.stepFiveData.costs.find(c => c.name == 'variable_gage_share_of_net')
			if(cost != undefined){
				////// console.log('artistShare cost found')
				////// console.log(cost)
				this.$bus.$emit('artistShareUpdated', cost.value)
			}
		},

		getFullCostsList(){
			return this.stepTwoData.costs.concat(
				this.stepThreeData.costs).concat(
					this.stepFourData.costs).concat(
						this.stepFiveData.costs).concat(
							this.stepSixData.costs).concat(
								this.stepSixData.specialCosts)
		},

		async getVenueId(){
			if(this.budgetData.concertid.concertid != undefined){
				try{
					let response = await this.$GetService(this.$getRoute('concerts').main_route + '?id=' + this.budgetData.concertid.concertid)
					if(response.status == 200){
						return response.data.venueid.venueid
					}
				}catch(error){
	
				}
			}
		},

		// async setPrivate(userid){
		//    ////// console.log('setting private')
		//    await this.putBudgetItems(this.$getRoute('budgets').main_route, { budgetid:this.budgetData.budgetid, private_userid: userid })
		// },

		// async setApprovalUser(userid){
		//    await this.putBudgetItems(this.$getRoute('budgets').main_route, { budgetid: this.budgetData.budgetid, approval_userid: userid })
		// },

		// async setGroup(group){
		//    ////// console.log(`setting group ${group}`)
		//    await this.putBudgetItems(this.$getRoute('budgets').main_route, { budgetid:this.budgetData.budgetid, private_accessgroup: group })
		// },

		// async setBudgetStatus(budgetid, status){
		//    if(status === 2){
		//       let wallresponse = await this.$wallHandler({ walltype:'budget_approved', eventtype:'concert', id:this.budgetData.concertid.concertid })
		//    }
		//    if(status === 1){
		//       let wallresponse = await this.$wallHandler({ walltype:'budget_sent_for_approval', eventtype:'concert', id:this.budgetData.concertid.concertid })
		//    }
		//    await this.putBudgetItems(
		//       this.$getRoute('budgets').main_route,
		//       { 
		//          budgetid: budgetid, 
		//          status: status
		//       }
		//    )
		// },

		// async setConcertWaitingForApproval(value){
		//    await this.putBudgetItems(this.$getRoute('concerts').main_route, 
		//       {
		//          concertid: this.budgetData.concertid.concertid,
		//          waiting_for_budget_approval: value
		//       }
		//    )
		// },

		openSaveChangesDialog(){
			this.confirmDialogText = 'Save changes before approval?'
			this.confirmDialogSubText = ''
			this.confirmButtonText = 'Yes'
			this.confirmSecondaryButtonText = 'No';
			this.showConfirmDialog = true
		},

		openBudgetApproveDeclineDialog(approve){
			if(approve){
				this.confirmDialogText = 'Approve the budget'
				this.confirmDialogSubText = ''
				this.confirmButtonText = 'Approve budget'
			}else{
				this.confirmDialogText = 'Decline the budget'
				this.confirmDialogSubText = '(Lock this and save to a new draft)'
				this.confirmButtonText = 'Decline budget'
			}
			this.showConfirmDialog = true
		},

		sendForApproval(userid){
			if(userid){
				this.$emit('setApprovalUser', userid);
				this.$emit('setBudgetStatus', this.budgetData.budgetid, 1)
				this.$emit('setConcertWaitingForApproval', 1);
				this.$emit('changesMade', false)
				this.$router.push(`/concerts/edit/${this.budgetData.concertid.concertid}`)
			}

			this.showApprovalDialog = false
		},

		openSaveNewDialog(){
			this.showConfirmDialog = true
			this.confirmDialogText = 'Save as new budget'
			this.confirmDialogSubText = '(Lock this and save to a new draft)'
			this.confirmButtonText = 'Save with changes';
			this.confirmSecondaryButtonText = 'Save without changes';
		},

		async confirmDialogResponse(confirmed, str){
			let dontClose = false
			if(confirmed){
				if(str.includes('Approve')){
					// console.log('approved')
					this.$emit('setApprovalUser', null)
					this.$emit('setBudgetStatus', this.budgetData.budgetid, 2)
					this.$emit('setConcertWaitingForApproval', 0);
					this.$emit('changesMade', false)
					this.$router.push(`/concerts/${this.budgetData.concertid.concertid}/budgets`)
				}
				else if(str.includes('Decline')){
					// console.log('declined')
					this.$emit('setApprovalUser', null)
					this.$emit('setConcertWaitingForApproval', 0);
					this.saveNewBudget();
				}
				else if(str.includes('Yes')){
					// console.log('saveChanges');
					this.showConfirmDialog = false;
					await this.saveBudget();
					this.confirmDenyButtonText = ''
					if(this.budgetData.status == 0)
						this.showApprovalDialog = true
					else
						this.openBudgetApproveDeclineDialog(true);
					
					dontClose = true;
				}
				else if(str.includes('No')){
					// console.log('no save changes');
					this.showConfirmDialog = false;
					if(this.budgetData.status == 0)
						this.showApprovalDialog = true
					else
						this.openBudgetApproveDeclineDialog(true);
					dontClose = true;
				}
				else if(str.includes('Save with changes')){
					// console.log('save with changes');
					await this.saveNewBudget(true);
				}
				else if(str.includes('Save without changes')){
					// console.log('save without changes');
					await this.saveNewBudget(false);
				}
			}
			this.confirmSecondaryButtonText = '';
			if(!dontClose)
				this.showConfirmDialog = false;
		},

		setApprovedOrDeclined(approve){
			this.$emit('setApprovalUser', null)
			this.$emit('setConcertWaitingForApproval', 0);
			if(approve){
				this.$emit('setBudgetStatus', this.budgetData.budgetid, 2)
				this.$emit('changesMade', false);
				setTimeout(() => {
					this.$router.push(`/concerts/edit/${this.budgetData.concertid.concertid}`)
				}, 500);
			} else 
				this.saveNewBudget(false);
		},

		async saveNewBudget(withChanges){
			try{
				let response = await this.$PostService(this.$getRoute('budgets').main_route, { 
					tourid: 0, 
					concertid: this.budgetData.concertid.concertid,
					version: this.budgetData.version++,
				})

				if(response.status == 200){
					this.$emit('setConcertWaitingForApproval', 0);
					// update old budget with status -1 (locked)
					this.$emit('setBudgetStatus', this.budgetData.budgetid, -1)
					// copy over items to new budget 
					await this.copyItemsToNewBudget(response.data.budgetid, withChanges)

					this.$emit('changesMade', false)

					this.$router.push(`/budgets/${response.data.budgetid}`)
				}
			}catch(error){
				// console.log(error)
			}
			this.showConfirmDialog = false
		},

		async copyItemsToNewBudget(budgetid, withChanges){
			////// console.log(budgetid)
			let tickets = [];
			let budgetRows = [];
			let newBudgetTickets = [];
			let newBudgetRows = [];
			let generalData = {};

			if(withChanges){
				for(let i = 0; i < this.stepOneData.tickets.length; i++){
					for(let j = 0; j < this.stepOneData.tickets[i].length; j++){
						tickets.push(this.stepOneData.tickets[i][j])
					}
				}
				//this.stepOneData.tickets.map(tickettype => tickettype.map(ticket => tickets.push(ticket)));
				budgetRows = this.getFullCostsList();

				generalData = await this.calculateVariousCosts(budgetid);
			}
			else {
				tickets = this.budgetData.budget_tickets;
				budgetRows = this.budgetData.budget_rows;

				generalData = {
					budgetid: budgetid,
					net_profit: this.budgetData.net_profit,
					revenues: this.budgetData.revenues,
					general_costs: this.budgetData.general_costs,
					marketing_costs: this.budgetData.marketing_costs,
					other_revenues: this.budgetData.other_revenues,
					production_costs: this.budgetData.production_costs,
					talent_costs: this.budgetData.talent_costs,
					variable_costs: this.budgetData.variable_costs,
					gross_ex_vat: this.budgetData.gross_ex_vat,
					net_gross: this.budgetData.net_gross,
					total_costs: this.budgetData.total_costs,
					total_tickets: this.budgetData.total_tickets
				}
			}

			for(let ticket of tickets){
				let ticketCopy = Object.assign({}, ticket)
				ticketCopy.budgetid = budgetid
				newBudgetTickets.push(ticketCopy)
			}

			for(let budgetRow of budgetRows){
				let budgetRowCopy = Object.assign({}, budgetRow)
				budgetRowCopy.budgetid = budgetid
				newBudgetRows.push(budgetRowCopy) 
			}

			await this.postBudgetItems(this.$getSubRoute('budgets', 'tickets'), newBudgetTickets)
			await this.postBudgetItems(this.$getSubRoute('budgets', 'rows'), newBudgetRows)

			await this.putBudgetItems(this.$getRoute('budgets').main_route, generalData)

		},

		/**
		 * Saves and updates the existing budget in the db
		 * Posts new tickets and deletes removed ones
		 * Posts new budget revenues and deletes removed ones
		 */
		async saveBudget(){
			this.toggleSavingOverlay(true)
			// update tickets - param true is special for tickets
			await this.updateBudgetItems(
				this.stepOneData.tickets, 
				this.stepOneData.deletedTickets, 
				this.$getSubRoute('budgets', 'tickets'), 
				true
			)
			//update other revenues
			await this.updateBudgetItems(
				this.stepOneData.otherRevenues, 
				this.stepOneData.deletedOtherRevenues, 
				this.$getSubRoute('budgets', 'rows')
			)
			// update costs
			let costs = this.getFullCostsList()

			let deletedCosts = this.stepTwoData.deletedCosts.concat(
				this.stepThreeData.deletedCosts).concat(
					this.stepFourData.deletedCosts).concat(
						this.stepFiveData.deletedCosts).concat(
							this.stepSixData.deletedCosts)

			await this.updateBudgetItems(
				costs, 
				deletedCosts, 
				this.$getSubRoute('budgets', 'rows')
			)

			// console.log('update crew calls');
			// console.log(this.stepThreeData);
			// // crew calls
			let mergedCrewCalls = [];
			for(const KEY in this.stepThreeData.budget_crew_calls){
				for(let call of this.stepThreeData.budget_crew_calls[KEY]) {
					let callCopy = JSON.parse(JSON.stringify(call));
					callCopy.crew_per_day = callCopy.crew_per_day.join(';');
					mergedCrewCalls.push(callCopy);
				}
			}
			await this.updateBudgetItems(
				mergedCrewCalls,
				this.stepThreeData.deletedCrewCalls,
				'budget_crew_calls'
			)

			await this.putBudgetItems(this.$getRoute('budgets').main_route, { 
				budgetid: this.budgetData.budgetid,
				co_produced_split: this.stepOneData.generalData.coSplit.value > 0 ? this.stepOneData.generalData.coSplit.value : 0
			})

			if(this.stepFiveData.isPrivate)
				this.$emit('setPrivate', this.userid);
			else
				this.$emit('setPrivate', null);

			this.$emit('setGroup', this.stepFiveData.selectedGroup)

			let generalData = await this.calculateVariousCosts(this.budgetData.budgetid);

			await this.putBudgetItems(this.$getRoute('budgets').main_route, generalData);

			let date = new Date();
			await this.putBudgetItems(this.$getRoute('budgets').main_route, { 
				budgetid: this.budgetData.budgetid,
				updated_date: `${date.toISOString().substr(0,10)} ${date.toTimeString().substr(0,8)}`
			})

			// update some concert variables
			let concertPutObj = {
				concertid: this.budgetData.concertid.concertid,
				sellable_capacity_budget: this.sellable_capacity_budget
			}
			const fixedGage = costs.find(cost => cost.name == 'fixed_gage_minimum_gage');
			
			if(fixedGage && +fixedGage.calculated_cost > 0)
				concertPutObj['gage_talent'] = fixedGage.calculated_cost;
			await this.putBudgetItems('concerts', concertPutObj)

			await this.toggleSavingOverlay(false)
			this.$emit('changesMade', false)
		},

		async calculateVariousCosts(budgetid){
			let other_revenues = 0
			this.stepOneData.otherRevenues.map(rev => other_revenues += rev.revenues)

			//update costs
			let variable_costs = 0
			this.stepTwoData.costs.map(cost => variable_costs += cost.calculated_cost)

			let production_costs = 0
			this.stepThreeData.costs.map(cost => production_costs += cost.calculated_cost)

			let marketing_costs = 0
			this.stepFourData.costs.map(cost => marketing_costs += cost.calculated_cost)

			let talent_costs = 0
			this.stepFiveData.costs.map(cost => {
				if(cost.calculated_cost > talent_costs)
					talent_costs = cost.calculated_cost
			})

			let general_costs = 0
			this.stepSixData.costs.map(cost => general_costs += cost.calculated_cost)

			return {
				budgetid: budgetid,
				variable_costs: variable_costs,
				production_costs: production_costs,
				marketing_costs: marketing_costs,
				talent_costs: talent_costs,
				general_costs: general_costs,
				other_revenues: other_revenues,
				gross_ex_vat: this.netRevenue,
				net_gross: this.netGross,
				total_costs: this.totalCosts,
				total_tickets: this.sellable_capacity_budget,
				net_profit: this.netGross - production_costs - marketing_costs - talent_costs - general_costs,
				revenues: this.grossRevenue,
				break_even: this.ticketsToBreakEven
			}
		},

		async toggleSavingOverlay(val){
			if(val){
				this.isCurrentlyUpdating = true
				this.savingText = 'Budget is saving...'
			}
			else{
				this.savingText = 'Budget saved!'
				// 1500 delay to show user it actually saved
				await setTimeout(() => {
					this.isCurrentlyUpdating = false
				}, 1500);
			}
		},

		async collectNewItems(data){
			// console.log('collectNewItems')
			// console.log(data);
			let newItems = []
			for(let item of data){
				if(item.budgetid == undefined){
					item.budgetid = this.$getRouteId(this.$route.path)
					newItems.push(item)
				}
			}
			return newItems
		},

		async updateBudgetItems(items, itemsToDelete, route, ticket = false){
			let newItems = []
			let data = []
			// post new items to db
			if(ticket) {
				for(let showTickets of items)
					newItems = newItems.concat(await this.collectNewItems(showTickets))
			}
			else
				newItems = await this.collectNewItems(items);

			
			newItems.map(item => item.name == '' ? item.name = item.display_name : null)

			if(newItems.length != 0) {
				data = await this.postBudgetItems(route, newItems)
				// console.log('### DATA ###')
				// console.log(data)
				// console.log('### newItems ###')
				// console.log(newItems)

				// update with new id's
				for(let i = 0; i < newItems.length; i++){
					for(const key in data)
						newItems[i][key] = data[key][i]
				}
			}

			// delete removed items from db
			for(let oldItem of itemsToDelete){

				let itemToDel = null;

				// ticket item
				if(oldItem.budget_ticketid != undefined)
					itemToDel = { budget_ticketid: oldItem.budget_ticketid }

				// revenue/cost item
				else if(oldItem.budget_rowid != undefined)
					itemToDel = { budget_rowid: oldItem.budget_rowid }

				// crew call item
				else if(oldItem.budget_crew_callid != undefined)
					itemToDel = { budget_crew_callid: oldItem.budget_crew_callid }

				if(itemToDel != null)
					await this.deleteBudgetItem(route, itemToDel)
			}
			//// console.log(`items: ######`)
			//// console.log(items)
			// update items in db
			if(ticket)
				for(let showTickets of items)
					await this.putBudgetItems(route, showTickets)
			else
				await this.putBudgetItems(route, items)

		},

		/**
		 * Posts new budget tickets to the db 
		 * param items is the array of new budget items
		 * param route is the api route
		 */
		async postBudgetItems(route, items){
			try{
				let response = await this.$PostService(route, items)
				if(response.status == 200){
					////// console.log(response)
					////// console.log('successful post of items: ')
					////// console.log(items)
					return response.data
				}
			} catch(error){
					// console.log(error)
					//////// console.log(response)
					//////// console.log(JSON.stringify(error))
			}
			return false
		},

		/**
		 * Update a budget item in the db
		 * param items is the array of new budget items
		 * param route is the api route
		 */
		async putBudgetItems(route, items){
			// // console.log('putBudget')
			//// console.log(items)
			try{
				let response = await this.$PutService(route, items)
				////// console.log(response)
				if(response.status == 200)
					console.log('update of items successful')
			}catch(error){
				// console.log(error)
			}
		},

		/**
		 * Deletes a budget item from the db
		 * param id is the id of the item (budget_ticketid / budget_rowid)
		 * param route is the api route
		 */
		async deleteBudgetItem(route, itemToDel){
			try{
				let response = await this.$DeleteService(route, itemToDel)

				if(response.status == 200){
					// console.log('deletion successful of item: ')
					// console.log(itemToDel)
				}
			} catch(error){
				// console.log(error)
			}
		},

	},


}
</script>

<style lang="scss">


    .budget-section-wrapper{
        margin-bottom:20px;
		border-left:2px solid $accent-color;
		background:#fff;
        padding:15px;
    }

</style>