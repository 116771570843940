<template>
	<div id="venues">
		<v-col cols="12" md="12" class="pa-0 elevation-1">
			<ViewHeader :header="header"/>
			<BrowseTable :view="this.$options.name" :idpath="this.idpath"/>
		</v-col>
	</div>
</template>

<script>
	import BrowseTable from '@/components/Tables/BrowseTable/'
	import ViewHeader from '@/components/ViewHeader/'
	export default {
		name:'venues',
		data:()=>({
			header:'Browse Venues',
			idpath:'venueid'
			
		}),
		components:{
			BrowseTable,
			ViewHeader
		}
	}
</script>

<style lang="scss" scoped>


</style>