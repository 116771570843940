<template>
   <div id="project-ranking-card" class="elevation-1">
      <div class="card-title">{{ title }}</div>
      <v-col cols="12" md="12" style="position:relative;">
         <NoDataPlaceholder
            v-if="bars.ticketBars.length === 0"
            :text="'no sales data'"
            style="z-index:1"
         />
         <div class="linear-progress">
            <v-col col="12" md="12">
               <v-row>
                  <v-col cols="12" md="12" class="mb-5">
                     <v-col cols="12" md="12" class="d-flex justify-center">
                        <v-btn-toggle
                           v-model="toggle_exclusive"
                           mandatory
                        >                     
                           <v-btn @click="btnSelection = 'percent'" small text color="#11283e">%</v-btn>
                           <v-btn @click="btnSelection = 'total'"  small text color="#11283e">{{ $config.CURRENCY }}</v-btn>
                           <!-- <v-btn @click="btnSelection = 'pred'"  small text color="#11283e">Pred</v-btn> -->
                        </v-btn-toggle>
                        </v-col>
                  </v-col>
                  <LinearProgressBars 
                     v-if="btnSelection == 'percent'" 
                     :bars="bars.ticketBars" 
                     :choice="btnSelection"
                     :sumType="'%'"
                  />
                  <LinearProgressBars 
                     v-if="btnSelection == 'total'" 
                     :bars="bars.grossBars" 
                     :choice="btnSelection"
                     :sumType="$config.CURRENCY"
                  />
                  <LinearProgressBars 
                     v-if="btnSelection == 'pred'" 
                     :bars="bars.predBars" 
                     :choice="'percent'"
                     :sumType="'%'"
                  />
               </v-row>
            </v-col>
         </div>
      </v-col>
   </div>
</template>

<script>
import LinearProgressBars from '@/components/LinearProgressBars/'
import NoDataPlaceholder from '@/components/NoDataPlaceholder'


export default {
   name: 'ProjectRankingCard',

   components: {
      LinearProgressBars,
      NoDataPlaceholder
   },

   props: {
      title: String,
      ticketBars: Array,
      grossBars: Array,
      predBars: Array,
      sortType: String
   },

   data:()=>({
      toggle_exclusive:undefined,
      btnSelection: 'percent',
      bars:{
         ticketBars:[],
         grossBars:[],
         predBars:[]
      }
   }),
   watch:{
      'ticketBars':{
         handler:function(){
            this.bars.ticketBars = JSON.parse(JSON.stringify(this.ticketBars))
            console.log('ticketbars WATCHER')
         },
         deep:true
      },
      'grossBars':{
         handler:function(){
            this.bars.grossBars = JSON.parse(JSON.stringify(this.grossBars))
         },
         deep:true
      },
      'predBars':{
         handler:function(){
            this.bars.predBars = JSON.parse(JSON.stringify(this.predBars))
         },
         deep:true
      },
      btnSelection(val){
         this.sortAll()
      }
   },

   async created(){
      this.bars.ticketBars = JSON.parse(JSON.stringify(this.ticketBars))
      this.bars.grossBars = JSON.parse(JSON.stringify(this.grossBars))
      this.bars.predBars = JSON.parse(JSON.stringify(this.predBars))
      this.sortAll()
      this.$bus.$on('SortProjectRanking', await this.sortAll)
   },


   methods:{
      async sortAll(){
         await this.sortBars(this.bars.ticketBars)
         await this.sortBars(this.bars.grossBars)
         await this.sortBars(this.bars.predBars)
         await this.fixGrossPercentage(this.bars.grossBars);
      },

      async sortBars(bars){
         if(!bars) return

         bars.sort( (a,b) => {
            if(this.sortType == 'desc'){
               if(this.btnSelection == 'percent' || this.btnSelection == 'pred')
                  return b.percentage - a.percentage
               else
                  return b.total - a.total
            } else {
               if(this.btnSelection == 'percent' || this.btnSelection == 'pred')
                  return a.percentage - b.percentage
               else
                  return a.total - b.total
            }

         })
         if(bars.length > 5)
            bars.length = 5
      },

      async fixGrossPercentage(bars){
         let val = bars.reduce((total, bar, i, arr) => {
            total += bar.total
            return total
         }, 0);
         bars.map(bar => bar.percentage = Math.round(bar.total / val * 100))
      }
   },
   
}
</script>

<style lang="scss" scoped>

   #project-ranking-card{
      position:relative;
      background:#fff;
      border-radius:3px;
   }

   .button-wrapper{
      .v-btn{
         font-weight:600;
         border:2px solid #6a7a8c;
         border-radius:30px;
      }
   }

</style>