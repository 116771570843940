<template>
    <v-row class="row-wrapper mb-10">
        <v-col cols="12" md="3" class="d-flex align-center">
            <v-text-field
                :disabled="noDelete"
                dense
                required
                label="Type"
                v-model="name"
                filled
                hide-details
            />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field
                style="text-align:right"
                type="number"
                dense
                required
                label="Number of shows"
                v-model="numConcerts"
                :rules="[numberRule]"
                filled
                hide-details
            />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field
                style="text-align:right"
                type="number"
                label="Cost per person"
                dense
                required
                :suffix="valueSuffix"
                v-model="value"
                filled
                hide-details
            />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field
                :disabled="lockPeople"
                v-if="!slim"
                style="text-align:right"
                type="number"
                label="Number of people"
                dense
                required
                v-model="numPeople"
                min="0"
                :rules="[numberRule]"
                filled
                hide-details
            />
        </v-col>

        <v-col cols="12" md="2">
            <v-text-field
                disabled
                type="number"
                label="Calculated cost"
                dense
                required
                :suffix="currency"
                v-model="calculated_cost"
                filled
                hide-details
            />
        </v-col>

        <v-col cols="12" md="1" class="d-flex align-center">
            <v-spacer></v-spacer>
            <v-btn
                v-if="!noDelete"
                small
                icon
                color="error"
                @click.stop="$bus.$emit('deleteBudgetItem', costItem)"
            >
                <v-icon>remove_circle_outline</v-icon>
            </v-btn>
            <comment-button
                class="ml-1"
                :name="costItem.name"
                :comment="!costItem.comment ? '' : costItem.comment"
                :idObj="{ budget_rowid: costItem.budget_rowid }"
                :route="$getSubRoute('budgets','rows')"
                @commentSaved="emitUpdate"
            />
        </v-col>
    </v-row>
</template>

<script>
import CommentButton from "@/components/Dialogs/Comment/CommentButtonPropSafe";

export default {
    name: "tourcostform",

    components: {
        CommentButton,
    },

    props: {
        costItem: Object,
        noDelete: Boolean,
        slim: Boolean,
        lockPeople: Boolean,
    },

    data: () => ({
        currency: "",
        valueSuffix: "",
        name: "",
        value: 0,
        numConcerts: 0,
        numPeople: 0,
        prevPeople: 0,
        dataCreated: false,
        numberRule: (v) => {
            if (v >= 0) return true;
            return "Cannot be negative";
        },
    }),

    created() {
        this.currency = this.$config.CURRENCY;
        this.name = this.costItem.name;
        this.numConcerts = this.costItem.num_concerts
            ? this.costItem.num_concerts
            : 0;
        this.numPeople = this.costItem.num_people
            ? this.costItem.num_people
            : 0;

        this.valueSuffix = this.currency;

        // wait until data is set, then set value to trigger an global update of calculated_cost
        setTimeout(() => {
            this.dataCreated = true;
            this.value = this.costItem.value;
        }, 1);
    },

    computed: {
        calculated_cost() {
            // if slim is true there is no number of people
            return (
                this.numConcerts * this.value * (this.slim ? 1 : this.numPeople)
            );
        },
    },

    watch: {
        name(val) {
            this.emitUpdate({ name: val });
        },

        value(val) {
            this.emitUpdate({
                value: val,
                calculated_cost: this.calculated_cost,
            });
        },

        numConcerts(val) {
            this.emitUpdate({
                num_concerts: val,
                calculated_cost: this.calculated_cost,
            });
        },

        numPeople(val) {
            this.emitUpdate({
                num_people: val,
                calculated_cost: this.calculated_cost,
                header: this.costItem.header,
            });
        },
    },

    methods: {
        emitUpdate(item) {
            if (!this.dataCreated) return;

            item.budget_rowid = this.costItem.budget_rowid;
            item.step = this.costItem.step;

            this.$bus.$emit("tourBudgetUpdatedData", item);
        },
    },
};
</script>

<style lang="scss" scoped>
.row-wrapper {
}
</style>