import ApiObjectCollection from "./ApiObjectCollection";
import ApiObject from "./ApiObject";
import Notice from "./Notice";

export default class NoticeCollection extends ApiObjectCollection {
    constructor() {
        super('notices');
        this.user = null;
        this.lastRead = null;
        this.unreadNoticeCount = 0;
    }
    
    countUnreadNotices(){
        var counter = 0;
        if(!this.lastRead) return 0;

        var last_notice_read = new Date(this.lastRead);
        for(let notice of this.collection){

            var noticeDate = new Date(notice.created);
            
            if (noticeDate > last_notice_read){
                notice.unread = true;
                counter++;
            } else
                break;
        }

        if(counter > this.unreadNoticeCount)
            this.unreadNoticeCount = counter;
        return counter;
    }

    async getNextNotices(from, to) {
        await this.fetchAll({ route: `?limit=${from},${to}` })
    }

    async updateLastNoticeRead(){
        
        if(this.collection.length > 0){

            if(new Date(this.user.last_notice_read) >= new Date(this.collection[0].created))
                return;

            this.user.last_notice_read = this.collection[0].created;
            this.lastRead = this.collection[0].created;
        }
        this.unreadNoticeCount = 0;
        await this.user.updateObject(this.user);
    }

    async markAsRead(notice = null){
        if(notice){
            notice.markAsRead();
        }else{
            for(let noticeItem of this.collection)
                noticeItem.markAsRead();
        }
        await this.updateLastNoticeRead();   
    }

    async checkForNewNotices(){
        await this.fetchNewNotices(`?from=${this.lastRead}`)
        //console.log(this.lastRead)

        return this.countUnreadNotices();
    }

    async fetchNewNotices( route = ''){
        
        let dataArray = await this.getData(route);

        if(!dataArray) return false;

        for(let data of dataArray){
            var obj = Object.assign( Object.create( Object.getPrototypeOf(this.dummy)), this.dummy)
            await obj.setObjectData(data);
            
            var oldObj = await this.isInCollection(obj);
            if(!oldObj)
                this.collection.unshift(obj);

        }
        return true;
    }

}