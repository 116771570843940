<template>
    <div id="budgethistory">
        <div class="budget-table-container elevation-1">
            <ViewHeader :header="header" />
            <v-data-table
                :headers="budgetHeaders"
                :items="budget_col != [] ? budget_col.collection : []"
                :items-per-page="5"
                @click:row="(item) => goTo(item)"
            >
                <template v-slot:item.status="{ item }">
                    {{ setStatus(item.status) }}
                </template>
                <template slot="no-data" v-if="budget_col == null">
                    <Loader v-if="!isLoaded" :loaderStyle="'free'"></Loader>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader/";
import Loader from "@/components/Loader.vue";
import { mapGetters } from 'vuex';
import ObjectFactory from '@/models/ObjectFactory';

export default {
    name: "budgethistory",
    components: {
        ViewHeader,
        Loader,
    },
    data: () => ({
        header: `Budget History`,
        metaFields: {},
        budgetHeaders: [],
        valid_headers: [
            "updated_date",
            "status",
            "userid",
            "revenues",
            "total_costs",
            "net_profit",
        ],
        isLoaded: false,
        budget_col:[],
    }),
    computed: {
        ...mapGetters({
            userSelections: "user/userSelections",
            isInitialized: "user/isInitialized",
        }),
    },
    async created() {
        await this.getBudgetItems();

        this.alignRightHeadersWithDigits();
        this.isLoaded = true;
    },
    methods: {
        goTo(item) {
            this.$router.push({ path: `/budgets/${item.budgetid}` });
        },
        setStatus(status) {
            switch (status) {
                case "1":
                    return "Awaiting approval";
                    break;
                case "-1":
                    return "Locked";
                    break;
                case "0":
                    return "Draft";
                    break;
                case "2":
                    return "Approved";
                    break;
                default:
                    return "Not set";
            }
        },
        async getBudgetItems() {
            let view = this.$route.path.match(/\/(.*?)\//)[1];
            view = view.substr(0, view.length - 1);
            try {

                let response = await this.$GetService(
                    `${this.$getRoute("budgets").main_route}?${view}id=${
                        this.$route.params.id
                    }`
                );
                if (response.status === 200) {
                    this.budget_col = await this.$ObjectFactory.BuildCollection({endpoint: 'budgets'});

                    for(let budget of response.data){
                        budget.revenues = this.$thousandSeperator(
                            budget.revenues
                        );
                        budget.total_costs = this.$thousandSeperator(
                            budget.total_costs
                        );
                        budget.net_profit = this.$thousandSeperator(
                            budget.net_profit
                        );
                        this.budget_col.addToCollection(budget);
                    }
                    this.getHeaders();
                }
            } catch (err) {
                console.log(err);
            }
        },
        getHeaders() {
            console.log(this.budget_col)
            for(let field of this.budget_col.metaFields){
                let key = field.COLUMN_NAME;
                if (this.valid_headers.includes(key)) {
                    let index = this.valid_headers.findIndex(
                        (header) => header === key
                    );
                    let obj = {
                        text:
                            key === "userid"
                                ? "User"
                                : key
                                      .replace(/_/g, " ")
                                      .replace(/(^|\s)\S/g, (l) =>
                                          l.toUpperCase()
                                      ),
                        align: "left",
                        sortable: "true",
                        value: key,
                    };
                    this.budgetHeaders[index] = obj;
                }
            }
            this.budgetHeaders.push({})
            
        },
        alignRightHeadersWithDigits() {
            for (let key in this.budgetHeaders) {
                if (
                    this.budgetHeaders[key].value !== "status"
                ) {
                    if (
                        this.budget_col.metaFields[this.budgetHeaders[key].value] ===
                            "int" ||
                        this.budget_col.metaFields[
                            this.budgetHeaders[key].value === "float"
                        ]
                    ) {
                        this.budgetHeaders[key].align = "right";
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#budgethistory {
    width: 100%;
    height: 100%;
    float: left;
    padding: 20px;
    &::v-deep .v-data-table__wrapper {
        tr:hover {
            background: #eee;
            cursor: pointer;
        }
    }

    .budget-table-container {
        background: #fff;
    }
    .v-data-table {
        border-bottom: 1px solid $border-color;
    }
}
</style>
