<template>
    <div class="salespage">
        <season-dialog
            :seasonDialog="seasonDialog"
            :dateSelected="dateSelected"
            :tourSelected="tourSelected"
            @closeSeasonDialog="seasonDialog = false"
            @newConcert="addConcert"
            @statusUpdated="statusUpdated"
        />
        <v-col cols="12" md="12" class="pa-0">
            <calendar-picker
                :dateSelected="dateSelected"
                @selectMonth="selectMonth"
                @selectYear="selectYear"
            />
            <sales-table
                :dateSelected="dateSelected"
                :tourSelected="tourSelected"
                :filteredTours="filteredTours"
                @selectTour="selectTour"
                @showSeasonDialog="showSeasonDialog"
                :isToursPending="isToursPending"
            >
            </sales-table>
        </v-col>
    </div>
</template>

<script>
import dayjs from "dayjs";
import SalesTable from "@/components/SalesPage/SalesTable.vue";
import ViewHeader from "@/components/ViewHeader";
import CalendarPicker from "./CalendarPicker.vue";
import SeasonDialog from "./SeasonDialog.vue";

export default {
    name: "salespage",
    components: {
        SalesTable,
        ViewHeader,
        CalendarPicker,
        SeasonDialog,
    },

    mixins: [
        require("./SalesTableCalcMixins.vue"),
        require("./SalesTableGeneralFunctionsMixins.vue"),
    ],

    data: () => ({
        tours: null, // array will hold all tours objs
        isToursPending:true,
        seasonDialog: false, // popup window for calendar
        dateSelected: dayjs(), // the date that determines what season&year to look at
        filteredTours: [], // the tours of a season to print out   
        tourSelected: [], // when looking at a single tour
        exclude_fields: "wall_events,budgets,tickets,comments,goal_number_of_gig,goal_wage_concert,oracle_project_number,settlement,waiting_for_budget_approval",
    }),

    async created() {
        await this.getTours()
        this.$bus.$on("salesPageStatusUpdated", this.salesPageStatusUpdated); // a concert has changed status
        this.dateSelected = dayjs(dayjs().year() + "-01-01T00:00:00.001Z"); // load with current date
        this.selectMonth(this.getCurrentSeasonMonth());
        this.updateFilteredTours();
        this.isToursPending = false
    },

    methods: {
        async getTours() {
            this.tours = (
                await this.$GetService(
                    this.$getRoute("tours").main_route +
                        "?inc=assoc&objfetch=obj&exclude_fields=" +
                        this.exclude_fields
                )
            ).data.map((tour) => this.addSalesTableData(tour));

            console.log("ALL TOURS:");
            console.log(this.tours);
            
        },

        updateFilteredTours() {
            this.filteredTours = this.getFilteredTours(this.dateSelected); 
        },

        getFilteredTours(rangeStart) {
            /**
             * Sales table is based on 3 seasons of 4 month each.
             * This function takes the start of a season as input and
             * returns the tours from array-tours that overlaps with
             * that range. Usually into array-filteredTours.
             * */
            let span = 4;

            if (!this.tours) return;
            let rangeEnd = rangeStart.add(span, "month");
            rangeEnd = rangeEnd.add(-1, "day"); // end on last day of 4th month instead of first day of month 5
            let filteredTours = [];
            for (let tour of this.tours) {
                // kan göras något fancy med /dan
                let tourStart = dayjs(tour.startdate);
                let tourEnd = dayjs(tour.enddate);
                if (
                    this.isDateRangeOverlapping(
                        tourStart,
                        tourEnd,
                        rangeStart,
                        rangeEnd
                    )
                )
                    filteredTours.push(tour);
            }
            return filteredTours; // tours within range
        },

        selectMonth(month) {
            this.dateSelected = this.dateSelected.month(month - 1); // changes month of dateSelected
            this.updateFilteredTours();
        },

        selectYear(year) {
            this.dateSelected = this.dateSelected.year(year); // changes year of dateSelected
            this.updateFilteredTours();
        },

        showSeasonDialog(item) {
            /**
             * When a tour is clicked from salesTable this function will activate the calendar popup for that tour
             * */
            this.seasonDialog = true;
            const [tour] = this.tours.filter(
                (tour) => tour.tourid == item.tourid
            );
            this.tourSelected = tour;
        },

        addConcert(concert) {
            /**
             * When adding a new concert to a tour, the data for salesTable needs to be recalculated.
             * This function takes a concert object and looks for a tour with matching tourid, inserts the new concert,
             * recalculate the salesPage data, updates season-filtered-tours and selected-tour.
             */

            /* work in progress
            let t = tours.find((tour) => tour.tourid === concert.tourid)
            t.push(concert)
            t = this.addSalesTableData(t);
            
            this.filteredTours = this.getFilteredTours(this.dateSelected); // update filteredTours
            this.tourSelected = t;
            */

            for (let tour of this.tours) {
                // mer fancy sen - niqq (reduce)
                if (tour.tourid == concert.tourid) {
                    tour.concerts.push(concert);
                    tour = this.addSalesTableData(tour);
                    this.updateFilteredTours();
                    this.tourSelected = tour;
                }
            }


        },

        salesPageStatusUpdated(id, status) {
            // filter eller find -dan
            for (let concert of this.tourSelected.concerts) {
                if (concert.concertid == id) {
                    concert.status = status;
                }
            }
            this.tourSelected = this.addSalesTableData(this.tourSelected);
            this.updateFilteredTours();
        },
    },
};
</script>

<style lang="scss">
#date-picker {
    background: #fff;
    border-bottom: 1px solid $border-color;
    padding: 20px 15px;
}
</style>
