<template>
  <v-row justify="center">
    <v-dialog v-model="showDeleteDialog" persistent max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline">Confirm Delete</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="confirmationName" :label="'Confirm deletion by typing the name: '+ this.name" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
   
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#666" text @click="closeDeleteDialog()">Cancel</v-btn>
          <v-btn color="error" :loading="isDeletePending" :disabled="!matched" depressed  @click="deleteColumn()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

   

   export default {
      name:'removedialog',
      props:{
         showDeleteDialog:Boolean,
         name:String
      },
      data:()=>({
         confirmationName:'',
         isDeletePending:false
      }),
      created(){
         this.$bus.$on('unSetLoading', this.unSetLoading)
      },
      methods:{
         closeDeleteDialog(){
            this.$emit('closeDeleteDialog')
         },
         deleteColumn(){
            if(this.name === this.confirmationName){
               this.$emit('deleteColumn', this.$route.params.id)
            }
            this.setLoading()
         },
         setLoading(){
            this.isDeletePending = true
         },
         unSetLoading(){
            this.isDeletePending = false
         }
      },
      computed:{
         matched(){
            return this.name === this.confirmationName ? true : false
         },
      }
   }
</script>

<style lang="scss" scoped>

</style>