<template>
    <v-dialog v-model="dialog" persistent width="800">
        <v-card>
            <v-card-title class="">
                <span class="header">{{ name }}</span>
                <v-spacer></v-spacer>
                <v-btn color="error" small depressed @click="removeComment">Delete</v-btn>
            </v-card-title>
            <v-divider />
            <v-textarea class="pa-5 pb-0" rows="3" outlined auto-grow label="Write a comment here" v-model="text" />

            <v-card-actions class="pa-5">
                <v-spacer></v-spacer>
                <v-btn text small @click="cancelComment">Cancel</v-btn>
                <v-btn depressed color="primary" small :disabled="text === ''" @click="saveComment">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "CommentDialog",

    props: {
        dialog: Boolean,
        comment: String,
        name: String,
    },

    data: () => ({
        text: "",
    }),

    created() {
        this.text = this.comment;
    },

    methods: {
        saveComment() {
            this.$emit("saveComment", this.text);
        },

        cancelComment() {
            this.$emit("cancelComment");
        },

        removeComment() {
            (this.text = ""), this.saveComment();
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    font-size: 15px !important;
    text-transform: uppercase;
    color: $accent-color;
    font-weight: 600 !important;
    display: flex;
    padding-bottom: 5px !important;
}
</style>
