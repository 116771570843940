<template>
    <div>
        <div class="header" v-if="use === 'editTable'">Split info</div>
        <v-row>
            <v-col cols="12" :md="md">
                <v-autocomplete
                    v-model="splitPerson"
                    :items="splitPeople"
                    label="Person"
                    item-text="name"
                    hide-details
                    return-object
                    outlined
                    dense
                    filled
                    clearable
                    :disabled="splitOrganization"
                />
            </v-col>
            <v-col cols="12" :md="md">
                <v-autocomplete
                    v-model="splitOrganization"
                    :items="splitOrgs"
                    label="Organization"
                    item-text="name"
                    hide-details
                    return-object
                    outlined
                    dense
                    filled
                    clearable
                    :disabled="splitPerson"
                />
            </v-col>
            <v-col cols="12" :md="md">
                <v-text-field
                    v-model="splitRole"
                    outlined
                    dense
                    hide-details
                    label="Role"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="12" :md="md">
                <v-text-field
                    outlined
					type="number"
                    dense
                    hide-details
                    label="Split value"
                    v-model="splitSum"
                    :disabled="blockSum"
                ></v-text-field>
            </v-col>
            <v-col cols="12" :md="md">
                <v-text-field
                    outlined
					type="number"
                    dense
                    hide-details
                    label="Split percent"
                    v-model="splitPercent"
                    :disabled="blockPercent"
                    suffix="%"
                ></v-text-field>
            </v-col>
            <v-col cols="12" :md="md">
                <v-btn
                    block
                    color="primary"
                    @click="addSplitInfo"
                    :disabled="buttonDisabled"
                    depressed
                    >add</v-btn
                >
            </v-col>
            <v-col cols="12" md="12" v-if="splitInfoCollection.collection">
                <v-row>
                    <v-col
                        v-for="(item, index) in splitInfoCollection.collection"
                        :key="index"
                        class="splitinfo-card-container"
                        cols="12"
                        :md="md"
                    >
                        <div class="split-info-card elevation-1">
                            <div style="flex: 1">
                                <div v-if="item.personid">
                                    <span class="splitname">{{
                                        item.personName
                                    }}</span>
                                </div>

                                <div v-if="item.organizationid">
                                    <span
                                        style="color: #306aa1; font-weight: 600"
                                        >{{ item.organizationName }}</span
                                    >
                                </div>
                                <div style="width: 100%">
                                    Role: {{ item.role }}
                                </div>
                                <div style="width: 100%">
                                    Split info:
                                    {{
                                        item.split_info == 0
                                            ? `${(
                                                  item.split_info_perc * 100
                                              ).toFixed(2)}%`
                                            : item.split_info
                                    }}
                                </div>
                            </div>
                            <div
                                style="display: flex; align-items: center"
                                @click="deleteSplitInfo(item, index)"
                            >
                                <v-btn color="primary" small icon>
                                    <i class="mdi mdi-close"></i>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ApiObject from "@/models/ApiObject";
import ObjectFactory from "@/models/ObjectFactory";

export default {
    name: "splitinfoform",
    props: {
        id: String,
        fillWith: Array,
        instantSave: Boolean,
        use: String,
    },

    data: () => ({
        splitInfoCollection: {},
        splitPerson: null,
        splitOrganization: null,
        splitSum: null,
        splitPercent: null,
        splitRole: "",
        splitInfoObject: {},
        splitPeople: [],
        splitOrgs: [],
        blockSum: false,
        blockPercent: false,
        md: null,
    }),

    async created() {
        this.splitInfoObject = await this.$ObjectFactory.BuildObject({
            endpoint: "artist_split_info",
        });

        this.setColMd();

        const createFields = await this.splitInfoObject.getMetaFields("create");

        for (let meta in createFields) {
            if (createFields[meta].COLUMN_NAME == "personid")
                this.splitPeople = JSON.parse(
                    JSON.stringify(createFields[meta].DROPDOWN)
                );
            else if (createFields[meta].COLUMN_NAME == "organizationid")
                this.splitOrgs = JSON.parse(
                    JSON.stringify(createFields[meta].DROPDOWN)
                );
        }

        this.splitInfoCollection = await this.$ObjectFactory.BuildCollection({
            endpoint: "artist_split_info",
            fromDummy: this.splitInfoObject,
        });

        if (this.fillWith) {
            for (let split of this.fillWith) {
                let item = Object.assign(
                    Object.create(Object.getPrototypeOf(split)),
                    split
                );
                item.organizationName = item.organizationid?.name;
                item.organizationid = item.organizationid?.organizationid;
                item.personName = item.personid?.name;
                item.personid = item.personid?.personid;
                item.artistid = item.artistid?.artistid;

                this.splitInfoCollection.addToCollection(item);
            }
        }
        console.log(this.splitInfoCollection);
    },
    watch: {
        splitPercent(val) {
            this.blockSum = val > 0 ? true : false;
            console.log(val);
        },
        splitSum(val) {
            this.blockPercent = val > 0 ? true : false;
            console.log(val);
        },
    },
    computed: {
        buttonDisabled() {
            return !((this.splitPerson || this.splitOrganization) && this.splitRole && (this.splitSum || this.splitPercent))
        },
    },
    methods: {
        setColMd() {
            this.md = this.use === "editTable" ? 2 : 12;
        },
        async addSplitInfo() {
            const splitInfo = {
                organizationid: this.splitOrganization?.organizationid,
                organizationName: this.splitOrganization?.name,
                personid: this.splitPerson?.personid,
                personName: this.splitPerson?.name,
                role: this.splitRole,
                split_info: this.splitSum ? this.splitSum : 0,
                split_info_perc: this.splitPercent
                    ? this.splitPercent / 100
                    : 0,
                artistid: this.id,
            };

            await this.splitInfoCollection.addToCollection(splitInfo);

            if (this.instantSave)
                await this.splitInfoCollection.postAndUpdateCollection();
            else this.$emit("collectionUpdated", this.splitInfoCollection);

            this.splitOrganization = null;
            this.splitPerson = null;
            this.splitRole = null;
            (this.splitSum = null), (this.splitPercent = null);
        },

        async deleteSplitInfo(item, index) {
            if (this.instantSave) {
                await item.deleteObject();
                this.splitInfoCollection.removeFromCollection(item);
            } else {
                this.splitInfoCollection.removeFromCollection(item);
                this.$emit("collectionUpdated", this.splitInfoCollection);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.splitinfo-card-container {
    padding: 10px;
    float: left;
    display: flex;
    .split-info-card {
        background: #f0f0f0;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        float: left;
        width: 100%;
        min-height: 110px;
        position: relative;

        .splitname {
            color: #306aa1;
            font-weight: 600;
            width: 100%;
            float: left;
        }
    }
    button {
        right: 15px;
        margin-right: 0px;
        font-size: 1.2em;
        background: rgb(223, 223, 223);
    }
}
</style>
