<template>
    <div>
        <div class="info-text">
            As a user you log in to Binary Brains by using your Okta credentials
            and the two step verification it requires.<br />
            Any login related questions should be brought up with your local
            IT-department or directly with Okta.
        </div>
		<div style="padding:50px;">
        	<img src="../../../assets/help1.png" />
		</div>
        <div class="info-text">
            The user administration is done in Active Directory where users can
            be added and removed. <br/>This is also where users are added and removed
            to different departments and other user groups. <br/>The user groups
            defines what you as a user can and can’t do in the system.
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>