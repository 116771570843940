<template>
   <v-col cols="12" md="12" class="pa-0">
      <v-row class="summary-text">
      
         <v-btn
            class="btn-text"
            block
            icon-left
            text
            @click="!disableExpand ? show = !show : null"
         >

            <v-icon :color="disableExpand ? 'grey' : ''">{{ show ? 'expand_less' : 'expand_more' }}</v-icon>
            {{ title}}
            <v-spacer/> 
            {{ formattedValue(value) }}
         </v-btn>
      </v-row>

      <div v-show="show"  class="items summary-text">
         <div v-for="item in items" :key="item.budget_rowid" class="dropdown-items">
            <v-row class="ma-0">
               <v-col cols="12" md="6" class="py-2">
                  <span>{{ item.name == '' ? '-' : item.name }}</span>
               </v-col>
               <v-col cols="12" md="6" class="py-2" >
                  <span class="float-right">{{ formattedValue(item[valueKey]) }}</span>
               </v-col>
            </v-row>
            <!-- <v-divider v-if="i < items.length-1 " class="mx-2"/> -->
         </div>
      </div>
   </v-col>
</template>

<script>
export default {
   name: 'toursummaryrow',

   props: {
      revenue: Boolean,
      title: String,
      value: String,
      currency: String,
      items: Array,
      valueKey: String,
      disableExpand: Boolean
   },

   data: ()=>({
      show: false,
   }),

   created(){
      if(this.revenue == undefined)
         this.revenue = false
   },

   methods: {
      formattedValue(value){
         let val = this.$thousandSeperator(Math.round(value))
         let presufVal = `${this.currency.match(/[$€£]/) ? this.currency : ''} ${val} ${this.currency.match(/[^$€£]/) ? this.currency : ''}`
         return !this.revenue ? `- ${presufVal}` : presufVal
      }
   }
}
</script>

<style lang="scss">

.btn-text{
   font-size: 0.8rem!important;
   font-weight: 500;
   text-transform: none;
   letter-spacing: 0px;
   color:#fff!important;
   
   
}

.outer-dropdown-items .btn-text{
   font-weight:400!important;
}

.dropdown-items{
   font-size:0.8rem;
}

</style>