<template>
    <v-row class="row-wrapper">
        <v-col cols="12" md="2">
            <v-text-field
                dense
                required
                label="Ticket type"
                v-model="ticket.section_name"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                style="text-align:right"
                type="number"
                label="Capacity"
                dense
                required
                placeholder="0"
                v-model="ticket.capacity"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                type="number"
                label="Kills"
                dense
                required
                placeholder="0"
                v-model="ticket.kills"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                type="number"
                label="Comps"
                dense
                required
                placeholder="0"
                v-model="ticket.comps"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                type="number"
                label="Other"
                dense
                required
                placeholder="0"
                v-model="ticket.other"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                readonly
                type="number"
                label="Sellable capacity"
                dense
                required
                v-model="ticket.sellable_capacity"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                id="percentageField"
                label="Est.Sales(%)"
                type="number"
                dense
                required
                v-model="percentage"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                id="percentageField"
                label="VAT"
                type="number"
                step="0.5"
                dense
                required
                :suffix="'%'"
                v-model="ticket.vat"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                id="percentageField"
                label="Commission"
                type="number"
                step="0.5"
                dense
                required
                :suffix="'%'"
                v-model="ticket.commission"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                type="number"
                :label="`Price inc VAT(${$config.CURRENCY})`"
                dense
                required
                placeholder="0"
                v-model="ticket.price"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field
                readonly
                class="number-text"
                :label="`Revenues inc VAT`"
                dense
                required
                :suffix="$config.CURRENCY"
                v-model="revenues"
                :value="revenues"
                filled
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2" class="pa-0 pr-2 d-flex">
            <div class="actions-wrapper pt-2 d-flex">
                <div class="platinum-container">
                    <span>Platinum</span>
                    <v-checkbox
                        true-value="1"
                        class="pt-0 ma-0 ml-3"
                        false-value="0"
                        v-model="ticket.platinum"
                        color="primary"
                        hide-details
                    >check</v-checkbox>
                </div>
                <v-spacer></v-spacer>
                <div class="actions">
                    <v-btn small icon color="error" @click.stop="openTicketDialog">
                        <v-icon>remove_circle_outline</v-icon>
                    </v-btn>
                    <comment-button
                        class="ml-1"
                        :name="ticket.section_name"
                        :data="{ comment: ticket.comment, budget_ticketid: ticket.budget_ticketid }"
                        :route="this.$getSubRoute('budgets','tickets')"
                        @setComment="(comment) => ticket.comment = comment"
                    />
                </div>
            </div>
        </v-col>

        <delete-dialog
            @deleteFromDialog="deleteTicketRevenue"
            @cancelDialog="cancelTicketDialog"
            :dialog="deleteTicketDialog"
            :item="ticket"
            :name="ticket.section_name"
        />

        <v-divider></v-divider>
    </v-row>
</template>

<script>
import DeleteDialog from "@/components/Dialogs/DeleteDialog";
import CommentButton from "@/components/Dialogs/Comment/CommentButton";

export default {
    components: {
        DeleteDialog,
        CommentButton,
    },

    props: {
        ticket: Object,
    },

    data: () => ({
        percentage: 100,
        price: "",
        revenues: "",
        deleteTicketDialog: false,
        commentDialog: false,
    }),

    async created() {
        this.percentage = this.ticket.sellable_percentage * 100;
        this.revenues = this.$thousandSeperator(this.ticket.revenues);
    },

    watch: {
        ticket: {
            handler: function (val) {
                console.log("### TICKET WATCHER ###");
                val.sellable_capacity =
                    val.capacity - val.kills - val.comps - val.other;
                this.price = this.$thousandSeperator(this.ticket.price);
                this.updateRevenue();
                console.log(this.ticket);
            },
            deep: true,
        },

        percentage() {
            if (this.percentage > 100) this.percentage = 99;
            if (this.percentage < 0) this.percentage = 1;

            this.ticket.sellable_percentage = this.percentage / 100;
            console.log(this.percentage / 100);
            this.updateRevenue();
        },
    },

    methods: {
        openTicketDialog() {
            this.deleteTicketDialog = true;
        },

        cancelTicketDialog() {
            this.deleteTicketDialog = false;
        },

        deleteTicketRevenue(ticket) {
            this.$emit("deleteTicket", ticket);
            this.cancelTicketDialog();
        },

        async updateRevenue() {
            console.log("updateTicketRevenue 1");
            this.ticket.revenues =
                this.ticket.sellable_capacity *
                this.ticket.sellable_percentage *
                this.ticket.price;
            this.revenues = this.$thousandSeperator(this.ticket.revenues);
        },
    },
};
</script>

<style lang="scss" scoped >
.platinum-container {
    flex: 1;
    float: left;
    height: 100%;
    margin-left: 20px;
	span{
		font-size:0.8rem;
	}
}

.actions-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    padding-top: 10px;
}
</style>