<template>
    <v-data-table
        :headers="tableHeaders"
        :items="items"
        :items-per-page="10"
        class="elevation no-hover"
        style="position:relative;"
    >
        <NoDataPlaceholder :text="'No data'" v-if="items.length === 0" />
        <template slot="no-data" style="position:relative">
            <Loader :loaderStyle="'free'"></Loader>
        </template>
        <template class="pa-0" v-slot:item.sold="{ item }">
            <v-progress-circular
                style="font-size: 12px;"
                :indeterminate="false"
                :rotate="-90"
                :size="40"
                :value="item.sold > 100 ? 100 : item.sold"
                :width="3"
                color="#505050"
            >{{ item.sold }}%</v-progress-circular>
        </template>
        
        <template v-slot:body.append>
            <div v-if="$vuetify.breakpoint.xsOnly" style="width:100%;float:left;" >
                <v-col cols="12" md="12">Total/Avg</v-col>
                <v-col
                    v-for="(value, key) in averages"
                    :key="value.id"
                    :class="checkIfNumber(value)"
                    cols="12"
                    md="12"
                    class="pa"
                >
                    <v-row>
                        <v-col cols="6" md="6" class="text-left py-0 d-flex align-center" style="font-size:0.9em;">
                            <b>{{ key.replace(/_/g, ' ').replace(/(^|\s)\S/g, l => l.toUpperCase())  }}</b>
                        </v-col>
                        <v-col cols="6" md="6" v-if="key !== 'sold'" class="py-2 " style="font-size:0.9em;">{{ value }}</v-col>
                        <v-col cols="6" md="6" style="font-size:0.7em;" v-else>
                            <v-progress-circular
                                style="font-size: 12px;"
                                :indeterminate="false"
                                :rotate="-90"
                                :size="50"
                                :value="value > 100 ? 100 : value"
                                :width="3"
                                color="#505050"
                            >{{ value }}%</v-progress-circular>
                        </v-col>
                    </v-row>
                </v-col>
            </div>
            <tr v-if="$vuetify.breakpoint.smAndUp && items.length > 0">
                <td colspan="3">TOTAL/AVG:</td>
                <td v-for="(value, key) in averages" :key="value.id" :class="checkIfNumber(value)">
                    <span v-if="key != 'sold'">
                        {{ value }}
                        <span v-if="key == 'break_even'">%</span>
                    </span>
                    <v-progress-circular
                        v-else
                        style="font-size: 12px;"
                        :indeterminate="false"
                        :rotate="-90"
                        :size="50"
                        :value="value > 100 ? 100 : value"
                        :width="3"
                        color="#505050"
                    >{{ value }}%</v-progress-circular>
                </td>
            </tr>
        </template>
        <template slot="no-data">No data</template>
    </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";


export default {
    name: "kpitable",
    props: {
        items: Array,
        averages: Array,
    },
    data: () => ({
        // tableHeaders: [
        //    'Date', 'City', 'Venue', 'Capacity', 'Break-even %', 'Turnover',
        //    'Total cost', 'Net', 'Avg. ticket price', 'Cost per ticket', 'Sold'
        // ],
    }),
    methods: {
        checkIfNumber(value) {
            return /^[0-9 || %]*$/gm.test(
                value.toString().replace(/ /g, "").charAt(0).replace(/-/, "")
            )
                ? "right"
                : "left";
        },
    },
    created() {
        console.log(this.items);
        this.tableHeaders = [];
        if (this.items.length > 0) {
            for (let key in this.items[0]) {
                if (this.items[0][key] == null) this.items[0][key] = "";
                this.items[0].sold = this.items[0].sold + "";
                let align = "left";
                if (key !== "date")
                    align = /^[0-9 || %]*$/gm.test(
                        this.items[0][key]
                            .replace(/ /g, "")
                            .charAt(0)
                            .replace(/-/, "")
                    )
                        ? "right"
                        : "left";
                console.log(align);
                this.tableHeaders.push({
                    text: key
                        .replace(/^\w/, (ch) => ch.toUpperCase())
                        .replace(/_/g, " "),
                    value: key,
                    align: align,
                });
            }
        }
    },
    methods: {
        checkIfNumber(value) {
            return /^[0-9 || %]*$/gm.test(
                value.toString().replace(/ /g, "").charAt(0).replace(/-/, "")
            )
                ? "right"
                : "left";
        },
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
.right {
    text-align: right;
}

.left {
    text-align: left;
}

.total-tr {
    td:last-child {
        padding-left: 12px !important;
        font-weight: 450;
    }
}
</style>