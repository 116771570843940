<template>
    <div class="card elevation-1 float-left pa-0">
        <v-col cols="12" md="12" class="float-left pa-0">
            <div class="card-title">Comparable ticket prices</div>
        </v-col>
        <v-col cols="12" md="12" class="pa-5 pb-0 float-left">
            <div class="concerts-button-wrapper d-flex align-center">
                <span v-for="concert in concerts" :key="concert.id">
                    <v-btn
                        :id="`btn_${concert.concertid}`"
                        depressed
                        large
                        class="mr-5"
                        @click="toggleConcert(concert.concertid)"
                    >
                        <v-icon left :color="concert.color">brightness_1</v-icon>
                        {{ concert.title }}
                    </v-btn>
                </span>
            </div>
            <v-progress-linear :active="isLoading" indeterminate />
            <v-col
                cols="12"
                md="12"
                class="float-left align-center pa-0"
                v-for="type in activeTickets"
                :key="type.id"
            >
                <div class="tickets-wrapper" v-if="dontInclude.length != concerts.length">
                    <div class="tickets-info elevation-1 float-left" style="width:100%;">
                        <div class="ticket-title" v-if="type.tickets.length > 0">{{ type.name }}</div>
                        <div
                            class="float-left"
                            v-for="ticket in type.tickets"
                            :key="ticket.id"
                            style="width:100%;"
                        >
                            <tickets-card
                                :currency="$config.CURRENCY"
                                :price="ticket.price"
                                :percentage="ticket.percentage"
                                :color="ticket.color"
                                :size="ticket.size"
                            />
                        </div>
                    </div>
                </div>
            </v-col>
        </v-col>
    </div>
</template>

<script>
import TicketsCard from "./TicketsCard";

export default {
    name: "compareticketprices",
    components: {
        TicketsCard,
    },
    props: {
        ticketTypes: Array,
        concerts: Array,
    },
    created() {
        console.log("compare ticket create");
        console.log(this.concerts);
    },
    data: () => ({
        isLoading: false,
        dontInclude: [],
    }),
    computed: {
        activeTickets() {
            let tmp = [];
            this.ticketTypes.map((type) => {
                tmp.push(Object.assign({}, type));
            });
            let colors = [];
            this.concerts.map((concert) => {
                if (this.dontInclude.indexOf(concert.concertid) != -1)
                    colors.push(concert.color);
            });
            tmp.map((event) => {
                let tmptickets = [];
                event.tickets.map((ticket) => {
                    if (colors.indexOf(ticket.color) == -1)
                        tmptickets.push(ticket);
                });
                event.tickets = tmptickets;
            });
            return tmp;
        },
    },
    methods: {
        toggleConcert(concertid) {
            let btn = document.getElementById(`btn_${concertid}`);

            if (btn.style.color == "rgb(230, 230, 230)")
                btn.style.color = "black";
            else btn.style.color = "rgb(230, 230, 230)";

            if (this.dontInclude.indexOf(concertid) == -1)
                this.dontInclude.push(concertid);
            else
                this.$delete(
                    this.dontInclude,
                    this.dontInclude.indexOf(concertid)
                );
        },
    },
};
</script>

<style lang="scss" scoped>
.card-title {
    border-top: 1px solid $border-color;
}

.tickets-wrapper {
    margin-bottom: 40px;
    float: left;
    width: 100%;
}
.tickets-info {
    border: 1px solid #e2e2e2;
}
.ticket-title {
    padding: 10px;
    font-size: 1rem;
    float: left;
    width: 100%;
    font-weight: 600;
    color: $font-color-pale;
    text-align: center;
    border-bottom: 1px solid $border-color;
    background: #f8f8f8;
}

.mobile .concerts-button-wrapper {
    display: block !important;
    .v-btn {
        width: 100%;
        max-width: 100% !important;
        margin-bottom: 15px;
    }
}
.concerts-button-wrapper {
    float: left;
    width: 100%;
    padding: 0px 0px;
    margin-bottom: 20px;
}
</style>