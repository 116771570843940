<template>
   <div class="progressioncard elevation-1">
		<div class="card-title">{{ title }}</div>
      <div class="py-3 px-5">
         <div class="number">{{ $thousandSeperator(number) }}<span>{{ subtext }}</span></div>
      </div>
   </div>
</template>

<script>
   export default {
      name:'simpleprogressioncard',
      props:{
			title:String,
			subtext:String,
			number:Number,
		}
   }
</script>

<style lang="scss" scoped>
   .progressioncard{
      position:relative;
      width:100%;
      background:#fff;
      min-height:120px;
      display:flex;
      flex-direction: column;
      border:1px solid $border-color;
      .number{
         font-size:2rem;
         font-weight:600;
         color:$font-color;
         margin:5px 0px;
         span{
            font-size:1rem;
            font-weight:400;
            color:$font-color-pale;
            text-transform:uppercase;
            margin-left:10px;
         }
      }
      .card-title{
         font-size:15px;
         text-transform: uppercase;
         font-weight:600;
         color:$font-color-pale;
         padding:10px 15px;
         border-bottom:1px solid $border-color;
         background:$card-title-bg;
      }
      .progresstext{
         float:right;
         font-weight:400;
         min-height:20px;
         width:100%;
         color:$font-color-pale;
         margin-bottom:5px;
         font-size:13px;
         text-transform:uppercase;
         flex:1;
         text-align:right;
      } 
   }

   @media only screen and (max-width: 960px) {
		.progressioncard{
			margin-bottom:10px;
		}
	}
</style>