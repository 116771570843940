import { GetService, PostService, DeleteService, PutService } from '@/api/apiServices.js';
import store from './../store/modules/metadata';

export default class ApiHandler {
	constructor(endpoint) {
		this.endpoint = endpoint;
		this.pk_field = null;
		this.metaFields = null;
		this.updateFields = null;
		this.createFields = null;
	}

	async getMetaFields(type = 'fields') {
		let keyname = type == 'fields' ? 'metaFields' : `${type}Fields`
		if (!this[keyname])
			this[keyname] = store.getters.meta_data(store.state)[this.endpoint][type];

		return this[keyname];
	}
	async getData(route = '') {
		try {
			const res = await GetService(`${this.endpoint}${route}`);
			if (res.status == 200) {
				return res.data;
			}
		} catch (error) {
			console.log(error);
		}
		return false;
	}

	async postData(obj) {
		try {
			const res = await PostService(`${this.endpoint}`, obj);
			if (res.status == 200) {
				if (Array.isArray(res.data))
					return res.data;
				else
					return res.data[this.pk_field];

			}
		} catch (error) {
			console.log(error);
		}
		return false;
	}

	async putData(obj) {
		try {
			const res = await PutService(`${this.endpoint}`, obj)
			console.log(res);
			if (res.status == 200)
				return true;
		} catch (error) {
			console.log(error);
		}
		return false;
	}

	async deleteData(obj) {
		try {
			const res = await DeleteService(`${obj.endpoint}`, { [obj.pk_field]: obj[obj.pk_field] });
			console.log(res);
			if (res.status == 200) {
				return true
			}
		} catch (error) {
			console.log(error);
		}
		return false;
	}

	static async PutGenericData(endpoint, obj) {
		let temp = new ApiHandler(endpoint);
		await temp.putData(obj);
	}
}