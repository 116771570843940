
<script>

import { Line } from 'vue-chartjs' 
import chartzoom from 'chartjs-plugin-zoom'


export default {
   extends: Line,

   props: {
      dataLeft: Array,
      dataRight: Array,
      labelLeft: String,
      labelRight: String,
      data: Array,
      options: Object
   },

   mounted() {
      this.addPlugin(chartzoom)
      if(this.options != null)
         this.chartOptions = this.options

      // if(this.data != null){
      //    this.datasets = this.data
      // }else {
      //    this.datasets[0].label = this.labelLeft
      //    this.datasets[0].data = this.dataLeft
      //    this.datasets[1].label = this.labelRight
      //    this.datasets[1].data = this.dataRight
      // }

      this.renderLineChart()
   },

   created() {
      //console.log(window.$data)
      this.$bus.$on('resetZoom', this.reset)
      this.$bus.$on('toggleZoom', this.toggleZoom)

      if(this.data != null){
         this.datasets = this.data
      }else {
         this.datasets[0].label = this.labelLeft
         this.datasets[0].data = this.dataLeft
         this.datasets[1].label = this.labelRight
         this.datasets[1].data = this.dataRight
      }
      this.chartOptions.scales.yAxes[0].scaleLabel.labelString = this.labelLeft
      this.chartOptions.scales.yAxes[1].scaleLabel.labelString = this.labelRight
   },

   updated(){
      console.log('updated')
   },

   computed:{
      // chartDataLeft: function(){
      //    return this.dataLeft
      // },
      // chartDataRight: function(){
      //    return this.dataRight
      // },
      // chartLabelLeft: function(){
      //    return this.labelLeft
      // },
      // chartLabelRight: function(){
      //    return this.labelRight
      // },
      // customOptions: function(){
      //    return this.options
      // }
   },

   data: ()=> ({
      isZoomEnabled: false,
      datasets: [
         {
            label: '',
            borderColor: '#4152bf',
            data: [],
            fill: false,
            yAxisID: 'yAxis1',
            lineTension: 0.2,
         }, 
         {
            label: '',
            borderColor: '#cc4545',
            data: [],
            fill: false,
            yAxisID: 'yAxis2',
            lineTension: 0.2
         }
      ], 
      chartOptions: {
            responsive: true,
            maintainAspectRatio: false,

            scales: {
               yAxes: [
                  {
                     position: 'left',
                     id: 'yAxis1',
                     ticks: {
                        // min: 0, 
                        //max: 0
                     },
                     scaleLabel: {
                        display: true,
                        labelString: ''
                     }
                  },
                  {
                     position: 'right',
                     id: 'yAxis2',
                     ticks: {
                        // min: 0,
                        // max: 1000 
                       //source: auto
                     },
                     scaleLabel: {
                        display: true,
                        labelString: ''
                     },
                     gridLines:{
                        display: false
                     }
                     
                  }
               ], 
               xAxes: [{
                  type: 'time',
                  // time: {
                  //    unit: 'month'
                  // }
                   ticks: {
                      autoSkip: true,
                      maxTicksLimit: 20
                   },
                  //labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
               }]
            },
            pan: {
               enabled: false,
               mode: "xy",
               speed: 10,
               threshold: 10,

               // Function called while the user is panning
               //onPan: function({chart}) { console.log(`I'm panning`); },
               // Function called once panning is completed
               //onPanComplete: function({chart}) { console.log(`I was panned`); }
            },
            zoom: {
               enabled: false,
               drag: false,
               mode: "x",
               limits: {
                  max: 10,
                  min: 0.5,
               },
               // Function called while the user is zooming
               // onZoom: function({chart}) { 

               // },
               // Function called once zooming is completed
               //onZoomComplete: function({chart}) { console.log(`I was zoomed!!!`); }
            },
            animation: {

            },
            // hover: {
            //    mode: 'x',
            //    intersect: false,
            // },
            tooltips: {
               // include lines of same x index
               mode: 'nearest',
               intersect: false,
            },
            legend: {
               labels: {
                  // This more specific font property overrides the global property
                  fontColor: 'black'
               }
            }
            
         },
   }),

   methods: {
      renderLineChart() {
         if (this.$data._chart) 
            this.$data._chart.destroy()

         this.renderChart({datasets: this.datasets}, this.chartOptions);      
      },
      reset() {
         this.$data._chart.resetZoom()
      },
      toggleZoom() {
         this.isZoomEnabled = !this.isZoomEnabled
         this.chartOptions.pan.enabled = this.isZoomEnabled
         this.chartOptions.zoom.enabled = this.isZoomEnabled
         this.renderLineChart()
      }
   },

   watch: {
      'data': {
         handler(val){
            console.log('data')
           // console.log(val)
            this.datasets = val
            this.renderLineChart()
         },
         deep: true,
      },
      'dataLeft': {
         handler(val){
            console.log('dataLeft')
            this.datasets[0].data = val
            this.renderLineChart()
         }
      },
      'dataRight': {
         handler(val){
            console.log('dataRight')
            this.datasets[1].data = val
            this.renderLineChart()
         }
      }
   },
}

</script>