<template>
   <div class="text-center">
      <v-dialog
         v-model="addTodoDialog"
         width="500"
         persistent
      >
         <v-card>
             <transition name="fade">
            <div class="todo-sent" v-if="sentMessage">
               <div class="todo-sent-wrapper">
                  <div class="sent-icon-wrapper">
                     <i class="material-icons">done</i>
                  </div>
                  <div class="" style="color:#93dc78;margin-top:30px;font-size:25px;text-transform:uppercase;letter-spacing:1px;text-align:center;font-weight:600;">Todo sent! </div>
                  <div class="message">Todo successfully sent to</div>
                  <div style="font-size:25px;color:#666;text-align:center;font-weight:500;margin-top:10px;">{{ sentToUser }} </div>
               </div>
            </div>
             </transition>
            <div class="header">Add todo</div>
            <v-container fluid>
               <v-form
                  v-model="valid"
                  lazy-validation
                  ref="form"
               >
                  <v-row align="center">
                     <v-col cols="12" md="12" v-if="view !== 'artists' && view !== 'concerts' && view !== 'tours'">
                        <v-select
                           :items="concerts"
                           label="Select concert"
                           item-text="title"
                           item-value="concertid"
                           v-model="newTodo.concertid"
                           :disabled="isAddTodoPending || artistHasValue"
                           clearable
                        ></v-select>
                     </v-col>
                      <v-col cols="12" md="12" v-if="view !== 'artists' && view !== 'concerts' && view !== 'tours'">
                        <v-select
                           :items="artists"
                           label="Select artist"
                           item-text="name"
                           item-value="artistid"
                           v-model="newTodo.artistid"
                           :disabled="isAddTodoPending || concertHasValue"
                           clearable
                        ></v-select>
                     </v-col>
                     <v-col class="d-flex">
                        <v-select
                           :items="users"
                           required
                           label="Select User"
                           :item-text="itemTextName"
                           item-value="userid"
                           v-model="newTodo.ownerid"
                           :rules="inputRules"
                           :disabled="isAddTodoPending"
                        ></v-select>
                     </v-col>
                     <v-col class="d-flex" cols="12" sm="12">
                        <v-text-field
                           label="Todo Title"
                           required
                           v-model="newTodo.title"
                           :rules="inputRules"
                           :disabled="isAddTodoPending"
                           
                        ></v-text-field>
                     </v-col>
                     <v-col class="d-flex" cols="12" sm="12">
                        <v-text-field
                           label="Todo Description"
                           required
                           v-model="newTodo.body"
                           :rules="inputRules"
                           :disabled="isAddTodoPending"
                        ></v-text-field>
                     </v-col>
                  </v-row>
               </v-form>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="#666" small text @click="closeAddTodoDialog">
                  Cancel
               </v-btn>

               <v-btn color="primary" depressed :loading="isAddTodoPending" small @click="addTodo">
                  Add
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>

import { setTimeout } from 'timers';
   export default {
      name:'Addtodo',
      props:{
         concerts:Array,
         artists:Array,
         users:Array,
         addTodoDialog: Boolean,
         isAddTodoPending: Boolean,
         sentToUser:String,
         view:String
      },
      data:()=>({
         sentMessage:false,
         concertHasValue: false,
         artistHasValue:false,
         sentIconAnim:true,
         newTodo:{
            title:'',
            body:'',
            ownerid:'',
            concertid:'',
            artistid:'',
            tourid:''
         },
         valid:false,
         inputRules: [
            v => !!v || ''
         ]
      }),
      watch:{
         'newTodo':{
            handler:function(){
               if(this.newTodo.concertid != ''){
                  this.concertHasValue = true
               } else {
                  this.concertHasValue = false
               }
               if(this.newTodo.artistid != ''){
                  this.artistHasValue = true
               } else {
                  this.artistHasValue = false

               }
               if(this.newTodo.concertid === undefined){
                  this.newTodo.concertid = ''
               }
               if(this.newTodo.artistid === undefined){
                  this.newTodo.artistid = ''
               }
            },
            deep:true
         }

      },
      created(){
         this.$bus.$on('showSentMessageAndHide', this.showSentMessageAndHide)
      },
      methods:{
         itemTextName: item => item.fullname,
         addTodo: function(){
            if (this.$refs.form.validate()) {
               this.$emit('addTodo', this.newTodo)
            }
         },
         closeAddTodoDialog(){
            this.$emit('closeAddTodoDialog')
         },
         showSentMessageAndHide(){
            this.sentMessage = true
            setTimeout(() => {
               this.$emit('closeAddTodoDialog')
               this.sentMessage = false
            }, 3000);
         }
      },
      
   }
</script>

<style lang="scss" scoped>

.todo-sent{
   position: absolute;
   left: 0px;
   right: 0px;
   background: #fff;
   width: 100%;
   height: 100%;
   z-index: 1;
   display:flex;
   .todo-sent-wrapper{
      margin:auto;
      .sent-icon-wrapper {
         border:6px solid #93dc78;
         border-radius:300px;
         height:60px;
         width:60px;
         display:flex;
         margin:auto;
         i{
            transition-delay: 1s;
            font-size:40px;
            margin:auto;
            color:#93dc78;
         }
      }
      .message{
         margin-top:30px;
         text-transform: uppercase;
         color:#9c9c9c;
         text-align:center;
         font-size:15px;
         font-weight:500;
      }
   }
}

.header{
	font-size:15px!important;
	text-transform:uppercase;
	color:$accent-color;
	font-weight:600!important;
	padding:16px 24px!important;

}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}

</style>