<template>
    <div>
        <div class="info-text">
            Once you click “Create new” on the Concert browse page you are
            redirected to a page with input fields<br />
            for information regarding a concerts. Depending on the concert type
            you’re adding different input fields will be required.
        </div>
        <img src="/" />
        <img src="/" />
        <div class="info-text">
            The application will let you know if you miss filling out any
            required fields. <br />These are important to the system in order to
            function properly. <br />Remember to fill out your information with
            care.<br />
            Good data is key for a working system and for you when working with
            your concert later on.
        </div>
        <img src="/" />
        <div class="info-text">
            Don’t forget to save before leaving the page. <br/>If you want to add
            information at a later stage you will do so in a sidebar on the
            “concert insights”-page.
        </div>
        <img src="/" />
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>