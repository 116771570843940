<template>
    <div>
        <div class="card-title pa-5 text-left">Copy marketing from budget</div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Name</th>
                        <th class="text-right pr-10">Value</th>
                    </tr>
                </thead>
                <tbody style="background: #f0f0f0">
                    <div class="pa-5 text-center" style="background:#fff;color:#888;" v-if="!allMarketingTypes"> No data available </div>
                    <tr
                        v-for="marketing in allMarketingTypes"
                        :key="marketing.name"
                    >
                        <td style="max-width: 20px">
                            <v-btn icon color="primary" @click="$bus.$emit('copyFromBudget', { name: marketing.name, estimate: marketing.value})">
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </td>
                        <td class="marketing-name">
                            {{ marketing.name.replace(/_/g, " ") }}
                        </td>
                        <td class="text-right pr-5">
                            {{ marketing.value }}
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    name: "budgetmarketingtable",
    props: {
        allMarketingTypes: Array,
    },
    data: () => ({}),
};
</script>

<style scoped lang="scss">
thead {
    box-shadow: 0px 3px 8px #00000024;
    transform: translate(0, 0);
    tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
}

tbody {
    display: block;
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
    float: left;
    tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
}

.marketing-name {
    text-transform: capitalize;
}
</style>