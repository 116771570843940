<template>
    <v-container class="wizard-budget-container summary">
        <v-col cols="12" md="12" class="d-flex align-center">
            <!-- <v-btn class="expand-button" icon @click="expandConcertBudgetContainer">
                <v-icon v-if="budgetContainerIsExpanded">mdi-arrow-expand-left</v-icon>
                <v-icon v-else>mdi-arrow-expand-right</v-icon>
            </v-btn>-->
            <v-btn
                class="float-left toggle-summary-btn"
                dark
                small
                @click="expandConcertBudgetContainer"
            >
                <v-icon :class="{ rotate: rotateIcon }" small color="#fff">mdi-arrow-expand-right</v-icon>
            </v-btn>
            <div class="summary-header ml-5">Show Budget Summary</div>
        </v-col>
        <v-progress-linear :active="!isSummaryLoaded" indeterminate />

        <div v-if="isSummaryLoaded" :key="dataChanged">
            <!-- REVENUES -->
            <div class="pa-3 summary-wrapper">
                <h3 class="summary-title">Revenues</h3>

                <summary-row
                    :revenue="true"
                    title="Ticket Sales Inc VAT"
                    :value="ticketRevenues"
                    :currency="currency"
                    :items="ticketItems"
                />
                <summary-row
                    :revenue="true"
                    title="Other revenues Inc VAT"
                    :value="otherRevenues"
                    :currency="currency"
                    :items="otherRevenueItems"
                />
                <summary-total-row
                    :revenue="true"
                    title="Total revenues Inc VAT"
                    :value="totalRevenues"
                    :currency="currency"
                />
                <!-- REVENUES-END -->
            </div>

            <!-- COSTS -->
            <div class="pa-3 summary-wrapper">
                <h3 class="summary-title">Expenses</h3>

                <summary-row
                    title="Variable costs"
                    :value="totalVariableCosts"
                    :currency="currency"
                    :items="variableCosts"
                />
                <summary-row
                    title="Production costs"
                    :value="totalProductionCosts"
                    :currency="currency"
                    :items="productionCosts"
                />
                <summary-row
                    title="Marketing costs"
                    :value="totalMarketingCosts"
                    :currency="currency"
                    :items="marketingCosts"
                />
                <summary-row
                    title="General costs"
                    :value="totalGeneralCosts"
                    :currency="currency"
                    :items="generalCosts"
                />
                <summary-total-row 
                    title="Total costs" 
                    :value="totalCosts" 
                    :currency="currency" 
                />
            </div>
            <!-- COSTS-END -->

            <!-- ARTIST DEAL -->
            <div class="pa-3 summary-wrapper">
                <h3 class="summary-title">Artist deal</h3>

                <summary-row
                    title="Talent costs"
                    :value="totalTalentCosts"
                    :currency="currency"
                    :items="talentCosts"
                />
                <summary-total-row
                    title="Total talent costs"
                    :value="totalTalentCosts"
                    :currency="currency"
                />
            </div>
            <!-- ARTIST DEAL-END -->

            <!-- PROFIT -->
            <div class="pa-3 pt-2 pb-0">
                <summary-total-row
                    :revenue="true"
                    title="Show net profit"
                    :value="netProfit"
                    :currency="currency"
                />

                <summary-total-row
                    :revenue="true"
                    title="Live Nation profit after split"
                    :value="splitNetProfit"
                    :currency="currency"
                />
            </div>
            <!-- PROFIT-END -->

            <!-- Global currency toggle -->
            <div class="pa-5 py-0">
                <v-row>
                    <v-col cols="12" md="2">
                        <v-switch
                            class="pt-0 float-right"
                            v-model="currencyConvert"
                            color="primary"
                            dark
                            label
                            :disabled="currencyRate === ''"
                        />
                    </v-col>
                    <v-col cols="12" md="7" class="pt-5">
                        <v-text-field
                            dense
                            required
                            label="Calculate for other currency"
                            v-model="currencyName"
                            dark
                            style="color:red;"
                        />
                    </v-col>

                    <v-col cols="12" md="3" class="pt-5">
                        <v-text-field
                            style="text-align:right"
                            type="number"
                            dense
                            required
                            label="Rate"
                            dark
                            v-model="currencyRate"
                        />
                    </v-col>
                </v-row>
            </div>
            <!-- <v-btn @click="logdata">Log</v-btn> -->
        </div>

        <table v-show="false" width="400">
            <tr>
                <th colspan="2">Revenues</th>
            </tr>

            <tr>
                <th colspan="2">Ticket sales</th>
            </tr>
            <tr>
                <th>Name</th>
                <th>Revenue ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of ticketItems" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.value }}</td>
            </tr>
            <tr v-if="otherRevenueItems.length > 0">
                <th colspan="2">Other revenues</th>
            </tr>
            <tr v-if="otherRevenueItems.length > 0">
                <th>Name</th>
                <th>Revenue ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of otherRevenueItems" :key="item.id">
                <td v-if="otherRevenueItems.length > 0">{{ item.name }}</td>
                <td v-if="otherRevenueItems.length > 0">{{ item.value }}</td>
            </tr>
            <tr>
                <td>Total revenues</td>
                <td>{{ totalRevenues }}</td>
            </tr>

            <tr>
                <th colspan="2">Costs</th>
            </tr>
            <tr>
                <th colspan="2">Variable costs</th>
            </tr>
            <tr>
                <th>Name</th>
                <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of variableCosts" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.value }}</td>
            </tr>
            <tr>
                <th colspan="2">Production costs</th>
            </tr>
            <tr>
                <th>Name</th>
                <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of productionCosts" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.value }}</td>
            </tr>
            <tr>
                <th colspan="2">Marketing costs</th>
            </tr>
            <tr>
                <th>Name</th>
                <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of marketingCosts" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.value }}</td>
            </tr>
            <tr>
                <th colspan="2">General costs</th>
            </tr>
            <tr>
                <th>Name</th>
                <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of generalCosts" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.value }}</td>
            </tr>
            <tr>
                <td>Total costs</td>
                <td>{{ totalCosts }}</td>
            </tr>

            <tr>
                <th colspan="2">Artist deal</th>
            </tr>
            <tr>
                <th colspan="2">Talent costs</th>
            </tr>
            <tr>
                <th>Name</th>
                <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of talentCosts" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.value }}</td>
            </tr>
            <tr>
                <td>Total talent costs</td>
                <td>{{ totalTalentCosts }}</td>
            </tr>
            <tr>
                <td>Net profit</td>
                <td>{{ netProfit }}</td>
            </tr>
            <tr>
                <td>Live nation profit after split</td>
                <td>{{ splitNetProfit }}</td>
            </tr>
        </table>
    </v-container>
</template>

<script>
import { calcPrs } from "../ConcertBudgetWizard/Shared/PrsTypes.js";
import SummaryRow from "./SummaryRow";
import SummaryTotalRow from "./SummaryTotalRow";

export default {
    name: "concertbudgetsummary",

    components: {
        SummaryRow,
        SummaryTotalRow,
    },

    props: {},

    data: () => ({
        dataChanged: 1,
        currency: "",
        isSummaryLoaded: false,
        budgetData: {},

        variableCosts: [],
        productionCosts: [],
        marketingCosts: [],
        generalCosts: [],
        artistDeal: [],

        nameObj: {},

        currencyConvert: false,
        currencyName: "",
        currencyRate: "",
        rotateIcon: false,
        excelData: [],
    }),

    created() {
        console.log("summary created");
        this.$bus.$on("ExportBudgetToExcel", this.exportBudgetToExcel);
        this.$bus.$on("receiveBudgetData", this.setBudgetData);

        this.currency = this.$config.CURRENCY;

        this.$bus.$on("updateBudgetSummary", this.updateAllCosts);
        this.$bus.$emit("requestBudgetData");
        if(this.$vuetify.breakpoint.mdAndDown){
            this.rotateIcon = true
        }
    },

    watch: {
        "budgetData.stepThree.budget_crew_calls": {
            handler(val) {
                console.log("budget_crew_calls watch");
                // let arr = this.getCostArray(val)
                // let bestDeal = arr[0]
                // arr.map(c => bestDeal.value < c.value ? bestDeal = c : null)
                // bestDeal.value = (this.currencyRate > 0 && this.currencyConvert) ? bestDeal.value / this.currencyRate : bestDeal.value
                // this.artistDeal = []
                // this.artistDeal.push(bestDeal)
            },
            deep: true,
        },

        budgetData: {
            handler(val) {
                console.log("budgetdata watch in summary");
                //this.$bus.$emit('requestBudgetData')
                this.updateAllCosts();
                //this.dataChanged++
            },
            deep: true,
        },
        currencyConvert(val) {
            if (val) this.currency = this.$getCurrency(this.currencyName);
            else this.currency = this.$config.CURRENCY;

            this.updateAllCosts();
        },

        '$vuetify.breakpoint.mdAndDown':{
            handler:function(v){
                if(v)
                    this.rotateIcon = true
                else {
                    this.rotateIcon = false
                }
            }
        }
    },

    computed: {
        totalTicketAmount() {
            let amount = 0;
            this.budgetData.stepOne.tickets.map((show) => {
                for (let ticket of show)
                    amount +=
                        (ticket.capacity -
                            ticket.kills -
                            ticket.comps -
                            ticket.other) *
                        ticket.sellable_percentage;
            });
            return amount;
        },

        ticketItems() {
            let tickets = [];
            this.budgetData.stepOne.tickets.map((show) => {
                for (let ticket of show)
                    tickets.push({
                        name: ticket.section_name,
                        value: this.getOneTicketRevenue(ticket),
                    });
            });
            return tickets;
        },
        ticketRevenues() {
            let revenues = 0;
            this.budgetData.stepOne.tickets.map((show) =>
                show.map(
                    (ticket) => (revenues += this.getOneTicketRevenue(ticket))
                )
            );
            return revenues;
        },

        otherRevenueItems() {
            let otherItems = [];
            this.budgetData.stepOne.otherRevenues.map((item) => {
                otherItems.push({
                    name: item.name,
                    value: item.value,
                });
            });
            return otherItems;
        },
        otherRevenues() {
            return this.getTotalAmount(this.otherRevenueItems);
        },

        totalRevenues() {
            // ta bort moms från ticket revenues
            return this.ticketRevenues + this.otherRevenues;
        },

        totalVariableCosts() {
            return this.getTotalAmount(this.variableCosts);
        },

        totalProductionCosts() {
            return this.getTotalAmount(this.productionCosts);
        },

        totalMarketingCosts() {
            return this.getTotalAmount(this.marketingCosts);
        },

        totalGeneralCosts() {
            return this.getTotalAmount(this.generalCosts);
        },

        totalCosts() {
            return (
                this.totalVariableCosts +
                this.totalProductionCosts +
                this.totalMarketingCosts +
                this.totalGeneralCosts
            );
        },

        talentCosts() {
            return this.artistDeal;
        },
        totalTalentCosts() {
            return this.getTotalAmount(this.artistDeal);
        },

        netProfit() {
            return this.totalRevenues - this.totalCosts - this.totalTalentCosts;
        },
        splitNetProfit() {
            let split = this.budgetData.stepOne.generalData.coSplit;
            return split.active ? this.netProfit * (split.value / 100) : "";
        },
    },

    methods: {
        expandConcertBudgetContainer(){
            this.$bus.$emit('setExpandConcertBudgetContainer')
            this.rotateIcon = !this.rotateIcon
        },
        updateAllCosts(allCosts = true) {
            console.log("updateAllCosts");
            if (allCosts) {
                this.variableCosts = this.getCostArray(
                    this.budgetData.stepTwo.costs
                );
                this.marketingCosts = this.getCostArray(
                    this.budgetData.stepFour.costs
                );
                this.generalCosts = this.getCostArray(
                    this.budgetData.stepSix.costs.concat(
                        this.budgetData.stepSix.specialCosts
                    )
                );

                // step five is special
                let arr = this.getCostArray(this.budgetData.stepFive.costs);
                let bestDeal = arr[0];
                arr.map((c) =>
                    bestDeal.value < c.value ? (bestDeal = c) : null
                );
                this.artistDeal = [];
                this.artistDeal.push(bestDeal);
            }
            this.productionCosts = this.getCostArray(
                this.budgetData.stepThree.costs
            );
        },
        getOneTicketRevenue(ticket) {
            //console.log(ticket)
            let rate = 1;
            if (this.currencyRate > 0 && this.currencyConvert)
                rate = this.currencyRate;
            return Math.round(
                ((ticket.capacity -
                    ticket.kills -
                    ticket.comps -
                    ticket.other) *
                    ticket.sellable_percentage *
                    ticket.price) /
                    rate
            );
        },
        getVatMultiplier() {
            return (
                1 -
                this.budgetData.stepTwo.costs.find((c) => c.name == "vat")
                    .value /
                    100
            );
        },
        getTotalAmount(items) {
            let amount = 0;
            items.map((i) => (amount += +i.value));
            return amount;
        },
        getCostArray(costs) {
            let costArr = [];

            costs.map((cost) => {
                costArr.push({
                    name: cost.display_name,
                    value:
                        this.currencyRate > 0 && this.currencyConvert
                            ? Math.round(
                                  cost.calculated_cost / this.currencyRate
                              )
                            : cost.calculated_cost,
                });
            });
            return costArr;
        },
        setBudgetData(data) {
            console.log("summary data");
            console.log(data);

            this.budgetData = data;
            this.updateAllCosts();

            setTimeout(() => {
                this.isSummaryLoaded = true;
            }, 2000);
        },

        setCurrency(cur) {
            if (cur) cur = cur.toLowerCase();
            if (cur == "dollar" || cur == "$") this.currency = "$";
            else if (cur == "euro" || cur == "€") this.currency = "€";
            else if (
                cur == "GBP" ||
                cur == "pound" ||
                cur.includes("british") ||
                cur == "pund" ||
                cur == "£"
            )
                this.currency = "£";
            else if (cur == "") this.currency = "";
            else this.currency = cur;
        },

        exportBudgetToExcel(filename) {
            let table = document.querySelector("table");
            let tableHTML =
                '<html><head><meta%20http-equiv="Content-Type"%20content="text/html;%20charset=utf-8"></meta></head><body>' +
                table.outerHTML.replace(/ /g, "%20") +
                "</body></html>";

            // Specify file name
            filename = `${filename}.xls`;

            // Create download link element
            let downloadLink = document.createElement("a");
            let dataType = "application/vnd.ms-excel;";

            document.body.appendChild(downloadLink);

            if (navigator.msSaveOrOpenBlob) {
                var blob = new Blob(["\ufeff", tableHTML], {
                    type: dataType,
                });

                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                // Create a link to the file
                downloadLink.href = "data:" + dataType + ", " + tableHTML;

                // Setting the file name
                downloadLink.download = filename;

                //triggering the function
                downloadLink.click();
            }
        },

        logdata() {
            console.log("#### All budget data ####");
            console.log(this.budgetData);
            console.log("#### variableCosts ####");
            console.log(this.variableCosts);
            console.log("#### artistDeal ####");
            console.log(this.artistDeal);
        },
    },
};
</script>

<style lang="scss" >


.wizard-budget-container.summary{
    overflow:auto;
    overscroll-behavior: contain;
    height:100%;
}

.rotate{
    transform:rotate(180deg)
}

.toggle-summary-btn{
    position:absolute;
    left:-25px;
    top:13px;
    min-width:25px!important;
    max-width:25px!important;
    border-radius:10px;
    background:#143a5d!important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    i{
        transition:all 1s ease;
    }
}

.divider {
    margin-top: 10px;

    border-color: $accent-color !important;
}

.summary-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.151);
}

.summary-title {
    font-size: 15px;
    color: $accent-color;
    margin-bottom: 10px;
}

.summary-text {
    font-size: 14px;
    font-weight: 300;
    color: #3c444d;

    input[type="number"] {
        text-align: right;
    }
    .number-text {
        input {
            text-align: right;
        }
    }
}
.summary-total {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border: 1px solid #ffffff26;
    margin-top: 10px;
    border-radius: 4px;
    padding: 10px;
    background: #237bcf36;
    box-shadow: 0px 0px 6px #000;

    input[type="number"] {
        text-align: right;
    }
    .number-text {
        input {
            text-align: right;
        }
    }
}
</style>