<template>
    <div id="recipient-form">
        <div class="getaccept-title">Add recipient</div>
        <v-form ref="form" lazy-validation v-model="valid">
            <v-row style="display:flex;align-items:center;">
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="formItems.first_name"
                        :rules="nameRules"
                        label="First name"
                        required
                        outlined
                        dense
                        hide-details
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="formItems.last_name"
                        :rules="nameRules"
                        label="Last name"
                        required
                        dense
                        outlined
                        hide-details
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="formItems.email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                        dense
                        outlined
                        hide-details
                    />
                </v-col>
                <v-col cols="12" md="12" class="d-flex pb-0">
                    <v-spacer></v-spacer>
                    <v-checkbox
                        label="Signer"
                        v-model="formItems.isSigner"
                        class="sign-checkbox mt-0 mr-5"
                    />
                    <v-btn
                        depressed
                        :disabled="invalidInput"
                        @click="addRecipient"
                        color="primary"
                        small
                    >Add recipient</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <div class="recipient-row">
            <v-list style="padding:0px;overflow:hidden;">
                <transition-group name="slider" tag="div" mode="out-in">
                    <v-list-item
                        v-for="(recipient, index) of recipients"
                        :key="recipient.email"
                        class="pa-0"
                        style="border-bottom:1px solid #e2e2e2;"
                    >
                        <div class="recipient">
                            <div class="float-left user-info">
                                <span class="name">
                                    <v-icon
                                        color="#f6921e"
                                        class="mr-1"
                                        style="font-size:20px;"
                                    >mdi-account</v-icon>
                                    {{ getRecipientName(recipient) }}
                                </span>
                                <span class="email">
                                    <v-icon
                                        color="primary"
                                        class="mr-2"
                                        style="font-size:20px;"
                                    >email</v-icon>
                                    {{ recipient.email }}
                                </span>
                            </div>
                            <div class="chip-container">
                                <span class="chip owner ml-3" v-if="recipient.isOwner">Owner</span>
                                <span
                                    class="chip agreed-with ml-3"
                                    v-if="recipient.agreed_with"
                                >Agreed with</span>
                                <span class="chip signer ml-3">{{ recipient.role }}</span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-checkbox
                                label="Signer"
                                true-value="signer"
                                false-value="cc"
                                v-model="recipient.role"
                                dense
                                hide-details
                            />
                            <v-btn
                                small
                                icon
                                class="ml-4"
                                color="primary"
                                :disabled="recipient.isOwner"
                                @click="removeRecipient(index)"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </div>
                    </v-list-item>
                </transition-group>
            </v-list>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "recipientform",
    props: {
        owner: Object,
        agreed_with: Object,
    },
    data: () => ({
        valid: false,
        formItems: { firstName: "", lastName: "", email: "", isSigner: false },
        isSigner: false,
        recipients: [],
        nameRules: [(v) => !!v || "Name is required"],
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
    }),
    watch: {
        owner(owner) {
            console.log(owner.email);
            console.log(this.recipients.length);
            if (this.recipients.length === 0 && owner.email) {
                this.recipients.push(this.getRecipientObject(owner));
                this.recipients.push(this.getRecipientObject(this.agreed_with));
            } else if (owner.email) {
                const oldOwnerIndex = this.recipients.indexOf(
                    this.recipients.find((owner) => owner.isOwner)
                );

                this.$set(
                    this.recipients,
                    oldOwnerIndex,
                    this.getRecipientObject(owner)
                );
            }
        },
        recipients(items) {
            console.log("recipiencts");
            console.log(items);
            this.$emit("updatedRecipients", items);
        },
    },
    created() {
        this.$bus.$on("resetRecipientForm", this.resetRecipients);
    },
    methods: {
        addRecipient() {
            if (this.$refs.form.validate()) {
                this.recipients.unshift(
                    this.getRecipientObject(this.formItems)
                );
                this.$refs.form.reset();
            }
        },
        getRecipientObject(object) {
            console.log(object);
            return {
                first_name: object?.first_name,
                last_name: object?.last_name,
                fullname: object?.name,
                email: object?.email,
                role: object?.isSigner ? "signer" : "cc",
                isOwner: object?.isOwner,
                agreed_with: object?.agreed_with,
            };
        },
        removeRecipient(index) {
            console.log(index);
            this.$delete(this.recipients, index);
        },
        getRecipientName(recipient) {
            return recipient.fullname
                ? recipient.fullname
                : `${recipient.first_name} ${recipient.last_name}`;
        },
        resetRecipients() {
            this.recipients = [];
        },
    },
};
</script>

<style lang="scss" scoped>
#recipient-form {
    padding: 0px 15px;
    border-radius: 3px;
    margin-top: 15px;

    .recipient {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        height: 65px;
        width: 100%;
        background: #fff;
        transition: all 0.2s ease;
        &:hover {
            background: #f8f8f8;
        }
    }
    .v-input{
        margin:0;
    }
    .user-info {
        .name {
            color: $font-color;
            font-size: 0.9rem;
            margin-right: 20px;
            font-weight: 500;
            min-width: 240px;
            max-width: 240px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            float: left;
            i {
                background: #f6921e;
                color: #fff !important;
                border-radius: 100px;
                padding: 5px;
                font-size: 18px !important;
                margin-right: 10px !important;
            }
        }
        .email {
            font-size: 0.9rem;
            color: #8c8c8c;
            i {
                margin-left: 0px;
            }
        }
    }
    .chip-container {
        .chip {
            font-size: 0.7rem;
            padding: 3px 10px;
            border-radius: 100px;
            font-weight: 500;
            color: #fff;
            text-transform: uppercase;
        }
        .chip.owner {
            background: rgb(255, 103, 103);
        }
        .chip.signer {
            background: $accent-color;
        }
        .chip.agreed-with {
            background: #65cb3b;
        }

        .v-label {
            font-size: 0.9rem;
        }
    }
}

.mobile #recipient-form{
    .recipient {
        height: auto;
        padding: 10px;
        width: 100%;
        float: left;
        display: block !important;
        .user-info {
            width: 100%;
            float: left;
            .name{
                width:100%;
                max-width:100%;
                margin:0;
                padding-right:25px;
            }
            .email {
                padding-left: 3px;
                margin-top: 10px;
                float: left;
                width:100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        .v-input{
            position:absolute;
            bottom:10px;
            right:10px;
        }
        .chip-container{
            float:left;
            margin-top:15px;
            .chip{
                margin:0px 10px 0px 0px!important;
            }
        }
        button {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.sign-checkbox {
    height: 40px;
}

.getaccept-title {
    font-size: 1rem !important;
    font-weight: 600;
    color: $font-color-pale;
    margin-bottom: 10px;
}

.slider-enter-active,
.slider-leave-active {
    transition: all 0.3s;
}

.slider-enter-active {
    transition-delay: 0.1s;
}

.slider-enter,
.slider-leave-to {
    opacity: 0;
    transform: translate(-30px);
}

.recipient-row {
    margin-left: -15px;
    margin-right: -15px;
    border-top: 1px solid #e2e2e2;
}
</style>
