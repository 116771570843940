<template>
    <div id="salesprogresscard" class="float-left elevation-1">
        <v-progress-linear
            v-if="isLoading"
            style="position: absolute; top: -1px; width: 100%; height: 2px"
            indeterminate
            active
        />
        <v-col cols="12" md="12" class="pa-0">
            <div class="card-title">Sales</div>
        </v-col>
        <v-col cols="12" md="12" class="pa-8" style="position:relative;">
            <v-row>
                <v-col cols="12" md="12">
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                            class="radio-btn-wrapper justify-center align-center pa-8"
                        >
                            <v-row>
                                <v-col cols="6" md="6" class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-radio-group v-model="unitsek" column>
                                        <v-radio
                                            label="Unit"
                                            color="primary"
                                            value="unit"
                                        ></v-radio>
                                        <v-radio
                                            :label="$config.CURRENCY"
                                            color="primary"
                                            :value="$config.CURRENCY"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="6" md="6" class="d-flex pl-6">
                                    <v-radio-group
                                        v-model="percenttotal"
                                        column
                                    >
                                        <v-radio
                                            label="Total"
                                            color="primary"
                                            value="total"
                                        ></v-radio>
                                        <v-radio
                                            label="Percent"
                                            color="primary"
                                            value="percent"
                                        ></v-radio>
                                    </v-radio-group>
                                    <v-spacer></v-spacer>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <DonutProgress
                                v-if="unitsek == 'unit'"
                                :value="donutData.ticket_sold_percentage"
                                :sum="
                                    percenttotal == 'total'
                                        ? $thousandSeperator(
                                              donutData.tickets_sold
                                          )
                                        : undefined
                                "
                                :sumType="''"
                                :donutSubtext="'tickets sold'"
                            />
                            <DonutProgress
                                v-if="unitsek == $config.CURRENCY"
                                :value="donutData.gross_sales_percentage"
                                :sum="
                                    percenttotal == 'total'
                                        ? $thousandSeperator(
                                              donutData.gross_sales
                                          )
                                        : undefined
                                "
                                :sumType="$config.CURRENCY"
                                :donutSubtext="'gross'"
                            />
                        </v-col>
                    </v-row>
                </v-col>

                <div class="linear-progress d-flex">
                    <v-col col="12" md="12">
                        <v-row>
                            <v-col cols="12" md="12">
                                <div class="title-wrapper">
                                    <div class="title">Type</div>
                                    <v-spacer></v-spacer>
                                    <div class="title">Tickets</div>
                                </div>
                            </v-col>
                            <LinearProgressBars
                                v-if="unitsek == 'unit'"
                                :bars="ticketBars"
                                :sumType="percenttotal == 'percent' ? '%' : ''"
                                :choice="percenttotal"
                            />
                            <LinearProgressBars
                                v-if="unitsek == $config.CURRENCY"
                                :bars="grossBars"
                                :sumType="
                                    percenttotal == 'percent'
                                        ? '%'
                                        : $config.CURRENCY
                                "
                                :choice="percenttotal"
                            />
                        </v-row>
                    </v-col>
                </div>
            </v-row>
        </v-col>
    </div>
</template>

<script>
import DonutProgress from "@/components/DonutProgress/";
import LinearProgressBars from "@/components/LinearProgressBars/";
import NoDataPlaceholder from "@/components/NoDataPlaceholder";
import Loader from "@/components/Loader";

export default {
    name: "SalesProgressCard",
    components: {
        DonutProgress,
        LinearProgressBars,
        NoDataPlaceholder,
        Loader,
    },
    props: {
        ticketBars: Array,
        grossBars: Array,
        donutData: Object,
        dataFound: Boolean,
        isLoading: Boolean,
    },
    data: () => ({
        unitsek: "unit",
        percenttotal: "total",
    }),

    async created() {
        this.$bus.$on("SortSalesProgressBars", async (bars) => {
            await this.sortBars(bars);
        });
    },
    methods: {
        async sortBars(bars) {
            if (bars != undefined) {
                if (this.percenttotal == "percent") {
                    bars.sort((a, b) => {
                        return b.percentage - a.percentage;
                    });
                } else {
                    bars.sort((a, b) => {
                        return b.total - a.total;
                    });
                }
            }
        },
    },
    watch: {
        percenttotal: function (val) {
            this.sortBars(this.ticketBars);
            this.sortBars(this.grossBars);
        },
    },
};
</script>

<style lang="scss" scoped>
#salesprogresscard {
    background: #fff;
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    .radio-btn-wrapper {
        float: left;
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        .radio-group-border-divider {
            border-left: 1px solid $border-color;
        }
    }
    .linear-progress {
        color: $font-color;
        width: 100%;
        float: left;
        .title-wrapper {
            border-bottom: 1px solid #6a7a8c;
            float: left;
            width: 100%;
            display: flex;
            .title {
                font-size: 0.89rem !important;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
}
</style>