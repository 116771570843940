import store from './../store/modules/metadata';
import ApiObject from './ApiObject';
import ArtistObject from './ArtistObject';
import ConcertObject from './ConcertObject';
import TicketingObject from './TicketingObject';
import TicketObject from './TicketObject';
import Notice from './Notice';
import NoticeCollection from './NoticeCollection';
import ApiObjectCollection from './ApiObjectCollection';


export default class ObjectFactory {
    constructor() {
    }

    async BuildObject({ endpoint, metaFields = null, fromApiObj = null }) {
        let newApiObj = fromApiObj ? Object.assign(Object.create(Object.getPrototypeOf(fromApiObj)), fromApiObj) : null;

        if (newApiObj == null)
            newApiObj = new ApiObject(endpoint);

        await newApiObj.setProperties(metaFields);

        let pk_field = newApiObj.pk_field;

        if (fromApiObj != null)
            newApiObj.setObjectData(fromApiObj);

        newApiObj['pk_field'] = pk_field;
        newApiObj.objFactory = new ObjectFactory();
        return newApiObj;
    }

    async BuildArtist({ artistid = null, artist = null, fromApiObj = null, metaFields = null }) {
        let newArtist = fromApiObj;

        if (newArtist == null)
            newArtist = new ArtistObject();

        // get meta fields from store
        // if (!metaFields)
        //     metaFields = store.getters.meta_data(store.state)['artists'].fields;

        newArtist = await this.BuildObject({ endpoint: 'artists', fromApiObj: newArtist, metaFields: metaFields });

        if (artistid)
            await newArtist.fetchObject(artistid); // add includeFields functionality in fetchObject
        else if (artist)
            await newArtist.setObjectData(artist)
        else
            throw Error('missing indata')

        // create potential ConcertObjects
        await newArtist.initConcerts();

        return newArtist;
    }

    async BuildConcert({ concertid = null, concert = null, fromApiObj = null, metaFields = null }) {
        let newConcert = fromApiObj;

        if (newConcert == null)
            newConcert = new ConcertObject();

        // get meta fields from store
        let meta_data = store.getters?.meta_data(store.state);
        newConcert.ticketsMetaFields = meta_data.tickets.fields[0];
        newConcert.ticketSalesMetaFields = meta_data['ticket_sales'].fields[0];

        if (!metaFields)
            metaFields = meta_data['concerts'].fields[0];

        newConcert = await this.BuildObject({ endpoint: 'concerts', fromApiObj: newConcert, metaFields: metaFields });

        if (concertid)
            await newConcert.fetchObject(concertid); // add includeFields functionality in fetchObject
        else if (concert)
            await newConcert.setObjectData(concert)
        else
            throw Error('missing indata')

        try {
            newConcert.MIN_DATE = new Date(newConcert.dev_min_date.substr(0, 10)).toISOString().substr(0, 10);
            newConcert.MAX_DATE = new Date(newConcert.dev_max_date.substr(0, 10)).toISOString().substr(0, 10);
        } catch (error) {
            newConcert.MIN_DATE = '';
            newConcert.MAX_DATE = '';
        }

        await newConcert.initTickets(concertid ? concertid : concert.concertid);

        return newConcert;
    }

    async BuildTicketing({ concertid = null, concert = null, metaFields = null }) {
        var ticketing = new TicketingObject();


        ticketing = await this.BuildConcert({ concertid: concertid, concert: concert, fromApiObj: ticketing, metaFields: metaFields });


        // ticketing.artistid = ticketing.artistid.name
        // ticketing.venueid = ticketing.venueid.name
        if (ticketing.on_sale && ticketing.date && ticketing.date_end) {
            ticketing.setDayName(ticketing.date)
            ticketing.on_sale = ticketing.on_sale === '0000-00-00 00:00:00' ? '' : ticketing.on_sale.substring(0, 10)
            ticketing.final_date = ticketing.date_end ? ticketing.date_end : ticketing.date
        }

        if (ticketing.ticketObjects) {
            for (let ticket of ticketing.ticketObjects) {
                if (ticket.ticket_sales) {
                    ticket.ticket_sales.sort((a, b) => new Date(a.date) - new Date(b.date));
                    ticket.ticket_sales.reverse();
                    let i = 0;
                    ticket.ticket_sales_with_date_keys = {};

                    for (let sale of ticket.ticket_sales) {
                        ticket.ticket_sales_with_date_keys[sale.date] = sale;
                        ticket.ticket_sales_with_date_keys[sale.date]['index'] = i++;
                    }
                }
            }
        }
        ticketing.defineProperties();
        return ticketing;
    }

    async BuildTicket({ ticketid = null, concertid, ticket = null, metaFields = null }) {
        var newTicket = new TicketObject();

        // // get meta fields from store
        // if (!metaFields)
        //     metaFields = store.getters.meta_data(store.state)['tickets'].fields[0];

        newTicket = await this.BuildObject({ endpoint: 'tickets', fromApiObj: newTicket, metaFields: metaFields });

        if (ticketid)
            await newTicket.fetchObject(ticketid);
        else if (ticket)
            newTicket.setObjectData(ticket)
        else
            throw Error('missing indata')

        await newTicket.setSumSales();

        newTicket.total_sold = newTicket.sum_sales[0]
        newTicket.concertid = concertid;

        if (!newTicket.comment)
            newTicket.comment = '';

        return newTicket;
    }

    async BuildCollection({ endpoint, fromCollection = null, fromDummy = null, metaFields = null }) {
        if (fromCollection == null)
            fromCollection = new ApiObjectCollection(endpoint);

        fromCollection.dummy = fromDummy == null ? await this.BuildObject({ endpoint: endpoint, metaFields: metaFields }) : fromDummy;

        fromCollection.metaFields = fromCollection.dummy.metaFields;

        fromCollection.pk_field = fromCollection.dummy.pk_field;
        await fromCollection.getLabels()
        return fromCollection;
    }

    async BuildNoticeCollection({ myUserId, limitParams = '' }) {

        var notices = new NoticeCollection();
        var noticeDummy = await Notice.Build();
        notices = await this.BuildCollection({ endpoint: 'notices', fromCollection: notices, fromDummy: noticeDummy });

        var user = await this.BuildObject({ endpoint: 'users' });
        notices.user = user;
        await notices.user.fetchObject(myUserId);
        notices.lastRead = notices.user.last_notice_read;

        if (!notices.lastRead)
            notices.lastRead = '1970-01-01 00:00:00';

        await notices.fetchAll({ route: limitParams });

        return notices;
    }


}