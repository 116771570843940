<template>
    <v-dialog v-model="shareFilterDialog" v-if="shareFilterDialog" persistent max-width="580" style="border: 1px solid red">
        <v-card>
            <v-card-title class="header">Share filter: {{ selectedFilterToBeShared.filterName }}</v-card-title>
            <v-container class="px-5 py-3 d-flex">
                <v-autocomplete
                    :items="data"
                    :label="dropdownLabel"
                    :item-text="itemText"
                    :item-value="itemValue"
                    :disabled="!data"
                    multiple
                    chips
                    deletable-chips
                    small-chips
                    :loading="getDropDownItemsIsPending"
                    hide-details
                    outlined
                    dense
                    clearable
                    required
                    v-model="selectedFromDropDown"
                >
                    <div class="select-all-users" slot="prepend-item">
                        <v-checkbox
                            v-model="checkbox"
                            class="float-left ma-0"
                            hide-details
                            color="primary"
                            :checked="checked"
                            :label="`Select all ${shareTo[shareTo.length - 1] == 's' ? shareTo : shareTo + 's'}`"
                            v-ripple
                        ></v-checkbox>
                    </div>
                </v-autocomplete>
            </v-container>
            <v-card-actions class="px-5 py-4">
                <v-spacer></v-spacer>
                <v-btn color="primary" small text @click="closeShareFilterDialog">Close</v-btn>
                <v-btn color="primary" :disabled="selectedFromDropDown.length == 0" :loading="isShareFilterPending" small depressed @click="shareFilter">Share</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "sharefilter",
    props: {
        shareFilterDialog: Boolean,
        selectedFilterToBeShared: Array,
        shareTo: String,
    },
    data: () => ({
        filterName: null,
        data: null,
        users: null,
        itemText: null,
        itemValue: null,
        checkbox: false,
        getDropDownItemsIsPending: true,
        isShareFilterPending: false,
        selectedFromDropDown: [],
        dropdownLabel: null,
        filter: null,
        postArray: [],
    }),
    watch: {
        selectedFromDropDown(data) {
            /**
             * Reset array to prevent duplicates.
             */
            this.postArray = [];
            /**
             * Every time the user adds or remove a user we recreate the object so they
             * are in the right format to be posted.
             */
            if (this.shareTo == "users") {
                this.postArray = this.selectedFromDropDown.map((user) => (user = { ownerid: user, ...this.filter }));
            }
            if (this.shareTo == "department") {
                this.postArray = this.users
                    .map((user) => (user = { ownerid: user.userid, ...this.filter, accessgroup: user.accessgroup }))
                    .filter((v) => data.some((group) => v.accessgroup?.includes(group)));
            }
            /**
             * We do this so the "select all users" checkbox is unchecked when clearing the input with vuetify.
             */
            if (this.selectedFromDropDown.length == 0) this.checkbox = false;

            console.log(this.postArray);
        },
        checkbox(status) {
            /**
             * If the "select all users" checkbox is checked we push in all the users
             * to the selecetedFromDropDown array.
             */
            if (status && this.shareTo == "users") {
                this.selectedFromDropDown = this.data.reduce((users, user) => {
                    users.push(user.userid);
                    return users;
                }, []);
            } else if (status && this.shareTo == "department") {
                this.selectedFromDropDown = this.data.reduce((departments, department) => {
                    departments.push(department.accessgroup);
                    return departments;
                }, []);
            } else {
                this.selectedFromDropDown = [];
            }
        },
    },
    async created() {
        this.getDropDownItems(this.shareTo);
        /**
         * Template for how the filter object have to be formated.
         */
        this.filter = {
            table_name: this.$getView(this.$route.path),
            filter_name: this.selectedFilterToBeShared.filterName,
            filter_str: this.selectedFilterToBeShared.filterString,
            filter_obj: this.selectedFilterToBeShared.objects,
            description: this.createDescription(),
        };
    },
    methods: {
        async getDropDownItems(shareTo) {
            /**
             * Get all users and filter out yourself. Users is always fetched.
             */
            this.users = (await this.$GetService(this.$getRoute("users").main_route)).data.filter((user) => user.userid != this.userInfo.userid);
            /**
             * Based on how the user want to share the filters we have two options.
             * To users or to department and we fetch different data based on their choice.
             */
            if (shareTo == "users") {
                this.data = this.users;
                /**
                 * Format the dropdown-object based on choice
                 */
                this.setDropdownOptions({
                    text: "fullname",
                    value: "userid",
                    label: "Select Users",
                });
            }
            if (shareTo == "department") {
                this.setDropdownOptions({
                    text: "accessgroup",
                    value: "accessgroup",
                    label: "Select Department",
                });
                this.data = (await this.$GetService("accessgroups")).data.filter((department) => department.accessgroup);
            }
            if (this.data) this.getDropDownItemsIsPending = false;
        },
        setDropdownOptions({ text, value, label }) {
            /**
             * Set the variable based on the choices.
             */
            this.itemText = text;
            this.itemValue = value;
            this.dropdownLabel = label;
        },
        closeShareFilterDialog() {
            this.$emit("closeShareFilterDialog");
            this.selectedFromDropDown = [];
            this.data = null;
        },
        async shareFilter() {
            this.isShareFilterPending = true;
            const response = await this.$PostService(this.$getRoute("user_filters").main_route, this.postArray);
            if (response.status === 200) {
                setTimeout(() => {
                    this.isShareFilterPending = false;
                    this.selectedFromDropDown = [];
                    this.checkbox = false;
                }, 1000);
            }
        },
        addAllUsers() {
            this.checkbox = !this.checkbox;
        },
        createDescription() {
            return this.selectedFilterToBeShared.filterString
                .replace(/>/gi, "bigger than")
                .replace(/.toLowerCase\(\)/g, "")
                .replace(/</gi, "smaller than")
                .replace(/&&/gi, "and")
                .replace(/\|\|/g, "or")
                .replace(/==/gi, "equal to")
                .replace(/!=/gi, "not equal to")
                .replace(/item.|'|_/gi, "");
        },
    },
    computed: {
        ...mapGetters({
            userInfo: "user/userInfo",
        }),
    },
};
</script>

<style lang="scss" scoped>
.v-chip--select {
    background: red;
}

.select-all-users {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    margin-left: 1px;
    width: calc(100% - 2px);
    :hover {
        background: #f7f7f7;
    }
    &::v-deep .v-input--selection-controls__ripple {
        background: transparent !important;
        &:before {
            background: transparent !important;
        }
    }
    .v-input {
        padding: 0px 16px;
        height: 48px;
        display: flex;
        align-items: center;
        width: 100%;
        &::v-deep .v-input__control {
            width: 100%;
            height: 100%;
            display: flex;
            .v-input__slot {
                margin: auto;
            }
            label {
                font-size: 13px;
                height: 48px;
                margin-left: 22px;
                font-weight: 600;
                color: $primary;
            }
        }
    }
    .text {
        float: left;
        margin-left: 30px;
        font-size: 13px;
        font-weight: 500;
        color: $primary;
    }
}

.header {
    font-size: 15px !important;
    text-transform: uppercase;
    color: $accent-color;
    font-weight: 600 !important;
    display: flex;
    position: relative;
}
</style>
