var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"float-left elevation-1",attrs:{"id":"salesprogresscard"}},[(_vm.isLoading)?_c('v-progress-linear',{staticStyle:{"position":"absolute","top":"-1px","width":"100%","height":"2px"},attrs:{"indeterminate":"","active":""}}):_vm._e(),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"card-title"},[_vm._v("Sales")])]),_c('v-col',{staticClass:"pa-8",staticStyle:{"position":"relative"},attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{staticClass:"radio-btn-wrapper justify-center align-center pa-8",attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","md":"6"}},[_c('v-spacer'),_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.unitsek),callback:function ($$v) {_vm.unitsek=$$v},expression:"unitsek"}},[_c('v-radio',{attrs:{"label":"Unit","color":"primary","value":"unit"}}),_c('v-radio',{attrs:{"label":_vm.$config.CURRENCY,"color":"primary","value":_vm.$config.CURRENCY}})],1)],1),_c('v-col',{staticClass:"d-flex pl-6",attrs:{"cols":"6","md":"6"}},[_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.percenttotal),callback:function ($$v) {_vm.percenttotal=$$v},expression:"percenttotal"}},[_c('v-radio',{attrs:{"label":"Total","color":"primary","value":"total"}}),_c('v-radio',{attrs:{"label":"Percent","color":"primary","value":"percent"}})],1),_c('v-spacer')],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.unitsek == 'unit')?_c('DonutProgress',{attrs:{"value":_vm.donutData.ticket_sold_percentage,"sum":_vm.percenttotal == 'total'
                                    ? _vm.$thousandSeperator(
                                          _vm.donutData.tickets_sold
                                      )
                                    : undefined,"sumType":'',"donutSubtext":'tickets sold'}}):_vm._e(),(_vm.unitsek == _vm.$config.CURRENCY)?_c('DonutProgress',{attrs:{"value":_vm.donutData.gross_sales_percentage,"sum":_vm.percenttotal == 'total'
                                    ? _vm.$thousandSeperator(
                                          _vm.donutData.gross_sales
                                      )
                                    : undefined,"sumType":_vm.$config.CURRENCY,"donutSubtext":'gross'}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"linear-progress d-flex"},[_c('v-col',{attrs:{"col":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"title"},[_vm._v("Type")]),_c('v-spacer'),_c('div',{staticClass:"title"},[_vm._v("Tickets")])],1)]),(_vm.unitsek == 'unit')?_c('LinearProgressBars',{attrs:{"bars":_vm.ticketBars,"sumType":_vm.percenttotal == 'percent' ? '%' : '',"choice":_vm.percenttotal}}):_vm._e(),(_vm.unitsek == _vm.$config.CURRENCY)?_c('LinearProgressBars',{attrs:{"bars":_vm.grossBars,"sumType":_vm.percenttotal == 'percent'
                                    ? '%'
                                    : _vm.$config.CURRENCY,"choice":_vm.percenttotal}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }