<template>
    <div class="crew-call-wrapper mb-5" >
        <div class="wizard-form-title" >{{ crewType }}</div>
        <v-form>
            <div v-for="(crew, index) of crewCallsCopy" :key="crew.id" class="crew-call">
                <v-row>
                    <v-col cols="12" md="5">
                        <v-text-field
                            dense
                            :label="`${crewType} role title`"
                            v-model="crew.crew_role"
                            hide-details
                            filled
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            dense
                            label="Price"
                            v-model="crew.price"
                            type="number"
                            filled
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            dense
                            readonly
                            label="Total cost"
                            v-model="crew.sum"
                            type="number"
                            :suffix="$config.CURRENCY"
                            hide-details
                            filled
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" md="1" v-for="(per_day, index) of crew.crew_per_day" :key="index">
                        <v-text-field
                            dense
                            :label="crewCallDayHeaders[index]"
                            v-model="crew.crew_per_day[index]"
                            type="number"
                            filled
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" md="1" class="d-flex align-center">
                        <v-btn icon color="error" @click="removeCrewCall(index)">
                            <v-icon>remove_circle_outline</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-col>
                </v-row>
            </div>
        </v-form>
        <v-col cols="12" md="12" class="pa-0 pt-3">
            <v-btn @click="addCrewCall" depressed dark color="primary">
                <v-icon left>add</v-icon>
                Add {{ crewType }}
            </v-btn>
        </v-col>
    </div>
</template>

<script>
export default {
    name: "crewcallsform",
    props: {
        crewType: String,
        crewCalls: Array,
        crewCallDayHeaders: Array,
    },
    data: () => ({
        dayLabels: [],
        crewCallsCopy: [],
        firstInit: true,
    }),
    watch: {
        crewCalls: {
            handler(v) {
                console.log(v);
            },
            deep: true,
        },
        crewCallsCopy: {
            handler(v) {
                if (!this.firstInit) {
                    for (let call of v) {
                        let sum = 0;

                        for (let day of call.crew_per_day)
                            if (+day > 0) sum += call.price * +day;

                        call.sum = sum;
                    }
                    this.$emit(
                        "crewCallsUpdated",
                        JSON.parse(JSON.stringify(v)),
                        this.crewType
                    );
                }
            },
            deep: true,
        },
    },
    created() {
        if (this.crewCalls) {
            for (let crewCall of this.crewCalls) {
                let copy = JSON.parse(JSON.stringify(crewCall));
                this.crewCallsCopy.push(copy);
            }
        }
        setTimeout(() => {
            this.firstInit = false;
        }, 1);

    },
    methods: {
        showCrewPerDay(crew) {
            crew.showPerDay = !crew.showPerDay;
        },
        addCrewCall() {
            let crew_per_day = [];
            for (let i = 0; i < 15; i++) crew_per_day.push(0);

            this.crewCallsCopy.push({
                crew_per_day: crew_per_day,
                crew_role: "",
                price: 0,
                sum: 0,
                type: this.crewType,
            });
        },
        removeCrewCall(index) {
            this.$emit("deleteCrewCall", this.crewCallsCopy[index]);
            this.$delete(this.crewCallsCopy, index);
            this.$emit(
                "crewCallsUpdated",
                JSON.parse(JSON.stringify(this.crewCallsCopy)),
                this.crewType
            );
        },
    },
};
</script>

<style lang="scss" scoped>

    .crew-call-wrapper{
        border-bottom:1px solid $border-color;
        padding-bottom:20px;
    }

    .card-title{
        text-align:left!important;
        margin-bottom:10px;
        border:none;
    }

    .crew-call:not(:last-child){
        margin-bottom:10px;
        border-bottom:1px solid #e2e2e2;
        padding-bottom:10px;

    }
</style>

