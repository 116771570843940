<template>
    <div id="handle-venue">
        <venue-budget-template
            v-show="!(isTableDataPending || isBudgetDataPending)"
            class="float-left"
            :ticketRevenuesArr="ticketRevenuesArr"
            :costStepArr="costStepArr"
            @budgetLoaded="budgetDataLoaded"
            @deleteTicket="deleteTicket"
            @postTicket="addTicketToArr"
        />
    </div>
</template>

<script>
import Loader from "@/components/Loader";
import RemoveDialog from "@/components/Tables/EditTableItem/RemoveDialog.vue";
import EditTableItem from "@/components/Tables/EditTableItem/index.vue";
import VenueBudgetTemplate from "@/components/VenueBudgetTemplate/VenueBudgetTemplate.vue";
import {
    Ticket,
    BudgetPostItem,
    CostStep,
} from "@/models/BudgetObjects.js";
import {
    step2,
    step3_venue_related,
    step3_staff_related,
    step3_production_related,
    step3_catering,
    step3_authority_cost,
    step4,
    step5,
    step6,
} from "@/models/venue_budget_template_rows.js";

import { mapGetters } from "vuex";

export default {
    name: "HandleVenue",

    props: {
        metaParam: String,
    },

    components: {
        Loader,
        RemoveDialog,
        EditTableItem,
        VenueBudgetTemplate,
    },

    data: () => ({
        venueName: "",
        view: "",
        showDeleteDialog: false,
        noEditTableChange: true,
        noTicketChange: true,
        noCostChange: true,
        buttonText: "",
        venueid: null,
        isTableDataPending: true,
        isBudgetDataPending: true,
        isUpdatePending: false,

        ticketRevenuesArr: [],

        costStepArr: [
            new CostStep("Variable costs (Step 2)"),
            new CostStep("Production costs (Step 3)"),
            new CostStep(), // step3_staff_related
            new CostStep(), // step3_production_related
            new CostStep(), // step3_catering
            new CostStep(), // step3_authority_cost
            new CostStep("Marketing costs (Step 4)"),
            new CostStep("Talent costs (Step 5)"),
            new CostStep("Other costs (Step 6)"),
        ],

        step2Arr: [],
        step3VenueArr: [],
        step3StaffArr: [],
        step3ProdArr: [],
        step3CateringArr: [],
        step3AuthorityArr: [],
        step4Arr: [],
        step5Arr: [],
        step6Arr: [],

        originalTicketData: [],
        originalCostData: [],

        // lazy load test
        stepTwo: new CostStep("Variable costs (Step 2)"),
        stepThreeA: new CostStep("Production costs (Step 3)"),
        stepThreeB: new CostStep(), // step3_staff_related
        stepThreeC: new CostStep(), // step3_production_related
        stepThreeD: new CostStep(), // step3_catering
        stepThreeE: new CostStep(), // step3_authority_cost
        stepFour: new CostStep("Marketing costs (Step 4)"),
        stepFive: new CostStep("Talent costs (Step 5)"),
        stepSix: new CostStep("Other costs (Step 6)"),

        showRequiredFieldsError: false,
        venueid: null,
    }),

    watch: {
        /**
         * Watches for changes made in the budget cost arrays.
         * To decide if the create/update button should be disabled or not
         */
        costStepArr: {
            handler: function (v) {
                if (this.costStepArr != undefined) {
                    if (
                        JSON.stringify(this.costStepArr) ===
                        JSON.stringify(this.originalCostData)
                    ) {
                        this.noCostChange = true;
                    } else {
                        this.noCostChange = false;
                        if (this.metaParam == "create") {
                            this.buttonText = "Create";
                        } else {
                            this.buttonText = "Save";
                        }
                    }
                }
            },
            deep: true,
        },
        /**
         * Watches for changes made in the ticket revenues array.
         * To decide if the create/update button should be disabled or not
         */
        ticketRevenuesArr: {
            handler: function (v) {
                if (this.ticketRevenuesArr != undefined) {
                    if (
                        JSON.stringify(this.ticketRevenuesArr) ===
                        JSON.stringify(this.originalTicketData)
                    )
                        this.noTicketChange = true;
                    else {
                        this.noTicketChange = false;
                        if (this.metaParam == "create") {
                            this.buttonText = "Create";
                        } else {
                            this.buttonText = "Save";
                        }
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        getVenueTitle() {
            return !this.venueName ? "Create new" : this.venueName;
        },
    },
    async created() {
        let route = this.$route.path;
        this.view = 'venues'

        this.venueid = this.$getRouteId(route);
        await this.initializeCostArrays();

        if (!route.includes("create")) {
            await this.getVenueBudgetData();
            this.buttonText = "saved";
        } else {
            this.buttonText = "Create";
        }
        this.tableDataLoaded();
        console.log("handlevenue created");
    },

    updated() {
        //console.log('handlevenue updated')
    },

    methods: {
        /**
         * Go back to previous page
         */
        goBack() {
            this.$router.go(-1);
        },

        requiredFieldsError() {
            this.showRequiredFieldsError = true;
        },
        tableDataLoaded() {
            console.log("tabledata loaded " + false);
            setTimeout(() => {
                this.isTableDataPending = false;
            }, 2000);
        },

        budgetDataLoaded() {
            this.isBudgetDataPending = false;
        },

        /**
         * Keeps track of changes made in the EditTableItem form
         */
        editTableChanged(changePayload, namePayload) {
            this.venueName = namePayload;
            this.noEditTableChange = changePayload;
            if (!changePayload) {
                if (this.metaParam == "update") this.buttonText = "update";
            }
        },

        openDeleteDialog() {
            this.showDeleteDialog = true;
        },
        closeDeleteDialog() {
            this.showDeleteDialog = false;
        },

        async deleteColumn(id) {
            this.$refs.editTableItem.deleteColumn(id);
        },

        /**
         * Initializes the budget costs arrays with default values.
         */
        async initializeCostArrays() {
            let i = 0;
            step2.forEach(async (cost) => {
                this.step2Arr.push(
                    new BudgetPostItem(
                        cost.display_name,
                        cost.name,
                        cost.active,
                        this.venueid,
                        cost.step,
                        cost.header,
                        cost.sticky
                    )
                );
            });
            i++;
            // Production costs - venue
            step3_venue_related.forEach(async (cost) => {
                this.step3VenueArr.push(
                    new BudgetPostItem(
                        cost.display_name,
                        cost.name,
                        cost.active,
                        this.venueid,
                        cost.step,
                        cost.header,
                        cost.sticky
                    )
                );
            });
            this.costStepArr[i++].subTitle = "Venue related";
            //this.stepThreeA.subTitle = 'Venue related'

            // Production costs - staff
            step3_staff_related.forEach(async (cost) => {
                this.step3StaffArr.push(
                    new BudgetPostItem(
                        cost.display_name,
                        cost.name,
                        cost.active,
                        this.venueid,
                        cost.step,
                        cost.header,
                        cost.sticky
                    )
                );
            });
            this.costStepArr[i++].subTitle = "Staff related";
            //this.stepThreeB.subTitle = 'Staff related'

            // Production costs - production
            step3_production_related.forEach(async (cost) => {
                this.step3ProdArr.push(
                    new BudgetPostItem(
                        cost.display_name,
                        cost.name,
                        cost.active,
                        this.venueid,
                        cost.step,
                        cost.header,
                        cost.sticky
                    )
                );
            });
            this.costStepArr[i++].subTitle = "Production related";
            //this.stepThreeC.subTitle = 'Production related'

            // Production costs - catering
            step3_catering.forEach(async (cost) => {
                this.step3CateringArr.push(
                    new BudgetPostItem(
                        cost.display_name,
                        cost.name,
                        cost.active,
                        this.venueid,
                        cost.step,
                        cost.header,
                        cost.sticky
                    )
                );
            });
            this.costStepArr[i++].subTitle = "Catering related";
            //this.stepThreeD.subTitle = 'Catering related'

            // Production costs - authority
            step3_authority_cost.forEach(async (cost) => {
                this.step3AuthorityArr.push(
                    new BudgetPostItem(
                        cost.display_name,
                        cost.name,
                        cost.active,
                        this.venueid,
                        cost.step,
                        cost.header,
                        cost.sticky
                    )
                );
            });
            this.costStepArr[i++].subTitle = "Authority related";
            //this.stepThreeE.subTitle = 'Authority related'

            step4.forEach(async (cost) => {
                this.step4Arr.push(
                    new BudgetPostItem(
                        cost.display_name,
                        cost.name,
                        cost.active,
                        this.venueid,
                        cost.step,
                        cost.header,
                        cost.sticky
                    )
                );
            });

            step5.forEach(async (cost) => {
                this.step5Arr.push(
                    new BudgetPostItem(
                        cost.display_name,
                        cost.name,
                        cost.active,
                        this.venueid,
                        cost.step,
                        cost.header,
                        cost.sticky
                    )
                );
            });

            step6.forEach(async (cost) => {
                this.step6Arr.push(
                    new BudgetPostItem(
                        cost.display_name,
                        cost.name,
                        cost.active,
                        this.venueid,
                        cost.step,
                        cost.header,
                        cost.sticky
                    )
                );
            });

            i = 0;
            this.costStepArr[i++].arr = this.step2Arr;
            this.costStepArr[i++].arr = this.step3VenueArr;
            this.costStepArr[i++].arr = this.step3StaffArr;
            this.costStepArr[i++].arr = this.step3ProdArr;
            this.costStepArr[i++].arr = this.step3CateringArr;
            this.costStepArr[i++].arr = this.step3AuthorityArr;
            this.costStepArr[i++].arr = this.step4Arr;
            this.costStepArr[i++].arr = this.step5Arr;
            this.costStepArr[i++].arr = this.step6Arr;
        },

        /**
         * Calls the database post or update functions to add or change the database data.
         * This is called when the Create/Update button is pressed in the form
         */
        async startCreation(venueid) {
            // post edittableitem data
            console.log(this.metaParam);
            if (!venueid) return;
            if (this.metaParam == "create") {
                console.log(this.ticketRevenuesArr);
                for (let ticket of this.ticketRevenuesArr) {
                    ticket.venueid = venueid;
                    await this.postTicketRevenue(ticket);
                }
                if (!(await this.postCosts(venueid)))
                    console.log("Creation failed");
            } else {
                this.isUpdatePending = true;
                if ((await this.putTicketRevenue()) && venueid) {
                    if (await this.putCosts()) {
                        this.buttonText = "Saved";
                        this.isUpdatePending = false;
                        this.noEditTableChange = true;
                    }
                }
            }
        },

        /**
         * Collects the venue budget data, ticket revenues + budget costs.
         * If successful it calls the array update functions
         */
        async getVenueBudgetData() {
            try {
                let response = await this.$GetService(
                    this.$getEditRoute(this.$route.path)
                );
                console.log(response);
                if (response.status === 200) {
                    this.updateTicketArr(response.data.venue_tickets);
                    this.updateCostArrays(response.data.venue_budget_rows);
                }
            } catch (error) {
                console.log(error);
            }
        },

        /**
         * Fills the ticket revenue array with tickets collected from the database
         * param data is the data collected from the database
         */
        async updateTicketArr(data) {
            this.ticketRevenuesArr = [];
            if (data != undefined) {
                await data.forEach((ticket) => {
                    this.ticketRevenuesArr.push(
                        new Ticket(
                            ticket.section_name,
                            ticket.default_price,
                            ticket.default_capacity,
                            this.venueid,
                            ticket.venue_ticketid,
                            0,
                            ticket.platinum
                        )
                    );
                });
            }

            this.originalTicketData = JSON.parse(
                JSON.stringify(this.ticketRevenuesArr)
            );
            this.noTicketChange = true;
        },

        /**
         * Updates the default initialized budget costs arrays
         * with data collected from the database
         * param data is the data collected from the database
         */
        async updateCostArrays(data) {
            let item = null;
            let i = 0;
            console.log('data öalksdlköaskd');
            console.log(data);
            await this.costStepArr.forEach((costStep) => {
                costStep.arr.forEach(async (cost) => {
                    if (JSON.stringify(data).includes(cost.name)) {
                        cost.name = data[i].name;
                        cost.active = 1;
                        cost.value = data[i].value;
                        cost.budget_row_typeid = data[i].budget_row_typeid;
                        cost.venue_budget_rowid = data[i].venue_budget_rowid;
                        cost.venueid = this.venueid;
                        i = i + 1;
                    }
                });
            });

            this.originalCostData = JSON.parse(
                JSON.stringify(this.costStepArr)
            );
            this.noCostChange = true;
        },

        async addTicketToArr(ticket) {
            await this.postTicketRevenue(ticket);
            this.ticketRevenuesArr.push(ticket);
        },

        /**
         * Post a ticket revenue to the database
         * param ticket is the new ticket to be added
         */
        async postTicketRevenue(ticket) {
            if (ticket.venueid == null) ticket.venueid = this.venueid;

            if (ticket.venueid > 0) {
                try {
                    let response = await this.$PostService(
                        this.$getRoute(this.view).tickets,
                        ticket
                    );
                    if (response.status == 200) {
                        ticket.venue_ticketid = response.data.venue_ticketid;
                        this.$bus.$emit("ticketAdded", ticket);
                    } else if (response.status == 422) {
                        console.log(response);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                this.$bus.$emit("ticketAdded", ticket);
            }
        },

        /**
         * Delete a ticket revenue from the database
         * param venueid is the id of said ticket
         */
        async deleteTicket(ticket) {
            if (ticket.venue_ticketid != "") {
                let ticketToDel = {
                    venue_ticketid: ticket.venue_ticketid,
                };
                try {
                    let response = await this.$DeleteService(
                        this.$getRoute(this.view).tickets,
                        ticketToDel
                    );
                    //console.log(response)
                    if (response.status == 200) {
                        let index = this.ticketRevenuesArr.indexOf(ticket);
                        if (index != -1)
                            this.$delete(this.ticketRevenuesArr, index);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                let index = this.ticketRevenuesArr.indexOf(ticket);
                if (index != -1) this.$delete(this.ticketRevenuesArr, index);
            }
        },

        /**
         * Update the ticket revenues in the in the database
         */
        async putTicketRevenue() {
            try {
                let response = await this.$PutService(
                    this.$getRoute(this.view).tickets,
                    this.ticketRevenuesArr
                );

                if (response.status == 200) {
                    this.originalTicketData = JSON.parse(
                        JSON.stringify(this.ticketRevenuesArr)
                    );
                    return true;
                }
            } catch (error) {
                console.log(error);
            }
            return false;
        },

        /**
         * Post the updated budget costs values to the database
         * param venuid is the id of the venue the costs are tied to.
         */
        async postCosts(venueid) {
            let combinedArr = [];
            for (let costItem of this.costStepArr) {
                combinedArr = combinedArr.concat(costItem.arr);
            }

            combinedArr.forEach(async (cost) => {
                cost.venueid = venueid;
            });

            const newOnes = combinedArr.filter(row => row.venue_budget_rowid == '' || !row.venue_budget_rowid)

            try {
                let response = await this.$PostService(
                    this.$getRoute(this.view).rows,
                    newOnes
                );
                console.log(response);
                if (response.status == 200) {
                    return true;
                } else if (response.status == 422) {
                    console.log("422 response:");
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
            return false;
        },

        /**
         * Updates the budget costs in the array tied to the venue
         */
        async putCosts() {
            let combinedArr = [];
            for (let costItem of this.costStepArr) {
                combinedArr = combinedArr.concat(costItem.arr);
            }

            // filter out old ones
            const oldOnes = combinedArr.filter(row => row.venue_budget_rowid != '')
            
            try {
                let response = await this.$PutService(
                    this.$getRoute(this.view).rows,
                    oldOnes
                );

                await this.postCosts(this.venueid);

                if (response.status == 200) {
                    this.originalCostData = JSON.parse(
                        JSON.stringify(this.costStepArr)
                    );
                    return true;
                }
            } catch (error) {
                console.log(error);
            }

            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
#handle-venue {
    width: 100%;
    height: 100%;
    float: left;
}
#budgettemplate {
    width: 100%;
    background: #fff;
    position: relative;
    min-height: 600px;
}

.required-fields-warning {
    font-size: 0.85em;
    font-weight: 600;
    color: rgb(218, 50, 50);
}
</style>