import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import DatetimePicker from "vuetify-datetime-picker";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import Auth from "@okta/okta-vue";
import config from "@/config";
import JsonCSV from 'vue-json-csv';
import VueWorker from 'vue-worker';

Vue.use(VueWorker);
Vue.use(vuetify, {
	iconfont: "md",
	theme: {
		primary: "#ffffff",
	},
});

Vue.use(Auth, {
	issuer: config.issuer,
	clientId: config.clientId,
	redirectUri: config.redirectUri,
	scopes: config.scopes,
	response_type: ["code"],
	response_mode: ["okta_post_message"],
	pkce: true,
	prompt: 'none',
	// tokenManager: {
	// 	storage: 'memory'
	// }
})

// to support Intersection Observer API (lazy loading for example) in certain browsers
import IntObs from "intersection-observer";

Vue.use(PerfectScrollbar);
Vue.use(VueAxios, axios, IntObs);
Vue.use(DatetimePicker);

Vue.component('downloadCsv', JsonCSV);

import {
	getRoute,
	getView,
	getEditRoute,
	getSubRoute,
	getRouteId,
} from "./config/routeconfig.js";
import {
	GetService,
	DeleteService,
	PutService,
	PostService,
	TestService,
	GetDocService,
	GetAcceptPost,
} from "@/api/apiServices.js";
import {
	thousandSeperator,
	getCurrency,
	logger,
	round,
	wallHandler,
	formatHeader,
	fetchDropdownList
} from "@/helpers/helpers.js";
import ObjectFactory from "./models/ObjectFactory";

// GLOBAL ROUTE FUNCTIONS
Vue.prototype.$getRoute = (route) => getRoute(route);
Vue.prototype.$getSubRoute = (route, subroute) => getSubRoute(route, subroute);
Vue.prototype.$getView = (route) => getView(route);
Vue.prototype.$getEditRoute = (route) => getEditRoute(route);
Vue.prototype.$getRouteId = (route) => getRouteId(route);

// GLOBAL API FUNCTIONS
Vue.prototype.$GetService = (endpoint, payload) =>
	GetService(endpoint, payload);
Vue.prototype.$PostService = (endpoint, payload) =>
	PostService(endpoint, payload);
Vue.prototype.$PutService = (endpoint, payload) =>
	PutService(endpoint, payload);
Vue.prototype.$DeleteService = (endpoint, payload) =>
	DeleteService(endpoint, payload);
Vue.prototype.$TestService = (endpoint, payload) =>
	TestService(endpoint, payload);

Vue.prototype.$GetDocService = (endpoint) => GetDocService(endpoint);
Vue.prototype.$GetAcceptPost = (payload, token) =>
	GetAcceptPost(payload, token);

// BUS GLOBAL FUNCTION
Vue.prototype.$bus = new Vue();

// GLOBAL HELPER FUNCTIONS
Vue.prototype.$thousandSeperator = (n) => thousandSeperator(n);
Vue.prototype.$getCurrency = (cur) => getCurrency(cur);
Vue.prototype.$logger = (str, obj = "") => logger(str, obj);
Vue.prototype.$round = (num, dec = 2) => round(num, dec);
Vue.prototype.$wallHandler = (
	title,
	text,
	walltype,
	routepath,
	eventtype,
	id
) => wallHandler(title, text, walltype, routepath, eventtype, id);
Vue.prototype.$formatHeader = (header) => formatHeader(header);
Vue.prototype.$fetchDropdownList = (meta) => fetchDropdownList(meta);

// We declare the ObjectFactory instance globally, so that we can initiate it with our Vue scope (this) so we can access it later on
// See created() in Home.vue for vue scope declaration
Vue.prototype.$ObjectFactory = new ObjectFactory();

const ignoreWarnMessage =
	"The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function (msg, vm, trace) {
	// `trace` is the component hierarchy trace
	if (msg === ignoreWarnMessage) {
		msg = null;
		vm = null;
		trace = null;
	}
};

Vue.config.productionTip = true;

// LOCALIZATION CONFIG TO LOAD
const config_loc = "config.json";

// this fetch enables the global config
fetch(process.env.BASE_URL + config_loc).then((response) => {
	response.json().then((config) => {
		Vue.prototype.$config = config;

		new Vue({
			router,
			store,
			vuetify,
			render: (h) => h(App),
		}).$mount("#app");
	});
});
