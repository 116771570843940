<template>
	<div class="events-data-table elevation-1">
		<div class="card-title">Your upcoming events</div>
		<v-data-table
			:headers="headers"
			:items="filteredConcerts"
			:items-per-page="5"
			item-key="name"
			@click:row="(item) => goTo(item)"
		>
			<template v-slot:item.progress="{ item }">
				<v-progress-circular :value="item.progress" color="primary" size="40" rotate="-90">
					<span class="progress-text">{{ item.progress || 0 }}%</span>
				</v-progress-circular>
			</template>
			<template v-slot:item.artistid="{ item }">
				{{ item.artistid.name }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "eventsdatatable",
	props: {
		items: Object,
	},
	data: () => ({
		headers: [
			{ text: "Start date", value: "date" },
			{ text: "Artist", value: "artistid" },
			{ text: "City", value: "town" },
			{ text: "Show name", value: "title" },
			{ text: "Progress", value: "progress", sortable: false },
		],
		filteredConcerts: [],
	}),
	created() {
		this.getConcertNotStarted();
		this.getProgress();
	},
	methods: {
		goTo(item) {
			this.$router.push({
				path: `concerts/edit/${item.concertid}`,
			});
		},
		getConcertNotStarted() {
			this.filteredConcerts = this.items.filter((concert) => new Date(concert.date) >= new Date());
			console.log(this.filteredConcerts);
		},
		getProgress() {
			this.filteredConcerts.forEach((concert) => {
				if (concert.tickets) {
					const sales = concert.tickets
						.reduce(
							(acc, ticket) => {
								if (ticket.ticket_sales) {
									ticket.ticket_sales.forEach((sale) => {
										acc.push(+sale.amount);
									});
								}
								return acc;
							},
							[0]
						)
						.reduce((a, b) => a + b);

					const available = concert.tickets
						.reduce(
							(acc, ticket) => {
								acc.push(+ticket.available);
								return acc;
							},
							[0]
						)
						.reduce((a, b) => a + b);

					concert.progress = Math.round((sales / available) * 100);
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.v-data-table td,
.v-data-table th {
	padding: 16px !important;
}

.events-data-table {
	background: #fff;
	border-radius: 3px;
	overflow: hidden;
}

.text-start {
	max-width: 236px;
}

.progress-text {
	font-size: 12px;
	font-weight: 600;
	color: #666;
}
</style>
