export const step1 = [
   {name: 'Merchandise',step:'1', header:'Tour revenues', budget_row_typeid:'0'},
   {name: 'Marketing',step:'1', header:'Tour revenues', budget_row_typeid:'0'},
   {name: 'Tour support',step:'1', header:'Tour revenues', budget_row_typeid:'0'},
]
export const step2 = [
   // tour gage
   {name: 'Gage fixed', step:'2', header:'Talent', budget_row_typeid:'1'},
   {name: 'Gage variable', step:'2', header:'Talent', budget_row_typeid:'2'},
   //Ensemble
   {name: 'Musicians', step:'2', header:'Ensemble', budget_row_typeid:'3'},
   {name: 'Dancers', step:'2', header:'Ensemble', budget_row_typeid:'3'},
   // Crew
   {name: 'Local rep', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'PM', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'TM', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Wardrobe', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'FOH', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'System technician', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Sound', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Monitor', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Backline', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Light Op', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Light Crew Manager', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Dimmer', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Rigger', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Stage Manager', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Stage', step:'2', header:'Crew', budget_row_typeid:'3'},
   {name: 'Picture', step:'2', header:'Crew', budget_row_typeid:'3'},
]
export const step3 = [
   {name: 'Artist', step:'3', header:'Hotel and Travel', budget_row_typeid:'3'},
   {name: 'Ensemble and orchestra', step:'3', header:'Hotel and Travel', budget_row_typeid:'3'},
   {name: 'Crew', step:'3', header:'Hotel and Travel', budget_row_typeid:'3'},
   {name: 'Additional trips', step:'3', header:'Hotel and Travel', budget_row_typeid:'3'},
]
export const step4 = [
   {name: 'Sound, Light, Trailers, Crew Busses', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Stage and stage set', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Video and content screen', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Instrument', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Additional scenography', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Designers', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Clothes', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Repetition rent', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Documentation', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Photography', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Premiere and ending party', step:'4', header:'Tour production', budget_row_typeid:'1'},
   {name: 'Other production costs', step:'4', header:'Tour production', budget_row_typeid:'1'},
]
export const step5 = [
   {name: 'Press advertising', step:'5', header:'Tour marketing', budget_row_typeid:'1'},
   {name: 'Poster print/distribution', step:'5', header:'Tour marketing', budget_row_typeid:'1'},
   {name: 'Flyers/leaflets', step:'5', header:'Tour marketing', budget_row_typeid:'1'},
   {name: 'TV/radio and spot production', step:'5', header:'Tour marketing', budget_row_typeid:'1'},
   {name: 'Facebook', step:'5', header:'Tour marketing', budget_row_typeid:'1'},
   {name: 'Instagram', step:'5', header:'Tour marketing', budget_row_typeid:'1'},
   {name: 'Merchandise', step:'5', header:'Tour marketing', budget_row_typeid:'1'},
]
export const step6 = [
   {name: 'Insurance costs', step:'6', header:'Other', budget_row_typeid:'1'},
   {name: 'Administrative costs', step:'6', header:'Other', budget_row_typeid:'1'},
]

export function getStepArray(step) {
   switch (step) {
      case 0: // all
         return step1.concat(step2.concat(step3.concat(step4.concat(step5.concat(step6)))));
      case 1: 
         return step1;
      case 2:
         return step2;
      case 3:
         return step3;
      case 4:
         return step4;
      case 5:
         return step5;
      case 6:
         return step6;
      default:
         return [];
   }
}