<template>
	<v-expansion-panels accordion >
		<div class="edit-slider-header d-flex">
			<v-btn class="mr-5 close-drawer" @click="closeEditDrawer" icon depressed >
				<v-icon color="primary" dark >menu_open</v-icon>
			</v-btn>
			<div class="header">
				<span>Edit</span>
			</div>
			<v-spacer></v-spacer>
			<v-btn color="primary" :loading="isSliderDataSavePending" small depressed @click="setData" :disabled="noChangesMade">Save changes</v-btn>
		</div>
		<v-expansion-panel>
			<v-expansion-panel-header>General</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.title"
							outlined
							dense
							hide-details
							label="Name"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.department"
							:items="department_list"
							dense
							hide-details
							filled
							label="Department"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu
							v-model="startDateMenu"
							:close-on-content-click="false"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.date"
									label="Start Date"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									dense
									hide-details
									readonly
								></v-text-field>
							</template>
							<v-date-picker v-model="items.date" no-title @input="startDateMenu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu
							v-model="endDateMenu"
							:close-on-content-click="false"
							:nudge-right="40"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.date_end"
									label="End Date"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									:disabled="items.department !== 'Swedish' && items.department !== 'Luger'"
									dense
									hide-details
									readonly
								></v-text-field>
							</template>
							<v-date-picker v-model="items.date_end" no-title @input="endDateMenu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.artistid"
							:items="items.dropdowns.artists"
							dense
							hide-details
							filled
							label="Artist"
							item-text="name"
							item-value="artistid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.status"
							:items="status_list"
							dense
							hide-details
							filled
							label="Status"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu
							v-model="offerEndMenu"
							:close-on-content-click="false"
							:nudge-right="40"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.offer_end_date"
									label="Offer end date"
									prepend-inner-icon="event"
									v-on="on"
									:disabled="items.department !== 'Swedish' && items.department !== 'Luger'"
									outlined
									dense
									hide-details
									readonly
								></v-text-field>
							</template>
							<v-date-picker v-model="items.offer_end_date" no-title @input="offerEndMenu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.venueid"
							:items="items.dropdowns.venues"
							dense
							hide-details
							filled
							label="Venue/Scene"
							item-text="name"
							item-value="venueid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.venue_rent_info"
							outlined
							dense
							hide-details
							label="Venue rent info"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.venue_typeid"
							:items="venue_type_list"
							dense
							hide-details
							filled
							label="Venue type"
							item-text="name"
							item-value="venue_typeid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.type_of_event"
							:items="$config.TYPE_OF_EVENT_LIST"
							dense
							hide-details
							filled
							label="Event type"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.arenaholds"
							outlined
							dense
							hide-details
							label="Arenaholds"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.town"
							outlined
							dense
							hide-details
							label="Town"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.age_limit"
							type="number"
							outlined
							dense
							hide-details
							label="Minimum age limit"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.gross_available"
							type="number"
							outlined
							dense
							hide-details
							label="Sales capacity"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.organizer"
							:items="items.dropdowns.organizations"
							dense
							hide-details
							filled
							label="Organizer/invoice to"
							item-text="name"
							item-value="organizationid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							outlined
							dense
							hide-details
							label="Past due debt (Inte tillagd) "
							disabled
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">	
						<v-autocomplete
							v-model="items.agreed_with" 
							outlined
							:items="items.dropdowns.people"
							dense
							hide-details
							filled
							label="Agreed with"
							item-text="name"
							item-value="personid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">	
						<v-autocomplete outlined
							:items="show_type_list"
							v-model="items.show_type"
							dense
							hide-details
							filled
							label="Show type"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.support1"
							:items="items.dropdowns.artists"
							dense
							hide-details
							filled
							label="Support 1"
							item-text="name"
							item-value="artistid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.support2"
							:items="items.dropdowns.artists"
							dense
							hide-details
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
							filled
							label="Support 2"
							item-text="name"
							item-value="artistid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.avails"
							outlined
							dense
							hide-details
							label="Avails"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">	
						<v-autocomplete outlined
							v-model="items.booked_by"
							:items="items.dropdowns.users"
							dense
							hide-details
							filled
							label="Booked by"
							item-text="fullname"
							item-value="userid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							label="Send to (inte tillagd)"
							outlined
							disabled
							dense
							hide-details
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.oracleid"
							outlined
							dense
							hide-details
							label="Oracle ID"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-textarea
							outlined
							dense
							hide-details
							label="Miscellaneous"
							v-model="items.miscellaneous"
							required
						></v-textarea>
					</v-col>
					<v-col cols="12" md="12">
						<v-switch  v-model="makePrivate" label="Make private"></v-switch>
					</v-col>
					<v-col cols="12" md="12">
						<v-switch  v-model="sold" label="Sold"></v-switch>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Team</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.project_manager"
							:items="items.dropdowns.users"
							dense
							hide-details
							filled
							label="Project Manager"
							item-text="fullname"
							item-value="userid"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
							
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.settlement_representative"
							:items="items.dropdowns.users"
							dense
							hide-details
							filled
							label="Settlement representative"
							item-text="fullname"
							item-value="userid"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
							
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.production_manager"
							:items="items.dropdowns.users"
							dense
							hide-details
							filled
							label="Production manager"
							item-text="fullname"
							item-value="userid"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.venue_manager"
							:items="items.dropdowns.people"
							dense
							hide-details
							filled
							label="Venue manager"
							item-text="name"
							item-value="personid"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.bpc_promoterid"
							outlined
							dense
							hide-details
							label="BPC Promoter ID"
						></v-text-field>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Production</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.insurance"
							outlined
							dense
							hide-details
							label="Insurance"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu
							v-model="riderSentMenu"
							:close-on-content-click="false"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.rider_sent"
									label="Rider sent"
									prepend-inner-icon="event"
									:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
									v-on="on"
									outlined
									dense
									hide-details
									readonly
								></v-text-field>
							</template>
							<v-date-picker v-model="items.rider_sent" no-title @input="riderSentMenu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.arrival_time"
							label="Time of arrival"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.loading"
							label="Loading"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.soundcheck_start"
							label="Soundcheck start"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.soundcheck_end"
							label="Soundcheck end"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.dinner"
							:items="dinner_list"
							dense
							hide-details
							filled
							label="Dinner"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
							maxlength="5"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.venue_opens"
							label="Venue opens"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.on_stage_support"
							label="On stage(support)"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.change_over"
							label="Change over"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.on_stage"
							label="On stage"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.off_stage"
							label="Off stage"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.load_out"
							:items="load_out_list"
							dense
							hide-details
							filled
							label="Load out"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.venue_close"
							label="Venue close"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.venue_representative"
							:items="items.dropdowns.people"
							dense
							hide-details
							filled
							label="Venue representative"
							item-text="name"
							item-value="personid"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field 
							type="text" 
							outlined dense
							hide-details 
							label="Led and projector" 
							v-model="items.led_and_projector"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field 
							type="text" 
							outlined dense
							hide-details 
							label="Pyro and SFX" 
							v-model="items.pyro_and_sfx"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field 
							type="text" 
							outlined dense
							hide-details 
							label="Confetti cleaning" 
							v-model="items.confetti_cleaning"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.tech_responsible"
							:items="items.dropdowns.people"
							dense
							hide-details
							filled
							label="Tech responsible"
							item-text="name"
							item-value="personid"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.prod_coordinator"
							:items="items.dropdowns.users"
							dense
							hide-details
							filled
							label="Prod coordinator"
							item-text="fullname"
							item-value="userid"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.accommodation"
							:items="items.dropdowns.organizations"
							dense
							hide-details
							filled
							label="Accommodation"
							item-text="name"
							item-value="organizationid"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.transport"
							:items="items.dropdowns.organizations"
							dense
							hide-details
							filled
							label="Transport"
							item-text="name"
							item-value="organizationid"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu
							v-model="productionDoneMenu"
							:close-on-content-click="false"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.production_done"
									label="Production done"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									dense
									hide-details
									:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
									readonly
								></v-text-field>
							</template>
							<v-date-picker v-model="items.production_done" no-title @input="productionDoneMenu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-textarea
							outlined
							dense
							hide-details
							label="Miscellaneous accomodation"
							v-model="items.miscellaneous_accomodation"
							required
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-textarea>
					</v-col>
					<v-col cols="12" md="12">
						<v-textarea
							outlined
							dense
							hide-details
							label="Miscellaneous transport"
							v-model="items.miscellaneous_transport"
							required
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-textarea>
					</v-col>
					<v-col cols="12" md="12">
						<v-textarea
							outlined
							dense
							hide-details
							label="Miscellaneous prod"
							v-model="items.miscellaneous_prod"
							required
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-textarea>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Marketing</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-menu
							v-model="publicInfoFromMenu"
							:close-on-content-click="false"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.public_information_from"
									label="Public information from"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									dense
									hide-details
									readonly
								></v-text-field>
							</template>
							<v-date-picker v-model="items.public_information_from" no-title @input="publicInfoFromMenu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12"  class="datetimepicker_on_sale">
						<v-menu
							v-model="onSaleMenu"
							:close-on-content-click="false"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.on_sale"
									label="On sale date"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									dense
									hide-details
									readonly
								></v-text-field>
							</template>
							<v-date-picker
								v-model="items.on_sale"
								no-title
								@input="onSaleMenu = false"
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.on_sale_time"
							label="On sale time (hh:mm)"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12"  class="datetimepicker_on_sale">
						<v-menu
							v-model="pressDateMenu"
							:close-on-content-click="false"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.press_date"
									label="Press date"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									dense
									hide-details
									readonly
								></v-text-field>
							</template>
							<v-date-picker
								v-model="items.press_date"
								no-title
								@input="pressDateMenu = false"
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							:rules="timeRules"
							v-model="items.press_time"
							label="Press time (hh:mm)"
							prepend-inner-icon="access_time"
							outlined
							dense
							hide-details
							maxlength="5"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">	
						<v-autocomplete outlined
							v-model="items.artwork"
							:items="items.dropdowns.users"
							dense
							hide-details
							filled
							label="Artwork"
							item-text="fullname"
							item-value="userid"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">	
						<v-autocomplete outlined
							v-model="items.pr"
							:items="items.dropdowns.users"
							dense
							hide-details
							filled
							label="PR"
							item-text="fullname"
							item-value="userid"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.head_of_marketing"
							:items="items.dropdowns.users"
							dense
							hide-details
							filled
							label="Head of marketing"
							item-text="fullname"
							item-value="userid"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="items.press_tickets"
							label="Press tickets"
							outlined
							dense
							hide-details
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox
							class="mt-1"
							label="Publish on website"
							v-model="items.publish_on_website"
							true-value="1"
							false-value="0"
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox
							class="mt-1"
							label="Luger release tickets"
							v-model="items.luger_release_tickets"
							true-value="1"
							false-value="0"
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox
							class="mt-1"
							label="LN release tickets"
							v-model="items.ln_release_tickets"
							true-value="1"
							false-value="0"
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu
							v-model="ticketReleaseDateMenu"
							:close-on-content-click="false"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.ticket_release_date"
									label="Ticket release date"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									dense
									hide-details
									:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
									readonly
								></v-text-field>
							</template>
							<v-date-picker v-model="items.ticket_release_date" no-title @input="ticketReleaseDateMenu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox
							class="mt-1"
							label="Organizer ok pre-sale"
							v-model="items.organizer_ok_pre_sale"
							true-value="1"
							false-value="0"
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu
							v-model="tele2ReleaseTicketDateMenu"
							:close-on-content-click="false"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="items.tele2_ticket_release_date"
									label="Tele2 release ticket date"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									dense
									hide-details
									:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
									readonly
								></v-text-field>
							</template>
							<v-date-picker v-model="items.tele2_ticket_release_date" no-title @input="tele2ReleaseTicketDateMenu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox
							class="mt-1"
							label="Link sent"
							v-model="items.link_sent"
							true-value="1"
							false-value="0"
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.ticket_release_company"
							:items="items.dropdowns.organizations"
							dense
							hide-details
							filled
							label="Release ticket company"
							item-text="name"
							item-value="organizationid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-textarea
							outlined
							dense
							hide-details
							label="Ticket comments"
							v-model="items.ticket_comments"
							required
						></v-textarea>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Economy agent</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-text-field 
							type="number" 
							outlined 
							dense
							hide-details 
							label="Gage Talent" 
							v-model="items.gage_talent"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field 
							type="number" 
							outlined 
							dense
							hide-details 
							label="Agent Commission" 
							v-model="items.agent_commission"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field 
							type="number" 
							outlined 
							dense
							hide-details 
							label="Agency Commission" 
							v-model="items.agency_commission"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.taxes_sold"
							:items="taxes_sold"
							dense
							hide-details
							filled
							label="Taxes Sold"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.percent_deal"
							:items="percent_deal"
							dense
							hide-details
							filled
							label="Percent Deal"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Settlement Price" v-model="items.settlement_price">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Taxes Apply" v-model="items.taxes_apply"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Specific Agreement" v-model="items.specifik_agreement">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Result Percent Deal" v-model="items.result_percent_deal">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Organizer Hotell" v-model="items.cost_organizer_hotell"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Organizer Travel" v-model="items.cost_organizer_travel"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Organizer Sound" v-model="items.cost_organizer_sound"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Organizer Light" v-model="items.cost_organizer_light"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Organizer Backline" v-model="items.cost_organizer_backline"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Organizer Scen" v-model="items.cost_organizer_scen"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Organizer Catering" v-model="items.cost_organizer_catering"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Orgnaizer Internal Transport" v-model="items.cost_orgnaizer_internal_transport"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Artist Hotell" v-model="items.cost_artist_hotell"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Artist Travel" v-model="items.cost_artist_travel"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Artist Sound" v-model="items.cost_artist_sound"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Artist Light" v-model="items.cost_artist_light"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Artist Backline" v-model="items.cost_artist_backline"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Artist Scen" v-model="items.cost_artist_scen"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Cost Artist Catering" v-model="items.cost_artist_catering"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" outlined dense
						hide-details label="Cost Artist Internal Transport" v-model="items.cost_artist_internal_transport"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.payment_condition"
							:items="payment_condition"
							dense
							hide-details
							filled
							label="Payment Condition"
							clearable
							:disabled="items.department !== 'Luger' && items.department !== 'Swedish'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="contract_send_date_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.contract_send_date" label="Contract Send Date" prepend-inner-icon="event" v-on="on"
									outlined dense
									hide-details readonly></v-text-field>
							</template>
							<v-date-picker v-model="items.contract_send_date" no-title @input="contract_send_date_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="contract_return_date_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.contract_return_date" label="Contract Return Date" prepend-inner-icon="event" v-on="on"
									outlined dense
									hide-details readonly></v-text-field>
							</template>
							<v-date-picker v-model="items.contract_return_date" no-title @input="contract_return_date_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Invoiced" v-model="items.invoiced"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Comments Economy" v-model="items.comments_Economy">
						</v-text-field>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Economy organizer</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Commision In Engagement Contract" v-model="items.commision_in_engagement_contract"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Other Agreements" v-model="items.other_agreements">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Ready For Invoice" v-model="items.ready_for_invoice"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Invoice Number" v-model="items.invoice_number"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Project Number" v-model="items.project_number"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Gage Support" v-model="items.gage_support"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="number" outlined dense
						hide-details label="Taxed Compensation" v-model="items.taxed_compensation">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="number" outlined dense
						hide-details label="Deducted Tax" v-model="items.deducted_tax"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Accounting Period" v-model="items.accounting_period">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-checkbox true-value="1" false-value="0" class="mt-1" label="Tax Reported" v-model="items.tax_reported"></v-checkbox>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Tax Percent" v-model="items.tax_percent"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Other Economy" v-model="items.other_Economy"></v-text-field>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Ticket management</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-switch  v-model="flex" label="Flex"></v-switch>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="number" outlined dense
						hide-details label="Max Tickets" v-model="items.max_tickets"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.ticket_responsible"
							:items="items.dropdowns.people"
							dense
							hide-details
							filled
							label="Ticket responsible"
							item-text="name"
							item-value="personid"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.presale_fan_club"
							:items="presale"
							dense
							hide-details
							filled
							label="Presale Fan Club"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="startdate_fan_club_menu" :close-on-content-click="false" offset-y min-width="290px">
							<template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.startdate_fan_club" label="Startdate Fan Club" prepend-inner-icon="event" v-on="on"
									outlined dense
									hide-details readonly :disabled="items.presale_fan_club !== 'Yes' && items.presale_fan_club !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.startdate_fan_club" no-title @input="startdate_fan_club_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field true-value="1" dense
						hide-details outlined prepend-inner-icon="access_time" :rules="timeRules" maxlength="5"
							false-value="0" label="Starttime Fan Club" v-model="items.starttime_fan_club" :disabled="items.presale_fan_club !== 'Yes' && items.presale_fan_club !== 'TBC'"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="enddate_fan_club_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.enddate_fan_club" label="Enddate Fan Club" prepend-inner-icon="event" v-on="on" outlined
									dense
									hide-details readonly :disabled="items.presale_fan_club !== 'Yes' && items.presale_fan_club !== 'TBC'" ></v-text-field>
							</template>
							<v-date-picker v-model="items.enddate_fan_club" no-title @input="enddate_fan_club_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field true-value="1" dense
						hide-details outlined prepend-inner-icon="access_time" :rules="timeRules" maxlength="5"
							false-value="0" label="Endtime Fan Club" v-model="items.endtime_fan_club" :disabled="items.presale_fan_club !== 'Yes' && items.presale_fan_club !== 'TBC'"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="number" outlined dense
						hide-details label="Max Tickets Fan Club" v-model="items.max_tickets_fan_club" :disabled="items.presale_fan_club !== 'Yes' && items.presale_fan_club !== 'TBC'">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.presale_amex"
							:items="presale"
							dense
							hide-details
							filled
							label="Presale Amex"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="startdate_amex_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.startdate_amex" label="Startdate Amex" prepend-inner-icon="event" v-on="on" outlined dense
								hide-details
									readonly :disabled="items.presale_amex !== 'Yes' && items.presale_amex !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.startdate_amex" no-title @input="startdate_amex_menu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field true-value="1" dense
						hide-details outlined prepend-inner-icon="access_time" :rules="timeRules" maxlength="5"
							false-value="0" label="Starttime Amex" v-model="items.starttime_amex" :disabled="items.presale_amex !== 'Yes' && items.presale_amex !== 'TBC'"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="enddate_amex_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.enddate_amex" label="Enddate Amex" prepend-inner-icon="event" v-on="on" outlined dense
								hide-details
									readonly :disabled="items.presale_amex !== 'Yes' && items.presale_amex !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.enddate_amex" no-title @input="enddate_amex_menu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field true-value="1" dense
						hide-details outlined prepend-inner-icon="access_time" :rules="timeRules" maxlength="5"
							false-value="0" label="Endtime Amex" v-model="items.endtime_amex" :disabled="items.presale_amex !== 'Yes' && items.presale_amex !== 'TBC'"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="number" outlined dense
						hide-details label="Max Tickets Amex" v-model="items.max_tickets_amex" :disabled="items.presale_amex !== 'Yes' && items.presale_amex !== 'TBC'">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.presale_local_presale"
							:items="presale"
							dense
							hide-details
							filled
							label="Presale Local Presale"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="startdate_local_presale_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.startdate_local_presale" label="Startdate Local Presale" prepend-inner-icon="event"
									v-on="on" outlined dense
									hide-details readonly :disabled="items.presale_local_presale !== 'Yes' && items.presale_local_presale !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.startdate_local_presale" no-title
								@input="startdate_local_presale_menu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field true-value="1" dense
						hide-details outlined prepend-inner-icon="access_time" :rules="timeRules" maxlength="5"
							false-value="0" label="Starttime Local Presale" v-model="items.starttime_local_presale" :disabled="items.presale_local_presale !== 'Yes' && items.presale_local_presale !== 'TBC'"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="enddate_local_presale_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.enddate_local_presale" label="Enddate Local Presale" prepend-inner-icon="event"
									v-on="on" outlined dense
									hide-details readonly :disabled="items.presale_local_presale !== 'Yes' && items.presale_local_presale !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.enddate_local_presale" no-title @input="enddate_local_presale_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field true-value="1" dense
						hide-details outlined prepend-inner-icon="access_time" :rules="timeRules" maxlength="5"
							false-value="0" label="Endtime Local Presale" v-model="items.endtime_local_presale" :disabled="items.presale_local_presale !== 'Yes' && items.presale_local_presale !== 'TBC'"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="number" outlined dense
						hide-details label="Max Tickets Local Presale"
							v-model="items.max_tickets_local_presale" :disabled="items.presale_local_presale !== 'Yes' && items.presale_local_presale !== 'TBC'"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.presale_spotify"
							:items="presale"
							dense
							hide-details
							filled
							label="Presale Spotify"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="startdate_spotify_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.startdate_spotify" label="Startdate Spotify" prepend-inner-icon="event" v-on="on"
									outlined dense
									hide-details readonly :disabled="items.presale_spotify !== 'Yes' && items.presale_spotify !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.startdate_spotify" no-title @input="startdate_spotify_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field true-value="1" dense
						hide-details outlined prepend-inner-icon="access_time" :rules="timeRules" maxlength="5"
							false-value="0" label="Starttime Spotify" v-model="items.starttime_spotify" :disabled="items.presale_spotify !== 'Yes' && items.presale_spotify !== 'TBC'"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="enddate_spotify_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.enddate_spotify" label="Enddate Spotify" prepend-inner-icon="event" v-on="on" outlined
									dense
									hide-details readonly :disabled="items.presale_spotify !== 'Yes' && items.presale_spotify !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.enddate_spotify" no-title @input="enddate_spotify_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field true-value="1" dense
						hide-details outlined prepend-inner-icon="access_time" :rules="timeRules" maxlength="5"
							false-value="0" label="Endtime Spotify" v-model="items.endtime_spotify" :disabled="items.presale_spotify !== 'Yes' && items.presale_spotify !== 'TBC'"></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="number" outlined dense
						hide-details label="Max Tickets Spotify" v-model="items.max_tickets_spotify" :disabled="items.presale_spotify !== 'Yes' && items.presale_spotify !== 'TBC'">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.presale_album"
							:items="presale"
							dense
							hide-details
							filled
							label="Presale album"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="startdate_album_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.startdate_album" label="Startdate album" prepend-inner-icon="event" v-on="on"
									outlined dense
									hide-details readonly :disabled="items.presale_album !== 'Yes' && items.presale_album !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.startdate_album" no-title @input="startdate_album_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="enddate_album_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.enddate_album" label="Enddate album" prepend-inner-icon="event" v-on="on" outlined
									dense
									hide-details readonly :disabled="items.presale_album !== 'Yes' && items.presale_album !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.enddate_album" no-title @input="enddate_album_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field 
							type="text" 
							outlined dense
							hide-details 
							label="Album Alottment" 
							v-model="items.album_allotment"
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.presale_sas"
							:items="presale"
							dense
							hide-details
							filled
							label="Presale sas"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="startdate_sas_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.startdate_sas" label="Startdate sas" prepend-inner-icon="event" v-on="on"
									outlined dense
									hide-details readonly :disabled="items.presale_sas !== 'Yes' && items.presale_sas !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.startdate_sas" no-title @input="startdate_sas_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="enddate_sas_menu" :close-on-content-click="false" offset-y min-width="290px"><template
								v-slot:activator="{ on }">
								<v-text-field v-model="items.enddate_sas" label="Enddate sas" prepend-inner-icon="event" v-on="on" outlined
									dense
									hide-details readonly :disabled="items.presale_sas !== 'Yes' && items.presale_sas !== 'TBC'"></v-text-field>
							</template>
							<v-date-picker v-model="items.enddate_sas" no-title @input="enddate_sas_menu = false">
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field 
							type="text" 
							outlined dense
							hide-details 
							label="Scandic Alottment" 
							v-model="items.scandic_allotment"
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-switch  v-model="aisle_seating" label="Aisle seating"></v-switch>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field 
							type="text" 
							outlined dense
							hide-details 
							label="Aisle seating info" 
							v-model="items.aisle_seating_info"
							:disabled="!aisle_seating"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.platinum"
							:items="presale"
							dense
							hide-details
							filled
							label="Platinum"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="number" outlined dense
						hide-details label="Platinum Amount" v-model="items.platinum_amount" :disabled="items.platinum !== 'Yes' && items.platinum !== 'TBC'">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.vip_package"
							:items="presale"
							dense
							hide-details
							filled
							label="Vip Package"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field type="text" outlined dense
						hide-details label="Other Platinum Vip" v-model="items.other_platinum_vip" :disabled="items.vip_package !== 'Yes' && items.vip_package !== 'TBC'">
						</v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete outlined
							v-model="items.collectors_tickets"
							:items="presale"
							dense
							hide-details
							filled
							label="Collectors Tickets"
							clearable
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field 
							type="text" 
							outlined dense
							hide-details 
							label="Amex Alottment" 
							v-model="items.amex_allotment"
							:disabled="items.department !== 'Concert' && items.department !== 'Luger Promoter'"
						/>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
	 </v-expansion-panels>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {
		name:'concertexpandcollapse',
		props:{
			isSliderDataSavePending:Boolean,
			items:Object,
			originalData:Object,
			fk_objects:Array,
			venue_type_list:Array,
		},
		data:()=>({
			noChangesMade:true,
			makePrivate:false,
			sold:false,
			flex: false,
			aisle_seating: false,
			onSaleDate:'',
			department_list:['Swedish', 'Concert', 'Luger', 'Luger Promoter'],
			status_list:['Hold', 'Offer', 'Confirmed', 'Unavailable', 'Declined', 'Routing', 'Repetition', 'Cancelled', 'We declined', 'Artist declined', 'Company gig'],
			show_type_list:['Egen', 'Samarr', 'Såld'],
			dinner_list:['Buy out', 'On site'],
			load_out_list:['After gig', 'After closing'],
			taxes_sold:['Excl. 25%', 'Excl. 6%', 'Excl. Incl. 25%', 'Incl. 6%'],
			percent_deal:['Flat', '+70% after break even', 'vs 60% of gross income', 'vs 80% of net inocome'],
			payment_condition:[
				'100 % av garantigaget skall enligt faktura vara leverantören tillhanda senast YYYY-MM-DD.', 
				'100 % av garantigaget skall enligt faktura vara leverantören tillhanda senast 30 dagar innan genomförd konsert', 
				'100 % av garantigaget skall enligt faktura vara leverantören tillhanda senast YYYY-MM-DD. Eventuellt procentutfall faktureras enligt avräkning första helgfria vardag efter konsert.',
				'50 % av garantigaget skall enligt faktura vara leverantören tillhanda senast YYYY-MM-DD.. Resterande garantigage skall vara leverantören tillhanda senast YYYY-MM-DD.',
				'Procentutfall faktureras enligt avräkning första helgfria vardag efter spelat arrangemang.',
				'The promoter agrees to wire 100% of the guarantee fee, subject to tax, no later than YYYY-MM-DD.',
				'The promoter agrees to wire 100% of the guarantee fee, subject to tax, no later than YYYY-MM-DD. The Balance plus the potential % breakdown, subject to tax, are to be wired fist day after show.',
			],
			startdate_fan_club:'',
			presale:['Yes', 'No', 'TBC'],
			timeRules:[
				value => {
				const pattern = /(?:[01]\d|2[0123]):(?:[012345]\d)/
				return pattern.test(value) || 'Invalid Timeformat(hh:mm)'
				}
			]
		}),
		watch: {
		'items': {
			handler: function(v) {
				console.log('items watcher')
				for(let key in this.items){
					if(this.items[key] === undefined){
						this.items[key] = null
					}
				}
				if(JSON.stringify(this.items) === JSON.stringify(this.originalData)){
					this.noChangesMade = true
				} else {
					this.noChangesMade = false
				}
			},
			deep: true
			},
			makePrivate(v){
				if(this.makePrivate){
					this.items.private_userid = this.userInfo.userid
				} else {
					this.items.private_userid = ''
				}
			},
			sold(){
				if(this.items.sold_status){
					this.items.sold_status = '1'
				} else {
					this.items.sold_status = '0'
				}
			},
			onSaleDate(v){
				if(!v){
					this.items.on_sale = ''
				}
			},
			flex(v){
				if(v){
					this.items.flex = '1';
				}else
					this.items.flex = '0';
			},
			aisle_seating(v){
				if(v){
					this.items.aisle_seating = '1';
				}else{
					this.items.aisle_seating_info = '';
					this.items.aisle_seating = '0';
				}

			}
		},
		async created(){
			this.checkIfPrivateOrIfSold();
			
			this.onSaleDate = new Date(this.items.on_sale)
			await this.fixDropDownValues();
			console.log(this.items)

		},
		methods:{
			checkIfPrivateOrIfSold(){
				this.items.private_userid > '' ? this.makePrivate = true : this.makePrivate = false
				this.items.sold_status === '1' ? this.sold = true : this.sold = false
				this.aisle_seating = this.items.aisle_seating == '1' ? true : false;
				this.flex = this.items.flex == '1' ? true : false;
			},
			async fixDropDownValues(){
				for(let fk of this.items.fk_objects){
					let table_fk_key = fk.table_fk_key
					let fk_key = fk.fk_key
					if(typeof this.items[table_fk_key] === 'object' && this.items[table_fk_key] !== null){
						// console.log(table_fk_key, fk_key);
						// console.log(this.items[table_fk_key])
						this.items[table_fk_key] = this.items[table_fk_key][fk_key]
					}
				}
			},
			closeEditDrawer(){
				console.log('asd')
				this.$emit('closeEditDrawer')
			},
			async setData(){
				console.log(this.items)
				this.fixDropDownValues()

				this.fixTickets()
				this.$emit('setData', this.items)
				this.originalData = JSON.parse(JSON.stringify(this.items))
			},
			async fixTickets(){
				const maxDatesArray  = [this.items.date, this.items.date_end]
				const minDatesArray = []
				
				const pushDateIfSet = (...dates) => {
					dates.map(date => date ? minDatesArray.push(date) : null)
				}

				pushDateIfSet(this.items.on_sale, this.items.startdate_spotify, this.items.startdate_fan_club, this.items.startdate_local_presale, this.items.startdate_amex)

				const minDate = new Date(Math.min(...minDatesArray.map(date => new Date(date).setHours(0,0,0,0))))
				const maxDate = new Date(Math.max(...maxDatesArray.map(date => new Date(date).setHours(0,0,0,0))))
				
				let ticketObjects = []
				console.log('IOTEMS');
				console.log(this.items);
				this.items.tickets.map(ticket => {
					if(ticket.ticket_sales){
						ticket.ticket_sales.map(sales =>{
							if(new Date(sales.date).setHours(0,0,0,0) >= maxDate || new Date(sales.date).setHours(0,0,0,0) <= minDate)
								ticketObjects.push(sales)
						})
					}
				})
			}
		},
		computed:{
			...mapGetters({
      		userInfo:'user/userInfo'
    		})
		}
	}
</script>

<style lang="scss" scoped>

.close-drawer{
	i{
		transform:rotate(180deg);
		margin-right:3px;
	}
	&:hover{
		background:$border-color!important;
	}
	
}
.edit-slider-header{
	width:100%;
	float:left;
	padding:12px 20px;
	z-index:1;
	display:flex;
	align-items: center;

.header{
	font-weight:600;
	font-size:20px;
	color:$font-color-pale;
	width:100%;
	height:100%;
	display:flex;
	align-items: center;
	}
}

.expansion-panel-container{
	width:100%;
	float:left;
}

.v-expansion-panel::before{
	box-shadow:none;
}

.v-expansion-panels{
	border-radius:0px;
}

.v-expansion-panel-header{
	text-transform: uppercase;
	font-weight:500;
	color:#666;
	height:60px;
	transition:all 0.2s ease;
	&:hover {
		color:$accent-color;
	}
}
.v-item-group{
	border-top:1px solid $border-color!important;
	border-bottom:1px solid $border-color!important;
}
</style>