<template>
    <div id="sales">
        <v-col cols="12" md="12" class="elevation-1 pa-0">
            <ViewHeader :header="header" />
            <sales-page />
        </v-col>
    </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import SalesPage from "@/components/SalesPage";
import { mapGetters } from "vuex";
export default {
    name: "sales",
    components: {
        SalesPage,
        ViewHeader,
    },
    data: () => ({
    }),
    computed: {
        ...mapGetters({
            view: "globalstates/view",
        }),
        header() {
            return `Browse ${this.$getRoute(this.view).label}`;
        },
    },
};
</script>

<style lang="scss" scoped></style>
