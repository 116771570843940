function getEchartOptions() {

    var options = {
        title: {
            id: "chartTitle",
            text: "EChart Test",
            show: false
        },

        textStyle: {
            //fontFamily: ["Courier New", "Courier", "monospace"]
            //light
            //color: '#ffffff'
            //dark
            color: '#262626'
        },

        // line colors (in series)
        color: [
            // light
            // "#21b8c2",
            // "#ADFF2F",
            // "#d9a656",
            // "#ff0000",
            // "#fff",
            // "#ff69b4"
            // dark
            "#3e5894",
            "#31bd56",
            "#9531bd",
            "#bd3136",
            "#b39b3e",
            "#bd6231"
        ],

        legend: {
            type: 'scroll',
            textStyle: {
                //light
                color: "rgba(255,255,255, 0.95)",
                //dark
                color: "rgba(38, 38, 38, 0.95)",
            },
            inactiveColor: '#a8a8a8'
        },

        tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(22, 22, 22, 0.75)",
            textStyle: {
                color: "#fff"
            },
            axisPointer: {
                type: 'cross'
            },
            formatter: function(params) {
                if(params.length > 0){
                    var res = params[0].name;
                    for (var i = 0, l = params.length; i < l; i++) {
                        if(params[i].value[1] == null)
                            res = '';
                        else
                            res += '<br/>' + params[i].seriesName + ': ' + params[i].value[1];
                    }
                    return res;
                }
            }
        },

        xAxis: {
            type: "category",
            axisLine: {
                show: true
            },
            axisTick: {
                show: true
            },
            axisLabel: {
                show: true
            },
            splitLine: {
                show: false,
                //interval: 10
            }
        },
        yAxis: [
            {
                gridIndex: 0,
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: true
                },
                splitLine: {
                    show: true
                    //interval: 10
                }
            },
            {
                gridIndex: 0,
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed',
                        opacity: 0.7
                    }
                    //interval: 10
                },
                // max: function (value) {
                //     return value.max + Math.round(value.max / 4)
                // }
                //boundaryGap: ['0%', '25%']
            }
        ],

        grid: {
            show: true,
            //top:'20%',
            //align: 'auto',
            containLabel: true
            //color: '#52667f',
            //backgroundColor: "rgba(140, 162, 194, 0.0)"
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100
            },
            {
                type: 'slider',
                bottom: '0%',
                show: true,
                start: 0,
                end: 100,
                xAxisIndex: [0],
                dataBackground: {
                    lineStyle: {

                    },
                    areaStyle: {
                        color: 'rgba(47, 84, 150, 0.5)'
                    }
                },
                backgroundColor: 'rgba(47, 84, 150, 0)',
            }
        ],

        toolbox: {
            //backgroundColor: 'rgba(241, 241, 243, 1)',
            top: '8%',
            feature: {
                saveAsImage: {
                    show: true,
                    title: "Save as image"
                },
                // dataView: {
                //     show: true,
                //     title: 'Show raw data',
                //     lang: ['Raw data', 'Close', 'Refresh'],
                //     buttonColor: '#2f5496',
                // }
            }
        }
    };

    return options;
}

/**
 * @param {Array} xAxis The xaxis values, dates, hours and so on
 * @param {string} title Graph title
 * @param {boolean} zoom true/false to toggle zooming feature
 * @param {Array} colors Optional color array
 */
export function initEchart( { xAxisData = [], title = '', yAxisLTitle = '', yAxisRTitle = '', zoom = true, colors = [] } ) {
    var echart_options = getEchartOptions();

    if (!zoom && echart_options.dataZoom)
        echart_options.dataZoom = [];

    if (colors.length > 0)
        echart_options.color = colors;

    echart_options.series = [];

    echart_options.title.text = title;

    if (xAxisData.length > 0)
        echart_options.xAxis.data = xAxisData;

    echart_options.yAxis[0].name = yAxisLTitle;
    echart_options.yAxis[1].name = yAxisRTitle;

    return echart_options;
}

export function initEchartTwoX( { xAxisData = [], xAxisData2 = [],  title = '', yAxisLTitle = '', yAxisRTitle = '', zoom = true, colors = [] } ) {
    var echart_options = getEchartOptions();

    if(echart_options.dataZoom){
        if (!zoom)
            echart_options.dataZoom = [];
        else
            echart_options.dataZoom[1].xAxisIndex.push(1);
    }

    if (colors.length > 0)
        echart_options.color = colors;

    echart_options.series = [];

    echart_options.title.text = title;
    echart_options.xAxis = [
        {
            type: "category",
            axisLine: {
                show: true
            },
            axisTick: {
                show: true
            },
            splitLine: {
                show: false,
                //interval: 10
            }
        },
        {
            type: "category",

            axisLine: {
                show: true
            },
            axisTick: {
                show: true
            },
            splitLine: {
                show: false,
                //interval: 10
            }
        }
    ]

    echart_options.xAxis[0].data = xAxisData;
    echart_options.xAxis[1].data = xAxisData2;
    
    echart_options.yAxis[0].name = yAxisLTitle;
    echart_options.yAxis[1].name = yAxisRTitle;

    return echart_options;
}

export function generateSerie( { data, name, type = 'line', yAxis = 0, smooth = true, linestyle = 'solid', lineWidth = 2 } ) {
    var serie = {
        name: name,
        data: data,
        yAxisIndex: yAxis,
        type: type
    };

    serie.lineStyle = { type: linestyle, width: lineWidth };

    if (type == 'line') {
        serie.smooth = smooth;
    }
    else if (type == 'bar') {
        serie.barWidth = '12%';
        //serie.showBackground = true;
        serie.backgroundStyle = {
            // barBorderColor: '#000',
            // barBorderWidth: 1,
            shadowBlur: {
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 10
            },
            shadowOffsetY: 3,
        };
    }

    return serie
}

export function generateSerieTwoX( { data, name, type = 'line', yAxis = 0, xAxis = 0, smooth = true, linestyle = 'solid' } ) {
    var serie = {
        name: name,
        data: data,
        yAxisIndex: yAxis,
        xAxisIndex: xAxis,
        type: type,
        markLine: {
            lineStyle: {
                color: '#000',
                type: 'solid'
            },
            data: []
        },
    };

    serie.lineStyle = { type: linestyle };

    if (type == 'line') {
        serie.smooth = smooth;
    }
    else if (type == 'bar') {
        serie.barWidth = '12%';
        //serie.showBackground = true;
        serie.backgroundStyle = {
            // barBorderColor: '#000',
            // barBorderWidth: 1,
            shadowBlur: {
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 10
            },
            shadowOffsetY: 3,
        };
    }

    return serie
}