const state = {
   activeFiltersNames: [],
   activeFilterConditions:''
 };
 
 const getters = {
   activeFiltersNames:state => state.activeFiltersNames,
   activeFilterConditions:state => state.activeFilterConditions
 };
 
 const actions = {
    setActiveFilterName({ commit }, activeFilterName) {
      commit('setActiveFilterName', activeFilterName)
   },
   setActiveFilterConditions({ commit }, activeFilterConditions) {
      commit('setActiveFilterConditions', activeFilterConditions)
   },
   removeActiveFilterNames({ commit }) {
      commit('removeActiveFilterNames')
   }
 };
 
const mutations = {
   setActiveFilterName: (state, activeFilterName) => state.activeFiltersNames.push(activeFilterName),
   setActiveFilterConditions: (state, activeFilterConditions) => state.activeFilterConditions = activeFilterConditions,
   removeActiveFilterNames: state => state.activeFiltersNames = []
}

export default {
   namespaced:true,
   state,
   getters,
   actions,
   mutations
};
