<template>
    <div>
        <v-container class="wizard-budget-container container--fluid" v-if="stepsSorted">
            <v-col cols="12" md="12" class="pa-0 pb-5">
                <h3 class="wizard-section-title">{{ stepHeader }}</h3>
            </v-col>
            
            <div v-for="(header, i) of costHeaders" :key="header.id">
                <!-- FOR CREW CALLS ONLY -->
                <div
                    v-if="header.toLowerCase() == 'staff costs'"
                    class="budget-section-wrapper elevation-1 mt-5"
                >
                    <div class="wizard-container-title mb-5">{{ header }}</div>

                    <div v-for="crewCallHeader of crewCallHeaders" :key="crewCallHeader">
                        <crew-calls-form
                            :crewType="crewCallHeader"
                            :crewCallDayHeaders="crewCallDayHeaders"
                            :crewCalls="stepData.budget_crew_calls[crewCallHeader]"
                            @crewCallsUpdated="(crewCalls, type) => $emit('crewCallsUpdated', crewCalls, type)"
                            @deleteCrewCall="(crewCall, index) => { stepData.deletedCrewCalls.push(crewCall); $delete(stepData.budget_crew_calls[crewCallHeader], index) }"
                        />
                    </div>
                </div>
                <!-- CREW CALLS END -->

                <!-- FOR THE REST OF PRODUCTION COSTS -->
                <div class="budget-section-wrapper elevation-1">
                    
                    <div class="wizard-container-title">
                        {{ header === 'staff costs' ? `other ${header}` : header }}
                    </div>

                    <div
                        class="wizard-form-text"
                        v-for="cost of stepThreeSortedData[i].costs"
                        :key="cost.id"
                    >
                        <budget-cost-form
                            :step="step"
                            :costItem="cost"
                            :index="i"
                            @deleteCost="deleteCostItem"
                        />
                    </div>

                    <v-col cols="12" md="12" class="primary--text d-flex align-center pl-0 pb-0">
                        <v-btn depressed @click.stop="addCostItem(i)" color="primary">
                            <v-icon left>add</v-icon>
                            Add
                            {{
                            header.toLowerCase() == "authority costs"
                            ? "authority"
                            : header
                            }}
                            cost
                        </v-btn>
                    </v-col>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import { BudgetPostItem } from "@/models/BudgetObjects.js";
import BudgetCostForm from "../Shared/ConcertCostForm";
import CrewCallsForm from "./CrewCallsForm";
import dayjs from "dayjs";

export default {
    name: "BudgetStepThree",

    components: {
        BudgetCostForm,
        CrewCallsForm,
    },

    props: {
        stepData: Array,
        concertDate: String,
    },

    data: () => ({
        stepHeader: "Show Expenses",
        stepsSorted: false,
        colNames: ["Type", "Cost"],
        costHeaders: [
            "Venue related",
            "Staff costs",
            "Production related",
            "Catering",
            "Authority costs",
        ],
        stepThreeSortedData: [
            { costs: [] },
            { costs: [] },
            { costs: [] },
            { costs: [] },
            { costs: [] },
        ],
        crewCallHeaders: [
            "Production calls",
            "Steel calls",
            "Casual labour calls",
            "Security",
            "First Aid",
            "Building staff",
        ],
        step: 3,
        crewCallDayHeaders: [],
    }),

    async created() {
        console.log(this.concertDate);
        console.log(this.stepData)
        this.crewCallsDate(this.concertDate);
        await this.sortSteps();
        this.stepsSorted = true;
    },

    mounted() {
        console.log('mounted');
    },

    updated(){
        this.$emit("isLoaded");
        console.log('updated')
    },

    methods: {
        getHeaderTitle(header) {
            console.log(header);
        },
        crewCallsDate(concertDate) {
            let dateArr = [];


            let tempDate = dayjs(new Date(concertDate)).subtract(7, "day")

            for (let i = 0; i < 15; i++) {
                dateArr.push(i === 7 ? "Start" : dayjs(tempDate).format("MMM, D"));
                tempDate = dayjs(tempDate).add(1, "day");
            }
            this.crewCallDayHeaders = dateArr;
        },
        async sortSteps() {
            for (let cost of this.stepData.costs) {
                if (cost.header == "Venue related")
                    this.stepThreeSortedData[0].costs.push(cost);
                if (cost.header == "Staff costs" || cost.header == "Staff related")
                    this.stepThreeSortedData[1].costs.push(cost);
                if (cost.header == "Production related")
                    this.stepThreeSortedData[2].costs.push(cost);
                if (cost.header == "Catering")
                    this.stepThreeSortedData[3].costs.push(cost);
                if (cost.header == "Authority costs")
                    this.stepThreeSortedData[4].costs.push(cost);
            }
            console.log('sorting done')
        },

        async deleteCostItem(costItem, index) {
            if (costItem.budget_rowid != undefined)
                this.stepData.deletedCosts.push(costItem);

            this.$delete(
                this.stepData.costs,
                this.stepData.costs.indexOf(costItem)
            );
            this.$delete(
                this.stepThreeSortedData[index].costs,
                this.stepThreeSortedData[index].costs.indexOf(costItem)
            );
        },

        async addCostItem(index) {
            let costItem = new BudgetPostItem();
            costItem.step = this.step;
            costItem.header = this.costHeaders[index];
            this.stepData.costs.push(costItem);
            this.stepThreeSortedData[index].costs.push(costItem);
        },
    },
};
</script>

<style lang="scss">
</style>
