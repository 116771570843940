/**
 * Mixin for GeneralForm functionality. 
 */
import ApiObject from "@/models/ApiObject";
import ObjectFactory from "@/models/ObjectFactory.js";
import { mapGetters } from "vuex";
export default {
    props: {
        items: Object,
        objectRoute: String,
        metaParam: String,
        options: Object,
        dropdown_lists: Object,
    },
    data: () => ({
        dataLoaded: false,
        showFields: true,
        formItems: {},
        formIsValid: false,
        metaData: {},
        dateMenu: {},
        fk_tables: {},
        isMissingPrerequisite: false,
        dialogOptions: {},
        portableFormType: '',
        showPortableForm: false,
        portableOriginField: null,
    }),
    async created() {
        this.dialogOptions = new ConfirmOptions({
            text: "Missing prerequisites",
            btnText: "OK",
            subText:
                "You need to create at least one organization/person > venue > artist > concert",
        });
        await this.prepareForm(this.items);
        this.dataLoaded = true;
    },
    watch: {
        formItems: {
            handler: function (v) {
                if(this.dataLoaded){
                    if ((JSON.stringify(this.formItems) !== JSON.stringify(this.originalItems))) {
                        this.$emit("changesMade");
                    } else {
                        this.$emit("noChangesMade");
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        addToFkTable(tableKey, item) {
            console.log(item);
            const apiCol = this.fk_tables[tableKey];

            /**
             * copy over the FK_NAME (ex: tourid) to the added item
             */
            item[apiCol.FK_NAME] = this.items[apiCol.FK_NAME];

            if (!apiCol.isInCollection(item))
                apiCol.addToCollection(item);
            
            console.log(`addToFkTable, this.fk_tables[${tableKey}.collection:`);
            console.log(this.fk_tables[tableKey].collection);
        },
        removeFromFkTable(tableKey, item){
            console.log(item);
            const apiCol = this.fk_tables[tableKey];
            const apiRemoveCol = this.fk_tables[`${tableKey}_to_remove`];

            /**
             * Clear the FK_NAME (ex:tourid) from the item to be removed
             */
            item[apiCol.FK_NAME] = 0;

            if (!apiRemoveCol.isInCollection(item))
                apiRemoveCol.addToCollection(item);

            apiCol.removeByPkField(item[item.pk_field]);
            
            console.log(`removeFromFkTable, this.fk_tables[${tableKey}_to_remove].collection:`);
            console.log(this.fk_tables[`${tableKey}_to_remove`].collection);
        },

        /**
         * Initializes the form content
         * @param {*} itemsToPrepare - The items to build the form from. Will be an ApiObject
         * @param {*} customOriginalItems - If you want to compare the form too some custom items
         */
        async prepareForm(itemsToPrepare, customOriginalItems = null) {
            console.log('prepareForm start')
            this.formItems = itemsToPrepare;

            /**
             * fix fk_objects for form
             * It takes inner objects and flatten them out, so only the relevant information is linked to the property
             * ex:  BEFORE= artistid: {artistid: 1, name: "Roland" }
             *      AFTER=  artistid: 1
             */
            if (itemsToPrepare.fk_objects) {
                for (let obj of itemsToPrepare.fk_objects)
                    if(itemsToPrepare[obj.table_fk_key]){
                        if(itemsToPrepare[obj.table_fk_key][obj.fk_key])
                            this.formItems[obj.table_fk_key] = itemsToPrepare[obj.table_fk_key][obj.fk_key];
                    }
            }
            
            // Save original state
            this.originalItems = JSON.parse(JSON.stringify(customOriginalItems == null ? this.formItems : customOriginalItems))

            // Build the api object, tied to the relevant endpoint
            const apiObj = await this.$ObjectFactory.BuildObject({
                endpoint: this.objectRoute,
            });

            // Get the raw metadata for the api object
            const rawMetaData = await apiObj.getMetaFields(this.metaParam);

            /**
             * Loop over the raw meta data and start building the actual metadata that will help render the form.
             * This work entails dividing the properties into categories, based the the TITLE meta data,
             * and also adding various properties based on what type of item it is.
             */
            this.metaData = {};
            for (let item of rawMetaData) {
                /**
                 * Get the category categoryPropKey
                 */
                var categoryPropKey = item.TITLE == "" ? "_other" : item.TITLE;
                
                /**
                 * Add an empty array to the category
                 */
                if (!this.metaData[categoryPropKey])
                    this.metaData[categoryPropKey] = [];
                
                /**
                 * Is date, add bool for dateMenu toggle
                 */
                if (item.DATA_TYPE == "date")
                    this.dateMenu[item.COLUMN_NAME] = false;
                
                if (this.itemIsSelection(item.COLUMN_NAME))
                    item.DATA_TYPE = item.COLUMN_NAME;

                /**
                 * Autocomplete item
                 * Adds more relevant meta properties tied to autocomplete
                 */
                if(this.showAutoComplete(item)){
                    item['items'] = this.autoItems(item);
                    
                    /**
                     * Multiselect is a special type of autocomplete, hence it is not marked as such
                     * Instead it initializes a multiselect ApiObjectCollection
                     */
                    if(item.DATA_TYPE == 'multiselect'){
                        /**
                         * Our collection for tracking items that are added
                         * ex: item.FK_TABLE == concerts 
                         */
                        this.fk_tables[item.FK_TABLE] = await this.setUpMultiSelect(item);
                        console.log('H*');
                        console.log(item);
                        /**
                         * If editing, also add a collection for items that are to be removed
                         */
                        this.fk_tables[`${item.FK_TABLE}_to_remove`] = await this.setUpMultiSelect(item, true);
                    }
                    else
                        item['autocomplete'] = true;
                    
                    /**
                     * Adds an object containing the "item-text" and "item-value" property keys
                     * ex: item.autoTextAndValue == { text: 'Daft Punk', value: 'artistid' }
                     */
                    item['autoTextAndValue'] = this.autoTextAndValue(item);

                    /**
                     * Add potential inner create button
                     */
                    item['showCreateButton'] = this.addCreateButton(item);
                }

                /**
                 * Add potential rules to the item
                 * DATA_TYPE time requires a special ruleset
                 */
                if(item.DATA_TYPE == 'time')
                    item['rules'] = this.options.getGeneralTimeRules(item.COLUMN_NAME);
                else
                    item['rules'] = this.options.getRules(item.COLUMN_NAME);

                /**
                 * After the item is fully initialized, push to the meta category array
                 */
                this.metaData[categoryPropKey].push(item);
            }
            console.log('prepareForm DONEZO');
            this.showFields = true;
           // this.dataLoaded = true;
        },

        async setUpMultiSelect(field, no_fill = false){
            const {FK_TABLE, FK_NAME, FK_TABLE_PK_FIELD} = field;
            const fillCollection = !no_fill && this.dropdown_lists[FK_TABLE] && this.metaParam == 'update';

            // Build Collection object
            let apiCol = await this.$ObjectFactory.BuildCollection({
                endpoint: FK_TABLE,
                metaFields: [FK_NAME, FK_TABLE_PK_FIELD]
            });

            

            // Copy over FK_NAME for later use
            apiCol['FK_NAME'] = FK_NAME;
            /**
             * If editing an Api Object, collect the relevant FK_OBJECTS that are tied to this Api Object and
             * add the relevant items to the newly built collection
             */
            if (fillCollection)
                this.dropdown_lists[FK_TABLE].filter(
                    item => item[FK_NAME] ? item[FK_NAME][FK_NAME] == this.items[FK_NAME] : null
                ).forEach(
                    item => apiCol.addToCollection(item)
                );

            console.log(apiCol)
            return apiCol;
        },

        formatLabel(COLUMN_NAME) {
            return this.options.getLabelRule(COLUMN_NAME);
        },
        itemIsSelection(COLUMN_NAME) {
            return this.options.isSelect(COLUMN_NAME);
        },
        showAutoComplete(field) {
            return (
                field.DATA_TYPE == "fk_object" ||
                this.options.showAutoComplete(field)
            );
        },
        autoItems(field) {
            // console.log('autoItems', field)
            // console.log(this.dropdown_lists)
            if (field.DATA_TYPE == "fk_object" || field.DATA_TYPE == 'multiselect') {
                let items = this.dropdown_lists[field.FK_TABLE]
                // filter out unwanted items using FK_NAME
                if(field.FK_NAME){
                    items = items.filter(item => item[field.FK_NAME] == 0 || !item[field.FK_NAME])
                }
                return items;
            } else 
                return this.options.getFormList(field.COLUMN_NAME);
        },
        addCreateButton(field){
            if(field.DATA_TYPE == 'fk_object' && field.FK_TABLE != 'users' && field.FK_TABLE != 'venue_types')
                return true;
            return false;
        },
        openCreateNewWindow(field){
            console.log('formItems');
            console.log(this.formItems);
            this.portableFormType = field.FK_TABLE;
            this.showPortableForm = true;
            this.portableOriginField = field;
        },
        async handleNewPortableObject(newid){
            // this.dataLoaded = false;
            console.log('emitting for dropdownList refresh')
            this.showFields = false;
            this.$emit('refresh_dropdown_list', async () => {
                console.log('in callback')
                let value_key = this.portableOriginField.DROPDOWN_VALUE;
                this.formItems[this.portableOriginField.COLUMN_NAME] = newid
    
                // // TEST HÅRDSÄTT
                // this.formItems['name'] = 'HEHHEHEHEH'
                // this.formItems['our_agent'] = '00u2jjgdg6l7t6AnQ357';
                // this.formItems['press_contact'] = '156';
                // console.log('formItems');
                // console.log(this.formItems);
                // console.log('this.items');
                // console.log(this.items);
                await this.prepareForm(this.items, this.originalItems);
            })
        },
        autoTextAndValue(field, customKey = "DATA_TYPE") {
            if (
                (field[customKey] == "fk_object" || field[customKey] == "multiselect") 
                &&
                (!this.dropdown_lists[field.FK_TABLE] || this.dropdown_lists[field.FK_TABLE].length === 0)
            )
                this.isMissingPrerequisite = true;

            return this.options.getAutoTextAndValue(field, customKey);
        },
        validate() {
            if (this.$refs.form.validate()) {
                console.log('validated');
                if (this.items.fk_objects) {
                    for (let obj of this.items.fk_objects)
                        if (this.formItems[obj.table_fk_key] == undefined)
                            this.formItems[obj.table_fk_key] = "";
                }
                this.originalItems = JSON.parse(JSON.stringify(this.formItems));
                return { valid: true, formItems: this.formItems, fk_tables: this.fk_tables };
            } else {
                console.log('NOT VALID');
                this.$emit('requiredFieldsError')
                return { valid: false };
            }
        },
        getFormItems() {
            return this.formItems;
        },
    }
}
class ConfirmOptions {
    constructor({
        type = "confirm",
        text = "",
        btnText = "Confirm",
        subText = "",
        secBtnText = "",
        denyBtnText = "Cancel",
    }) {
        this.type = type;
        this.text = text;
        this.subText = subText;
        this.btnText = btnText; // YES OPTION
        this.secBtnText = secBtnText; // NO OPTION
        this.denyBtnText = denyBtnText; // Cancel option
    }
}