<template>
    <div id="checklist" class="pa-0 elevation-1">
        <v-card class="mx-auto" tile flat>
            <div class="card-title">CHECKLIST</div>
            <div
                v-for="(item, index) in checklist_items"
                :key="item.id"
                color="#fff"
                :value="item"
                @mousedown.prevent="itemActive(index, item)"
                @mouseup.prevent="
                    itemA = null;
                    clearAnim();
                    animDone = false;
                "
                v-ripple="false"
                :class="checked.includes(item) ? 'checked' : ''"
                class="checklist-item-wrapper"
                style="position: relative; height: 60px; width: 100%"
            >
                <div
                    v-if="checked.includes(item)"
                    :class="[itemA == index && !animDone ? 'deactivate' : '']"
                    class="longclick"
                ></div>
                <div
                    v-if="!checked.includes(item)"
                    :class="[itemA == index && !animDone ? 'activate' : '']"
                    class="longclick-not"
                ></div>
                <div class="checklist-item">{{ fixLabel(item) }}</div>
                <v-spacer></v-spacer>
                <v-checkbox
                    color="green"
                    v-if="checked.includes(item)"
					class="disablecheck"
                    v-model="isChecked"
                ></v-checkbox>
                <v-checkbox
                    color="green"
                    class="disablecheck"
                    v-if="!checked.includes(item)"
                ></v-checkbox>
            </div>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiObject from "@/models/ApiObject";
import Notice from "@/models/Notice";
export default {
    name: "Checklist",
    props: {
        checklist_items: Array,
        items: Object,
        view: String,
        viewidname: String,
    },
    data: () => ({
        checked: [],
        apiObj: null,
        noticeObj: null,
        itemA: null,
        longPressTimeOut: null,
        animDone: false,
        isChecked: true,
    }),
    async created() {
        console.log("checklist ##");
        console.log(this.checklist_items);
        console.log(this.items);
        await this.setChecked();
        this.apiObj = await this.$ObjectFactory.BuildObject({ endpoint: this.view });
        this.noticeObj = await Notice.Build();
        console.log(this.checked);
    },
    computed: {
        ...mapGetters({
            userInfo: "user/userInfo",
        }),
    },
    methods: {
        itemActive(index, item) {
            this.itemA = index;
            this.longPressTimeOut = setTimeout(() => {
                if (!this.checked.includes(item)) {
                    this.updateChecklist(item);
                    this.checked.push(item);
                    this.animDone = true;
                } else {
                    this.$delete(this.checked, this.checked.indexOf(item));
                    this.updateChecklist(item);
                    this.animDone = true;
                }
            }, 1000);
        },
        clearAnim() {
            clearTimeout(this.longPressTimeOut);
        },
        fixLabel(item, i) {
            return item.replace(/_/g, " ");
        },
        async setChecked() {
            if (
                Object.entries(this.items).length !== 0 &&
                this.checklist_items.length !== 0
            ) {
                for (let item of this.checklist_items) {
                    if (this.items[item] === "1") {
                        this.checked.push(item);
                    }
                }
            }
        },
        async updateChecklist(item) {
            this.items[item] = this.items[item] === "0" ? "1" : "0";

            console.log(this.items[item]);
            try {
                let response = await this.$PutService(this.view, {
                    [this.viewidname]: this.items[this.viewidname],
                    [item]: this.items[item],
                });
                console.log(response);
                if (this.items[item] === "0") {
                    let wallresponse = this.$wallHandler({
                        walltype: "checklist_task_not_done",
                        item: item
                            .replace(/_/g, " ")
                            .replace(/(^|\s)\S/g, (l) => l.toUpperCase()),
                        route: this.$route,
                    });
                } else {
                    // notices
                    await this.apiObj.setObjectData(this.items);
                    let uniqueUserIds = [
                        ...new Set(this.apiObj.getValuesInObjects("userid")),
                    ];
                    const ID = this.$route.params.id;
                    const ROUTE = this.view.replace("page", "s");
                    for (let userid of uniqueUserIds)
                        this.noticeObj.postNotice({
                            ownerid: userid,
                            type: `marked ${item} as done`,
                            creator: this.userInfo.userid,
                            object: this.items.title,
                            link: `${ROUTE}/edit/${ID}`,
                        });
                    let wallresponse = this.$wallHandler({
                        walltype: "checklist_task_done",
                        item: item
                            .replace(/_/g, " ")
                            .replace(/(^|\s)\S/g, (l) => l.toUpperCase()),
                        route: this.$route,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#checklist {
    border-radius: 3px;
    float: left;
    width: 100%;
    overflow: hidden;
}

.checklist-item-wrapper {
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.473);
    cursor: pointer;
	&:hover{
		background:rgb(243, 243, 243);
	}
    .checklist-item {
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 500;
        z-index: 1;
    }
}

.v-list {
    padding: 0px;
    .v-list-item {
        height: 60px;
        border-bottom: 1px solid $border-color;
        .v-list-item__content {
            z-index: 1;
        }
        &:last-child {
            border-bottom: none;
        }
        .v-list-item__title {
            text-transform: uppercase;
            margin-left: 10px;
            color: $font-color-pale;
            font-size: 0.9rem;
            font-weight: 500;
        }
    }
}
.v-list {
    &::v-deep .v-list-item--active .v-icon {
        color: #8ed136 !important;
    }
}

.activate {
    width: 100% !important;
    transition: all 1s ease;
    opacity: 1 !important;
}

.deactivate {
    width: 0% !important;
    transition: all 1s ease;
    opacity: 0.8 !important;
}

.longclick {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: #b4b4b45e !important;
    z-index: 0;
}
.longclick-not {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 0%;
    background: #b4b4b45e !important;
    z-index: 0;
    opacity: 0;
}

.disablecheck {
    pointer-events: none;
}
</style>