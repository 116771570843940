<template>
    <v-container class="wizard-budget-container container--fluid">
        <v-col class="pa-0 pb-5">
            <h3 class="wizard-section-title">Additional {{ header }}</h3>
        </v-col>

        <div class="wizard-form-text" v-for="(val, key) in stepData" :key="key">
            <div class="budget-section-wrapper elevation-1" v-if="key == 'Talent'">
                <div class="wizard-container-title">TALENT COST</div>
                <div
                    class="wizard-form-text"
                    v-for="item in stepData[key]"
                    :key="item.budget_rowid"
                >
                    <tour-gage-form :costItem="item" :ticketCapacity="ticketCapacity" />
                </div>
            </div>
            <div class="budget-section-wrapper elevation-1" v-if="key == 'Talent'">
				<budget-secret-form
					:isPrivate="isPrivate"
					:userGroups="accessGroups"
					:selectedGroup="selectedGroup"
					@privateChange="setPrivate"
					@groupChange="setGroup"
				/>
            </div>
            <div class="budget-section-wrapper elevation-1" v-if="key != 'Talent'">
                <div>
                    <!-- ENSEMBLE -->
                    <tour-two-cost-comp
                        :header="key"
                        :items="stepData[key]"
                        :colNames="colNames[key]"
                    />
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import TourGageForm from "./TourGageForm";
import BudgetSecretForm from "../../ConcertBudgetWizard/Step5/BudgetSecretForm";
import TourTwoCostComp from "./TourTwoCostComp";

export default {
    name: "toursteptwo",
    components: {
        TourGageForm,
        BudgetSecretForm,
        TourTwoCostComp,
    },
    props: {
        header: String,
        stepData: Array,
        privateData: Object,
        accessGroups: Array,
        ticketCapacity: Number,
    },
    data: () => ({
        isLoading: true,
        colNames: {
            Ensemble: ["Type"],
            Crew: ["Type"],
        },
        gageColNames: ["Type", "Value", "Cost"],
        isPrivate: false,
        selectedGroup: "",
    }),
    created() {
        console.log(this.stepData);
        this.isPrivate = this.privateData.isPrivate;
        this.selectedGroup = this.privateData.accessGroup;

        const COLCOST = [
            "Number of shows",
            "Cost per person",
            "Number of people",
            "Calculated costs",
        ];
        this.colNames.Ensemble = this.colNames.Ensemble.concat(COLCOST);
        this.colNames.Crew = this.colNames.Crew.concat(COLCOST);
    },
    methods: {
        setPrivate(val) {
            this.emitUpdate({ isPrivate: val });
        },
        setGroup(val) {
            this.emitUpdate({ accessGroup: val });
        },
        emitUpdate(item) {
            this.$bus.$emit("tourBudgetUpdatedData", item);
        },
    },
};
</script>

<style>
</style>