<template>
	<div class="greeting">
		<div class="timeofday">{{ greeting }}</div>
		<div class="username">{{ userInfo.fullname }}!</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex'

   export default {
		name:'greetingcard',
		data:()=>({
			greeting:''
		}),
		created(){
			// this.username = JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.name.split(' ')[0]
			this.getTimeOFDay()
		},
		methods:{
			getTimeOFDay(){
				var time = new Date().getHours();
				if (time < 10) {
					this.greeting = "Good morning,";
				} else if (time < 20) {
					this.greeting = "Good day,";
				} else {
					this.greeting = "Good evening,";
				}
			}
		}, 
		computed:{
			...mapGetters({
				userInfo:'user/userInfo',
			})
		}
   }
</script>

<style lang="scss" scoped>
	.greeting{
		border-bottom:1px solid #cccbcb;
		padding-bottom:20px;
		font-size:25px;
		float:left;
		width:100%;
		.timeofday{
			font-weight:300;
			color:#6a7a8c;
			float:left;
		}
		.username{
			color:#666;
			text-transform:capitalize;
			float:left;
			font-weight:500;
			margin-left:10px;
		}
	}
</style>