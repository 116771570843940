<template>
   <v-dialog v-model="dialog" persistent width="800">

      <v-card>
         <v-card-title primary-title>
            Confirm that you wish to permanently delete
         </v-card-title>

         <v-card-text>
            <v-text-field v-if="simple" readonly :v-model="(ticketItem.section_name == undefined) ? name : ticketItem.section_name"/>
            <ticket-revenue-form v-if="!simple" :ticketItem="ticketItem"></ticket-revenue-form>
         </v-card-text>

         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDialog">Cancel</v-btn>
            <v-btn color="error" @click="removeTicketRevenue">Delete</v-btn>
         </v-card-actions>
      </v-card>

   </v-dialog>
</template>

<script>
import TicketRevenueForm from '@/components/VenueBudgetTemplate/TicketRevenueForm'

export default {
   props: {
      ticketItem: Object,
      dialog: Boolean,
      simple: Boolean,
      name: String
   },

   components: {
      TicketRevenueForm,
   },

   created() {
      console.log(this.ticketItem)
   },

   data: () => ({
   }),

   methods:{

      removeTicketRevenue(){
			this.$emit('deleteTicketFromDialog', this.ticketItem)
      },

      cancelDialog(){
         this.$emit('cancelTicketDialog')
      }
   }
}
</script>

<style>

</style>