<template>
    <div id="home" v-if="dataIsReady">
        <Menu :view="this.$route.name"></Menu>
        <v-main>
            <v-container class="home-container" fluid fill-height>
                <v-layout justify-center>
                    <router-view :key="$route.fullPath"></router-view>
                </v-layout>
            </v-container>
        </v-main>
    </div>
</template>
<script>
// @ is an alias to /src
import Menu from "../components/Menu/";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "home",
    components: {
        Menu,
    },
    data: () => ({
        dataIsReady: false,
    }),
    async created() {
        this.$bus.$on("setHistoryView", this.setHistory);
        // this.setupSocket();
        await this.getInit();
        await this.getUserSelection();
        this.setInitialized(true);
        this.dataIsReady = true;
        /**
         * This particular function is mutationfunction from the vuex store.
         * It sets the "view" into the globalstate module whenever the user has logged in or refreshed the page.
         * This is done to keep track of which view is currently being seen so the correct data is fetched.
         */
        if (this.$route.path != "/") this.setView(this.$getRoute(this.$route.path).main_route);
    },
    watch: {
        "$route.path": {
            handler(view) {
                /**
                 * View is also set whenver a user change route.
                 */
                if (view != "/") {
                    this.setView(this.$getRoute(view).main_route);
                }
            },
        },
    },
    methods: {
        ...mapMutations({
            setView: "globalstates/setView",
        }),
        async getInit() {
            const response = await this.getData("init");
            this.checkForMiniVariantSetting();

            /**
             * This sets the correct meta data in the vuexstore to be accessed globally.
             */
            const metaData = response.reduce((metaData, item) => {
                if (item.user) {
                    const [userInfo] = item.user;
                    this.setUserInfo(userInfo);
                    console.log(this.userInfo);
                } else {
                    metaData[item.endpoint] = item.meta;
                    metaData[item.endpoint]["pk_field"] = item.pk_field;
                }
                return metaData;
            }, {});

            this.setMetaData(metaData);

            /**
             * Collect country information and add it to the store
             */
            const country_list = await this.getData("countries");
            this.setCountryList(country_list);
        },
        checkForMiniVariantSetting() {
            if (localStorage.getItem("miniVariant") || localStorage.getItem("miniVariant") == undefined) this.setExpandedHeader();
            else {
                this.unsetExpandedHeader();
            }
        },
        setupSocket() {
            console.log("setup");
            const io = require("socket.io-client");

            const socket = io("http://localhost:3000");

            socket.on("update", () => {
                console.log("new notification");
            });
        },
        setHistory(history) {
            this.setVisited(history);
        },
        async getUserSelection() {
            console.log("USER SELECTIOSN");
            console.log(this.userSelections);

            if (this.isInitialized) return;

            console.log("GETTING NEW USER SELECTION FROM DB");

            const selections = await this.getData(this.$getRoute("user_selections").main_route);
            if (selections) this.setUserSelection(selections);
        },
        async getData(route) {
            try {
                let response = await this.$GetService(route);
                if (response.status === 200) {
                    return response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        ...mapActions({
            setUserInfo: "user/setUserInfo",
            setUserSelection: "user/setUserSelections",
            setVisited: "user/setVisited",
            setInitialized: "user/setInitialized",
            setMetaData: "metadata/setMetaData",
            setCountryList: "metadata/setCountryList",
            setConcertsMetaFields: "metadata/setConcertsMetaFields",
            setArtistsMetaFields: "metadata/setArtistsMetaFields",
            setTicketsMetaFields: "metadata/setTicketsMetaFields",
            setTicketSalesMetaFields: "metadata/setTicketSalesMetaFields",
            setToursMetaFields: "metadata/setToursMetaFields",
            setExpandedHeader: "globalstates/setExpandedHeader",
            unsetExpandedHeader: "globalstates/unsetExpandedHeader",
        }),
    },
    computed: {
        ...mapGetters({
            userSelections: "user/userSelections",
            userInfo: "user/userInfo",
            isInitialized: "user/isInitialized",
            view: "globalstates/view",
        }),
    },
};
</script>
<style lang="scss">
#home {
    width: 100%;
    height: 100%;
    float: left;
    background: #11283e08;
}

.row {
    margin-left: -12px !important;
    margin-right: -12px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.home-container {
    padding: 0px;
    float: left;
}

.page-container {
    width: 100%;
    display: flex;
}

#organizations,
#concerts,
#tours,
#artists,
#venues,
#people,
#organizations,
#ticketing,
#comparableprojects,
#invoices,
#help,
#sales {
    width: 100%;
    padding: 10px;
    height: 100%;
}

#personalinsights {
    width: 100%;
    padding: 0px;
    height: 100%;
}

#tourpage,
#concertpage,
#artistpage,
#salespage,
#comparable-projects-wrapper,
#pi-page {
    float: left;
    width: 100%;
    padding: 70px 15px 15px 15px;
    position: relative;
}

.mobile {
    #tourpage,
    #concertpage,
    #artistpage,
    #comparable-projects-wrapper,
    #pi-page {
        padding: 95px 0px 15px 0px !important;
    }
}

.mobile #comparable-projects-wrapper {
    padding: 120px 10px 10px 10px !important;
}
.mobile #pi-page {
    padding: 110px 10px 10px 10px !important;
}

#landingpage {
    float: left;
    padding: 15px;
    width: 100%;
}

.card-title {
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
    text-align: center;
    color: $font-color-pale;
    padding: 9px 0px;
    border-bottom: 1px solid $border-color;
}

// RESPONSIVE

#home {
    .v-main {
        transition: none !important;
        padding-top: 90px !important;

        height: 100%;
    }
}

.mobile #home .v-main {
    padding-top: 50px !important;
}

.mobile #home.v-main .home-container {
    padding: 0px !important;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}
.slide-fade-leave-active {
    transition: all 0.3s ease-out;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

span {
    font-family: "roboto" !important;
}

@media only screen and (max-width: 1256px) {
}
</style>
