<template>
    <div id="help">
        <HelpPage />
    </div>
</template>

<script>
import HelpPage from "@/components/HelpPage";
export default {
    components: {
        HelpPage,
    },
};
</script>

<style scoped>
</style>