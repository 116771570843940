import TabRow from "./TabRow";
import TabRowFile from "./TabRowFile";

export default class TabTable {
    constructor(tabTitle, rows, includedHeaders, excludedHeaders, type = '') {
        // ex: Word
        this.title = tabTitle;
        this.headers = [];
        this.rows = [];
        this.type = type;
        this.generateTabRows(rows);
        for (const KEY in rows[0]) {

            if (includedHeaders.indexOf(KEY) != -1)
                this.headers.push(KEY);
            else if (excludedHeaders.indexOf(KEY) == -1 && includedHeaders.length == 0)
                this.headers.push(KEY);

        }
    }

    generateTabRows(rows) {
        for (const ITEM of rows) {
            if (this.type == 'file')
                this.rows.push(new TabRowFile(ITEM));
            else
                this.rows.push(new TabRow(ITEM))

        }
    }
    deleteRow(i) {
        this.rows.splice(i, 1)
    }
}