<template>
   <span>
      <v-btn
         v-if="loaded"
         icon 
         color="grey"
         @click.stop="commentDialog = true"
      >
         <v-icon color="$accent-color" v-if="data.comment.length === 0">mdi-comment-plus-outline</v-icon>
         <v-icon color="primary" v-if="data.comment.length !== 0">mdi-comment-text</v-icon>  
      </v-btn>

      <comment-dialog
         :dialog="commentDialog"
         :name="name"
         :comment="data.comment"
         @saveComment="saveComment"
         @cancelComment="commentDialog = false"
      />
   </span>
</template>

<script>

/**
 * To import and make this component work you need to give it a valid data object + a valid route as props.
 * The component should be imported in the following way (example is shown with a ticket item): 
      <comment-button
         :name="ticket.section_name"                                                      // name is not required
         :data="{ comment: ticket.comment, budget_ticketid: ticket.budget_ticketid }"    
         :route="this.$getSubRoute('budgets','tickets')"                                  
      />
 */

import CommentDialog from '@/components/Dialogs/Comment/CommentDialog'
export default {
   name: 'CommentButton',

   components: {
      CommentDialog
   },

   props: {
      name: String,
      data: Object,
      route: String,
      item: Object,
   },

   data: () => ({
      commentDialog: false,
      loaded: false,
   }),
   
   created(){     
      if(!this.name)
            this.name = ''
            
      if(!this.data.comment)
         this.data.comment = '';

      this.loaded = true
   },

   methods: {
      async saveComment(comment){
         const temp = JSON.parse(JSON.stringify(this.data));
         temp.comment = comment
         // this.data.comment = comment
         await this.putComment(temp, this.route)
         this.commentDialog = false
      },

      async putComment(item, route){
         try{
            let response = await this.$PutService(route, item)
            if(response.status == 200){
               console.log('successful post of comment: ')
               this.$emit('setComment', item.comment)
            }
         } catch(error){
            console.log(error)
         }
      },
   }

}
</script>

<style>

</style>