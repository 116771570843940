<template>
    <v-dialog v-model="showstatusChanger" v-if="showstatusChanger" persistent max-width="400" @keydown.esc="$emit('closestatusChangerDialog')">
        <v-card>
            <v-card-title class="header"> {{ concertSelected.title }} <span>{{ concertSelected.date }}</span></v-card-title>
            <v-col cols="12" md="12" class="px-5 py-3">
                <v-autocomplete v-model="selectedStatus" :items="statusList" label="Concert Status" hide-details outlined dense filled />
            </v-col>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" small text @click="$emit('closestatusChangerDialog')">Close</v-btn>
                <v-btn color="primary" small depressed @click="saveConcertStatus(concertSelected.concertid, selectedStatus)">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        concertSelected: Object,
        showstatusChanger: Boolean,
        statusList: Array,
    },

    data: () => ({
        selectedStatus: null,
    }),

    created() {
        this.selectedStatus = this.concertSelected.status;
    },

    methods: {
        async saveConcertStatus(id, status) {
            const resp = await this.$PutService(this.$getRoute("concerts").main_route, { concertid: id, status: status });
            this.$bus.$emit("salesPageStatusUpdated", id, status);
            this.$emit("closestatusChangerDialog");
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    font-size: 16px !important;
    span{
        font-size:13px!important;
        color:$font-color-pale;
        margin-left:10px;
    }
}
</style>
