<template>
    <div class="page-container">
        <Loader :loaderStyle="'linear'" v-if="!loadingDone" />
        <overlay-card :show="showBudgetCreateOverlay" :text="budgetCreateOverlayText" :isLoading="budgetCreateOverlayLoading" />
        <div class="fadein-container" :class="loadingDone ? 'fade-in' : ''">
            <PageHeader
                v-if="loadingDone"
                enableOtherActions="true"
                :items="items"
                :status="status"
                :defaultDates="defaultDates"
                :datePicker="datePicker"
                :datesCollected="datesCollected"
                :view="view"
                :budgetid="budgetid != '' ? budgetid : null"
                :budgetStatus="budgetData ? budgetData.status : null"
                :newDataLoading="newDataLoading"
                @createNewBudget="createBudget()"
                @setStartDate="(date) => (this.datePicker.startDate.date = date)"
                @setEndDate="(date) => (this.datePicker.endDate.date = date)"
                @getSalesData="getSalesData"
            />
            <div id="tourpage" v-if="loadingDone">
                <v-container class="container--fluid pb-0">
                    <ProgressionCards :dataObject="progressionCards.dataObjects" />
                </v-container>
                <v-container class="container--fluid pt-0">
                    <v-row>
                        <v-col cols="12" md="8" class="salesprogresscard-container">
                            <SalesProgressCard
                                :grossBars="grossBars"
                                :ticketBars="ticketBars"
                                :donutData="donutData"
                                :dataFound="ticketDataFound"
                                :isLoading="salesProgressIsLoading"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <TodoList />
                        </v-col>
                    </v-row>
                </v-container>
                <v-container class="float-left container--fluid pa-3 mb-2">
                    <v-col cols="12" md="12" class="float-left pa-0 elevation-1 graph-container">
                        <div class="card-title">Concert KPI</div>
                        <v-col cols="12" md="12" class="float-left pa-0">
                            <kpi-table :items="kpiData" :averages="kpiAverages" />
                        </v-col>
                    </v-col>
                </v-container>
                <v-container class="float-left pa-3 container--fluid">
                    <v-col cols="12" md="12" class="float-left pa-0 elevation-1 graph-container">
                        <div class="card-title">Statistics</div>
                        <v-col cols="12" md="12" class="float-left py-8">
                            <echart-card v-if="statsGraphOptions.series.length > 0" style="height: 400px" :options="statsGraphOptions" />
                            <NoDataPlaceholder :text="'No data for graph'" v-else />
                        </v-col>
                    </v-col>
                </v-container>
                <v-container class="float-left pa-3 mb-2 container--fluid">
                    <v-row>
                        <v-col cols="12" md="6">
                            <project-ranking-card
                                :title="'Top 5 projects'"
                                :ticketBars="topConcertsTickets"
                                :grossBars="topConcertsGross"
                                :predBars="topConcertsPred"
                                :sortType="'desc'"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <project-ranking-card
                                :title="'Bottom 5 projects'"
                                :ticketBars="bottomConcertsTickets"
                                :grossBars="bottomConcertsGross"
                                :predBars="bottomConcertsPred"
                                :sortType="'asc'"
                            />
                        </v-col>
                    </v-row>
                </v-container>
                <v-container class="container--fluid float-left pa-3 mb-2">
                    <TabBrowser v-if="loadingDone" :items="tabItems" :tabnames="tabNames" />
                </v-container>
                <v-container class="container--fluid float-left pa-3 mb-2">
                    <Calendar v-if="Object.keys(items).length" :items="items.concerts" />
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
import { initEchart, generateSerie } from "@/components/Cards/ChartCards/EchartOptions.js";
import { mapActions, mapGetters } from "vuex";
import { getStepArray } from "./tour_budget_template_rows.js";
import PageHeader from "@/components/Menu/PageHeader/";
import SalesProgressCard from "@/components/Cards/SalesProgressCard/";
import Loader from "@/components/Loader";
import DatePicker from "@/components/DatePicker";
import TodoList from "@/components/TodoList";
import ProgressionCards from "@/components/Cards/ProgressionCards/";
import Checklist from "@/components/Checklist/";
import Calendar from "@/components/Calendar";
import TabBrowser from "@/components/Tables/TabBrowser/";
import EchartCard from "@/components/Cards/ChartCards/EchartCard";
import ProjectRankingCard from "@/components/Cards/ProjectRankingCard.vue";
import KpiTable from "./KpiTable";
import OverlayCard from "@/components/Cards/OverlayCard.vue";
import NoDataPlaceholder from "@/components/NoDataPlaceholder";
import ObjectFactory from "@/models/ObjectFactory.js";

export default {
    name: "tourpage",
    components: {
        Loader,
        DatePicker,
        ProgressionCards,
        Checklist,
        Calendar,
        EchartCard,
        TabBrowser,
        ProjectRankingCard,
        KpiTable,
        TodoList,
        SalesProgressCard,
        PageHeader,
        OverlayCard,
        NoDataPlaceholder,
    },
    data: () => ({
        loadingDone: false,
        stretchHeader: false,
        budgetid: "",
        budgetData: {},
        items: [],
        datesCollected: false,
        checklist_items: ["all_information_ok", "eos", "contract_ok", "master_tour_connections", "police_authorization", "assurance", "show_file"],
        status: "",
        defaultDates: {
            startDate: "",
            endDate: "",
        },
        datePicker: {
            startDate: {
                date: "",
            },
            endDate: {
                date: "",
            },
        },
        kpiSoldPercentages: [],
        progressionCards: {
            dataObjects: {
                gigs: {
                    title: "Gigs",
                    number: 0,
                    subtext: "Shows",
                    percentage: 0,
                    progresstext: "0 of goal",
                },
                total_gage: {
                    title: "Total gage",
                    number: 0,
                    subtext: "currency",
                    percentage: 0,
                    progresstext: "",
                    redOverLimit: true,
                },
                turn_over: {
                    title: "Turnover",
                    number: 0,
                    subtext: "currency",
                    percentage: 0,
                    progresstext: "0 of goal",
                    isLoading: false,
                },
                net_profit: {
                    title: "net profit",
                    number: 0,
                    subtext: "currency",
                    percentage: 0,
                    progresstext: "0 of goal",
                },
                risk: {
                    title: "risk",
                    number: 0,
                    subtext: "currency",
                    percentage: 0,
                    progresstext: "",
                },
                break_even: {
                    title: "break even avg",
                    number: 0,
                    subtext: "%",
                    percentage: 0,
                    progresstext: "",
                },
                sold_tickets: {
                    title: "sold tickets",
                    number: 0,
                    subtext: "Tickets",
                    percentage: 0,
                    progresstext: "0 of total",
                },
                cost_per_ticket_average: {
                    title: "Cost per ticket avg",
                    number: 0,
                    subtext: "currency",
                    percentage: 0,
                    progresstext: "0 of avg ticket price",
                    redOverLimit: true,
                },
            },
            budgetCreateOverlayText: "",
            showBudgetCreateOverlay: false,
            budgetCreateOverlayLoading: false,
        },

        tabNames: ["concerts", "organizations", "people"],
        tabItems: {
            concerts: [],
            organizations: [],
            people: [],
            documents: [],
        },

        kpiData: [],
        kpiAverages: {},

        statsGraphOptions: { series: [] },
        ticketSaleData: [],
        analyticsData: [],

        topConcertsTickets: [],
        topConcertsGross: [],
        topConcertsPred: [],
        bottomConcertsTickets: [],
        bottomConcertsGross: [],
        bottomConcertsPred: [],
        ticketBars: [],
        grossBars: [],
        donutData: {
            ticket_sold_percentage: 0,
            tickets_sold: 0,
            tickets_available: 0,
            gross_sales_percentage: 0,
            gross_sales: 0,
            gross_available: 0,
        },
        newDataLoading: false,
        salesProgressIsLoading: false,
    }),
    computed: {
        ...mapGetters({
            view: "globalstates/view",
            meta_data: "metadata/meta_data",
        }),
    },
    async created() {
        this.$bus.$on("updateTourDataFromSlider", this.updateDataFromSlider);
        await this.init();
        this.loadingDone = true;
    },
    methods: {
        async init() {
            // GET TOURDATA
            this.items = await this.getData(this.$getEditRoute(this.$route.path));
            let concertObjects = [];
            if (this.items.concerts?.length > 0) {
                for (let concert of this.items.concerts) {
                    concertObjects.push(await this.$ObjectFactory.BuildConcert({ concert: concert }));
                }

                this.items.concerts = concertObjects;
            }

            // FILTER OUT ALL NON CONFIRMED CONCERTS
            if (this.items.concerts?.length > 0)
                this.items.concerts = this.items.concerts.filter((concert) => concert?.status?.toLowerCase() === "confirmed");

            // IF NO CONCERTS, DO NO INITIALIZATION OF DATA
            if (this.items.concerts?.length > 0) {
                await this.getDefaultDates();
                await this.getSalesData(true);
                await this.initOverConcerts();
                await this.getTicketBarsAndGrossBars();
                await this.checkIfSale();
                await this.checkIfData();
                console.log("init done");
            }

            // SET HISTORY VIEW
            this.$bus.$emit("setHistoryView", {
                display: this.items.name,
                route: this.$route.path,
            });
            for (const KEY in this.progressionCards.dataObjects)
                if (this.progressionCards.dataObjects[KEY].subtext == "currency")
                    this.progressionCards.dataObjects[KEY].subtext = this.$config.CURRENCY;

            this.newDataLoading = false;
            this.unSetDrawersDisabled();
        },
        async getSalesData(loadingIsToggled = false) {
            this.newDataLoading = true;
            await this.getProgressionCardsData();

            this.salesProgressIsLoading = true;
            await this.getSalesProgressCardData(this.datePicker.startDate.date, this.datePicker.endDate.date);
            this.salesProgressIsLoading = false;

            await this.initGraph();
            this.newDataLoading = false;
        },
        getSalesProgressCardData(startDate, endDate) {
            // ASSIGN CONSTANTS FOR READABILITY
            const DONUT_DATA = this.donutData;
            const P_CARD = this.progressionCards.dataObjects.sold_tickets;

            // ASSIGN DONUT DATA WITH DATA FROM PROGRESSIONCARD
            DONUT_DATA.tickets_sold = P_CARD.number;
            DONUT_DATA.ticket_sold_percentage = P_CARD.percentage;
            DONUT_DATA.gross_sales_percentage = P_CARD.percentage;

            // ASSIGN GROSS SALES WITH DATA CALCULATED IN FUNCTION 'getSoldTickets'
            DONUT_DATA.gross_sales = this.items.gross_sales;
        },
        checkIfData() {
            if (this.ticketBars.length > 0) {
                this.ticketDataFound = true;
                this.loader = false;
            } else {
                this.ticketDataFound = false;
                this.loader = false;
            }
        },
        getTicketBarsAndGrossBars(startDate, endDate) {
            if (!this.items.concerts) return;

            let ticketBars = [];
            let grossBars = [];

            this.items.concerts.forEach((concert) => {
                ticketBars.push({
                    title: concert.title,
                    percentage: Math.round((concert.sold_tickets / concert.capacity) * 100),
                    total: `${concert.sold_tickets} out of ${concert.capacity}`,
                });
                grossBars.push({
                    title: concert.title,
                    percentage: Math.round((concert.gross_sales / concert.potential_revenue) * 100),
                    total: `${concert.gross_sales} out of ${concert.potential_revenue}`,
                });
            });
            this.ticketBars = ticketBars;
            this.grossBars = grossBars;
        },
        async getDefaultDates() {
            if (this.items.concerts.length === 0 || !this.items.concerts) return;

            // ASSIGN DEFAULT
            this.defaultDates.startDate = this.items.startdate;
            this.defaultDates.endDate = this.items.enddate;

            // datepicker is not set by pageheader?
            this.datePicker.startDate.date = this.items.startdate;
            this.datePicker.endDate.date = this.items.enddate;

            // RENDER DATEPICKER
            this.datesCollected = true;
        },
        checkIfSale() {
            if (!this.items.concerts) return;

            // GET ONSALE DATES FOR ALL CONCERTS
            const ON_SALE_DATES = this.items.concerts
                .reduce((a, b) => {
                    b.on_sale ? a.push(b.on_sale) : null;
                    return a;
                }, [])
                .map((date) => new Date(date));

            // GET AND ASSIGN EARLIEST ONSALE DATE FROM ON_SALE_DATES ARRAY
            const MIN_ONSALE_DATE = new Date(Math.min(...ON_SALE_DATES));

            // ASSIGN TODAY
            const TODAY = new Date();

            // DESTRUCTURE OBJECT FOR READABILITY
            const { startdate, enddate } = this.items;

            // HELPER FUNCTION FOR READABILITY
            const date = (date) => new Date(date);

            // SET STATUS FOR TOUR BASED ON DATE
            if (TODAY > date(enddate)) {
                this.status = "completed";
            } else if (TODAY >= date(startdate) && TODAY < date(enddate)) {
                this.status = "ongoing";
            } else if (TODAY >= MIN_ONSALE_DATE && TODAY < date(enddate)) {
                this.status = "onsale";
            } else {
                this.status = "pending";
            }
        },
        getProgressionCardsData() {
            if (!this.items.concerts) return;

            this.ticketSaleData = [];
            this.analyticsData = [];

            const CONCERTS = this.items.concerts;
            const P_CARD = this.progressionCards.dataObjects;

            P_CARD.gigs.number = CONCERTS.length;

            /**
             *  Only to do calc if this variable is set.
             */
            if (this.goal_number_of_gig) {
                P_CARD.gigs.percentage = Math.round((CONCERTS.length / +this.items.goal_number_of_gig) * 100);
                P_CARD.gigs.progresstext = `${P_CARD.gigs.percentage != Infinity ? P_CARD.gigs.percentage : 0}% of goal`;
            } else {
                P_CARD.gigs.percentage = 0;
                P_CARD.gigs.progresstext = `No goal set`;
            }

            // SOLD TICKETS
            P_CARD.sold_tickets.number = this.getSoldTickets(CONCERTS);
            P_CARD.sold_tickets.percentage = Math.round((P_CARD.sold_tickets.number / this.getAvailableTickets(CONCERTS)) * 100);
            P_CARD.sold_tickets.progresstext = `${P_CARD.sold_tickets.percentage}% of total`;
            // COST PER TICKET AVG

            // find current tour budget
            this.budgetData = null;
            if (this.items.budgets) {
                let status = Math.max.apply(
                    Math,
                    this.items.budgets.map((budget) => {
                        return budget.status;
                    })
                );
                this.budgetData = this.items.budgets.find((budget) => {
                    return budget.status == status;
                });
            }
            this.$logger("this.budgetData", this.budgetData);
            // tour values

            if (this.budgetData) {
                this.budgetData.calced_total_cost = 0;
                this.budgetData.calced_total_cost =
                    +this.budgetData.general_costs +
                    +this.budgetData.production_costs +
                    +this.budgetData.talent_costs +
                    +this.budgetData.variable_costs +
                    +this.budgetData.marketing_costs;
            }

            this.items.total_costs = +this.items.total_costs + (this.budgetData ? +this.budgetData.total_costs : 0);

            let tmpTicketDivider = P_CARD.sold_tickets.number > 0 ? +P_CARD.sold_tickets.number : 1;
            P_CARD.cost_per_ticket_average.number = Math.round(this.items.total_costs / tmpTicketDivider);

            P_CARD.cost_per_ticket_average.percentage = Math.round((P_CARD.cost_per_ticket_average.number / this.getTourAverageTicketPrice()) * 100);
            (P_CARD.cost_per_ticket_average.progresstext = `${P_CARD.cost_per_ticket_average.percentage}% of avg ticket price`),
                // TURNOVER
                (P_CARD.turn_over.number = this.items.gross_sales);
            P_CARD.turn_over.percentage = Math.round((this.items.gross_sales / this.items.potentialRevenues) * 100);
            P_CARD.turn_over.progresstext = `${P_CARD.turn_over.percentage}% of goal`;

            //  NETPROFIT
            if (this.budgetData) this.budgetData.net_profit = +this.budgetData.revenues - +this.budgetData.total_costs; // tour marketing missing

            P_CARD.net_profit.number = this.items.gross_sales - this.items.total_costs;

            console.log(P_CARD.net_profit.percentage);

            P_CARD.net_profit.percentage = this.budgetData ? Math.round((P_CARD.net_profit.number / this.budgetData.net_profit) * 100) : 100;
            P_CARD.net_profit.progresstext = `${isFinite(P_CARD.net_profit.percentage) ? P_CARD.net_profit.percentage : 0} % of budget`;
        },
        getSoldTickets(CONCERTS) {
            this.items.total_costs = 0;
            this.items.gross_sales = 0;
            this.items.potentialRevenues = 0;

            return (
                CONCERTS.reduce(
                    (amount, concert) => {
                        if (concert.budgets) {
                            let status = Math.max.apply(
                                Math,
                                concert.budgets.map((budget) => {
                                    return budget.status;
                                })
                            );
                            let budget = concert.budgets.find((budget) => {
                                return budget.status == status;
                            });

                            // replace budget marketing with dynamic marketing cost from selected dates
                            //this.items.total_costs += +budget.total_costs + +(this.getConcertMarketingCostSync(concert, true))
                            this.items.total_costs +=
                                +budget.variable_costs +
                                +budget.production_costs +
                                +budget.talent_costs +
                                +budget.general_costs +
                                +this.getConcertMarketingCostSync(concert, true);
                        } else {
                            this.items.total_costs += concert.gage_talent ? +concert.gage_talent : 0;
                        }

                        // for tab browser
                        concert.sold_tickets = 0;
                        concert.total_sold_tickets = 0;
                        concert.total_gross_sales = 0;
                        concert.gross_sales = 0;
                        concert.potential_revenue = 0;
                        if (concert.tickets) {
                            this.ticketSaleData = concert.getConcertSalesGraphData(
                                this.datePicker.startDate.date,
                                this.datePicker.endDate.date,
                                this.ticketSaleData
                            );
                            concert.tickets.forEach((ticket) => {
                                concert.potential_revenue += ticket.available * ticket.price;
                                this.items.potentialRevenues += +ticket.available * +ticket.price;

                                if (ticket.ticket_sales && ticket.price > 0) {
                                    ticket.ticket_sales.forEach((sale) => {
                                        if (this.isTicketSaleInRange(sale, "date")) {
                                            amount.push(+sale.amount);
                                            concert.sold_tickets += +sale.amount;
                                            concert.gross_sales += sale.amount * ticket.price;
                                            this.items.gross_sales += +sale.amount * +ticket.price;
                                            // this.initDataForGraph(
                                            //     sale,
                                            //     "date",
                                            //     "amount",
                                            //     this.ticketSaleData
                                            // );
                                            this.getTicketBarsAndGrossBars();
                                        } else amount.push(0);

                                        // for tab browser
                                        concert.total_sold_tickets += +sale.amount;
                                        concert.total_gross_sales += +sale.amount * +ticket.price;
                                    });
                                }
                            });
                        }
                        console.log(concert.sold_tickets);

                        return amount || [0];
                    },
                    [0]
                ).reduce((a, b) => a + b) || 0
            );
            return 0;
        },
        getAverageTicketPrice(CONCERTS) {
            const PRICES = CONCERTS.reduce((amount, concert) => {
                if (concert.tickets) {
                    concert.tickets.forEach((ticket) => {
                        amount.push(+ticket.price);
                    });
                }
                return amount || 0;
            }, []);

            return Math.round(PRICES.reduce((a, b) => a + b) / PRICES.length) || 0;
        },

        getAvailableTickets(CONCERTS) {
            return (
                CONCERTS.reduce(
                    (amount, concert) => {
                        // for tab browser
                        concert.capacity = 0;

                        if (concert.tickets) {
                            concert.tickets.forEach((ticket) => {
                                amount.push(+ticket.available);

                                // for tab browser
                                concert.capacity += +ticket.available;
                            });
                        }
                        return amount || 0;
                    },
                    [0]
                ).reduce((a, b) => a + b) || 0
            );
        },

        async getConcertAverageTicketPrice(concert) {
            if (!concert.tickets) return 0;
            const avgPrice = concert.tickets.reduce((price, ticket, index, arr) => {
                price += +ticket.price;
                return index == arr.length - 1 ? price / arr.length : price;
            }, 0);
            return avgPrice;
        },
        getTourAverageTicketPrice() {
            if (!this.items.concerts) return;

            let avg = 0;
            this.items.concerts.map((concert) => {
                if (concert.tickets) {
                    const avgPrice = concert.tickets.reduce((price, ticket, index, arr) => {
                        price += +ticket.price;
                        return index == arr.length - 1 ? price / arr.length : price;
                    }, 0);

                    avg += avgPrice;
                }
            });
            return Math.round(avg / this.items.concerts.length);
        },
        async getConcertMarketingCost(concert) {
            let marketingCost = 0;
            let date = new Date();
            if (concert.marketing) {
                concert.marketing.map((m) => {
                    if (m.marketing_data) {
                        m.marketing_data.map((data) => {
                            //if(new Date(data.startdate) <= date){
                            marketingCost += +data.amount;
                            //}
                        });
                    }
                });
            }
            return marketingCost;
        },
        getConcertMarketingCostSync(concert, date = false) {
            let marketingCost = 0;
            if (concert.marketing) {
                concert.marketing.map((m) => {
                    if (m.marketing_data) {
                        m.marketing_data.map((data) => {
                            if (date) {
                                if (
                                    new Date(data.startdate) >= new Date(this.datePicker.startDate.date) &&
                                    new Date(data.enddate <= new Date(this.datePicker.endDate.date))
                                ) {
                                    marketingCost += +data.amount;
                                }
                            } else marketingCost += +data.amount;
                        });
                    }
                });
            }
            return marketingCost;
        },
        initAnalyticsDataForGraph(data) {
            if (data) {
                data.map((datapoint) => {
                    let existingData = this.analyticsData.find((analytic) => {
                        return analytic[0] == datapoint.date;
                    });

                    if (existingData) existingData[1] += +datapoint.visitors;
                    else {
                        this.analyticsData.push([datapoint.date, +datapoint.visitors]);
                    }
                });
            }
        },
        initGraph() {
            if (this.ticketSaleData.length == 0) return;

            this.ticketSaleData.sort((a, b) => new Date(a[0]) - new Date(b[0]));
            this.setMissingDates(this.ticketSaleData[0][0], this.ticketSaleData[this.ticketSaleData.length - 1][0], this.ticketSaleData);
            this.ticketSaleData.sort((a, b) => new Date(a[0]) - new Date(b[0]));

            // analytics
            this.items.concerts.map((concert) => {
                this.initAnalyticsDataForGraph(concert.analytics);
            });
            this.analyticsData.sort((a, b) => new Date(a[0]) - new Date(b[0]));

            // collect x axis
            let selectedDateRange = this.getDateRangeArray(new Date(this.datePicker.startDate.date), new Date(this.datePicker.endDate.date));

            this.statsGraphOptions = initEchart({
                xAxisData: selectedDateRange,
                title: "Statistics",
                yAxisLTitle: "Tickets",
                yAxisRTitle: "Visitors",
            });
            if (this.ticketSaleData.length > 0)
                this.statsGraphOptions.series.push(
                    generateSerie({
                        data: this.ticketSaleData,
                        name: "Sold",
                        smooth: false,
                    })
                );

            if (this.analyticsData.length > 0)
                this.statsGraphOptions.series.push(
                    generateSerie({
                        data: this.analyticsData,
                        name: "Google analytics",
                        yAxis: 1,
                        smooth: false,
                    })
                );
        },
        setMissingDates(fromdate, enddate, salesArray) {
            //Convert dates from ISOStrings to date objects, set time to 12:00:00 to avoid daylight savings issues
            var date = new Date(Date.parse(fromdate + " 12:00:00"));
            var todate = new Date(Date.parse(enddate + " 12:00:00"));
            var isodate = "";
            var dateArray = [];

            //Fill dateArray with existing dates
            for (let index = 0; index < salesArray.length; ++index) dateArray.push(salesArray[index][0]);

            do {
                //convert date to ISOString
                isodate = date.toISOString().substring(0, 10);
                //If date is missing, push object to salesArray for missing date
                if (dateArray.indexOf(isodate) == -1) salesArray.push([isodate, null]);

                //Add one day to date
                date.setDate(date.getDate() + 1);
            } while (date <= todate);
        },
        getDateRangeArray(start, end) {
            start = new Date(start);
            end = new Date(end);
            let arr = [];
            while (start <= end) {
                arr.push(start.toISOString().substr(0, 10));
                start = this.getFinalDates(start, 1);
            }
            return arr;
        },
        getFinalDates(date, add) {
            let myDate = date;
            let day = 86400000; //number of milliseconds in a day
            return new Date(+myDate + add * day);
        },
        async initOverConcerts() {
            this.kpiData = [];
            this.kpiAverages = {
                capacity: 0,
                break_even: 0,
                turnover: 0,
                total_costs: 0,
                net: 0,
                avg_ticket_price: 0,
                cost_per_ticket: 0,
                sold: 0,
            };
            let breakEvenArr = [];

            this.tabItems = {
                concerts: [],
                organizations: [],
                people: [],
                documents: [],
            };

            this.topConcertsTickets = [];
            this.topConcertsGross = [];
            this.topConcertsPred = [];
            this.bottomConcertsTickets = [];
            this.bottomConcertsGross = [];
            this.bottomConcertsPred = [];

            let total_tour_sold_tickets = 0;
            let total_tour_capacity = 0;
            let all_concerts_gage = 0;
            let all_concerts_costs = 0;

            if (!this.items.concerts) return;

            for (let i = 0; i < this.items.concerts.length; i++) {
                let concert = this.items.concerts[i];

                await this.initTopBottomItem(concert);

                concert.turnover = +concert.total_gross_sales;

                // collect relevant numbers from the current concert budget
                if (concert.budgets) {
                    let status = Math.max.apply(
                        Math,
                        concert.budgets.map((budget) => {
                            return budget.status;
                        })
                    );
                    let budget = concert.budgets.find((budget) => {
                        return budget.status == status;
                    });

                    concert.break_even = concert.capacity != 0 ? this.$round((budget.break_even / concert.capacity) * 100, 1) : 0;

                    //this.kpiAverages.break_even_arr.push(0);
                    breakEvenArr.push(concert.break_even);
                    concert.total_costs =
                        +budget.variable_costs +
                        +budget.production_costs +
                        +budget.talent_costs +
                        +budget.general_costs +
                        +(await this.getConcertMarketingCost(concert));

                    all_concerts_costs += +concert.total_costs;
                    all_concerts_gage += +budget.talent_costs;
                } else {
                    concert.break_even = 0;
                    let gage_talent_no_budget = concert.gage_talent ? +concert.gage_talent : 0;
                    concert.total_costs = gage_talent_no_budget; // + concert.fixed_gage
                    all_concerts_gage += gage_talent_no_budget;
                }

                concert.net = concert.turnover - concert.total_costs;
                concert.avg_ticket_price = Math.round(await this.getConcertAverageTicketPrice(concert));
                concert.cost_per_ticket =
                    concert.total_sold_tickets != 0 ? Math.round(concert.total_costs / concert.total_sold_tickets) : concert.total_costs;

                // local variables storing some tour info
                total_tour_sold_tickets += +concert.total_sold_tickets;
                total_tour_capacity += +concert.capacity;

                // add to the averages
                for (let key in this.kpiAverages) {
                    if (key != "sold" || key != "break_even") this.kpiAverages[key] += +concert[key];
                }
                await this.initKpiTable(concert);
                console.log("log1");
                let data = await this.initTabBrowserItem(concert);
                console.log("log2");
                // final top/bottom objects. outside of its init function because it needs values from data object above
                this.topConcertsPred.push({
                    title: data.town,
                    percentage: Math.round(concert.predicted_sales * 100),
                    total: concert.capacity,
                });

                this.bottomConcertsPred.push({
                    title: data.town,
                    percentage: Math.round(concert.predicted_sales * 100),
                    total: concert.capacity,
                });
            }

            // format averages for kpi table

            let sum = 0;
            const avgPercentage = this.kpiSoldPercentages.reduce((acc, percentage) => acc + percentage) / this.kpiSoldPercentages.length;

            for (let key in this.kpiAverages) {
                if (key == "sold") {
                    this.kpiAverages[key] = avgPercentage;
                } else if (key == "break_even") {
                    this.kpiAverages[key] = this.$round(breakEvenArr.reduce((a, b) => a + b, 0) / breakEvenArr.length, 1);
                } else if (key == "avg_ticket_price" || key == "cost_per_ticket")
                    this.kpiAverages[key] = this.$thousandSeperator(Math.round(this.kpiAverages[key] / this.items.concerts.length));
                else this.kpiAverages[key] = this.$thousandSeperator(this.kpiAverages[key]);
            }
            // find current tour budget
            this.budgetData = null;
            if (this.items.budgets) {
                let status = Math.max.apply(
                    Math,
                    this.items.budgets.map((budget) => {
                        return budget.status;
                    })
                );
                this.budgetData = this.items.budgets.find((budget) => {
                    return budget.status == status;
                });
            }

            this.items.total_sold_tickets = total_tour_sold_tickets;
            this.items.total_capacity = total_tour_capacity;

            // Fixed progression cards (not depending on datepicker)
            let P_CARD = this.progressionCards.dataObjects;
            // break-even
            P_CARD.break_even.number = this.kpiAverages.break_even;
            // total gage
            P_CARD.total_gage.number = all_concerts_gage + (this.budgetData ? +this.budgetData.talent_costs : 0); // + tour_gage'

            /**
             * 	Only calculate if a goal is set.
             */
            if (this.items.goal_wage_concert) {
                P_CARD.total_gage.percentage = Math.round((P_CARD.total_gage.number / this.items.goal_wage_concert) * 100);
                P_CARD.total_gage.progresstext = `${P_CARD.total_gage.percentage != Infinity ? P_CARD.total_gage.percentag : 0}% of goal`;
            } else {
                P_CARD.total_gage.percentage = 0;
                P_CARD.total_gage.progresstext = "No goal set";
            }
            // risk
            P_CARD.risk.number = this.items.total_costs; // + tour_costs

            // emit to Top/bottom component that data is ready to be sorted
            console.log("init over concerts done");
            this.$bus.$emit("SortProjectRanking");
        },
        async initKpiTable(concert) {
            let kpiItem = {
                date: concert.date,
                city: concert.town,
                venue: concert.venueid.name,
                capacity: this.$thousandSeperator(concert.capacity),
                break_even: `${concert.break_even}%`,
                turnover: this.$thousandSeperator(concert.turnover),
                total_costs: this.$thousandSeperator(concert.total_costs),
                net: this.$thousandSeperator(concert.net),
                avg_ticket_price: this.$thousandSeperator(concert.avg_ticket_price),
                cost_per_ticket: this.$thousandSeperator(concert.cost_per_ticket),
                sold: concert.total_sold_percentage,
            };
            this.kpiData.push(kpiItem);
        },

        async initTopBottomItem(concert) {
            concert.total_sold_percentage = concert.capacity != 0 ? Math.round((concert.total_sold_tickets / concert.capacity) * 100) : 0;

            this.kpiSoldPercentages.push(concert.total_sold_percentage);

            let concertTickets = {
                title: concert.town,
                percentage: concert.total_sold_percentage,
                total: concert.total_sold_tickets,
            };

            let concertGross = {
                title: concert.town,
                percentage: concert.total_sold_percentage,
                total: concert.total_gross_sales,
            };

            // goes to top ProjectRankingCard
            this.topConcertsTickets.push(Object.assign({}, concertTickets));

            this.topConcertsGross.push(Object.assign({}, concertGross));

            // Goes to bottom ProjectRankingCard
            this.bottomConcertsTickets.push(Object.assign({}, concertTickets));

            this.bottomConcertsGross.push(Object.assign({}, concertGross));
        },
        async initTabBrowserItem(concert) {
            const { concertid, status, date, artistid, town, venueid, total_sold_percentage } = concert;

            let tabConcert = {
                rowid: concertid,
                status: status ? status : "no status",
                date: date,
                artistid: artistid.name,
                town: town,
                venueid: venueid.name,
                Capacity: this.$thousandSeperator(concert.capacity),
                ["Total Sold Percentage"]: total_sold_percentage,
            };

            this.tabItems.concerts.push(tabConcert);
            let data = JSON.parse(JSON.stringify(concert));
            for (let key in data) {
                // Find people/organization data by iterating through items-object and look for objects containing value 'personid'
                for (let innerkey in data[key]) {
                    if (innerkey == "organizationid" || innerkey == "personid") {
                        data[key].Role = `${this.formatHeader(key)}`;
                        data[key].rowid = data[key][innerkey];
                        delete data[key][innerkey];

                        // add concert title
                        data[key]["Concert"] = data.title;

                        // delete unwanted keys
                        if (data[key]["country"]) delete data[key]["country"];
                        if (data[key]["postal_adress"]) delete data[key]["postal_adress"];
                        if (data[key]["adress"]) delete data[key]["adress"];

                        let index = innerkey == "personid" ? "people" : "organizations";

                        console.log(index);
                        console.log(data[key]);
                        this.tabItems[index].push(data[key]);
                    }
                }
            }

            let metaFields = {};

            for (let table in this.tabItems) {
                metaFields[table] = this.meta_data[table].browse;
            }

            for (let table in this.tabItems) {
                for (let metaItem in metaFields[table]) {
                    for (let tabItem of this.tabItems[table]) {
                        for (let key in tabItem) {
                            if (key == metaFields[table][metaItem].COLUMN_NAME) {
                                tabItem[metaFields[table][metaItem].LABEL] = tabItem[key];
                                delete tabItem[key];
                            }
                        }
                    }
                }
            }

            return data;
        },
        isTicketSaleInRange(sale, key, startDate = this.datePicker.startDate.date, endDate = this.datePicker.endDate.date) {
            if (sale[key]) if (new Date(sale[key]) >= new Date(startDate) && new Date(sale[key]) <= new Date(endDate)) return true;

            return false;
        },
        formatHeader(header) {
            return header.replace(/_/g, " ").replace(/(^|\s)\S/g, (l) => l.toUpperCase()); // Format header received from object (Capitalize and remove underscore)
        },
        /**
         * Takes a specific sale of a ticket type and adds to the saleData array
         * EX: this.initDataForGraph(sale, 'date', 'amount', this.benchmarkData)
         * where sale = { date: '2020-01-01', amount: 100 }
         */
        initDataForGraph(saleData, datekey, valuekey, salesArray) {
            let found = false;

            salesArray.forEach((sale) => {
                if (saleData[datekey] == sale[0]) {
                    found = true;
                    sale[1] += +saleData[valuekey];
                }
            });
            if (!found) salesArray.push([saleData[datekey], +saleData[valuekey]]); // Add new date with sales
        },
        async getData(route) {
            try {
                let response = await this.$GetService(route);
                if (response.status === 200) {
                    if (response.data.budgets) {
                        let status = Math.max.apply(
                            Math,
                            response.data.budgets.map((budget) => {
                                return budget.status;
                            })
                        );
                        let budget = response.data.budgets.find((budget) => {
                            return budget.status == status;
                        });
                        this.budgetid = budget.budgetid;
                        console.log(this.budgetid);
                    }
                    return response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },

        toggleBudgetCreateOverlay(val) {
            if (val) {
                this.showBudgetCreateOverlay = val;
                this.budgetCreateOverlayLoading = val;
                this.budgetCreateOverlayText = "Creating new budget...";
            } else {
                this.budgetCreateOverlayText = "Budget created!";
                this.budgetCreateOverlayLoading = false;
                // 1500 delay to show user it actually saved
                setTimeout(() => {
                    this.showBudgetCreateOverlay = false;
                    this.$router.push(`/budgets/${this.budgetid}`);
                }, 1500);
            }
        },

        async createBudget() {
            this.toggleBudgetCreateOverlay(true);
            const data = await this.genericPost(this.$getRoute("budgets").main_route, {
                tourid: this.items.tourid,
                concertid: 0,
            });
            if (data == null) throw error("Could not post new budget to database when creating new tour budget");

            console.log(`successfully created tour budget with new id ${data.budgetid}`);
            this.creatingNewBudget = true;
            this.budgetid = data.budgetid;
            await this.postDefaultTourBudgetItems();
        },
        async postDefaultTourBudgetItems() {
            console.log("postDefaultTourBudgetItems");
            // get all template rows
            let templateRows = getStepArray(0);
            templateRows.map((item) => {
                item.budgetid = this.budgetid;
                item.value = 0;
            });

            const data = await this.genericPost(this.$getRoute("budgets").rows, templateRows);

            if (data == null) throw error("Could not post template budget rows to database when creating new tour budget");

            this.toggleBudgetCreateOverlay(false);
        },
        async genericPost(route, item) {
            console.log("genericPost item");
            console.log(item);
            try {
                const response = await this.$PostService(route, item);
                console.log(response);
                if (response.status == 200) return response.data;
            } catch (error) {
                console.log(error);
            }
            return null;
        },
        async updateDataFromSlider() {
            // set loading on progress cards
            this.newDataLoading = true;

            let route = this.$route.path.substr(1).replace(/\/edit\//g, "?id=");
            try {
                let response = await this.$GetService(this.$getEditRoute(this.$route.path));

                if (response.status === 200) {
                    this.items = Object.assign({}, response.data);
                    this.init();
                }
            } catch (error) {
                console.log(error);
            }
        },
        ...mapActions({
            setDrawersDisabled: "globalstates/setDrawersDisabled",
            unSetDrawersDisabled: "globalstates/unSetDrawersDisabled",
        }),
    },
    beforeDestroy() {
        this.setDrawersDisabled();
        clearTimeout(this.timeOut);
    },
};
</script>

<style lang="scss" scoped>
a {
    text-decoration: none !important;
}
button {
    font-weight: 600 !important;
}

.v-tooltip__content {
    padding: 5px 10px !important;
}

.fadein-container {
    min-height: calc(100vh - 170px);
    position: relative;
    width: 100%;
    float: left;
    opacity: 0;
    transition: all 0.5s ease-out;
    margin-top: -20px;
}

.fade-in {
    opacity: 1 !important;
    margin-top: 0px !important;
}

.header-actions-wrapper {
    padding: 40px;
}

.custom-row {
    margin-left: -20px;
    margin-right: -20px;
}

.button-wrapper {
    margin-left: 15px;
    margin: auto;
    display: flex;
    flex: 1;
    .v-btn {
        background-color: #ffffff21 !important;
        flex: 1;
        width: 100%;
        i {
            font-size: 21px;
            margin-right: 15px;
        }
        span {
            color: #fff !important;
        }
    }
}

.benchmark {
    &::v-deep .v-input {
        color: #fff !important;
    }
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #fb8c00 !important;
}

.salesprogresscard-container {
    position: relative;
}

@media only screen and (max-width: 1264px) {
    #tourpage {
        padding: 103px 0px 0px 0px !important;
    }

    .title-container {
        display: block !important;
        max-width: 100% !important;
        float: left;
    }
    .header-actions-wrapper {
        display: block !important;
        max-width: 100%;
        float: left;
    }
    .header-wrapper {
        display: block !important;
        background-size: cover !important;
        width: 100%;
        top: 50px;
        box-shadow: none !important;
        left: 0px;
    }
    .tour-title {
        margin-top: 10px !important;
    }
    .button-wrapper {
        display: block !important;
        button {
            margin-bottom: 10px;
        }
    }
}

.graph-container {
    position: relative;
    border-radius: 3px;
    background: #fff;
}
</style>
