<template>
    <div>
        <EditMarketingType
            :EditMarketingTypeDialog="EditMarketingTypeDialog"
            :currentMarketingInfo="currentMarketingInfo"
            @closeEditMarketingType="EditMarketingTypeDialog = false"
            @addRowsLocallyAfterSave="(rows) => addRowsLocallyAfterSave(rows)"
            @deleteMarketingDataLocally="(id) => deleteMarketingDataLocally(id)"
            @updateLocalMarketingData="(info) => updateLocalMarketingData(info)"
        />
        <v-data-table
            :headers="headers"
            :items="localMarketingData"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="name"
            show-expand
            style="border-radius: 0px"
            @click:row="
                (item, row) => {
                    row.expand(!row.isExpanded);
                }
            "
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                    style="box-shadow: 0px -3px 8px #00000024 !important"
                >
                    <div class="card-title text-left" style="border: none">
                        Marketing
                    </div>
                </v-toolbar>
                <v-col cols="12" md="12" class="add-container">
                    <v-row>
                        <v-col cols="12" md="7">
                            <v-text-field
                                label="Name"
                                v-model="marketingInfo.name"
                                outlined
                                filled
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Estimate"
                                v-model="marketingInfo.estimate"
                                outlined
                                filled
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-btn
                                color="primary"
                                depressed
                                style="height: 100%"
                                block
                                small
                                class="float-right"
                                :loading="isAddMarketingTypePending"
                                @click="addMarketingType"
                                :disabled="
                                    marketingInfo.name.length === 0 ||
                                    marketingInfo.estimate.length === 0
                                "
                                >Add</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    @click.stop="deleteMarketingType(item.marketingid)"
                    icon
                    color="error"
                    :loading="isDeleteMarketingTypePending"
                >
                    <v-icon>remove_circle_outline</v-icon>
                </v-btn>
                <v-btn @click.stop="editMarketingData(item)" icon>
                    <v-icon>edit</v-icon></v-btn
                >
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td class="pa-0" :colspan="headers.length">
                    <v-simple-table
                        style="
                            width: 100%;
                            float: left;
                            background: rgb(244 244 244);
                            border-radius: 0px;
                        "
                    >
                        <template v-slot:default>
                            <thead class="simple-table-thead">
                                <tr>
                                    <th>Date span</th>
                                    <th class="text-right">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="data in item.marketing_data"
                                    :key="data.marketing_dataid"
                                >
                                    <td>
                                        {{ data.startdate }}
                                        <v-icon color="primary">
                                            arrow_right_alt</v-icon
                                        >
                                        {{ data.enddate }}
                                    </td>
                                    <td class="text-right">
                                        {{ data.amount }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </td>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import EditMarketingType from "./EditMarketingType";

export default {
    name: "marketingtable",
    props: {
        marketingData: Object,
    },
    components: {
        EditMarketingType,
    },
    data: () => ({
        expanded: [],
        singleExpand: false,
        EditMarketingTypeDialog: false,
        headers: [
            {
                text: "Actions",
                value: "actions",
                align: "left",
                sortable: false,
            },
            { text: "Name", value: "name" },
            { text: "", value: "data-table-expand" },
        ],
        marketingInfo: {
            name: "",
            estimate: "",
            marketingid: "",
        },
        localMarketingData: [],
        currentMarketingInfo: null,
        isAddMarketingTypePending: false,
        isDeleteMarketingTypePending: false,
    }),
    created() {
        /**
         *   Deep copy the prop 'marketingData' so that wen can
         *   make changes without modifying the prop
         */

        if (this.marketingData) {
            this.localMarketingData = JSON.parse(
                JSON.stringify(this.marketingData)
            );
            /**
             *   Ensures that the 'localMarketingData' has the
             *   'marketing_data' array.
             */
            this.localMarketingData.forEach((local) => {
                if (!local.marketing_data) local.marketing_data = [];
            });
        }

        /**
         *   Emit from the 'BudgetMarketingTable' component.
         *   Is used to the set the name and estimate locally.
         */
        this.$bus.$on("copyFromBudget", this.copyFromBudget);
    },
    methods: {
        updateParentData() {
            /**
             *   A general function to call the parent component functions
             *   so that new data added will be updated.
             */
            this.$emit("getMarketingData");
        },
        updateLocalMarketingData(info) {
            /**
             *   This function is called by the emit from the child component.
             *   It updates the name and estimate values locally if changed
             *   in the child component.
             */
            this.localMarketingData.forEach((local) => {
                if (local.marketingid === info.marketingid)
                    (local.name = info.name), (local.estimate = info.estimate);
            });

            /**
             *   Force refresh of the parentdata by refetching.
             */
            this.updateParentData();
        },
        addRowsLocallyAfterSave(rows) {
            /**
             *  Here we get the rows added in the child component
             *  and push them into the 'marketing_data' array so they
             *  mirror each other. We do that by looking for the rows
             *  with the same marketid.
             */
            rows.forEach((row) => {
                this.localMarketingData.forEach((local) => {
                    console.log(local)
                    if (row.marketingid === local.marketingid)
                        local.marketing_data.push(row);
                });
            });

        },
        deleteMarketingDataLocally(id) {
            /**
             *   Here we get the id recently deleted from thie child component.
             *   We look for the row with the same 'marketing_dataid' and delete it
             *   so they mirror each other.
             */
            this.localMarketingData.forEach((local) => {
                if (local.marketing_data)
                    local.marketing_data.forEach((data, index) => {
                        if (data.marketing_dataid === id)
                            this.$delete(local.marketing_data, index);
                    });
            });
            /**
             *   Force refresh of the parentdata by refetching.
             */
            this.updateParentData();
        },
        copyFromBudget(data) {
            /**
             *   Get the name and estimate from the row clicked
             */
            const { name, estimate } = data;

            /**
             *   Declare the name in 'marketingInfo' object.
             *   This is object is later used when adding a new Marketingtype.
             *   We also to some regex stuff to remove the underscore recieved from the database
             *   and capitalize the name.
             */
            this.marketingInfo.name = name
                .replace(/_/g, " ")
                .replace(/(^|\s)\S/g, (l) => l.toUpperCase());

            /**
             *   Declare the estimate in 'marketingInfo' object
             *   This is object is later used when adding a new Marketingtype.
             */
            this.marketingInfo.estimate = estimate;
        },
        async addMarketingType() {
            /**
             * Set spinner on save button
             */
            this.isAddMarketingTypePending = true;
            try {
                const response = await this.$PostService(
                    this.$getRoute("marketing").main_route,
                    {
                        concertid: this.$route.params.id,
                        name: this.marketingInfo.name,
                        estimate: this.marketingInfo.estimate,
                    }
                );

                if (response.status === 200) {
                    console.log(response.data)
                    /**
                     *   If post is successfull, push it to the 'localMarketingData' array
                     *   so the user can see the changes.
                     */
                    this.localMarketingData.push({
                        name: this.marketingInfo.name,
                        estimate: this.marketingInfo.estimate,
                        marketing_data: [],
                        marketingid: response.data.marketingid,
                    });

                    /**
                     *   Unset spinner on save button.
                     */
                    this.isAddMarketingTypePending = false;

                    /**
                     *  Reset form
                     */

                    this.marketingInfo.name = "";
                    this.marketingInfo.estimate = "";

                    /**
                     *   Force refresh of the parentdata by refetching.
                     */
                    this.updateParentData();
                }
            } catch (err) {
                console.log(err);
            }
        },
        editMarketingData(marketingInfo) {
            /**
             *  Set the params data to a local variable
             *  which is used as a prop in the child component.
             */
            this.currentMarketingInfo = marketingInfo;

            /**
             *   Open dialog which recieved the prop.
             */
            this.EditMarketingTypeDialog = true;
        },
        async deleteMarketingType(id, index) {
            /**
             * Set spinner on delete button
             */
            this.isDeleteMarketingTypePending = true;
            try {
                const response = await this.$DeleteService(
                    this.$getRoute("marketing").main_route,
                    {
                        marketingid: id,
                    }
                );
                if (response.status === 200) {
                    /**
                     * unset spinner on delete button
                     */
                    this.isDeleteMarketingTypePending = false;
                    /**
                     *   Find the index of the data deleted in the child
                     *   component, we do that by looking for the data with
                     *   the same marketingid.
                     */
                    const localIndex = this.localMarketingData.findIndex(
                        (local) => local.marketingid === id
                    );

                    /**
                     *   When the index is found we delete it
                     *   from the local array.
                     */
                    this.$delete(this.localMarketingData, localIndex);

                    /**
                     *   Force refresh of the parentdata by refetching.
                     */
                    this.updateParentData();
                }
                console.log(response);
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style lang="scss" >
.v-data-table:last-child {
    thead:not(.simple-table-thead) {
        th:first-child {
            width: 150px;
        }
    }
    tr {
        &:hover {
            cursor: pointer;
        }
    }
}

.add-container {
    border-bottom: 1px solid $border-color;
}
</style>