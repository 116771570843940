<template>
    <div id="datepicker">
        <div class="mr-3 d-flex" v-if="showDateButtons && !isMobile">
            <v-btn color="primary" class="mr-3" @click="setYTD" depressed small>YTD</v-btn>
            <v-btn color="primary" @click="setMonth" depressed small>Month</v-btn>
        </div>
        <div class="mr-3 d-flex" v-if="showBpcButtons">
            <v-btn-toggle v-model="toggle_none" color="primary" dense >
                <v-btn
                    @click="setForecast"
                >
                    Forecast
                </v-btn>
                <v-btn
                    @click="setBudget"
                >
                    Budget
                </v-btn>
            </v-btn-toggle>
        </div>
        <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            class="float-left"
            :disabled="showBpcButtons"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="startDate"
                    label="From"
                    persistent-hint
                    v-on="on"
                    filled
                    hide-details
                    outlined
                    :dark="light"
                    dense
                ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" no-title @input="setStartDate"></v-date-picker>
        </v-menu>
        <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            :disabled="showBpcButtons"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="endDate"
                    label="To"
                    :dark="light"
                    filled
                    hide-details
                    persistent-hint
                    v-on="on"
                    outlined
                    dense
                ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" no-title @input="setEndDate" :max="maxDate"></v-date-picker>
        </v-menu>
        <v-btn
            color="primary"
            class="get-data-btn"
            v-if="showButton"
            :disabled="new Date(startDate) > new Date(endDate) || wrongBenchmarkValues"
            small
            depressed
            @click="getSalesData"
            >Get data</v-btn
        >
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        defaultDates: Object,
        showButton: Boolean,
        showDateButtons: Boolean,
        dark: Boolean,
        maxDate: String,
        wrongBenchmarkValues: Boolean,
        showBpcButtons: Boolean,
    },
    data: (vm) => ({
        startDate: "",
        endDate: "",
        menu1: false,
        menu2: false,
    }),
    async created() {
        if (this.defaultDates) {
            this.startDate = this.defaultDates.startDate;
            this.endDate = this.defaultDates.endDate;
        }
        console.log(this.wrongBenchmarkValues);
    },
    watch: {
        wrongBenchmarkValues(v) {
            console.log(v);
        },
    },
    methods: {
        setMonth() {
            this.startDate = `${new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().substring(0, 10)}`;
            this.endDate = `${new Date().toISOString().substring(0, 10)}`;
            this.$emit("setStartDate", this.startDate);
            this.$emit("setEndDate", this.endDate);
        },
        setYTD() {
            this.startDate = `${new Date().getFullYear()}-01-01`;
            this.endDate = new Date().toISOString().substring(0, 10);
            this.$emit("setStartDate", this.startDate);
            this.$emit("setEndDate", this.endDate);
        },
        setForecast() {
            this.$emit("setBpcType", "forecast");
            let date = new Date();
            let month = date.getUTCMonth() + 1;
            this.setStartDate(`${date.getUTCFullYear()}-${month < 10 ? "0" + month : month}-01`);
            this.setEndDate(`${date.getUTCFullYear()}-12-31`);
        },
        setBudget() {
            this.$emit("setBpcType", "budget");
            let date = new Date();
            this.setStartDate(`${date.getUTCFullYear() + 1}-01-01`);
            this.setEndDate(`${date.getUTCFullYear() + 1}-12-31`);
        },
        setStartDate(datestr = null) {
            if (datestr) this.startDate = datestr;
            this.$emit("setStartDate", this.startDate);
            this.menu1 = false;
        },
        setEndDate(datestr = null) {
            if (datestr) this.endDate = datestr;
            this.$emit("setEndDate", this.endDate);
            this.menu2 = false;
        },
        getSalesData() {
            this.$emit("getSalesData");
        },
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
#datepicker {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0px 13px;
    float: right;
}

.v-input {
    &::v-deep label {
        top: 7px !important;
    }
}

.datepicker-span-text {
    margin-right: 15px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: $font-color-pale;
}

.v-input {
    padding-top: 0px !important;
    margin-right: 10px;
    font-size: 15px;
    max-width: 110px;
    min-width: 110px;
    &::v-deep .v-input__control {
        .v-input__slot {
            margin: 0;
            min-height: 30px !important;
        }
    }
}

@media only screen and (max-width: 1264px) {
    .get-data-btn {
        flex: 1;
    }
}
</style>
