<template>
    <div class="progress-bars">
        <v-col cols="12" md="12" v-for="bar in bars" :key="bar.id">
            <div class="bar-title-wrapper d-flex">
                <div class="bar-title">{{ bar.title }}</div>
                <v-spacer></v-spacer>
                <div class="bar-title-percentage" v-if="choice == 'percent' && bar.percentage !== 'No estimate'">
                    {{ bar.percentage === Infinity || bar.percentage === null  ? "0" + sumType : bar.percentage + sumType }}
                </div>
                <div class="bar-title-percentage" v-if="choice == 'percent' && bar.percentage === 'No estimate'">
                    {{ bar.percentage }}
                </div>
                <div v-if="choice == 'total'" class="bar-title-percentage"> {{ isFinite(bar.total) ? $thousandSeperator(bar.total) : bar.total }} {{ sumType}}</div>
                <div v-if="choice ==  $config.CURRENCY" class="bar-title-percentage"> {{ bar.total }} {{ sumType}} </div>
            </div>
            <v-progress-linear
                v-if="bar.color === ''"
                :value="bar.percentage"
                :height="height"
                :color="bar.percentage > 80 ? '#56b54a' : 'primary'"
            ></v-progress-linear>
            <v-progress-linear
                v-else
                :value="bar.percentage"
                :height="height"
                :color="bar.color"
            ></v-progress-linear>
        </v-col>
    </div>
</template>

<script>
export default {
    name: "LinearProgressBars",
    props: {
        value: String,
        bars: Array,
        choice: String,
        sumType: String,
    },
    data: () => ({
        height: 6,
    }),
};
</script>

<style lang="scss" scoped>
.progress-bars {
    width: 100%;
    float: left;
    .bar-title {
        font-size: 0.9rem;
        text-transform: uppercase;
        font-weight: 500;
        color: $font-color;
    }
}
</style>