<template>
    <v-container class="wizard-budget-container summary">
        <v-col cols="12" md="12" class>
            <v-btn
                class="float-left toggle-summary-btn"
                dark
                small
                @click="expandTourBudgetContainer"
            >
                <v-icon :class="{ rotate: rotateIcon }" small color="#fff">mdi-arrow-expand-right</v-icon>
            </v-btn>
            <div class="summary-header">Profit and loss statement</div>
        </v-col>

        <v-progress-linear :active="!isSummaryLoaded" indeterminate />

        <div v-if="isSummaryLoaded">
            <!-- REVENUES -->
            <div class="revenue-wrapper pa-3"">
                <h3 class="summary-title">Revenue</h3>

                <!-- CONCERT REVENUES -->
                <tour-summary-expand-button
                    :disable="sumSplitIsOn"
                    title="Show revenues"
                    :value="formattedValue(getAllConcertsTotalRevenues(), true) "
                    @showToggle="show => showConcertRevenues = show"
                />
                <div v-if="showConcertRevenues" class="outer-dropdown-items">
                    <div v-for="(item, index) in concertRevenueItems" :key="item.name">
                        <summary-row
                            :revenue="true"
                            :title="item.name"
                            :value="getConcertTotalRevenues(index)"
                            :currency="currency"
                            :items="item.revenues"
                            valueKey="value"
                        />
                    </div>
                </div>

                <summary-row
                    :revenue="true"
                    :title="'Tour revenues'"
                    :value="tourRevenues"
                    :currency="currency"
                    :items="tourRevenueItems"
                    valueKey="value"
                />


                <summary-total-row
                    :revenue="true"
                    title="Total revenues"
                    :value="totalRevenues"
                    :currency="currency"
                />
            </div>
            <!-- REVENUES-END -->

            <v-divider />

            <!-- COSTS -->
            <div class="pa-3 costs-wrapper">
                <h3 class="summary-title">Costs</h3>

                <!-- CONCERT COSTS -->
                <tour-summary-expand-button
                    :disable="sumSplitIsOn"
                    title="Show budget costs"
                    :value="formattedValue(getAllConcertsTotalCosts())"
                    @showToggle="show => showConcertCosts = show"
                />
                <div v-if="showConcertCosts" class="outer-dropdown-items">
                    <div v-for="(item, index) in concertCostItems" :key="item.name">
                        <summary-row
                            :title="item.name"
                            :value="getConcertTotalCosts(index)"
                            :currency="currency"
                            :items="item.costs"
                            valueKey="value"
                        />
                    </div>
                </div>

                <!-- TOUR COSTS -->
                <div v-for="(stepItem, key) in modifiedDataInSteps" :key="stepItem.id">
                    <summary-row
                        v-if="Object.keys(stepItem).length == 1 && key != 'step1'"
                        :title="`${getStepName(key)}`"
                        :value="getCostVariety(key, Object.keys(stepItem)[0])"
                        :currency="currency"
                        :items="stepItem[Object.keys(stepItem)[0]]"
                        valueKey="calculated_cost"
                    />
                    <tour-summary-expand-button
                        v-else-if="key != 'step1'"
                        :title="getStepName(key)"
                        :value="formattedValue(getTotalCostVariety(key))"
                        @showToggle="show => showCostType[key] = show"
                    />
                    <div v-if="showCostType[key]" class="outer-dropdown-items">
                        <div v-for="(items, innerKey) in stepItem" :key="innerKey" class>
                            <summary-row
                                :title="`${innerKey} costs`"
                                :value="getCostVariety(key, innerKey)"
                                :currency="currency"
                                :items="items"
                                valueKey="calculated_cost"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- COSTS-END -->
            <!-- PROFIT -->
            <div class="pa-3 pt-0 pb-0">
                <summary-total-row
                    :revenue="true"
                    title="Net profit"
                    :value="netProfit"
                    :currency="currency"
                />
            </div>
            <!-- PROFIT-END -->
        </div>
        <div class="split-toggle">
            <v-col cols="8" md="12" class="d-flex align-center pa-5 pt-0">
                <v-switch class="float-left" v-model="sumSplitIsOn" />Total tour P&L per concert
            </v-col>
        </div>
    </v-container>
</template>

<script>
import { calcPrs } from "../ConcertBudgetWizard/Shared/PrsTypes.js";
import TourSummaryExpandButton from "./TourSummaryExpandButton";
import SummaryRow from "./TourSummaryRow";
import SummaryTotalRow from "./SummaryTotalRow";

export default {
    name: "concertbudgetsummary",

    components: {
        TourSummaryExpandButton,
        SummaryRow,
        SummaryTotalRow,
    },

    props: {
        dataInSteps: Object,
        concertRevenueItems: Array,
        concertCostItems: Array,
    },

    data() {
        return {
            dataChanged: 1,
            currency: "",
            isSummaryLoaded: true,
            showConcertRevenues: false,
            showConcertCosts: false,
            showCostType: {
                step1: false,
                step2: false,
                step3: false,
                step4: false,
                step5: false,
                step6: false,
            },
            sumSplitIsOn: false,
            rotateIcon:false,
        };
    },

    created() {
        //this.$logger("dataInSteps in summary", this.dataInSteps);
        this.currency = this.$config.CURRENCY;
        if(this.$vuetify.breakpoint.mdAndDown){
            this.rotateIcon = true
        }
    },

    updated() {},

    watch: {
        sumSplitIsOn() {
            this.showConcertRevenues = false;
            this.showConcertCosts = false;
        },
        '$vuetify.breakpoint.mdAndDown':{
            handler:function(v){
                if(v)
                    this.rotateIcon = true
                else {
                    this.rotateIcon = false
                }
            }
        }
    },

    computed: {
        modifiedDataInSteps() {
            let stepObj = JSON.parse(JSON.stringify(this.dataInSteps));
            let valueKey = "calculated_cost";

            let previousTalentCost = {};
            previousTalentCost[valueKey] = 0;
            let index = -1;

            for (const STEP in stepObj) {
                for (const HEADER in stepObj[STEP]) {
                    for (let data of stepObj[STEP][HEADER]) {
                        data[valueKey] = this.getCorrectSum(+data[valueKey]);

                        if (HEADER == "Talent") {
                            if (previousTalentCost[valueKey] < data[valueKey]) {
                                previousTalentCost = JSON.parse(
                                    JSON.stringify(data)
                                );
                                this.$delete(stepObj[STEP][HEADER], index);
                                index = stepObj[STEP][HEADER].indexOf(data);
                                console.log(index);
                            } else {
                                this.$delete(
                                    stepObj[STEP][HEADER],
                                    stepObj[STEP][HEADER].indexOf(data)
                                );
                            }
                        }
                    }
                }
            }
            return stepObj;
        },
        tourRevenueItems() {
            let items = [];
            for (const KEY in this.dataInSteps.step1) {
                for (const item of this.dataInSteps.step1[KEY]) {
                    let data = JSON.parse(JSON.stringify(item));
                    data.value = this.getCorrectSum(+data.value);
                    items.push(data);
                }
            }

            return items;
        },

        tourRevenues() {
            let sum = 0;
            for (const ITEM of this.tourRevenueItems) sum += +ITEM.value;

            return sum;
        },

        totalRevenues() {
            let sum = 0;
            sum += this.getAllConcertsTotalRevenues() + this.tourRevenues;

            return sum;
        },

        totalCosts() {
            let sum = this.getAllConcertsTotalCosts();
            for (const KEY in this.modifiedDataInSteps)
                sum += KEY == "step1" ? 0 : this.getTotalCostVariety(KEY);

            return sum;
        },

        netProfit() {
            return this.totalRevenues - this.totalCosts;
        },
    },

    methods: {
        expandTourBudgetContainer(){
            this.$bus.$emit('setExpandTourBudgetContainer')
            this.rotateIcon = !this.rotateIcon
        },
        getCorrectSum(sum) {
            let divider = 1;
            if (this.concertRevenueItems.length != 0)
                divider = this.concertRevenueItems.length;
            return this.sumSplitIsOn ? Math.round(sum / divider) : sum;
        },

        getConcertTotalRevenues(index) {
            let sum = 0;
            for (const ITEM of this.concertRevenueItems[index].revenues)
                sum += +ITEM.value;
            return sum;
        },

        getAllConcertsTotalRevenues() {
            let sum = 0;
            for (let i = 0; i < this.concertRevenueItems.length; i++)
                sum += this.getConcertTotalRevenues(i);

            return this.getCorrectSum(sum);
        },

        getConcertTotalCosts(index) {
            let sum = 0;
            for (const ITEM of this.concertCostItems[index].costs)
                sum += +ITEM.value;
            return sum;
        },

        getAllConcertsTotalCosts() {
            let sum = 0;
            for (let i = 0; i < this.concertCostItems.length; i++)
                sum += this.getConcertTotalCosts(i);
            return this.getCorrectSum(sum);
        },

        getTotalCostVariety(key) {
            let sum = 0;
            for (const innerKey in this.modifiedDataInSteps[key])
                sum += this.getCostVariety(key, innerKey);

            return sum;
        },
        getCostVariety(key, innerKey) {
            let sum = 0;
            for (let item of this.modifiedDataInSteps[key][innerKey])
                sum += +item.calculated_cost;

            return sum;
        },
        getStepName(key) {
            switch (key) {
                case "step2":
                    return "Personnel costs";
                case "step3":
                    return "Hotel and travel costs";
                case "step4":
                    return "Production costs";
                case "step5":
                    return "Marketing costs";
                case "step6":
                    return "Other costs";
                default:
                    return key;
            }
        },

        formattedValue(value, revenue = false) {
            let val = this.$thousandSeperator(Math.round(value));
            let presufVal = `${
                this.currency.match(/[$€£]/) ? this.currency : ""
            } ${val} ${this.currency.match(/[^$€£]/) ? this.currency : ""}`;
            return !revenue ? `- ${presufVal}` : presufVal;
        },
    },
};
</script>

<style lang="scss">

.wizard-budget-container.summary{
    height:100%;
    overflow:auto;
}

.rotate{
    transform:rotate(180deg)
}

.divider {
    margin-top: 10px;
    border-color: $accent-color !important;
}

.summary-title {
    font-size: 0.9;
    color: $accent-color;
    margin-bottom: 10px;
    font-weight: 500;
}

.summary-text {
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;

    input[type="number"] {
        text-align: right;
    }
    .number-text {
        input {
            text-align: right;
        }
    }
}

.split-toggle {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
}
.summary-total {
    font-size: 14px;
    font-weight: 400;
    color: #fff;

    input[type="number"] {
        text-align: right;
    }
    .number-text {
        input {
            text-align: right;
        }
    }
}

.revenue-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.151);
}
.outer-dropdown-items {
    margin: 0px 0px 0px 10px;
}

.costs-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.151);
}
</style>