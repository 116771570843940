export default {
	methods: {
		setScrollLock() {
			var scrollables = this.$el.querySelectorAll('.scroll-locked');
			console.log(scrollables)
			console.log('MOUNTED')
			if (scrollables && scrollables.length > 0) {
				scrollables.forEach(function (scrollable) {
					scrollable.addEventListener('wheel', function (event) {
						var meetTop = scrollable.scrollTop === 0;
						var meetBottom = (scrollable.scrollHeight - scrollable.clientHeight) === scrollable.scrollTop;
						var isUpward = event.deltaY < 0;
						var isDownward = event.deltaY > 0;

						if (meetBottom && isDownward) {
							event.preventDefault();
							event.stopPropagation();
							return;
						}
						if (meetTop && isUpward) {
							event.preventDefault();
							event.stopPropagation();
							return;
						}
					});
				})
			}
		}
	},
	beforeDestroyed: function() {
		var scrollables = this.$el.querySelectorAll('.scroll-lockable');
		if (scrollables && scrollables.length > 0) {
			scrollables.forEach(function(scrollable) {
				scrollable.removeEventListener('wheel');
			})
		}
	},
};
