<template>
    <div>
        <v-row class="summary-text" >
            <v-btn 
                class="btn-text" 
                block 
                icon-left 
                text
                @click="!disable ? showItems = !showItems : null"
            >
                <v-icon :color="disable ? 'grey' : ''">
                    {{ showItems && !disable ? 'expand_less' : 'expand_more' }}
                </v-icon>
                {{ title }}
                <v-spacer />
                {{ value }}
            </v-btn>
        </v-row>
    </div>
</template>

<script>

export default {
    props: {
        disable: Boolean,
        title: String,
        value: Number,
    },
    data: () => ({
        showItems: false,
    }),
    watch: {
        showItems(val){
            this.$emit('showToggle', val);
        },
        disable(val){
            if(val == false)
                this.showItems = val;
        }
    }

}
</script>

<style>

</style>