import ApiHandler from './ApiHandler';
import store from './../store/modules/metadata';
//import ObjectFactory from './ObjectFactory';

export default class ApiObject extends ApiHandler {
    constructor(endpoint) {
        super(endpoint);
        this.objFactory = null;
    }

    async setProperties(metaFields) {
        if(!metaFields) {
            metaFields = store.getters.meta_data(store.state)[this.endpoint].fields[0];
        }
        this.metaFields = metaFields;
        if (!Array.isArray(metaFields)) {
            for (const KEY in metaFields) {    // object with keys
                this[KEY] = null;
            }
        } else
            for (const FIELD of metaFields)  // array with strings
                this[FIELD] = null;
        
        this.pk_field = store.getters.meta_data(store.state)[this.endpoint].pk_field;
    }

    async fetchObject(id, idTag = 'id') {
        const data = await this.getData(`?${idTag}=${id}`)
        if (data)
            this.setObjectData(data);
        else
            return false;
    }

    async setCreateData(indata, metaData) {
        const FIELDS = metaData == null ? await this.getMetaFields('create') : metaData;
        var outdata = {};
        const NAME_KEY = 'COLUMN_NAME';
        for (const KEY in FIELDS) {
            if (FIELDS[KEY]) {
                const COL = FIELDS[KEY].COLUMN_NAME;
                if (FIELDS[KEY].REQUIRED && !Object.keys(indata).includes(COL)){
                    // throw Error(`missing required field, ${FIELDS[KEY].COLUMN_NAME}`)
                    return false;
                }
                else if (indata[COL] != undefined) {
                    outdata[COL] = indata[COL];
                    this[COL] = indata[COL];
                }
            }
        }
        return outdata;
    }

    async setUpdateData(indata, metaData = null) {
        const FIELDS = metaData == null ? await this.getMetaFields('update') : metaData;
        var outdata = {};

        outdata[this.pk_field] = indata[this.pk_field];

        const NAME_KEY = 'COLUMN_NAME';
        for (const KEY in FIELDS) {
            if (FIELDS[KEY]) {
                const COL = FIELDS[KEY].COLUMN_NAME;

                if (indata[COL] != undefined || indata[COL] !== null) {
                    outdata[COL] = indata[COL];
                    this[COL] = indata[COL];
                } 
            }
        }

        return outdata;
    }

    setObjectData(indata) {
        for (const KEY in indata) {
            this[KEY] = indata[KEY];
        }
    }

    async postObject(indata = this) {
        console.log('post object')
        console.log(indata);

        var outdata = await this.setCreateData(indata);
        console.log("seke outdata log")
        console.log(outdata)
        if (!outdata) return false;
        const RES = await this.postData(outdata);
        // res is either a new id or false
        if (RES)
            this[this.pk_field] = RES;

        return RES;
    }

    async updateObject(indata) {
        if (!indata) throw Error('indata parameter missing');

        if (!indata[this.pk_field] || indata[this.pk_field] == '') throw Error('Missing primary key (id)');

        var outdata = await this.setUpdateData(indata);
        if (outdata) {
            return await this.putData(outdata);
        }
    }

    async deleteObject() {
        return await this.deleteData(this);
    }

    getValuesInObjects(key) {
        var fields = [];
        var values = [];

        if(this.fk_objects){
            for (const field of this.fk_objects) {
                if (field.fk_key == key)
                    fields.push(field.table_fk_key);
            }
            for (let field of fields) {
                if (this[field]) {
                    if (this[field][key]) // this.field is an object that has value as a property (key)
                        values.push(this[field][key]);
                    else if (this[field]) // this.field has the value
                        values.push(this[field]);
                }
            }
        }else{
            for (let field in this)
                if (this[field]) 
                    if (this[field][key]) // this.field is an object that has value as a property (key)
                        values.push(this[field][key]);
        }
        return values;
    }

    setNullValue(newValue = '') {
        for (const key in this)
            if (this[key] == null)
                this[key] = newValue;
    }

    thousandSeperator(n) {
        if(n != undefined){
           var parts = n.toString().split(".")
           parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
           return parts.join(".")
        }
        return ''
     }

     objArrToCsvString({data, delimiter, addHeaders}){
        let dataStr = '';
        let i = 0;
        if(addHeaders){
            i = 0;
            for(let key in data[0])
                dataStr += `${key}${i++ < Object.keys(data[0]).length - 1 ? delimiter : '\n'}`;
        }
        
        for(let row of data){
            i = 0;
            for(let key in row) {
                dataStr += `${row[key]}${i++ < Object.keys(row).length - 1 ? delimiter : '\n'}`
            }
        }
        return dataStr;
     }

     getCsvData(){
        let data = {};
        data[this.pk_field] = this[this.pk_field];
        return data;
     }

}