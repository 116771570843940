<template>
    <div style="width: 100%" v-if="apiObject != null">
        <RemoveDialog
            v-if="metaParam == 'update'"
            :showDeleteDialog="showDeleteDialog"
            :name="apiObject.name"
            @closeDeleteDialog="closeDeleteDialog"
            @deleteColumn="deleteColumn"
        />
        <v-row class="ma-0 elevation-2" :class="{ mobile: isMobile, sticky: !isPortable }">
            <v-col cols="12" md="12" class="form-header-container pa-3">
                <v-btn v-if="!isPortable" @click="goBack()" icon class="mr-3 back-btn">
                    <v-icon>arrow_back</v-icon>
                </v-btn>
                <h1 color="primary" class="header">{{ getEditTableTitle }}</h1>
                <v-spacer></v-spacer>
                <span class="required-fields-warning" v-if="showRequiredFieldsError">Missing required fields</span>
                <v-btn
                    class="create-btn"
                    small
                    depressed
                    color="primary"
                    :disabled="noChangesMade || buttonText === 'Saved'"
                    :loading="isUpdatePending"
                    @click="setData"
                    >{{ buttonText }}</v-btn
                >
                <v-btn class="delete-btn" v-if="metaParam === 'update'" small depressed color="error" @click="openDeleteDialog()">Delete</v-btn>
            </v-col>
        </v-row>
        <v-col cols="12" md="12" class="float-left pa-5" style="position: relative">
            <Loader :loaderStyle="'linear'" v-if="isTableDataPending" />

            <div id="edittableitem" class="elevation-1" v-if="!isTableDataPending">
                <v-col cols="12" md="12" class="float-left" :class="isMobile ? 'pa-5 pt-3' : 'pa-8'">
                    <handle-general-form
                        v-if="!isTableDataPending"
                        ref="handleGeneralFormRef"
                        :apiObject="apiObject"
                        :view="view"
                        :metaParam="metaParam"
                        :dropdown_lists="dropdown_lists"
                        @generalFormUpdate="generalFormUpdate"
                        @requiredFieldsError="showRequiredFieldsError = true"
                        @refresh_dropdown_list="refreshDropdown"
                    />
                    <handle-extra
                        v-if="ref_list.includes(view) && !isTableDataPending"
                        class="pt-5"
                        ref="extraFormRef"
                        :metaParam="metaParam"
                        :stack="isMobile"
                        :dropdown_lists="dropdown_lists"
                    />
                </v-col>
            </div>
        </v-col>
    </div>
</template>
<script>
import EditItemMixin from "./EditItemMixin.js";
import Loader from "@/components/Loader";
import SkeletonLoader from "@/components/SkeletonLoader";
import RemoveDialog from "./RemoveDialog.vue";
import GeneralForm from "./forms/GeneralForm.vue";
import ApiObject from "@/models/ApiObject.js";
import ObjectFactory from "@/models/ObjectFactory";
import { mapGetters } from "vuex";
import SplitInfoForm from "./forms/FormExtensions/SplitInfoForm";
import HandleGeneralForm from "./HandleGeneralForm.vue";

export default {
    name: "edittableitem",
    mixins: [EditItemMixin],
    components: {
        Loader,
        RemoveDialog,
        GeneralForm,
        SplitInfoForm,
        HandleGeneralForm,
        SkeletonLoader,
    },
};
</script>

<style lang="scss">
#create-concert-form,
#create-tour-form {
    float: left;
    width: 100%;
    .v-chip {
        background: $accent-color !important;
        color: #fff !important;
    }
}

.v-picker {
    .v-picker__title {
        display: none;
    }
}

.datetimepicker_on_sale {
    .v-input {
        padding: 0px !important;
    }
}

#extratableitem {
    width: 100%;
    background: #fff;
    position: relative;
    // min-height: 600px;
    float: left;
    border-radius: 3px;
    overflow: hidden;
}

.mobile .sticky {
    top: 50px;
    border-bottom: 1px solid $border-color;
    float: left;
    width: 100%;
    height: auto;
    .header {
        font-size: 1.2em;
    }
}

.sticky {
    position: sticky;
    height: 60px;
    top: 90px;
    width: 100%;
    z-index: 2;
}
#budgettemplate {
    width: 100%;
    background: #fff;
    position: relative;
    min-height: 600px;
}

.form-header-container {
    float: left;
    display: flex;
    align-items: center;
    background: #fff;
    .header {
        background-color: #fff;
        font-size: 1.4rem;
        font-weight: 600;
        color: $accent-color;
        padding: 20px;
        padding-bottom: 0px;
        float: left;
        padding: 0px;
    }
    .back-btn {
        i {
            font-size: 30px;
        }
    }
}

.v-btn--fab.v-size--default {
    width: 45px;
    height: 45px;
}

.header {
    font-weight: 600;
    color: $accent-color;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #ccc !important;
    color: #fff !important;
}

.delete-btn {
    margin-left: 20px;
}
.create-btn {
    margin-left: 20px;
}

.required-fields-warning {
    font-size: 0.85em;
    font-weight: 600;
    color: rgb(218, 50, 50);
}
</style>
