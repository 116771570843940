<template>
    <div class="getaccept-wrapper pa-4">
        <div
            class="getaccept-inner elevation-1"
            :class="docusignSelection == 'Yes' && selectedUser.email ? '' : 'pa-4'"
        >
            <div class="getaccept-logo">
                <img src="@/assets/getaccept-logo.png" />
            </div>
            <v-row>
                <v-col cols="12" md="4">
                    <v-select
                        label="Send with GetAccept"
                        :items="docusignItems"
                        v-model="docusignSelection"
                        outlined
                        dense
                        hide-details
                        :disabled="!isDocSelected"
                        filled
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        label="Select document owner"
                        :items="users"
                        v-model="selectedUser"
                        item-text="name"
                        return-object
                        outlined
                        dense
                        hide-details
                        :disabled="users.length === 0 || docusignSelection !== 'Yes'"
                        :loading="users.length === 0"
                        filled
                    />
                </v-col>
            </v-row>
            <v-row v-show="docusignSelection == 'Yes' && owner.email" class="recipient-row">
                <v-col cols="12" md="12" class="pa-0">
                    <recipient-form
                        v-show="owner.isSigner"
                        :owner="owner"
                        :agreed_with="agreed_with"
                        @updatedRecipients="(recipients) => $emit('setRecipients', recipients)"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import RecipientForm from "./RecipientForm";
export default {
    name: "doc-menu-row",
    components: {
        RecipientForm,
    },
    props: {
        agreed_with: Object,
        isDocSelected: Boolean,
    },
    data: () => ({
        docusignItems: ["Yes", "No"],
        docusignSelection: null,
        users: [],
        selectedUser: { isSigner: false, isOwner: false },
        owner: { isSigner: false, isOwner: false },
    }),
    watch: {
        docusignSelection(val) {
            if (val != "Yes") {
                this.selectedUser = { isSigner: false, isOwner: false };
                this.$bus.$emit("resetRecipientForm");
            }

            this.$emit("docusign", val);
        },
        selectedUser(user) {
            this.owner = user;
            this.owner.isSigner = true;
            this.owner.isOwner = true;
            this.$emit("setOwner", user);
        },
    },
    async created() {
        this.$bus.$on("resetDocMenu", (signSelection) => {
            this.docusignSelection = signSelection;
            console.log("reset");
            this.$bus.$emit("resetRecipientForm");
        });
        await this.getData("getAcceptUsers");
    },
    methods: {
        async getData(route) {
            try {
                const response = await this.$GetService(route);
                console.log(response);
                if (response.status == 200) this.users = response.data.users;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style lang="scss">
.getaccept-wrapper {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    background: #f8f8f8;
    .getaccept-inner {
        background: #fff;
        padding: 15px;
        padding-bottom: 0px;
        border-radius: 3px;
        .getaccept-logo {
            padding: 10px 15px 20px 0px;
        }
        .recipient-row {
            margin-left: -15px;
            margin-right: -15px;
            margin-top: 10px;
        }
    }
}

</style>