<template>
	<v-expansion-panels accordion>
		<div class="edit-slider-header d-flex">
			<v-btn class="mr-5 close-drawer" @click="closeEditDrawer" depressed icon>
				<v-icon color="primary" dark>menu_open</v-icon>
			</v-btn>
			<div class="header">
				<span>Edit</span>
			</div>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				:loading="isSliderDataSavePending"
				small
				:disabled="noChangesMade"
				@click="setData"
				depressed
				>Save changes</v-btn
			>
		</div>
		<v-expansion-panel>
			<v-expansion-panel-header>General</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="formItems.name"
							outlined
							dense
							hide-details
							label="Name"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-autocomplete
							outlined
							v-model="formItems.artistid"
							:items="formItems.dropdowns.artists"
							dense
							hide-details
							filled
							label="Artist"
							item-text="name"
							item-value="artistid"
							clearable
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="startDateMenu" :close-on-content-click="false" offset-y min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="formItems.startdate"
									label="Start Date"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									dense
									hide-details
									readonly
								></v-text-field>
							</template>
							<v-date-picker
								v-model="formItems.startdate"
								no-title
								@input="startDateMenu = false"
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-menu v-model="endDateMenu" :close-on-content-click="false" offset-y min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="formItems.enddate"
									label="End Date"
									prepend-inner-icon="event"
									v-on="on"
									outlined
									dense
									hide-details
									readonly
								></v-text-field>
							</template>
							<v-date-picker
								v-model="formItems.enddate"
								no-title
								@input="endDateMenu = false"
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="12">
						<v-textarea
							outlined
							dense
							hide-details
							label="Comments"
							v-model="formItems.comments"
							required
						></v-textarea>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Economy</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="formItems.oracle_project_number"
							outlined
							dense
							hide-details
							label="Oracle project number"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="formItems.settlement"
							outlined
							dense
							hide-details
							label="Settlement"
							required
						></v-text-field>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Initital goal</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="formItems.goal_number_of_gig"
							outlined
							dense
							hide-details
							label="Goal number of gig"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="formItems.goal_wage_concert"
							outlined
							dense
							hide-details
							label="Goal wage show"
							required
						></v-text-field>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Statistics</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="formItems.estimate_number_gigs"
							outlined
							dense
							hide-details
							label="Estimate number of gigs"
							required
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-text-field
							v-model="formItems.estimate_wage_per_gig"
							outlined
							dense
							hide-details
							label="Estimate wage per gig"
							required
						></v-text-field>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>Link concerts</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row>
					<v-col cols="12" md="12">
						<v-col cols="12" md="12" id="linked-concerts-wrapper" class="pa-0">
							<div
								v-for="(concert, index) in formItems.concerts"
								:key="concert.id"
								class="linked-concert-wrapper d-flex"
							>
								<div class="linked-concert elevation-1">
									<span class="concert-name">{{ concert.title }}</span>
									<v-btn
										color="primary"
										class="ml-5 elevation-0"
										x-small
										fab
										@click="unLinkConcert(concert, index)"
									>
										<v-icon>close</v-icon>
									</v-btn>
								</div>
							</div>
						</v-col>
						<v-col cols="12" md="12" class="pa-0">
							<v-autocomplete
								outlined
								:rules="requiredRules"
								required
								v-model="concertToLink"
								:items="concerts"
								dense
								hide-details
								filled
								label="Add unlinked shows"
								item-text="title"
								item-value="concertid"
								clearable
								return-object
								@input="linkConcert"
								class="float-left"
							></v-autocomplete>
						</v-col>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	name: "tourexpandcollapse",
	props: {
		view: String,
		isSliderDataSavePending: Boolean,
		items: Object,
		originalData: Object,
	},
	data: () => ({
		concerts: [],
		noChangesMade: true,
		linkedConcerts: [],
		originalData: [],
		concertToLink: "",
		concertsToBeRemoved: [],
		concertsToBeAdded: [],
		originalConcerts: [],
		formItems: [],
	}),
	async created() {
		if (this.items.concerts){
			this.originalConcerts = this.items.concerts.reduce((a, b) => {
				a.push(b.concertid);
				return a;
			}, []);
		} else this.items.concerts = [];

		this.formItems = JSON.parse(JSON.stringify(this.items));


		await this.getConcerts();

		this.fixDropDownValues();
		this.originalData = JSON.parse(JSON.stringify(this.formItems));
	},
	watch: {
		formItems: {
			handler: function(v) {
				if (JSON.stringify(this.formItems) === JSON.stringify(this.originalData)) {
					this.noChangesMade = true;
				} else {
					this.noChangesMade = false;
				}
			},
			deep: true,
		},
	},
	methods: {
		async getConcerts() {
			try {
				let response = await this.$GetService(
					`${this.$getRoute("concerts").main_route}?fields=title,tourid,dev_min_date,dev_max_date&tourid=0`
				);
				if (response.status === 200) {
					this.concerts = response.data;
				}
			} catch (err) {
				console.log(err);
			}
		},
		setTourDates(startDate, endDate) {
			if(startDate && new Date(startDate) < new Date(this.formItems.startdate))
				this.formItems.startdate = startDate?.substr(0,10);
			if(endDate && new Date(endDate) > new Date(this.formItems.enddate))
				this.formItems.enddate = endDate?.substr(0,10);
		},
		resetTourDates(){
			// FIND ALL MIN DATES FROM DATELIST AND REDUCE TO ONE ARRAY
            const MIN_DATES = this.formItems.concerts.reduce((total, concert) => {
                total.push(new Date(`${concert.dev_min_date.substr(0,10)} 12:00:00`));
                return total;
            }, []);

            // FIND ALL MAX DATES FROM DATELIST AND REDUCE TO ONE ARRAY
            const MAX_DATES = this.formItems.concerts.reduce((total, concert) => {
                total.push(new Date(`${concert.dev_max_date.substr(0,10)} 12:00:00`));
                return total;
            }, []);

            // GET EARLIEST DATE
            const MIN_DATE = new Date(Math.min(...MIN_DATES))
                .toISOString()
                .substring(0, 10);

            // GET LATEST DATES
            const MAX_DATE = new Date(Math.max(...MAX_DATES))
                .toISOString()
                .substring(0, 10);

            // ASSIGN DEFAULT DATES
            this.formItems.startdate = MIN_DATE;
			this.formItems.enddate = MAX_DATE;
			
			console.log('formItems.startdate', this.formItems.startdate);
			console.log('formItems.enddate', this.formItems.enddate);
		},
		async unLinkConcert(concert, index) {
			// DESTRUCTURE OBJECT
			console.log(concert);
			
			const { concertid, title, dev_min_date, dev_max_date } = concert;
			
			// IF ARRAY DO NOT HAVE ID, PUSH ID
			if (!this.concertsToBeRemoved.includes(concertid)) this.concertsToBeRemoved.push(concertid);

			// CHECK IF ID IS IN CONCERTSTOBEADDED ARRAY, IF SO, REMOVE
			this.concertsToBeAdded.forEach((id, i) => {
				if (id === concertid) this.$delete(this.concertsToBeAdded, i);
			});

			// ADD CONCERT TO DROPDOWN WHEN REMOVED
			this.concerts.push({ concertid: concertid, title: title, dev_min_date, dev_max_date });

			// REMOVE SELF FROM LINKED CONCERTS
			this.$delete(this.formItems.concerts, index);

			this.concertToLink = "";

			this.resetTourDates();
		},
		async linkConcert() {
			// DESTRUCTURE OBJECT
			console.log(this.concertToLink);
			const { concertid, title, dev_min_date, dev_max_date } = this.concertToLink;
			console.log('dev_min_date', dev_min_date);
			console.log('dev_max_date', dev_max_date);
			console.log('formItems.startdate', this.formItems.startdate);
			console.log('formItems.enddate', this.formItems.enddate);
			// ONLY PUSH ID IF ITS NOT ADDED AND IS NOT IN THE ORIGINAL DATA
			if (!this.concertsToBeAdded.includes(concertid) && !this.originalConcerts.includes(concertid)){
				this.concertsToBeAdded.push(concertid);
			}
			this.setTourDates(dev_min_date, dev_max_date);

			console.log('newStart', this.formItems.startdate);
			console.log('newEnd', this.formItems.enddate);

			// CHECK IF ID IS IN CONCERTSTOBEREMOVED ARRAY, IF SO, REMOVE
			this.concertsToBeRemoved.forEach((id, i) => {
				if (id === concertid) this.$delete(this.concertsToBeRemoved, i);
			});

			// ADD CONCERT TO LINKED CONCERTS
			this.formItems.concerts.push({
				concertid: concertid,
				title: title,
				dev_min_date,
				dev_max_date
			});

			// REMOVE SELF FROM DROPDOWN
			this.concerts.forEach((concert, i) => {
				if (concert.concertid === concertid) this.$delete(this.concerts, i);
			});
		},
		async getLinkedConcerts() {
			this.formItems.concerts.forEach((linkedconcert) => {
				this.concerts.forEach((concert) => {
					if (linkedconcert.concertid === concert.concertid) {
						this.linkedConcerts.push(concert);
					}
				});
			});
		},
		async fixDropDownValues() {
			for (let fk of this.formItems.fk_objects) {
				let table_fk_key = fk.table_fk_key;
				let fk_key = fk.fk_key;
				if (typeof this.formItems[table_fk_key] === "object" && this.formItems[table_fk_key] !== null) {
					this.formItems[table_fk_key] = this.formItems[table_fk_key][fk_key];
				}
			}
		},
		closeEditDrawer() {
			this.$emit("closeEditDrawer");
		},
		setData() {
			this.$emit("setData", this.formItems);
			if (this.concertsToBeAdded.length !== 0) this.setLinkedConcert(this.concertsToBeAdded);
			if (this.concertsToBeRemoved.length !== 0) this.unSetLinkedConcerts(this.concertsToBeRemoved);
			this.noChangesMade = true;
		},
		async setLinkedConcert(concertids) {
			console.log("setlinkedconcert function");
			concertids.map(async (id) => {
				try {
					let response = await this.$PutService(this.$getRoute("concerts").main_route, {
						concertid: id,
						tourid: this.$route.params.id,
					});
					console.log(response);
				} catch (err) {
					console.log(err);
				}
			});
		},
		async setLinkedConcert(concertids) {
			concertids.map(async (id) => {
				try {
					let response = await this.$PutService(this.$getRoute("concerts").main_route, {
						concertid: id,
						tourid: this.$route.params.id,
					});
					console.log(response);
				} catch (err) {
					console.log(err);
				}
			});
		},
		async unSetLinkedConcerts(concertids) {
			concertids.map(async (id) => {
				try {
					let response = await this.$PutService(this.$getRoute("concerts").main_route, {
						concertid: id,
						tourid: "0",
					});
					console.log(response);
				} catch (err) {
					console.log(err);
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.close-drawer {
	i {
		transform: rotate(180deg);
		margin-right: 3px;
	}
	&:hover {
		background: $border-color !important;
	}
}

.edit-slider-header {
	width: 100%;
	float: left;
	padding: 12px 20px;
	z-index: 1;
	display: flex;
	align-items: center;
	.header {
		font-weight: 600;
		font-size: 20px;
		color: $font-color-pale;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
	}
}

#linked-concerts-wrapper {
	.linked-concert-wrapper {
		.linked-concert {
			margin-bottom: 15px;
			border-radius: 30px;
			padding: 7px 7px 7px 20px;
			align-items: center;
			float: left;
			background: #f0f0f0;

			.concert-name {
				font-size: 14px;
				font-weight: 500;
			}
		}
	}
	margin-bottom: 20px;
}

.expansion-panel-container {
	width: 100%;
	float: left;
}

.v-expansion-panel::before {
	box-shadow: none;
}

.v-expansion-panels {
	border-radius: 0px;
}

.v-item-group {
	border-top: 1px solid $border-color !important;
	border-bottom: 1px solid $border-color !important;
}

.v-expansion-panel-header {
	text-transform: uppercase;
	font-weight: 500;
	color: #666;
	height: 60px;
	transition: all 0.2s ease;
	&:hover {
		color: $accent-color;
	}
}
</style>
