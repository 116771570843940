<template>
   <div>
      <div class="summary-total">
         <v-row>
         <v-col cols="3" md="3" class="py-0" >
            <span>{{ title }}</span>
         </v-col>
         <v-col cols="3" md="3" class="py-0" v-for="value of values" :key="value.id">
            <v-spacer/>
            <span class="float-right">{{ formattedValue(value) }}</span>
         </v-col>
         </v-row>
      </div>
      
   </div>
</template>

<script>
export default {
   name:'summarytotalrow',

   props: {
      title: String,
      values: Number,
      currency: String,
   },

   methods: {
      formattedValue(value){
         let val = this.$thousandSeperator(Math.round(value))
         let presufVal = `${this.currency.match(/[$€£]/) ? this.currency : ''} ${val} ${this.currency.match(/[^$€£]/) ? this.currency : ''}`
         return presufVal
      }
   }

}
</script>

<style>

</style>