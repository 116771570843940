<template>
    <div id="generic-tab-browser" class="elevation-1">
        <v-tabs v-model="tab" color="primary" grow  show-arrows>
            <v-tab v-for="item in tabData.tabTables" :key="item">{{ item.title }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item v-for="table in tabData.tabTables" :key="table">
                <v-card flat>
                    <selection-data-table
                        :items="table.rows"
                        :headers="table.headers"
                        :singleSelect="singleSelect"
                        :clear="clearSelection"
                        v-if="type === 'selection'"
                        @itemsSelected="(item) => $emit('itemsSelected', item)"
                    />
                    <favorite-data-table
                        :items="table.rows"
                        :headers="table.headers"
                        v-if="type === 'favorite'"
                    />
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import TabBrowser from "@/models/TabBrowser.js";
import SelectionDataTable from "./SelectionDataTable";
import FavoriteDataTable from "./FavoriteDataTable";

export default {
    name: "generic-tab-browser",

    components: {
        SelectionDataTable,
        FavoriteDataTable,
    },
    props: {
        tabData: TabBrowser,
        dataTable: Object,
        singleSelect: Boolean,
        type: String,
    },
    data: () => ({
        tab: 0,
        clearSelection: false,
    }),
    watch: {
        tab() {
            console.log("tab switch");
            this.clearSelection = true;
            setTimeout(() => {
                this.clearSelection = false;
            }, 10);
        },
    },
    created() {
        console.log("generictabbrowser created");
        console.log(this.tabData);
    },

    methods: {},
};
</script>

<style scoped lang="scss">
#generic-tab-browser {
    border-radius: 3px;
    overflow: hidden;

    .v-tab {
        border-bottom: 1px solid $border-color;
    }
}
</style>