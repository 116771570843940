<template>
    <v-dialog v-model="ticketSalesDialog"  v-if="ticketSalesDialog" persistent scrollable max-width="700">
        <v-card id="ticketsales-dialog" v-if="ticket">
            <v-card-title class="header">Sales: {{ ticket.name }}</v-card-title>
            <div class="ticket-sales-wrapper" v-if="local_ticket">
                <v-col v-for="item in local_ticket.ticket_sales" :key="item.ticket_salesid" cols="12" md="12" class="pt-0 pb-0 single-ticket">
                    <v-row>
                        <v-col cols="12" md="2" class="d-flex align-center">
                            <span class="input-date">{{ item.date }}</span>
                        </v-col>
                        <v-col cols="12" md="10">
                            <input
                                v-model="item.amount"
                                type="number"
                                @change="$emit('update-ticket-sale', local_ticket)"
                                class="input-amount"
                                :disabled="apiChosen"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small text @click="$emit('cancel')">Cancel</v-btn>
                <v-btn small depressed color="primary" @click="saveChanges()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import TicketObject from "@/models/TicketObject";

export default {
    name: "ticketsalesdialog",
    props: {
        ticketSalesDialog: Boolean,
        ticket: TicketObject,
    },
    watch: {
        ticketSalesDialog() {
            this.local_ticket = Object.assign(Object.create(Object.getPrototypeOf(this.ticket)), this.ticket);
            //this.local_ticket = JSON.parse(JSON.stringify(this.ticket))
            console.log(this.local_ticket);
        },
    },
    data: () => ({
        local_ticket: null,
        dataIsReady: false,
    }),
    async created() {
		console.log('as90+hda90+sdh')
        console.log(this.ticket);

        // await this.setSalesArray();
        // this.checkIfApiSet();

        // this.salesArray = this.setMissingDates(
        // 	this.currentSelectedConcert.item.dev_min_date.substring(0, 10),
        // 	this.currentSelectedConcert.item.dev_max_date.substring(0, 10),
        // 	this.salesArray
        // );

        // console.log(this.currentSelectedConcert)
        // this.salesArray = this.setMissingDates(this.mindate.toISOString().substring(0,10), this.maxdate.toISOString().substring(0,10), this.salesArray);
    },
    methods: {
        closeTicketSalesDialog() {
            this.$emit("closeTicketSalesDialog");
        },
        async saveChanges() {
            await this.local_ticket.updateTicketSales();
            this.$emit("saved");
            console.log("saVEDOAS DNO");
        },
        // checkIfApiSet() {
        // 	this.currentSelectedConcert.item.tickets.map(ticket => {
        // 		if (ticket.ticketid === this.currentSelectedConcert.ticketid) {
        // 			if (ticket.ticket_apiid !== null) {
        // 				this.apiChosen = true;
        // 			}
        // 		}
        // 	});
        // 	console.log(this.apiChosen);
        // },
        // pushTicketsToCorrectArray() {
        // 	this.salesArray.map(ticket => {
        // 		if (ticket.ticket_salesid > 0) {
        // 			this.putArr.push(ticket);
        // 		} else {
        // 			this.postArr.push(ticket);
        // 		}
        // 	});
        // 	this.saveTickets();
        // },
        // async saveTickets() {
        // 	console.log(this.putArr);
        // 	console.log(this.postArr);
        // 	try {
        // 		let response = await this.$PutService(this.$getRoute("tickets").sales, this.putArr);
        // 	} catch (err) {
        // 		console.log(err);
        // 	}
        // 	try {
        // 		let response = await this.$PostService(
        // 			this.$getRoute("tickets").sales,
        // 			this.postArr
        // 		);
        // 		console.log(response);
        // 		if (response.status === 200) {
        // 			this.$emit("getTicketData");
        // 			this.$emit("closeTicketSalesDialog");
        // 		}
        // 	} catch (err) {
        // 		console.log(err);
        // 	}
        // },
        // setSalesArray() {
        // 	this.salesArray = [];
        // 	this.currentSelectedConcert.item.tickets.map(item => {
        // 		if (item.ticket_sales) {
        // 			if (item.ticketid === this.currentSelectedConcert.ticketid)
        // 				this.salesArray = JSON.parse(JSON.stringify(item.ticket_sales));
        // 		}
        // 	});
        // 	if (this.salesArray === undefined) this.salesArray = [];
        // 	else this.salesArray.sort((a, b) => new Date(a.date) - new Date(b.date));
        // },
        // closeTicketSalesDialog() {
        // 	this.$emit("closeTicketSalesDialog");
        // },
        // pushDateIfSet(...dates) {
        // 	let dateArray = [];
        // 	dates.map(date => (date ? dateArray.push(new Date(date)) : null));
        // 	return dateArray;
        // },
        // dateExist(date) {
        // 	date = date.toISOString().substring(0, 10);
        // 	if (this.salesArray.length === 0) return true;
        // 	this.salesArray.map(item => {
        // 		if (item.date === date) {
        // 			return true;
        // 		} else {
        // 			return false;
        // 		}
        // 	});
        // },

        // setMissingDates(fromdate, todate, salesArray) {
        // 	//Convert dates from ISOStrings to date objects, set time to 12:00:00 to avoid daylight savings issues
        // 	var date = new Date(Date.parse(fromdate + " 12:00:00"));
        // 	var todate = new Date(Date.parse(todate + " 12:00:00"));
        // 	var isodate = "";
        // 	var dateArray = [];
        // 	var ticketid = this.currentSelectedConcert.ticketid;
        // 	//Fill dateArray with existing dates
        // 	for (let index = 0; index < salesArray.length; ++index)
        // 		dateArray.push(salesArray[index].date);

        // 	do {
        // 		//convert date to ISOString
        // 		isodate = date.toISOString().substring(0, 10);
        // 		//If date is missing, push object to salesArray for missing date
        // 		if (dateArray.indexOf(isodate) == -1)
        // 			salesArray.push({ date: isodate, amount: "0", ticketid: ticketid, db_key: `${isodate}_${ticketid}` });

        // 		//Add one day to date
        // 		date.setDate(date.getDate() + 1);
        // 	} while (date <= todate);

        // 	salesArray.sort((a, b) => new Date(b.date) - new Date(a.date));

        // 	return salesArray;
        // }
    },
};
</script>

<style lang="scss" scoped>
#ticketsales-dialog {
    .header {
        font-size: 15px !important;
        text-transform: uppercase;
        color: $accent-color;
        font-weight: 600 !important;
        border-bottom: 1px solid $border-color;
        padding-bottom: 15px;
        z-index: 2;
        box-shadow: 0px 2px 8px #00000030;
    }
    .input-amount {
        border: 1px solid $border-color;
        padding: 6px 12px;
        border-radius: 2px;
        width: 100%;
        font-size: 13px;
        background: #fff;
    }
    .input-date {
        font-size: 13px;
        color: $accent-color;
        font-weight: 600;
    }
    .ticket-sales-wrapper {
        max-height: 600px;
        overflow: auto;
        padding: 10px 10px;
    }
    .v-card__actions {
        z-index: 1;
        box-shadow: 0px -3px 8px #00000030;
    }
    .single-ticket {
        &:hover {
            background: rgba(33, 150, 243, 0.1) !important;
        }
    }
}
</style>
