<template>
    <v-col cols="12" md="12" id="date-picker" class="d-flex justify-center">
        <div class="float-left" style="width:100%;">
            <v-btn
                @click="$emit('selectYear', dateSelected.year() - 1)"
                :class="{ 'mb-3': isMobile }"
                small
                :block="isMobile"
                color="primary"
                depressed
            >
                <v-icon class="mdi mdi-arrow-left-bold" small left></v-icon>
                {{ dateSelected.year() - 1 }}
            </v-btn>
            <v-btn-toggle dense v-model="buttonSelector" color="primary" :class="!isMobile ? 'ml-3 mr-3' : 'd-flex'">
                <v-btn :value="1" :style="isMobile ? 'flex:1' : ''" @click="$emit('selectMonth', 1)" small> Spring {{ dateSelected.year() }} </v-btn>
                <v-btn :value="5"  :style="isMobile ? 'flex:1' : ''" @click="$emit('selectMonth', 5)" small> Summer {{ dateSelected.year() }} </v-btn>
                <v-btn :value="9"  :style="isMobile ? 'flex:1' : ''" @click="$emit('selectMonth', 9)" small> Fall {{ dateSelected.year() }} </v-btn>
            </v-btn-toggle>
            <v-btn
                @click="$emit('selectYear', dateSelected.year() + 1)"
                :class="{ 'mt-3': isMobile }"
                small
                :block="isMobile"
                color="primary"
                depressed
            >
                {{ dateSelected.year() + 1 }}
                <v-icon class="mdi mdi-arrow-right-bold" small right></v-icon>
            </v-btn>
        </div>
    </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "calendarpicker",
    props: {
        dateSelected: Object,
    },
    mixins: [
        require("./SalesTableGeneralFunctionsMixins.vue"),
    ],
    data: () => ({
        buttonSelector : Number
    }),
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
    created() {
        this.buttonSelector = this.getCurrentSeasonMonth()
    },
};
</script>

<style lang="scss" scoped></style>
