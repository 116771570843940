<template>
    <div v-on-clickaway="hideWall">
        <v-btn class="menu-action-icon" v-if="!isDrawersDisabled" color="primary" :class="{ active: showWall }" @click="toggleWall" icon>
            <v-icon>mdi-wechat</v-icon>
        </v-btn>
        <div class="wall-container" v-show="showWall" @scroll="onScroll" :class="{ mobile: isMobile }">
            <div
                v-if="showWall"
                style="
                    height: calc(100vh - 70px);
                    float: left;
                    width: 100%;
                    overscroll-behaviour: contain;
                "
            >
                <div class="wall-header">
                    <span>Wall</span>
                </div>
                <div class="wall-comment-wrapper">
                    <div class="input-container">
                        <v-text-field
                            class="commentinput"
                            type="text"
                            v-model="commentInput"
                            placeholder="Write a comment"
                            outlined
                            dense
                            counter="280"
                            :disabled="isWallDataPending"
                        ></v-text-field>
                        <v-btn small @click="showEmojis = !showEmojis" icon>
                            <v-icon>insert_emoticon</v-icon>
                        </v-btn>
                        <VEmojiPicker
                            style="border:1px solid red;"
                            v-if="showEmojis"
                            @select="selectEmoji"
                            class="emoji-picker"
                            v-on-clickaway="hideEmojis"
                            tabindex="-1"
                            autofocus
                        />
                    </div>
                    <v-btn
                        :disabled="!commentInput || commentInput.length > 280"
                        :loading="isSaveCommentPending"
                        class="send-btn"
                        color="primary"
                        small
                        text
                        @click="postCommentOnWall"
                        >Post</v-btn
                    >
                </div>

                <v-col cols="12" md="12" class="float-left wall-item-container" style="overflow: hidden">
                    <Loader :loaderStyle="'linear'" v-if="isWallDataPending" />
                    <div v-if="noWallItems" class="no-content">
                        <span>
                            Wall is empty
                            <v-icon>mdi-emoticon-sad-outline</v-icon>
                        </span>
                    </div>
                    <div>
                        <WallItem
                            v-for="item in items"
                            :key="item.id"
                            :title="item.title"
                            :date="item.date"
                            :fullname="item.userid"
                            :walltype="item.wall_type"
                            :comment="item.text"
                        />
                    </div>
                </v-col>
            </div>
        </div>
    </div>
</template>

<script>
import WallItem from "./WallItem";
import Loader from "@/components/Loader";
import { mapGetters } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
import { unemojify, emojify } from "node-emoji";

import { directive as onClickaway } from "vue-clickaway";
import Notice from "@/models/Notice";

export default {
    name: "wall",
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        WallItem,
        Loader,
        VEmojiPicker,
    },
    props: {
        view: String,
        isDrawersDisabled: Boolean,
    },
    data: () => ({
        commentInput: "",
        locked: true,
        showWall: false,
        refreshIsPending: false,
        items: [],
        noWallItems: false,
        isWallDataPending: true,
        noContent: false,
        isSaveCommentPending: false,
        showEmojis: false,
        originalData: [],
        refreshid: "",
        loadingValue: null,
        lazyIsActive: [],
        lazyReady: false,
        counter: 0,
        globalDate: "1970-01-01 00:00:00",
        dataisLoading: false,
    }),
    watch: {
        showWall() {
            if (!this.showWall) {
                this.items = [];
                this.commentInput = "";
                this.showEmojis = false;
                this.isWallDataPending = true;
                this.noWallItems = false;
                this.counter = 0;
                clearInterval(this.refreshid);
            }
        },
    },
    created() {},
    methods: {
        async getWallData() {
            this.isWallDataPending = true;
            const limit = 15;
            try {
                let response = await this.$GetService(
                    `${this.$getRoute("wall").main_route}${this.$route.path.includes("concert") ? "?concertid=" : "?tourid="}${
                        this.$route.params.id
                    }&limit=${this.counter},${limit}`
                );
                this.counter += limit;
                if (response.status === 200) {
                    // this.sendUpdatedDataToConcertPage(response.data)
                    response.data.map((comment) => (comment.text = emojify(comment.text)));
                    if (this.counter === limit) {
                        this.globalDate = response.data[0].date;
                        this.items = response.data;
                    } else {
                        console.log("not limit");
                        this.items.push(...response.data);
                    }

                    this.isWallDataPending = false;
                } else if (response.status === 204) {
                    console.log("INGA HITTADE");

                    this.isWallDataPending = false;
                    this.noWallItems = true;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async refreshWall() {
            console.log('refresh')
            try {
                let response = await this.$GetService(
                    `${this.$getRoute("wall").main_route}${this.$route.path.includes("concert") ? "?concertid=" : "?tourid="}${
                        this.$route.params.id
                    }&from=${this.globalDate}`
                );
                if (response.status === 200) {
                    const ids = this.items.map((item) => item.wall_eventid);

                    const items = response.data.filter((item) => !ids.includes(item.wall_eventid));

                    items.forEach((item) => (item.text = emojify(item.text)));

                    this.items.unshift(...items);

                    this.globalDate = response.data[0].date;
                }
            } catch (error) {
                console.log(error);
            }
        },
        toggleWall() {
            this.showWall = !this.showWall;

            if (this.showWall) {
                this.getWallData();
                this.checkForNewData();
            }
        },
        async checkForNewData() {
            if (this.showWall) {
                this.refreshid = setInterval(async () => {
                    await this.refreshWall();
                }, 10000);
            }
        },
        hideWall() {
            this.showWall = false;
        },
        hideEmojis() {
            this.showEmojis = false;
        },
        async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (!this.noWallItems && !this.dataIsLoading) {
                if (scrollTop + clientHeight >= scrollHeight * 0.9) {
                    this.dataIsLoading = true;
                    await this.getWallData();
                    this.dataIsLoading = false;
                }
            }
        },
        getInitials() {
            if (this.userInfo.fullname) {
                let fullname = this.userInfo.fullname.split` `;
                let first = fullname[0][0];
                let second = fullname[1][0];
                let initials = `${first}${second}`;
                return initials;
            }
        },
        selectEmoji(emoji) {
            this.commentInput += `${emoji.data}`;
        },

        async addNotice(ID, ROUTE) {
            let userids = [];
            let objectName = "";

            let pageObject = await this.$ObjectFactory.BuildObject({ endpoint: ROUTE });
            await pageObject.fetchObject(ID);

            if (this.$route.name == "concertpage") {
                userids = [...new Set(pageObject.getValuesInObjects("userid"))];
                objectName = pageObject.title;
            } else if (this.$route.name == "tourpage") {
                let tmpObject = await this.$ObjectFactory.BuildObject({
                    endpoint: "concerts",
                });
                for (let concert of pageObject.concerts) {
                    await tmpObject.setObjectData(concert);
                    userids = userids.concat(tmpObject.getValuesInObjects("userid"));
                }

                userids = [...new Set(userids)];
                objectName = pageObject.name;
            }

            let noticeObj = await Notice.Build();

            for (let user of userids)
                noticeObj.postNotice({
                    ownerid: user,
                    type: "added a comment",
                    creator: this.userInfo.userid,
                    object: objectName,
                    link: `${ROUTE}/edit/${ID}`,
                });
        },

        async postCommentOnWall() {
            // add notices for concert or tour
            if (this.$route.name == "concertpage" || this.$route.name == "tourpage") {
                const ID = this.$route.params.id;
                const ROUTE = this.$route.name.replace("page", "s");
                this.addNotice(ID, ROUTE);
            }

            let postObj = {
                title: "added a comment",
                text: this.commentInput,
                wall_type: "comment",
            };

            let response = await this.$wallHandler({
                text: this.commentInput,
                walltype: "comment",
                route: this.$route,
            });

            if (response.status === 200) {
                postObj.wall_eventid = response.data.wall_eventid;
                this.items.unshift(postObj);
                this.checkForNewData();
                this.commentInput = "";
                postObj.date = "Right now";
                postObj.userid = this.userInfo.fullname;
                postObj.text = emojify(postObj.text);
                this.isSaveCommentPending = false;
            }
        },
        closeDrawer() {
            this.$emit("closeWallDrawer");
        },
    },
    computed: {
        ...mapGetters({
            userInfo: "user/userInfo",
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
.active {
    background: #306aa11c;
}

.menu-action-icon {
    width: 35px !important;
    height: 35px !important;
    position: relative;
    i {
        font-size: 25px !important;
    }
}

.emoji-picker {
    &::v-deep #Emojis {
        font-family: none !important;
        .container-emoji {
            overscroll-behavior: contain;
        }
    }
}
.wall-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    z-index: 200;
    background: #fff;
    position: absolute;
    right: 20px;
    top: 47px;
    width: 450px;
    box-shadow: 0px 4px 9px 0px #00000047;
    border-radius: 10px;
    overscroll-behavior: contain;
}

.mobile .wall-container {
    width: calc(100% - 40px);
    max-width: 380px;
}

.no-content {
    height: 100%;
    display: flex;
    span {
        font-weight: 600;
        margin: auto;
        color: $font-color-pale;
        i {
            color: $accent-color !important;
            margin-left: 10px;
        }
    }
}

.wall-header {
    font-size: 1rem;
    color: $font-color;
    font-weight: 600;
    width: 100%;
    padding: 15px;
    display: flex;
    background: #fff;
    align-items: center;
    .close-drawer {
        i {
            transform: rotate(180deg);
            margin-right: 3px;
        }
    }
}
.wall-comment-wrapper {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    padding: 0px 15px;
    z-index: 1;
    position: relative;
    .input-container {
        flex: 1;
        display: flex;
        padding-right: 10px;
        position: relative;
        button {
            position: absolute;
            top: 7px;
            right: 20px;
        }

        input {
            flex: 1;
            max-height: 45px !important;
            height: 35px !important;
            min-height: 35px !important;
            border-radius: 3px;
            padding: 0px 45px 0px 0px;
            font-size: 14px;
        }
    }
    .send-btn {
        height: 35px !important;
        i {
            font-size: 22px !important;
        }
    }
}

.v-lazy {
    float: left;
}

.emoji-picker {
    position: absolute;
    top: 50px;
    z-index: 999;
}

.mobile .emoji-picker {
    left: -10px !important;
}
</style>
