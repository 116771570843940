<template>
   <v-overlay v-if="show" style="z-index: 10;">
      <v-card color="" min-width="250">
         <v-card-text class="overlay-title pa-5">
            {{ text }}
            <v-icon color="success" v-if="!isLoading">done</v-icon>
         </v-card-text>
         <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
      </v-card>
   </v-overlay>
</template>

<script>
export default {
   name: 'overlaycard',
   props: {
      text: String,
      show: Boolean,
      isLoading: Boolean,
   }
}
</script>

<style lang="scss">

.overlay-title{
	font-size:20px;
	text-transform: uppercase;
	font-weight:350;
	text-align:center;
	color:#2c323a;
}
</style>