<template>
	<div id="budgetpage">
		<!-- <v-progress-circular class="primary--text" v-show="!isWizardLoaded" :active="!isWizardLoaded" indeterminate/> -->
		<v-progress-linear :active="!isWizardLoaded || !isDataLoaded" indeterminate />

		<!-- SAVE OVERLAY -->
		<v-overlay v-if="isCurrentlySaving">
			<v-card color min-width="250">
				<v-card-text class="overlay-title pa-5">
					{{ savingText }}
					<v-icon color="success" v-if="savingText.includes('saved')">done</v-icon>
				</v-card-text>
				<v-progress-linear v-if="savingText.includes('saving')" indeterminate></v-progress-linear>
			</v-card>
		</v-overlay>

		<div
			class="budgetpage-container float-left"
			:class="{ expand: budgetContainerIsExpanded }"
			v-show="isWizardLoaded && isDataLoaded"
		>
			<v-container class="budget-header-container">
				<h1>{{ title }}</h1>
				<span class="budget-status-text">{{ budgetStatusText }}</span>
			</v-container>

			<!---->
			<div class="budget-header-locked-container pa-8"  v-if="budgetStatusText === 'Locked'"  >
				<h1>Budget is locked</h1>
				<h3>No changes made will be saved</h3>
			</div>

			<concert-budget-wizard
				v-if="isDataLoaded && budgetType == 'concert'"
				:style="{'padding-top': budgetStatusText == 'Locked' ? '0px' : '60px'}"
				:budgetData="budgetData"
				:userid="userid"
				:concertDate="apiObj.date"
				@isLoaded="isWizardLoaded = true"
				@setPrivate="setPrivate"
				@setApprovalUser="setApprovalUser"
				@setGroup="setGroup"
				@setBudgetStatus="setBudgetStatus"
				@setConcertWaitingForApproval="setWaitingForApproval"
				@changesMade="(change) => {isBudgetChanged = change}"
			/>

			<tour-budget-wizard
				v-if="isDataLoaded && budgetType == 'tour'"
				:style="{'padding-top': budgetStatusText == 'Locked' ? '0px' : '60px'}"
				:budgetData="budgetData"
				:dataInSteps="dataInSteps"
				:userid="userid"
				:calculationsDone="calculationsDone"
				@isLoaded="isWizardLoaded = true"
				@setPrivate="setPrivate"
				@setApprovalUser="setApprovalUser"
				@setGroup="setGroup"
				@setBudgetStatus="setBudgetStatus"
				@setConcertWaitingForApproval="setWaitingForApproval"
				@changesMade="(change) => {isBudgetChanged = change}"
			/>

			<budget-button-menu
				v-if="isDataLoaded && budgetType == 'tour'"
				:budgetData="budgetData"
				:userid="userid"
				:savingAllowed="savingAllowed"
				@saveBudget="saveBudget"
				@saveNewBudget="saveNewBudget"
				@sendForApproval="sendForApproval"
				@setApprovedOrDeclined="setApprovedOrDeclined"
			/>
		</div>

		<div
			class="budgetpage-summary elevation-1"
			v-show="isWizardLoaded"
			:class="{hide: hideSummary}"
			:style="{top: $vuetify.breakpoint.mdAndDown ? `50px` : ''}"
			
		>
			<concert-budget-summary 
				v-if="isWizardLoaded && budgetType == 'concert'" 
				class="pa-0"
			/>
			<tour-budget-summary
				v-if="isWizardLoaded && calculationsDone && budgetType == 'tour'"
				class="pa-0"
				:dataInSteps="dataInSteps"
				:concertRevenueItems="tourInfo.revenueItems"
				:concertCostItems="tourInfo.costItems"
			/>
		</div>
		<delete-dialog
			@deleteFromDialog="(item) => { updateBudgetDataFromChild(item, true); showDeleteItemDialog = false;}"
			@cancelDialog="showDeleteItemDialog = false"
			:dialog="showDeleteItemDialog"
			:item="itemForDeleteDialog.item"
			:name="itemForDeleteDialog.name"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import BudgetButtonMenu from "./Shared/BudgetButtonMenu";
import ConcertBudgetWizard from "./ConcertBudgetWizard/ConcertBudgetWizard";
import ConcertBudgetSummary from "./BudgetSummary/ConcertBudgetSummary";
import TourBudgetWizard from "./TourBudgetWizard/TourBudgetWizard";
import TourBudgetSummary from "./BudgetSummary/TourBudgetSummary";
import DeleteDialog from "@/components/Dialogs/DeleteDialog";
import Loader from "@/components/Loader";
import ApiObject from "@/models/ApiObject";
import Notice from "@/models/Notice";

export default {
	name: "budget",

	components: {
		BudgetButtonMenu,
		ConcertBudgetWizard,
		ConcertBudgetSummary,
		TourBudgetWizard,
		TourBudgetSummary,
		DeleteDialog,
		Loader
	},

	data: () => ({
		isCurrentlySaving: false,
		savingText: "",
		isExpanded:false,
		isDataLoaded: false,
		isWizardLoaded: false,
		calculationsDone: false,
		wizardCalculationCount: 0,
		savingAllowed: false,
		budgetContainerIsExpanded: false,
		budgetData: {},
		originalData: {},
		rowsToAdd: [],
		rowsToDelete: [],
		title: "",
		budgetStatusText: "",
		budgetTypeid: "",
		budgetType: "",
		isBudgetChanged: false,
		newItemCount: 0,
		hideSummary:false,
		// delete dialog
		showDeleteItemDialog: false,
		itemForDeleteDialog: { item: {}, name: "" },

		tourInfo: {},

		step3PeopleBind: {},

		apiObj: null,
		noticeObj: null,
	}),

	async created() {
		this.checkIfMobile()
		this.$bus.$on("tourBudgetUpdatedData", this.updateBudgetDataFromChild);
		this.$bus.$on("deleteBudgetItem", this.openDeleteDialog);
		this.$bus.$on("addBudgetItem", this.addBudgetItem);
		this.$bus.$on("setExpandConcertBudgetContainer", this.expandBudgetContainer);
		this.$bus.$on("setExpandTourBudgetContainer", this.expandBudgetContainer);
		await this.init();
		this.$logger("budgetData in bpage", this.budgetData);
		this.isBudgetLoaded = true;
		
	},
	computed: {
		dataInSteps() {
			//console.log("### datainsteps compute ###");

			// referencing this will force the computed property to clear its cache and renew whenever the wizard is loaded
			this.isWizardLoaded;

			if (!this.budgetData.budget_rows) throw Error("No budget_rows data");
			let stepsObj = {};
			for (const data of this.budgetData.budget_rows) {
				const DEEPCOPY = JSON.parse(JSON.stringify(data));

				if (!stepsObj[`step${DEEPCOPY.step}`]) stepsObj[`step${DEEPCOPY.step}`] = {};

				if (!stepsObj[`step${DEEPCOPY.step}`][`${DEEPCOPY.header}`])
					stepsObj[`step${DEEPCOPY.step}`][`${DEEPCOPY.header}`] = [];

				stepsObj[`step${DEEPCOPY.step}`][`${DEEPCOPY.header}`].push(DEEPCOPY);
			}
			return stepsObj;
		},

		...mapGetters({
			userInfo: "user/userInfo"
		})
	},

	watch: {
		budgetData: {
			handler(val) {
				if (!this.isDataLoaded) return;
				if (JSON.stringify(val) == this.originalData) {
					console.log("budgetData is NOT changed");
					this.savingAllowed = false;
				} else {
					console.log("budgetData IS changed");
					this.savingAllowed = true;
				}
			},
			deep: true
		},
		'$vuetify.breakpoint.mdAndDown':{
			handler: function(v){
				if(v){
					this.budgetContainerIsExpanded = true
					this.hideSummary = true
				} else {
					this.budgetContainerIsExpanded = false
					this.hideSummary = false
				}
			} 
		},
		calculationsDone(isDone) {
			if (isDone) this.originalData = JSON.stringify(this.budgetData);
		}
	},

	methods: {
		async init() {
			this.budgetData = await this.getGenericData(this.$getEditRoute(this.$route.path));
			await this.initBudgetData();

			// for notices
			this.apiObj = await this.$ObjectFactory.BuildObject({endpoint: this.budgetType + 's' });
			await this.apiObj.fetchObject(this.budgetTypeid);
			this.noticeObj = await Notice.Build();
			console.log(this.apiObj)

			console.log("init done");
			this.isDataLoaded = true;
		},

		async initBudgetData() {
			this.setBudgetInfo();

			this.tourInfo = {};

			if (this.budgetType == "tour") {
				this.tourInfo = await this.getTourInfo();
				this.budgetData.ticketCapacity = this.tourInfo.ticketCapacity;

				for (let row of this.budgetData.budget_rows) {
					if (row.num_concerts == "" || !row.num_concerts)
						row.num_concerts = this.tourInfo.numConcerts;
					row.num_people = !row.num_people ? "0" : row.num_people;
				}
			}
		},
		checkIfMobile(){
			console.log(this.$vuetify.breakpoint.mdAndDown)
			if(this.$vuetify.breakpoint.mdAndDown){
				
				this.budgetContainerIsExpanded = true;
				this.hideSummary = true;
			}
				
			
		},
		async getTourInfo() {
			let totalCapacity = 0;
			let revenueItems = [];
			let costItems = [];
			const ROUTE = this.$getRoute("concerts").main_route;
			const TOURID = this.budgetData.tourid.tourid;
			// collect concerts data
			const DATA = await this.getGenericData(
				`${ROUTE}?tourid=${TOURID}&inc=assoc&exclude_fields=tickets,wall_events`
			);
			// get latest budget
			if (DATA.length > 0) {
				for (let concert of DATA) {
					if (concert.budgets) {
						const TITLE = concert.title;

						let tempRevenueItem = {
							name: TITLE,
							revenues: [
								{ name: "Ticket sales", value: 0 },
								{ name: "Other revenues", value: 0 }
							]
						};

						const STATUS = Math.max.apply(
							Math,
							concert.budgets.map(budget => {
								return budget.status;
							})
						);
						const BUDGET = concert.budgets.find(budget => {
							return budget.status == STATUS;
						});

						// calculate ticket capacity and ticket sales
						if (BUDGET.budget_tickets) {
							BUDGET.budget_tickets.map(
								({ capacity, kills, comps, other, sellable_percentage, price }) => {
									const AVAILABLE = Math.round(
										(capacity - kills - comps - other) * sellable_percentage
									);
									totalCapacity += AVAILABLE;
									tempRevenueItem.revenues[0].value += AVAILABLE * +price;
								}
							);
						}

						// calculate other revenues
						if (BUDGET.budget_rows) {
							BUDGET.budget_rows.forEach(({ step, budget_row_typeid, value }) => {
								if (step == "1" && budget_row_typeid == "0")
									tempRevenueItem.revenues[1].value += +value;
							});
						}
						revenueItems.push(tempRevenueItem);

						// costs

						let tempCostItem = {
							name: TITLE,
							costs: [
								{ name: "Variable costs", value: BUDGET.variable_costs },
								{ name: "Production costs", value: BUDGET.production_costs },
								{ name: "Marketing costs", value: BUDGET.marketing_costs },
								{ name: "Talent costs", value: BUDGET.talent_costs },
								{ name: "General costs", value: BUDGET.general_costs }
							]
						};
						costItems.push(tempCostItem);
					}
				}
			}

			this.$logger("revenueItems", revenueItems);
			this.$logger("costItems", costItems);

			this.bindStepTwoHeaderWithThreeId();

			return {
				ticketCapacity: totalCapacity,
				numConcerts: DATA.length.toString(),
				revenueItems: revenueItems,
				costItems: costItems
			};
		},

		bindStepTwoHeaderWithThreeId() {
			for (let key in this.dataInSteps.step2) {
				this.dataInSteps.step2[key].forEach(row => {
					if (!this.step3PeopleBind[row.header]) {
						this.dataInSteps.step3["Hotel and Travel"].forEach((row3, index) => {
							if (row3.name.toLowerCase().includes(row.header.toLowerCase()))
								this.step3PeopleBind[row.header] = { index: index };
						});
					}
				});
			}
		},

		setBudgetInfo() {
			if (this.budgetData.concertid) {
				this.title = this.budgetData.concertid.title;
				this.budgetTypeid = this.budgetData.concertid.concertid;
				this.budgetType = "concert";
			} else if (this.budgetData.tourid) {
				this.title = this.budgetData.tourid.name;
				this.budgetTypeid = this.budgetData.tourid.tourid;
				this.budgetType = "tour";
			}
			switch (+this.budgetData.status) {
				case -1:
					this.budgetStatusText = "Locked";
					break;
				case 0:
					this.budgetStatusText = "Draft";
					break;
				case 1:
					this.budgetStatusText = "Awaiting approval";
					break;
				case 2:
					this.budgetStatusText = "Approved";
					break;
				default:
					break;
			}
			console.log("budgetinfo done");
		},

		addBudgetItem({ step, header, typeid }) {
			let item = {
				name: "",
				step: step,
				header: header,
				value: "0",
				num_concerts: this.tourInfo.numConcerts ? this.tourInfo.numConcerts : "0",
				num_people: "0",
				calculated_cost: 0,
				comment: null,
				budget_rowid: --this.newItemCount, // set temporary negative rowid to keep reactivity
				budget_row_typeid: typeid,
				budgetid: this.budgetData.budgetid
			};
			this.$set(this.budgetData.budget_rows, this.budgetData.budget_rows.length, item);
			this.rowsToAdd.push(item);
		},

		openDeleteDialog(item) {
			this.itemForDeleteDialog.item = item;
			this.itemForDeleteDialog.name =
				item.displayName == "" || !item.displayName ? item.name : item.displayName;
			this.showDeleteItemDialog = true;
		},

		updateBudgetDataFromChild(newData, isToBeDeleted = false) {
			//this.$logger('incomingData', newData);
			// incoming budget_row data
			if (newData.budget_rowid != undefined) {
				// find the real item with the incoming id
				if (!this.budgetData.budget_rows) throw Error("No budget_rows data");

				let item = this.budgetData.budget_rows.find(oldData => {
					return oldData.budget_rowid == newData.budget_rowid;
				});

				if (!item) return;

				if (isToBeDeleted) this.prepItemForDeletion(item);
				else {
					let diff = this.getStepTwoPeopleDiff(newData, item);

					for (let key in newData) item[key] = newData[key];

					if (
						(newData.num_people || newData.num_people == "") &&
						newData.header &&
						newData.step == "2"
					)
						this.setStep3People(item, diff);
				}

				// Let the calcluations be done before the summary presents it after the first load
				if (!this.calculationsDone) {
					this.wizardCalculationCount++;
					if (this.wizardCalculationCount == this.budgetData.budget_rows.length)
						// change to -1
						this.calculationsDone = true;
				}
			} else if (newData.isPrivate != undefined) {
				this.budgetData.private_userid = newData.isPrivate ? this.userInfo.userid : "";
			} else if (newData.accessGroup != undefined) {
				this.budgetData.private_accessgroup = newData.accessGroup;
			}
		},

		prepItemForDeletion(item) {
			if (item.step == "2" && (item.header == "Ensemble" || item.header == "Crew"))
				this.setStep3People(item, -item.num_people);

			this.$delete(this.budgetData.budget_rows, this.budgetData.budget_rows.indexOf(item));
			// new item added but was never saved to db
			if (item.budget_rowid < 0) this.$delete(this.rowsToAdd, this.rowsToAdd.indexOf(item));
			else this.rowsToDelete.push({ budget_rowid: item.budget_rowid });
		},

		getStepTwoPeopleDiff(newData, item) {
			let diff = 0;

			if (newData.num_people < 0) diff = -(Math.abs(newData.num_people) + +item.num_people);
			if (newData.num_people == "") diff = -item.num_people;
			else diff = +newData.num_people - item.num_people;

			return diff;
		},

		setStep3People(item, diff) {
			const STEPDATA = this.dataInSteps.step3["Hotel and Travel"];
			const INDEX = this.step3PeopleBind[item.header].index;

			let realItem = this.budgetData.budget_rows.find(data => {
				return data.budget_rowid == STEPDATA[INDEX].budget_rowid;
			});

			realItem.num_people = +realItem.num_people + diff;
		},

		async setPrivate(userid) {
			await this.putBudgetItems(this.$getRoute("budgets").main_route, {
				budgetid: this.budgetData.budgetid,
				private_userid: userid
			});
		},

		async setApprovalUser(userid) {
			await this.putBudgetItems(this.$getRoute("budgets").main_route, {
				budgetid: this.budgetData.budgetid,
				approval_userid: userid
			});

			if (userid != "") {
				await this.noticeObj.postNotice({
					ownerid: userid,
					type: "assigned a budget for your approval",
					creator: this.userInfo.userid,
					object: this.title,
					link: `${this.budgetType}s/${this.budgetTypeid}/budgets`
				});
			}
		},

		async setGroup(group) {
			await this.putBudgetItems(this.$getRoute("budgets").main_route, {
				budgetid: this.budgetData.budgetid,
				private_accessgroup: group
			});
		},

		async setBudgetStatus(budgetid, status) {
			let wallresponse;
			if (status === 2){
				wallresponse = await this.$wallHandler({
					walltype: "budget_approved",
					eventtype: this.budgetType,
					id: this.budgetTypeid
				});

				// post notice
				var uniqueUserIds = [];

				let concertObj = await this.$ObjectFactory.BuildObject({endpoint: 'concerts'});
				if(this.budgetType == 'tour'){
					var concertsUserIds = [];
					let i = 0;
					for(let concert of this.apiObj.concerts){
						if(i++ == 0) await concertObj.fetchObject(concert.concertid);
						else await concertObj.setObjectData(concert);
						concertsUserIds = concertsUserIds.concat([...new Set(concertObj.getValuesInObjects("userid"))]);
					}
					uniqueUserIds = [...new Set(concertsUserIds)];
				} else
					uniqueUserIds = [...new Set(this.apiObj.getValuesInObjects("userid"))];

				
				for (let userid of uniqueUserIds){
					await this.noticeObj.postNotice({
						ownerid: userid,
						type: "approved a budget",
						creator: this.userInfo.userid,
						object: this.title,
						link: `budgets/${this.budgetData.budgetid}`
					});
				}
			}

			if (status === 1)
				wallresponse = await this.$wallHandler({
					walltype: "budget_sent_for_approval",
					eventtype: this.budgetType,
					id: this.budgetTypeid
				});

			await this.putBudgetItems(this.$getRoute("budgets").main_route, {
				budgetid: budgetid,
				status: status
			});
		},

		async setWaitingForApproval(value) {
			let item = { waiting_for_budget_approval: value };
			item[`${this.budgetType}id`] = this.budgetTypeid;
			await this.putBudgetItems(this.$getRoute(`${this.budgetType}s`).main_route, item);
		},

		sendForApproval(userid) {
			if (userid) {
				this.setApprovalUser(userid);
				this.setBudgetStatus(this.budgetData.budgetid, 1);
				this.setWaitingForApproval(1);
				this.isBudgetChanged = false;
				this.$router.push(`/${this.budgetType}s/edit/${this.budgetTypeid}`);
			}
		},
		setApprovedOrDeclined(approve) {
			this.setApprovalUser(null);
			this.setWaitingForApproval(0);
			if (approve) {
				this.setBudgetStatus(this.budgetData.budgetid, 2);
				this.isBudgetChanged = false;
				setTimeout(() => {
					this.$router.push(`/${this.budgetType}s/edit/${this.budgetTypeid}`);
				}, 500);
			} else this.saveNewBudget(false);
		},

		/**
		 * The process of saving the budget data
		 */
		async saveBudget() {
			await this.toggleSavingOverlay(true);
			const ROWS_ROUTE = this.$getRoute("budgets").rows;
			const ROWS = !this.budgetData.budget_rows ? [] : this.budgetData.budget_rows;

			// post new rows into db
			if (this.rowsToAdd.length > 0) {
				for (let row of this.rowsToAdd) delete row.budget_rowid; // remove temporary id

				const freshIDs = await this.postBudgetItems(ROWS_ROUTE, this.rowsToAdd);
				let i = 0;
				for (let row of this.rowsToAdd) row.budget_rowid = freshIDs[i++];

				this.rowsToAdd = [];
			}

			// delete rows marked for deletion
			if (this.rowsToDelete.length > 0) {
				await this.deleteBudgetItems(ROWS_ROUTE, this.rowsToDelete);
				this.rowsToDelete = [];
			}

			// update existing rows
			await this.putBudgetItems(ROWS_ROUTE, this.budgetData.budget_rows);

			// set private and accessgroups
			await this.setPrivate(this.budgetData.private_userid);
			await this.setGroup(this.budgetData.private_accessgroup);

			let generalData = {};
			if (this.budgetType == "concert")
				generalData = await this.calculateVariousConcertTotals(this.budgetData.budgetid);
			else generalData = await this.calculateVariousTourTotals(this.budgetData.budgetid);

			await this.putBudgetItems(this.$getRoute("budgets").main_route, generalData);

			// updated date
			let date = new Date();
			await this.putBudgetItems(this.$getRoute("budgets").main_route, {
				budgetid: this.budgetData.budgetid,
				updated_date: `${date.toISOString().substr(0, 10)} ${date
					.toTimeString()
					.substr(0, 8)}`
			});

			// reset originalData
			this.originalData = JSON.stringify(this.budgetData);
			await this.toggleSavingOverlay(false);
		},

		async saveNewBudget(withChanges) {
			try {
				let response = await this.$PostService(this.$getRoute("budgets").main_route, {
					tourid: this.budgetType == "tour" ? this.budgetTypeid : 0,
					concertid: this.budgetType == "concert" ? this.budgetTypeid : 0,
					version: this.budgetData.version++
				});

				if (response.status == 200) {
					this.setWaitingForApproval(0);
					this.setBudgetStatus(this.budgetData.budgetid, -1);

					// copy over items to new budget
					await this.copyItemsToNewBudget(response.data.budgetid, withChanges);

					this.isBudgetChanged = false;

					this.$router.push(`/budgets/${response.data.budgetid}`);
				}
			} catch (error) {
				console.log(error);
			}
		},

		async copyItemsToNewBudget(budgetid, withChanges) {
			////console.log(budgetid)
			let tickets = [];
			let budgetRows = [];
			let newBudgetTickets = [];
			let newBudgetRows = [];
			let generalData = {};

			if (withChanges) {
				//await this.saveBudget();

				if (this.budgetType == "concert") {
					tickets = JSON.parse(JSON.stringify(this.budgetData.budget_tickets));
					generalData = await this.calculateVariousConcertTotals(budgetid);
				} else generalData = await this.calculateVariousTourTotals(budgetid);

				budgetRows = JSON.parse(JSON.stringify(this.budgetData.budget_rows));
			} else {
				const OLD_BUDGET = JSON.parse(this.originalData);
				this.$logger("OLD BUDGET", OLD_BUDGET);
				tickets = OLD_BUDGET.budget_tickets;
				budgetRows = OLD_BUDGET.budget_rows;

				if (this.budgetType == "concert") {
					generalData = {
						budgetid: budgetid,
						net_profit: OLD_BUDGET.net_profit,
						revenues: OLD_BUDGET.revenues,
						general_costs: OLD_BUDGET.general_costs,
						marketing_costs: OLD_BUDGET.marketing_costs,
						other_revenues: OLD_BUDGET.other_revenues,
						production_costs: OLD_BUDGET.production_costs,
						talent_costs: OLD_BUDGET.talent_costs,
						variable_costs: OLD_BUDGET.variable_costs,
						gross_ex_vat: OLD_BUDGET.gross_ex_vat,
						net_gross: OLD_BUDGET.net_gross,
						total_costs: OLD_BUDGET.total_costs,
						total_tickets: OLD_BUDGET.total_tickets
					};
				} else {
					generalData = {
						budgetid: budgetid,
						revenues: OLD_BUDGET.revenues,
						talent_costs: OLD_BUDGET.talent_costs,
						variable_costs: OLD_BUDGET.variable_costs,
						production_costs: OLD_BUDGET.production_costs,
						marketing_costs: OLD_BUDGET.marketing_costs,
						general_costs: OLD_BUDGET.general_costs,
						total_costs: OLD_BUDGET.total_costs,
						net_profit: OLD_BUDGET.net_profit
					};
				}
			}

			if (this.budgetType == "concert") {
				for (let ticket of tickets) {
					let ticketCopy = JSON.parse(JSON.stringify(ticket));
					ticketCopy.budgetid = budgetid;
					newBudgetTickets.push(ticketCopy);
				}
				await this.postBudgetItems(
					this.$getSubRoute("budgets", "tickets"),
					newBudgetTickets
				);
			}

			for (let budgetRow of budgetRows) {
				let budgetRowCopy = JSON.parse(JSON.stringify(budgetRow));
				budgetRowCopy.budgetid = budgetid;
				newBudgetRows.push(budgetRowCopy);
			}

			await this.postBudgetItems(this.$getSubRoute("budgets", "rows"), newBudgetRows);

			await this.putBudgetItems(this.$getRoute("budgets").main_route, generalData);
		},

		async calculateVariousConcertTotals(budgetid) {
			let other_revenues = 0;
			this.stepOneData.otherRevenues.map(rev => (other_revenues += rev.revenues));

			//update costs
			let variable_costs = 0;
			this.stepTwoData.costs.map(cost => (variable_costs += cost.calculated_cost));

			let production_costs = 0;
			this.stepThreeData.costs.map(cost => (production_costs += cost.calculated_cost));

			let marketing_costs = 0;
			this.stepFourData.costs.map(cost => (marketing_costs += cost.calculated_cost));

			let talent_costs = 0;
			this.stepFiveData.costs.map(cost => {
				if (cost.calculated_cost > talent_costs) talent_costs = cost.calculated_cost;
			});

			let general_costs = 0;
			this.stepSixData.costs.map(cost => (general_costs += cost.calculated_cost));

			return {
				budgetid: budgetid,
				variable_costs: variable_costs,
				production_costs: production_costs,
				marketing_costs: marketing_costs,
				talent_costs: talent_costs,
				general_costs: general_costs,
				other_revenues: other_revenues,
				gross_ex_vat: this.netRevenue,
				net_gross: this.netGross,
				total_costs: this.totalCosts,
				total_tickets: this.totalTickets,
				net_profit:
					this.netGross -
					production_costs -
					marketing_costs -
					talent_costs -
					general_costs,
				revenues: this.grossRevenue,
				break_even: this.ticketsToBreakEven
			};
		},

		getStepHeaderSum(step, header, valueKey = "calculated_cost") {
			let sum = 0;
			if (this.dataInSteps[step][header]) {
				for (const ITEM of this.dataInSteps[step][header]) sum += +ITEM[valueKey];
			}
			return sum;
		},
		getHighestTalentCost(step, header, valueKey = "calculated_cost") {
			let highestCost = 0;
			if (this.dataInSteps[step][header]) {
				for (const ITEM of this.dataInSteps[step][header])
					if (highestCost < ITEM[valueKey]) highestCost = +ITEM[valueKey];
			}
			return highestCost;
		},
		async calculateVariousTourTotals(budgetid) {
			let revenues = 0;
			for (const rev of this.tourInfo.revenueItems)
				revenues += +rev.ticketSales + +rev.otherRevenues;
			revenues += this.getStepHeaderSum("step1", "Tour revenues", "value");

			let talentCosts = this.getHighestTalentCost("step2", "Talent");
			let variableCosts =
				this.getStepHeaderSum("step2", "Crew") +
				this.getStepHeaderSum("step2", "Ensemble") +
				this.getStepHeaderSum("step3", "Hotel and Travel");
			let productionCosts = this.getStepHeaderSum("step4", "Tour production");
			let marketingCosts = this.getStepHeaderSum("step5", "Tour marketing");
			let generalCosts =
				this.getStepHeaderSum("step3", "Fixed Hotel and Travel") +
				this.getStepHeaderSum("step6", "Other");
			let totalCosts =
				talentCosts + variableCosts + productionCosts + marketingCosts + generalCosts;
			let netProfit = revenues - totalCosts;

			return {
				// fill up with tour data totals
				budgetid: budgetid,
				revenues: revenues,
				talent_costs: talentCosts,
				variable_costs: variableCosts,
				production_costs: productionCosts,
				marketing_costs: marketingCosts,
				general_costs: generalCosts,
				total_costs: totalCosts,
				net_profit: netProfit
			};
		},

		/**
		 * Update a budget item in the db
		 * param items is the array of new budget items
		 * param route is the api route
		 */
		async putBudgetItems(route, items) {
			// console.log('putBudget')
			this.$logger("put items", items);
			try {
				let response = await this.$PutService(route, items);
				console.log(response);
				if (response.status == 200) console.log("update of items successful");
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Post a budget item in the db
		 * param items is the array of new budget items
		 * param route is the api route
		 */
		async postBudgetItems(route, items) {
			// console.log('putBudget')
			this.$logger("post items", items);
			try {
				let response = await this.$PostService(route, items);
				console.log(response);
				if (response.status == 200) {
					console.log("posting of items successful");
					return response.data.budget_rowid;
				}
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Delete a budget item in the db
		 * param items is the array of new budget items
		 * param route is the api route
		 */
		async deleteBudgetItems(route, items) {
			// console.log('putBudget')
			this.$logger("delete items", items);
			try {
				let response = await this.$DeleteService(route, items);
				console.log(response);
				if (response.status == 200) console.log("deletion of items successful");
			} catch (error) {
				console.log(error);
			}
		},

		async getGenericData(route) {
			try {
				let response = await this.$GetService(route);
				console.log(response);
				if (response.status == 200) {
					return response.data;
				}
			} catch (error) {
				console.log(error);
			}
		},

		expandBudgetContainer() {
			this.hideSummary = !this.hideSummary
			if(!this.$vuetify.breakpoint.mdAndDown){
				this.budgetContainerIsExpanded = !this.budgetContainerIsExpanded
			}
			
		},

		async toggleSavingOverlay(val) {
			if (val) {
				this.isCurrentlySaving = true;
				this.savingText = "Budget is saving...";
			} else {
				this.savingText = "Budget saved!";
				// 1500 delay to show user it actually saved
				await setTimeout(() => {
					this.isCurrentlySaving = false;
					this.savingAllowed = false;
				}, 1500);
			}
		}
	},

	beforeRouteLeave(to, from, next) {
		if (this.isBudgetChanged && (this.budgetData.status != -1 || this.budgetData.status != 2)) {
			let response = window.confirm(
				"Do you really want to leave? You have potential unsaved changes."
			);
			if (response) next();
			else next(false);
		} else next();
	}
};
</script>

<style lang="scss" >
#budgetpage {
	height: 100%;
	float: left;
	width: 100%;
	padding: 0px;
}

.wizard-budget-container .v-input{
	*:disabled{
		color:#222!important;
	}
}

.budgetpage-container {
	width: calc(100% - 330px);
	transition:all 0.2s ease;
}

.budgetpage-container.mobile{
	width:100%!important;
}

.budget-header-container {
	align-items: center;
	position:fixed;
	display: flex;
	border-bottom: 1px solid $border-color;
	margin: 0px;
	max-width: 100% !important;
	background: #fff;
	z-index:2;
	padding: 11px 20px;
	h1 {
		color: $accent-color;
		font-size: 22px;
		font-weight: 600;
		margin-right: 10px;
	}
	.budget-status-text {
		font-size: 14px;
		text-transform: uppercase;
		color: #fff;
		font-weight: 600;
		padding: 4px 20px;
		border-radius: 20px;
		background-color: $accent-color;
		margin-left: 10px;
	}
	
}

.budget-header-locked-container {
	align-items: center;

	border-bottom: 1px solid $border-color;
	background-color: #cf2323;
	margin-top: 55px;
	max-width: 100% !important;
	h1 {
		color: #ffffff;
		font-size: 22px;
		font-weight: 600;
		margin-right: 10px;
	}
	h3 {
		color: #ffffff;
		font-size: 14px;
		font-weight: 400;
	}
	padding: 20px;
}

.expand {
	width: 100% !important;
}

.hide {
	margin-right:-326px;
}

.budget-header {
	font-size: 42px;
	color: #666;
	font-weight: 480;
	text-align: center;
}
.budget-subheader {
	font-size: 18px;
	color: #666;
	font-weight: 350;
	text-align: center;
}

.budgetpage-summary {
	top: 90px;
	right: 0px;
	z-index:4;
	width: 330px;
	bottom: 0;
	position: fixed;
	background: #11283e;
	overscroll-behavior: contain;
	transition:all 0.2s ease;
}

.budgetpage-summary.mobile{
	top:80px;
}

.expand-button {
	background: #f8f8f8;
	i {
		font-size: 18px !important;
	}
}

// Wizard css

.wizard-budget-container {
	float: left;
	width: 100%;
	padding: 0px;
	border-radius: 3px;
}

.wizard-section-title {
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: 600;
	color: $font-color-pale;
}



.wizard-container-title {
	font-size: 1.4rem;
	width:100%;
	border-bottom:1.5px solid $border-color;
	text-transform: uppercase;
	font-weight: 600;
	color: $font-color-pale;
	margin-bottom:10px;
}


.wizard-overlay-title {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 350;
	text-align: center;
	color: #2c323a;
}

.wizard-form-title {
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: 500;
	color: #8f8f8f;
}

.v-text-field__suffix {
	font-size: 14px !important;
}

input[type="number"] {
	text-align: right;
	font-size: 14px;
}

.wizard-form-text {
	font-weight: 400;
	color: #3c444d;
	font-size: 13px;

	input[type="text"] {
		text-align: left;
		font-size: 14px;
	}
	.v-select {
		text-align: left;
		font-size: 14px;
	}
	.number-text {
		input {
			text-align: right;
		}
	}
}
</style>