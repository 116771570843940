import store from '@/store/modules/metadata';

export default function getCountryList(){
    const raw_country_list = store.getters.raw_country_list(store.state);
    
    if(raw_country_list != null){
        let name_only = [];
        for(const country of raw_country_list)
            name_only.push(country.name)
        return name_only;
    }
}