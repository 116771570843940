import ApiObject from './ApiObject';
import dayjs from 'dayjs';

export default class TicketObject extends ApiObject {
    constructor() {
        super('tickets');
        this.sum_sales = [];
        this.total_sold = 0;
        this.ticketSalesGraphData = [];
    }

    async setSumSales() {
        if (!this.ticket_sales) return;
        let ticketSales = JSON.parse(JSON.stringify(this.ticket_sales))
        this.sum_sales = []
        let sum = 0;
        for (let sale of ticketSales.reverse()) {
            sum += +sale.amount;
            this.sum_sales.push(sum);
        }
        this.sum_sales.reverse();
        this.total_sold = this.sum_sales[0];
    }

    getChange() {
        return this.getSoldSum() - +this.sum_sales[1]; // // returns the difference between yesterdays sales and the day before yesterday sales
    }

    getGrossSales(minDate = null, maxDate = null) {
        let sold = (minDate && maxDate) ? this.getTotalSold(minDate, maxDate) : this.getSoldSum();
        return sold * +this.price;
    }

    getSoldPercentage(minDate = null, maxDate = null) {
        let sold = (minDate && maxDate) ? this.getTotalSold(minDate, maxDate) : this.getSoldSum();
        let percentage = Math.round(sold / this.available * 100);
        return isNaN(percentage) ? 0 : percentage;
    }

    getTotalSold(minDate, maxDate) {
        if (!this.ticket_sales) return 0;

        let sum = 0;
        for (let sale of this.ticket_sales) {
            let saleDate = new Date(sale.date);
            if (saleDate >= new Date(minDate) && saleDate <= new Date(maxDate)) {
                sum += +sale.amount;
            }
        }
        return sum;
    }

    getTicketSalesForGraph(minDate, maxDate, externalArray = null) {
        if (!this.ticket_sales) return;

        this.ticketSalesGraphData = [];

        for (let sale of this.ticket_sales.sort((a, b) => new Date(a.date) - new Date(b.date))) {
            let saleDate = new Date(sale.date);
            if (saleDate < dayjs() || +sale['amount'] > 0) { // dont allow sale dates in the future with a value of 0

                if (saleDate >= new Date(minDate) && saleDate <= new Date(maxDate)) {

                    if (externalArray) {
                        const saleObj = externalArray.find(innerSale => sale['date'] == innerSale[0])
                        if (saleObj)
                            saleObj[1] += +sale['amount'];
                        else
                            externalArray.push([sale['date'], +sale['amount']])
                    }

                    this.ticketSalesGraphData.push([sale['date'], +sale['amount']])  // Add new date with sales
                }
            }
        }

        return externalArray ? externalArray : this.ticketSalesGraphData;
    }

    getSoldSum(minusDays = 0) {
        return +this.sum_sales[minusDays];
    }

    async setSales(sales) {
        let ticket_sales = await this.objFactory.BuildCollection({ endpoint: 'ticket_sales' });
        await ticket_sales.addToCollection(sales);
        await ticket_sales.updateCollection();
    }

    setApiConnection(api_id) {
        this.ticket_apiid = api_id;
        this.updateObject(this);
    }

    clearApiConnection() {
        this.setApiConnection(null);
    }

    async updateTicketSales() {
        await ApiObject.PutGenericData('ticket_sales', this.ticket_sales);
    }

}