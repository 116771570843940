<template>
    <v-container class="wizard-budget-container container--fluid">
        <v-col cols="12" md="12" class="pa-0 mb-5">
            <h3 class="wizard-section-title">{{ header }}</h3>
        </v-col>
        <div class="budget-section-wrapper elevation-1">
			<div class="wizard-container-title">Type of {{ header }}</div>
            <div v-for="(val, key) in stepData" :key="key">
                <div v-for="item of stepData[key]" :key="item.budget_rowid">
                    <tour-revenue-form :item="item" />
                </div>
            </div>

            <v-col class="primary--text d-flex align-center pa-0 mt-3">
                <v-btn
                    depressed
                    @click.stop="$bus.$emit('addBudgetItem', { step: 1, header: 'Tour revenues', typeid: 0 })"
                    color="primary"
                >
                    <v-icon left>add</v-icon>
                    Add {{ header.toLowerCase() }}
                </v-btn>
            </v-col>
        </div>
    </v-container>
</template>

<script>
import TourRevenueForm from "./TourRevenueForm";

export default {
    name: "tourstepone",
    components: {
        TourRevenueForm,
    },
    props: {
        header: "",
        stepData: [],
    },
    data: () => ({
        reloadIndex: 0,
        colNames: ["Type", "Revenues"],
    }),
    created() {
        //this.$logger('stepData in stepOne', this.stepData);
    },
};
</script>

<style>
</style>