<template>
    <v-container class="wizard-budget-container container--fluid">
        <v-col class="pa-0 pb-5">
            <h3 class="wizard-section-title">{{ header }} costs</h3>
        </v-col>
        <v-spacer />
		<div class="budget-section-wrapper elevation-1">
			<div class="wizard-container-title">TYPE OF HOTEL AND TRAVEL COST</div>
			<div v-for="(val, key) in stepData" :key="key">

				<div class="wizard-form-text" v-for="item of stepData[key]" :key="item.budget_rowid">
					<tour-cost-form
						v-if="isDefault(item.name)"
						:costItem="item"
						:noDelete="true"
						:lockPeople="item.name.toLowerCase() == 'artist' || item.name.toLowerCase() == 'additional trips' ? false : true"
					/>

					<tour-fixed-cost-form v-else :costItem="item" />
				</div>
			</div>
			<v-col class="primary--text d-flex align-center pa-0 mt-3">
				<v-btn
					depressed
					@click.stop="$bus.$emit('addBudgetItem', { step: 3, header: `Fixed ${header}`, typeid: 1 })"
					color="primary"
				>
					<v-icon left>add</v-icon>
					Add additional {{ header.toLowerCase() }} cost
				</v-btn>
			</v-col>
		</div>
    </v-container>
</template>

<script>
import TourCostForm from "../Shared/TourCostForm";
import TourFixedCostForm from "../Shared/TourFixedCostForm";

export default {
    name: "tourstepthree",
    components: {
        TourCostForm,
        TourFixedCostForm,
    },
    props: {
        header: String,
        stepData: Array,
    },
    data: () => ({
        colNames: [
            [
                "Type",
                "Number of trips",
                "Cost per trip",
                "Number of people",
                "Calculated costs",
            ],
            ["Type", "", "Cost"],
        ],
    }),
    created() {
        //this.$logger('stepData in stepThree', this.stepData);
    },
    computed: {},
    methods: {
        isDefault(d) {
            d = d.toLowerCase();
            return (
                d == "artist" ||
                d == "ensemble and orchestra" ||
                d == "crew" ||
                d == "additional trips"
            );
        },
    },
};
</script>

<style>
</style>