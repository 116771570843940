<template>
	<div id="invoices">
		<v-col cols="12" md="12" class="elevation-1 pa-0">
			<ViewHeader :header="header"/>
			<BrowseTable :idpath="idpath" :view="this.$options.name" :noCreate="true"/>
		</v-col>
	</div>
</template>

<script>
	import ViewHeader from '@/components/ViewHeader'
	import BrowseTable from '@/components/Tables/BrowseTable/'
	export default {
		name:'invoices',
		components:{
			BrowseTable,
			ViewHeader
		},
		data:()=>({
			header:'Browse invoices',
			idpath:'invoiceid'
		})
	}
</script>

<style lang="scss" scoped>


</style>