export var labelRules = {
    artistid: 'Artist',
    venueid: 'Venue',
    organizationid: 'Organization',
    concertid: 'Concert',
    private_userid: 'Make Private',
    venue_typeid: 'Venue type',
    type_of_event: 'Type of Event',
    bpc_promoterid: 'BPC Promoter alias',
    bpc_talentid: 'BPC Talent ID',
    bpc_venueid: 'BPC Venue ID',
    oracleid: 'Oracle ID'
}

export var territory = [
    "Sverige",
    "Skandinavien",
    "Europa(inkl.Skandinavien)",
    "Europa(exkl.Skandinavien)",
    "Internationellt"
];

export var generalFormLists = {
    territory: territory
}

export var artistFormLists = {
    territory: territory
}

const config_loc = require("@/../public/config.json");
export var concertFormLists = {
    department: config_loc.DEPARTMENT_LIST,
    type_of_event: config_loc.TYPE_OF_EVENT_LIST,
    status: config_loc.STATUS_LIST,
    show_type: ["Own", "Collaboration", "Sold"],
    dinner: ["Buy out", "On site"],
    load_out: ["After gig", "After closing"],
    taxes_sold: ["Excl. 25%", "Excl. 6%", "Excl. Incl. 25%", "Incl. 6%"],
    percent_deal: [
        "Flat",
        "+70% after break even",
        "vs 60% of gross income",
        "vs 80% of net inocome"
    ],
    payment_condition: [
        "100 % av garantigaget skall enligt faktura vara leverantören tillhanda senast YYYY-MM-DD.",
        "100 % av garantigaget skall enligt faktura vara leverantören tillhanda senast 30 dagar innan genomförd konsert",
        "100 % av garantigaget skall enligt faktura vara leverantören tillhanda senast YYYY-MM-DD. Eventuellt procentutfall faktureras enligt avräkning första helgfria vardag efter konsert.",
        "50 % av garantigaget skall enligt faktura vara leverantören tillhanda senast YYYY-MM-DD. Resterande garantigage skall vara leverantören tillhanda senast YYYY-MM-DD.",
        "Procentutfall faktureras enligt avräkning första helgfria vardag efter spelat arrangemang.",
        "The promoter agrees to wire 100% of the guarantee fee, subject to tax, no later than YYYY-MM-DD.",
        "The promoter agrees to wire 100% of the guarantee fee, subject to tax, no later than YYYY-MM-DD. The Balance plus the potential % breakdown, subject to tax, are to be wired fist day after show."
    ],
    presale: ["Yes", "No", "TBC"],
}