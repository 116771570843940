<template>
	<div>
		<v-col cols="12" md="12" class="pa-0">
			<h3 class="wizard-container-title">Secret for others</h3>
			<v-col cols="12" md="12" class="pa-0 mb-5">
			<span>Only you will be able to see the budget if yes is selected.<br> Only you and the group will be able to see the budget if a group is selected.</span>
			<v-switch
				:disabled="selectedGroup != ''"
				:label="privateBudget ? 'Yes' : 'No'"
				v-model="privateBudget"
				hide-details
			/>
			</v-col>
		</v-col>
		<v-col cols="12" md="12" class="d-flex align-center pa-0">
			<v-select 
			class="float-left"
			dense
			label="Visible to"
			:items="userGroups"
			v-model="selectedGroup"
			style="max-width:300px;"
			:disabled="!privateBudget"
			filled
			hide-details
			/>
		<v-btn 
			:disabled="selectedGroup == ''"
			class="float-left ml-5"
			icon depressed color="error"
			@click.stop="selectedGroup = ''"
			>
			<v-icon>clear</v-icon>
			</v-btn>
		</v-col>
	</div>     
</template>

<script>
export default {
   name: 'budgetsecretform',
   props: {
      isPrivate: Boolean,
      userGroups: [],
      selectedGroup: String,
   },
   created() {
      this.privateBudget = this.isPrivate
      console.log('selectedGroup: ' + this.selectedGroup);
   },
   data: () => ({
      privateBudget: false,
   }),
   watch: {
      privateBudget(val){
         this.$emit('privateChange', this.privateBudget)
      },
      selectedGroup(val){
         val == '' ? this.privateBudget = false : this.privateBudget = true

         this.$emit('groupChange', this.selectedGroup)
      }
   },
}
</script>

<style>

</style>