<template>
    <v-dialog persistent absolute v-model="show" width="1000" transition="dialog-top-transition">
        <EmailDialog :showEmailDialog="showEmailDialog" @closeEmailDialog="closeEmailDialog" :emailTemplate="emailTemplate" />
        <v-card v-if="dataIsReady" class="tab-container container--fluid pa-0">
            <v-dialog v-model="sendingIsPending" hide-overlay persistent width="300">
                <v-card :color="documentSent ? '#31b008' : 'primary'" dark>
                    <div class="send-loader">
                        <span class="mb-3" style="text-align: center; font-size: 0.85rem">
                            {{ !documentSent ? documentPendingText : "Document sent!" }}
                        </span>
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </div>
                </v-card>
            </v-dialog>
            <v-col cols="12" md="12" class="pa-0">
                <div class="card-title">{{ docBrowser.title }}</div>

                <v-col cols="12" md="12" class="pa-0">
                    <generic-tab-browser
                        :tabData="docBrowser"
                        :singleSelect="true"
                        :type="'selection'"
                        @itemsSelected="(item) => (selectedItem = item)"
                        class="elevation-0"
                    />

                    <doc-menu-row
                        v-if="selectedItem && selectedItem.filetype != 'EMAIL'"
                        :users="users"
                        :agreed_with="agreed_with"
                        :isDocSelected="this.selectedItem != undefined"
                        @setRecipients="(recipientList) => (recipients = recipientList)"
                        @setOwner="(user) => (owner = user)"
                        @docusign="(sign) => (docusignSelection = sign)"
                    />
                    <email-menu-row
                        v-if="selectedItem && selectedItem.filetype === 'EMAIL'"
                        @addRecipient="(payload) => addRecipient(payload)"
                        @removeRecipient="(payload) => removeRecipient(payload)"
                        :recipients="emailOptions.recipients"
                    />

                    <NoDataPlaceholder :text="`No existing document templates.`" v-if="noDocTemplates" />
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="$emit('close')" small>Cancel</v-btn>
                        <v-btn
                            class="btn"
                            @click="getDocument()"
                            small
                            :disabled="emailOptions.recipients.length === 0 || emailOptions.subject === ''"
                            v-if="selectedItem && selectedItem.filetype === 'EMAIL'"
                            depressed
                            color="primary"
                            :loading="generateTemplatePending"
                            >Generate template</v-btn
                        >
                        <v-btn v-else small depressed  :disabled="!isDocumentReady" color="primary" @click="getDocument()">
                            <v-icon class="pr-2">{{
                                docusignSelection === "Yes" ? "mdi-file-send" : "mdi-download"
                            }}</v-icon>

                            <span>{{ docusignSelection === "Yes" ? "Send document" : "Download document" }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-col>
            </v-col>
        </v-card>
    </v-dialog>
</template>

<script>
/* MODELS */
import TabBrowser from "@/models/TabBrowser.js";

/* COMPONENTS */
import GenericTabBrowser from "../GenericTabBrowser";
import DocMenuRow from "./DocMenu/DocMenuRow";
import EmailMenuRow from "./DocMenu/EmailMenuRow";
import NoDataPlaceholder from "@/components/NoDataPlaceholder";
import EmailDialog from "@/components/Dialogs/EmailDialog";

export default {
    name: "docbrowser",
    components: {
        GenericTabBrowser,
        DocMenuRow,
        EmailMenuRow,
        NoDataPlaceholder,
        EmailDialog,
    },
    props: {
        show: Boolean,
        contentId: String,
        type: String,
        agreed_with: Object,
    },
    data: () => ({
        docBrowser: null,
        selectedTab: null,
        selectedItem: null,
        docusignSelection: null,
        noDocTemplates: false,
        dataIsReady: false,
        owner: null,
        recipients: [],
        sendingIsPending: false,
        documentSent: false,
        documentPendingText: "Sending document",
        showEmailDialog: false,
        emailOptions: {
            subject: "",
            recipients: [],
            mailToString: "",
        },
        mailTo: "",
        emailTemplate: "",
        generateTemplatePending: false,
    }),
    async created() {
        let rawItems = await this.getData(`documents?type=${this.type}`);
        this.docBrowser = new TabBrowser({
            title: "Documents",
            rowType: "file",
        });

        if (
            !this.docBrowser.generateTablesFromKeystring({
                keystring: "filetype",
                items: rawItems,
                inclHeaders: ["title", "created_date", "filetype"],
            })
        )
            this.noDocTemplates = true;

        this.dataIsReady = true;
    },

    computed: {
        isDocumentReady() {
            let isReady = this.selectedItem && this.docusignSelection;

            return isReady && (this.docusignSelection == "Yes" ? (this.owner?.email ? true : false) : true);
        },
    },
    methods: {
        addRecipient(payload) {
            this.emailOptions.recipients.push(payload.recipient);
            this.emailOptions.subject = payload.subject;
            this.emailOptions.mailToString += `${payload.recipient};`;
            console.log(this.emailOptions);
        },
        removeRecipient(payload) {
            this.emailOptions.mailToString = this.emailOptions.mailToString.replace(payload.recipient + ";", "");
            this.$delete(this.emailOptions.recipients, payload.index);
        },
        closeEmailDialog() {
            this.showEmailDialog = false;
        },
        async getDocument(callback) {
            if (this.selectedItem.filetype === "EMAIL") {
                this.generateTemplatePending = true;
                const response = await this.selectedItem.getDocument(this.contentId, sign);
                const createHtml = (response, html) => {
                    const reader = new FileReader();
                    reader.addEventListener("loadend", (e) => {
                        html(e.srcElement.result);
                    });
                    reader.readAsText(response.data);
                };

                createHtml(response, (generatedHtml) => {
                    this.emailTemplate = generatedHtml;
                    let link = document.createElement("a");
                    link.href = "mailto:" + this.emailOptions.mailToString + "?subject=" + this.emailOptions.subject + "&body=---- PASTE HERE ----";
                    link.click();
                    this.showEmailDialog = true;
                });
                this.generateTemplatePending = false;
                return;
            }

            this.sendingIsPending = true;

            this.documentPendingText = "Generating document";

            /**
             * Sign has to be declared to 0 or 1, to represent false or true
             */
            let sign = 0;

            if (this.docusignSelection == "Yes") {
                sign = 1;
                this.documentPendingText = "Sending document";
            }

            if (this.isDocumentReady) var documentData = await this.selectedItem.getDocument(this.contentId, sign);

            if (sign === 0) {
                this.$bus.$emit("resetDocMenu", null);
                this.selectedItem.downloadDocument(documentData);
                this.sendingIsPending = false;
                return;
            }

            var blobToBase64 = function(documentData, callback) {
                var reader = new FileReader();
                reader.onload = function() {
                    var dataUrl = reader.result;
                    var base64 = dataUrl.split(",")[1];
                    callback(base64, documentData.token);
                };
                reader.readAsDataURL(documentData.blob);
            };
            blobToBase64(documentData, (result, token) => {
                this.createGetAccept(result, token);
            });
        },

        async createGetAccept(data, token) {
            let document = {
                name: this.selectedItem.title,
                file_content: data,
                sender_id: this.owner.user_id,
                recipients: this.recipients,
                is_automatic_sending: true,
            };
            await this.postGetAcceptData(document, token);
            this.documentSent = true;
            setTimeout(() => {
                this.sendingIsPending = false;
                this.documentSent = false;
            }, 2000);
        },

        async getData(route) {
            try {
                let response = await this.$GetService(route);
                if (response.status == 200) return response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async postGetAcceptData(payload, token) {
            try {
                let response = await this.$GetAcceptPost(payload, token);
                console.log(response);
                if (response.status == 200) return response.data;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
#docbrowser {
    border-radius: 3px;
}
.tab-container {
    position: relative;
    border-radius: 3px;
    background: #fff;
}

.send-loader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}
</style>
