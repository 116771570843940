<template>
   <v-dialog v-model="dialog" persistent width="400">

      <v-card>
         <v-card-title primary-title>
            {{ text }}
         </v-card-title>
         <v-card-text>
            {{ subtext }}
            <v-select
               :items="items"
               item-text="fullname"
               item-value="userid"
               v-model="selectedItem"
            />
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDialog">Cancel</v-btn>
            <v-btn color="primary" text :disabled="selectedItem == 0" @click="confirmDialog">Send for approval</v-btn>
         </v-card-actions>
      </v-card>

   </v-dialog>
</template>

<script>

export default {
   props: {
      dialog: Boolean,
      text: String,
      subtext: String,
      items: Array,
   },

   data: () => ({
      selectedItem: 0
   }),

   methods:{

      confirmDialog(){
			this.$emit('confirmDialog', this.selectedItem)
      },

      cancelDialog(){
         this.$emit('cancelDialog')
      }
   }
}
</script>

<style lang="scss" >

</style>