<template>
   <div class="ticketcard">
         <v-row class="align-center">
            <v-col cols="3" md="3" class="pa-1">
               <div class="number float-right">{{ $thousandSeperator(price) }}<span>{{ currency }}</span></div>
            </v-col>
            <v-col cols="3" md="3" class="pa-1">
               <div >
                  <v-progress-linear rounded :height="10" :value="size" :background-color="color" color="#ccc"/>
               </div>
            </v-col>
            <v-col cols="3" md="3" class="pa-1">
               <v-progress-linear rounded :height="10" :value="percentage" :color="'#252f36'"/>
            </v-col>
            <v-col cols="3" md="3" class="pa-1">
               <div class="number">{{ $thousandSeperator(percentage) }} <span>%</span></div>
            </v-col>
            
         </v-row>
         
   </div>
</template>

<script>
   export default {
      name:'ticketscard',
      props:{
         title:String,
         currency: String,
         price:Number,
         percentage:Number,
         color: String,
         size: Array,
      },
      data: ()=>({
         height: 10,
      }),
   }
</script>

<style lang="scss" scoped>
   .ticketcard{
      position:relative;
      width:100%;
      display:flex;
      flex-direction: column;
      .number{
         font-size:1rem;
         font-weight:600;
         color:$font-color;
         margin:5px 0px;
         span{
            font-size:1rem;
            font-weight:400;
            color:$font-color-pale;
            text-transform:uppercase;
            margin-left:5px;
         }
      }
      .card-title{
         font-size:15px;
         text-transform: uppercase;
         font-weight:600;
         color:$font-color-pale;
         padding:10px 15px;
         border-bottom:1px solid $border-color;
         background:$card-title-bg;
      }
   }
</style>