<template>
    <div>
        <div class="info-text">
            The start page is made for you specifically to give you the best
            possible overview and navigate to the right place as quickly as
            possible. <br />The three KPI-boxes gives you some fun information
            about what’s going on right now in your country.<br />
            The comparison in percentage is based on the average for the last 12
            months.
        </div>
        <img src="/" />
        <div class="info-text">
            The “recent to-dos” is a list of your most recent to-do’s created in
            the system by you or by someone else but assigned to you. <br />They
            help you collaborate with your team and send and receive tasks
            without the need to send emails. <br />When a to-do is done, simply
            check the box or directly delete the to-do. <br />This list
            summarize all your to-do’s. You can see if they are general to-do’s
            or if they are linked to a specific concert or tour.
        </div>
        <img src="/" />
        <div class="info-text">
            The calendar is made to give you an overview of what’s going on
            right now, and what’s planned ahead in your country.<br />
            You can also filter on a specific department if needed. The colors
            in the calendar shows what type of status a concert has.
        </div>
        <img src="/" />
        <div class="info-text">
            The lists of upcoming releases and upcoming shows show the concerts
            you as a user is linked to and that are either soon going on-sale or
            that has a concert date soon.
        </div>
        <img src="/" />
        <img src="/" />
        <div class="info-text">
            The favorite list is a list of the items you have favored (if you
            press the heart in browse you will add that item to your favorites).<br/>
            You have one list per item type.<br/> You can toggle between these lists
            by clicking on each item type name. If you press on the heart the
            item is unmarked as a favorite.<br/> If you instead press the row for a
            specific item you go to that specific item in the system. Keeping
            updated lists of your favorites is key to be efficient in the
            system.
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>