<template>
   <v-col cols="12" md="12" class="py-0 px-0">
      <v-row v-if="step == 2" class="row-wrapper">
         <v-col cols="12" md="3" class="d-flex align-center">
            <v-text-field 
               :disabled="!costItem.editableName"
               dense
               required
               label="Cost name"
               v-model="costItem.display_name"
               filled
               hide-details
            />
         </v-col>

         <v-col cols="12" md="3">
            <v-select 
               v-if="step2Special"
               class="ma-0 pa-0"
               dense
               :items="defType"
               label="Type"
               item-text="name"
               item-value="val"
               v-model="selectedType"
               hide-details
               filled
            ></v-select>
         </v-col>
         
         <v-col cols="12" md="2">
            <v-text-field 
               style="text-align:right"
               label="Value"
               type="number"
               dense
               required
               placeholder="0"
               :suffix="valueSuffix"
               v-model="costItem.value"
               filled
               hide-details
            />
         </v-col>

         <v-col cols="12" md="3">
            <v-text-field
               readonly
               label="Caluclated cost"
               type="number"
               dense
               required
               :suffix="currency"
               v-model="costItem.calculated_cost"
               filled
               hide-details
            />
         </v-col>

         <v-col cols="12" md="1" class="align-center d-flex" >
            <v-spacer></v-spacer>
            <v-btn
               :disabled="costItem.sticky != undefined ? costItem.sticky : !costItem.editableName"
               small
               icon
               color="error"
               @click.stop="openCostDialog"
            >
               <v-icon>remove_circle_outline</v-icon>
            </v-btn>
            <comment-button
               class="ml-1"
               :name="costItem.display_name"
               :data="{ comment: costItem.comment, budget_rowid: costItem.budget_rowid }"
               :route="this.$getSubRoute('budgets','rows')"
               @setComment="(comment) => costItem.comment = comment"
            />
         </v-col>
      </v-row>
      <v-row v-if="step == 3" class="row-wrapper">
         <v-col cols="12" md="4">
            <v-text-field 
               dense
               required
               placeholder="Cost name"
               label="Type"
               v-model="costItem.display_name"
               filled
               hide-details
            />
         </v-col>
         <v-col cols="12" md="2">
            <v-select 
               class="ma-0 pa-0"
               label="Cost type"
               dense
               :items="defType"
               item-text="name"
               item-value="val"
               v-model="selectedType"
               hide-details
               filled
            ></v-select>
         </v-col>

         <v-col cols="12" md="2">
            <v-text-field
               v-if="selectedType == 3"
               class="wizard-form-text"
               type="number"
               label="Percentage"
               dense
               required
               placeholder="0"
               suffix="%"
               v-model="costItem.value"
               filled
               hide-details
            />
         </v-col>

         <v-col cols="12" md="3" class="pr-0">
            <v-text-field 
               v-if="selectedType != 3"
               style="text-align:right"
               label="Cost"
               type="number"
               dense
               required
               placeholder="0"
               :suffix="valueSuffix"
               v-model="costItem.value" 
               filled
               hide-details
            />
            <v-text-field
               v-if="selectedType == 3"
               label="Cost"
               type="number"
               disabled
               dense
               required
               :suffix="currency"
               v-model="costItem.calculated_cost"
               filled
               hide-details
            />
         </v-col>

         <v-col cols="12" md="1" class="d-flex align-center">
            <v-spacer></v-spacer>
            <v-btn
               :disabled="costItem.sticky != undefined ? costItem.sticky : false"
               small
               icon 
               color="error"
               @click.stop="openCostDialog"
            >
               <v-icon>remove_circle_outline</v-icon>
            </v-btn>
            <comment-button
               class="ml-1"
               :name="costItem.display_name"
               :data="{ comment: costItem.comment, budget_rowid: costItem.budget_rowid }"
               :route="this.$getSubRoute('budgets','rows')"
               @setComment="(comment) => costItem.comment = comment"
            />
         </v-col>
      </v-row>
      <v-row v-if="step == 4" class="row-wrapper">
         <v-col cols="12" md="4">
            <v-text-field 
               dense
               required
               label="Type"
               v-model="costItem.display_name"
               filled
               hide-details
            />
         </v-col>

         <v-col cols="12" md="4"></v-col>

         <v-col cols="12" md="3">
            <v-text-field 
               style="text-align:right"
               label="Cost"
               type="number"
               dense
               required
               placeholder="0"
               :suffix="valueSuffix"
               v-model="costItem.value" 
               filled
               hide-details
            />
         </v-col>
         <v-col cols="12" md="1" class="pt-0 d-flex align-center" >
               <v-spacer></v-spacer>
               <v-btn
                  :disabled="costItem.sticky != undefined ? costItem.sticky : false"
                  small
                  icon 
                  color="error"
                  @click.stop="openCostDialog"
               >
                  <v-icon>remove_circle_outline</v-icon>
               </v-btn>
               <comment-button
                  :name="costItem.display_name"
                  class="ml-1"
                  :data="{ comment: costItem.comment, budget_rowid: costItem.budget_rowid }"
                  :route="this.$getSubRoute('budgets','rows')"
                  @setComment="(comment) => costItem.comment = comment"
               />
         </v-col>
      </v-row>

      <delete-dialog 
         @deleteFromDialog="deleteCost"
         @cancelDialog="cancelCostDialog"
         :dialog="deleteCostDialog"
         :item="costItem"
         :name="costItem.display_name"
      />
   </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteDialog from '@/components/Dialogs/DeleteDialog'
import CommentButton from '@/components/Dialogs/Comment/CommentButton'

export default {
   name: 'BudgetCostForm',

   components: {
      DeleteDialog,
      CommentButton,
   },

   props: {
      costItem: Object,
      index: Number,
      step: Number,
   },

   computed: {
      step2Special(){
         return this.costItem.name != 'vat' && this.costItem.name != 'ticket_commission' && this.costItem.name != 'royalty'
      }
   },

   data: ()=>({
      currency: '',
      deleteCostDialog: false,
      defType: [
         {name:'Fixed cost', val:'1' },
         {name:'Cost per ticket', val:'2'},
         {name:'% of sales', val:'3'},
      ],
      selectedType: 1,
      valueSuffix: '',

   }),

   async created() {
      this.currency = this.$config.CURRENCY;

      if(this.costItem.comment == null)
         this.costItem.comment = ''

      if(this.costItem.name == 'ticket_commission' || this.costItem.name == 'royalty')
         this.costItem.budget_row_typeid = 3

      if(this.step == 3 || this.step == 4)
         this.defType.length = 3

      this.selectedType = this.defType[this.costItem.budget_row_typeid - 1].val
   },
   
   watch: {
      selectedType(val) {
         
         this.costItem.budget_row_typeid = val
         if(val == 1)
            this.valueSuffix = this.currency
         if(val == 2)
            this.valueSuffix = this.currency +'/ticket'
         if(val == 3 || val == 4)
            this.valueSuffix = '%'

         //this.calcCost()
      },
      'costItem.value': {
         handler: function(val){
            console.log(val)
            if(val !== '' && val.length > 1)
               this.costItem.value = val.replace(/^0+/g, '')
            if(val === '')
               this.costItem.value = '0'

            if(this.selectedType == 3 || this.selectedType == 4){
               if(val > 100)
                  this.costItem.value = 99
               if(val < 0)
                  this.costItem.value = 1
            }
         },
         deep: true
      },
      'costItem.display_name': {
         handler: function(val){
            this.costItem.name = val
         },
         deep: true
      }
   },

   async updated(){
   },

   methods: {

      openCostDialog(){
         this.deleteCostDialog = true
      },

      cancelCostDialog(){
         this.deleteCostDialog = false
      },

      async deleteCost(){
         this.$emit('deleteCost', this.costItem, this.index)
         this.deleteCostDialog = false
      },
   },

}
</script>

<style lang="scss" scoped>


</style>