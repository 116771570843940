<template>
    <v-container fluid class="pl-0 pt-0 pr-0 pb-0 browse-table">
        <updateAlert
            :updateFound="updateFound"
            @getTableData="getTableData"
            @closeUpdateAlert="closeUpdateAlert"
            :isRefreshPending="isRefreshPending"
        />
        <FilterDialog
            :filterDialog="filterDialog"
            :metaFields="metaFields"
            :items="items"
            @closeFilterDialog="closeFilterDialog"
            v-if="reRenderDialog"
            :view="view"
            @pushFiltered="pushFiltered"
        />

        <bpc-export-dialog v-if="bpcDialog" :dialog="bpcDialog" @cancelDialog="bpcDialog = false" />
        <v-data-table
            :headers="selectedHeaders"
            :items="items"
            :items-per-page="10"
            class="elevation no-hover"
            item-key="name"
            style="position: relative"
            :loading="pendingData"
            @click:row="(item) => goTo(item)"
        >
            <template v-slot:top>
                <v-col cols="12" md="12" class="pt-0 pb-0 pl-5 pr-5" style="border-bottom: 1px solid #e0e0e0">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        outlined
                                        v-model="search"
                                        :label="`Search for ${view}`"
                                        prepend-inner-icon="search"
                                        filled
                                        dense
                                        hide-details
                                        style="display:flex;"
                                        @keyup.enter="searchAll"
                                    >
                                        <template v-slot:append style="margin:auto;" v-if="searchIsActive">
                                            <v-btn x-small  @click="resetSearch" text class="mt-0" style="height:25px;"
                                                >reset</v-btn
                                            >
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="6" :class="{ 'd-flex align-center': !isMobile }">
                                    <v-btn
                                        color="primary"
                                        depressed
                                        small
                                        :block="isMobile"
                                        @click="searchAll"
                                        :loading="searchIsPending"
                                        :disabled="items.length === 0"
                                    >
                                        <v-icon left small>search</v-icon>
                                        Search
                                    </v-btn>
                                </v-col> -->
                            </v-row>
                        </v-col>

                        <v-col cols="12" md="6" class="btn-container d-flex align-center">
                            <v-spacer></v-spacer>
                            <v-btn small depressed v-if="!noCreate" :to="{ path: `${view}/create` }" color="success">
                                <v-icon left small>add</v-icon>Create new
                            </v-btn>
                            <v-btn
                                small
                                @click="exportTableToExcel(`${view}_data`)"
                                class="ml-5"
                                color="primary"
                                depressed
                                :disabled="items.length === 0"
                            >
                                <v-icon left small>swap_vert</v-icon>Create excel file
                            </v-btn>

                            <v-btn
                                v-if="view == 'concerts'"
                                @click="showBpcExport()"
                                class="ml-5"
                                color="primary"
                                small
                                depressed
                                :disabled="items.length === 0"
                            >
                                <v-icon left small>swap_vert</v-icon>BPC Export
                            </v-btn>

                            <v-btn class="filter-btn ml-5" small depressed color="primary" :disabled="items.length === 0" @click="openFilterDialog">
                                <v-icon left small>filter_list</v-icon>
                                Filter
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-row>
                                <v-col cols="12" md="12" class="pt-0 pb-0">
                                    <SelectionDropDown
                                        v-if="selectedHeaders.length > 1"
                                        :selectedHeaders="selectedHeaders"
                                        :allHeaders="allHeaders"
                                        :selectedHeadersObj="selectedHeadersObj"
                                        :view="view"
                                        :userselectionid="userselectionid"
                                        @saveAndGetSelection="saveAndGetSelection"
                                        @removeSelection="removeSelection"
                                    />
                                </v-col>
                                <v-col cols="12" md="12" class="d-flex updatewrapper">
                                    <v-row class="ma-0">
                                        <v-col cols="12" md="12" class="pa-0">
                                            <div class="active-filter-wrapper">
                                                <div class="title">
                                                    Active filters
                                                </div>
                                                <div v-if="activeFiltersNames.length === 0">
                                                    No active filters
                                                </div>
                                                <div v-for="activefilter in activeFiltersNames" :key="activefilter.id" class="filter">
                                                    <v-icon>filter_list</v-icon>
                                                    {{ activefilter }}
                                                </div>
                                                <v-btn
                                                    color="error"
                                                    class="reset-btn"
                                                    @click="removeFilters"
                                                    depressed
                                                    v-if="activeFiltersNames.length !== 0"
                                                >
                                                    <span>Remove filters</span>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template v-slot:item.actions="{ item }">
                <Favorite :items="items" :item="item" @addFavorite="addFavorite(item)" :favorites="favorites" />
                <router-link
                    style="text-decoration: none"
                    :to="{ path: `${view}/edit/${item[idpath]}` }"
                    v-if="view === 'artists' || view === 'concerts' || view === 'tours'"
                >
                </router-link>
            </template>
            <template v-slot:item.title="{ item }">
                <span class="title-fix">{{ item.title }}</span>
            </template>
            <template v-slot:item.progress="{ item }">
                <v-progress-circular
                    :indeterminate="progressBar.indeterminate"
                    :rotate="progressBar.rotate"
                    :size="progressBar.size"
                    :value="item.progress"
                    :width="progressBar.width"
                    color="primary"
                    >{{ item.progress }}%</v-progress-circular
                >
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { exportTableToExcel } from "@/plugins/createExcellFile.js";
import Favorite from "./Favorite.vue";
import UpdateAlert from "./UpdateAlert.vue";
import SelectionDropDown from "./SelectionDropDown.vue";
import FilterDialog from "./FilterDialog/";
import Loader from "@/components/Loader";
import BpcExportDialog from "@/components/Dialogs/BpcExportDialog";

export default {
    name: "browsetable",
    components: {
        SelectionDropDown,
        Loader,
        FilterDialog,
        Favorite,
        UpdateAlert,
        BpcExportDialog,
    },
    props: {
        idpath: String,
        noCreate: Boolean,
    },
    data: () => ({
        searchIsPending: false,
        searchIsActive: false,
        collection: null,
        metaFields: {},
        labelList: {},
        originalData: [],
        updateFound: false,
        filterDialog: false,
        isTableDataPending: true,
        isRefreshPending: false,
        pendingData: true,
        items: [],
        userselectionid: "",
        fields: null,
        search: "",
        headerList: [],
        selectionDialog: false,
        progressBar: {
            indeterminate: false,
            rotate: -90,
            size: 40,
            width: 5,
        },
        updateTimer: null,
        selectedHeadersObj: [
            {
                text: "Actions",
                align: "left",
                value: "actions",
                sortable: false,
            },
        ],
        selectedHeaders: [
            {
                text: "Actions",
                sortable: false,
                value: "actions",
            },
        ],
        allHeaders: [],
        allColumns: [],
        favorites: [],
        reRenderDialog: false,
        tinyInts: [],
        ignoreHeaders: ["dev_max_date", "dev_min_date", "endpoint", "pk_field", "createFields", "updateFields", "metaFields", "logging"],
        isMissingPrerequisites: false,
        bpcDialog: false,
    }),
    async created() {
        this.ignoreHeaders.push(this.idpath);
        await this.getHeaderInfo();
        await this.getMetaFields();
        await this.getTableData();
        await this.getAllHeaders();
        await this.findUserSelection();
        this.getSelectedHeaders();
        this.$bus.$emit("filterSelectedHeaders");
        await this.getFavorites();
        this.moveFavoritesToTop();
        this.pendingData = false;
    },
    updated() {
        this.items.forEach((item) => {
            for (let key in item) {
                if (!isNaN(item[key]) && item[key] != null) item[key] = this.$thousandSeperator(item[key]);
            }
        });
    },
    methods: {
        resetSearch() {
            this.items = this.originalData;
            this.moveFavoritesToTop();
            this.searchIsActive = false;
            this.search = "";
        },
        searchAll() {
            /**
             * Everytime a search occur we search on the original data.
             * IF we didnt do this the user would search on the already filtered data everytime
             * a new search occured.
             */
            this.items = this.originalData;

            /**
             * If theres no keywords and the user still press the search button we set the items to the original data,
             * move the favorites to top and do a return to prevent any code to execute below. This is also used as a reset.
             *
             */
            if (this.search.split` `.filter((v) => v).length == 0) {
                this.items = this.originalData;
                this.moveFavoritesToTop();
                this.searchIsActive = false;
                return;
            }

            /**
             * Shows the reset button
             */
            this.searchIsActive = true;

            /**
             * We split up all the keywords into an array so they can be accessed in the function below.
             */
            const keyWords = this.search.split` `.filter((v) => v);

            /**
             * As this is a recursive function we use the this to keep track of of the keywords being search.
             */
            let wordIndex = 0;

            /**
             * This should be straight forward. We filter on the incoming array, loop
             * through the key-values and look for the word. If found, we set found variable to true
             * and the filter will keep those items.
             */
            const recursiveFilter = (word, items, result) => {
                const filtered = items.filter((item) => {
                    let found = false;
                    Object.keys(item).forEach((key) => {
                        if (item[key] && typeof item[key] == "string") {
                            if (item[key].toLowerCase().includes(word.toLowerCase())) found = true;
                        }
                    });
                    return found;
                });

                /**
                 * When the filter is done we plus the wordIndex by 1 so the next word in the keyWords array
                 * can be searched for.
                 */
                wordIndex++;

                /**
                 * If the wordIndex is equal to the length of the keywords array, it menas
                 * that the function has went through the whole keyword array and will return the result in the callback function.
                 * If not we call the function again with the next keyword and the filtered array.
                 */
                wordIndex == keyWords.length ? result(filtered) : recursiveFilter(keyWords[wordIndex], filtered, result);
            };

            /**
             * Start of the recursive function. We use a callback to get the result from the function.
             * We need to check if the result is not undefined or null in order to know if the filter is done.
             * If so we redeclare the variable to the result.
             */
            recursiveFilter(keyWords[wordIndex], this.items, (result) => {
                if (result) this.items = result;
            });
        },
        goTo(item) {
            this.$router.push({
                path: `${this.view}/edit/${item[this.idpath]}`,
            });
        },
        exportTableToExcel,

        showBpcExport() {
            this.bpcDialog = true;
        },
        closeUpdateAlert() {
            this.updateFound = false;
        },
        removeFilters() {
            this.removeActiveFilterNames();
            this.items = this.originalData;
            this.$bus.$emit("emptyFilteredArray");
        },
        removeSelection(wordIndex) {
            this.$delete(this.selectedHeaders, wordIndex);
        },
        async getMetaFields(meta = "browse") {
            this.metaFields = this.metaData[this.view][meta];
        },

        async findUserSelection() {
            if (this.userSelections[this.view].fields === "") {
                await this.setDefaultHeaders();
            }
        },
        pushFiltered(payload) {
            this.items = payload;
            this.closeFilterDialog();
        },
        refreshTableData() {
            this.isRefreshPending = true;
            this.getTableData();
        },
        async getTableData() {
            this.isRefreshPending = true;
            // ApiObject Test
            const collection = await this.$ObjectFactory.BuildCollection({
                endpoint: this.view,
            });
            await collection.fetchAll();
            if (collection.collection.length === 0) this.pendingData = false;
            await this.fixTableData(collection);
        },

        async fixTableData(data) {
            console.log("fixTableData start");
            this.collection = data;
            this.items = data.collection;
            let tempMeta = {};
            for (let field of this.metaFields) {
                let metaKey = field.COLUMN_NAME;
                if (metaKey) tempMeta[metaKey] = field;
            }
            this.metaFields = tempMeta;
            // format with thousand seperator
            this.items.forEach((item) => {
                for (let key in item) {
                    if (this.metaFields[key]?.DATA_TYPE === "tinyint") item[key] = item[key] === "0" ? "No" : "Yes";

                    if (!isNaN(item[key]) && item[key] != null) item[key] = this.$thousandSeperator(item[key]);
                }
            });
            console.log("fixTableData loop done");
            this.originalData = JSON.parse(JSON.stringify(this.items));
            this.updateFound = false;
            this.isRefreshPending = false;
            console.log("fixTableData done");
        },

        async setDefaultHeaders() {
            /**
             * Default headers choosen by livenation
             */
            const defaultHeaders = {
                people: "name,category,tel_cell,email",
                organizations: "name,homepage,visit_town,country",
                concerts: "date,artistid,town,venueid,status",
                artists: "name,homepage,our_agent,external_agent",
                tours: "name,estimate_number_gigs,goal_wage_concert,startdate,enddate",
                venues: "name,visit_town,country,total_capacity,website",
                invoices: "invoice_date,company,due_date,invoice_type,payment_status",
            };

            try {
                let fields = {
                    table_name: this.view,
                    fields: defaultHeaders[this.view],
                    user_selectionid: null,
                };
                if (this.userSelections[this.view].user_selectionid > 0) {
                    fields.user_selectionid = this.userSelections[this.view].user_selectionid;
                }
                if (fields.user_selectionid > 0) {
                    console.log("PUT");
                    let response = await this.$PutService(this.$getRoute("user_selections").main_route, fields);
                    if (response.status === 200) {
                        this.getSelectedHeaders();
                        this.setUserSelections([fields]);
                        this.isGetSelectionsPending = false;
                    }
                } else {
                    console.log("POST");
                    let response = await this.$PostService(this.$getRoute("user_selections").main_route, fields);
                    if (response.status === 200) {
                        fields.user_selectionid = response.data.user_selectionid;
                        this.getSelectedHeaders();
                        this.setUserSelections([fields]);
                        this.isGetSelectionsPending = false;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        getSelectedHeaders() {
            let selectedHeadersArr = this.userSelections[this.view].fields.split(",");
            this.selectedHeaders = [
                {
                    text: "Actions",
                    align: "left",
                    sortable: false,
                    value: "actions",
                },
            ];
            selectedHeadersArr.forEach((header) => {
                let myHeader = {
                    text: this.collection.getLabel(header),
                    align: "left",
                    sortable: true,
                    value: header,
                };
                // myHeader.text = header.replace(/_/g, " ").replace(/(^|\s)\S/g, (l) => l.toUpperCase());

                this.selectedHeaders.push(myHeader);
            });
            this.alignRightHeadersWithDigits();
            console.log(this.selectedHeaders);
        },
        async getHeaderInfo() {
            try {
                this.metaFields = this.metaData[this.view].fields;
                this.alignRightHeadersWithDigits();
            } catch (error) {
                console.log(error);
            }
        },
        alignRightHeadersWithDigits() {
            for (let key in this.selectedHeaders) {
                if (this.selectedHeaders[key].value !== "actions") {
                    if (this.metaFields[this.selectedHeaders[key].value] === "int" || this.metaFields[this.selectedHeaders[key].value === "float"]) {
                        this.selectedHeaders[key].align = "right";
                    }
                }
            }
        },
        /**
         * Confirm if a previously selected header is still a valid selection
         * param header is the value to confirm
         * returns true if valid
         */
        async confirmHeader(header) {
            this.allHeaders.forEach((element) => {
                if (element == header.text) return true;
            });
            return false;
        },
        async getAllHeaders() {
            for (let key in this.metaFields) {
                if (!this.ignoreHeaders.includes(key)) {
                    this.allHeaders.push(this.collection.getLabel(key));
                    this.allColumns.push(key);
                }
            }
        },
        setChipColor(status) {
            if (status === "pending" || status === "No") {
                return "warning";
            } else {
                return "success";
            }
        },
        async saveAndGetSelection(payload) {
            payload = payload.map((selection) => this.collection.getColumnName(selection));
            payload = [...new Set(payload)];
            this.$delete(payload, payload.indexOf(""));

            this.updateSelectionsLocally(payload);
            try {
                let fields = {
                    table_name: this.view,
                    fields: payload
                        .join(",")
                        .replace(/ /g, "_")
                        .toLowerCase(),
                    user_selectionid: this.userSelections[this.view].user_selectionid,
                };
                let response = await this.$PutService(this.$getRoute("user_selections").main_route, fields);
            } catch (error) {
                console.log(error);
            }
        },
        updateSelectionsLocally(payload) {
            let fieldstring = payload
                .join(",")
                .replace(/ /g, "_")
                .toLowerCase();
            this.userSelections[this.view].fields = fieldstring;
            this.getSelectedHeaders();
        },
        openFilterDialog() {
            this.filterDialog = true;
            this.reRenderDialog = true;
        },
        closeFilterDialog() {
            this.filterDialog = false;
            this.reRenderDialog = false;
        },
        async getFavorites() {
            this.favorites = [];
            try {
                let response = await this.$GetService(this.$getRoute("favorites").main_route);
                if (response.status === 200) {
                    response.data.forEach((favorite) => {
                        this.favorites.push(favorite);
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        moveFavoritesToTop() {
            /**
             *  Variable for mirroring the the view with the incoming keys.
             */
            const viewVariable = this.view === "people" ? "personid" : this.view.slice(0, -1) + "id";

            /**
             * 	Get all all favorite id's based on view.
             */
            const favorites = this.favorites
                .filter((item) => item.type === viewVariable)
                .reduce((acc, id) => {
                    acc.push(id[viewVariable][viewVariable]);
                    return acc;
                }, []);

            /**
             * 	Move all items to top if any favorites exists.
             */

            if (favorites.length > 0) {
                this.items = this.items.sort((a, b) => (favorites.includes(a[viewVariable]) ? -1 : 1));
            }
        },
        async addFavorite(payload) {
            console.log(payload);
            let addFlag = true;
            let favoritetype = payload.pk_field;
            this.favorites.forEach(async (fav, i) => {
                if (fav.itemid === payload[favoritetype]) {
                    addFlag = false;
                    let favoriteid = fav.favoriteid;
                    try {
                        let response = await this.$DeleteService(this.$getRoute("favorites").main_route, { favoriteid: favoriteid });
                        if (response.status === 200) {
                            this.$delete(this.favorites, i);
                            this.$bus.$emit("unSetLoading");
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            });
            if (addFlag === true) {
                try {
                    let response = await this.$PostService(this.$getRoute("favorites").main_route, {
                        type: favoritetype,
                        itemid: payload[favoritetype],

                        [favoritetype]: payload[favoritetype],
                    });
                    if (response.status === 200) {
                        this.favorites.push({
                            favoriteid: response.data.favoriteid,
                            itemid: payload[favoritetype],
                            type: favoritetype,
                        });
                        this.$bus.$emit("unSetLoading");
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        ...mapActions({
            removeActiveFilterNames: "filters/removeActiveFilterNames",
            setUserSelections: "user/setUserSelections",
        }),
    },
    beforeDestroy() {
        this.removeActiveFilterNames();
    },
    computed: {
        ...mapGetters({
            metaData: "metadata/meta_data",
            userSelections: "user/userSelections",
            activeFiltersNames: "filters/activeFiltersNames",
            view: "globalstates/view",
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style scoped lang="scss">
.v-data-table {
    &::v-deep .v-data-table__wrapper {
        .v-data-table__progress th {
            padding: 0px !important;
        }
    }
}
.mobile {
    .v-data-table {
        &::v-deep .v-data-table__wrapper {
            td {
                max-width: 100%;
            }
        }
    }
}

.title-fix {
    max-width: 250px;
    float: left;
}

.browse-table {
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
}
.reset-btn {
    border-radius: 40px;
    max-height: 30px;
    font-size: 10px;
    i {
        font-size: 18px;
    }
}
.active-filter-wrapper {
    float: left;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 45px;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border: 1px solid #00000014;
    width: 100%;
    border-radius: 3px;
    .title {
        float: left;
        margin-right: 10px;
        background: #ececec;
        padding: 0px 15px;
        border-right: 1px solid #00000014;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 14px !important;
        text-transform: uppercase;
    }
    .filter {
        float: left;
        color: #222;
        background: $primary;
        color: #fff;
        border-radius: 40px;
        padding: 4px 20px 4px 10px;
        margin-right: 10px;
        font-weight: 500;
        display: flex;
        align-items: center;
        i {
            color: #fff;
            font-size: 18px;
            margin-right: 10px;
        }
    }
}
.table-btn {
    min-height: 100%;
    min-width: 200px;
}

.v-tooltip__content {
    padding: 5px 2px 5px 10px !important;
    i {
        margin-left: 10px;
        color: #fff;
    }
}
.v-data-table {
    padding-top: 10px !important;
}
.v-text-field.v-text-field--enclosed {
    &::v-deep .v-text-field__details {
        display: none !important;
    }
}

.v-progress-circular {
    &::v-deep .v-progress-circular__info {
        font-size: 12px !important;
        color: #666;
    }
}
.statuschip {
    text-transform: uppercase;
    font-size: 12px;
    padding: 0px 25px;
    font-weight: 600;
    letter-spacing: 1px;
}
.v-btn:not(.v-btn--round).v-size--x-large {
    height: 56px !important;
}

.selection-btn {
    height: 40px !important;
    &::v-deep .v-btn__content {
        // font-size:12px!important;
    }
}

.mobile .btn-container {
    display: block !important;
}

.btn-container {
    padding: 14px;
    display: flex;
}

.mobile .btn-container {
    .v-btn {
        width: 100%;
        margin: 0 0 20px 0 !important;
        float: left;
        max-width: 100% !important;
    }
}

.edit-btn {
    margin-left: 5px;
}

.tbody {
    ::v-deep tr:hover {
        background: #fff !important;
    }
}

.theme--light {
    ::v-deep .v-data-table {
        tbody {
            tr {
                &:hover {
                    &::not(.v-data-table__expanded__content) {
                        background: #fff !important;
                    }
                }
            }
        }
    }
}

.browse-table {
    &::v-deep .v-data-table__wrapper {
        tr:hover {
            background: #eee;
            cursor: pointer;
        }
    }
}
</style>
