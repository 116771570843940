
import axios from 'axios'
import Vue from 'vue'

const API_URL = process.env.VUE_APP_API_URL

const accessToken = async () =>  `Bearer ${await Vue.prototype.$auth.getAccessToken()}`


		


export async function GetDocService(endpoint){
	try {
		const res = await axios.get(`${API_URL}/${endpoint}`, { responseType: "blob" })
		if(res.status == 401)
			Vue.prototype.$auth.logout();

		return res;
	}
	catch (error) {
		console.log('%cError in GetService', 'color: red')
		console.log(error.response.data.errorInfo[2])
	}
}

export async function GetAcceptPost(payload, token) {
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	try {
		const res = await axios.post('https://api.getaccept.com/v1/documents', payload) 
		if(res.status == 401)
			Vue.prototype.$auth.logout();

		return res;
	}
	catch (error) {
		console.log('%cError in GetAcceptPost', 'color: red')
		console.log(error.response.data.errorInfo[2])
	}
}

export async function GetService(endpoint) {
	axios.defaults.headers.common['Authorization'] = await accessToken()
	try {
		const res = await axios.get(`${API_URL}/${endpoint}`);
		if(res.status == 401)
			Vue.prototype.$auth.logout();

		return res;
	}
	catch (error) {
		console.log('%cError in GetService', 'color: red')
		console.log(error.response.data.errorInfo[2])
	}
}
export async function TestService() {
	axios.defaults.headers.common['Authorization'] = await accessToken()
	try {
		const res = await axios.post('https://dev-301818.okta.com/authorize')
		if(res.status == 401)
			Vue.prototype.$auth.logout();

		return res;
	}
	catch (error) {
		console.log('%cError in GetService', 'color: red')
		console.log(error.response.data.errorInfo[2])
	}
}

export async function PostService(endpoint, payload) {
	axios.defaults.headers.common['Authorization'] = await accessToken()
	try {
		const res = await axios.post(`${API_URL}/${endpoint}`, payload) 
		if(res.status == 401)
			Vue.prototype.$auth.logout();

		return res;
	}
	catch (error) {
		console.log('%cError in PostService', 'color: red')
		console.log(error.response.data.errorInfo[2])
	}
}

export async function DeleteService(endpoint, payload) {
	axios.defaults.headers.common['Authorization'] = await accessToken()
	try {
		const res = await axios.delete(`${API_URL}/${endpoint}`, { data: payload }) 
		if(res.status == 401)
			Vue.prototype.$auth.logout();

		return res;
	}
	catch (error) {
		console.log('%cError in DeleteService', 'color: red')
		console.log(error.response.data.errorInfo[2])
	}
}

export async function PutService(endpoint, payload) {
	axios.defaults.headers.common['Authorization'] = await accessToken()
	try {
		const res = await axios.put(`${API_URL}/${endpoint}`, payload)
		if(res.status == 401)
			Vue.prototype.$auth.logout();

		return res;
	}
	catch (error) {
		console.log('%cError in PutService', 'color: red')
		console.log(error.response.data.errorInfo[2])
	}
}
