<template>
    <v-container fluid class="pa-3">
            <h1 class="header">{{ header }}</h1>
    </v-container>
</template>

<script>
export default {
    name: "viewheader",
    props: {
        header: String,
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
.header {
    color: $font-color;
	letter-spacing:1px;
    padding:5px;
    width: 100%;
}
.container {
    background:#fff;
    font-size: 20px;
    font-weight: 600;
    color: $accent-color;
	border-bottom: 1px solid $border-color;
    padding-bottom: 0px;
    border-radius: 3px;
}
</style>
