<template>
    <div>
        <div class="month-wrapper">
            <div class="month-name">
                {{ getMonthName() }}
            </div>
            <div class="month elevation-0">
                <div class="day" v-for="(concert, day) in calender" :key="day">
                    <div
                        class="status-wrapper d-flex"
                        v-on="on"
                        v-bind="attrs"
                        :class="getStatusClassName(concert.status)"
                        @click="calendarClick(concert, day)"
                        v-if="!concert.title"
                    >
                        <div class="day-number">
                            <span>{{ getDay(day) }}</span>
                        </div>
                        <div class="status">
                            {{ concert.status }}
                        </div>
                    </div>
                    <v-tooltip bottom transition="false" v-else>
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                class="status-wrapper d-flex"
                                v-on="on"
                                v-bind="attrs"
                                :class="getStatusClassName(concert.status)"
                                @click="calendarClick(concert, day)"
                            >
                                <div class="day-number">
                                    <span>{{ getDay(day) }}</span>
                                </div>
                                <div class="status">
                                    {{ concert.status }}
                                </div>
                            </div>
                        </template>
                        <span>{{ concert.title }}</span>
                    </v-tooltip>
                </div>
            </div>
        </div>

        <portable-edit-table-item
            v-if="showPortableForm"
            :dialog="showPortableForm"
            :formtype="portableFormType"
            :fromApiObj="apiObject"
            @closeForm="
                () => {
                    showPortableForm = false;
                }
            "
            @portableObjectCreated="handleNewPortableObject"
        />

        <status-changer-dialog
            v-if="showstatusChanger"
            :concertSelected="concertSelected"
            :showstatusChanger="showstatusChanger"
            :statusList="statusList"
            @click:row="(item) => $emit('statusChangerDialog', item)"
            @closestatusChangerDialog="closeStatusDialog()"
        />
    </div>
</template>

<script>
import dayjs, { Dayjs } from "dayjs";
import PortableEditTableItem from "@/components/Tables/EditTableItem/forms/PortableEditTableItem.vue";
import StatusChangerDialog from "@/components/SalesPage/StatusChangerDialog.vue";

export default {
    name: "tourcalendar",

    components: {
        PortableEditTableItem,
        StatusChangerDialog,
    },

    mixins: [require("./SalesTableGeneralFunctionsMixins.vue")],

    data: () => ({
        calender: {},
        showstatusChanger: false,
        showPortableForm: false,
        portableFormType: "concerts",
        apiObject: null,
        concertSelected: null,
        statusList: [],
    }),
    watch: {
        tourSelected: {
            handler() {
                this.updateCalender();
            },
            deep: true,
        },
    },

    props: {
        dateSelected: Object,
        tourSelected: Array,
        monthOffset: Number,
    },

    async created() {
        this.updateCalender(); // update calender when new tour is selected
        this.statusList = this.$config.STATUS_LIST; // List of different statuses, might change where from
    },

    methods: {
        updateCalender() {
            let calenderStart = this.dateSelected.add(this.monthOffset, "month");
            this.calender = {};

            for (let d = 0; d < calenderStart.daysInMonth(); d++) {
                // loop days per month
                let date = dayjs(calenderStart.add(d, "day")).format("YYYY-MM-DD");
                this.calender[date] = this.getDayData(date);
            }
        },

        getDay(date) {
            return dayjs(date).format("D");
        },

        getMonthName() {
            return dayjs(Object.keys(this.calender)[0]).format("MMMM");
        },

        getDayData(date) {
            // returns concert obj of the day or {status : "free"} if free     // ** skriv om loop  if (tour.concert)

            try {
                for (let c = 0; c < this.tourSelected.concerts.length; c++) {
                    if (this.tourSelected.concerts[c].date == date) {
                        if (!this.tourSelected.concerts[c].status) {
                            this.tourSelected.concerts[c].status = "No-status";
                        }
                        return this.tourSelected.concerts[c]; // date & concert.date match - return concert
                    }
                }
            } catch (TypeError) {} // no existing concerts
            if (this.isDateRangeOverlapping(date, date, this.tourSelected.startdate, this.tourSelected.enddate)) {
                return { status: "Free" }; // date didnt match with concert dates - return "free"
            } else {
                return { status: "" };
            }
        },

        async handleNewPortableObject(newid, apiObj) {
            // creating a new concert from clicking on a free day in calendar
            this.$emit("newConcert", apiObj);
        },

        async calendarClick(concert, day) {
            console.log(day)
            console.log(this.tourSelected.tourid)
            if (concert.status == "Free") {
                // no existing concert
                this.portableFormType = "concerts";
                this.apiObject = await this.$ObjectFactory.BuildObject({
                    endpoint: "concerts",
                });
                this.apiObject["tourid"] = this.tourSelected.tourid;
                this.apiObject["date"] = day;
                this.apiObject["date_end"] = day;
                this.apiObject["artistid"] = this.tourSelected.artistid.artistid;
                this.showPortableForm = true;
                console.log(this.apiObject)
            } else {
                // existing concert
                this.showStatusChangerDialog(concert);
            }
        },

        showStatusChangerDialog(concert) {
            // Option to change status for existing concerts when clicked
            this.showstatusChanger = true;
            this.concertSelected = concert;
        },

        closeStatusDialog() {
            this.showstatusChanger = false;
        },

        getStatusClassName(status) {
            return status ? status.replace(/ /g, "-").toLowerCase() : "disabled";
        },
    },
};
</script>

<style lang="scss">
.month-wrapper {
    background: #f0f0f0;
    .month-name {
        text-align: center;
        color: $font-color-pale;
        font-weight: 600;
        font-size: 16px;
        padding: 10px;
        border-bottom: 1px solid $border-color;
        background: #fff;
    }
    .month {
        .status-wrapper {
            border-bottom: 1px solid #e2e2e2;
            padding: 5px;
            display: flex;
            align-items: center;
            height: 30px;
            cursor: pointer;
            &:hover .day-number{
                background:#e7e7e7
            }

            .status {
                text-transform: uppercase;
                font-size: 13px;
                text-align: center;
                line-height: 26px;
                height: 29px;
                font-weight: 600;
                flex: 1;
                &:hover {
                    background: #f7f7f7;
                }
            }


            padding: 0px;
            transition: all 0.2s ease;
            .day-number {
                color: $font-color;
                background: #f7f7f7;
                border-right: 1px solid $border-color;
                width: 30px;
                height: 100%;
                display: flex;
                font-size: 13px;
                font-weight: 600;
                span {
                    margin: auto;
                }
            }
        }
    }
}

/* Statuses
free
no-status

hold
offer
confirmed
unavailable
declined
routing
repetition
cancelled
we-declined
artist-declined
company-gig
*/

.disabled {
    pointer-events: none;
}

.free,
.no-status,
.hold,
.offer,
.confirmed,
.unavailable,
.declined,
.we-declined,
.artist-declined,
.cancelled,
.routing,
.repetition,
.company-gig {
    background: #fff;
}

.free {
    background: rgb(255, 255, 255);
    color: rgb(155, 155, 155);
    font-weight: 600;
}
.no-status {
    border-right: 6px solid #ccc;
    color: black;
}

.hold {
    border-right: 6px solid #4a81b5;
    color: #4a81b5;
}

.offer {
    border-right: 6px solid #49b4ad;
    color: #49b4ad;
}

.confirmed {
    border-right: 6px solid #55b54a;
    color: #55b54a;
}

.unavailable {
    border-right: 6px solid #b5804a;
    color: #b5804a;
}

.declined,
.we-declined,
.artist-declined,
.cancelled {
    border-right: 6px solid #b54a4a;
    color: #b54a4a;
}

.routing {
    border-right: 6px solid #7d49b5;
    color: #7d49b5;
}

.repetition {
    border-right: 6px solid #aeb54a;
    color: #aeb54a;
}

.company-gig {
    border-right: 6px solid #b54a99;
    color: #b54a99;
}
</style>
