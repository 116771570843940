<template>
   <div>
      <v-row class="row-wrapper">
         <v-col cols="12" md="2">
            <v-text-field 
               :disabled="!costItem.editableName"
               dense
               required
               placeholder="Cost name"
               v-model="costItem.display_name"
               hide-details
               filled
               label="Type"
            />
         </v-col>

         <v-col cols="12" md="2">
            <v-text-field
               disabled 
               dense
               v-model="formType.name"
               hide-details
               filled
               label="Cost type"
            ></v-text-field>
         </v-col>

         <v-col cols="12" md="3">
            <v-text-field 
               label="Value"
               style="text-align:right"
               type="number"
               dense
               required
               placeholder="0"
               :suffix="valueSuffix"
               v-model="costItem.value" 
               hide-details
               filled
            />
         </v-col>

         <v-col cols="12" md="2">
            <v-text-field
               disabled
               label="Cost"
               type="number"
               dense
               required
               :suffix="currency"
               v-model="costItem.calculated_cost"
               hide-details
               filled
            />
         </v-col>

         <v-col cols="12" md="2">
            <v-text-field
               disabled
               label="Other currency"
               type="number"
               dense
               required
               :suffix="otherCurrency.match(/[^$£€]/) ? otherCurrency : ''"
               :prefix="otherCurrency.match(/[$£€]/) ? otherCurrency : ''"
               v-model="costItem.currency_amount"
               hide-details
               filled
            />
         </v-col>


         <v-col cols="12" md="1" class="pa-0 d-flex align-center" >
            <v-spacer></v-spacer>
            <v-btn
               :disabled="costItem.sticky || !costItem.editableName"
               small
               icon 
               color="error"
               @click.stop="openCostDialog"
            >
               <v-icon>remove_circle_outline</v-icon>
            </v-btn>
            <comment-button
               :name="costItem.display_name"
               class="ml-1"
               :data="{ comment: costItem.comment, budget_rowid: costItem.budget_rowid }"
               :route="this.$getSubRoute('budgets','rows')"
               @setComment="(comment) => costItem.comment = comment"
            />
         </v-col>
      </v-row>            

      <delete-dialog 
         @deleteFromDialog="deleteCost"
         @cancelDialog="cancelCostDialog"
         :dialog="deleteCostDialog"
         :item="costItem"
         :name="costItem.display_name"
      />
   </div>
</template>

<script>
import DeleteDialog from '@/components/Dialogs/DeleteDialog'
import CommentButton from '@/components/Dialogs/Comment/CommentButton'

export default {
   name: 'BudgetFiveCostForm',

   components: {
      DeleteDialog,
      CommentButton,
   },

   props: {
      costItem: Object,
      formType: Object,
      totalTickets: Number,
      totalRevenues: Number,
   },

   computed: {
      calculated_cost(){
         return this.costItem.calculated_cost
      },
      value(){
         return this.costItem.value
      }
   },

   data: ()=>({
      currency: '',
      otherCurrency: '',
      deleteCostDialog: false,
      valueSuffix: '',
   }),

   async created() {
      this.currency = this.$config.CURRENCY;
      
      if(this.costItem.name == 'variable_gage_share_of_net')
         this.costItem.budget_row_typeid = 3
      if(this.costItem.name == 'variable_gage_share_of_gross')
         this.costItem.budget_row_typeid = 3
      if(this.costItem.name == 'variable_gage_sek_ticket')
         this.costItem.budget_row_typeid = 2

      if(this.costItem.comment == null)
         this.costItem.comment = ''

   
      if(this.formType.val == 1)
         this.valueSuffix = this.currency
      if(this.formType.val == 2)
         this.valueSuffix = `${this.currency}/ticket`
      if(this.formType.val == 3)
         this.valueSuffix = '%'

      this.otherCurrency = this.$getCurrency(this.costItem.currency_name)
   },
   
   watch: {
      value(val){
         //console.log(`value watcher: ${val}`)
         if(val !== '' && val.length > 1)
            this.costItem.value = val.replace(/^0+/g, '')
         if(val === '')
            this.costItem.value = '0'

         if(this.formType.val == 3){
            if(+val >= 100)
               this.costItem.value = 100
            if(+val <= 0)
               this.costItem.value = 0
         }

         //console.log(`value: ${this.value}`)
      },   
      'costItem.display_name': {
         handler: function(val){
            //this.costItem.name = val.replace(/ /g, '_').toLowerCase().trim()
            this.costItem.name = val
         },
         deep: true,
      },
      'costItem':{
         handler(val){
            this.calcOtherCurrency()
         },
         deep: true
      },
      'costItem.currency_rate':{
         handler(val){
            this.calcOtherCurrency()
         },
         deep: true
      },
      'costItem.currency_name':{
         handler(val){
            this.otherCurrency = this.$getCurrency(val)
         },
         deep: true
      }
   },

   async updated(){
   },

   methods: {

      calcOtherCurrency(){
         let rate = this.costItem.currency_rate
         if(rate > 0 || rate == '')
            this.costItem.currency_amount = Math.round(this.costItem.calculated_cost / rate)
         else 
            this.costItem.currency_amount = 0         
      },

      openCostDialog(){
         this.deleteCostDialog = true
      },

      cancelCostDialog(){
         this.deleteCostDialog = false
      },

      async deleteCost(){
         this.$emit('deleteCost', this.costItem, this.index)
      },
   },

}
</script>

<style lang="scss" scoped>



</style>