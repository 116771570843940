import ApiObject from './ApiObject';
import ObjectFactory from './ObjectFactory';
import { getCostCodes } from '@/models/venue_budget_template_rows.js';
import dayjs from 'dayjs';


export default class ConcertObject extends ApiObject {
    constructor() {
        super('concerts');
        this.ticketObjects = [];
        this.ticketsMetaFields = null;
        this.ticketSalesMetaFields = null;
        this.concertSalesGraphData = [];
    }

    async initTickets(concertid) {
        if (!this.tickets) return;
        for (let ticket of this.tickets) {
            let newTicket = await this.objFactory.BuildTicket({ ticket: ticket, concertid: concertid, metaFields: this.ticketsMetaFields });
            this.ticketObjects.push(newTicket);
        }
        for (let ticket of this.ticketObjects) {
            if(ticket.ticket_sales){
                let newSaleDates = await this.setMissingDates(this.dev_min_date, this.dev_max_date, ticket.ticket_sales, ticket.ticketid);
                if(newSaleDates.length > 0)
                    ticket.ticket_sales = ticket.ticket_sales.concat(newSaleDates);
            }
        }
    }

    async addTicket(name, price, capacity, available, platinum) {
        let ticket = await this.objFactory.BuildTicket({ ticket: { name, price, capacity, available, platinum }, concertid: this.concertid });
        let newID = await ticket.postObject();
        ticket[ticket.pk_field] = newID;
        ticket.ticket_sales = [];
        ticket.ticket_sales = await this.setMissingDates(this.MIN_DATE, this.MAX_DATE, ticket.ticket_sales, ticket.ticketid);
        await ticket.setSumSales();
        this.ticketObjects.push(ticket);
        console.log(this.ticketObjects);
    }

    removeTicket(ticketid) {
        const ticket = this.ticketObjects.find(ticket => ticket.ticketid === ticketid);
        if (ticket)
            if (ticket.deleteObject())
                this.ticketObjects.splice(this.ticketObjects.indexOf(ticket), 1)

    }

    getConcertTabItem(){
        const tabConcert = {
            rowid: this.concertid,
            status: this.status ? this.status : 'no status',
            date: this.date,
            artistid: this.artistid.name,
            town: this.town,
            venueid: this.venueid.name,
            Capacity: this.thousandSeperator(+this.getTotalTicketsAvailable()),
            Sold: this.getTotalSoldPercentage(),
        }
        return tabConcert;
    }

    getConcertSalesGraphData(minDate = this.MIN_DATE, maxDate = this.MAX_DATE, externalArray = null){
        this.concertSalesGraphData = [];
        for(let ticket of this.ticketObjects){
            ticket.getTicketSalesForGraph(minDate, maxDate, (externalArray ? externalArray : this.concertSalesGraphData));
        }

        console.log(externalArray)
        console.log(this.concertSalesGraphData)
        return externalArray ? externalArray : this.concertSalesGraphData;
    }

    getTotalGross(minDate = this.MIN_DATE, maxDate = this.MAX_DATE) {
        let sum = 0
        for (let ticket of this.ticketObjects) {
            sum += +ticket.getGrossSales(minDate, maxDate);
        }
        return sum;
    }
    getTotalTicketsAvailable() {
        let sum = 0
        for (let ticket of this.ticketObjects) {
            sum += +ticket.available;
        }
        return sum;
    }
    getTotalCapacity() {
        let sum = 0
        for (let ticket of this.ticketObjects) {
            sum += +ticket.capacity;
        }
        return sum;
    }
    getTotalChange() {
        let sum = 0;
        for (let ticket of this.ticketObjects) {
            sum += ticket.getChange();
        }
        return sum;
    }
    getTotalSoldTickets(minDate = this.MIN_DATE, maxDate = this.MAX_DATE) {
        let sum = 0;
        for (let ticket of this.ticketObjects){
            sum += ticket.getTotalSold(minDate, maxDate);
        }
        return sum;
    }
    getTotalSoldPercentage(minDate = this.MIN_DATE, maxDate = this.MAX_DATE) {
        let sold = this.getTotalSoldTickets(minDate, maxDate);
        let percentage = Math.round(sold / +this.getTotalTicketsAvailable() * 100);

        return isNaN(percentage) ? 0 : percentage;
    }

    getCurrentBudget() {
        if (!this.budgets)
            return null; // FETCH BUDGET

        return this.budgets.find(budget => budget.status == Math.max.apply(Math, this.budgets.map(budget => budget.status)));
    }

    /**
     * Function to see if the concert is currently on sale.
     * @param shift (optional) decides how many days the on_sale date should be shifted before being compared 
     * @returns true if concert is on sale
     */
    isOnSale(shift = 0){
        if(this.on_sale == null) return false;

        let now = dayjs();
        let on_sale_time = '00:00:01.000Z';
        if(this.on_sale_time != null)
            on_sale_time = `${this.on_sale_time}.000Z`

        let on_sale_plus_one = dayjs(`${this.on_sale}T00:00:01.000Z`).add(shift, 'day');
        let on_sale_plus_one_time = dayjs(`${this.on_sale}T${on_sale_time}`).add(shift, 'day');
        if(now >= on_sale_plus_one && (shift < 1 ? now >= on_sale_plus_one_time : true))
            return true;

        return false;
    }

    saveChanges() {
        for (let ticket of this.ticketObjects)
            ticket.updateObject(ticket)
    }

    async setMissingDates(fromdate, enddate, salesArray, ticketid, metaFields = null) {
        if(!fromdate || !enddate) return;
        if (!salesArray) salesArray = [];
        
        //Convert dates from ISOStrings to date objects, set time to 12:00:00 to avoid daylight savings issues
        let date = new Date(Date.parse(fromdate.substring(0, 10) + " 12:00:00"));
        let todate = new Date(Date.parse(enddate.substring(0, 10) + " 12:00:00"));
        let isodate = "";
		let dateArray = [];
		let salesObject = await this.objFactory.BuildCollection({ endpoint: 'ticket_sales', metaFields: this.ticketSalesMetaFields });
		
        //Fill dateArray with existing dates
        for (let index = 0; index < salesArray.length; ++index)
            dateArray.push(salesArray[index].date);

        do {
            //convert date to ISOString
            isodate = date.toISOString().substring(0, 10);
            //If date is missing, push object to salesArray for missing date
            if (dateArray.indexOf(isodate) == -1) await salesObject.addToCollection({ amount: 0, date: isodate, ticketid: ticketid, db_key: `${isodate}_${ticketid}` });

            //Add one day to date
            date.setDate(date.getDate() + 1);
        } while (date <= todate);

        if (salesObject.collection.length > 0) {
            const ticketids = await salesObject.postCollection(salesObject.collection);
            for(let i = 0; i < ticketids.length; i++){
                salesObject.collection[i].ticket_salesid = ticketids[i]
            }

            return salesObject.collection; // for new tickets
        }
        return [];
    }

    getCsvData(data_type, costs =  null){
        if(costs == null)
            costs = getCostCodes();

        const config_loc = require("@/../public/config.json");
        const departments = {};
        for(const key in config_loc.DEPARTMENT_LIST)
            departments[config_loc.DEPARTMENT_LIST[key].name] = config_loc.DEPARTMENT_LIST[key].code;

        // local help functions
        let getField = (field) => field ? field : 'NODATA';
        let getVatValue = (value, rate) => +value * (1-1/(1+rate/100))
        let roundToDec = (val, dec) => Math.round((+val + Number.EPSILON) * Math.pow(10,dec)) / Math.pow(10,dec)

        const budget = this.getCurrentBudget();
        let data = [];

        // builds up an object (one csv row) with the correct values
        let getObj = (accounts, value) => {
            let date = new Date(this.date);
            const month = new Intl.DateTimeFormat('en-US', {month:'long'}).format(date).substr(0,3)
            const eventDate = `${date.getUTCFullYear()}.${month}${date.getUTCDay()}`

            return {
                'Oracle ID': getField(this.oracleid),
                'ROMEID': this.concertid,
                'Company': getField(departments[this.department]),
                'Department': getField(this.venue_typeid?.code),
                'EventDate': eventDate,
                'Promoter': getField(this.bpc_promoterid),
                'Talent': getField(this.artistid.bpc_talentid),
                'Venue': getField(this.venueid?.bpc_venueid),
                'Data_type': data_type,         
                'Accounts': accounts,
                'Value': roundToDec(value, 2),
                'Deal_Status': this.status,
                'DATASRC': 'ROME',             // always ROME
                'RPTCURRENCY': 'LC',            // always LC
                'TYPEOFEVENT': getField(this.type_of_event),
            }
        }
        
        let nbrOfShows = 0;
        let nbrOfTickets = 0;
        let nbrOfFreeTickets = 0;
        let totalCapacity = 0;
        let ticketRevenues = 0;
        let avgTicketPrice = 0;

        if(budget){
            if(budget.budget_tickets){
                nbrOfTickets = budget.budget_tickets.length;
                for(let {price, price_ex_vat, show_number, capacity, comps, kills, other, sellable_percentage} of budget.budget_tickets){
                    
                    if(+show_number > nbrOfShows)
                        nbrOfShows = +show_number;
                    if(+price == 0)
                        nbrOfFreeTickets++;
                    
                    avgTicketPrice += +price_ex_vat;
                    let sellableCapacity = Math.round((+capacity - +comps - +kills - +other) * sellable_percentage)
                    totalCapacity += sellableCapacity;
                    ticketRevenues += +price_ex_vat * sellableCapacity;
                }
                avgTicketPrice = avgTicketPrice / (nbrOfTickets - nbrOfFreeTickets);

                data.push(getObj('NUMSHOW', nbrOfShows));
                data.push(getObj('DRPCNT', nbrOfTickets));
                data.push(getObj('PDATT', nbrOfTickets - nbrOfFreeTickets));
                data.push(getObj('CAPCTY', totalCapacity));
                data.push(getObj('A40100.00', ticketRevenues, 2));
                data.push(getObj('TKTAVG', avgTicketPrice, 2));
            }
            
            if(budget.budget_rows){
                let talentGage = {code: 'A50002.00', value: 0}
                for(let row of budget.budget_rows){
                    if(row.header.toLowerCase() == 'other revenues')
                        data.push(getObj(row.account_code, row.value - getVatValue(row.value, row.vat))); // other revenues EXCL. vat
                    if(costs[row.name] && costs[row.name] != 'A-1' && +row.calculated_cost){
                        
                        if(row.header.toLowerCase() == 'talent costs'){
                            if(row.calculated_cost > talentGage.value){
                                talentGage.code = costs[row.name];
                                talentGage.value = row.calculated_cost;
                            }
                        } else{
                            data.push(getObj(costs[row.name], row.calculated_cost));
                        }
                    }else if(row.name.toLowerCase() != 'vat' && +row.calculated_cost)
                        data.push(getObj('A50128.00', row.calculated_cost));
                }
                data.push(getObj(talentGage.code, talentGage.value));
            }
        }

        return data;
    }
    
}