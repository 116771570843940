<template>
    <v-dialog v-model="marketingTypesDialog" persistent max-width="800">
        <v-card>
            <v-card-title>
                <span class="header">Marketing types</span>
            </v-card-title>
            <v-card-text class="pa-0">
                <BudgetMarketingTable :allMarketingTypes="allMarketingTypes" />
                <MarketingTable
                    :marketingData="marketingData"
                    @showEditMarketingType="(id) => showEditMarketingType(id)"
                    @getMarketingData="$emit('getMarketingData')"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" small depressed @click="closeMarketingTypesDialog"
                    >Close</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import BudgetMarketingTable from "./BudgetMarketingTable";
import MarketingTable from "./MarketingTable";
import MarketingType from "./MarketingType.vue";

export default {
    props: {
        marketingTypesDialog: Boolean,
        marketingData: Object,
        concertId: String,
        allMarketingTypes: Array,
    },
    components: {
        MarketingType,
        BudgetMarketingTable,
        MarketingTable,
    },
    data: () => ({
        marketingName: "",
        payload: {
            marketingName: "",
            marketingId: "",
        },
        currentMarketingId: null,
        EditMarketingTypeDialog: false,
        isAddNewMarketingTypePending: false,
        isDeleteMarketingTypePending: false,
        marketingTypes: [],
    }),
    created() {
        if (this.marketingData)
            this.marketingTypes = JSON.parse(
                JSON.stringify(this.marketingData)
            );

        console.log(this.marketingTypes.length);
    },
    methods: {
        async addNewMarketingType() {
            this.isAddNewMarketingTypePending = true;
            try {
                let response = await this.$PostService(
                    this.$getRoute("marketing").main_route,
                    {
                        concertid: this.concertId,
                        name: this.marketingName,
                    }
                );
                console.log(response);
                if (response.status === 200) {
                    this.payload.marketingName = this.marketingName;
                    this.payload.marketingId = response.data.marketingid;
                    this.marketingTypes.push({
                        name: this.marketingName,
                        marketingid: response.data.marketingid,
                        marketing_data: [],
                    });
                    this.$emit("getMarketingData");
                    this.isAddNewMarketingTypePending = false;
                    this.openEditMarketingTypeDialog();
                }
            } catch (error) {
                console.log(error);
            }
        },
        showEditMarketingType(marketingInfo) {
            this.currentMarketingInfo = marketingInfo;
            this.EditMarketingTypeDialog = true;
        },
        async deleteMarketingType(id, i) {
            try {
                let response = await this.$DeleteService("marketing", {
                    marketingid: id,
                });
                if (response.status === 200) {
                    this.$bus.$emit("unSetIsDeleteMarketingTypePending");
                    this.$delete(this.marketingTypes, i);
                    this.$emit("getMarketingData");
                }
            } catch (error) {
                console.log(error);
            }
        },
        editMarketingType(name, id) {
            this.payload.marketingName = name;
            this.payload.marketingId = id;
            this.openEditMarketingTypeDialog();
        },
        closeMarketingTypesDialog() {
            this.$emit("closeMarketingTypesDialog");
        },
        openEditMarketingTypeDialog() {
            this.$emit("openEditMarketingTypeDialog", this.payload);
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    font-size: 15px !important;
    text-transform: uppercase;
    color: $accent-color;
    font-weight: 600 !important;
}

.header-title {
    font-weight: 600;
    font-size: 14px;
    color: $accent-color !important;
    border: none !important;
}
.v-data-table {
    width: 100%;
    border-top: 1px solid $border-color;
}
.add-btn {
    height: 40px !important;
}

.mobile {
    .add-btn {
        margin-top: 15px !important;
    }
}

.v-card__actions {
    z-index: 1;
    position: relative;
    box-shadow: 0px -3px 8px #0000001f;
    border: none;
}
</style>