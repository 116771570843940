const state = {
    /**
     * meta_data ex:
     * {
     *      endpoint: {
     *          fields: {...},
     *          browse: {...},
     *          create: {...},
     *          update: {...},
     *      },
     *      ...
     * }
     */
    meta_data: null,
    raw_country_list: null
 };
  
 const getters = {
    meta_data: state => state.meta_data,
    raw_country_list: state => state.raw_country_list,
 };
  
 const actions = {
    setMetaData({ commit }, payload) {
        commit('setMetaData', payload)
    },
    setCountryList({ commit }, payload) {
      commit('setCountryList', payload)
  },
 };
  
 const mutations = {
    setMetaData: (state, payload) => state.meta_data = payload,
    setCountryList: (state, payload) => state.raw_country_list = payload,
 }
 
 export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
 };
 