<template>
	<div id="typecard" class="float-left elevation-1">
		<v-col cols="12" md="12" class="pa-0">
			<div class="card-title">Types</div>
		</v-col>
		<v-col cols="12" md="12" class="mt-10">
			<v-row align="center" justify="center">
				<v-btn-toggle dense v-model="toggle_exclusive" color="primary" mandatory>
					<v-btn @click="activeEvents = true">ACTIVE</v-btn>
					<v-btn @click="activeEvents = false">ENDED</v-btn>
				</v-btn-toggle>
			</v-row>
		</v-col>
		<v-col cols="12" md="12" class="pa-8" v-if="activeEvents">
			<v-row>
				<v-col cols="12" md="4">
					<div class="type-header">Number of events</div>
					<div class="event-data-wrapper">
						<div class="event-types" v-for="(active, name) in types.active" :key="active.id">
							<div class="event-type-name">{{ name }} ({{ active.max_capacity }})</div>
							<div class="event-type-value">{{ active.amount }}</div>
							<v-progress-linear height="7" rounded :value="active.amount_percentage"></v-progress-linear>
							<div class="event-type-value">{{ benchmarkTypes.active[name].amount }}</div>
							<v-progress-linear
								color="#888"
								height="7"
								rounded
								:value="benchmarkTypes.active[name].amount_percentage"
							></v-progress-linear>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="4">
					<div class="type-header">Average ticket price</div>
					<div class="event-data-wrapper">
						<div class="event-types" v-for="(active, name) in types.active" :key="active.id">
							<div class="event-type-name">{{ name }} ({{ active.max_capacity }})</div>
							<div class="event-type-value">{{ active.avg_ticket_price }} {{ $config.CURRENCY }}</div>
							<v-progress-linear height="7" rounded :value="active.avg_ticket_price_percentage"></v-progress-linear>
							<div
								class="event-type-value"
							>{{ benchmarkTypes.active[name].avg_ticket_price_percentage }} {{ $config.CURRENCY }}</div>
							<v-progress-linear
								color="#888"
								height="7"
								rounded
								:value="benchmarkTypes.active[name].avg_ticket_price"
							></v-progress-linear>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="4">
					<div class="type-header">Marketing cost per fan</div>
					<div class="event-data-wrapper">
						<div class="event-types" v-for="(active, name) in types.active" :key="active.id">
							<div class="event-type-name">{{ name }} ({{ active.max_capacity }})</div>
							<div
								class="event-type-value"
							>{{ active.cost_per_fan === null ? 'no-data' : `${active.cost_per_fan} ${$config.CURRENCY}`}}</div>
							<v-progress-linear height="7" rounded :value="active.cost_per_fan_percentage"></v-progress-linear>
							<div
								class="event-type-value"
							>{{ benchmarkTypes.active[name].cost_per_fan === null ? 'no-data' : `${benchmarkTypes.active[name].cost_per_fan} ${$config.CURRENCY}` }}</div>
							<v-progress-linear
								color="#888"
								height="7"
								rounded
								:value="benchmarkTypes.active[name].cost_per_fan_percentage"
							></v-progress-linear>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" md="12" class="pa-8" v-if="!activeEvents">
			<v-row>
				<v-col cols="12" md="4">
					<div class="type-header">Number of events</div>
					<div class="event-data-wrapper">
						<div class="event-types" v-for="(ended, name) in types.ended" :key="ended.id">
							<div class="event-type-name">{{ name }} ({{ ended.max_capacity }})</div>
							<div class="event-type-value">{{ ended.amount }}</div>
							<v-progress-linear height="7" rounded :value="ended.amount_percentage"></v-progress-linear>
							<div class="event-type-value">{{ benchmarkTypes.ended[name].amount }}</div>
							<v-progress-linear
								color="#888"
								height="7"
								rounded
								:value="benchmarkTypes.ended[name].amount_percentage"
							></v-progress-linear>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="4">
					<div class="type-header">Average ticket price</div>
					<div class="event-data-wrapper">
						<div class="event-types" v-for="(ended, name) in types.ended" :key="ended.id">
							<div class="event-type-name">{{ name }} ({{ ended.max_capacity }})</div>
							<div class="event-type-value">{{ ended.avg_ticket_price }} {{ $config.CURRENCY }}</div>
							<v-progress-linear height="7" rounded :value="ended.avg_ticket_price_percentage"></v-progress-linear>
							<div class="event-type-value">{{ benchmarkTypes.ended[name].avg_ticket_price }} {{ $config.CURRENCY }}</div>
							<v-progress-linear
								color="#888"
								height="7"
								rounded
								:value="benchmarkTypes.ended[name].avg_ticket_price_percentage"
							></v-progress-linear>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="4">
					<div class="type-header">Marketing cost per fan</div>
					<div class="event-data-wrapper">
						<div class="event-types" v-for="(ended, name) in types.ended" :key="ended.id">
							<div class="event-type-name">{{ name }} ({{ ended.max_capacity }})</div>
							<div
								class="event-type-value"
							>{{ ended.cost_per_fan === null ? 'no-data' : `${ended.cost_per_fan} ${$config.CURRENCY}`}}</div>
							<v-progress-linear height="7" rounded :value="ended.cost_per_fan_percentage"></v-progress-linear>
							<div
								class="event-type-value"
							>{{ benchmarkTypes.ended[name].cost_per_fan === null ? 'no-data' : `${benchmarkTypes.ended[name].cost_per_fan} ${$config.CURRENCY}` }}</div>
							<v-progress-linear
								color="#888"
								height="7"
								rounded
								:value="benchmarkTypes.ended[name].cost_per_fan_percentage"
							></v-progress-linear>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-col>
	</div>
</template>

<script>
export default {
	name: "typecard",
	props: {
		concerts: Object,
		isConcertInRange: Function
	},
	data: () => ({
		activeEvents: true,
		endedEvents: false,
		types: {},
		benchmarkTypes: {},

		endedAvgPriceHighest: 0,
		endedNumberHighest: 0,
		endedCostPerFanHighest: 0,
		activeAvgPriceHighest: 0,
		activeNumberHighest: 0,
		activeCostPerFanHighest: 0
	}),
	created() {
		this.initTypes();
		this.$bus.$on("getTypes", this.initTypes);
		console.log(this.types);
		console.log("-----o----");
		console.log(this.benchmarkTypes);
	},
	methods: {
		initTypes() {
			this.types = this.getTypes({ benchmark: false });
			this.benchmarkTypes = this.getTypes({ benchmark: true });
		},
		getTypes({ benchmark }) {
			if (!benchmark) this.types = {};
			else this.benchmarkTypes = {};
			// OBJECT TO STORE THE SORTED DATA
			let CONCERTDATA_BY_TYPE_ACTIVE = {
				stadium: {
					amount: 0,
					amount_percentage: 0,
					avg_ticket_price: 0,
					avg_ticket_price_percentage: 0,
					cost_per_fan: 0,
					cost_per_fan_percentage: 0,
					ticket_prices_merged: 0,
					ticket_sales: 0,
					ticket_types: 0,
					marketing_sum: 0,
					max_capacity:'25000+'
				},
				arena: {
					amount: 0,
					amount_percentage: 0,
					avg_ticket_price: 0,
					avg_ticket_price_percentage: 0,
					cost_per_fan: 0,
					cost_per_fan_percentage: 0,
					ticket_prices_merged: 0,
					ticket_sales: 0,
					ticket_types: 0,
					marketing_sum: 0,
					max_capacity:'25000'
				},
				theatre: {
					amount: 0,
					amount_percentage: 0,
					avg_ticket_price: 0,
					avg_ticket_price_percentage: 0,
					cost_per_fan: 0,
					cost_per_fan_percentage: 0,
					ticket_prices_merged: 0,
					ticket_sales: 0,
					ticket_types: 0,
					marketing_sum: 0,
					max_capacity:'5000'
				},
				club: {
					amount: 0,
					amount_percentage: 0,
					avg_ticket_price: 0,
					avg_ticket_price_percentage: 0,
					cost_per_fan: 0,
					cost_per_fan_percentage: 0,
					active: 0,
					ticket_prices_merged: 0,
					ticket_sales: 0,
					ticket_types: 0,
					marketing_sum: 0,
					max_capacity:'1500'
				}
			};

			let CONCERTDATA_BY_TYPE_ENDED = {
				stadium: {
					amount: 0,
					amount_percentage: 0,
					avg_ticket_price: 0,
					avg_ticket_price_percentage: 0,
					cost_per_fan: 0,
					cost_per_fan_percentage: 0,
					ticket_prices_merged: 0,
					ticket_sales: 0,
					ticket_types: 0,
					marketing_sum: 0,
					max_capacity:'25000+'
				},
				arena: {
					amount: 0,
					amount_percentage: 0,
					avg_ticket_price: 0,
					avg_ticket_price_percentage: 0,
					cost_per_fan: 0,
					cost_per_fan_percentage: 0,
					ticket_prices_merged: 0,
					ticket_sales: 0,
					ticket_types: 0,
					marketing_sum: 0,
					max_capacity:'25000'
				},
				theatre: {
					amount: 0,
					amount_percentage: 0,
					avg_ticket_price: 0,
					avg_ticket_price_percentage: 0,
					cost_per_fan: 0,
					cost_per_fan_percentage: 0,
					ticket_prices_merged: 0,
					ticket_sales: 0,
					ticket_types: 0,
					marketing_sum: 0,
					max_capacity:'5000'
				},
				club: {
					amount: 0,
					amount_percentage: 0,
					avg_ticket_price: 0,
					avg_ticket_price_percentage: 0,
					cost_per_fan: 0,
					cost_per_fan_percentage: 0,
					active: 0,
					ticket_prices_merged: 0,
					ticket_sales: 0,
					ticket_types: 0,
					marketing_sum: 0,
					max_capacity:'1500'
				}
			};

			// HELPER FUNCTION TO REDUCE ALL SUMS TO ONE NUMBER
			const reduceSums = ({ item: item, key: key, layers: layers, key2: key2 }) => {
				if (layers === 1) {
					return item
						.reduce((amount, ticket) => {
							amount.push(+ticket[key]);
							return amount;
						}, [])
						.reduce((a, b) => a + b);
				}
				if (layers === 2) {
					if (item) {
						return item
							.reduce(
								(amount, market) => {
									if (market[key]) {
										market[key].forEach(market => {
											amount.push(+market[key2]);
										});
									}
									return amount;
								},
								[0]
							)
							.reduce((a, b) => a + b);
					} else {
						return 0;
					}
				}
			};

			// LOOP THROUGH CONCERTS AND GET ALL AVAILABLE TICKETS FOR EACH CONCERT
			// THEN PUSH THE CONCERT THAT MATCH THE MAX RANGE TO THE CONCERT_DATA_BY_TYPE OBJECT
			const MAX_RANGES = {
				club: 1500,
				theatre: 5000,
				arena: 25000
			};

			this.concerts.forEach(async concert => {
				if (
					this.isConcertInRange({
						concert: concert,
						benchmark: benchmark
					})
				) {
					if (concert.tickets) {
						let concertdata;
						if (
							new Date(concert.date_end ? concert.date_end : concert.date) >
							new Date()
						) {
							concertdata = CONCERTDATA_BY_TYPE_ACTIVE;
						} else {
							concertdata = CONCERTDATA_BY_TYPE_ENDED;
						}
						const AVAILABLE_TICKETS = reduceSums({
							item: concert.tickets,
							key: "available",
							layers: 1
						});
						if (AVAILABLE_TICKETS < MAX_RANGES.club) {
							concertdata.club.amount++;
							concertdata.club.ticket_types += +concert.tickets.length;
							concertdata.club.ticket_prices_merged += +reduceSums({
								item: concert.tickets,
								key: "price",
								layers: 1
							});
							concertdata.club.marketing_sum += +reduceSums({
								item: concert.marketing,
								key: "marketing_data",
								layers: 2,
								key2: "amount"
							});
							concertdata.club.ticket_sales += +reduceSums({
								item: concert.tickets,
								key: "ticket_sales",
								layers: 2,
								key2: "amount"
							});
						} else if (AVAILABLE_TICKETS < MAX_RANGES.theatre) {
							concertdata.theatre.amount++;
							concertdata.theatre.ticket_types += concert.tickets.length;
							concertdata.theatre.ticket_prices_merged += +reduceSums({
								item: concert.tickets,
								key: "price",
								layers: 1
							});
							concertdata.theatre.marketing_sum += +reduceSums({
								item: concert.marketing,
								key: "marketing_data",
								layers: 2,
								key2: "amount"
							});
							concertdata.theatre.ticket_sales += +reduceSums({
								item: concert.tickets,
								key: "ticket_sales",
								layers: 2,
								key2: "amount"
							});
						} else if (AVAILABLE_TICKETS < MAX_RANGES.arena) {
							concertdata.arena.amount++;
							concertdata.arena.ticket_types += concert.tickets.length;
							concertdata.arena.ticket_prices_merged += +reduceSums({
								item: concert.tickets,
								key: "price",
								layers: 1
							});
							concertdata.arena.marketing_sum += +reduceSums({
								item: concert.marketing,
								key: "marketing_data",
								layers: 2,
								key2: "amount"
							});
							concertdata.arena.ticket_sales += +reduceSums({
								item: concert.tickets,
								key: "ticket_sales",
								layers: 2,
								key2: "amount"
							});
						} else {
							concertdata.stadium.amount++;
							concertdata.stadium.ticket_types += +concert.tickets.length;
							concertdata.stadium.ticket_prices_merged += +reduceSums({
								item: concert.tickets,
								key: "price",
								layers: 1
							});
							concertdata.stadium.marketing_sum += +reduceSums({
								item: concert.marketing,
								key: "marketing_data",
								layers: 2,
								key2: "amount"
							});
							concertdata.stadium.ticket_sales += +reduceSums({
								item: concert.tickets,
								key: "ticket_sales",
								layers: 2,
								key2: "amount"
							});
						}
					}
				}
			});

			const CONCERT_ENDED = CONCERTDATA_BY_TYPE_ENDED;
			const CONCERT_ACTIVE = CONCERTDATA_BY_TYPE_ACTIVE;

			const SET_AVERAGE_TICKET_PRICE = (() => {
				for (let key in CONCERT_ENDED) {
					if (CONCERT_ENDED[key].ticket_types > 0)
						CONCERT_ENDED[key].avg_ticket_price = Math.round(
							CONCERT_ENDED[key].ticket_prices_merged /
								CONCERT_ENDED[key].ticket_types
						);
					else
						CONCERT_ENDED[key].avg_ticket_price =
							CONCERT_ENDED[key].ticket_prices_merged;
				}
				for (let key in CONCERT_ACTIVE) {
					if (CONCERT_ACTIVE[key].ticket_types > 0)
						CONCERT_ACTIVE[key].avg_ticket_price = Math.round(
							CONCERT_ACTIVE[key].ticket_prices_merged /
								CONCERT_ACTIVE[key].ticket_types
						);
					else
						CONCERT_ACTIVE[key].avg_ticket_price =
							CONCERT_ACTIVE[key].ticket_prices_merged;
				}
			})();

			const SET_COST_PER_FAN = (() => {
				for (let key in CONCERT_ENDED) {
					if (CONCERT_ENDED[key].marketing_sum > 0 && CONCERT_ENDED[key].ticket_sales > 0)
						CONCERT_ENDED[key].cost_per_fan = Math.round(
							CONCERT_ENDED[key].marketing_sum / CONCERT_ENDED[key].ticket_sales
						);
					else CONCERT_ENDED[key].cost_per_fan = null;
				}
				for (let key in CONCERT_ACTIVE) {
					if (
						CONCERT_ACTIVE[key].marketing_sum > 0 &&
						CONCERT_ENDED[key].ticket_sales > 0
					)
						CONCERT_ACTIVE[key].cost_per_fan = Math.round(
							CONCERT_ACTIVE[key].marketing_sum / CONCERT_ACTIVE[key].ticket_sales
						);
					else CONCERT_ACTIVE[key].cost_per_fan = null;
				}
			})();

			const SET_PERCENTAGE = (() => {
				if (!benchmark) {
					// DEFAULT VALUES FOR ENDED CONCERTS
					this.endedAvgPriceHighest = 0;
					this.endedNumberHighest = 0;
					this.endedCostPerFanHighest = 0;

					// DEFAULT VALUES FOR ACTIVE CONCERTS
					this.activeAvgPriceHighest = 0;
					this.activeNumberHighest = 0;
					this.activeCostPerFanHighest = 0;
				}

				// GET AND SET THE HIGHEST VALUE FROM ALL ITEMS
				for (let key in CONCERT_ENDED) {
					if (CONCERT_ENDED[key].avg_ticket_price > 0) {
						if (CONCERT_ENDED[key].avg_ticket_price > this.endedAvgPriceHighest)
							this.endedAvgPriceHighest = CONCERT_ENDED[key].avg_ticket_price;
					}
					if (CONCERT_ENDED[key].amount > 0) {
						if (CONCERT_ENDED[key].amount > this.endedNumberHighest)
							this.endedNumberHighest = CONCERT_ENDED[key].amount;
					}
					if (CONCERT_ENDED[key].cost_per_fan !== null) {
						if (CONCERT_ENDED[key].cost_per_fan > this.endedCostPerFanHighest)
							this.endedCostPerFanHighest = CONCERT_ENDED[key].cost_per_fan;
					}
				}
				// FIND ITEM WITH HIGHEST VALUE AND SET PERCENTAGES RELATED TO THAT ONE
				for (let key in CONCERT_ENDED) {
					if (this.endedAvgPriceHighest === CONCERT_ENDED[key].avg_ticket_price) {
						if (this.endedAvgPriceHighest === 0)
							CONCERT_ENDED[key].avg_ticket_price_percentage = 0;
						else CONCERT_ENDED[key].avg_ticket_price_percentage = 100;
					} else {
						CONCERT_ENDED[key].avg_ticket_price_percentage = Math.round(
							(CONCERT_ENDED[key].avg_ticket_price / this.endedAvgPriceHighest) * 100
						);
					}
					if (this.endedNumberHighest === CONCERT_ENDED[key].amount) {
						if (this.endedNumberHighest === 0) CONCERT_ENDED[key].amount_percentage = 0;
						else CONCERT_ENDED[key].amount_percentage = 100;
					} else {
						CONCERT_ENDED[key].amount_percentage = Math.round(
							(CONCERT_ENDED[key].amount / this.endedNumberHighest) * 100
						);
					}
					if (this.endedCostPerFanHighest === CONCERT_ENDED[key].cost_per_fan) {
						if (this.endedCostPerFanHighest === 0)
							CONCERT_ENDED[key].cost_per_fan_percentage = 0;
						else CONCERT_ENDED[key].cost_per_fan_percentage = 100;
					} else {
						if (CONCERT_ENDED[key].cost_per_fan !== null) {
							CONCERT_ENDED[key].cost_per_fan_percentage = Math.round(
								(CONCERT_ENDED[key].cost_per_fan / this.endedCostPerFanHighest) *
									100
							);
						} else {
							CONCERT_ENDED[key].cost_per_fan_percentage = 0;
						}
					}
				}

				// GET AND SET THE HIGHEST VALUE FROM ALL ITEMS
				if (!benchmark) {
					for (let key in CONCERT_ACTIVE) {
						if (CONCERT_ACTIVE[key].avg_ticket_price > 0) {
							if (CONCERT_ACTIVE[key].avg_ticket_price > this.activeAvgPriceHighest) {
								this.activeAvgPriceHighest = CONCERT_ACTIVE[key].avg_ticket_price;
							}
						}
						if (CONCERT_ACTIVE[key].amount > 0) {
							if (CONCERT_ACTIVE[key].amount > this.activeNumberHighest) {
								this.activeNumberHighest = CONCERT_ACTIVE[key].amount;
							}
						}
						if (CONCERT_ACTIVE[key].cost_per_fan !== null) {
							if (CONCERT_ACTIVE[key].cost_per_fan > this.activeCostPerFanHighest)
								this.activeCostPerFanHighest = CONCERT_ACTIVE[key].cost_per_fan;
						}
					}
				}

				// FIND ITEM WITH HIGHEST VALUE AND SET PERCENTAGES RELATED TO THAT ONE
				for (let key in CONCERT_ACTIVE) {
					if (this.activeAvgPriceHighest === CONCERT_ACTIVE[key].avg_ticket_price) {
						if (this.activeAvgPriceHighest === 0)
							CONCERT_ACTIVE[key].avg_ticket_price_percentage = 0;
						else CONCERT_ACTIVE[key].avg_ticket_price_percentage = 100;
					} else {
						CONCERT_ACTIVE[key].avg_ticket_price_percentage = Math.round(
							(CONCERT_ACTIVE[key].avg_ticket_price / this.activeAvgPriceHighest) *
								100
						);
					}
					if (this.activeNumberHighest === CONCERT_ACTIVE[key].amount) {
						if (this.activeNumberHighest === 0)
							CONCERT_ACTIVE[key].amount_percentage = 0;
						else CONCERT_ACTIVE[key].amount_percentage = 100;
					} else {
						CONCERT_ACTIVE[key].amount_percentage = Math.round(
							(CONCERT_ACTIVE[key].amount / this.activeNumberHighest) * 100
						);
					}
					if (this.activeCostPerFanHighest === CONCERT_ACTIVE[key].cost_per_fan) {
						if (this.activeCostPerFanHighest === 0)
							CONCERT_ACTIVE[key].cost_per_fan_percentage = 0;
						else CONCERT_ACTIVE[key].cost_per_fan_percentage = 100;
					} else {
						if (CONCERT_ACTIVE[key].cost_per_fan !== null) {
							CONCERT_ACTIVE[key].cost_per_fan_percentage = Math.round(
								(CONCERT_ACTIVE[key].cost_per_fan / this.activeCostPerFanHighest) *
									100
							);
						} else {
							CONCERT_ACTIVE[key].cost_per_fan_percentage = 0;
						}
					}
				}
			})();

			return {
				active: CONCERT_ACTIVE,
				ended: CONCERT_ENDED
			};
		}
	}
};
</script>

<style lang="scss" scoped>
#typecard {
	width: 100%;
	background: #fff;
	border-radius: 3px;
	.type-header {
		text-transform: uppercase;
		color: $font-color;
		font-size: 0.9rem;
		font-weight: 600;
		border-bottom: 1px solid $font-color;
	}
	.event-data-wrapper {
		.event-types {
			margin-top: 20px;
			font-size: 0.9rem;
			color: $font-color-pale;
			.event-type-name {
				font-size: 0.9rem;
				text-transform: uppercase;
				font-weight: 500;
			}
			.event-type-value {
				font-size: 0.9rem;
				font-weight: 600;
				margin-top: 5px;
			}
		}
	}
}
</style>