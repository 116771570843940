var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.EditMarketingTypeDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"750"},model:{value:(_vm.EditMarketingTypeDialog),callback:function ($$v) {_vm.EditMarketingTypeDialog=$$v},expression:"EditMarketingTypeDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"header"},[_vm._v("Add dates and amount for "+_vm._s(_vm.localMarketingInfo.name))])]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{class:{ 'pa-3': _vm.isMobile }},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-col',{staticClass:"pa-5 pb-3 pt-0",attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{class:{ 'pa-0': _vm.isMobile },attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Name","outlined":"","dense":"","hide-details":"","filled":""},model:{value:(_vm.localMarketingInfo.name),callback:function ($$v) {_vm.$set(_vm.localMarketingInfo, "name", $$v)},expression:"localMarketingInfo.name"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Estimate","type":"number","outlined":"","dense":"","suffix":_vm.$config.CURRENCY,"hide-details":"","filled":""},model:{value:(
                                            _vm.localMarketingInfo.estimate
                                        ),callback:function ($$v) {_vm.$set(_vm.localMarketingInfo, "estimate", $$v)},expression:"\n                                            localMarketingInfo.estimate\n                                        "}})],1)],1)],1),_c('v-divider'),_c('v-col',{staticClass:"pa-5 pb-0 pt-3",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"add-row-header"},[_vm._v("Add row")]),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":"Amount","type":"number","outlined":"","dense":"","suffix":_vm.$config.CURRENCY,"hide-details":"","filled":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c('v-col',{staticClass:"d-flex justify-center",class:{ 'py-5': _vm.isMobile },attrs:{"cols":"12","md":"5"}},[_c('DatePicker',{staticClass:"pa-0",style:(_vm.isMobile
                                                ? 'float:left!important;padding:0px;'
                                                : ''),attrs:{"showButton":false,"defaultDates":_vm.defaultDates},on:{"setStartDate":_vm.setStartDate,"setEndDate":_vm.setEndDate}})],1),_c('v-col',{style:(_vm.isMobile
                                            ? 'padding-left:0px!important;'
                                            : ''),attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"add-btn",attrs:{"disabled":this.amount === '',"block":"","depressed":"","color":"primary"},on:{"click":_vm.addRow}},[_vm._v("Add")])],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"added-rows-header d-flex px-6",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"remove-placeholder"}),_c('span',[_vm._v("Date span")]),_c('v-spacer'),_c('span',[_vm._v("Amount")])],1),_c('v-col',{staticClass:"added-rows-wrapper pa-0",attrs:{"cols":"12","md":"12"}},[_vm._l((_vm.localMarketingInfo.marketing_data),function(row,i){return _c('div',{key:row.id,staticClass:"added-row d-flex align-center"},[_c('v-btn',{staticClass:"remove-row-btn",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteRow(row.marketing_dataid, i)}}},[_c('v-icon',[_vm._v("remove_circle_outline")])],1),_c('span',{staticClass:"startdate"},[_vm._v(_vm._s(row.startdate))]),_c('v-icon',{staticClass:"mr-2 ml-2",attrs:{"color":"primary"}},[_vm._v("arrow_right_alt")]),_c('span',{staticClass:"enddate"},[_vm._v(_vm._s(row.enddate))]),_c('v-spacer'),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(row.amount)+" "+_vm._s(_vm.$config.CURRENCY))])],1)}),(
                        _vm.currentMarketingInfo.marketing_data.length === 0 &&
                        _vm.rowsToBePosted.length === 0
                    )?_c('div',{staticClass:"no-row-placeholder"},[_c('span',[_vm._v("No rows added")])]):_vm._e()],2)],1),_c('v-card-actions',[_c('v-spacer'),(
                    _vm.rowsToBePosted.length != 0 ||
                    _vm.nameChangesMade ||
                    _vm.estimateChangesMade
                )?_c('span',{staticClass:"unsaved-changes-alert"},[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" Unsaved changes, press the \"Save\" button to save your work.")],1):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('closeEditMarketingType')}}},[_vm._v("close")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.rowsToBePosted.length === 0 &&
                    !_vm.nameChangesMade &&
                    !_vm.estimateChangesMade},on:{"click":_vm.saveRows}},[_vm._v(" Save ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }