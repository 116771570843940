<template>
    <div class="notifications-wrapper" v-on-clickaway="hideNotifications">
        <v-btn
            class="menu-action-icon"
            :class="{ active: showNotifications}"
            icon
            color="primary"
            @click="toggleNotifications"
        >
            <v-icon>notifications</v-icon>
            <div class="dot" v-if="unRead > 0">
                <span>{{ unRead }}</span>
            </div>
        </v-btn>
        <div class="notifications" v-show="showNotifications">
            <v-list v-if="showNotifications" style>
                <div class="header d-flex pr-5 align-center relative">
                    <div class="notifications-title">Notifications</div>
                    <v-spacer></v-spacer>
                    <v-btn v-on="on" icon small @click="actionsOpen = true">
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                    <div
                        class="notification-actions"
                        v-if="actionsOpen"
                        v-on-clickaway="hideActions"
                    >
                        <div class="mark-as-read" v-ripple @click="markAllAsRead">
                            <v-icon>done</v-icon>Mark all as read
                        </div>
                    </div>
                </div>
                <div
                    class="px-2"
                    @click="goTo('/' + notification.link === '/' ? '' : '/' + notification.link, notification)"
                    v-ripple="false"
                    v-for="notification in notifications"
                    :key="notification.noticeid"
                >
                    <div class="notication-wrapper">
                        <div class="notification-info">
                            <div class="user-icon-wrapper">
                                <div class="user-icon">
                                    <span>{{ getInitials(notification.creator) }}</span>
                                    <div
                                        class="icon-type-wrapper"
                                        :class="colorByType(notification.type)"
                                    >
                                        <v-icon v-if="notification.type.includes('comment')">comment</v-icon>
                                        <v-icon v-if="notification.type.includes('marked')">done</v-icon>
                                        <v-icon
                                            v-if="notification.type.includes('status')"
                                        >mdi-information-variant</v-icon>
                                        <v-icon
                                            v-if="notification.type.includes('date')"
                                        >mdi-calendar-refresh-outline</v-icon>
                                        <v-icon
                                            v-if="notification.type.includes('assigned a budget')"
                                        >mdi-file-move</v-icon>
                                        <v-icon
                                            v-if="notification.type.includes('approved')"
                                        >thumb_up</v-icon>
                                        <v-icon
                                            v-if="notification.type.includes('assigned a task')"
                                        >mdi-playlist-edit</v-icon>
                                    </div>
                                </div>
                            </div>
                            <div class="notification-body">
                                <div class="not-read" v-if="notification.unread"></div>
                                <span class="creator-type">
                                    <b>{{ notification.creator}}</b>
                                    {{ notification.type.replace(/_/g, ' ')}}
                                </span>
                                <span class="created">{{ notification.created}}</span>
                                <br />
                                <span class="object">{{ notification.object}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </v-list>
        </div>
    </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
import { mapGetters } from "vuex";
import ObjectFactory from "@/models/ObjectFactory";

export default {
    name: "notifications",
    directives: {
        onClickaway: onClickaway,
    },
    data: () => ({
        notifications: [],
        showNotifications: false,
        noticesObj: {},
        unRead: 0,
        lazyIsActive: [],
        actionsOpen: false,
        element: null,
        counter: 0,
        intervalTimer: null,
    }),
    watch: {
        "$route.path": {
            handler: function () {
                this.showNotifications = false;
            },
        },
        element(v) {
            console.log(v);
        },
    },
    methods: {
        async getNotifications() {
            const limit = 15;
            this.noticesObj = await this.$ObjectFactory.BuildNoticeCollection({
                myUserId: this.userInfo.userid,
                limitParams: `?limit=${this.counter},${limit}`,
            });
            this.counter += limit;
            this.notifications = this.noticesObj.getCollection();

            await this.checkForNewNotifications();

            this.intervalTimer = setInterval(
                this.checkForNewNotifications,
                100000
            );
        },
        async checkForNewNotifications() {
            console.log(this.noticesObj);
            this.unRead = await this.noticesObj.checkForNewNotices();
        },
        goTo(link, notification) {
            if (link === "//") {
                link = "/";
            }
            this.noticesObj.markAsRead(notification);
            this.unRead--;
            if (this.$route.path !== link) this.$router.push(link);
        },
        async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (scrollTop + clientHeight >= scrollHeight * 0.9) {
                await this.noticesObj.getNextNotices(this.counter, 10);
                this.counter += 10;
            }
        },
        test() {
            clearInterval(this.intervalTimer);
        },
        async toggleNotifications() {
            this.showNotifications = !this.showNotifications;
            this.unRead = 0;
            await this.noticesObj.updateLastNoticeRead();
        },
        markAllAsRead() {
            this.noticesObj.markAsRead();
            this.actionsOpen = false;
            this.unRead = 0;
        },
        hideNotifications() {
            this.showNotifications = false;
        },
        hideActions() {
            this.actionsOpen = false;
        },
        getInitials(creator) {
            const [first, last] = creator.split` `;
            return `${first[0]}${last[0]}`;
        },
        colorByType(type) {
            if (type.includes("comment")) return "comment-type";
            if (type.includes("marked")) return "checklist-type";
            if (type.includes("status")) return "status-type";
            if (type.includes("date")) return "date-type";
            if (type.includes("approval") || type.includes("approved"))
                return "approval-type";
            if (type.includes("task")) return "todo-type";
        },
    },
    beforeDestroy() {
        console.log("destroy");
        clearTimeout(this.intervalTimer);
    },
    async created() {
        await this.getNotifications();
    },
    computed: {
        ...mapGetters({
            userInfo: "user/userInfo",
        }),
    },
};
</script>

<style lang="scss" scoped>
.menu-action-icon {
    width: 35px !important;
    margin-left: 10px;
    height: 35px !important;
    position: relative;
    i {
        font-size: 25px !important;
    }
    &:last-child {
        margin-right: 0px !important;
    }
    .dot {
        min-width: 15px;
        height: 15px;
        border-radius: 10px;
        display: flex;
        position: absolute;
        bottom: -5px;
        right: 5px;
        background: red;
        span {
            margin: auto;
            color: #fff;
            font-size: 9px;
        }
    }
}

.active {
    background: #306aa11c;
}

.mobile .notifications {
    width: calc(100% - 40px);
    max-width: 350px;
}

.notifications-wrapper {
    z-index: 200;
    .notifications {
        height: calc(100vh - 70px);
        overflow: auto;
        overscroll-behavior: contain;
        position: absolute;
        top: 47px;
        background: #fff;
        right: 20px;
        border-radius: 10px;
        width: 350px;
        box-shadow: 0px 4px 9px 0px #00000047;
        .header {
            position: relative;
            .notification-actions {
                position: absolute;
                right: 20px;
                bottom: -35px;
                background: #fff;
                box-shadow: 0px 2px 6px 0px #0000003f;
                font-size: 0.9rem;
                color: $font-color;
                z-index: 5;
                padding: 0px;
                border-radius: 3px;
                font-weight: 500;
                cursor: pointer;
                .mark-as-read {
                    display: flex;
                    align-items: center;
                    padding: 7px 10px;
                    transition: all 0.2s ease;
                    i {
                        margin-right: 7px;
                        font-size: 18px;
                    }
                    &:hover {
                        background: #ebebeb;
                        border-radius: 3px;
                    }
                }
            }
        }
        a {
            text-decoration: none;
            color: inherit !important;
        }

        .v-list-item {
            padding: 0px 7px;
        }

        .comment-type {
            background: #ffa500;
        }

        .checklist-type {
            background: #8ed136;
        }

        .status-type {
            background: #a930b8;
        }

        .date-type {
            background: #609ae1;
        }

        .checklist-type {
            background: #8ed136;
        }

        .approval-type {
            background: #64dfee;
        }

        .todo-type {
            background: #f96c6c;
        }

        .notifications-title {
            float: left;
            padding: 5px 15px 10px 15px;
            font-size: 1rem;
            font-weight: 600;
            color: $font-color;
        }

        .content-list {
            max-height: 600px;
            float: left;
            width: 100%;
        }
        .notication-wrapper {
            width: 100%;
            height: auto;
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding: 10px 10px 10px 7px;
            transition: all 0.2s ease;
            .notification-info {
                display: flex;
                align-items: center;
                .user-icon-wrapper {
                    min-width: 40px;
                    height: 40px;
                    float: left;
                    display: flex;
                    align-items: center;
                    .user-icon {
                        width: 40px;
                        height: 40px;
                        background: $font-color;
                        border-radius: 55px;
                        display: flex;
                        position: absolute;
                        .icon-type-wrapper {
                            position: absolute;
                            z-index: 1;
                            width: 22px;
                            height: 22px;
                            border-radius: 25px;
                            display: flex;
                            bottom: -10px;
                            right: 0px;
                            i {
                                margin: auto;
                                color: #fff;
                                font-size: 15px;
                            }
                        }
                        span {
                            margin: auto;
                            color: #fff;
                            font-size: 1.2rem;
                            font-weight: 400;
                            text-transform: uppercase;
                        }
                    }
                }
                .notification-body {
                    font-size: 0.8rem;
                    padding-left: 0px;
                    float: left;
                    padding-left: 10px;
                    line-height: 1.25;
                    position: relative;
                    .not-read {
                        position: absolute;
                        background: $accent-color;
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        right: 5px;
                        top: calc(50% - 5px);
                    }
                    .creator-type {
                        margin-right: 5px;
                        float: left;
                        width: 100%;
                    }
                    .created {
                        color: rgb(148, 148, 148);
                    }
                    .object {
                        color: $font-color;
                    }
                }
            }
            &:hover {
                background: #ebebeb;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }
}
</style>