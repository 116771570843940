<template>
    <div>
        <div class="info-text">
            When using a desktop the navigation bar is on the left hand side
            where you can navigate easily to any main page in the system.
        </div>
        <img src="/" />
        <div class="info-text">
            For even better shortcuts use the search functionality that follows
            you wherever you are in the system located in the top menu.<br />
            Search for any name or title for any type of item in the system and
            you will get a list of results grouped per type of item. <br />Click
            on the result you want to navigate to in order to do so.
        </div>
        <img src="/" />
        <div class="info-text">
            In the top menu you will also find your notifications.<br />If you
            have new notifications you will se a number in a red circle (showing
            the number of new notifications).<br />
            You can mark the notifications as read by XXXXXX or click on them to
            go to the concert, budget or tour it relates with.
        </div>
        <img src="/" />
        <div class="info-text">
            In the system you will also notice some “history chips” that
            accumulate the more you work in the system.<br />
            They show where you have been in the system (excluding main pages
            that can be reached directly from the side menu). <br />This is a
            way of quickly navigate between different pages back and forth.<br />If
            you want to clean up your history chips you simply click on the “x”
            to remove a chip. Removing a chip will not close any page or tab.
        </div>
        <img src="/" />
        <div class="info-text">- Statisk "go back" knapp</div>
        <img src="/" />
        <div class="info-text"> - Toggla mini-meny</div>
        <img src="/" />
        <img src="/" />
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>