<template>
    <div>
        <div class="info-text">
            This page is the center of a concert in the system. <br />It’s from
            here you get everything from insights on sales and marketing to the
            possibility <br />to follow up your budget or check in on your team
            and what they are doing.
        </div>
        <img src="/" />
        <div class="info-text">
            At the top bar you can select the dates you want to see insights
            for. <br />The period on-sale to concert start-date is selected
            automatically but if you for example want to dig deeper into the
            least week of sales data you<br />
            can select those dates here and then click “get data” to update the
            page with ticket sales and marketing for the selected period.
        </div>
        <img src="/" />
        <div class="info-text">
            KPI’s like “Sales” and “Revenue” will be updated for this specific
            period only but the KPI <br />“Until Break-even” will stay untouched
            because it has to look at the whole period to make sense.
        </div>
        <img src="/" />
        <div class="info-text">
            At the top-bar you also have a sub-menu for the concert. <br />By
            clicking the three dots you will find links to Budget history, to
            Create/Edit budget, to Create document,<br />
            to add campaigns, and to add benchmark. <br />The budget is covered
            in another section but we will go through create document and add
            campaigns here
        </div>
        <img src="/" />
        <img src="/" />
        <div class="info-text">
            A pop-out is triggered by clicking on the sub-menu and then “Create
            document”.
        </div>
        <img src="/" />
        <div class="info-text">
            This pop-out lists all available document templates that can be
            created. <br />They are sorted by type. If you select an email
            template you will get the readymade template to copy to your email
            client.<br />
            If you on the other hand choose a word document template you can
            either export it to your <br />computer or you can chose to send it
            over GetAccept if it’s a contract you want to send for
            signatures.<br />
            The word documents are both available to be exported to word as well
            as PDF.<br />
            You need to select whether or not you want to send it via GetAccept
            before being able to download the document.
        </div>
        <img src="/" />
        <div class="info-text">
            The Facebook campaign pop-out that you can trigger by clicking on
            the sub-menu (three dots) is how you add facebook <br />
            marketing to your concert directly from Facebook Business Manager.
            <br />Simply search in the list of campaigns and select the one you
            want to add before clicking on the button Apply.
        </div>
        <img src="/" />
        <div class="info-text">
            The last functionality in the sub menu is the possibility to add a
            benchmark to your concert. <br />The benchmark can be either an old
            concert or it can be a saved set of comparable projects from the
            comparable project tab.<br />Select the one benchmark you want to
            benchmark your concert with and voilá – you have a benchmark to
            compare with.<br />
            For more details on the comparable project benchmark functionality.
            Read the information for the comparable project page.
        </div>
        <img src="/" />
        <div class="info-text">
            The insight section starts with three KPI’s for your concert.<br />
            The concert needs to be past the pre-sale or on-sale date to start
            accumulating data for the sales insights.<br /><br />
            The first KPI is the number of tickets sold and a sold percentage
            bar below the KPI
        </div>
        <img src="/" />
        <div class="info-text">
            The second KPI shows the amount of revenues for the concert in local
            currency.<br />
            Below is also a sold percentage but based on sales in local currency
            and not in units. <br />Big difference in ticket prices can make a
            difference between these two percentages.
        </div>
        <img src="/" />
        <div class="info-text">
            The last KPI is showing how many tickets left to be sold until the
            concert reaches break-even financially.<br />
            The progress bar shows the same KPI but in a percentage where 100 %
            is at break-even.
        </div>
        <img src="/" />
        <div class="info-text">
            The sales progress insights is a more detailed view of the ticket
            sales. The donut-graph shows the summary of all sales based on the
            selection between Unit/local currency and Total/Percent. Total
            always shows the total number of tickets sold or total revenue.
            Percent shows how many percent of the total has been sold. This can
            vary a bit between Unit and local currency if there is big
            differences between different ticket prices. The progress bars below
            shows the same selection made for the donut-graph but divided into
            each ticket type.
        </div>
        <img src="/" />
        <div class="info-text">
            The checklist is a fixed set of things defined by Live Nation that
            usually needs to be done before and during a concert. To check an
            item in the checklist you need to click and hold until the checkbox
            stays green. This is a feature so that you don’t click an item by
            mistake. When a checklist item has been marked as done the rest of
            the team will see that you have done so on the concert wall (more
            about the wall soon).
        </div>
        <img src="/" />
        <div class="info-text">
            The list of to-do’s is the same as on the homepage but only showing
            the to-do’s that’s yours and that are connected with this specific
            concert. <br />To read more about to-do’s in general. Go to the
            chapter for the home page.
        </div>
        <img src="/" />
        <div class="info-text">
            The list of to-do’s is the same as on the homepage but only showing
            the to-do’s that’s yours and that are connected with this specific
            concert. <br />To read more about to-do’s in general. Go to the
            chapter for the home page.
        </div>
        <img src="/" />
        <div class="info-text">
            The sales graph includes both a graph over sales of tickets per day
            and the number of visitors to the Live Nation-homepage through
            Google Analytics (when available).<br />
            In this graph you will also be able to see if there are any
            pre-sales. The timeline in the graph is based on the selected dates
            in the date-picker but you can also zoom in and out in the graph by
            scrolling or dragging the bars in the small thumbnail-graph below.
        </div>
        <img src="/" />
        <div class="info-text">
            If you want to save the graph as a picture, simply click the button
            on the right of the graph.
        </div>
        <img src="/" />
        <div class="info-text">
            If you selected a benchmark in the sub-menu this is where you find
            the graph of sales for the benchmark. The sales has been aligned
            with your current concert.
        </div>
        <img src="/" />
        <div class="info-text">
            The marketing graph shows all campaigns and the amount of money
            spent per day.
        </div>
        <img src="/" />
        <div class="info-text">
            You can add marketing manually by clicking the “Add/Edit marketing
            type” button.
        </div>
        <img src="/" />
        <div class="info-text">
            In the pop-out you see a list of possible marketing categories (the
            same as in the budget) with corresponding budget estimates if there
            are any.
        </div>
        <img src="/" />
        <div class="info-text">
            Select the type you want to add. If there isn’t any budget estimate
            from before, you can change that here directly.<br />
            Click on add to add the campaign type to the list below.
        </div>
        <img src="/" />
        <div class="info-text">
            Then click the pen-button to trigger a new pop-out where row’s can
            be added. One row contains the whole budget for a campaign including
            a start date and an end date for that campaign. The sum is spread
            evenly between the days selected. You can add multiple rows of
            different date intervals with different budgets. Click save, and
            then close to see your newly added campaign. It’s also possible to
            edit a campaign or remove it completely from the same menu.
        </div>
        <img src="/" />
        <div class="info-text">
            The marketing details below the marketing graph can be viewed either
            as a total cost or as a percentage. The total cost is the total
            spent on marketing. The percentage is for the donut a percentage of
            spent on marketing compared with the total amount for marketing in
            the latest approved budget. The percentage per marketing type is
            instead the percentage of spent on marketing for that type compared
            with what was budgeted for that specific marketing type.
        </div>
        <img src="/" />
        <div class="info-text">
            The profit and loss statement gives a summary of the project to date
            result, the budget for the concert and a column showing the
            difference between these two. It follows the same structure as the
            budgeting tool. The project to date is based on actual sales and
            actual marketing costs added in the system. The other costs are
            pulled from the budget. The budget is the first approved budget
            while the results in project to dates pick costs from the latest
            approved budget.
        </div>
        <img src="/" />
        <div class="info-text">
            In the top right corner there is a button with a pen over some rows.
            This button is to unfold the edit slider. The edit slider contains
            all fields for the information you can add to a concert. This is a
            quick way of changing any type of information for a concert. Once
            you have made your changes you need to click “save”.
        </div>
        <img src="/" />
        <img src="/" />
        <div class="info-text">
            The icon beside with two conversation bubbles is the other slider
            related to a specific concert. This button triggers “the wall”. The
            Wall works like a notification functionality for this specific
            concert. You can see who has done what for the concert, but you can
            also chat with your team directly on the wall.
        </div>
        <img src="/" />
        <img src="/" />
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>