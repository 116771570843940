<template>
	<div  style="display:flex;">
		<div class="loader-wrapper" v-if="loaderStyle=='fill'">
			<div class="spinner-wrapper">
			<v-progress-circular
				indeterminate
				color="primary"
			></v-progress-circular>
			</div>
		</div>
		<div class="loader-wrapper" v-if="loaderStyle === 'linear'">
			<v-progress-linear
				indeterminate
				class="linear"
				color="primary"
			></v-progress-linear>
		</div>
		<div class="loader-wrapper-pageheader"  v-if="loaderStyle === 'linear-pageheader'">
			<v-progress-linear
				indeterminate
				class="linear"
				color="primary"
				
			></v-progress-linear>
		</div>
		<v-progress-circular style="margin:auto;" v-if="loaderStyle=='free'" indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
  export default {
		props:{
			loaderStyle:String,
		},
    	name:'loader'
  }
</script>

<style scoped>

.loader-wrapper{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index:99999;
  display:flex;
}
.loader-wrapper-pageheader{
  position:absolute;
  left:0;
  top:100%;
  width:100%;
  height:100%;
  z-index:99999;
  display:flex;
}

.spinner-wrapper{
  margin:auto;
}

</style>