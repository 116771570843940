<template>
	<v-dialog v-model="dialog" persistent min-width="400" max-width="600">
		<v-card>
			<v-card-title primary-title>{{ options.text }}</v-card-title>
			<v-card-text>{{ options.subText }}</v-card-text>
			<v-card-actions>
				<v-btn text @click="cancelDialog">{{ options.denyBtnText }}</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="confirmDialog(options.btnText)">{{ options.btnText }}</v-btn>
				<v-btn
					color="primary"
					text
					@click="confirmDialog(options.secBtnText)"
					v-if="options.secBtnText != ''"
				>{{ options.secBtnText }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		dialog: Boolean,
		options: Object
	},

	created() {},

	methods: {
		confirmDialog(choice) {
			const CONF_OBJ = {
				response: choice == this.options.btnText ? true : false,
				type: this.options.type
			};
			this.$emit("confirmDialog", CONF_OBJ);
		},

		cancelDialog() {
			this.$emit("confirmDialog", { cancel: true });
		}
	}
};
</script>

<style lang="scss" >
</style>