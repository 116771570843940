<template>
    <div>
        <div class="info-text">
            Browse is a functionality throughout the system for each type of
            item in the system.<br />
            All main pages for
            concerts/tours/artists/venues/organizations/people/invoices leads to
            a browse page for browsing through each type of items.
        </div>
        <img src="/" />
        <div class="info-text">
            Browse is a functionality throughout the system for each type of
            item in the system. <br />All main pages for
            concerts/tours/artists/venues/organizations/people/invoices leads to
            a browse page for browsing through each type of items.
        </div>
        <img src="/" />
        <div class="info-text">
            This will take you to a new page to fill in information about the
            item you want to create. <br />Fill in all the required fields as a
            minimum before saving in order to create your new item. Browse have
            two main functions.<br />
            One being to find whatever you are looking for using advanced search
            and filter functionality.<br />
            The other one is about being able to create reports from this page.
            We will go though the functionality for doing so.<br />
            The search field is used to quickly search among all fields possible
            for the specific type of item you’re browsing.<br />
            It will search for information that isn’t showing in the browse
            table as well making it a powerful tool for finding what you’re
            looking for quickly.
        </div>
        <img src="/" />
        <div class="info-text">
            <div class="info-text">
                If you want more complex searches among the results you can use
                the filter functionality.<br />
                You can add filters using the different types of logics
                available. <br />These can be “equal to”, “greater than” or
                “less than”. You can also add additional rows using the and/or
                contingency where <br />“and” which forces both rules to apply
                while “or” suggests that one of the created filter rules need to
                apply. <br />
                If you read your filter rules out loud, they should make sense
                what you’re filtering. <br />When you’re happy with your filter
                you can either just “apply” it, or you can save it to be able to
                reuse it later as well. <br />The list of your saved filters is
                accessible here as well and you can bring a saved filter up and
                apply it as well. <br />It’s possible to add more than one
                filter to your browse page and by clicking on an active filter
                you’re able to inspect the rules of this filter.
            </div>
        </div>
        <img src="/" />
        <div class="info-text">
            You can also change what information is visible in the browse
            table.<br />
            You remove columns by clicking the “x”, and you add columns by
            putting your cursor at the end of<br />
            all selection and start typing the name of the column you want to
            add.
        </div>
        <img src="/" />
        <div class="info-text">
            If you want to export the table you have created you simply click on
            the “export to excel” button. <br />The full list of results with
            the defined columns will then be downloaded to your computer.
        </div>
        <img src="/" />
        <div class="info-text">
            When browsing concerts there is also a button called “forecast
            export”. <br/>This button triggers a pop-up where you can set the dates
            for what concerts you want to forecast.<br/> By clicking “Forecast” you
            will get the dates between the first of the current month until
            December 31st. <br/>If you instead hit “budget” you will get the full
            next year. <br/> All concerts that are confirmed with a start date between
            the selected dates and with a budget will be  <br/>exported and downloaded
            to your computer in a .csv-format ready for Oracle /BPC to process
            and read.
        </div>
        <img src="/" />
        <img src="/" />
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>