import { mapGetters } from "vuex";
export default {
    props: {
        portableFormView: String,
        isPortable: Boolean,
        emitObject: Boolean,
        expandVersion: Boolean,
        fromApiObj: Object
    },
    data: () => ({
        isDataLoaded: false,
        view: "",
        pathid: "",
        showRequiredFieldsError: false,
        apiObject: null,
        updatedApiObject: null,
        noChangesMade: true,
        isTableDataPending: true,
        originalData: [],
        items: [],
        buttonText: "Save",
        showDeleteDialog: false,
        metaParam: "",
        splitInfoCollection: null,
        ref_list: [
            'venues',
            'artists'
        ],
        dropdown_lists: {},
    }),
    watch: {

    },
    computed: {
        getEditTableTitle() {
            let title = "";
            if (!this.apiObject) return "";

            switch (this.view) {
                case "concerts":
                    title = this.apiObject.title;
                case "invoices":
                    title = this.apiObject.invoice_name;
                default:
                    title = this.apiObject.name;
            }

            return this.metaParam === "create" ? "Unnamed" : title;
        },
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
    beforeCreate() {
        let ref_list = [
            { name: 'venues', component: 'HandleVenue' },
            { name: 'artists', component: 'HandleArtist' },
        ]
        // can only access props like this in beforeCreate()
        let portableView = this.$options.propsData.portableFormView;
        let view = portableView ? portableView : this.$getView(this.$route.path);
        // Since this is a circular import, we have to declare it in beforeCreate
        for (let ref of ref_list) {
            if (ref.name == view)
                this.$options.components.HandleExtra = require(`./forms/FormExtensions/${ref.component}.vue`).default;

        }

        console.log('before create done');
    },
    async created() {
        this.setLoading();
        this.metaParam = 'create';
        this.buttonText = 'create';

        console.log('EDIT ITEM MIXIN');


        // This is for the portable creation. This is ALWAYS metaParam create
        if (this.portableFormView) {
            this.view = this.portableFormView;
            this.apiObject = await this.$ObjectFactory.BuildObject({ endpoint: this.view, fromApiObj: this.fromApiObj ? this.fromApiObj : null });

        } else { // this is for normal creation/edit

            this.view = this.$getView(this.$route.path);
            this.pathid = this.$getRouteId(this.$route.path);

            this.apiObject = await this.$ObjectFactory.BuildObject({ endpoint: this.view });

            if (!this.$route.path.includes("create")) {
                this.metaParam = 'update';
                await this.apiObject.fetchObject(this.pathid);
                this.buttonText = this.metaParam;
            }
        }

        // fetch dropdownlists
        await this.fetchDropdownLists();

        this.isDataLoaded = true;
        this.unSetLoading();
        this.handleHistoryView();
    },
    async updated() {
        console.log('edittable updated');
    },
    methods: {

        /**
         * setData starts the validation and creation flow.
         * @param {Object} - stayOnPage will not force to the user back to the browse table after creation.   
         */
        async setData({ stayOnPage = false }) {
            console.log('editItemMixin setData');
            const handle_gf_ref = this.$refs.handleGeneralFormRef;

            /**
             * Try to validate and collect the form data
             * If successful, the validate() function will return an object,
             * data == { valid: true, formItems: this.formItems, fk_tables: this.fk_tables },
             * or false if it isn't valid.
             */
            let data = handle_gf_ref.validate();


            if (data.valid) {
                /**
                 * Data was valid, so we start the creation flow of the validated form.
                 * When the post is done, we collect it's new id and 
                 * a copy of the now real Api object.
                 */
                const { newid, apiObj } = await handle_gf_ref.startCreation(data);
                console.log('creation successful', newid);

                /**
                 * If a custom form extension exists, start its special creation flow.
                 */
                if (this.ref_list.includes(this.view))
                    await this.$refs.extraFormRef.startCreation(newid, this.metaParam);

                /**
                 * If we are not creating the api object from a portable form,
                 * or we haven't been ordered to stay on the page,
                 * we move the user back to the browse table
                 * where the api object now will be visible.
                 */
                if (!this.isPortable && !stayOnPage)
                    this.$router.go(-1);

                /**
                 * If we have been ordered to send the object back to what ever is using this component,
                 * we do a local emit back to the parent.
                 */
                if (this.emitObject)
                    this.$emit('objectCreated', newid, apiObj)

                this.updatedApiObject = apiObj;
            } else {
                console.log('missing data')
            }
        },

        async handleHistoryView() {
            /**
             * Do not add any history chip when you youre editing a concert, tour or artist.
             */

            if (this.$route.path.includes('concerts/edit') || this.$route.path.includes('artists/edit') || this.$route.path.includes('tours/edit')) {
                return
            }
            if (this.checkPath()) {
                this.$bus.$emit("setHistoryView", {
                    display: this.apiObject.name,
                    route: this.$route.path,
                });
            } else {
                this.$bus.$emit("setHistoryView", {
                    display: `Create  ${this.view.slice(-1) === "s"
                        ? this.view.substring(0, this.view.length - 1)
                        : this.view
                        }`,
                    route: this.$route.path,
                });
            }

        },
        checkPath() {
            return this.$route.path.includes("venues/edit") || this.$route.path.includes("people/edit") || this.$route.path.includes("organizations/edit")
        },
        generalFormUpdate(changesMade, apiObject) {
            //console.log('generalFormUpdate', changesMade)
            this.noChangesMade = changesMade;
            // this.apiObject = apiObject;
        },

        goBack() {
            this.$router.go(-1);
        },

        rnd() {
            return Math.floor(Math.random() * Math.floor(9999));
        },
        openDeleteDialog() {
            this.showDeleteDialog = true;
        },
        closeDeleteDialog() {
            this.showDeleteDialog = false;
        },

        async refreshDropdown(callback) {
            this.dropdown_lists = {};
            await this.fetchDropdownLists();
            await callback();
        },

        async fetchDropdownLists() {
            let metaObj = await this.apiObject.getMetaFields(this.metaParam);
            for (let meta of metaObj) {
                if (meta.DATA_TYPE == 'fk_object' || meta.DATA_TYPE == 'multiselect') {
                    if (!this.dropdown_lists[meta.FK_TABLE])
                        this.dropdown_lists[meta.FK_TABLE] = await this.$fetchDropdownList(meta)
                }
            }
            console.log('DROPDOWN LIST')
            console.log(this.dropdown_lists)
        },

        async deleteColumn(id) {
            try {
                let response = await this.$DeleteService(
                    this.$getEditRoute(this.$route.path),
                    {
                        [this.apiObject.pk_field]: id,
                    }
                );
                if (response.status === 200) {
                    this.$bus.$emit("unSetLoading");
                    this.$router.go(-1);
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        },

        setLoading() {
            this.$emit("tablePending", true);
        },
        unSetLoading() {
            this.isTableDataPending = false;
            this.$emit("tableLoaded");
        },
        closeEditDrawer() {
            this.items = {};
            this.$emit("closeEditDrawer");
        },
    },
}