<template>
	<div>
		<div class="donut-chart-wrapper">
			<v-progress-circular
				:rotate="-90"
				:size="220"
				:width="10"
				:value="value"
				:color="value > 100 ? 'success' : 'primary'"
			>
				<div class="progress-percentage">
					<div class="value">{{ (sum == undefined) ? (value + '%') : (sum +' '+ sumType) }} </div>
					<div class="text">{{ donutSubtext }}</div>
					<div class="text" v-if="donutSubtext === 'tickets sold'">(Excl. comps)</div>
				</div>
			</v-progress-circular>
		</div>
	</div>
</template>

<script>
	export default {
		name:'DonutChart',
		props:{
			donutSubtext:String,
			value:Number,
			sum: Number,
			sumType: String,
		},
		created(){
		},
		data:()=>({
		})
	}
</script>

<style lang="scss" scoped>
.donut-chart-wrapper{
	width:100%;
	display:flex;
	justify-content: center;
	float:left;
	.progress-percentage{
		line-height:1.3;
		.value{
			font-size:1.5em!important;
			font-weight:600;
			text-align:center;
			color:$font-color;
		}
		.text{
			text-align:center;
			font-size:0.9rem;
			font-weight:400;
			text-transform: uppercase;
			color:$font-color-pale;
			
		}
	}
}





</style>