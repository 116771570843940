<template>
	<div id="tabbrowser" class="elevation-1">
		<v-progress-linear indeterminate :active="!tableReady" />
		<v-tabs
			:centered="centered"
			:grow="grow"
			:vertical="vertical"
			:right="right"
			:icons-and-text="icons"
			show-arrows
		>
			<v-tabs-slider></v-tabs-slider>
			<v-tab v-for="(tab, index) in tabnames.length" :key="tab.id" :href="`#tab-${tabnames[index]}`">
				{{ tabnames[index] }}
			</v-tab>

			<v-tab-item v-for="(tab, index) in tabnames.length" :key="tab.id" :value="'tab-' + tabnames[index]">
				<v-card flat tile>
					<DataTable
						v-if="tableReady"
						:activeTab="tabnames[index]"
						:tableHeaders="tableHeaders"
						:tableData="tableData"
					/>
				</v-card>
			</v-tab-item>
		</v-tabs>
	</div>
</template>

<script>
import DataTable from "./DataTable.vue";
import { mapGetters } from "vuex";

export default {
	name: "tabs",
	components: {
		DataTable,
	},
	props: {
		//tabs:Number,
		items: Object,
		tabnames: Array,
		ready: Boolean,
		findPeople: Boolean,
	},
	data: () => ({
		tableReady: false,
		tableHeaders: {},
		metaFields: {},
		tableData: {
			people: [],
		},
		excludeHeaders: [
			"tickets",
			"gross_available",
			"predicted_sales",
			"rowid",
			"gross_percentage",
			"gross_sales",
			"sold_percentage",
			"analytics",
			"marketing",
			"sales_prediction",
			"budgets",
			"wall_events",
		],
		icons: false,
		centered: false,
		grow: true,
		vertical: false,
		prevIcon: false,
		nextIcon: false,
		right: false,
		metaDataHeaders: [],
	}),
	computed: {
        ...mapGetters({
			meta_data: "metadata/meta_data",
		}),
    },
	watch: {
		items: {
			handler(v) {
				this.getTableData();
			},
			deep: true,
		},
	},
	async created() {
		for (let name of this.tabnames) {
			let text = "Actions";
			if (name == "documents") text = "Download";
			this.tableHeaders[name] = [];
		}
		await this.initHeaders();
		await this.getTableData();

		await this.createHeaders(this.tabnames);
		this.tableReady = true;
	},
	methods: {
		async initHeaders() {
			for (let table in this.tableHeaders) {
				await this.getHeaderInfo(table);

				// let i = 2;
				// // Move role up to i:th place in tableheaders
				// let role = this.tableHeaders[table].find( v => { return v.value == 'role' })
				// this.moveHeaderInTable(role, table, i++);
				// // concert title up to i:th place in tableheaders
				// let concert = this.tableHeaders[table].find( v => { return v.value == 'concert' })
				// this.moveHeaderInTable(concert, table, i++);
				// // Move role up to i:th place in tableheaders
				// let position = this.tableHeaders[table].find( v => { return v.value == 'position' })
				// this.moveHeaderInTable(position, table, i++);
			}
		},
		// async moveHeaderInTable(header, table, i){
		// 	if(header){
		// 		this.tableHeaders[table].splice(this.tableHeaders[table].indexOf(header), 1);
		// 		this.tableHeaders[table].splice(i, 0, header);
		// 	}
		// },
		async getHeaderInfo(table) {
			this.metaDataHeaders[table] = this.meta_data[table].browse;
			this.metaFields["sold_tickets"] = "int";
			this.alignRightHeadersWithDigits(table);
		},
		alignRightHeadersWithDigits(table) {
			for (let key in this.tableHeaders[table]) {
				if (this.tableHeaders[table][key].value !== "actions") {
					if (
						this.metaFields[this.tableHeaders[table][key].value] === "int" ||
						this.metaFields[this.tableHeaders[table][key].value === "float"]
					) {
						this.tableHeaders[table][key].align = "right";
					}
					if (this.tableHeaders[table][key].value === "tickets_available") {
						this.tableHeaders[table][key].align = "right";
					}
				}
			}
		},
		createHeaders(tabs) {
			tabs.forEach((tab) => {
				if (this.items[tab]) {
					if (this.items[tab][0]) {
						let headers = Object.keys(this.items[tab][0]); // Create other headers by taking the first post in the array and make header-objects out of the object-keys and push to tableHeaders
						for (let header of headers) {
							if (!this.excludeHeaders.includes(header)) {
								this.tableHeaders[tab].push({ text: header, value: header });
							}
						}
					}
				}
			});
		},
		formatHeader(header) {
			return header.replace(/_/g, " ").replace(/(^|\s)\S/g, (l) => l.toUpperCase()); // Format header received from object (Capitalize and remove underscore)
		},
		async getTableData() {
			this.tabnames.map((key) => {
				this.tableData[key] = this.items[key];
			});
		},
	},
};
</script>

<style lang="scss" scoped>
#tabbrowser {
	border-radius: 3px;
	overflow: hidden;
}
</style>
