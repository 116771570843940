<template>
    <v-dialog width="800" height="800" v-model="show" scrollable persistent transition="dialog-top-transition">
        <v-card>
            <v-card-title class="header">
                Campaigns
            </v-card-title>
            <div class="headers" style="float:left;width:100%;">
                <v-col cols="3" md="3" class="float-left pl-4">Start-End date</v-col>
                <v-col cols="5" md="7" class="float-left pl-5">Name</v-col>
                <v-col cols="3" md="2" class="float-left">Budget</v-col>
            </div>
            <div class="list-wrapper float-left" style="width:100%;position:relative;max-height:700px;overflow:auto;">
                <Loader :loaderStyle="'linear'" v-if="campaignDataPending" />
                <v-list :flat="flat" :dense="dense" class="campaign-list">
                    <v-list-item-group multiple v-model="model" color="primary">
                        <v-list-item v-for="campaign in campaigns" :key="campaign.id" @click="setCampaign(campaign)" class="py-0 px-2">
                            <v-list-item-content class="campaign-wrapper">
                                <div class="d-flex align-center">
                                    <div class="campaign-info">
                                        <v-col class="campaign-date-wrapper pa-0" cols="3" md="3" :class="{ 'd-flex': !isMobile }">
                                            <span class="campaign-date">{{ campaign.startdate }}</span>
                                            <v-icon :class="{ rotate: isMobile }">arrow_right_alt</v-icon>
                                            <span class="campaign-date">{{ campaign.enddate }}</span>
                                        </v-col>
                                        <v-col cols="7" md="7" class="campaign-name">
                                            <span>{{ campaign.name }}</span>
                                        </v-col>
                                        <v-col cols="2" md="2" class="campaign-budget pa-0">
                                            <v-spacer></v-spacer>
                                            <span v-if="campaign.budget"
                                                >{{ $thousandSeperator(+campaign.budget / 100) }} {{ $config.CURRENCY }}</span
                                            >
                                            <span v-else-if="campaign.budget_summed !== 0">
                                                {{ $thousandSeperator(+campaign.budget_summed / 100) }} {{ $config.CURRENCY }}
                                            </span>
                                            <span class="no-budget" v-else>No budget set</span>
                                        </v-col>
                                    </div>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </div>
            <v-card-actions style="position:relative;">
                <v-spacer></v-spacer>
                <v-btn small text @click="$emit('close')">Close</v-btn>
                <v-btn color="primary" small :disabled="noChangesMade" :loading="isUpdateCampaignPending" @click="updateCampaigns" depressed
                    >Apply</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Loader from "@/components/Loader";
import { mapGetters } from "vuex";
import ApiObjectCollection from "@/models/ApiObjectCollection.js";
import ApiObject from "@/models/ApiObject.js";
import ObjectFactory from "@/models/ObjectFactory.js";

export default {
    name: "campaignbrowser",
    components: {
        Loader,
    },
    props: {
        show: Boolean,
        view: String,
    },
    data: () => ({
        campaigns: [],
        currentConcert: {},
        search: "",
        counter: 0,
        scrollbox: null,
        campaignDataPending: false,
        model: [],
        toBeUpdated: [],
        fbItems: [],
        noChangesMade: true,
        isUpdateCampaignPending: false,
        dataIsLoading: false,
    }),
    async created() {
        this.campaignDataPending = true;
        this.toBeUpdated = await this.$ObjectFactory.BuildCollection({ endpoint: "fb_api" });
        await this.getSelectedCampaigns();
        this.getCampaigns();
    },
    watch: {
        "toBeUpdated": {
            handler: function(v) {
                if (this.toBeUpdated.collection.length > 0) this.noChangesMade = false;
                else this.noChangesMade = true;
            },
            deep: true,
        },
    },
    methods: {
        async getCampaigns() {
            try {
                const limit = 50;
                const response = await this.$GetService(`fb_api?concertid=0&limit=${this.counter},${limit}`);
                this.counter += limit;
                console.log(response);
                if (response.status === 200) {
                    this.campaignDataPending = false;
                    console.log(response.data);
                    let i = 0;
                    if (this.fbItems) {
                        for (let campaign of this.fbItems) {
                            this.model.push(i++);
                        }
                    }
                    this.campaigns.push(...response.data);
                    if (this.fbItems && this.counter === limit) {
                        this.campaigns.unshift(...this.fbItems);
                    }
                }

                console.log(this.campaigns);
            } catch (err) {
                console.log(err);
            }
        },
        async getSelectedCampaigns() {
            const viewObject = await this.$ObjectFactory.BuildObject({ endpoint: this.view });
            await viewObject.fetchObject(this.$route.params.id);
            this.fbItems = viewObject.fb_api;
        },
        async setCampaign(campaign) {
            console.log(this.$route);
            campaign.concertid = +campaign.concertid > 0 ? 0 : this.$route.params.id;
            if (this.toBeUpdated.isInCollection(campaign)) {
                console.log("IS IN COLLECTIOn");
                this.toBeUpdated.removeFromCollection(campaign);
            } else {
                this.toBeUpdated.addToCollection(campaign);
            }
        },
        async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (scrollTop + clientHeight >= scrollHeight * 0.9) {
                if (!this.dataIsLoading) {
                    this.dataIsLoading = true;
                    await this.getCampaigns();
                    this.dataIsLoading = false;
                }
            }
        },
        async updateCampaigns() {
            this.isUpdateCampaignPending = true;
            if (await this.toBeUpdated.updateCollection()) {
                this.$emit("addedCampaigns");
                setTimeout(() => {
                    this.isUpdateCampaignPending = false;
                    this.toBeUpdated.clear();
                }, 500);
            }
        },
    },
    computed: {
        filteredCampaigns() {
            return this.campaigns.filter((campaign) => {
                return campaign.name.toLowerCase().match(this.search.toLowerCase());
            });
        },
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
.header {
    font-size: 15px !important;
    text-transform: uppercase;
    color: $accent-color;
    font-weight: 600 !important;
    display: flex;
    padding-bottom: 0px !important;
}

.v-card {
    min-height: 700px;
}

.search-field {
    font-weight: 400;
    margin-bottom: 20px;
    &::v-deep .v-input__control {
        * {
            margin: 0;
        }
    }
}

.v-list {
    padding-top: 0px;
    min-height: 600px;
    max-height: 600px;
    padding-bottom: 0px;
    float: left;
    width: 100%;
    position: relative;
    border-radius: 5px;
}

.no-budget {
    text-transform: uppercase;
    color: rgb(129, 129, 129);
    font-size: 0.77rem;
    font-style: italic;
}

.headers {
    margin-top: 10px;
    border-bottom: 1px solid $border-color;
    div {
        font-size: 0.83rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $font-color;
        &:last-child {
            text-align: right;
        }
    }
}

.v-card__actions {
    border-top: 1px solid $border-color;
}

.campaign-list {
    padding: 10px;
    .v-list-item {
        border-radius: 5px;
        margin-bottom: 5px;
        overflow: hidden;
        &:hover {
            background: #f0f0f0 !important;
        }
    }
}

.rotate {
    transform: rotate(90deg);
	margin-left:20px;
}

.campaign-info {
    display: flex;
    width: 100%;
    .campaign-name {
        font-size: 0.85rem;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        line-height: 1.3;
        span {
            float: left;
        }
    }
    .campaign-date-wrapper {
        float: left;
        align-items: center;
        i {
            float: left;
            font-size: 22px;
            color: $accent-color;
        }
        .campaign-date {
            font-size: 0.8rem;
            color: rgb(129, 129, 129);
            font-weight: 500;
            float: left;
        }
    }
    .campaign-budget {
        text-align: right;
        font-size: 0.86rem;
        color: #000;
        font-weight: 400;
        display: flex;
        align-items: center;
    }
}
</style>
