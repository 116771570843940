<template>
    <div id="todos" class="elevation-1 d-flex">
        <Loader v-if="isTodoPending" :loaderStyle="'linear'" />
        <div class="card-title">Recent todos</div>
        <div class="todos-wrapper">
            <Todo
                v-for="(todo, index) in todos"
                :key="todo.id"
                :index="index"
                :todo="todo"
                :events="events"
                :users="users"
                :view="view"
                :userName="userInfo.fullname"
                @deleteItem="deleteItem"
                @updateTodo="updateTodo"
                ref="unSetLoading"
            />
            <NoDataPlaceholder
                v-if="noTodosFound && todos.length === 0"
                :text="'No todos found'"
            />
        </div>
        <Addtodo
            :concerts="concerts"
            :artists="artists"
            :users="users"
            :view="view"
            v-if="addTodoDialog"
            :addTodoDialog="addTodoDialog"
            @closeAddTodoDialog="closeAddTodoDialog"
            @addTodo="addTodo"
            :isAddTodoPending="isAddTodoPending"
            :sentToUser="sentToUser"
        />
        <div class="add-todo-wrapper">
            <v-btn depressed small color="primary" @click="addTodoDialog = true"
                >Add todo</v-btn
            >
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { setTimeout, clearTimeout } from "timers";
import Loader from "@/components/Loader";
import Addtodo from "./Addtodo.vue";
import Todo from "./Todo.vue";
import NoDataPlaceholder from "@/components/NoDataPlaceholder";
import ApiObject from "@/models/ApiObject";
import Notice from "@/models/Notice";

export default {
    name: "TodoList",
    components: {
        Loader,
        Todo,
        Addtodo,
        NoDataPlaceholder,
    },
    data: () => ({
        view: "",
        addTodoDialog: false,
        noTodosFound: false,
        //Stored Data
        sentToUser: "",
        concerts: [],
        todos: [],
        artists: [],
        people: [],
        users: [],
        //Update to newest todate with intervaltimer
        intervalTimer: null,
        newestTodoDate: "2001-01-01 00:00:00",
        // Loader states
        isTodoPending: true,
        isAddTodoPending: false,
    }),
    async created() {
        this.view = this.$route.path.replace(/[/\d]|edit/g, "");
        await this.fetchEvents();
        await this.fetchArtists();
        await this.fetchUsers();
        this.checkTodos();
        this.intervalTimer = setInterval(this.checkTodos, 100000);
        this.hideLoader();
    },
    methods: {
        addTodo: async function (todo) {
            this.noTodosFound = false;
            let objectName = "General todo";
            let link = "/";
            let apiObj = {};

            if (this.$route.path !== "/") {
                //GET ID
                const { id } = this.$route.params;
                const [path] = this.$route.path.split`/`.filter(
                    (el) => el !== ""
                );
                todo[path.substring(0, path.length - 1) + "id"] = id;
            }
            
            if (+todo.concertid > 0) {
                apiObj = await this.$ObjectFactory.BuildObject({ endpoint: "concerts" });
                await apiObj.fetchObject(todo.concertid);
                objectName = apiObj.title;
                link = `concerts/edit/${todo.concertid}`;
                console.log(link);
            } else if (+todo.artistid > 0) {
                apiObj = await this.$ObjectFactory.BuildObject({ endpoint: "artists" });
                await apiObj.fetchObject(todo.artistid);
                objectName = apiObj.name;
                link = `artists/edit/${todo.artistid}`;
            } else if (+todo.tourid > 0) {
                apiObj = await this.$ObjectFactory.BuildObject({ endpoint: "tours" });
                await apiObj.fetchObject(todo.tourid);
                objectName = apiObj.name;
                link = `tours/edit/${todo.tourid}`;
            }
            try {
                this.isAddTodoPending = true;
                let response = await this.$PostService(
                    this.$getRoute("todos").main_route,
                    todo
                );
                if (response.status == 200) {
                    this.checkTodos();
                    this.users.forEach(async (user) => {
                        if (user.userid === todo.ownerid) {
                            let fullname = user.fullname.replace(" (You)", "");
                            if (fullname === this.userInfo.fullname) {
                                this.addTodoDialog = false;
                                // todo.concertid = this.findInArray(todo.concertid, 'concerts', 'concertid', 'title')
                                // todo.artistid = this.findInArray(todo.artistid, 'artists', 'artistid', 'name')
                                // todo.authorid = this.findInArray(this.userId, 'users', 'userid', 'fullname')
                                // todo.ownerid = this.findInArray(todo.ownerid, 'users', 'userid', 'fullname')
                            } else {
                                this.sentToUser = fullname;
                                this.$bus.$emit("showSentMessageAndHide");
                            }
                        }
                    });

                    this.addNotice(todo.ownerid, objectName, link);
                    this.isAddTodoPending = false;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async addNotice(ownerid, objectName, link) {
            let noticeObj = await Notice.Build("notices");
            noticeObj.postNotice({
                ownerid: ownerid,
                type: "assigned a task for you",
                creator: this.userInfo.userid,
                object: objectName,
                link: link,
            });
        },
        findInArray: function (needle, haystack, indexname, key, key2) {
            let returnstring = "";
            this[haystack].forEach((item) => {
                if (item[indexname] === needle) {
                    key2 == null
                        ? (returnstring = item[key])
                        : (returnstring = `${item[key]} ${item[key2]}`);
                }
            });

            return returnstring;
        },
        deleteItem: async function (todoid, index) {
            try {
                let response = await this.$DeleteService(
                    this.$getRoute("todos").main_route,
                    { todoid: todoid }
                );
                if (response.status === 200) {
                    this.$bus.$emit("unSetLoading");
                    await this.$delete(this.todos, index);
                    if (this.todos.length === 0) {
                        this.noTodosFound = true;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        updateTodo: async function (todo, index) {
        
            // todo.status_done == "1"
            //     ? (todo.status_done = "0")
            //     : (todo.status_done = "1");
            try {
                let response = await this.$PutService(
                    this.$getRoute("todos").main_route,
                    { status_done: todo.status_done, todoid: todo.todoid }
                );
                if (response.status === 200) {
                    this.$set(this.todos, index, todo);
                }
            } catch (error) {
                console.log(error);
            }
        },
        hideLoader: function () {
            this.isTodoPending = false;
        },
        getNewestTodo() {
            let returndate = "2000-01-01 00:00:00";
            if (this.todos.length > 0) {
                let newestTodo = this.todos.reduce((a, b) =>
                    a.created_at > b.created_at ? a : b
                );
                ("");
                if (newestTodo.created_at > "") {
                    returndate = newestTodo.created_at;
                }
            }

            this.newestTodoDate = returndate;
        },
        checkTodos: async function () {
            let routeString = `todos?from=${this.newestTodoDate}`;
            this.view === "artists"
                ? (routeString += `&artistid=${this.$getRouteId(
                      this.$route.path
                  )}`)
                : (routeString = routeString);
            this.view === "concerts"
                ? (routeString += `&concertid=${this.$getRouteId(
                      this.$route.path
                  )}`)
                : (routeString = routeString);
            this.view === "tours"
                ? (routeString += `&tourid=${this.$getRouteId(
                      this.$route.path
                  )}`)
                : (routeString = routeString);
            try {
                const response = await this.$GetService(`${routeString}`);
                if (response.status === 200) {
                    response.data.forEach((obj) => {
                        //console.log(obj)
                        this.todos.unshift(obj);
                    });
                } else if (response.status === 204) {
                    this.noTodosFound = true;
                }
            } catch (error) {
                console.log(error);
            }
            this.getNewestTodo();
        },
        async fetchTodos() {
            let routeString;
            if (this.view === "artists") {
                routeString = `todos?${this.$getRouteId(this.$route.path)}`;
            } else if (this.view === "concerts") {
                routeString = `todos?${this.$getRouteId(this.$route.path)}`;
            } else if (this.view === "tours") {
                routeString = `todos?${this.$getRouteId(this.$route.path)}`;
            } else {
                routeString = "todos";
            }
            try {
                let response = await this.$GetService(`${routeString}`);
                console.log("TODORESPONSE");
                console.log(response);
                if (response.status === 200) {
                    this.todos = response.data;
                    this.hideLoader();
                } else if (response.status === 204) {
                    this.noTodosFound = true;
                    this.hideLoader();
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchEvents() {
            try {
                let response = await this.$GetService(
                    this.$getRoute("concerts").main_route
                );
                this.concerts = response.data;
                console.log(this.concerts);
            } catch (error) {
                console.log(error);
            }
        },
        async fetchArtists() {
            try {
                let response = await this.$GetService(
                    this.$getRoute("artists").main_route
                );
                this.artists = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async fetchUsers() {
            try {
                let response = await this.$GetService(
                    this.$getRoute("users").main_route
                );
                this.users = response.data;
                this.users.forEach((user) => {
                    if (user.fullname === this.userInfo.fullname) {
                        user.fullname += " (You)";
                    }
                });
                // console.log(this.users)
            } catch (error) {
                console.log(error);
            }
        },
        closeAddTodoDialog() {
            this.addTodoDialog = false;
        },
    },
    beforeDestroy() {
        console.log("destroyed");
        clearInterval(this.intervalTimer);
    },
    computed: {
        ...mapGetters({
            userInfo: "user/userInfo",
        }),
    },
};
</script>

<style scoped lang="scss">
#todos {
    min-height: 664px;
    max-height: 664px;
    float: left;
    width: 100%;
    position: relative;
    background: #fff;
    overflow-y: auto;
    flex-direction: column;
    border-radius: 3px;
    z-index:1;
}

.todos-wrapper {
    position: relative;
    flex: 1;
    padding: 10px;
    overflow: auto;
    min-height: 555px;
    overscroll-behavior: contain;
}

.add-todo-wrapper {
    padding: 15px;
    background: #fff;
    border-top: 1px solid #e2e2e2;
    button {
        float: right;
    }
}

.container-mock {
    float: left;
    width: 100%;
    background: #fff;
    padding: 15px;
}

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}
@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-o-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>