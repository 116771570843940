<template>
    <div>
        <v-container class="wizard-budget-container container--fluid pb-0">
            <v-col cols="12" md="12" class="pa-0 pt-0 pb-5">
                <h3 class="wizard-section-title">General</h3>
            </v-col>
            <div class="budget-section-wrapper elevation-1">
                <v-spacer />
                <v-col cols="12" md="12">
                    <v-row>
                        <h2
                            class="wizard-form-title"
                        >Number of shows: {{ stepData.generalData.nbrOfShows }}</h2>
                    </v-row>
                </v-col>
                <v-col cols="12" md="12">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-switch
                                class="wizard-form-text mt-0"
                                label="Co-produced split"
                                v-model="coSplit"
                            />
                        </v-col>
                        <v-col v-if="coSplit" cols="12" md="12" class="pa-0">
                            <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                                <v-text-field
                                    class="wizard-form-text float-left mt-0"
                                    type="Number"
                                    suffix="%"
                                    dense
                                    placeholder="0"
                                    v-model="stepData.generalData.coSplit.value"
                                    filled
                                    hide-details
                                />
                                <span class="float-left ml-5">To Live Nation</span>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-col>
            </div>
        </v-container>

        <v-container class="wizard-budget-container container--fluid pt-0">
            <div class="budget-section-wrapper elevation-1">
                <v-col cols="12" md="12" class="pa-0 mb-5">
                    <h3 class="wizard-container-title">Revenues</h3>
                </v-col>
                <v-spacer />
                <div v-for="(showTickets,index) of stepData.tickets" :key="index">
                    <v-col
                        class="wizard-form-title pa-0 mb-5"
                        v-if="stepData.tickets.length > 1"
                        cols="12"
                        md="12"
                    >Show #{{ index+1 }}</v-col>
                    <div class="budget-revenue-form-wrapper" v-for="ticket of showTickets" :key="ticket.id">
                        <budget-ticket-form
                            :ticket="ticket"
                            @deleteTicket="deleteTicketRevenue"
                            @saveComment="saveComment"
                            class="budget-revenue-form"
                        />
                    </div>
                    <v-row>
                        <v-col cols="12" md="12" class="primary--text d-flex align-center">
                            <v-btn @click.stop="$emit('addTicketRevenue', index+1)" color="primary" depressed>
                                <v-icon left>add</v-icon>
                                Add section/ticket type to show #{{index+1}}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider v-if="index != stepData.tickets.length-1" class="mt-5 mb-5" />
                </div>
                <v-row>
                    <v-col cols="12" md="12" class="primary--text d-flex align-center">
                        <v-btn @click.stop="addNewShow" color="primary" depressed>
                            <v-icon left>add</v-icon>Add show
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <div class="budget-section-wrapper elevation-1">
                <div class="wizard-container-title">Other revenues</div>
                <div
                    class="wizard-form-text"
                    v-for="revenue of stepData.otherRevenues"
                    :key="revenue.id"
                >
                    <budget-revenue-form
                        :revenue="revenue"
                        @deleteOtherRevenue="deleteOtherRevenue"
                    />
                </div>
                <v-col cols="12" md="12" class="primary--text d-flex align-center">
                    <v-row>
                        <v-btn @click.stop="$emit('addOtherRevenue')" color="primary" depressed>
                            <v-icon left>add</v-icon>Add other revenues
                        </v-btn>
                    </v-row>
                </v-col>
            </div>
        </v-container>
    </div>
</template>

<script>
import BudgetTicketForm from "./ConcertTicketForm";
import BudgetRevenueForm from "./ConcertRevenueForm";
export default {
    name: "BudgetStepOne",

    components: {
        BudgetTicketForm,
        BudgetRevenueForm,
    },

    props: {
        stepData: Array,
        concertid: String,
    },

    data: () => ({
        isLoading: [],
        isNewShowLoading: false,
        containerClass: "wizard-budget-container container--fluid",
        coSplit: 0,
        ticketHeaders: [],

        ticketRevenues: 0,
    }),

    created() {
        console.log("stepOne:");
        console.log(this.stepData);

        this.ticketHeaders = [
            "Ticket Type",
            "Total Capacity",
            "Kills",
            "Comps",
            "Other",
            "Sellable Capacity",
            "Est. sales (%)",
            "VAT",
            "Ticket commission",
            `Price Inc Vat (${this.$config.CURRENCY})`,
            "Revenues Inc VAT",
        ];

        this.stepData.tickets.forEach((show) => {
            this.isLoading.push(true);
        });

        this.coSplit = this.stepData.generalData.coSplit.active;
    },

    mounted() {
        ////console.log('step one mounted')
        this.$emit("isLoaded");
    },

    updated() {
        ////console.log('StepOne Updated')
    },

    computed: {},

    watch: {
        "stepData.generalData.coSplit.value": {
            handler(val) {
                if (this.stepData.generalData.coSplit.value > 100)
                    this.stepData.generalData.coSplit.value = 99;
                else if (this.stepData.generalData.coSplit.value < 0)
                    this.stepData.generalData.coSplit.value = 1;
                else this.stepData.generalData.coSplit.value = val;
            },
            deep: true,
        },
        coSplit(val) {
            this.stepData.generalData.coSplit.active = val ? 1 : 0;
            if (!val) this.stepData.generalData.coSplit.value = 0;
        },
    },

    methods: {
        async deleteTicketRevenue(ticket) {
            if (ticket.budget_ticketid != undefined)
                this.stepData.deletedTickets.push(ticket);
            //console.log(this.stepData.tickets[ticket.show_number-1])
            let showIndex = ticket.show_number - 1;
            this.$delete(
                this.stepData.tickets[showIndex],
                this.stepData.tickets[showIndex].indexOf(ticket)
            );
            if (this.stepData.tickets[showIndex].length == 0) {
                console.log("show empty");
                console.log(this.stepData.tickets);
                this.$delete(this.stepData.tickets, showIndex);
                console.log(this.stepData.tickets);

                // set showids
                let i = 1;
                this.stepData.tickets.map((show) => {
                    show.map((ticket) => (ticket.show_number = i));
                    i++;
                });
            }
        },

        async deleteOtherRevenue(revenue) {
            this.stepData.deletedOtherRevenues.push(revenue);
            this.$delete(
                this.stepData.otherRevenues,
                this.stepData.otherRevenues.indexOf(revenue)
            );
        },

        // async addTicketRevenue(showNumber){
        //    let ticket = {
        //       section_name: '',
        //       price: '',
        //       capacity: 0,
        //       kills: '',
        //       comps: '',
        //       other: '',
        //       sellable_percentage: '1.0',
        //       comment: '',
        //       show_number: showNumber,
        //       vat: 0,
        //    }
        //    this.stepData.tickets[showNumber-1].push(ticket)
        //    this.$emit('addTicketRevenue', ticket)
        // },

        async addNewShow() {
            this.$emit("addNewShow");
        },

        // async addOtherRevenue(){
        //    let otherRevenue = {
        //       name: '',
        //       value: 0,
        //       step: '1',
        //       header: 'Other revenues',
        //       comment: '',
        //       budget_row_typeid: '0',
        //       vat: 0,
        //    }
        //    this.stepData.otherRevenues.push(otherRevenue)
        //    this.$emit('updateTriggered')
        // },
    },
};
</script>

<style lang="scss" scoped>


.budget-revenue-form-wrapper{
    &:not(:first-child){
        border-top:1px solid $border-color;
        margin-top:15px;
        padding-top:15px;
    }
}


</style>