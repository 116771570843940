<template>
    <div class="page-container elevation-1">
        <HelpMenu @goToSection="(section) => (currentSection = section)" />
        <div class="info-section-wrapper">
            <div class="section-header">
                {{ currentSection.replace(/_/g, ' ') }}
            </div>
            <div class="info-section">
                <component :is="currentSection"></component>
            </div>
        </div>
    </div>
</template>

<script>
import HelpMenu from "./HelpMenu";
import Browse from "./Sections/Browse.vue";
import Login_and_user_Administration from "./Sections/Login_and_user_Administration.vue";
import General_functionality from "./Sections/General_functionality.vue";
import Start_Page from "./Sections/Start_Page.vue";
import New_Concert from "./Sections/New_Concert.vue";
import Concert_Insights from "./Sections/Concert_Insights.vue";
import Budget_History from "./Sections/Budget_History.vue";
import Concert_Budget from "./Sections/Concert_Budget.vue";
import Tour_Insights from "./Sections/Tour_Insights.vue";
import Tour_Budget from "./Sections/Tour_Budget.vue";
import Artist_Insights from "./Sections/Artist_Insights.vue";
import Venues from "./Sections/Venues.vue";
import Organizations from "./Sections/Organizations.vue";
import People from "./Sections/People.vue";
import Invoices from "./Sections/Invoices.vue";
import Insights from "./Sections/Insights.vue";

export default {
    name: "help",
    components: {
        HelpMenu,
        Login_and_user_Administration,
        General_functionality,
        Start_Page,
        Browse,
        New_Concert,
        Concert_Insights,
        Budget_History,
        Concert_Budget,
        Tour_Insights,
        Tour_Budget,
        Artist_Insights,
        Venues,
        Organizations,
        People,
        Invoices,
        Insights
    },
    data: () => ({
        sectionHeader: "Login and user Administration",
        currentSection: "Login_and_user_Administration",
    }),
    methods: {
        goToSection(section) {
            console.log(section);
        },
	},
	created(){
		console.log('help page created')
	}
};
</script>

<style scoped lang="scss">
.page-container {
    background: #fff;
    padding: 0px;
    height: 100%;
    display: block !important;
    .info-section-wrapper {
        float: left;
        height: 100%;
        padding: 20px;
        max-height: 100%;
        overflow: auto;
        width: calc(100% - 250px);
        .info-text{
            color:$font-color;
            font-size:14px;
        }
        .section-header {
            font-size: 16px;
            margin-bottom: 15px;
            font-weight: 600;
            color: $font-color-pale;
        }
    }
}
</style>
