export function exportTableToExcel(filename, view) {
   let temphtml = $('table').html()
   $("body").append("<table id='exceltbl'>" + temphtml + "</table>");
   if (view !== 'tickets') { 
      $("#exceltbl th:first-child").remove();
      $("#exceltbl td:first-child").remove();
   } else {
      $("#exceltbl th:last-child").remove();
      $("#exceltbl td:last-child").remove();
   }
   var downloadLink;
   var dataType = 'application/vnd.ms-excel;';
   var tableSelect = document.getElementById("exceltbl");
   $("#exceltbl").remove();
   var tableHTML = '<html><head><meta%20http-equiv="Content-Type"%20content="text/html;%20charset=utf-8"></meta></head><body>'+tableSelect.outerHTML.replace(/ /g, '%20')+'</body></html>';
   
   // Specify file name
   filename = filename +'.xls'
   
   // Create download link element
   downloadLink = document.createElement("a");
   
   document.body.appendChild(downloadLink);
   
   if(navigator.msSaveOrOpenBlob){
      var blob = new Blob(['\ufeff', tableHTML], {
         type: dataType
      });
      
      navigator.msSaveOrOpenBlob( blob, filename);
   }else{
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
   
      // Setting the file name
      downloadLink.download = filename;
      
      //triggering the function
      downloadLink.click();
   }

}