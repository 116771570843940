<script>
module.exports = {
    methods: {
        addSalesTableData(tour) {
            /** 
             * SalesTableData is the data displayed at page "Sales" and needs to be calculated using data from tours.
             * This function takes a tour and return it with this new data
             
             * salesTable data 
                1 name                          *** = tour.name
                2 target_show_count             *** = tour.estimate_number_gigs
                3 confirmed_show_count          *** added
                4 percent_target_show_count     *** added
                5 target_fee_per_show           *** = tour.estimate_wage_per_gig
                6 target_total_fee              *** added
                7 confirmed_total_fee           *** added
                8 percent_target_total_fee      *** added
                9 target_commission             *** added
                10 confirmed_total_commission   *** added
                11 agent                        *** add "N/A" if missing else = tour.our_agent.fullname
            */
            
            //convert tour strs to ints
            tour.estimate_number_gigs = parseInt(tour.estimate_number_gigs);
            tour.estimate_wage_per_gig = parseInt(tour.estimate_wage_per_gig);
            tour.artistid.artist_fee = parseInt(tour.artistid.artist_fee);
            tour.artistid.agency_commission = parseInt(tour.artistid.agency_commission);

            //placeholder values for calculations
            let target_commission;
            let confirmed_show_count = 0;
            let confirmed_total_fee = 0;
            let confirmed_total_commission = 0;
            let counted_comissions = 0; // this value is used to see how many concerts are missing before reaching estimated_gigs

            if (tour.concerts) {
                for (let concert of tour.concerts) {
                    concert.agency_commission = parseInt(concert.agency_commission);
                    concert.artist_fee = parseInt(concert.artist_fee);

                    let prioritized_agency_commission = isNaN(concert.agency_commission) ? tour.artistid.agency_commission : concert.agency_commission;
                    // summing together commissions from existing concerts
                    if (tour.estimate_wage_per_gig && prioritized_agency_commission) {
                        if (isNaN(target_commission)) {target_commission = 0;} // needed to turn into value
                        target_commission = target_commission + (tour.estimate_wage_per_gig * prioritized_agency_commission / 100);
                        counted_comissions++;
                    }
                    // summing together comssions from confirmed concerts
                    if (concert.status == "Confirmed") {
                        confirmed_show_count++;
                        if (concert.artist_fee){
							confirmed_total_fee = confirmed_total_fee + concert.artist_fee; // add to confirmed_total_fee
							if (prioritized_agency_commission)
								confirmed_total_commission = confirmed_total_commission + (concert.artist_fee * prioritized_agency_commission) / 100;
						}
                    }
                }
            }

            tour.confirmed_show_count = confirmed_show_count;
            // 4 add percent_target_show_count
            tour.percent_target_show_count = tour.estimate_number_gigs > 0 ? Math.round((tour.confirmed_show_count / tour.estimate_number_gigs) * 100) : 0;
            // 6 add target_total_fee
            tour.target_total_fee = Math.round(tour.estimate_wage_per_gig * tour.estimate_number_gigs);
            // 7 add confirmed_total_fee
            tour.confirmed_total_fee = Math.round(confirmed_total_fee);
            // 8 add percent_target_total_fee
            tour.percent_target_total_fee =  tour.target_total_fee > 0 ? Math.round((confirmed_total_fee / tour.target_total_fee) * 100) : 0;
            // 9 add target_commission
            if (counted_comissions < tour.estimate_number_gigs && (!isNaN(tour.estimate_wage_per_gig) && !isNaN(tour.artistid.agency_commission))) {
                if (isNaN(target_commission)) {target_commission = 0;} // needed to turn into value
                target_commission = target_commission + (tour.estimate_number_gigs - counted_comissions) * ((tour.estimate_wage_per_gig * tour.artistid.agency_commission) / 100);
            }
            tour.target_commission = target_commission;
            // 10 add confirmed_total_commission
            tour.confirmed_total_commission = Math.round(confirmed_total_commission);
            // 11 add "N/A" if missing else = tour.our_agent.fullname
            if (!tour.our_agent) {
                tour.our_agent = { fullname: "N/A" }
            }
            else if (!tour.our_agent) {
                tour.our_agent.fullname = "N/A"
            }
            
            
        return tour;
        },
    },
};
</script>