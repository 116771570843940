<template>
      <div>
      <v-container class="wizard-budget-container container--fluid">

         <v-col cols="12" md="12" class="pa-0 pb-5">
            <h3 class="wizard-section-title">{{ stepHeader }}</h3>
         </v-col>

         <div class="budget-section-wrapper elevation-1">
            <div class="wizard-container-title mb-3">Talent cost</div>
            <v-col cols="12" md="12" class="pt-0 pb-0">
               <div 
                  class="wizard-form-text"
                  v-for="(cost,i) of stepData.costs" :key="cost.id"
               >
                  <budget-five-cost-form 
                     v-if="showForm"
                     :costItem="cost"
                     :formType="formTypes[i]"
                     @deleteCost="deleteCostItem"
                  />
               </div>
            </v-col>
         </div>
         <!-- Global currency toggle -->
         <div class="budget-section-wrapper elevation-1">
            <v-col cols="12" md="12" class="pa-0">
               <div class="wizard-container-title mb-3">Use other currency</div>
               <v-row class="mb-5">
                  
                  <v-col>

                     <v-text-field
                        class="wizard-form-text pt-1"
                        dense
                        required
                        label="Currency name"
                        placeholder=""
                        v-model="currencyName"
                        hide-details
                        filled
                     />
                  </v-col>
                  <v-col>
                     <v-text-field 
                        style="text-align:right"
                        class="wizard-form-text pt-1"
                        type="number"
                        dense
                        required
                        label="Rate"
                        v-model="currencyRate"
                        hide-details
                        filled
                     />
                  </v-col>
               </v-row>
            </v-col>
         </div>
         <div class="budget-section-wrapper elevation-1">
         <v-col cols="12" md="12" class="pa-0">
            <budget-secret-form 
               :isPrivate="isPrivate"
               :userGroups="stepData.userGroups"
               :selectedGroup="selectedGroup"
               @privateChange="setPrivate"
               @groupChange="setGroup"
            />
         </v-col>
         </div>
		</v-container>
   </div>
</template>

<script>

import { BudgetPostItem } from '@/models/BudgetObjects.js'
import BudgetFiveCostForm from './ConcertFiveCostForm'
import BudgetSecretForm from './BudgetSecretForm'

export default {
   name: 'BudgetStepFive',

   components: {
      BudgetFiveCostForm,
      BudgetSecretForm,
   },

   props: {
      stepData: Array,
   },

   data: ()=>({
      stepHeader: 'Talent',
      colNames: [
         'Talent cost', 'Type', 'Value', 'Cost', 'Other currency'
      ],
      step: 5,
      currency: '',
      formTypes: [
         {name:'Minimum gage', val:'1' },
         {name:'Share of net', val:'3'},
         {name:'Share of gross', val:'3'},
         {name:`/ticket`, val:'2'},
      ],
      showForm: false,
      currencyRate: '',
      currencyName: '',
   }),

   watch: {
      'stepData':{
         handler(val){
            console.log('#### step5data watcher ####')
         },
         deep: true
      },
      currencyRate(val){
         if(val > 0)
            this.stepData.costs.map(c => c.currency_rate = val)
         else
            this.stepData.costs.map(c => c.currency_rate = 0)
      },
      currencyName(val){
         this.stepData.costs.map(c => c.currency_name = val)
      },
   },

   computed: {
      isPrivate(){
         return this.stepData.isPrivate
      },
      selectedGroup(){
         return this.stepData.selectedGroup
      },
   },

   async created(){
      console.log('stepFive')
      console.log(this.stepData)
      this.currency = this.$config.CURRENCY;

      this.formTypes[3].name = `${this.currency}/ticket`
      
      // check for currency exchange
      if(this.stepData.costs[0].currency_rate > 0){
         this.currencyRate = this.stepData.costs[0].currency_rate
         this.currencyName = this.stepData.costs[0].currency_name
      }
   },

   mounted(){
      //console.log('stepfive mounted')
      this.$emit('isLoaded')
      this.showForm = true
   },


   methods: {

      async deleteCostItem(costItem){
         if(costItem.budget_rowid != undefined)
            this.stepData.deletedCosts.push(costItem)

         this.$delete(this.stepData.costs, this.stepData.costs.indexOf(costItem))
      },

      async addCostItem(){
         let costItem = new BudgetPostItem()
         costItem.step = this.step
         costItem.header = 'Talent costs'
         this.stepData.costs.push(costItem)
      },

      async setPrivate(val){
         //console.log(`setting private to ${val}`)
         this.stepData.isPrivate = val
      },

      async setGroup(val){
         //console.log(`setting group to ${val}`)
         this.stepData.selectedGroup = val
      }
   }
}

</script>

<style lang="scss">

</style>