<template>
   <div class="no-data-placeholder" :style="!dontHide ? 'background:#fff;' : ''">
      <div class="no-data-wrapper">
         <v-icon>mdi-emoticon-sad-outline</v-icon>
         <span>{{ text }}</span>
      </div>
   </div>
</template>

<script>
   export default {
      name:'Nodataplaceholder',
      props:{
         text:String,
         dontHide:Boolean,
         dataFound:Boolean
      }
   }
</script>

<style lang="scss" scoped>
   .no-data-placeholder{
      position:absolute;
      display:flex;
      width:100%;
      height:100%;
      top:0;
      left:0;
      z-index:0;
      .no-data-wrapper{
         margin:auto;
         padding:10px 15px 10px 10px;
         border-radius:5px;
         i{
            margin-right:10px;
            color:$accent-color;
         }
         span{
            text-transform: uppercase;
            font-size:14px;
            font-weight:600;
            color:#666;
         }
      }
   }
</style>