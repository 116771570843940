const state = {
	userInfo: '',
	userSelections: {
		'venues': { fields: '', user_selectionid: null },
		'people': { fields: '', user_selectionid: null },
		'organizations': { fields: '', user_selectionid: null },
		'artists': { fields: '', user_selectionid: null },
		'concerts': { fields: '', user_selectionid: null },
		'tours': { fields: '', user_selectionid: null },
		'invoices': { fields: '', user_selectionid: null },
	},
	currentView: '',
	visited: [],
	isInitialized: false,
};

const getters = {
	userInfo: state => state.userInfo,
	userSelections: state => state.userSelections,
	visited: state => state.visited,
	currentView: state => state.currentView,
	isInitialized: state => state.isInitialized,
};

const actions = {
	setUserInfo({commit}, userInfo){
		commit('setUserInfo', userInfo)
	},
	setUserSelections({ commit }, payload) {
		commit('setUserSelections', payload)
	},
	setVisited({ commit }, payload) {
		commit('setVisited', payload)
	},
	removeVisited({ commit }, payload) {
		commit('removeVisited', payload)
	},
	setInitialized({ commit }, payload) {
		commit('setInitialized', payload);
	}
};

const mutations = {
	setUserInfo: (state, userInfo) => state.userInfo = userInfo,
	setUserSelections: (state, payload) => {
		payload.forEach(item => {
			state.userSelections[item.table_name].fields = item.fields
			state.userSelections[item.table_name].user_selectionid = item.user_selectionid
		})
	},
	setVisited: (state, history) => {

		const checkForVisited = history => state.visited.find(visit => visit.display === history.display)

		state.currentView = history.display

		if (!checkForVisited(history)) {
			state.visited.push(history)
		}
		if (state.visited.length > 8) {
			state.visited.shift()
		}
	},
	removeVisited: (state, payload) => {
		console.log(payload)
		state.visited = state.visited.filter(item => item.display !== payload.display)
	},
	setInitialized: (state, payload) => {
		state.isInitialized = payload;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};