const config_loc = require("@/../public/config.json");

export const step2 = [
	{ display_name: "VAT", name: "vat", code: "A-1", active: 1, step: "2", header: "Variable costs", sticky: true },
	{
		display_name: "Ticket commission",
		name: "ticket_commission",
		code: "A50310.00",
		active: 1,
		step: "2",
		header: "Variable costs",
		sticky: true,
	},
	{ display_name: "Royalty", name: "royalty", code: "A50326.00", active: 1, step: "2", header: "Variable costs" },
	{
		display_name: "PRS",
		name: "prs",
		code: "A50202.00",
		active: 1,
		step: "2",
		header: "Variable costs",
		sticky: true,
	},
];
export const step3_venue_related = [
	{
		display_name: "Venue rent",
		name: "venue_rent",
		code: "A50148.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{ display_name: "Cleaning", name: "cleaning", code: "A50210.00", active: 1, step: "3", header: "Venue related" },
	{
		display_name: "Confetti cleaning",
		name: "confetti_cleaning",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{ display_name: "Stage", name: "stage", code: "A50136.00", active: 1, step: "3", header: "Venue related" },
	{
		display_name: "Barriers",
		name: "barricades",
		code: "A50204.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Cable covers",
		name: "cable_covers",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{ display_name: "Jumbotron", name: "jumbotron", code: "A50128.00", active: 1, step: "3", header: "Venue related" },
	{
		display_name: "Electrician and equipment",
		name: "electrician_and_equipment",
		code: "A50109.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Pipe and drapes",
		name: "pipe_and_drapes",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Extra equipment",
		name: "extra_equipment",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Track and field cover",
		name: "track_and_field_cover",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Field Damage / Replacement",
		name: "field_damage_replacement",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Field Seating",
		name: "field_seating",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Generators",
		name: "generators",
		code: "A50155.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{ display_name: "CCTV", name: "cctv", code: "A50214.00", active: 1, step: "3", header: "Venue related" },
	{
		display_name: "Damage Contingency",
		name: "damage_contingency",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Hearing Impaired",
		name: "hearing_impaired",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Miscellaneous Rentals",
		name: "misc_rentals",
		code: "A50110.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Portajohns",
		name: "portajohns",
		code: "A50229.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Public Transportation",
		name: "public_transportation",
		code: "A50120.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Ramps / Other Structures",
		name: "ramps_other_structures",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{
		display_name: "Seat Covers / Drapes",
		name: "seat_covers_drapes",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{ display_name: "Signage", name: "signage", code: "A50128.00", active: 1, step: "3", header: "Venue related" },
	{ display_name: "Tables", name: "tables", code: "A50209.00", active: 1, step: "3", header: "Venue related" },
	{
		display_name: "Trash Collection",
		name: "trash_collection",
		code: "A50210.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{ display_name: "Utilities", name: "utilities", code: "A50296.00", active: 1, step: "3", header: "Venue related" },
	{
		display_name: "Wheelchair Accessible",
		name: "wheelchair_accessible",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Venue related",
	},
	{ display_name: "Other", name: "venue_other", code: "A50128.00", active: 1, step: "3", header: "Venue related" },
];
export const step3_staff_related = [
	{
		display_name: "Access Control",
		name: "access_control",
		code: "A50296.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{
		display_name: "Box Office",
		name: "box_office",
		code: "A50206.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{
		display_name: "Check in / Accreditation Team",
		name: "check_in",
		code: "A50296.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{
		display_name: "Cleaning Staff",
		name: "cleaning_staff",
		code: "A50210.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{
		display_name: "Conversion Staff",
		name: "conversion_staff",
		code: "A50296.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{ display_name: "Firemen", name: "firemen", code: "A50296.00", active: 1, step: "3", header: "Staff related" },
	{
		display_name: "Electrician",
		name: "electrician",
		code: "A50109.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{ display_name: "Plumber", name: "plumber", code: "A-1", active: 1, step: "3", header: "Staff related" },
	{
		display_name: "Front of House",
		name: "front_of_house",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{ display_name: "House Nut", name: "house_nut", code: "A50128.00", active: 1, step: "3", header: "Staff related" },
	{
		display_name: "Security - Overnight",
		name: "security_overnight",
		code: "A50214.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{ display_name: "Scanners", name: "scanners", code: "A50296.00", active: 1, step: "3", header: "Staff related" },
	{
		display_name: "Shunter Drivers",
		name: "shunter_drivers",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{
		display_name: "Traffic Control",
		name: "traffic_control",
		code: "A50296.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{
		display_name: "Translators",
		name: "translators",
		code: "A50296.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{
		display_name: "Ushers / Ticket Takers",
		name: "ushers",
		code: "A50296.00",
		active: 1,
		step: "3",
		header: "Staff related",
	},
	{ display_name: "Will Call", name: "will_call", code: "A50296.00", active: 1, step: "3", header: "Staff related" },
];
export const step3_production_related = [
	{
		display_name: "Artist transportation",
		name: "artist_transportation",
		code: "A50120.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Catering equipment",
		name: "catering_equipment",
		code: "A50106.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Communication equipment",
		name: "communication_equipment",
		code: "A50222.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Sound and Lights",
		name: "sound_and_lights",
		code: "A50118.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{ display_name: "Lights", name: "lights", code: "A50118.00", active: 1, step: "3", header: "Production related" },
	{ display_name: "Sound", name: "sound", code: "A50118.00", active: 1, step: "3", header: "Production related" },
	{
		display_name: "Work Lights",
		name: "work_lights",
		code: "A50118.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Dressing room/office equipment",
		name: "dressing_room_office_equipment",
		code: "A50222.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Platforms/risers",
		name: "platforms_risers",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Plywood / Steel plates",
		name: "plywood_and_plates",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{ display_name: "Risers", name: "risers", code: "A50128.00", active: 1, step: "3", header: "Production related" },
	{ display_name: "Staging", name: "staging", code: "A50136.00", active: 1, step: "3", header: "Production related" },
	{ display_name: "Steel", name: "steel", code: "A50128.00", active: 1, step: "3", header: "Production related" },
	{
		display_name: "Fire extinguishers",
		name: "fire_extinguishers",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Forklift Driver",
		name: "forklift_driver",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Forklifts",
		name: "forklifts",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{ display_name: "Cranes", name: "cranes", code: "A50128.00", active: 1, step: "3", header: "Production related" },
	{
		display_name: "Other machinery",
		name: "other_machinery",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Pyro / SFX",
		name: "pyro_sfx",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Phone Persons",
		name: "phone_persons",
		code: "A50296.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Telephones / IT",
		name: "telephones_it",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{ display_name: "Tents", name: "tents", code: "A50209.00", active: 1, step: "3", header: "Production related" },
	{ display_name: "Towels", name: "towels", code: "A50128.00", active: 1, step: "3", header: "Production related" },
	{
		display_name: "Crew Shuttle",
		name: "travels_shuttles_crew",
		code: "A50144.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Shuttle Drivers",
		name: "shuttle_drivers",
		code: "A50144.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Hotels (crew)",
		name: "hotels_crew",
		code: "A50144.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Hotels / Flights",
		name: "hotels_flights",
		code: "A50144.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{ display_name: "Vans", name: "vans", code: "A50120.00", active: 1, step: "3", header: "Production related" },
	{ display_name: "Video", name: "video", code: "A50150.00", active: 1, step: "3", header: "Production related" },
	{
		display_name: "Wristbands",
		name: "wristbands",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Other production related",
		name: "other_production_related",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Assistants - Casual Labor",
		name: "assistants_casual_labour",
		code: "A50180.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Assistants - Dressing Room Staff",
		name: "assistants_dressing_room",
		code: "A50180.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Assistants - Other",
		name: "assistants_other",
		code: "A50180.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Assistants - Runners",
		name: "assistants_runners",
		code: "A50180.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Assistants - Wardrobe",
		name: "assistants_wardrobe",
		code: "A50180.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Backline",
		name: "backline",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Band Transport",
		name: "band_transport",
		code: "A50120.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Cable Ramps / Yellowjackets",
		name: "cable_ramps",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Credentials",
		name: "credentials",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{ display_name: "Doctor", name: "doctor", code: "A50296.00", active: 1, step: "3", header: "Production related" },
	{
		display_name: "Electrical",
		name: "electrical",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{ display_name: "Fencing", name: "fencing", code: "A50203.00", active: 1, step: "3", header: "Production related" },
	{ display_name: "Fuel", name: "fuel", code: "A50128.00", active: 1, step: "3", header: "Production related" },
	{
		display_name: "Furniture",
		name: "furniture",
		code: "A50209.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{ display_name: "Gases", name: "gases", code: "A50128.00", active: 1, step: "3", header: "Production related" },
	{
		display_name: "Golf Carts",
		name: "golf_carts",
		code: "A50296.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Health & Safety",
		name: "health_safety",
		code: "A50220.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Hospitality",
		name: "hospitality",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Hostesses",
		name: "hostesses",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Information Technology",
		name: "information_technology",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Motors / Truss",
		name: "motors_truss",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Offices / Trailers",
		name: "offices_trailers",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{ display_name: "Parking", name: "parking", code: "A50128.00", active: 1, step: "3", header: "Production related" },
	{ display_name: "Permits", name: "permits", code: "A50128.00", active: 1, step: "3", header: "Production related" },
	{
		display_name: "Plumbing",
		name: "plumbing",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Power Distro / Cabling",
		name: "power_distro_cabling",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Production Supplies",
		name: "production_supplies",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Production Supplies - Local",
		name: "production_supplies_local",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Rider Costs",
		name: "rider_costs",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Rigging Analysis",
		name: "rigging_analysis",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Rigging Study",
		name: "rigging_study",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Spot Rental",
		name: "spot_rental",
		code: "A50110.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Upstage Blacks / Backdrop",
		name: "upstage_blacks_backdrop",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Vehicle Rentals",
		name: "vehicle_rentals",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Visqueen",
		name: "visqueen",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Walkies / Radios",
		name: "walkies_radios",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
	{
		display_name: "Other",
		name: "production_other",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Production related",
	},
];
export const step3_catering = [
	{
		display_name: "Crew/Local",
		name: "local_crew_catering",
		code: "A50180.00",
		active: 1,
		step: "3",
		header: "Catering",
	},
	{ display_name: "Artist/Band/Tour", name: "artist_catering", code: "A50106.00", active: 1, step: "3", header: "Catering" },
	{
		display_name: "Band Chef",
		name: "band_chef_catering",
		code: "A50106.00",
		active: 1,
		step: "3",
		header: "Catering",
	},
	{
		display_name: "Assistants",
		name: "assistants_catering",
		code: "A50180.00",
		active: 1,
		step: "3",
		header: "Catering",
	},
	{
		display_name: "Post Show Hospitality",
		name: "post_show_hospitality_catering",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Catering",
	},
	{
		display_name: "Rentals & Hookups",
		name: "rentals_hookups_catering",
		code: "A50128.00",
		active: 1,
		step: "3",
		header: "Catering",
	},
	{ display_name: "Other", name: "other_catering", code: "A50128.00", active: 1, step: "3", header: "Catering" },
	{ display_name: "Ice", name: "ice", code: "A50128.00", active: 0, step: "3", header: "Catering" },
];
export const step3_authority_cost = [
	// {display_name: 'Grammex', name: 'grammex', code: 'A-1', active:1, step:'3', header:'Authority costs'},
	{
		display_name: config_loc.BUDGET_LABELS["public_liability_insurance"],
		name: "public_liability_insurance",
		code: "A50306.00",
		active: 1,
		step: "3",
		header: "Authority costs",
	},
	{
		display_name: "Non-appearance insurance",
		name: "non_appearance_insurance",
		code: "A50306.00",
		active: 1,
		step: "3",
		header: "Authority costs",
	},
	{
		display_name: "Police",
		name: "police_license_and_security",
		code: "A50230.00",
		active: 1,
		step: "3",
		header: "Authority costs",
	},
	{
		display_name: "K-9 / Bomb Sweep",
		name: "k9_sweep",
		code: "A50214.00",
		active: 1,
		step: "3",
		header: "Authority costs",
	},
	{
		display_name: "other",
		name: "authority_other",
		code: "A50399.00",
		active: 1,
		step: "3",
		header: "Authority costs",
	},
];
export const step4 = [
	{
		display_name: "Press advertising",
		name: "press_advertising",
		code: "A50408.00",
		active: 1,
		step: "4",
		header: "Marketing costs",
	},
	{ display_name: "Print", name: "print", code: "A50408.00", active: 1, step: "4", header: "Marketing costs" },
	{ display_name: "Outdoor", name: "outdoor", code: "A50404.00", active: 1, step: "4", header: "Marketing costs" },
	{
		display_name: "Posters/Flyers/leaflets",
		name: "posters_flyers_leaflets",
		code: "A50416.00",
		active: 1,
		step: "4",
		header: "Marketing costs",
	},
	{
		display_name: "Television",
		name: "television",
		code: "A50412.00",
		active: 1,
		step: "4",
		header: "Marketing costs",
	},
	{ display_name: "Radio", name: "radio", code: "A50410.00", active: 1, step: "4", header: "Marketing costs" },
	{
		display_name: "Production",
		name: "marketing_production",
		code: "A50402.00",
		active: 1,
		step: "4",
		header: "Marketing costs",
	},
	{
		display_name: "Publicity & Promotion",
		name: "publicity_and_promotion",
		code: "A50499.00",
		active: 1,
		step: "4",
		header: "Marketing costs",
	},
	{
		display_name: "Prorate - National Campaign",
		name: "prorate_national_campaign",
		code: "A50499.00",
		active: 1,
		step: "4",
		header: "Marketing costs",
	},
	{ display_name: "Facebook", name: "facebook", code: "A50444.00", active: 1, step: "4", header: "Marketing costs" },
	{
		display_name: "Instagram",
		name: "instagram",
		code: "A50444.00",
		active: 1,
		step: "4",
		header: "Marketing costs",
	},
	{ display_name: "Snapchat", name: "snapchat", code: "A50444.00", active: 1, step: "4", header: "Marketing costs" },
	{ display_name: "Twitter", name: "twitter", code: "A50444.00", active: 1, step: "4", header: "Marketing costs" },
	{
		display_name: "Other digital",
		name: "other_digital",
		code: "A50444.00",
		active: 1,
		step: "4",
		header: "Marketing costs",
	},
	{
		display_name: "Other",
		name: "marketing_other",
		code: "A50499.00",
		active: 1,
		step: "4",
		header: "Marketing costs",
	},
];

export const step5 = [
	{
		display_name: "Fixed gage",
		name: "fixed_gage_minimum_gage",
		code: "A50002.00",
		active: 1,
		step: "5",
		header: "Talent costs",
		sticky: true,
	},
	{
		display_name: "Variable gage",
		name: "variable_gage_share_of_net",
		code: "A50002.00",
		active: 1,
		step: "5",
		header: "Talent costs",
		sticky: true,
		type: 3,
	},
	{
		display_name: "Variable gage",
		name: "variable_gage_share_of_gross",
		code: "A50002.00",
		active: 1,
		step: "5",
		header: "Talent costs",
		sticky: true,
		type: 3,
	},
	{
		display_name: "Variable gage",
		name: "variable_gage_sek_ticket",
		code: "A50002.00",
		active: 1,
		step: "5",
		header: "Talent costs",
		sticky: true,
		type: 2,
	},
];
export const step6 = [
	{
		display_name: "Spot ops. est.",
		name: "spot_operators",
		code: "A50128.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{ display_name: "Support 1", name: "support_1", code: "A50014.00", active: 1, step: "6", header: "Other costs" },
	{ display_name: "Support 2", name: "support_2", code: "A50014.00", active: 1, step: "6", header: "Other costs" },
	{
		display_name: "Stage and delay towers",
		name: "stage_and_delay_towers",
		code: "A50128.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{
		display_name: "Artist chargebacks",
		name: "artist_chargebacks",
		code: "A50099.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{
		display_name: "Production invoices",
		name: "production_invoices",
		code: "A50128.00",
		active: 1,
		step: "6",
		header: "Other costs",
		sticky: true,
	},
	{
		display_name: "Promoter profit leveller on production invoices",
		name: "promoter_profit_leveller_on_production_invoices",
		code: "A50128.00",
		active: 1,
		step: "6",
		header: "Other costs",
		sticky: true,
	},
	{
		display_name: "Camera Operators",
		name: "camera_operators",
		code: "A50131.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{
		display_name: "Field Cover Crew",
		name: "field_cover_crew",
		code: "A50131.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{
		display_name: "Forklift Operators",
		name: "forklift_operators",
		code: "A50131.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{
		display_name: "Local Show Crew",
		name: "local_show_crew",
		code: "A50131.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{
		display_name: "Payroll / Agency Fees",
		name: "payroll_agency_fees",
		code: "A50296.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{ display_name: "Pre-Rig", name: "pre_rig", code: "A50128.00", active: 1, step: "6", header: "Other costs" },
	{
		display_name: "Production Manager",
		name: "production_manager",
		code: "A50131.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{
		display_name: "Scaffolding Crew",
		name: "scaffolding_crew",
		code: "A50131.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{ display_name: "Site Crew", name: "site_crew", code: "A50131.00", active: 1, step: "6", header: "Other costs" },
	{
		display_name: "Stage Manager",
		name: "stage_manager",
		code: "A50131.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{ display_name: "Superviser", name: "superviser", code: "A50131.00", active: 1, step: "6", header: "Other costs" },
	{ display_name: "Teamsters", name: "teamsters", code: "A50131.00", active: 1, step: "6", header: "Other costs" },
	{
		display_name: "Traveling Production Crew",
		name: "traveling_production_crew",
		code: "A50131.00",
		active: 1,
		step: "6",
		header: "Other costs",
	},
	{ display_name: "Wardrobe", name: "wardrobe", code: "A50131.00", active: 1, step: "6", header: "Other costs" },
];

export const stickyCosts = [
	"vat",
	"prs",
	"variable_gage_share_of_net",
	"variable_gage_share_of_gross",
	"variable_gage_sek_ticket",
	"production_invoices",
	"promoter_profit_leveller_on_production_invoices",
];

export function getStepArray(step) {
	switch (step) {
		case 2:
			return step2;
		case 3:
			return step3_venue_related.concat(
				step3_staff_related.concat(step3_production_related.concat(step3_catering.concat(step3_authority_cost)))
			);
		case 31:
			return step3_venue_related;
		case 32:
			return step3_staff_related;
		case 33:
			return step3_production_related;
		case 34:
			return step3_catering;
		case 35:
			return step3_authority_cost;
		case 4:
			return step4;
		case 5:
			return step5;
		case 6:
			return step6;
		default:
			break;
	}
}

export function getAllRows(budgetid = undefined) {
	const bo = require("@/models/BudgetObjects.js");
	let arr = [];
	for (let i = 2; i < 7; i++) {
		arr = arr.concat(getStepArray(i));
	}

	const finalArr = arr.reduce((rows, row) => {
		let item = new bo.BudgetPostItem(row.display_name, row.name, 1, 0, row.step, row.header, row.sticky, 0, 0);
		item.budgetid = budgetid;
		item.account_code = row.code;
		rows.push(item);
		return rows;
	}, []);

	return finalArr;
}

export function getCostCodes() {
	let arr = [];
	for (let i = 2; i < 7; i++) {
		arr = arr.concat(getStepArray(i));
	}
	let obj = {};
	for (let row of arr) obj[row.name] = row.code;
	return obj;
}
