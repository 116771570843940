
export function calcPrs(type, tickets){
   let prsCost = 0
   let ticketRevenues = 0
   let totalTickets = 0

   tickets.map(show => {
      if(show.price == undefined){

         show.map(ticket => {
            totalTickets += +ticket.sellable_capacity * +ticket.sellable_percentage
            ticketRevenues += +ticket.sellable_capacity * +ticket.sellable_percentage * getPriceExVat(ticket.price, ticket.vat)
   
            // Calc for Sweden
            if(type == 0){
               let priceExVat = getPriceExVat(ticket.price, ticket.vat)
               for(let range of stim){
                  if(priceExVat <= range.max || range.max == -1){
                     prsCost += ticket.sellable_capacity * ticket.sellable_percentage * range.fee
                     break
                  }
               }
            }
         })
      }
      else { // for calculating prs from concertpage
         let ticket = show
         totalTickets += +ticket.capacity * +ticket.sellable_percentage
         ticketRevenues += +ticket.capacity * +ticket.sellable_percentage * getPriceExVat(ticket.price, ticket.vat)
   
         // Calc for Sweden
         if(type == 0){
            let priceExVat = getPriceExVat(ticket.price, ticket.vat)
            for(let range of stim){
               if(priceExVat <= range.max || range.max == -1){
                  prsCost += ticket.capacity * ticket.sellable_percentage * range.fee
                  break
               }
            }
         }
      }
   })

   // norway
   if(type == 1){ 
      let altPrsCost = 0
      let baseAmount = 0
      let multiplier = 0

      if(ticketRevenues < 18796){
         multiplier = 0.1
         prsCost += 677
         baseAmount = 6767
      }
      else if(ticketRevenues < 56501){
         multiplier = 0.08
         prsCost += 1880
         baseAmount = 18796
      }
      else if(ticketRevenues < 187961){
         multiplier = 0.04
         prsCost += 4897
         baseAmount = 56501
      }
      else{
         multiplier = 0.02
         prsCost += 10156
         baseAmount = 187961
      }
      ticketRevenues - baseAmount > 0 ? prsCost += (ticketRevenues - baseAmount) * multiplier : 0

      // alternative prs cost with totaltickets
      altPrsCost += 677
      totalTickets - 100 > 0 ? altPrsCost += (totalTickets - 100) * 3 : 0

      if(prsCost < altPrsCost)
         prsCost = altPrsCost
   }

   // denmark
   else if(type == 2){ 
      if(ticketRevenues <= 639474)
         prsCost = ticketRevenues * 0.0525
      else{
         let difference = ticketRevenues - 639474
         prsCost = (ticketRevenues - difference) * 0.0525
         prsCost += difference * 0.0242
      }
   }
   // finland
   else if(type == 3){ 
      if(ticketRevenues <= 34000)
         prsCost = ticketRevenues * 0.05
      else{
         let difference = ticketRevenues - 34000
         prsCost = (ticketRevenues - difference) * 0.05
         prsCost += difference * 0.03
      }
   }

   console.log(`final prs cost with ${type} = ${prsCost}`)
   return prsCost   
}

export function getPriceExVat(value, rate){
   return value - value * (1-1/(1+rate/100))
}



const stim = [                  // Ticket price: 	PRS fee / ticket:
   { max: 0, fee: 4.23 },     // 1-100 	               5,56
   { max: 100, fee: 5.56 },     // 1-100 	               5,56
   { max: 150, fee: 7.64 },     // 101-150               7,64
   { max: 200, fee: 10.42 },    // 151-200            	10,42
   { max: 250, fee: 10.93 },    // 201-250 	            10,93
   { max: 300, fee: 11.44 },    // 251-300            	11,44
   { max: 350, fee: 11.94 },    // 301-350            	11,94
   { max: 400, fee: 12.45 },    // 351-400            	12,45
   { max: 450, fee: 12.95 },    // 401-450            	12,95
   { max: 500, fee: 13.46 },    // 451-500           	   13,46
   { max: 550, fee: 13.96 },    // 501-550           	   13,96
   { max: 600, fee: 14.47 },    // 551-600            	14,47
   { max: 650, fee: 14.97 },    // 601-650             	14,97
   { max: 700, fee: 15.48 },    // 651-700            	15,48
   { max: -1,  fee: 15.98 },    // 701 > 	               15,98
]