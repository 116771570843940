<template>
      <v-col cols="12" md="12" class="row-wrapper pa-0">
         <v-container class="wizard-budget-container container--fluid">
            <v-col cols="12" md="12" class="pa-0 pb-5">
               <h3 class="wizard-section-title">Other costs / Chargebacks</h3>
            </v-col>
            <div class="budget-section-wrapper elevation-1">
               <div class="wizard-container-title mb-3">
                     {{ colNames[0] }}
               </div>
               <div 
                  class="wizard-form-text" 
                  v-for="cost of stepData.costs" :key="cost.id"
               >
                  <budget-six-cost-form
                     :costItem="cost"
                     :globalRate="isGlobalRate"
                     @deleteCost="deleteCostItem"
                     @getLocalRate="calcLocalRate"
                  />
               </div>
               <v-col cols="12" md="12" class="primary--text d-flex align-center px-0 pb-0">
                  <v-btn 
                     depressed
                     @click.stop="$emit('addCostItem', step, 'Other costs')"
                     color="primary" 
                  >
                     <v-icon left>add</v-icon>
                     Add other cost
                  </v-btn>
               </v-col>
            </div>
            <div class="budget-section-wrapper elevation-1">
               <div class="wizard-container-title mb-3">
                  Invoices
               </div>
               <div class="wizard-form-text" v-for="(cost,i) of stepData.specialCosts" :key="cost.id">
                  
                  <budget-six-cost-form
                     v-if="i == 0"
                     :costItem="cost"
                     :globalRate="isGlobalRate"
                     @deleteCost="deleteCostItem"
                     @getLocalRate="calcLocalRate"            
                  />
                  <v-row v-if="i == 1 && showProfitCalc">
                     <v-col cols="12" md="3" class="pa-0">
                        <v-text-field
                           :value="cost.display_name"
                           disabled
                           dense
                           filled
                           hide-details
                        >
                           
                        </v-text-field>
                           
                     </v-col>
                     <v-col cols="12" md="2">
                        <v-text-field
                           disabled
                           type="number"
                           suffix="%"
                           v-model="profitValue"
                           filled
                           dense
                           hide-details
                        ></v-text-field>
                     </v-col>
                     <v-col cols="12" md="4"></v-col>
                     <v-col cols="12" md="2">
                        <v-text-field
                           disabled
                           type="number"
                           :suffix="currency"
                           v-model="profitCost"
                           hide-details
                           filled
                           dense
                        ></v-text-field>
                     </v-col>
                  </v-row>
                  
               </div>
            </div>
         </v-container>
   </v-col>
</template>

<script>
import { BudgetPostItem } from '@/models/BudgetObjects.js'
import BudgetSixCostForm from './ConcertSixCostForm'
import CommentButton from '@/components/Dialogs/Comment/CommentButton'

export default {
   name: 'BudgetStepSix',

   components: {
      BudgetSixCostForm,
      CommentButton,
   },

   props: {
      stepData: Array,
   },

   data: ()=>({
      colNames: [
         'Type of other cost', 'Other currency', 'Exchange rate', 'Amount', 'Cost'
      ],
      step: 6,
      isGlobalRate: false,
      globalCurrency: '',
      globalRate: '',
      promoterProfitCost: {},
      showProfitCalc: false,
      artistShare: 0,
      currency: ''
   }),


   created(){
      console.log('stepSix')
      console.log(this.stepData);

      this.currency = this.$config.CURRENCY;

      this.$bus.$on('artistShareUpdated', (share) => {
         this.artistShare = share
      })

      // this.stepData.costs.map(c => {
      //    if(!c.currency_amount) c.currency_amount = ''
      //    if(!c.currency_rate) c.currency_rate = ''

      //    this.calcLocalRate(c)
      // })
      this.setShowProfit(this.stepData.specialCosts)
   },
   computed: {
      profitValue(){
         console.log(this.artistShare)
         let num = (100 - this.artistShare) / (this.artistShare / 100)
         return Math.round((num + Number.EPSILON) * 100) / 100
      },
      profitCost(){
         //console.log(`PROFITVALUE: ${this.profitValue}`)
         return Math.round(this.stepData.specialCosts[0].value * (this.profitValue / 100))
      }
   },

   watch: {
   //    isGlobalRate(val){
   //       //console.log(val)
   //       if(val)
   //          this.calcGlobalRate()
   //       else
   //          this.calcLocalRate()
   //    },
      globalRate(val){
         //console.log(`globalRate watch ${this.globalRate} ${this.isGlobalRate}`)
         if(this.isGlobalRate)
            if(val => 0)
               this.calcGlobalRate()
            
      },
      'stepData.specialCosts':{
         handler(val){
            //console.log('specialcosts watcher ####')
            //console.log(val[0])
            this.setShowProfit(val)
         },
         deep: true,
         immediate: true
      },
      artistShare(val){
         //console.log('artist share watcher #####')
         this.setShowProfit(this.stepData.specialCosts)
      },
   },

   mounted(){
      //console.log('stepsix mounted ############### ')
      this.$emit('isLoaded')
   },

   methods: {
      setShowProfit(specialCosts){
         if(specialCosts[0].value > 0 && this.artistShare > 0){
            this.showProfitCalc = true
            this.stepData.specialCosts[1].value = this.profitCost
         }
         else {
            this.showProfitCalc = false
            specialCosts[1].value = 0
         }
      },
      // // calcGlobalRate(){
      // //    //console.log('calcGlobalRate')
      // //    this.stepData.costs.map(c => c.currency_amount > 0 ? Math.round(c.value = c.currency_amount * this.globalRate) : c.value = 0)
      // // },

      // calcLocalRate(cost){
      //    //cost.currency_amount > 0 ? cost.value = Math.round(cost.currency_amount * cost.currency_rate) : cost.value = 0
      // },

      async deleteCostItem(costItem){
         if(costItem.budget_rowid != undefined)
            this.stepData.deletedCosts.push(costItem)

         this.$delete(this.stepData.costs, this.stepData.costs.indexOf(costItem))
      },

   }
}

</script>

<style lang="scss">

.row-wrapper{

}

</style>