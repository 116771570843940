<template>
   <div class="card float-left pa-0">
      <v-row>
         <v-col cols="12" md="4" class="float-left align-center  py-0">
            <simple-progression-card
               title="Average sold"
               :number="ticketsSold"
               subtext="Tickets"
            />
         </v-col>
         <v-col cols="12" md="4" class="float-left align-center py-0">
            <simple-progression-card
               title="Avgerage sold %"
               :number="ticketsSoldPercentage"
               subtext="%"
            />
         </v-col>
         <v-col cols="12" md="4" class="float-left align-center py-0">
            <simple-progression-card
               title="Average ticket price"
               :number="ticketPrice"
               :subtext="$config.CURRENCY"
            />
         </v-col>
      </v-row>
   </div>
</template>

<script>
import SimpleProgressionCard from '@/components/Cards/SimpleProgressionCard';

export default {
   name: 'averageinfocards',
   components: {
      SimpleProgressionCard,
   },

   props: {
      ticketsSold: Number,
      ticketsSoldPercentage: Number,
      ticketPrice: Number,
   }
}
</script>

<style lang="scss" scoped>

.card{
   background:transparent!important;
   border:none!important;
}

</style>