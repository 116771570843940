<template>
      <v-container class="wizard-budget-container container--fluid">
         
         <v-col cols="12" md="12" class="pa-0 pb-5">
            <h3 class="wizard-section-title">{{ stepHeader }}</h3>
         </v-col>
         <div class="budget-section-wrapper elevation-1">
         <div class="wizard-container-title mb-3">
            {{ colNames[0] }}
         </div>
         <div 
            class="wizard-form-text" 
            v-for="cost of stepData.costs" :key="cost.id"
         >
            <budget-cost-form 
               :step="step"
               :costItem="cost"
               @deleteCost="deleteCostItem"
            />
         </div>

         <v-col cols="12" md="12" class="primary--text d-flex align-center pa-0 mt-5">
            <v-btn 
               depressed
               @click.stop="$emit('addCostItem', step, 'Marketing costs')"
               color="primary" 
            >
               <v-icon left>add</v-icon>
               Add {{ stepHeader }} cost
            </v-btn>
         </v-col>
         </div>
		</v-container>
</template>

<script>
import { BudgetPostItem } from '@/models/BudgetObjects.js'
import BudgetCostForm from '../Shared/ConcertCostForm'

export default {
   name: 'BudgetStepFour',

   components: {
      BudgetCostForm,
   },

   props: {
      stepData: Array,
   },

   data: ()=>({
      stepHeader: 'Marketing',
      colNames: [
         'Type of marketing cost', 'Cost'
      ],
      step: 4,
   }),

   created(){
      console.log('stepFour')
      console.log(this.stepData)
   },

   mounted(){
      this.$emit('isLoaded')
   },

   methods: {
      async deleteCostItem(costItem){
         if(costItem.budget_rowid != undefined)
            this.stepData.deletedCosts.push(costItem)

         this.$delete(this.stepData.costs, this.stepData.costs.indexOf(costItem))
      },

      // async addCostItem(){
      //    let costItem = new BudgetPostItem()
      //    costItem.step = this.step
      //    costItem.header = 'Marketing costs'
      //    this.stepData.costs.push(costItem)
      // },
   }
}

</script>

<style lang="scss">

</style>