<template>
    <div class="todo">
        <v-checkbox
            class="mx-2"
            :false-value="'0'"
            color="primary"
            :true-value="'1'"
            v-model="todo.status_done"
            hide-details
            @click.stop="updateTodo(todo,index, todo.status_done);"
        ></v-checkbox>
        <div class="todo-info">
            <div class="title-wrapper">
                <span>{{ todo.title }}</span>
            </div>
            <div class="body-wrapper">{{ todo.body }}</div>
            <div class="user-event-wrapper">
                <span class="user">By {{ todo.authorid === userName ? 'You' : todo.authorid }}</span>
                <v-spacer></v-spacer>
                <span class="concert" v-if="todo.concertid != null ">
                    <b>Show:</b>
                    {{ todo.concertid }}
                </span>
                <span class="concert" v-else-if="todo.artistid != null">
                    <b>Artist:</b>
                    {{ todo.artistid }}
                </span>
                <span class="concert" v-else-if="todo.tourid != null">
                    <b>Tour:</b>
                    {{ todo.tourid }}
                </span>
                <span class="concert" v-else>Allmänna todos</span>
            </div>
        </div>
        <div class="remove-wrapper">
            <v-btn
                text
                small
                :loading="isRemoveTodoPending"
                @click="deleteItem(todo.todoid, index);"
                style="float:right"
                icon
                color="primary"
            >
                <v-icon>remove_circle_outline</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Todo",
    props: {
        todo: Object,
        events: Array,
        users: Array,
        index: Number,
        view: String,
        userName: String,
    },
    data: () => ({
        isRemoveTodoPending: false,
    }),
    mounted() {
        this.$bus.$on("unSetLoading", this.unSetLoading);
    },
    methods: {
        deleteItem(todoid, todos, index) {
            this.$emit("deleteItem", todoid, todos, index);
            this.setLoading();
        },
        updateTodo(todo, index, status) {
            this.$emit("updateTodo", todo, index);
            status == 0 ? (this.status_done = "1") : (this.status_done = "0");
        },
        setLoading() {
            this.isRemoveTodoPending = true;
        },
        unSetLoading() {
            this.isRemoveTodoPending = false;
        },
    },
    created() {},
};
</script>

<style lang="scss" scoped>
.todo {
    background: #f3f3f3;
    width: 100%;
    float: left;
    padding:16px 10px 50px 10px;
    border: 1px solid $border-color;
    border-left: 5px solid $primary;
    position: relative;
    display: flex;
    margin-bottom: 10px;
    border-radius: 3px;
    &:last-child {
        margin-bottom: 0px;
    }
    .todo-info {
        width: 70%;
        float: left;
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .title-wrapper {
            flex: 1;
            width: 100%;
            float: left;
            font-size: 0.88rem;
            font-weight: 600;
            text-transform: uppercase;
            color: #666;
            line-height: 1;
        }
        .body-wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 0.88rem;
            font-weight: 300;
            line-height: 1;
            word-break: break-word;
            color: rgb(144, 144, 144);
			margin-top:10px;
            span {
                padding: 10px 0px;
            }
        }
        .user-wrapper {
            float: left;
            border: 1px solid red;
        }
        .user-event-wrapper {
            flex: 1;
            float: left;
            display: flex;
            align-items: flex-end;
            position: absolute;
            bottom: 0px;
            left: 0px;
            padding: 10px;
            width: 100%;
            border-top: 1px solid $border-color;
            font-size: 0.88rem;
            i {
                float: left;
                font-size: 15px;
                margin: 0px 6px 2px 0px;
            }
            .user,
            .event {
                text-transform: uppercase;
                font-size: 11px;
            }
            .user {
                font-weight: 600;
                margin-right: 10px;
                color: #6d6d6d;
                display: flex;
                align-items: center;
            }
            .event {
                color: #6d6d6d;
                font-weight: 600;
            }
        }
    }
    .remove-wrapper {
        font-size: 13px;
        flex: 1;
        display: flex;
        button {
            margin: auto 5px auto auto;
            &:hover {
                background: rgb(235, 235, 235);
            }
        }
    }
    .checkbox-wrapper {
        margin: 0;
        align-items: center;
        display: flex;
        float: left;
    }
    .v-input {
        margin: auto;
        &::v-deep .v-input--selection-controls__input {
            margin: 0;
        }
    }
}
</style>