<template>
   <div>
      <v-row>
         <v-col class="d-flex" cols="12" md="3">
            <span class="pt-3 font-weight-bold d-flex align-center">
            {{ budgetItem.display_name }}
            </span>
         </v-col>

         <v-col cols="12" md="1">
            <v-switch
               class="mt-1 font-weight-light" 
               v-model="activeSelect" 
               :label="`${switchText[i = activeSelect ? '1' : '0']}`"
               hide-details
            />
         </v-col>

         <v-col cols="12" md="4">
            <v-text-field
               outlined
               hide-details
               type="number"
               dense
               label="Default value"
               v-model="budgetItem.value"
               required
            ></v-text-field>
         </v-col>
         <v-col cols="12" md="4">
            <v-select class="mt-0 pt-0"
               hide-details
               outlined 
               dense
               label="Default type"
               :items="defType"
               item-text="name"
               item-value="val"
               v-model="selectedType"
            ></v-select>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: 'budgetitem',

   props: {
      budgetItem: Object,
   },

   data: () => ({
      defType: [
         {name:'Fixed cost', val:'1' },
         {name:'Cost per ticket', val:'2'},
         {name:'Percentage', val:'3'},
      ],

      activeSelect: false,
      switchText: ['Off', 'On'],
      selectedType: { name:'Fixed cost', val:'1' }
   }),

   watch: {
      'activeSelect': {
         handler: function(v) {
           this.budgetItem.active = v ? '1' : '0'
         },
      },
      'selectedType': {
         handler: function(v) {
            this.budgetItem.budget_row_typeid = v
         }
      },
   },

   async created() {
      if(this.budgetItem.active == '1')
         this.activeSelect = true

      this.selectedType = this.defType[this.budgetItem.budget_row_typeid - 1].val
   },

   methods: {
   }
}
</script>

<style lang="scss">


</style>