<template>
    <div class="header-wrapper" :class="{ stretch: expandedHeader }">
        <Loader :loaderStyle="'linear-pageheader'" v-if="newDataLoading" />
        <v-col cols="12" md="12" sm="12" flex-column class="pa-0 info-container">
            <div class="info-wrapper pa-0" :class="{ mobile: $vuetify.breakpoint.xs }">
                <div class="d-flex align-center" v-if="view === 'tours'">
                    <v-tooltip bottom transition="false" style="z-index: 10" nudge-right="40">
                        <template v-slot:activator="{ on }">
                            <span class="title" v-on="on">
                                <v-icon>speaker</v-icon>
                                {{ items.name || "no data" }}
                            </span>
                        </template>
                        <span>{{ items.name || "no data" }}</span>
                    </v-tooltip>
                    <span class="info-title" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-icon>mdi-account-music</v-icon>
                        {{ items.artistid.name || "no data" }}
                    </span>
                    <span class="date" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-icon>calendar_today</v-icon>
                        {{ items.startdate ? items.startdate : "No date set" }}
                    </span>
                    <div class="sale-status-wrapper">
                        <span v-if="status === 'onsale'" class="presale">onsale</span>
                        <span v-if="status === 'ongoing'" class="ongoing">
                            <div class="ongoing-static"></div>
                            <div class="ongoing-fade"></div>
                            <span class="ongoing-text">Ongoing</span>
                        </span>
                        <span v-if="status === 'completed'" class="completed">Completed</span>
                        <span v-if="status === 'pending'" class="pending">Pending</span>
                    </div>
                </div>
                <div class="d-flex align-center" v-if="view === 'concerts'">

                    <v-tooltip bottom transition="false" style="z-index: 10" nudge-right="40">
                        <template v-slot:activator="{ on }">
                            <span class="title" v-on="on">
                                <v-icon>speaker</v-icon>
                                {{ items.title || "no data" }}
                            </span>
                        </template>
                        <span>{{ items.title || "no data" }}</span>
                    </v-tooltip>
                    <span class="info-title" v-if="!isMobile">
                        <v-icon>mdi-account-music</v-icon>
                        {{ items.artistid.name || "no data" }}
                    </span>
                    <span class="info-title" v-if="!isMobile">
                        <v-icon>mdi-stadium</v-icon>
                        {{ items.venueid.name || "no data" }}
                    </span>
                    <span class="date" v-if="!isMobile">
                        <v-icon>calendar_today</v-icon>
                        {{ items.date === "0000-00-00" ? "No date set" : items.date }}
                    </span>
                    <div class="sale-status-wrapper">
                        <span v-if="status === 'onsale'" class="presale">onsale</span>
                        <span v-if="status === 'ongoing'" class="ongoing">
                            <div class="ongoing-static"></div>
                            <div class="ongoing-fade"></div>
                            <div class="ml-4">Ongoing</div>
                        </span>
                        <span v-if="status === 'completed'" class="completed">Completed</span>
                        <span v-if="status === 'pending'" class="pending">Pending</span>
                    </div>
                </div>
                <div class="d-flex align-center" v-if="view === 'artists'">
                    <span class="info-title">
                        <v-icon>mdi-account-music</v-icon>
                        {{ items.name || "no data" }}
                    </span>
                </div>
                <div class="d-flex align-center" style="flex: 1" v-if="view === 'comparableprojects'">
                    <span class="info-title">
                        <v-icon>mdi-chart-areaspline</v-icon>
                        {{ benchmarkTitle || "Unnamed" }}
                    </span>
                    <v-spacer></v-spacer>
                    <v-form class="d-flex justify-center benchmark-form" v-if="!isMobile" ref="form" v-model="saveValid" lazy-validation>
                        <v-text-field
                            v-model="benchmarkTitleToBeSaved"
                            :counter="30"
                            label="Benchmark name"
                            required
                            dense
                            outlined
                            hide-details
                            style="
                                min-width: 300px;
                                flex: 0;
                                height: 35px;
                                min-height: 35px;
                                text-transform: none;
                            "
                        />
                        <div class="button-wrapper">
                            <v-btn
                                class="ml-3"
                                color="primary"
                                depressed
                                small
                                :disabled="!saveValid || selectedConcerts.length == 0"
                                @click="$emit('saveBenchmark', benchmarkTitleToBeSaved)"
                                >Save</v-btn
                            >
                            <v-btn v-if="$route.path.includes('edit')" small color="error" text @click="$emit('showDeleteDialog')">Delete</v-btn>
                        </div>
                    </v-form>
                </div>
                <div class="d-flex align-center" v-if="view === 'insights'">
                    <span class="account-name">
                        <v-icon>mdi-account</v-icon>
                        {{ username || "no data" }}
                    </span>
                    <div class="departments-wrapper">
                        <v-btn-toggle borderless style="border-radius: 0px" v-model="toggle_exclusive" color="primary" mandatory v-if="!isMobile">
                            <v-btn
                                @click="
                                    $emit('getDepartmentData', '');
                                    chosenDepartment = 'personalinsights';
                                "
                                small
                            >
                                <v-icon left small v-if="chosenDepartment === 'personalinsights'">mdi-check-circle-outline</v-icon>
                                <v-icon left small v-else>mdi-checkbox-blank-circle-outline</v-icon>Personal insights
                            </v-btn>
                            <div class="departments-button-wrapper" v-if="userDepartments.length > 0">
                                <span class="d-flex align-center">Departments:</span>
                                <v-btn
                                    @click="
                                        $emit('getDepartmentData', department);
                                        chosenDepartment = department;
                                    "
                                    depressed
                                    v-for="(department, index) in userDepartments"
                                    :key="index"
                                >
                                    <v-icon left small v-if="chosenDepartment === department">mdi-check-circle-outline</v-icon>
                                    <v-icon left small v-else>mdi-checkbox-blank-circle-outline</v-icon>
                                    {{ department }}
                                </v-btn>
                            </div>
                        </v-btn-toggle>
                        <v-menu a transition="slide-y-transition" bottom v-if="isMobile">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="d-flex pl-3 pr-0" color="primary" v-bind="attrs" depressed v-on="on">
                                    Departments
                                    <v-icon color="#fff">mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item-group color="indigo" active-class="TEST">
                                    <v-list-item
                                        @click="
                                            $emit('getDepartmentData', '');
                                            chosenDepartment = 'personalinsights';
                                        "
                                        :class="chosenDepartment === 'personalinsights' ? 'active-department' : ''"
                                    >
                                        <v-icon class="mr-2" small>{{
                                            chosenDepartment === "personalinsights" ? "mdi-check-circle-outline" : "mdi-circle-outline"
                                        }}</v-icon>
                                        <v-list-item-title>Personal Insights</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-for="(department, index) in userDepartments"
                                        :key="index"
                                        :class="chosenDepartment === department ? 'active-department' : ''"
                                        @click="
                                            $emit('getDepartmentData', department);
                                            chosenDepartment = department;
                                        "
                                    >
                                        <v-list-item-title>
                                            <v-icon class="mr-2" small>{{
                                                chosenDepartment === department ? "mdi-check-circle-outline" : "mdi-circle-outline"
                                            }}</v-icon>
                                            {{ department }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </div>
            <v-spacer></v-spacer>
            <div
                class="other-actions"
                v-if="enableOtherActions"
                :style="{
                    'border-right': isMobile ? 'none' : '1px solid #e2e2e2',
                }"
            >
                <v-menu transition="scroll-y-transition" left class="TEST" v-model="menu" max-height="95%" :close-on-content-click="false">
                    <template #activator="{ on: onOtherActions }">
                        <v-tooltip bottom transition="false">
                            <template #activator="{ on: onMoreActionsTooltip }">
                                <v-btn
                                    v-on="{
                                        ...onOtherActions,
                                        ...onMoreActionsTooltip,
                                    }"
                                    color="primary"
                                    class="ma-2"
                                    icon
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <span class="padding-right:20px;float:left;">Other actions</span>
                        </v-tooltip>
                    </template>
                    <v-card width="350" tile>
                        <v-list dense>
                            <div v-if="isMobile && view === 'concerts'">
                                <v-subheader>
                                    <span>Info</span>
                                    <v-spacer></v-spacer>
                                </v-subheader>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon>mdi-account-music</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ items.artistid.name || "no data" }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon>mdi-stadium</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ items.venueid.name || "no data" }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon>calendar_today</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ items.date === "0000-00-00" ? "No date set" : items.date }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <div v-if="isMobile && view === 'tours'">
                                <v-subheader>
                                    <span>Info</span>
                                    <v-spacer></v-spacer>
                                </v-subheader>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon>mdi-account-music</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ items.artistid.name || "no data" }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon>calendar_today</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ items.date === "0000-00-00" ? "No date set" : items.date }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <v-subheader v-if="view != 'artists'">
                                <span>Budget</span>
                                <v-spacer></v-spacer>
                                <span class="budget-status" :style="`color: ${budgetStatusColor}`">{{ budgetStatusText }}</span>
                            </v-subheader>
                            <div v-if="view != 'artists'">
                                <v-list-item
                                    :to="{
                                        path: `/${view}/${this.$getRouteId(this.$route.path)}/budgets`,
                                    }"
                                >
                                    <v-list-item-icon>
                                        <v-icon>history</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Budget history</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :disabled="disableCreateButton" @click="$emit('createNewBudget')">
                                    <v-list-item-icon>
                                        <v-icon>mdi-circle-edit-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Create budget</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :to="{ path: `/budgets/${budgetid}` }" :disabled="disableEditButton">
                                    <v-list-item-icon>
                                        <v-icon>edit</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ items.waiting_for_budget_approval < 1 ? "Edit" : "View" }} budget</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <v-subheader v-if="showDocumentButton && view !== 'tours'">Create</v-subheader>
                            <v-list-item v-if="view == 'concerts'" @click="generateBudgetDocument()">
                                <v-list-item-icon>
                                    <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Generate budget document</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="showDocumentButton && view != 'tours'" @click="openDocBrowser()">
                                <v-list-item-icon>
                                    <v-icon>mdi-file-document-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Create document</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-subheader>Campaigns</v-subheader>
                            <v-list-item @click="showCampaignBrowser = true">
                                <v-list-item-icon>
                                    <v-icon>mdi-facebook</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Facebook</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-subheader v-if="view === 'concerts'">Compare</v-subheader>
                            <div class="benchmark-wrapper" v-if="view == 'concerts'">
                                <v-autocomplete
                                    filled
                                    label="Benchmark"
                                    :items="benchmarkConcerts"
                                    item-text="title"
                                    return-object
                                    outlined
                                    dense
                                    v-model="selectedBenchmark"
                                    class="benchmark"
                                    @change="setBenchmark"
                                />
                            </div>
                            <div v-if="view != 'artists'">
                                <v-divider></v-divider>
                                <v-subheader>Sale dates</v-subheader>
                                <div class="sale-dates">
                                    <span
                                        >On sale:
                                        {{ this.items.on_sale ? this.items.on_sale : "no date set" || "no data" }}
                                        {{ this.items.on_sale_time && this.items.on_sale ? this.items.on_sale_time : "no time set" }}</span
                                    >
                                    <span
                                        >Ticket release date:
                                        {{ this.items.ticket_release_date ? this.items.ticket_release_date : "no date set" || "no data" }}</span
                                    >
                                    <span
                                        >Tele2 ticket release date:
                                        {{
                                            this.items.tele2_ticket_release_date ? this.items.tele2_ticket_release_date : "no date set" || "no data"
                                        }}</span
                                    >
                                </div>
                            </div>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
            <DatePicker
                :dark="true"
                v-if="datesCollected && !isMobile"
                @setEndDate="setEndDate"
                @setStartDate="setStartDate"
                @getSalesData="getSalesData"
                :defaultDates="defaultDates"
                :showDateButtons="showDateButtons"
                class="d-flex"
                :showButton="true"
                :maxDate="items.enddate"
                :wrongBenchmarkValues="wrongBenchmarkValues"
            />
            <doc-browser
                v-if="showDocBrowser"
                :show="showDocBrowser"
                :contentId="items[`${view.slice(0, -1)}id`]"
                :type="view"
                :agreed_with="agreed_with_person"
                @close="showDocBrowser = false"
            />
            <campaign-browser
                v-if="showCampaignBrowser"
                :view="view"
                :show="showCampaignBrowser"
                @close="showCampaignBrowser = false"
                @addedCampaigns="$emit('addedCampaigns')"
            ></campaign-browser>
        </v-col>
        <div v-if="isMobile" class="second-nav-wrapper">
            <v-form class="d-flex justify-center benchmark-form" ref="form" v-if="view === 'comparableprojects'" v-model="saveValid" lazy-validation>
                <v-text-field
                    v-model="benchmarkTitleToBeSaved"
                    :counter="30"
                    label="Benchmark name"
                    required
                    dense
                    outlined
                    hide-details
                    style="
                        flex: 1;
                        height: 35px;
                        min-height: 35px;
                        text-transform: inherit !important;
                    "
                />
                <div class="button-wrapper">
                    <v-btn
                        class="ml-3"
                        color="primary"
                        small
                        depressed
                        :disabled="!saveValid || selectedConcerts.length == 0"
                        @click="$emit('saveBenchmark', benchmarkTitleToBeSaved)"
                        >Save</v-btn
                    >
                    <v-btn v-if="$route.path.includes('edit')" color="error" small text @click="$emit('showDeleteDialog')">Delete</v-btn>
                </div>
            </v-form>
            <DatePicker
                :dark="true"
                v-if="datesCollected && view !== 'comparableprojects'"
                @setEndDate="setEndDate"
                @setStartDate="setStartDate"
                @getSalesData="getSalesData"
                :defaultDates="defaultDates"
                :showDateButtons="showDateButtons"
                class="d-flex"
                :showButton="true"
                :maxDate="items.enddate"
                :wrongBenchmarkValues="wrongBenchmarkValues"
            />
        </div>
    </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import Loader from "@/components/Loader.vue";
import { mapGetters } from "vuex";
import DocBrowser from "@/components/Tables/DocumentBrowser/DocBrowser";
import CampaignBrowser from "@/components/CampaignBrowser";

export default {
    name: "pageheader",
    components: {
        DatePicker,
        Loader,
        DocBrowser,
        CampaignBrowser,
    },
    props: {
        items: Object,
        userDepartments: Array,
        benchmarkTitle: String,
        view:String,
        status: String,
        defaultDates: Object,
        datesCollected: Boolean,
        datePicker: Object,
        benchmarkConcerts: Array,
        budgetid: String,
        budgetStatus: String,
        username: String,
        enableOtherActions: Boolean,
        showDateButtons: Boolean,
        selectedConcerts: Array,
        saveValid: Boolean,
        newDataLoading: Boolean,
        wrongBenchmarkValues: Boolean,
        showDocBrowser: false,
        showCampaignBrowser: false,
    },
    data: () => ({
        menu: false,
        selectedBenchmark: {},
        budgetStatusText: "",
        budgetStatusColor: "",
        benchmarkTitleToBeSaved: "",
        chosenDepartment: "personalinsights",
    }),
    created() {
        this.budgetStatusText = this.checkBudgetStatus();
        this.benchmarkTitleToBeSaved = this.benchmarkTitle;
        console.log(this.$vuetify.breakpoint);
        this.$logger("items in pageheader", this.items);
        console.log(this.items);
    },
    updated() {},

    computed: {
        disableCreateButton() {
            return this.budgetid || this.items.waiting_for_budget_approval == "1" ? true : false;
            // return this.budgetid >= 0 && this.items.waiting_for_budget_approval !== '1';
        },
        disableEditButton() {
            return !this.budgetid ? true : false;
        },
        showDocumentButton() {
            return this.view == "concerts" || this.view == "tours" || this.view == "artists";
        },
        ...mapGetters({
            expandedHeader: "globalstates/expandedHeader",
            isMobile: "globalstates/isMobile",
        }),
    },
    methods: {
        async generateBudgetDocument() {
            const response = await this.$GetDocService(`generateDocument?documentid=-1&id=${this.$route.params.id}&sign=false`);
            const [, filename] = response.headers["content-disposition"].split`;`[1].match('"(.*)"');
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(response.data);
            link.download = filename;
            link.click();
        },
        getContentTitle() {
            if (this.view == "concerts") return this.items.title;

            return this.items.name;
        },
        setBenchmark() {
            this.$emit("setBenchmark", this.selectedBenchmark);
            this.menu = false;
        },
        setStartDate(payload) {
            this.$emit("setStartDate", payload);
        },
        setEndDate(payload) {
            this.$emit("setEndDate", payload);
        },
        getSalesData() {
            this.$emit("getSalesData");
        },
        checkBudgetStatus() {
            if (!this.budgetStatus) return;
            switch (this.budgetStatus) {
                case "-1":
                    this.budgetStatusColor = "#b54a4a";
                    return "Locked";
                case "1":
                    this.budgetStatusColor = "#ee9a45";
                    return "Awaiting approval";
                    break;
                case "2":
                    this.budgetStatusColor = "#55b54a";
                    return "Approved";
                    break;
                default:
                    this.budgetStatusColor = "#4a81b5";
                    return "Draft";
            }
        },
        openDocBrowser() {
            this.agreed_with_person = this.items.agreed_with ? JSON.parse(JSON.stringify(this.items.agreed_with)) : {};
            this.agreed_with_person.isSigner = true;
            this.agreed_with_person.agreed_with = true;
            this.showDocBrowser = true;
            this.menu = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.benchmark-form {
    label {
        font-size: 4px;
    }
    &::v-deep .v-input__slot {
        min-height: 35px !important;
    }
}

.second-nav-wrapper {
    width: 100%;
    float: left;
    background: #fff;
    padding: 10px;
    border-top: 1px solid #e2e2e2;
    display: flex;
    border-bottom: 1px solid #e2e2e2;
}

.v-item--active {
    background: #306aa1 !important;
    color: #fff !important;
    opacity: 1 !important;
    i {
        color: #fff !important;
    }
    &:before {
        opacity: 0 !important;
    }
}

.v-list {
    .v-list-item {
        .v-list-item__icon {
            margin: 10px 10px 10px 0px;
            i {
                font-size: 1.3em;
            }
        }
    }
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background: #f0f0f0;
}

.stretch {
    width: calc(100% - 63px) !important;
}

.v-subheader {
    color: $primary;
    font-weight: 500;
    padding: 0px 20px;
}

.v-application .v-menu__content .v-select-list {
    &::v-deep .v-list-item {
        max-width: 300px !important;
    }
}

.mobile .header-wrapper {
    width: 100% !important;
    display: block;
}

.header-wrapper {
    display: flex;
    position: fixed;
    min-height: auto !important;
    z-index: 1;
    width: calc(100% - 254px);
    height: 50px !important;
    padding: 0px !important;
    display: flex;
    z-index: 2;
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 13px #00000026;

    a {
        flex: 1;
    }
}
.benchmark-wrapper {
    padding: 15px;
    .benchmark {
        &::v-deep .v-input {
            color: #fff !important;
        }
        &::v-deep .v-text-field__details {
            display: none;
        }
        &::v-deep .v-input__slot {
            margin: 0;
        }
    }
}
.other-actions {
    height: 100%;
    display: flex;
    align-items: center;
    display: flex;
    padding-right: 10px;
    border-right: 1px solid $border-color;

    button {
        width: 35px !important;
        height: 35px !important;
    }
}
.sale-dates {
    float: left;
    background: #fff;
    width: 100%;
    padding-left: 15px;
    padding-bottom: 15px;
    color: #222 !important;

    span {
        width: 100%;
        float: left;
        font-size: 13px;
        text-transform: uppercase;
        margin-bottom: 5px;
        color: #666;
    }
}

.budget-status {
    font-size: 12px;
    color: #e29c00;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 20px;
    margin-left: 10px;
}

.mobile {
    .info-wrapper {
        .title {
            max-width: 207px !important;
        }
    }
}

.info-container {
    display: flex;
    height: 100% !important;
    align-items: center;
    .info-wrapper {
        width: 100%;
        text-transform: uppercase;
        line-height: 1.3;
        align-items: center;
        padding: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        .my-insight {
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
            padding: 0px 10px;
        }
        .departments-wrapper {
            border-left: 1px solid $border-color;
            padding-left: 20px;
        }
        .departments-button-wrapper {
            display: flex;
        }
        button {
            border-radius: 3px !important;
            margin-right: 10px;
            font-weight: 500;
        }
        span {
            font-weight: 600;
        }
        i {
            margin-right: 10px;
            color: $primary;
        }
        span {
            padding: 0px 15px;
            color: $font-color-pale;
        }
        .title {
            font-weight: 600;
            font-size: 0.9rem !important;
            max-width: 450px;
            float: left;
            text-align: center;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .info-title,
        .account-name {
            font-size: 0.9rem;
            font-weight: 600;
            float: left;
            text-align: center;
            display: flex;
            align-items: center;
            height: 100%;
        }
        .date {
            font-size: 0.9rem;
            font-weight: 600;
            float: left;
            text-align: center;
            display: flex;
            align-items: center;
            height: 100%;
        }
        .sale-status-wrapper {
            display: flex;
            float: left;
            height: 100%;
            padding-left: 5px;
            position: relative;
            margin-left: 10px;
            span {
                margin: auto;
                font-size: 0.9rem;
                font-weight: 500;
                padding: 0px;
                letter-spacing: 0.08rem;
                display: flex;
                align-items: center;
                color: #000;
                border: none;
            }

            .ongoing-static,
            .ongoing-fade {
                margin-right: 10px;
                position: absolute;
                font-size: 21px;
                width: 10px;
                left: 0px;
                height: 10px;
                background: #c93333;
                border-radius: 10px;
            }
            .ongoing-static {
                z-index: 10;
            }
            .ongoing-fade {
                animation: concertongoing infinite 1s ease-out forwards;
                background: red;
                z-index: 0;
            }
            .presale {
                color: #69c786;
            }
            .onsale {
                color: #6ac310;
            }
            .ongoing {
                color: #49a7ff !important;
                .ongoing-text {
                    margin-left: 15px;
                }
            }
            .completed {
                color: #ff3939;
            }
            .pending {
                color: #ffaa2a;
            }
            @keyframes concertongoing {
                0% {
                    opacity: 1;
                    transform: scale(1);
                }
                100% {
                    opacity: 0;
                    transform: scale(2.5);
                }
            }
        }
    }
}

.v-item-group {
    padding: 0px 7px;
}

.v-list-item {
    margin-bottom: 10px;
    border-radius: 3px;
    // background:#f0f0f0;
    &:last-child {
        margin-bottom: 0px;
    }
}

.active-department {
    background: $primary;
    * {
        color: #fff !important;
    }
}

.v-menu__content {
    overscroll-behavior: contain;
}
</style>
