<template>
    <v-dialog
        persistent
        overlay-opacity="1"
        absolute
        v-model="showEmailDialog"
        v-if="showEmailDialog"
        transition="dialog-top-transition"
        width="1000"
    >
        <v-card class="pa-4" style="background: #f0f0f0">
            <div class="action-container d-flex">
                <v-spacer></v-spacer>
                <v-btn @click="copy" color="primary" depressed class="mr-4" small>
                    <v-icon left small>content_copy</v-icon>
                    Copy to clipboard
                </v-btn>
                <v-btn @click="closeDialog" small text style="float: right" >close</v-btn>
            </div>
            <div class="clipboard-alert-container" :class="{ show: copyToClipboard }">
                <div class="clipboard-alert">Copied to clipboard!</div>
            </div>
            <div v-html="emailTemplate" id="email" class="elevation-2"></div>
        </v-card>
    </v-dialog>
</template>

<script>
import htmlToText from "html-to-text";

export default {
    name: "EmailDialog",
    props: {
        showEmailDialog: Boolean,
        emailTemplate: String,
    },
    data: () => ({
        text1: "lorem ipsum 123",
        copyToClipboard: false,
    }),
    methods: {
        copy() {
            window.getSelection().selectAllChildren(document.getElementById("email"));

            document.execCommand("copy");

            window.getSelection().removeAllRanges();
            this.copyToClipboard = true;
        },
        closeDialog() {
            this.copyToClipboard = false;
            this.$emit("closeEmailDialog");
        },
    },
    created() {
        console.log("EMAILDIALOG SHOWN");

        //   const test = this.raw.replace(/<style>([^()]*<\/style>)/g, "");
        //   console.log(test);
    },
};
</script>

<style scoped lang="scss">
.show {
    opacity: 1 !important;
    top: 32px;
}

.action-container {
    position: relative;
    z-index: 10;
    position: absolute;
    left: 0px;
    top: 34px;
    padding-right: 32px;
    width: 100%;
}

.v-btn {
    &::v-deep .v-btn__content {
        font-size:0.75rem!important;
    }
}

#email {
    padding: 20px 0px;
    background: #fff;
}

.clipboard-alert-container {
    width: 100%;
    transition: all 0.2s ease;
    opacity: 0;
    float: left;
    display: flex;
    position: absolute;
    left: 0px;
    .clipboard-alert {
        padding: 7px 15px;
        text-align: center;
        margin: auto;
        background: rgb(127 194 110 / 42%);
        border-radius: 3px;
        color: rgb(15 72 0 / 42%);
        font-weight: 600;
    }
}
</style>
