<template>
    <v-col cols="12" md="12" class="pa-0">
        <div>
            <v-col cols="12" md="12" class="pa-0">
                <h1 color="primary" class="header">Budget Default Settings</h1>
                <v-spacer></v-spacer>
            </v-col>
            <v-form>
                <v-col class="pa-0">
                    <v-col cols="12" md="12" class="pa-0 mt-8">
                        <v-row>
                            <v-col cols="12" md="12" class="float-left">
                                <h1 class="header font-weight-bold">Ticket Revenues (Step 1)</h1>
                            </v-col>
                        </v-row>

                        <v-row v-for="item in ticketRevenuesArr" :key="item.id">
                            <v-col class cols="12" md="11">
                                <ticket-revenue-form :ticketItem="item"></ticket-revenue-form>
                            </v-col>

                            <v-col class cols="12" md="1">
                                <v-btn icon class="mt-4" color="error" @click.stop="openTicketDialog(item)">
                                    <v-icon>remove_circle_outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <delete-ticket-dialog
                        @deleteTicketFromDialog="deleteTicketRevenue"
                        @cancelTicketDialog="cancelTicketDialog"
                        :dialog="deleteTicketDialog"
                        :ticketItem="ticketToForm"
                    />

                    <v-col cols="12" md="12" class="primary--text d-flex align-center mb-8 pa-0 mt-3">
                        <v-btn dark depressed @click.stop="openTicketDialog(null)" color="primary">
                            <v-icon left>add</v-icon>
                            Add default section/ticket type
                        </v-btn>

                        <new-ticket-dialog
                            @addTicketToArr="newTicketSection"
                            @cancelTicketDialog="cancelTicketDialog"
                            :dialog="ticketDialog"
                            :ticketItem="ticketToForm"
                        />
                    </v-col>

                    <v-lazy
                        v-for="(step, index) in costStepArr"
                        :key="step.id"
                        v-model="lazyIsActive[index]"
                        :options="{
                            threshold: 0.2,
                        }"
                        min-height="200"
                        transition="fade-transition"
                    >
                        <div v-if="!step.title.toLowerCase().includes('talent costs')">
                            <v-row v-if="step.title != ''">
                                <v-col cols="12" md="12" class="float-left">
                                    <h1 class="header font-weight-bold">{{ step.title }}</h1>
                                </v-col>
                            </v-row>

                            <v-row v-if="step.subTitle != ''">
                                <v-col cols="12" md="12" class="float-left">
                                    <h2 class="header overline">{{ step.subTitle }}</h2>
                                </v-col>
                            </v-row>

                            <v-row v-for="item in step.arr" :key="item.id">
                                <v-col cols="12" md="12" v-if="!item.sticky">
                                    <budget-item :budgetItem="item"></budget-item>
                                </v-col>
                            </v-row>
                        </div>
                    </v-lazy>
                </v-col>
            </v-form>
        </div>
    </v-col>
</template>

<script>
import TicketRevenueForm from "../VenueBudgetTemplate/TicketRevenueForm.vue";
import NewTicketDialog from "@/components/Dialogs/NewTicketDialog";
import DeleteTicketDialog from "@/components/Dialogs/DeleteTicketDialog";
import BudgetItem from "../VenueBudgetTemplate/BudgetItem.vue";
import { Ticket, BudgetPostItem, CostStep } from "@/models/BudgetObjects.js";

export default {
    name: "VenueBudgetTemplate",

    props: {
        ticketRevenuesArr: Array,
        costStepArr: Array,
    },

    components: {
        TicketRevenueForm,
        NewTicketDialog,
        DeleteTicketDialog,
        BudgetItem,
    },

    data: () => ({
        venueid: null,
        originalData: [],
        loadingDone: false,
        ticketDialog: false,
        deleteTicketDialog: false,
        ticketToForm: null,
        lazyIsActive: [],
        stepLoaded: [],
        loadedCount: 0,
    }),

    async created() {
        this.$bus.$on("ticketAdded", (ticket) => {
            this.ticketDialog = false;
        });

        let i = 0;
        while (i++ < this.costStepArr.length) {
            this.lazyIsActive.push(false);
            this.stepLoaded.push(false);
        }
        console.log(this.costStepArr);
        this.$emit("budgetLoaded");
    },

    async mounted() {
        console.log("venuebudget mounted");
    },

    watch: {
        // loadedCount(val){
        // 	console.log(val)
        // },
        // lazyIsActive(val){
        // 	console.log(val)
        // }
    },

    updated() {
        //this.stepLoaded[this.loadedCount++] = true
        //console.log('venuebudget updated')
    },

    methods: {
        stepDone(payload) {
            console.log("stepdone");
            console.log(payload);
        },
        /**
         * Calls the parent function to add a new ticket to the database
         */
        newTicketSection(ticket = null) {
            if (ticket == null) ticket = new Ticket();

            this.$emit("postTicket", ticket);
        },

        /**
         * Opens the two different ticket dialogs. Either for creation or deletion
         */
        openTicketDialog(ticket) {
            this.ticketToForm = null;
            if (ticket != null) {
                this.ticketToForm = ticket;
                this.deleteTicketDialog = true;
                this.$bus.$emit("showDeleteTicketDialog", this.ticketToForm);
            } else {
                this.ticketToForm = new Ticket();
                this.ticketDialog = true;
                this.$bus.$emit("showNewTicketDialog", this.ticketToForm);
            }
        },

        deleteTicketRevenue(ticket) {
            this.$emit("deleteTicket", ticket);
            this.deleteTicketDialog = false;
        },

        cancelTicketDialog() {
            this.ticketDialog = false;
            this.deleteTicketDialog = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
