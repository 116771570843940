<template>
  <div id="login">
		<div class="login-wrapper elevation-1">
			<div class="welcome-text">
				<h1>Welcome to Applaud!</h1>
				<p>The login button below will redirect you to the Okta sign-in page</p>

			</div>
    		<v-btn v-on:click='login' color="primary" id='login-button' block> Login </v-btn>
		</div>
  </div>
</template>

<script>

export default {
  name: 'app',
  data: function () {
    return {
      authenticated: false
    }
  },
  created () {
    this.isAuthenticated()
  },
  watch: {
    // Everytime the route changes, check for auth status
    authenticated(){
		if(this.authenticated)
			this.$router.push({ path: '/' })
	}
  },
  methods: {
    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated()
    },
    login () {
      this.$auth.loginRedirect('/')
    },
    async logout () {
      await this.$auth.logout()
	  await this.isAuthenticated()
	  this.$router.push({ path: '/logout' })

      // Navigate back to home
      this.$router.push({ path: '/' })
    }
  }
}
</script>
<style lang="scss">
	#login{
		background: #11283e08;
		width:100%;
		height:100%;
		display:flex;
		.login-wrapper{
			padding:20px;
			width:auto;
			border-radius:10px;
			background:#fff;
			margin:auto;
			.welcome-text{
				h1{
					color:$font-color;
					font-size:1.5rem;
					font-weight:500;
					margin:0px 0px 20px 0px;
				}
				p{
					font-size:1.1rem;
					color:#909090;
				}

			}
		}
	}
</style>