<template>
	<v-row >
		<v-col cols="12" :md="md" v-for="(cards, index)  in dataObject" :key="cards.id">
			<v-card class="elevation-0">
				<Progressioncard 
					:title="dataObject[index].title"
					:subtext="dataObject[index].subtext"
					:number="dataObject[index].number"
					:percentage="dataObject[index].percentage"
					:progresstext="dataObject[index].progresstext"
					:isLoading="dataObject[index].isLoading"
					:redOverLimit="dataObject[index].redOverLimit"
				/>
			</v-card>
		</v-col>
	</v-row>
</template>
 
<script>
import Progressioncard from './Progressioncard.vue';
   export default {
		name:'progressioncards',
		props:{
			dataObject:Object,
		},
		components:{
			Progressioncard,
		},
		data:()=>({
			mockdata:{
				cardone:{
					title:'Events on sale',
					subtext:'Events',
					number:'257',
					percentage:'88'
				},
				cardtwo:{
					title:'Tickets sold yesterday',
					subtext:'Tickets',
					number:'23767',
					percentage:'112'
				},
				cardthree:{
					title:'Enjoyed a livenation event yesterday ',
					subtext:'People',
					number:'32902',
					percentage:'86'
				}
			},
			md:null,
			mb:false,
		}),
		created(){
			this.calculateMd()
		},
		methods:{
			calculateMd(){
				this.md = Math.round(12 / Object.keys(this.dataObject).length)
				if(this.md < 3){
					this.mb = true
					this.md = 3
				}
			}
		}
   }
</script>

<style lang="scss" scoped>
   .v-card{
      border-radius:3px!important;
   }
</style>