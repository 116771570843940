<template>
    <div class="page-container">
        <Loader :loaderStyle="'linear'" v-if="!loadingDone" />
        <div class="fadein-container" :class="loadingDone ? 'fade-in' : ''">
            <PageHeader
                v-if="loadingDone"
                :items="items"
                :status="status"
                :view="view"
                :defaultDates="defaultDates"
                :datePicker="datePicker"
                :datesCollected="datesCollected"
                :benchmarkConcerts="benchmarkConcerts"
                :selectedBenchmark="selectedBenchmark"
                :budgetid="budgetid != '' ? budgetid : null"
                :budgetStatus="budgetData ? budgetData.status : null"
                :enableOtherActions="true"
                :newDataLoading="newDataLoading"
                @getSalesData="getSalesData"
                @setStartDate="(date) => (this.datePicker.startDate.date = date)"
                @setEndDate="(date) => (this.datePicker.endDate.date = date)"
                @setBenchmark="setBenchmark"
                @createNewBudget="createBudget()"
                @showDocBrowser="showDocBrowser = true"
                @addedCampaigns="getSelectedCampaigns"
            />
            <div id="concertpage" v-if="loadingDone">
                <v-container class="container--fluid pb-0">
                    <ProgressionCards :dataObject="progressionCard.dataObject" />
                </v-container>
                <v-container class="container--fluid pb-0">
                    <v-row>
                        <v-col cols="12" md="8" class="salesprogresscard-container">
                            <SalesProgressCard
                                :grossBars="grossBars"
                                :ticketBars="ticketBars"
                                :donutData="donutData"
                                :dataFound="ticketDataFound"
                                :isLoading="salesProgressIsLoading"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <Checklist
                                v-if="Object.entries(this.items).length > 0"
                                :checklist_items="checklist_items"
                                :items="items"
                                :view="view"
                                :viewidname="'concertid'"
                            />
                            <TodoList class="mt-7" />
                        </v-col>
                    </v-row>
                </v-container>

                <!-- SALES GRAPH -->
                <v-container class="float-left pa-3 mb-2 container--fluid">
                    <v-col cols="12" md="12" class="float-left pa-0 elevation-1 graph-container">
                        <div class="card-title">Sales</div>
                        <v-col cols="12" md="12" class="float-left pa-8">
                            <NoDataPlaceholder :text="'No data for graph'" v-if="salesGraphOptions.series.length === 0" />
                            <echart-card v-if="salesGraphOptions.series.length > 0" style="height: 400px" :options="salesGraphOptions" />
                        </v-col>
                    </v-col>
                </v-container>

                <!-- BENCHMARK GRAPH -->
                <v-container id="benchmarken" class="float-left pa-3 mb-2 container--fluid">
                    <v-col cols="12" md="12" class="float-left pa-0 elevation-1 graph-container">
                        <div class="card-title">Benchmark</div>
                        <v-col cols="12" md="12" class="float-left py-8">
                            <NoDataPlaceholder
                                :text="'No benchmark selected'"
                                v-if="!(selectedBenchmark.concertid || selectedBenchmark.comparable_concertid)"
                            />
                            <NoDataPlaceholder
                                :text="`No valid data in selected benchmark (${selectedBenchmark.title})`"
                                v-if="benchmarkData.length == 0 && (selectedBenchmark.concertid || selectedBenchmark.comparable_concertid)"
                            />
                            <echart-card
                                v-if="benchmarkData.length > 0 || selectedBenchmark.concertid || selectedBenchmark.comparable_concertid"
                                style="height: 400px"
                                :options="benchGraphOptions"
                            />
                        </v-col>
                    </v-col>
                </v-container>

                <!-- MARKETING GRAPH -->
                <v-container class="pa-3 float-left container--fluid">
                    <v-col cols="12" md="12" class="float-left pa-0 elevation-1 graph-container">
                        <div class="card-title">Marketing</div>
                        <v-col cols="12" md="12" class="float-left pa-8">
                            <NoDataPlaceholder
                                :text="'No marketing data within selected dates'"
                                :dontHide="true"
                                v-if="marketingGraphOptions.series.length === 0"
                            />
                            <echart-card
                                v-if="marketingGraphOptions.series.length > 0"
                                style="height: 400px"
                                :options="marketingGraphOptions"
                                :key="marketingGraphOptions.series.length"
                            />
                            <MarketingTypes
                                v-if="marketingTypesDialog"
                                :marketingTypesDialog="marketingTypesDialog"
                                :marketingData="items.marketing"
                                :allMarketingTypes="allMarketingTypes"
                                :concertId="items.concertid"
                                @closeMarketingTypesDialog="
                                    () => {
                                        marketingTypesDialog = false;
                                        initMarketingDataForGraph();
                                    }
                                "
                                @openEditMarketingTypeDialog="openEditMarketingTypeDialog"
                                @getMarketingData="getMarketingData"
                                @getBudgetData="getBudgetDataz"
                            />
                            <v-btn @click="marketingTypesDialog = true" depressed color="primary" class="mt-10">Add/Edit marketing type</v-btn>
                        </v-col>
                    </v-col>
                </v-container>

                <v-container class="container--fluid float-left container--fluid pa-3">
                    <v-row>
                        <v-col cols="12" md="5" class="">
                            <v-col cols="12" md="12" style="background: #fff" class="pa-0 elevation-1 float-left">
                                <v-col cols="12" md="12" class="pa-0">
                                    <div class="card-title">Marketing</div>
                                </v-col>
                                <MarketingInfoCard
                                    v-if="budgetData"
                                    :view="view"
                                    :marketingBars="marketingBars"
                                    :budgetMarketingSum="budgetData.marketing_costs"
                                    :marketingCost="marketingCost"
                                />
                                <div
                                    v-else
                                    class="elevation-1"
                                    style="
                                        position: relative;
                                        min-height: 300px;
                                    "
                                >
                                    <NoDataPlaceholder :text="'no budget'" style="z-index: 1" />
                                </div>
                            </v-col>
                        </v-col>
                        <v-col cols="12" md="7">
                            <div class="budget-summary">
                                <concert-budget-summary
                                    v-if="budgetid != '' && graphLoaded && !creatingNewBudget && budgetData"
                                    :budgetData="budgetData"
                                    :currentData="currentSummaryData"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/components/Menu/PageHeader";
import TodoList from "@/components/TodoList";
import DatePicker from "@/components/DatePicker";
import ProgressionCards from "@/components/Cards/ProgressionCards/";
import SalesProgressCard from "@/components/Cards/SalesProgressCard/";
import MarketingInfoCard from "@/components/Cards/MarketingInfoCard/";
import Checklist from "@/components/Checklist/";
import { mapActions, mapGetters } from "vuex";
import TwoAxisLineChart from "@/components/Cards/ChartCards/TwoAxisLineChart";
import LineChart from "@/components/Cards/ChartCards/LineChart";
import ConcertBudgetSummary from "./BudgetSummary/ConcertBudgetSummary";
import EditMarketingType from "./MarketingDialogs/EditMarketingType";
import MarketingTypes from "./MarketingDialogs/MarketingTypes";
import { calcPrs } from "@/components/BudgetPage/ConcertBudgetWizard/Shared/PrsTypes";
import NoDataPlaceholder from "@/components/NoDataPlaceholder";
import Loader from "@/components/Loader";
import EchartCard from "@/components/Cards/ChartCards/EchartCard";
import { initEchart, generateSerie, initEchartTwoX, generateSerieTwoX } from "@/components/Cards/ChartCards/EchartOptions.js";
import ApiObjectCollection from "@/models/ApiObjectCollection.js";
import ObjectFactory from "@/models/ObjectFactory.js";
import { getAllRows } from "@/models/venue_budget_template_rows.js";
import dayjs from "dayjs";

export default {
    name: "ConcertPage",
    components: {
        PageHeader,
        DatePicker,
        ProgressionCards,
        SalesProgressCard,
        MarketingInfoCard,
        Checklist,
        TwoAxisLineChart,
        LineChart,
        ConcertBudgetSummary,
        EditMarketingType,
        MarketingTypes,
        NoDataPlaceholder,
        Loader,
        EchartCard,
        TodoList,
    },
    data: () => ({
        budgetid: "",
        menu: false,
        venueTickets: [],
        status: "",
        enableOtherOptions: true,
        venueBudgetRows: [],
        marketingCost: 0,
        loader: true,
        loadingDone: false,
        items: {},
        mindate: "",
        marketingTypesDialog: false,
        EditMarketingTypeDialog: false,
        wallData: [],
        originalWallData: [],
        allMarketingTypes: null,
        datePicker: {
            startDate: {
                date: "",
            },
            endDate: {
                date: "",
            },
        },
        defaultDates: {
            startDate: "",
            endDate: "",
        },
        budgetStatusColor: "",
        progressionCard: {
            dataObject: {
                sales: {
                    title: "Sales",
                    number: 0,
                    subtext: "Tickets",
                    percentage: 0,
                    progresstext: "0% Sold",
                },
                revenue: {
                    title: "Gross income",
                    number: 0,
                    subtext: "",
                    percentage: 0,
                    progresstext: "0% Sold",
                },
                until_breakeven: {
                    title: "Tickets until break-even",
                    number: 0,
                    subtext: "Tickets",
                    percentage: 0,
                    progresstext: "",
                },
            },
        },
        ticketBars: [],
        grossBars: [],
        ticketsInRange: [],
        marketingBars: [],
        ticketDataFound: false,
        benchmarkConcerts: [],
        selectedBenchmark: {},

        totalTicketSales: 0,

        salesGraphOptions: { series: [] },
        benchGraphOptions: { series: [] },
        marketingGraphOptions: { series: [] },

        rawTicketSales: [],
        ticketSalesData: [],
        allTicketSalesData: [],
        analyticsData: [],
        benchmarkData: [],

        marketingData: [],
        marketingId: "",
        marketingTypeName: "",
        datesCollected: false,
        donutData: {
            ticket_sold_percentage: 0,
            tickets_sold: 0,
            tickets_available: 0,
            gross_sales_percentage: 0,
            gross_sales: 0,
            gross_available: 0,
        },
        salesProgressIsLoading: false,
        checklist_items: ["confirmation", "eos", "contract_ok", "engagement_contracts", "police_authorization", "assurance", "show_file"],
        graphLoaded: false,
        budgetData: {},
        salesPredictionArray: [],

        currentSummaryData: {
            ticketRevenues: 0,
            marketingCosts: 0,
        },
        timeOut: "",

        marketingColors: [
            "#429ab2",
            "#955cbf",
            "#ad42a5",
            "#3dba81",
            "#3796bf",
            "#b57959",
            "#3ca343",
            "#b53c5e",
            "#a8bf43",
            "#b5883e",
            "#228c83",
            "#a33939",
        ],

        // break even calc
        ticketsSold: [],

        creatingNewBudgetF: false,

        marketingColorIndex: 0,
        historyBarData: {
            display: "",
            route: "",
        },
        newDataLoading: false,
        selectedCampaigns: null,
    }),
    async created() {
        this.$bus.$on("updateConcertDataFromSlider", this.updateDataFromSlider);

        await this.initAllData();
        await this.checkIfData();

        this.loadingDone = true;
        this.historyBarData.display = this.items.title;
        this.historyBarData.route = this.$route.path;
        console.log(this.items);
    },
    watch: {
        selectedBenchmark(val) {
            this.initBenchmarkDataForGraph(val);
            this.$vuetify.goTo("#benchmarken", { offset: 120, duration: 750 });
        },
        "items.marketing": {
            handler: function() {
                this.initMarketingDataForGraph();
            },
            deep: true,
        },
    },
    methods: {
        async initAllData() {
            await this.getData();
            this.$bus.$emit("setHistoryView", {
                display: this.items.title,
                route: this.$route.path,
                icon: "mdi-stadium",
            });
            this.budgetData = await this.getGenericData(`${this.$getRoute("budgets").main_route}?id=${this.budgetid}`);

            await this.initRawTicketSales();
            await this.getDefaultDates();
            await this.fixNull();
            await this.getBenchmarkConcerts();
            await this.getSalesData();
            await this.getPredictionSalesData();
            // await this.getWallData();
            // this.checkForWallUpdates()
            this.$logger("budgetdata", this.budgetData);
            this.unSetDrawersDisabled();
            this.graphLoaded = true;
            await this.getMarketingBars();
            this.checkIfSale();
            this.getTotalMarketing();
        },
        setBenchmark(payload) {
            this.selectedBenchmark = payload;
        },
        async getBudgetDataz() {
            this.budgetData = await this.getGenericData(`${this.$getRoute("budgets").main_route}?id=${this.budgetid}`);

            this.getTotalMarketing();
        },
        checkIfSale() {
            // ASSIGN ON SALE DATE
            const ONSALE = new Date(this.items.on_sale);

            // LIST OF ALL PRESALE DATES
            const DATELIST = [
                this.items.startdate_spotify,
                this.items.startdate_fan_club,
                this.items.startdate_local_presale,
                this.items.startdate_amex,
            ];

            // GET THE EARLIEST DATE FROM DATELIST
            const PRESALE_START_DATE = new Date(Math.min(...DATELIST.map((date) => new Date(date))));

            // IF DATE END IS NOT SET, ASSIGN IT TO START DATE
            if (this.items.date_end === "") this.items.date_end = this.items.date;

            //ASSIGN TODAY
            const TODAY = new Date(new Date().setHours(0, 0, 0, 0));

            if (TODAY > new Date(this.items.date_end)) {
                this.status = "completed";
            } else if (TODAY >= new Date(this.items.date) && TODAY < new Date(this.items.date_end)) {
                this.status = "ongoing";
            } else if (ONSALE >= TODAY && TODAY < new Date(this.items.date_end)) {
                this.status = "onsale";
            } else if (TODAY >= PRESALE_START_DATE && TODAY < new Date(this.items.date_end)) {
                this.status = "presale";
            } else {
                this.status = "pending";
            }
        },
        openEditMarketingTypeDialog(payload) {
            this.EditMarketingTypeDialog = true;
            this.marketingTypesDialog = false;
            this.marketingTypeName = payload.marketingName;
            this.marketingId = payload.marketingId;
        },
        checkIfData() {
            if (this.ticketBars.length > 0) {
                this.ticketDataFound = true;
                this.loader = false;
            } else {
                this.ticketDataFound = false;
                this.loader = false;
            }
        },
        async getData() {
            try {
                let response = await this.$GetService(`${this.$getEditRoute(this.$route.path)}&inc=assoc`);
                if (response.status === 200) {
                    this.items = await this.$ObjectFactory.BuildConcert({ concert: response.data });

                    console.log(this.items)

                    this.getAllExistingMarketingTypes();
                    if (response.data.budgets) {
                        let status = Math.max.apply(
                            Math,
                            response.data.budgets.map((budget) => {
                                return budget.status;
                            })
                        );
                        let budget = response.data.budgets.find((budget) => {
                            return budget.status == status;
                        });
                        this.budgetid = budget.budgetid;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        getAllExistingMarketingTypes() {
            // Get budget rows from the newest current budget.
            const budget = this.items.getCurrentBudget();
            if (budget == null) return;

            const { budget_rows } = budget;

            // All marketing types is in budget step 4
            this.allMarketingTypes = budget_rows.reduce((acc, row) => {
                if (row.step === "4") {
                    if (row.value > 0) {
                        acc.push({ name: row.name, value: row.value });
                    }
                }
                return acc;
            }, []);
        },
        fixNull() {
            for (let key in this.items) {
                if (this.items[key] === null) {
                    this.items[key] = "";
                }
            }
        },
        async initRawTicketSales() {
            if (this.items.tickets) {
                this.items.tickets.map((ticket) => {
                    if (ticket.ticket_sales) {
                        ticket.ticket_sales.map((sale) => this.rawTicketSales.push(sale));
                    }
                });
                this.sortGraphData(this.rawTicketSales, "date");
            }
        },

        /**
         * Check to see if a sale data is within the correct date range.
         * Is used to collect data for graphs and the like.
         * @param data is the sale data
         * @param key is the date key in the sale data
         * @param startDate (optional) is the start date to be used in the compare
         * @param endDate (optional) is the end date to be used in the compare
         * @param val (optional) is the value key in the sale data
         * @returns true if in range
         */
        isDataInRange(data, key, startDate = this.datePicker.startDate.date, endDate = this.datePicker.endDate.date, valKey = "amount") {
            let saleDate = new Date(data[key]);
            if (data[key])
                if (saleDate > dayjs() && +data[valKey] < 1)
                    // dont allow sale dates in the future with a value of 0
                    return false;
            if (saleDate >= new Date(startDate) && saleDate <= new Date(endDate)) return true;

            return false;
        },
        getDefaultDates() {
            let MIN_DATE = null;
            let MAX_DATE = null;
            try {
                this.items.dev_min_date = this.items.dev_min_date ? this.items.dev_min_date : new Date().toISOString();
                this.items.dev_max_date = this.items.dev_max_date ? this.items.dev_max_date : new Date().toISOString();
                MIN_DATE = new Date(this.items.dev_min_date.substr(0, 10)).toISOString().substr(0, 10);
                MAX_DATE = new Date(this.items.dev_max_date.substr(0, 10)).toISOString().substr(0, 10);
            } catch (error) {
                MIN_DATE = "";
                MAX_DATE = "";
            }

            // ASSIGN DEFAULT DATES
            this.defaultDates.startDate = MIN_DATE;
            this.defaultDates.endDate = MAX_DATE;
            this.datePicker.startDate.date = MIN_DATE;
            this.datePicker.endDate.date = MAX_DATE;

            // RENDER DATEPICKER
            this.datesCollected = true;
        },

        setStartDate(payload) {
            this.datePicker.startDate.date = payload;
            //console.log(this.datePicker);
        },
        setEndDate(payload) {
            this.datePicker.endDate.date = payload;
            //console.log(this.datePicker);
        },
        calcTicketAvailable() {
            if (this.items.tickets) return this.items.tickets.map((ticket) => +ticket.available).reduce((a, b) => a + b);
        },
        calcTicketRevenue(startDate = this.datePicker.startDate.date, endDate = this.datePicker.endDate.date) {
            let revenue = 0;
            this.items.tickets.map((ticket) => {
                if (ticket.ticket_sales) {
                    ticket.ticket_sales.map((sale) => {
                        if (this.isDataInRange(sale, "date", startDate, endDate)) revenue += +ticket.price * +sale.amount;
                    });
                }
            });
            return revenue;
        },
        calcTicketSales(startDate = this.datePicker.startDate.date, endDate = this.datePicker.endDate.date) {
            this.totalTicketSales = 0;
            this.ticketSalesData = [];
            this.allTicketSalesData = [];
            this.items.tickets.map((ticket) => {
                if (ticket.ticket_sales && ticket.price > 0) {
                    ticket.ticket_sales.map((sale) => {
                        this.totalTicketSales += +sale.amount;
                        this.initDataForGraph(sale, "date", "amount", this.allTicketSalesData);
                    });
                }
            });
            return this.totalTicketSales;
        },
        calcTotalTicketRevenue() {
            return +this.items.tickets.map((ticket) => ticket.available * ticket.price).reduce((a, b) => a + b);
        },
        calcSalesPercentage() {
            return Math.round((this.progressionCard.dataObject.sales.number / this.items.tickets_available) * 100);
        },
        calcRevenuePercentage() {
            return Math.round((this.progressionCard.dataObject.revenue.number / this.calcTotalTicketRevenue()) * 100);
        },
        calcBreakEven() {
            let ticketsToBreakEven = this.budgetData.break_even;

            let ticketSales = this.calcTicketSales();
            let breakEvenPercentage = ticketsToBreakEven && ticketSales ? Math.round((ticketSales / ticketsToBreakEven) * 100) : 0;

            if (ticketSales) {
                return {
                    value: Math.round(ticketsToBreakEven - ticketSales < 0 ? 0 : ticketsToBreakEven - ticketSales),
                    percentage: breakEvenPercentage > 100 ? 100 : breakEvenPercentage,
                };
            } else {
                return {
                    value: ticketsToBreakEven,
                    percentage: 0,
                };
            }
        },
        getSalesData() {
            this.newDataLoading = true;
            let selectedDateRange = this.getDateRangeArray(new Date(this.datePicker.startDate.date), new Date(this.datePicker.endDate.date));
            if (this.items.tickets) {
                this.getProgressionCardData(this.datePicker.startDate.date, this.datePicker.endDate.date);

                this.salesProgressIsLoading = true;
                this.getSalesProgressCardData(this.datePicker.startDate.date, this.datePicker.endDate.date);
                this.getTicketBarsAndGrossBars(this.datePicker.startDate.date, this.datePicker.endDate.date);
                this.salesProgressIsLoading = false;

                this.initAnalyticsDataForGraph(this.items.analytics);

                this.sortGraphData(this.allTicketSalesData, 0);
                this.sortGraphData(this.analyticsData, 0);

                this.salesGraphOptions = initEchart({
                    xAxisData: selectedDateRange,
                    title: "Sales",
                    yAxisLTitle: "Tickets",
                    //yAxisRTitle: "Visitors",
                });

                this.ticketSalesData = this.items.getConcertSalesGraphData(this.datePicker.startDate.date, this.datePicker.endDate.date);
                // this.setMissingDates(this.ticketSalesData[0][0], this.ticketSalesData[this.ticketSalesData.length - 1][0], this.ticketSalesData);
                this.sortGraphData(this.ticketSalesData, 0);
                if (this.ticketSalesData.length > 0)
                    this.salesGraphOptions.series.push(generateSerie({ data: this.ticketSalesData, name: "Ticket sales" }));

                if (this.analyticsData.length > 0)
                    this.salesGraphOptions.series.push(
                        generateSerie({
                            data: this.analyticsData,
                            name: "Google analytics",
                            yAxis: 1,
                        })
                    );

                this.currentSummaryData.ticketRevenues = this.calcTicketRevenue();
            }

            this.marketingGraphOptions = initEchart({
                xAxisData: selectedDateRange,
                title: "Marketing",
            });
            if (this.selectedBenchmark.concertid || this.selectedBenchmark.comparable_concertid)
                this.initBenchmarkDataForGraph(this.selectedBenchmark);

            this.selectedCampaigns = this.getSelectedCampaigns();

            this.newDataLoading = false;
        },

        getProgressionCardData(startDate, endDate) {
            // reset graph data
            this.ticketSalesData = [];
            this.allTicketSalesData = [];
            this.analyticsData = [];

            this.progressionCard.dataObject.revenue.subtext = this.$config.CURRENCY;

            this.items.tickets_available = this.calcTicketAvailable();
            this.progressionCard.dataObject.revenue.number = this.calcTicketRevenue(startDate, endDate);
            this.progressionCard.dataObject.revenue.percentage = this.calcRevenuePercentage();
            this.progressionCard.dataObject.revenue.progresstext = `${this.calcRevenuePercentage()}% Sold`;

            this.progressionCard.dataObject.sales.number = this.calcTicketSales(startDate, endDate);
            this.progressionCard.dataObject.sales.percentage = this.calcSalesPercentage();
            this.progressionCard.dataObject.sales.progresstext = `${this.calcSalesPercentage()}% Sold`;

            if (this.budgetData) {
                if (this.budgetData.budget_tickets) {
                    let breakEvenPackage = this.calcBreakEven();
                    this.progressionCard.dataObject.until_breakeven.number = breakEvenPackage.value;
                    this.progressionCard.dataObject.until_breakeven.percentage = breakEvenPackage.percentage;
                    if (breakEvenPackage.percentage > 100)
                        this.progressionCard.dataObject.until_breakeven.progresstext = `${breakEvenPackage.percentage % 100} % past break-even`;
                    else this.progressionCard.dataObject.until_breakeven.progresstext = `${breakEvenPackage.percentage} % towards break-even`;
                }
            }
        },
        getSalesProgressCardData(startDate, endDate) {
            this.donutData.tickets_sold = this.progressionCard.dataObject.sales.number;
            this.donutData.ticket_sold_percentage = this.progressionCard.dataObject.sales.percentage;
            this.donutData.gross_sales = this.progressionCard.dataObject.revenue.number;
            this.donutData.gross_sales_percentage = this.progressionCard.dataObject.revenue.percentage;
        },
        getTicketBarsAndGrossBars(startDate, endDate) {
            let ticketBars = [];
            let grossBars = [];
            this.items.ticketObjects.map((ticket) => {
                if (ticket.ticket_sales) {
                    ticketBars.push({
                        title: ticket.name,
                        percentage: this.getTicketsInRange(startDate, endDate, ticket.name).percentage,
                        total: `${this.getTicketsInRange(startDate, endDate, ticket.name).amountSold} out of ${ticket.available}`,
                    });
                    grossBars.push({
                        title: ticket.name,
                        percentage: Math.round((ticket.ticket_sales.map((sale) => sale.amount).reduce((a, b) => +a + +b) / ticket.available) * 100),
                        total: `${ticket.getGrossSales(startDate, endDate)} out of ${Math.round(ticket.available * ticket.price)}`,
                    });
                }
            });
            this.ticketBars = ticketBars;
            this.grossBars = grossBars;
        },
        async getSelectedCampaigns() {
            const viewObject = await this.$ObjectFactory.BuildCollection({ endpoint: "fb_api" });
            await viewObject.fetchAll({ route: `?concertid=${this.$route.params.id}` });
            this.selectedCampaigns = viewObject.getCollection();
            this.initMarketingDataForGraph();
        },
        async getMarketingData() {
            console.log("get marketing data");
            let data = await this.getGenericData(this.$getEditRoute(this.$route.path));
            this.items.marketing = data.marketing;
            this.marketingBars = [];
            console.log("AJKLÖSHDJKLASDJ");
            console.log(data.marketing);
            console.log(this.items.marketing);
            await this.getMarketingBars();
            this.initMarketingDataForGraph();
            this.getTotalMarketing();
        },
        getMarketingBars() {
            this.marketingBars = [];
            this.marketingColorIndex = 0;
            if (this.items.marketing) {
                this.items.marketing.map((item, index) => {
                    console.log(index);
                    this.marketingBars.push({
                        title: item.name,
                        // total: !item.marketing_data ? "0" : `${this.getSingleMarketingTotal(item)} out of ${this.getMarketingBudgetValues(item.name) === 0 ? this.getSingleMarketingTotal(item) : this.getMarketingBudgetValues(item.name)} `,
                        total: !item.marketing_data
                            ? "0"
                            : `${this.getSingleMarketingTotal(item)} out of ${
                                  item.estimate === 0 ? this.getSingleMarketingTotal(item) : item.estimate
                              } `,
                        percentage: !item.marketing_data
                            ? "0"
                            : item.estimate == 0
                            ? "No estimate"
                            : Math.round((+this.getSingleMarketingTotal(item) / item.estimate) * 100),
                        color: this.getMarketingColorByIndex(index),
                    });
                });
            }
        },
        getTotalMarketing() {
            this.marketingCost = [];
            if (!this.items.marketing) return 0;
            this.marketingCost = this.items.marketing.reduce((acc, value) => {
                acc += +this.getSingleMarketingTotal(value);
                return acc;
            }, 0);
            console.log(this.marketingCost);
        },
        getSingleMarketingTotal(item) {
            if (!item.marketing_data) return 0;
            if (item.marketing_data.length === 1) {
                return item.marketing_data.map((data) => data.amount);
            } else {
                return item.marketing_data.map((data) => data.amount).reduce((a, b) => +a + +b);
            }
        },
        getMarketingBudgetValues(name) {
            let value;
            if (this.budgetData) {
                this.budgetData.budget_rows.map((row) => {
                    if (row.step === "4" && row.name === name) {
                        value = row.value;
                    }
                });
                return value || 0;
            }
        },

        /**
         * Collect a marketing color
         */
        getMarketingColorByIndex(index) {
            let color = this.companyColors[index];
            return color;
        },

        getTicketsInRange(startDate, endDate, ticketName) {
            let amountSold = 0;
            let available = 0;
            this.items.tickets.map((ticket) => {
                if (ticket.ticket_sales) {
                    ticket.ticket_sales.map((sale) => {
                        if (this.isDataInRange(sale, "date", startDate, endDate) && ticket.name === ticketName) {
                            available = ticket.available;
                            amountSold += +sale.amount;
                        }
                    });
                }
            });
            return {
                amountSold: amountSold,
                percentage: Math.round(amountSold === 0 ? 0 : (amountSold / available) * 100),
            };
        },
        async getBenchmarkConcerts() {
            let benchmarks = await this.getGenericData(this.$getRoute("comparableprojects").main_route);
            if (benchmarks) benchmarks.map((benchmark) => this.benchmarkConcerts.push(benchmark));

            let concerts = await this.getGenericData(`${this.$getRoute("concerts").main_route}?fields=title`);
            if (concerts) {
                concerts.map((concert) => this.benchmarkConcerts.push(concert));
            }
        },

        getConcertRealStartEnd() {
            let maxDatesArray = [];

            let minDatesArray = [];
            const pushMinDateIfSet = (...dates) => {
                dates.map((date) => (date ? minDatesArray.push(date) : null));
            };
            pushMinDateIfSet(
                this.items.on_sale,
                this.items.startdate_spotify,
                this.items.startdate_fan_club,
                this.items.startdate_local_presale,
                this.items.startdate_amex
            );

            const pushMaxDateIfSet = (...dates) => {
                dates.map((date) => (date ? maxDatesArray.push(date) : null));
            };
            pushMaxDateIfSet(this.items.date, this.items.date_end);

            let minDate = new Date(Math.min(...minDatesArray.map((date) => new Date(date)))).toISOString().substring(0, 10);
            let maxDate = new Date(Math.max(...maxDatesArray.map((date) => new Date(date)))).toISOString().substring(0, 10);
            return [minDate, maxDate];
        },

        getDateRangeArray(start, end) {
            start = new Date(start);
            end = new Date(end);
            let arr = [];
            while (start <= end) {
                arr.push(start.toISOString().substr(0, 10));
                start = this.getFinalDates(start, 1);
            }
            return arr;
        },
        getFinalDates(date, add) {
            let myDate = date;
            let day = 86400000; //number of milliseconds in a day
            return new Date(+myDate + add * day);
        },

        getPredictionSalesData() {
            let predictionSales = [];
            if (this.items.sales_prediction) {
                this.items.sales_prediction.map((data) => {
                    this.initDataForGraph(data, "date", "amount", predictionSales);
                });
                this.sortGraphData(predictionSales, 0);
            }
        },
        /**
         * Takes a specific sale of a ticket type and adds to the saleData array
         * EX: this.initDataForGraph(sale, 'date', 'amount', this.benchmarkData)
         * where sale = { date: '2020-01-01', amount: 100 }
         */
        initDataForGraph(saleData, datekey, valuekey, salesArray) {
            let found = false;

            salesArray.forEach((sale) => {
                if (saleData[datekey] == sale[0]) {
                    found = true;
                    sale[1] += +saleData[valuekey];
                }
            });
            if (!found) salesArray.push([saleData[datekey], +saleData[valuekey]]); // Add new date with sales
        },
        /**
         * Collects the analytics data for the graph
         */
        initAnalyticsDataForGraph(analyticsData) {
            let tmpDate = new Date(this.datePicker.startDate.date);

            if (analyticsData) {
                while (tmpDate <= new Date(this.datePicker.endDate.date)) {
                    let isoDate = tmpDate.toISOString().substr(0, 10);
                    let analytic = analyticsData.find((datapoint) => {
                        return datapoint.date === isoDate;
                    });

                    if (analytic != undefined) {
                        this.analyticsData.push([analytic.date, analytic.visitors]);
                    }

                    tmpDate.setDate(tmpDate.getDate() + 1);
                }

                this.sortGraphData(this.ticketSalesData, 0);
                this.sortGraphData(this.analyticsData, 0);
            }
        },
        initBenchmarkOptions() {
            let startEnd = this.getConcertRealStartEnd();
            let fullDateRange = this.getDateRangeArray(startEnd[0], startEnd[1]);
            let colors = ["#3e5894", "#bd3136", "#bd319c", "#31bd5d", "#adbd31", "#bd7731"];

            this.benchGraphOptions = initEchartTwoX({
                xAxisData: fullDateRange,
                xAxisData2: [...new Array(100)].map((x, index) => (x = ++index)), // generate array from 1 to 100
                title: "Benchmark",
                colors: colors,
                yAxisLTitle: "Tickets",
            });
            this.benchGraphOptions.xAxis[1].axisLabel = { show: false };
            console.log("allticketsalesdata");
            console.log(this.allTicketSalesData);
            if (this.allTicketSalesData && this.allTicketSalesData.length != 0) {
                let salesSerie = generateSerieTwoX({
                    data: this.allTicketSalesData,
                    name: "Ticket sales",
                });
                //salesSerie.markLine.data.push({ name: 'Today', xAxis: new Date().toISOString().substr(0,10) });
                this.benchGraphOptions.series.push(salesSerie);
            }
        },
        async initBenchmarkDataForGraph(bench) {
            if (this.benchGraphOptions.series.length == 0) this.initBenchmarkOptions();

            this.benchmarkData = [];
            // if(this.benchGraphOptions.series.length > 0);
            // 	this.benchGraphOptions.series.length = 1;
            let yAxis = 0;

            if (bench.comparable_concertid) {
                let benchmark = await this.getGenericData(`${this.$getRoute("comparableprojects").main_route}?id=${bench.comparable_concertid}`);

                let x = benchmark.graph_x.split(";");
                let y = benchmark.graph_y.split(";");

                let total = 0;
                y.map((point) => (total += +point));

                let ticketsAvail = this.calcTicketAvailable();
                for (let i = 0; i < x.length; i++) this.benchmarkData.push([x[i], ticketsAvail ? Math.round((y[i] / total) * ticketsAvail) : y[i]]);
                console.log(this.benchmarkData);
            } else {
                let concert = await this.getGenericData(`${this.$getRoute("concerts").main_route}?id=${bench.concertid}`);
                //console.log(concert)
                let tmpSales = [];

                if (concert) {
                    this.benchmarkData = [];

                    if (concert.tickets) {
                        concert.tickets.map((ticket) => {
                            if (ticket.ticket_sales) ticket.ticket_sales.map((sale) => tmpSales.push(sale));
                        });
                    }
                    this.sortGraphData(tmpSales, "date");

                    tmpSales.map((sale) => {
                        this.initDataForGraph(sale, "date", "amount", this.benchmarkData);
                    });

                    yAxis = 1;
                }
            }
            this.benchGraphOptions.series.push(
                generateSerieTwoX({
                    data: this.benchmarkData,
                    name: bench.title,
                    yAxis: yAxis,
                    xAxis: 1,
                })
            );
            this.$logger("benchGraphOptions", this.benchGraphOptions);
        },

        splitMarketingData(startDate, endDate, amount) {
            let arr = [];
            let diff = endDate.getTime() - startDate.getTime();
            let totalDays = diff == 0 ? 1 : Math.round(diff / (1000 * 3600 * 24)) + 1;
            const splitAmount = Math.round(+amount / totalDays);

            // fill dates inbetween
            while (startDate.getTime() <= endDate.getTime()) {
                // collect amount for profit and loss statement
                if (startDate.getTime() <= new Date(this.datePicker.endDate.date + " 12:00:00"))
                    this.currentSummaryData.marketingCosts += splitAmount;

                arr.push([startDate.toISOString().substring(0, 10), splitAmount]);
                startDate.setDate(startDate.getDate() + 1);
            }
            return arr;
        },

        async initMarketingDataForGraph() {
            let endDate = "";
            let startDate = "";

            this.marketingGraphOptions.series.length = 0;

            let freshMarketing = null;
            if (this.items.marketing) {
                freshMarketing = JSON.parse(JSON.stringify(this.items.marketing));
            }

            this.currentSummaryData.marketingCosts = 0;

            if (freshMarketing) {
                this.marketingColorIndex = 0;
                freshMarketing.map((data, index) => {
                    let marketingDataSplit = [];
                    let marketingData = [];

                    if (data.marketing_data) {
                        data.marketing_data.map((datapoint) => {
                            if (this.isDataInRange(datapoint, "startdate")) {
                                startDate = new Date(Date.parse(datapoint.startdate + " 12:00:00"));
                                endDate = new Date(Date.parse(datapoint.enddate + " 12:00:00"));

                                marketingDataSplit = this.splitMarketingData(startDate, endDate, datapoint.amount);

                                this.setMissingDates(this.datePicker.startDate.date, this.datePicker.endDate.date, marketingDataSplit);
                            }
                        });
                        this.sortGraphData(marketingDataSplit, 0);
                        console.log(marketingDataSplit);
                        this.addMarketingSerieToGraph(marketingDataSplit, data.name, index);
                    }
                });
            }
            this.initCampaignDataForGraph();
        },

        initCampaignDataForGraph() {
            if (!this.selectedCampaigns?.length == 0 || !this.selectedCampaigns) return;
            this.$logger("selectedCampains", this.selectedCampaigns);
            for (let [index, campaign] of this.selectedCampaigns) {
                let campaignData = [];
                if (this.isDataInRange(campaign, "startdate")) {
                    var startDate = new Date(Date.parse(campaign.startdate + " 12:00:00"));
                    var endDate = new Date(Date.parse(campaign.enddate + " 12:00:00"));

                    let amount = (+campaign.budget > campaign.budget_summed ? +campaign.budget : +campaign.budget_summed) / 100; // budget+budget sum is stored in cents

                    campaignData = this.splitMarketingData(startDate, endDate, amount);

                    this.sortGraphData(campaignData, 0);
                    this.addMarketingSerieToGraph(campaignData, campaign.name, index);
                }
            }
        },

        addMarketingSerieToGraph(data, name, index) {
            let color = this.getMarketingColorByIndex(index);

            if (data.length > 0) {
                let serie = generateSerie({
                    data: data,
                    name: name,
                    lineWidth: 2,
                });
                serie.itemStyle = { color: color };
                serie.areaStyle = {};

                this.marketingGraphOptions.series.push(serie);
            }
        },
        sortGraphData(data, key) {
            data.sort((a, b) => {
                return new Date(a[key]) - new Date(b[key]);
            });
        },

        setMissingDates(fromdate, enddate, salesArray) {
            //Convert dates from ISOStrings to date objects, set time to 12:00:00 to avoid daylight savings issues
            var date = new Date(Date.parse(fromdate + " 12:00:00"));
            var todate = new Date(Date.parse(enddate + " 12:00:00"));
            var isodate = "";
            var dateArray = [];

            //Fill dateArray with existing dates
            for (let index = 0; index < salesArray.length; ++index) dateArray.push(salesArray[index][0]);

            do {
                //convert date to ISOString
                isodate = date.toISOString().substring(0, 10);
                //If date is missing, push object to salesArray for missing date
                if (dateArray.indexOf(isodate) == -1) salesArray.push([isodate, null]);

                //Add one day to date
                date.setDate(date.getDate() + 1);
            } while (date <= todate);
        },

        async getGenericData(route) {
            try {
                let response = await this.$GetService(route);
                if (response.status == 200) {
                    return response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async createBudget() {
            try {
                let response = await this.$PostService(this.$getRoute("budgets").main_route, { tourid: 0, concertid: this.items.concertid });

                if (response.status == 200) {
                    this.creatingNewBudget = true;
                    this.budgetid = response.data.budgetid;
                    await this.getVenueTickets();
                    await this.getBudgetRows();
                    if (this.venueTickets.length > 0) await this.postVenueTickets();
                    await this.postVenueBudgetRows();
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getVenueTickets() {
            try {
                let response = await this.$GetService(this.$getSubRoute("venues", "tickets") + "?venueid=" + this.items.venueid.venueid);
                console.log(response);
                if (response.status == 200) {
                    //this.venueTickets = response.data
                    for (let ticket of response.data) {
                        ticket.budgetid = this.budgetid;
                        ticket.price = ticket.default_price;
                        ticket.capacity = ticket.default_capacity;
                        ticket.platinum = ticket.platinum;
                        this.venueTickets.push(ticket);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getBudgetRows() {
            try {
                let response = await this.$GetService(this.$getSubRoute("venues", "rows") + "?venueid=" + this.items.venueid.venueid);
                if (response.status == 200) {
                    // READ BUDGET ROW TEMPLTE AND CONFIRM NONE IS MISSING
                    let defaultRows = getAllRows(this.budgetid);
                    console.log(defaultRows);
                    let newObj = {};
                    for (let row of response.data) {
                        row.budgetid = this.budgetid;
                        newObj[row.name] = row;
                    }

                    for (let row of defaultRows) {
                        const real = newObj[row.name];
                        if (real) {
                            for (const KEY in real) row[KEY] = real[KEY];
                        }
                        this.venueBudgetRows.push(row);
                    }
                    console.log(this.venueBudgetRows);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async postVenueTickets() {
            try {
                let response = await this.$PostService(this.$getSubRoute("budgets", "tickets"), this.venueTickets);
                console.log(response);
                if (response.status == 200) {
                    console.log("success");
                }
            } catch (error) {
                console.log(error);
            }
        },
        async postVenueBudgetRows() {
            try {
                console.log(this.venueBudgetRows);
                let response = await this.$PostService(this.$getSubRoute("budgets", "rows"), this.venueBudgetRows);
                console.log(response);
                if (response.status == 200) {
                    console.log("postVenueRows success");
                    this.$router.push(`/budgets/${this.budgetid}`);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async updateDataFromSlider() {
            let route = this.$route.path.substr(1).replace(/\/edit\//g, "?id=");
            try {
                let response = await this.$GetService(this.$getEditRoute(this.$route.path));
                if (response.status === 200) {
                    this.items = Object.assign({}, response.data);
                }
            } catch (error) {
                console.log(error);
            }
        },
        ...mapActions({
            setDrawersDisabled: "globalstates/setDrawersDisabled",
            unSetDrawersDisabled: "globalstates/unSetDrawersDisabled",
        }),
    },
    computed: {
        ...mapGetters({
            companyColors: "globalstates/companyColors",
            view: "globalstates/view",
        }),
    },
    beforeDestroy() {
        this.setDrawersDisabled();
        clearTimeout(this.timeOut);
    },
};
</script>

<style lang="scss" scoped>
a {
    text-decoration: none !important;
}
button {
    font-weight: 600 !important;
}

.v-tooltip__content {
    padding: 5px 10px !important;
}

.budget-summary {
    border-radius: 3px;
    background: #11283e;
}

.waiting-for-budget-approval {
    display: flex;
    background: rgba(255, 166, 0, 0.59) !important;
    border-radius: 3px;
    padding: 10px 15px;
    span {
        font-size: 14px;
        color: #fff;
        margin: auto;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.fadein-container {
    min-height: calc(100vh - 170px);
    position: relative;
    width: 100%;
    float: left;
    opacity: 0;
    transition: all 0.5s ease-out;
    margin-top: -20px;
}

.fade-in {
    opacity: 1 !important;
    margin-top: 0px !important;
}

.graph-container {
    position: relative;
    border-radius: 3px;
    background: #fff;
}
.custom-row {
    margin-left: -20px;
    margin-right: -20px;
}

.button-wrapper {
    margin-left: 15px;
    margin: auto;
    display: flex;
    flex: 1;
    .v-btn {
        background-color: #ffffff21 !important;
        flex: 1;
        width: 100%;
        i {
            font-size: 21px;
            margin-right: 15px;
        }
        span {
            color: #fff !important;
        }
    }
}

.benchmark-wrapper {
    padding: 15px;
    .benchmark {
        &::v-deep .v-input {
            color: #fff !important;
        }
        &::v-deep .v-text-field__details {
            display: none;
        }
        &::v-deep .v-input__slot {
            margin: 0;
        }
    }
}

.concert-title-wrapper {
    text-transform: uppercase;

    line-height: 1.3;
    align-items: center;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    i {
        margin-right: 10px;
        color: $accent-color;
    }
    span {
        padding: 0px 15px;
        color: $font-color-pale;
    }
    .concert-title {
        font-weight: 500;
        font-size: 0.9rem;
        max-width: 450px;
        float: left;
        text-align: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .artist-name {
        font-size: 0.9rem;
        font-weight: 500;
        float: left;
        text-align: center;
        display: flex;
        align-items: center;
        height: 100%;
    }
    .venue-name {
        font-size: 0.9rem;
        font-weight: 500;
        float: left;
        text-align: center;
        display: flex;
        align-items: center;
        height: 100%;
    }
    .date {
        font-size: 0.9rem;
        font-weight: 500;
        float: left;
        text-align: center;
        display: flex;
        align-items: center;
        height: 100%;
    }
    .sale-status-wrapper {
        display: flex;
        float: left;
        height: 100%;
        position: relative;
        margin-left: 10px;
        padding-left: 5px;
        span {
            margin: auto;
            font-size: 0.9rem;
            padding: 7px 25px 7px 15px;
            font-weight: 500;
            letter-spacing: 0.08rem;
            display: flex;
            align-items: center;
            border: none;
        }
        .presale {
            color: #65ef90;
        }
        .onsale {
            color: #6eef2b;
        }
        .ongoing {
            color: #49a7ff;
        }
        .ongoing-static,
        .ongoing-fade {
            margin-right: 10px;
            position: absolute;
            font-size: 21px;
            width: 10px;
            left: 0px;
            height: 10px;
            background: #c93333;
            border-radius: 10px;
        }
        .ongoing-static {
            z-index: 10;
        }
        .ongoing-fade {
            animation: concertongoing infinite 1s ease-out forwards;
            background: red;
            z-index: 0;
        }
        .ended {
            color: #ff3939;
        }
        .pending {
            color: #ffaa2a;
        }
        @keyframes concertongoing {
            0% {
                opacity: 1;
                transform: scale(1);
            }
            100% {
                opacity: 0;
                transform: scale(2.5);
            }
        }
    }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #fb8c00 !important;
}

.salesprogresscard-container {
    position: relative;
}

@media only screen and (max-width: 1264px) {
    .title-container {
        display: block !important;
        max-width: 100% !important;
        float: left;
    }
    .header-actions-wrapper {
        display: block !important;
        max-width: 100%;
        float: left;
    }
    .header-wrapper {
        display: block !important;
        background-size: cover !important;
    }
    .concert-title {
        margin-top: 10px !important;
    }
    .button-wrapper {
        display: block !important;
        button {
            margin-bottom: 10px;
        }
    }
}
</style>
