 <template>
    <!-- <div style="width: 100%">
        <v-col
            cols="12"
            md="12"
            class="float-left pa-5"
            style="position: relative"
        >
            <Loader :loaderStyle="'linear'" v-if="isTableDataPending" />

            <div
                id="edittableitem"
                class="elevation-1"
                v-if="!isTableDataPending"
            >
                <v-col
                    cols="12"
                    md="12"
                    class="float-left"
                    :class="isMobile ? 'pa-5 pt-3' : 'pa-8'"
                > -->
                <div v-if="!isTableDataPending">
                    <general-form
                        v-if="!expandVersion"
                        ref="generalFormRef"
                        :items="localApiObject"
                        :options="getFormOptions(view)"
                        :dropdown_lists="dropdown_lists"
                        :objectRoute="view"
                        :metaParam="metaParam"
                        @requiredFieldsError="$emit('requiredFieldsError')"
                        @changesMade="changesFound()"
                        @noChangesMade="noChangesFound()"
                        @refresh_dropdown_list="(callback) => $emit('refresh_dropdown_list', callback)"
                    />
                    <general-expand-form
                        v-if="expandVersion"
                        ref="generalFormRef"
                        :items="localApiObject"
                        :options="getFormOptions(view)"
                        :dropdown_lists="dropdown_lists"
                        :objectRoute="view"
                        :metaParam="metaParam"
                        @requiredFieldsError="$emit('requiredFieldsError')"
                        @changesMade="changesFound()"
                        @noChangesMade="noChangesFound()"
                        @closeEditDrawer="$emit('closeEditDrawer')"
                        @refresh_dropdown_list="(callback) => $emit('refresh_dropdown_list', callback)"
                    />
                </div>
                <!-- </v-col>
            </div>
        </v-col>
    </div> -->
</template>
<script>
import Loader from "@/components/Loader";
import RemoveDialog from "./RemoveDialog.vue";
import GeneralForm from "./forms/GeneralForm.vue";
import GeneralExpandForm from "./forms/GeneralExpandForm.vue";
import ApiObject from "@/models/ApiObject.js";
import ObjectFactory from "@/models/ObjectFactory";
import ConcertOptions from "./forms/FormOptions/ConcertOptions";
import ArtistOptions from "./forms/FormOptions/ArtistOptions";
import TourOptions from "./forms/FormOptions/TourOptions";
import GeneralOptions from "./forms/FormOptions/GeneralOptions";
import { mapGetters } from "vuex";

export default {
    name: "handlegeneralform",
    props: {
        metaParam: String,
        apiObject: Object,
        view: String,
        dropdown_lists: Object,
        expandVersion: Boolean,
    },
    components: {
        Loader,
        RemoveDialog,
        GeneralForm,
        GeneralExpandForm,
    },
    data: () => ({
        noChangesMade: true,
        noEditTableChange: true,
        isTableDataPending: true,
        originalData: [],
        concertOptions: null,
        artistOptions: null,
        tourOptions: null,
        generalOptions: null,
        splitInfoCollection: null,
        localApiObject: null,
        primarykey: '',
    }),
    watch: {
        localApiObject: {
            handler(obj){
                console.log('LOCAL API OBJECT UDPATED')
                console.log(obj);
            },
            deep: true,
        }
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
    async created() {
        console.log('expandVersion ', this.expandVersion);
        await this.initComponent()
    },
    async updated() {
        console.log('edittable updated');
    },
    methods: {
        async initComponent(){
            console.log('HEREHEREHERE')
            this.localApiObject = await this.$ObjectFactory.BuildObject({endpoint: this.view, fromApiObj: this.apiObject });

            if (this.metaParam === "create") {
                this.localApiObject.environment_consious = "0";
            }

            this.originalData = Object.assign(
                Object.create(Object.getPrototypeOf(this.localApiObject)),
                this.localApiObject
            );
            this.primarykey = this.localApiObject.pk_field;

            /**
             * Create the various options objects
             */
            this.concertOptions = await ConcertOptions.Build(
                this.view,
                this.metaParam
            );
            this.artistOptions = await ArtistOptions.Build(
                this.view,
                this.metaParam
            );
            this.tourOptions = await TourOptions.Build(this.view, this.metaParam);
            this.generalOptions = await GeneralOptions.Build(
                this.view,
                this.metaParam
            );

            this.unSetLoading();
        },

        /**
         * Returns the relevant form options based on which view we are on.
         */
        getFormOptions(view) {
            switch (view) {
                case "artists":
                    return this.artistOptions;
                case "concerts":
                    return this.concertOptions;
                case "tours":
                    return this.tourOptions;
                case "venues":
                case "people":
                case "organizations":
                case "invoices":
                default:
                    return this.generalOptions;
            }
        },

        label: (label) => label.replace(/[_|\w]/g, " "),
        rnd() {
            return Math.floor(Math.random() * Math.floor(9999));
        },

        changesFound() {
            this.noChangesMade = false;
            this.$emit(
                "generalFormUpdate",
                this.noChangesMade,
                this.localApiObject
            );
        },
        noChangesFound() {
            this.noChangesMade = true;
            this.$emit(
                "generalFormUpdate",
                this.noChangesMade,
                this.localApiObject
            );
        },

        validate(){
            return this.$refs.generalFormRef.validate();
        },
        
        /**
         * Starting point for the creation process in HandleGeneralForm
         * @param validatedItems - The valid items that should be posted
         * @returns the result from setData()
         */
        async startCreation(validatedItems){

            this.isUpdatePending = true;
            let data = { items: validatedItems.formItems };

            if (this.metaParam == "update") 
                data["action"] = "edit";

            /**
             * Collect and organize fk_tables
             */
            if (validatedItems.fk_tables) 
                data["fk_tables"] = validatedItems.fk_tables;

            return await this.setData(data);
        },

        /**
         * The function that post/put the items to the backend.
         * Handles the flow for both creating and editing.
         * @param args - Takes an object as in-parameter. This is then unwrapped directly into 3 seperate objects.
         * @returns
         */
        async setData({ items, fk_tables, action }) {

            /**
             * Remove empty fields
             */
            for (let key in items) 
                if (items[key] === "") 
                    delete items[key];

            /**
             * If editing an object that needs to be updated in the backend.
             */
            if (action === "edit") {

                /**
                 * Update the apiObject with the new incoming items.
                 * And then PUT them to the backend.
                 * The ApiObject function updateObject takes care of all this, and returns true if successful.
                 */
                const isUpdateSuccessful = await this.localApiObject.updateObject(items);

                /**
                 * Send the updated fk_table items to the backend.
                 * The ApiObjectCollection function updateCollection takes care of all this.
                 */
                if(fk_tables){
                    for(const col_key in fk_tables)
                        await fk_tables[col_key].updateCollection();
                }

                /**
                 * If the update was successful, save the new data as the original data.
                 */
                if (isUpdateSuccessful) {
                    this.originalData = Object.assign({}, this.localApiObject);
                    this.unSetLoading();
                }

                /**
                 * Finally, return an object containing the "new" id (not really new when editing),
                 * and also the api object that have been updated. 
                 */
                return { 
                    newid: this.localApiObject[this.localApiObject.pk_field], 
                    apiObj: this.localApiObject
                };
            } 
            else { // If Creating new
                console.log('POSTING SKER NU')
                console.log(items);
                /**
                 * Update the apiObject with the new incoming items.
                 * And then POST them to the backend.
                 * The ApiObject function postObject takes care of all this, and returns the new id if successful.
                 */
                let newid = await this.localApiObject.postObject(items);

                /**
                 * If a newid was recieved, take care of the fk_table items that needs to be updated based on the new id
                 */
                if (newid) {
                    if (fk_tables) {

                        /**
                         * Go over the fk tables and post the new items.
                         * Skip the removal lists
                         */
                        for(const col_key in fk_tables) {
                            if(!col_key.includes('to_remove')){
                                fk_tables[col_key].setFieldOnAll(
                                    fk_tables[col_key].FK_NAME,
                                    newid
                                ); // fk_tables[col_key].FK_NAME == 'tourid'
                                await fk_tables[col_key].updateCollection();
                            }
                        }

                        // TEMPORARY
                        /**
                         * Temporary fix to copy over dates from linked tour concerts 
                         * to the tour.
                         */
                        let minDates = [];
                        let maxDates = [];
                        if(fk_tables['concerts']){
                            for (let concert of fk_tables['concerts'].collection) {
                                let apiConc = await this.$ObjectFactory.BuildConcert({
                                    concertid: concert.concertid,
                                });
                                if(apiConc.dev_min_date != null)
                                    minDates.push(
                                        new Date(apiConc.dev_min_date.substr(0, 10))
                                    );
                                if(apiConc.dev_max_date != null)
                                    maxDates.push(
                                        new Date(apiConc.dev_max_date.substr(0, 10))
                                    );
                            }
                            const MIN_DATE = new Date(Math.min(...minDates));
                            const MAX_DATE = new Date(Math.max(...maxDates));
                            const currStart = items.startdate;
                            const currEnd = items.enddate;

                            if (MIN_DATE < (currStart ? new Date(currStart) : MAX_DATE))
                                this.localApiObject.startdate = MIN_DATE.toISOString().substring(0, 10);

                            if (MAX_DATE > (currEnd ? new Date(currEnd) : MIN_DATE))
                                this.localApiObject.enddate = MAX_DATE.toISOString().substring(
                                    0,
                                    10
                                );
                                
                            await this.localApiObject.updateObject(this.localApiObject);
                        }
                        
                    }

                    if (this.splitInfoCollection)
                        await this.setSplitInfo(newid);

                    /**
                     * Finally, return an object containing the new id,
                     * and also the api object that have been updated. 
                     */
                    return { newid: newid, apiObj: this.localApiObject };
                } else {
                    // TODO: Add creation failed feedback
                }
            }
        },

        handleEditFlow(){

        },

        handleCreateFlow(){

        },

        async setSplitInfo(newid) {
            for (let info of this.splitInfoCollection.collection)
                info.artistid = newid;
            await this.splitInfoCollection.postCollection();
        },
        async deleteColumn(id) {
            try {
                let response = await this.$DeleteService(
                    this.$getEditRoute(this.$route.path),
                    {
                        [this.primarykey]: id,
                    }
                );
                if (response.status === 200) {
                    this.$bus.$emit("unSetLoading");
                    this.$router.go(-1);
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        },
        setLoading() {
            this.isTableDataPending = true;
            this.$emit("tablePending", true);
        },
        unSetLoading() {
            console.log('unset loading');
            this.isTableDataPending = false;
            this.$emit("tableLoaded");
        },
    },
};
</script>

<style lang="scss">
#create-concert-form,
#create-tour-form {
    float: left;
    width: 100%;
    .v-chip {
        background: $accent-color !important;
        color: #fff !important;
    }
}

.v-picker {
    .v-picker__title {
        display: none;
    }
}

.datetimepicker_on_sale {
    .v-input {
        padding: 0px !important;
    }
}

#edittableitem {
    width: 100%;
    background: #fff;
    position: relative;
    min-height: 600px;
    float: left;
    border-radius: 3px;
}

.mobile .sticky {
    top: 50px;
    border-bottom: 1px solid $border-color;
    float: left;
    width: 100%;
    height: auto;
    .header {
        font-size: 1.2em;
    }
}

.sticky {
    position: sticky;
    height: 60px;
    top: 90px;
    width: 100%;
    z-index: 2;
}
#budgettemplate {
    width: 100%;
    background: #fff;
    position: relative;
    min-height: 600px;
}

.form-header-container {
    float: left;
    display: flex;
    align-items: center;
    background: #fff;
    .header {
        background-color: #fff;
        font-size: 1.4rem;
        font-weight: 600;
        color: $accent-color;
        padding: 20px;
        padding-bottom: 0px;
        float: left;
        padding: 0px;
    }
    .back-btn {
        i {
            font-size: 30px;
        }
    }
}

.v-btn--fab.v-size--default {
    width: 45px;
    height: 45px;
}

.header {
    font-weight: 600;
    color: $accent-color;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #ccc !important;
    color: #fff !important;
}

.delete-btn {
    margin-left: 20px;
}
.create-btn {
    margin-left: 20px;
}

.required-fields-warning {
    font-size: 0.85em;
    font-weight: 600;
    color: rgb(218, 50, 50);
}
</style>