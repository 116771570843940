import ApiObject from "./ApiObject";
import ObjectFactory from "./ObjectFactory";

export default class Notice extends ApiObject {
    constructor() {
        super('notices');
        this.unread = false;
    }

    static async Build() {
        var notice = new Notice();
        let objFactory = new ObjectFactory();
        notice = await objFactory.BuildObject({endpoint: 'notices', fromApiObj: notice });
        return notice;
    }

    markAsRead(){
        this.unread = false;
    }

    async postNotice( { ownerid, type, creator, object, link } ) {
        // stop the posting of a notice to yourself
        if(creator == ownerid) return;

        let newNotice = await Notice.Build();
        
        await newNotice.postObject({
            ownerid: ownerid,
            type: type,
            creator: creator,
            object: object,
            link: link,
        })
    }
}