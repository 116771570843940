<template>
	<div>
		<div id="testing">
			<download-csv :data="json_data">
				Download Data
			</download-csv>
            <v-btn v-if="json_data.length > 0" @click="download()">DOWNLOAD</v-btn>

            <!-- PORTABLE CREATE TEST -->
            <portable-edit-table-item 
                v-if="showPortableForm"
                :dialog="showPortableForm"
                :formtype="portableFormType"
                :fromApiObj="apiObject"
                @closeForm="() => { showPortableForm = false; }"
                @portableObjectCreated="handleNewPortableObject"
            />
            <v-btn @click="openPortable">OPEN PORTABLE CREATE</v-btn>

		</div>
	</div>
</template>

<script>
import ApiObject from "@/models/ApiObject";
import ApiObjectCollection from "@/models/ApiObjectCollection";
import NoticeCollection from "@/models/NoticeCollection";
import TicketingObject from "@/models/TicketingObject";
import { mapGetters } from "vuex";
import axios from "axios";
import ObjectFactory from "@/models/ObjectFactory";
import PortableEditTableItem from "@/components/Tables/EditTableItem/forms/PortableEditTableItem.vue";

export default {
    name: "people",
    components: {
        PortableEditTableItem,
    },
    data: () => ({
        notices: {},
        unread: 0,
        json_data: [],
        test:'HEJEJK',
        mall: null,

        
        showPortableForm: false,
        portableFormType: 'concerts',
        apiObject: null,
    }),
    computed: {
        ...mapGetters({
            userName: "user/userName",
            userId: "user/userId",
        }),
    },
    async created() {
        // let concert = await this.$ObjectFactory.BuildConcert({concertid: -1});
        // concert.budgets = null;
        // let concertCol = await this.$ObjectFactory.BuildCollection({ endpoint: 'concerts', fromDummy: concert });
        // await concertCol.fetchAllOneByOne({filterRoute: '?status=Confirmed&fromdate=1970-01-01'});
        // console.log(concertCol);
		// this.json_data = concertCol.getCsvData('FCST');
        // console.log(this.json_data)
    },
    methods: {
        download(){
            let blob = new Blob([this.json_data], {type: 'text/csv'});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'testing.csv';
            link.click();
        },
        async openPortable(){

            // Concert test
            // this.portableFormType = 'concerts';
            // this.apiObject = await this.$ObjectFactory.BuildObject({endpoint: 'concerts'})
            // this.apiObject['tourid'] = '5';
            // this.apiObject['title'] = 'Portable concert';
            // this.apiObject['date'] = '2021-01-22'
            // this.apiObject['date_end'] = '2021-01-22'

            // Org test
            this.portableFormType = 'organizations';
            this.apiObject = await this.$ObjectFactory.BuildObject({endpoint: 'organizations'})
            this.apiObject['org_number'] = '1337';
            this.apiObject['name'] = 'Portable org';


            console.log('apiObject to be sent in');
            console.log(this.apiObject);
            this.showPortableForm = true;
        },

        async handleNewPortableObject(newid, apiObj){
            // let value_key = this.portableOriginField.DROPDOWN_VALUE;
            // this.formItems[this.portableOriginField.COLUMN_NAME] = newid
            // console.log('formItems');
            // console.log(this.formItems);
            // await this.prepareForm(this.formItems, this.originalItems);
            console.log('new incoming portable object with id:', newid);
            console.log(apiObj);
        },
    }
};
</script>

<style scoped lang="scss">
#testing {
    width: 100%;
    padding: 20px;
    height: 100%;
}
</style>
