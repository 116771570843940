<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        :loading="isToursPending"
        class="elevation-0"
        @click:row="(item) => $emit('showSeasonDialog', item)"
        :class="{ 'full-width': items.length != 0 }"
    >
    </v-data-table>
</template>

<script>
import SeasonDialog from "./SeasonDialog.vue";

export default {
    name: "SalesTable",
    components: {
        SeasonDialog,
    },
    props: {
        tourSelected: Array,
        filteredTours: Array,
        isToursPending:Boolean
    },

    data: () => ({
        headers: [
            { text: "Name", value: "name" },
            {
                text: "Target show count",
                value: "estimate_number_gigs",
                align: "right",
            },
            {
                text: "Confirmed show count",
                value: "confirmed_show_count",
                align: "right",
            },
            {
                text: "% of target show count",
                value: "percent_target_show_count",
                align: "right",
            },
            {
                text: "Target fee per show",
                value: "estimate_wage_per_gig",
                align: "right",
            },
            {
                text: "Target total fee",
                value: "target_total_fee",
                align: "right",
            },
            {
                text: "Confirmed total fee",
                value: "confirmed_total_fee",
                align: "right",
            },
            {
                text: "% of target total fee",
                value: "percent_target_total_fee",
                align: "right",
            },
            {
                text: "Target commission",
                value: "target_commission",
                align: "right",
            },
            {
                text: "Confirmed total commission",
                value: "confirmed_total_commission",
                align: "right",
            },
            { text: "Agent", value: "our_agent.fullname", align: "right" },
        ],
        items: [],
        seasonDialog: false,
    }),

    watch: {
        tourSelected: {
            handler() {
                this.updateSalesTable();
            },
            deep: true,
        },
    },

    async created() {},
    methods: {
        showDialog(item) {
            this.seasonDialog = true;
        },

        updateSalesTable() {
            this.items = []; // clear items
            if (this.filteredTours.length > 0) {
                for (let tour of this.filteredTours) {
                    // loop selected tours
                    //remove NaN´s from sales table
                    if (isNaN(tour.estimate_number_gigs)) {
                        tour.estimate_number_gigs = "N/A";
                    }
                    if (isNaN(tour.estimate_wage_per_gig)) {
                        tour.estimate_wage_per_gig = "N/A";
                    }
                    if (isNaN(tour.target_total_fee)) {
                        tour.target_total_fee = "N/A";
                    }
                    if (isNaN(tour.target_commission)) {
                        tour.target_commission = "N/A";
                    }
                    if (isNaN(tour.confirmed_show_count)) {
                        tour.target_commission = "N/A";
                    }
                    if (isNaN(tour.percent_target_show_count)) {
                        tour.target_commission = "N/A";
                    }
                    if (isNaN(tour.percent_target_show_count)) {
                        tour.target_commission = "N/A";
                    }
                    
                    this.items.push(tour); // push into items for display
                }
            }
        },
    },

    watch: {
        filteredTours() {
            this.updateSalesTable();
        },
        //deep: true, 2021-01-27
    },
};
</script>

<style lang="scss">
tr {
    cursor: pointer;
}

.full-width {
    table {
        width: 100vw !important;
    }
}

