<script>
var dayjs = require('dayjs')

module.exports = {
    methods: {
        isDateRangeOverlapping(dateAStart, dateAEnd, dateBStart, dateBEnd) {
            // return boolean weather dateA and DateB overlaps
            return (
                (dateAStart <= dateBStart && dateBStart < dateAEnd) || // B starts in A
                (dateAStart <= dateBEnd && dateBEnd <= dateAEnd) || // B ends in A
                (dateBStart <= dateAStart && dateAEnd <= dateBEnd) // A in B
            );
        },

        getCurrentSeasonMonth() {
            //load with current date
            //this.dateSelected = dayjs(dayjs().year() + "-01-01T00:00:00.001Z");
            //quantize to 4months seasons
            month = parseInt(dayjs().format('M'))
            switch(true) {
                case (month < 5):
                    return 1;
                case (month < 9):
                    return 5;
                case (month < 13):
                    return 9;
            }
        }
    },
};
</script>