<template>
    <div>
        <overlay-card
            :show="showBudgetSaveOverlay"
            :text="budgetSaveOverlayText"
            :isLoading="budgetSaveOverlayLoading"
        />
        <v-col cols="12" md="12">
            <div class="float-left" style="width:100%">
                <tour-step-one
                    v-if="page == 1 || isLoading"
                    header="Tour revenues"
                    :stepData="dataInSteps.step1"
                />
                <tour-step-two
                    v-if="page == 2 || isLoading"
                    header="Tour gage"
                    :stepData="dataInSteps.step2"
                    :privateData="privateData"
                    :accessGroups="accessGroups"
                    :ticketCapacity="budgetData.ticketCapacity"
                />
                <tour-step-three
                    v-if="page == 3 || isLoading"
                    header="Hotel and Travel"
                    :stepData="dataInSteps.step3"
                />
                <tour-step-fixed-cost-step
                    :key="page"
                    v-if="page == 4 || isLoading"
                    header="Tour production"
                    :step="4"
                    :stepData="dataInSteps.step4"
                />
                <tour-step-fixed-cost-step
                    :key="page"
                    v-if="page == 5 || isLoading"
                    header="Tour marketing"
                    :step="5"
                    :stepData="dataInSteps.step5"
                />
                <tour-step-fixed-cost-step
                    :key="page"
                    v-if="page == 6 || isLoading"
                    header="Other"
                    :step="6"
                    :stepData="dataInSteps.step6"
                />
            </div>
        </v-col>
        <v-pagination class="mt-10" v-model="page" :length="pageAmount" />
    </div>
</template>

<script>
import OverlayCard from "@/components/Cards/OverlayCard";
import TourStepOne from "./Step1/TourStepOne";
import TourStepTwo from "./Step2/TourStepTwo";
import TourStepThree from "./Step3/TourStepThree";
import TourStepFixedCostStep from "./Shared/TourStepFixedCostStep";

export default {
    name: "tourbudgetwizard",
    components: {
        OverlayCard,
        TourStepOne,
        TourStepTwo,
        TourStepThree,
        TourStepFixedCostStep,
    },

    props: {
        budgetData: Object,
        dataInSteps: Object,
        savingAllowed: Boolean,
        calculationsDone: Boolean,
        userid: String,
    },

    data: () => ({
        isLoading: true,
        page: 1,
        pageAmount: 6,
        accessGroups: [],
    }),

    created() {
        for (let group of this.budgetData.dropdowns.accessgroups)
            this.accessGroups.push(group.accessgroup);
    },

    watch: {
        calculationsDone(v) {
            this.isLoading = !v;
            if (v) this.$emit("isLoaded");
        },
    },

    computed: {
        // private and accessgroup
        privateData() {
            return {
                isPrivate: this.budgetData.private_userid != "" ? true : false,
                accessGroup:
                    this.budgetData.private_accessgroup.accessgroup != undefined
                        ? this.budgetData.private_accessgroup.accessgroup
                        : this.budgetData.private_accessgroup,
            };
        },
    },

    methods: {
        toggleBudgetSaveOverlay(val) {
            if (val) {
                this.showBudgetSaveOverlay = val;
                this.budgetSaveOverlayLoading = val;
                this.budgetSaveOverlayText = "Saving budget...";
            } else {
                this.budgetSaveOverlayText = "Budget Saved!";
                this.budgetSaveOverlayLoading = false;
                // 1500 delay to show user it actually saved
                setTimeout(() => {
                    this.showBudgetSaveOverlay = false;
                }, 1500);
            }
        },
    },
};
</script>

<style>
</style>