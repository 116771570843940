<template>
   <v-container class="budget-container elevation-1 pa-0">
      <v-col cols="12" md="12">
         <div class="summary-header pa-0">Show Budget Summary</div>
      </v-col>
      <v-col cols="12" md="12" class="pa-5"> 
         <v-row >
            <v-col cols="3" md="3" class="pb-0"></v-col>
            <v-col cols="3" md="3" class="pb-0">
               <h3 class="summary-header text-right">Project to date</h3>
            </v-col>
            <v-col cols="3" md="3" class="pb-0">
               <h3 class="summary-header text-right">Budget</h3>
            </v-col>
            <v-col cols="3" md="3" class="pb-0">
               <h3 class="summary-header text-right">Difference</h3>
            </v-col>
         </v-row>
         <v-divider style="border-color:rgba(255, 255, 255, 0.151)"/>
         <v-progress-linear :active="!isSummaryLoaded" indeterminate/>
         <div v-if="isSummaryLoaded" :key="dataChanged">
            <v-row>
               <v-col cols="12" md="12" class="pt-0 pb-0">
                  <div class="pt-5">
                     <h3 class="summary-title">Revenues</h3>
                     <!-- REVENUES-START-->
                     <summary-row
                        title="Ticket Sales Inc VAT"
                        :values="ticketRevenues"
                        :currency="currency"
                        :items="ticketItems"
                     />
                     <summary-row
                        title="Other revenues Inc VAT"
                        :values="otherRevenues"
                        :currency="currency"
                        :items="otherRevenueItems"
                     />
                     <v-col class="px-0">
                        <summary-total-row
                           title="Total revenues Inc VAT"
                           :values="totalRevenues"
                           :currency="currency"
                        />
                     </v-col>
                     <!-- REVENUES-END -->
                  </div>
                  <!-- COSTS -->
                  <div class="pt-5">
                     <h3 class="summary-title">Expenses</h3>
                     <summary-row
                        title="Variable costs"
                        :values="totalVariableCosts"
                        :currency="currency"
                        :items="allVariableCosts"
                     />
                     <summary-row
                        title="Production costs"
                        :values="totalProductionCosts"
                        :currency="currency"
                        :items="allProductionCosts"
                     />
                     <summary-row
                        title="Marketing costs"
                        :values="totalMarketingCosts"
                        :currency="currency"
                        :items="allMarketingCosts"
                     />
                     <summary-row
                        title="Talent costs"
                        :values="totalTalentCosts"
                        :currency="currency"
                        :items="allTalentCosts"
                     />
                     <summary-row
                        title="General costs"
                        :values="totalGeneralCosts"
                        :currency="currency"
                        :items="allGeneralCosts"
                     /> 
                     <v-col class="px-0">
                        <summary-total-row
                              title="Total costs"
                              :values="totalCosts"
                              :currency="currency"
                           />
                        <!-- COSTS-END -->
                        <!-- PROFIT -->  
                           <summary-total-row
                              title="Net profit"
                              :values="netProfit"
                              :currency="currency"
                           />
                  </v-col>
                  </div>
               </v-col>
            </v-row>
         </div>
         <table v-show="false" width="400">
            <tr><th colspan="2" >Revenues</th></tr>
            <tr>
               <th colspan="2">Ticket sales</th>
            </tr>
            <tr>
               <th>Name</th>
               <th>Revenue ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of ticketItems" :key="item.id">
               <td>{{ item.name }}</td>
               <td>{{ item.value }}</td>
            </tr>
            <tr v-if="otherRevenueItems.length > 0">
               <th colspan="2">Other revenues</th>
            </tr>
            <tr v-if="otherRevenueItems.length > 0">
               <th>Name</th>
               <th>Revenue ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of otherRevenueItems" :key="item.id">
               <td v-if="otherRevenueItems.length > 0">{{ item.name }}</td>
               <td v-if="otherRevenueItems.length > 0">{{ item.value }}</td>
            </tr>
            <tr><th colspan="2">----------------</th></tr>
            <tr>
               <td>Total revenues</td>
               <td>{{ totalRevenues }}</td>
            </tr>

            <tr><th colspan="2">Expenses</th></tr>
            <tr>
               <th colspan="2">Variable costs</th>
            </tr>
            <tr>
               <th>Name</th>
               <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of variableCosts" :key="item.id">
               <td>{{ item.name }}</td>
               <td>{{ item.value }}</td>
            </tr>
            <tr>
               <th colspan="2">Production costs</th>
            </tr>
            <tr>
               <th>Name</th>
               <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of productionCosts" :key="item.id">
               <td>{{ item.name }}</td>
               <td>{{ item.value }}</td>
            </tr>
            <tr>
               <th colspan="2">Marketing costs</th>
            </tr>
            <tr>
               <th>Name</th>
               <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of marketingCosts" :key="item.id">
               <td>{{ item.name }}</td>
               <td>{{ item.value }}</td>
            </tr>
            <tr>
               <th colspan="2">General costs</th>
            </tr>
            <tr>
               <th>Name</th>
               <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of generalCosts" :key="item.id">
               <td>{{ item.name }}</td>
               <td>{{ item.value }}</td>
            </tr>
            <tr>
               <td>Total costs</td>
               <td>{{ totalCosts }}</td>
            </tr>

            <tr><th colspan="2">Artist deal</th></tr>
            <tr>
               <th colspan="2">Talent costs</th>
            </tr>
            <tr>
               <th>Name</th>
               <th>Cost ({{ this.currency }})</th>
            </tr>
            <tr v-for="item of talentCosts" :key="item.id">
               <td>{{ item.name }}</td>
               <td>{{ item.value }}</td>
            </tr>
            <tr>
               <td>Total talent costs</td>
               <td>{{ totalTalentCosts }}</td>
            </tr>
            <tr><td>Net profit</td><td>{{ netProfit }}</td></tr>
            <tr><td>Live nation profit after split</td><td>{{ splitNetProfit }}</td></tr>
         </table>
      </v-col>
   </v-container>
</template>

<script>
import { calcPrs } from '@/components/BudgetPage/ConcertBudgetWizard/Shared/PrsTypes.js'
import SummaryRow from './ConcertSummaryRow'
import SummaryTotalRow from './ConcertSummaryTotalRow'

export default {
   name: 'concertbudgetsummary',
   
   components: {
      SummaryRow,
      SummaryTotalRow,
   },

   props: {
      budgetData: Array,
      currentData: Array,
   },

   data: ()=>({
      dataChanged: 1,
      currency: '',
      isSummaryLoaded: false,
      budgetData: {},

      budgetSteps: [
         {tickets: [], otherRevenues: [], generalData: { coSplit: { active: 0, split: 0}}},
      ],

      variableCosts: [],
      productionCosts: [],
      marketingCosts: [],
      generalCosts: [],
      artistDeal: [],

      nameObj: {},
      
      currencyConvert: false,
      currencyName: '',
      currencyRate: '',
   }),

   created(){
      console.log('summary created')
      this.currency = this.$config.CURRENCY;
      this.initBudgetData(this.budgetData)
     // console.log('## currentData ##')
      //console.log(this.currentData)
   },

   watch: {

      'budgetData':{
         handler(val){
            //console.log('budgetdata watch in summary')
            //this.$bus.$emit('requestBudgetData')
            this.updateAllCosts()
            //this.dataChanged++
         },
         deep: true
      },
      currencyConvert(val){
         if(val)
            this.currency = this.$getCurrency(this.currencyName)
         else
            this.currency = this.$config.CURRENCY;

         this.updateAllCosts()
      },
   },

   computed: {
      totalTicketAmount(){
         let amount = 0
         this.budgetSteps[0].tickets.map(ticket => {
               amount += (ticket.capacity - ticket.kills - ticket.comps - ticket.other) * ticket.sellable_percentage
         })
         return amount
      },

      ticketNames(){
         let names = []

         this.budgetSteps[0].tickets.map(ticket => {
            names.push(ticket.section_name)
         })
         return names         
      },

      ticketItems(){
         let tickets = []
         let actualTickets = []
         let budgetTickets = []

         this.budgetSteps[0].tickets.map(ticket => {
            actualTickets.push({
               name: ticket.section_name, 
               value: this.getOneTicketRevenue(ticket)
            })
         })
         this.budgetSteps[0].tickets.map(ticket => {
            budgetTickets.push({
               name: ticket.section_name, 
               value: this.getOneTicketRevenue(ticket)
            })
         })

         for(let i = 0; i < budgetTickets.length; i++){
            tickets.push({
               name: budgetTickets[i].name,
               actualValue: actualTickets[i].value,
               budgetValue: budgetTickets[i].value,
               diffValue: actualTickets[i].value - budgetTickets[i].value
            })
         }

         return tickets
      },
      ticketRevenues(){
         let revenues = []

         let budgetRevenues = 0
         this.budgetSteps[0].tickets.map( ticket => budgetRevenues += this.getOneTicketRevenue(ticket))

         revenues.push(this.currentData.ticketRevenues)
         revenues.push(budgetRevenues)
         revenues.push(this.currentData.ticketRevenues - budgetRevenues)
         return revenues
      },

      otherRevenueItems(){
         let items = []
         let actualItems = []
         let budgetItems = []

         this.budgetSteps[0].otherRevenues.map(item => {
            actualItems.push({
               name: item.name,
               value: item.value
            })
         })

         this.budgetSteps[0].otherRevenues.map(item => {
            budgetItems.push({
               name: item.name,
               value: item.value
            })
         })

         for(let i = 0; i < budgetItems.length; i++){
            items.push({
               name: budgetItems[i].name,
               actualValue: actualItems[i].value,
               budgetValue: budgetItems[i].value,
               diffValue: actualItems[i].value - budgetItems[i].value
            })
         }

         return items
      },
      otherRevenues(){
         let revenues = []
         let actualRevenes = 0
         let budgetRevenues = 0
         
         this.otherRevenueItems.map(rev => budgetRevenues += rev.budgetValue)
         actualRevenes = budgetRevenues
         revenues.push(actualRevenes)
         revenues.push(budgetRevenues)
         revenues.push(actualRevenes - budgetRevenues)
         return revenues
      },

      totalRevenues(){
         let revenues = []
         for(let i = 0; i < 3; i++){
            revenues.push(+this.ticketRevenues[i] + +this.otherRevenues[i])
         }
         return revenues
      },

      allVariableCosts(){
         return this.createCostSummary(this.variableCosts)
      },

      totalVariableCosts(){
         if(this.budgetData){
            let arr = []
            let val = this.budgetData.variable_costs ? -this.budgetData.variable_costs : 0
            arr.push(val)
            arr.push(val) // budget
            arr.push(val - val)
            return arr
         }
      },

      allProductionCosts(){
         return this.createCostSummary(this.productionCosts)
      },

      totalProductionCosts(){
         let arr = []
         let val = this.budgetData.production_costs ? -this.budgetData.production_costs : 0
         arr.push(val)
         arr.push(val) // budget
         arr.push(val - val)
         return arr
      },

      allMarketingCosts(){
         return this.createCostSummary(this.marketingCosts)
      },

      totalMarketingCosts(){
         let arr = []
         arr.push(-this.currentData.marketingCosts)
         arr.push(-this.budgetData.marketing_costs)
         arr.push(-this.currentData.marketingCosts - -this.budgetData.marketing_costs)
         return arr
      },

      allGeneralCosts(){
         return this.createCostSummary(this.generalCosts)
      },

      totalGeneralCosts(){
         let arr = []
         let val = -this.budgetData.general_costs
         arr.push(val)
         arr.push(val) // budget
         arr.push(val - val)
         return arr
      },

      talentCosts(){
         return this.createCostSummary(this.artistDeal)
      },
      totalTalentCosts(){
         let arr = []
         let val = -this.budgetData.talent_costs
         arr.push(val)
         arr.push(val) // budget
         arr.push(val - val)
         return arr
      },

      totalCosts(){
         let costs = []
         for(let i = 0; i < 3; i++){
            costs.push(this.totalVariableCosts[i] + this.totalProductionCosts[i] + this.totalMarketingCosts[i] + this.totalTalentCosts[i] + this.totalGeneralCosts[i])
         }
         // if(costs.length > 0)
         //    costs[0] = -costs[0]
         return costs
      },


      netProfit(){
         let profit = []
         for(let i = 0; i < 3; i++){
            profit.push(this.totalRevenues[i] + this.totalCosts[i])
         }
         //profit[0] = -profit[0]
         return profit
      },
      splitNetProfit(){
         let split = this.budgetSteps[0].generalData.coSplit
         return split.active ? this.netProfit * (split.value / 100) : '' 
      },
   },

   methods: {

      createCostSummary(costs){
         let arr = []
         costs.map(cost => {
            arr.push({      
               name: cost.name,
               actualValue: cost.value,
               budgetValue: cost.value,
               diffValue: 0
            })
         })
         return arr
      },
      initBudgetData(data){

         console.log('summary data')
         console.log(data)

         if(data){
            // this.budgetSteps[0].generalData
             for(let i = 1; i < 6; i++)
                this.budgetSteps.push({costs: []})
    
             // tickets
             if(data.budget_tickets)
                data.budget_tickets.map(ticket => this.budgetSteps[0].tickets.push(ticket))
             
             // rows
             console.log(this.budgetSteps)
             if(data.budget_rows)
                data.budget_rows.map(row => {
                   row.step == 1 ? this.budgetSteps[row.step-1].otherRevenues.push(row) : this.budgetSteps[row.step-1].costs.push(row)
                })
    
             this.budgetSteps[0].generalData.coSplit.value = +data.co_produced_split
             +data.co_produced_split > 0 ? this.budgetSteps[0].generalData.coSplit.active = 1 : this.budgetSteps[0].generalData.coSplit.active = 0
    
             this.updateAllCosts()
    
             setTimeout(() => {
                this.isSummaryLoaded = true
             }, 2000);
         }


      },
      updateAllCosts(){
         //console.log('updateAllCosts')
        // console.log(this.budgetSteps)
         this.variableCosts = this.getCostArray(this.budgetSteps[1].costs)
         this.productionCosts = this.getCostArray(this.budgetSteps[2].costs)
         this.marketingCosts = this.getCostArray(this.budgetSteps[3].costs)
         this.generalCosts = this.getCostArray(this.budgetSteps[5].costs)

         // step five is special
         let arr = this.getCostArray(this.budgetSteps[4].costs)
         let bestDeal = arr[0]
         arr.map(c => bestDeal.value < c.value ? bestDeal = c : null)
         this.artistDeal = []
         this.artistDeal.push(bestDeal)
      },
      getOneTicketRevenue(ticket){
         //console.log(ticket)
         let rate = 1
         if(this.currencyRate > 0 && this.currencyConvert)
            rate = this.currencyRate
         return Math.round((ticket.capacity - ticket.kills - ticket.comps - ticket.other) * ticket.sellable_percentage * ticket.price / rate)
      },
      getVatMultiplier(){
         return 1 - this.budgetSteps[1].costs.find(c => c.name == 'vat').value / 100
      },
      getTotalAmount(items){
         console.log(items)
         let amount = 0
         items.map(i => amount += +i.value)
         return amount
      },
      getCostArray(costs){
         let costArr = []
            
         costs.map(cost => {
           
            costArr.push({
               name: cost.display_name, 
              // value: (this.currencyRate > 0 && this.currencyConvert) ? Math.round(cost.calculated_cost / this.currencyRate) : cost.calculated_cost
              value: 100000
            }) 
         })
         return costArr
      },

      setCurrency(cur){
         if(cur)
            cur = cur.toLowerCase()
            
         if(cur == 'dollar' || cur == '$')
            this.currency = '$'
         else if(cur == 'euro' || cur == '€')
             this.currency = '€'
         else if(cur == 'GBP' || cur == 'pound' || cur.includes('british') || cur == 'pund' || cur == '£')
            this.currency = '£'
         else if(cur == '')
            this.currency = ''
         else
            this.currency = cur
      },

      exportBudgetToExcel(filename){
            let table = document.querySelector('table');
            let tableHTML = '<html><head><meta%20http-equiv="Content-Type"%20content="text/html;%20charset=utf-8"></meta></head><body>'+ table.outerHTML.replace(/ /g, '%20') +'</body></html>';

            // Specify file name
            filename = `${filename}.xls`;
            
            // Create download link element
            let downloadLink = document.createElement("a");
            let dataType = 'application/vnd.ms-excel;';
            
            document.body.appendChild(downloadLink);
            
            if(navigator.msSaveOrOpenBlob){
               var blob = new Blob(['\ufeff', tableHTML], {
                  type: dataType
               });
               
               navigator.msSaveOrOpenBlob( blob, filename);
            }else{
               // Create a link to the file
               downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
            
               // Setting the file name
               downloadLink.download = filename;
               
               //triggering the function
               downloadLink.click();
            }
      },

      logdata(){
         console.log('#### All budget data ####')
         console.log(this.budgetData)
         console.log('#### variableCosts ####')
         console.log(this.variableCosts)
         console.log('#### artistDeal ####')
         console.log(this.artistDeal)
      }
   }
}
</script>

<style lang="scss">

.divider{
   margin-top: 10px;
   border-color:$accent-color!important;
}

.summary-header{
   font-size: 1rem;
   color: #fff;
   font-weight:400;
   padding:5px 0px;
}

.summary-title{
   font-size: 15px;
   color: $accent-color;
   margin-bottom: 10px;
}



.summary-text{
   font-size: 14px;
	font-weight:300;
	color:#3c444d;

   input[type="number"]{
      text-align:right
   }
   .number-text{
      input{
         text-align:right
      }
   }
}
.summary-total{
   font-size: 14px;
	font-weight:400;
	color:#3c444d;

   input[type="number"]{
      text-align:right
   }
   .number-text{
      input{
         text-align:right
      }
   }
}

</style>