<template>
    <div class="progressioncard elevation-1" style="position: relative">
        <v-progress-linear
            v-if="isLoading"
            style="position: absolute; width: 100%; height: 2px"
            indeterminate
            active
        />
        <div class="card-title">{{ title }}</div>
        <div class="pa-5">
            <div class="number">
                {{ $thousandSeperator(number) }}<span>{{ subtext }}</span>
            </div>
            <div class="progresstext">{{ progresstext }}</div>
            <v-progress-linear
                rounded
                :height="height"
                :value="
                    percentage > 100 && percentage < 201
                        ? percentage % 100
                        : percentage
                "
                :color="
                    !redOverLimit
                        ? this.percentage > 80
                            ? '#56b54a'
                            : 'primary'
                        : this.percentage > 100
                        ? '#b54a4a'
                        : 'primary'
                "
            ></v-progress-linear>
        </div>
    </div>
</template>

<script>
export default {
    name: "Progressioncard",
    props: {
        title: String,
        subtext: String,
        number: Number,
        progresstext: String,
        percentage: Number,
        isLoading: Boolean,
        redOverLimit: Boolean,
    },
    created() {
		console.log(this.progresstext)
        this.progresstext = this.progresstext
            .split("%")
            .map((item) =>
                item.trim() === "Infinity" || item.trim() === "NaN" ? "0%" : item
            ).join`%`;
    },
    data: () => ({
        height: 6,
    }),
};
</script>

<style lang="scss" scoped>
.progressioncard {
    position: relative;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    .number {
        font-size: 1.5rem;
        font-weight: 600;
        color: $font-color;
        margin: 5px 0px;
        span {
            font-size: 1rem;
            font-weight: 400;
            color: $font-color-pale;
            text-transform: uppercase;
            margin-left: 10px;
        }
    }
    .progresstext {
        float: right;
        font-weight: 400;
        min-height: 20px;
        width: 100%;
        color: $font-color-pale;
        margin-bottom: 5px;
        font-size: 13px;
        text-transform: uppercase;
        flex: 1;
        text-align: right;
    }
}
</style>
