<template>
    <div>
        <v-autocomplete
            v-model="selected"
            :items="allHeaders"
            outlined
            filled
            chips
            small-chips
            label="Selections"
            item-text="text"
            item-value="value"
            multiple
            dense
            @change="saveAndGetSelections()"
        >
            <template v-slot:selection="data">
                <v-chip
                    :close-icon="selected.length > 1 ? '$delete' : null"
                    :input-value="data.selected"
                    color="primary"
                    close
                    @click="data.select"
                    @click:close="removeSelection(data.item)"
                >
                    {{ data.item }}
                </v-chip>
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
export default {
    name: "selectiondropdown",
    props: {
        selectedHeaders: Array,
        allHeaders: Array,
        view: String,
        userselectionid: String,
    },
    data: () => ({
        selected: [],
    }),
    async created() {
        this.$bus.$on("filterSelectedHeaders", this.filterSelectedHeaders);
        this.filterSelectedHeaders();
        console.log(this.selectedHeaders)
    },
    methods: {
        async saveAndGetSelections() {
            let payload = this.selected.filter((item) => item !== "Actions");
            this.$emit("saveAndGetSelection", payload);
        },
        removeSelection(item) {
            let index = this.selected.indexOf(item);
            console.log(index)
            this.$delete(this.selected, index);
            this.saveAndGetSelections();
        },
        filterSelectedHeaders() {
            this.selectedHeaders.forEach((header) => {
                if (header.value !== "actions") {
                    this.selected.push(header.text);
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.v-input__slot {
    min-height: 56px !important;
}

.v-chip.v-size--default {
    font-size: 12px !important;
    height: 24px !important;
    &::v-deep .v-icon--right {
        margin-right: -9px !important;
    }
}
</style>
