import FormOptions from './FormOptions';
import { labelRules, generalFormLists } from './Shared/SharedObjects.js';
export default class TourOptions extends FormOptions{
    constructor(endpoint, otherTitle = ''){
        super(endpoint, otherTitle);
    }

    static async Build(endpoint, metaParam){
        let generalOpt = new TourOptions(endpoint, 'Linked Concerts');
        generalOpt = await FormOptions.BuildOptions(endpoint, metaParam, generalOpt);
        return generalOpt;
    }

    getFormList(param){
        var generalList = this.getGeneralFormList(param);
        if(generalList)
            return generalList;

        // if(generalFormLists[param])
        //     return generalFormLists[param];

    }
    showAutoComplete(field){
        const autoCompletes = ['concerts']
        let DATA_TYPE = field.COLUMN_NAME ? field.COLUMN_NAME : field.FK_TABLE;
        return this.showGeneralAutoComplete(DATA_TYPE, autoCompletes);
    }
    isSelect(COLUMN_NAME) {
        const selects = [''];
        return this.isGeneralSelect(COLUMN_NAME, selects); // || (custom concert COLUMN_NAMEs)
    }
    getRules(COLUMN_NAME) {
        return this.getGeneralRules(COLUMN_NAME);
    }

    getLabelRule(COLUMN_NAME){
        if(labelRules[COLUMN_NAME])
            return labelRules[COLUMN_NAME];
        
        return this.getGeneralLabelRules().default(COLUMN_NAME);
    }

    isDisabled(COLUMN_NAME, formItems) {
        return this.isGeneralDisabled(disabledObj, COLUMN_NAME, formItems);
    }

    getAutoTextAndValue(field, customKey){
        const autoTextVal = { 'concerts': { text: 'title', value: '' } }
        if(autoTextVal[field[customKey]])
            return autoTextVal[field[customKey]]
        else
            return this.getGeneralAutoTextAndValue(field)
    }
    
}
var disabledObj = [
    {
        key: '',
        value: [],
        disabledFields: []
    },
]
// var specialRulesEXAMPLE = [
//     {
//         fields: ['title', 'description'],
//         rule: v => (v || '').length <= 5 || 'Max 5 chars',
//     },
//     {
//         fields: ['tickets_hold'],
//         rule: v => v > 0 || 'Need to be > 0',
//     }
// ]