<template>
   <div class="card elevation-1 float-left pa-0" >
      <v-col cols="12" md="12" class="float-left pa-0">
         <div class="card-title">Sales pattern</div>
      </v-col>

      <v-col cols="12" md="12" class="float-left align-center">
         <echart-card
            style="height: 350px;"
            :options="options"
         />
      </v-col>
      
   </div>
</template>

<script>
import EchartCard from '@/components/Cards/ChartCards/EchartCard';


export default {
   name: 'salesgraph',
   components: {
      EchartCard,
   },
   props: {
      options: Array,
   },
}
</script>

<style>

</style>