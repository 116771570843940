import getCountryList from './Shared/CountryList';
import ObjectFactory from '@/models/ObjectFactory.js';

export default class FormOptions {
    constructor(endpoint, otherTitle = '') {
        this.endpoint = endpoint;
        this.requiredFields = [];
        this.otherTitle = otherTitle;
    }

    static async BuildOptions(endpoint, metaParam, fromOpt = null) {
        if (!fromOpt)
            fromOpt = new FormOptions(endpoint);

        const objFactory = new ObjectFactory();
        let apiObj = await objFactory.BuildObject({ endpoint: endpoint });

        let metaObj = await apiObj.getMetaFields(metaParam);
        for (let key in metaObj)
            if (metaObj[key].REQUIRED)
                fromOpt.requiredFields.push(metaObj[key].COLUMN_NAME)

        console.log(fromOpt);
        return fromOpt;
    }

    getGeneralFormList(param) {
        var generalFormLists = {
            country: getCountryList()
        };
        if (generalFormLists[param])
            return generalFormLists[param];
        return false;
    }

    showGeneralAutoComplete(DATA_TYPE, autoCompletes) {
        return DATA_TYPE == 'country' || autoCompletes.indexOf(DATA_TYPE) != -1;
    }
    isGeneralSelect(COLUMN_NAME, selects) {
        return COLUMN_NAME == 'country' || selects.indexOf(COLUMN_NAME) != -1;;
    }

    /**
     * Get the ruleset for a form item
     * @param {String} COLUMN_NAME name of a column with potential rules
     * @param {Array} specialRules see example below class. Is optional
     */
    getGeneralRules(COLUMN_NAME, specialRules = []) {
        let rules = [];

        if (this.requiredFields.indexOf(COLUMN_NAME) != -1)
            rules = requiredRules;

        for (let ruleObj of specialRules)
            if (ruleObj.fields.indexOf(COLUMN_NAME) != -1)
                rules.push(ruleObj.rule);
        
        return rules;
    }

    getGeneralTimeRules(COLUMN_NAME){
        let rules = this.getGeneralRules(COLUMN_NAME);
        rules.push(timeRules.rule);
        return rules;
    }

    getGeneralLabelRules() {
        return {
            default: (str) => str.replace(/_/g, " ").replace(/(^|\s)\S/g, l => l.toUpperCase())
        }
    }

    isGeneralDisabled(disabledObj, COLUMN_NAME, formItems) {
        for (let obj of disabledObj) {
            if (obj.disabledFields.indexOf(COLUMN_NAME) != -1) {
                for (let val of obj.value) {
                    if (formItems[obj.key] == val) {
                        for (let field of obj.disabledFields)
                            formItems[field] = null;
                        return true;
                    }
                }
                break;
            }
        }
        return false;
    }

    getGeneralAutoTextAndValue(field) {
        if ((field.DATA_TYPE == 'fk_object' || field.DATA_TYPE == 'multiselect') && field.items)
            return { text: Object.keys(field.items[0])[1], value: Object.keys(field.items[0])[0] };
        else
            return { text: '', value: '' };
    }

}

var timeRules = {
    fields: [],
    rule: (value) => {
        if(value == null)
            return true || "Invalid Timeformat(hh:mm)";
        const pattern = /([0-1]{1}[0-9]{1}|20|21|22|23):[0-5]{1}[0-9]{1}|^\s*$/;
        return pattern.test(value) || "Invalid Timeformat(hh:mm)";
    },
};


var requiredRules = [v => !!v || "This field is required"];
// var specialRulesEXAMPLE = [
//     {
//         fields: ['title', 'description'],
//         rule: v => (v || '').length <= 5 || 'Max 5 chars',
//     },
//     {
//         fields: ['tickets_hold'],
//         rule: v => v > 0 || 'Need to be > 0',
//     }
// ]