<template>
    <div class="fav-btn-wrapper">
        <v-btn
            @click.stop="addFavorite(item)"
            :loading="isFavoritePending"
            fab
            depressed
            x-small
            class="mr-1"
        >
            <v-icon class="material-icons" :class="isFavorite(item)"
                >favorite</v-icon
            >
        </v-btn>
    </div>
</template>

<script>
import { bus } from "@/main.js";
export default {
    name: "favorite",
    props: {
        item: Object,
        favorites: Array,
        isFavoriteTable: Boolean,
    },
    data: () => ({
        isFavoritePending: false,
    }),
    created() {},
    mounted() {
        this.$bus.$on("unSetLoading", this.unSetLoading);
        console.log(this.isFavoriteTable)
    },
    methods: {
        addFavorite(payload) {
            this.$emit("addFavorite", payload);
            this.setLoading();
        },
        setLoading() {
            this.isFavoritePending = true;
        },
        unSetLoading() {
            this.isFavoritePending = false;
        },
        isFavorite(item) { 
            if (this.isFavoriteTable) {
                return "active";
            }
            let returnClass = ''
            if (!this.favorites) return;
            this.favorites.forEach((fav) => {
                if (fav.itemid === item[item.pk_field] && fav.type === item.pk_field) {
                    returnClass = "active";
                    return;
                }
            });
            return returnClass
        },
    },
};
</script>

<style lang="scss" scoped>
.fav-btn-wrapper {
    float: left;
}

.v-icon {
    color: #666 !important;
}

.active {
    color: #f12832 !important;
}
</style>