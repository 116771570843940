<template>
    <v-dialog v-model="ticketApiDialog" max-width="720" persistent>
        <v-card>
            <v-card-title class="header">
                Ticket api match
                <v-col cols="12" md="12" class="pa-0 pt-5">
                    <v-text-field
                        placeholder="Search tickets"
                        required
                        outlined
                        dense
                        class="search-field"
                        prepend-inner-icon="search"
                        v-model="search"
						hide-details
                    />
                    <v-col cols="12" md="12" class="pa-0">
                        <Loader :loaderStyle="'linear'" v-if="apiDataIsPending" />
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-menu
                                    ref="menu1"
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="dates.startDate"
                                            label="Start Date"
                                            persistent-hint
                                            v-on="on"
                                            :filled="dark"
                                            clearable
                                            outlined
                                            :dark="dark"
                                            dense
											hide-details
											class="filterinputs"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dates.startDate"
                                        no-title
                                        @input="menu1 = false;filterByDate()"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="dates.endDate"
                                            label="End Date"
                                            persistent-hint
                                            clearable
                                            v-on="on"
                                            :filled="dark"
                                            outlined
                                            :dark="dark"
                                            dense
											hide-details
											class="filterinputs"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dates.endDate"
                                        no-title
                                        @input="menu2 = false;filterByDate()"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
							<v-col cols="12" md="6">
								<v-btn
									class="filter-btn"
									:class="{active: isDateFilterActive}"
									@click="isDateFilterActive = !isDateFilterActive"
									depressed
								>use date filter</v-btn>
							</v-col>
                            <v-col cols="12" md="3">
									<v-text-field
										label="Min Price"
										v-model="minPrice"
										outlined
										dense
										class="float-left filterinputs"
										hide-details
									></v-text-field>
                            </v-col>
                           <v-col cols="12" md="3">
								<v-text-field
									label="Max Price"
									v-model="maxPrice"
									outlined
									dense
									class="float-left filterinputs"
									hide-details
								></v-text-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-btn
									:class="{active: isPriceFilterActive}"
									@click="isPriceFilterActive = !isPriceFilterActive"
									depressed
									class="filter-btn"
								>use price filter</v-btn>
							</v-col>
                        </v-row>
                    </v-col>
                </v-col>
            </v-card-title>

            <v-list :flat="flat" :dense="dense">
				<div v-if="filteredApis.length === 0" class="no-data">No data</div>
                <v-list-item-group v-model="model" :mandatory="mandatory" color="primary">
					
                    <v-lazy
						v-for="(item, index) in filteredApis"
						:key="item.ticket_apiid"
						v-model="lazyIsActive[index]"
                        :options="{
							threshold: 0.5
						}"
                        min-height="25"
                        transition="fade-transition"
					>
                        <v-list-item
                            @click="chosenTicketApiId = item.ticket_apiid;chosenPrice = item.price;selected = item.ticket_apiid;apiSelected(item.ticket_apiid)"
                            :class="selected === item.ticket_apiid ? 'v-list-item--active': ''"
                        >
                            <v-list-item-content class="ticket-api-wrapper" v-if="originaldata">
                                <div class="d-flex align-center">
                                    <v-icon>mdi-antenna</v-icon>
                                    <div class="api-ticket-info">
                                        <span class="ticket-name">{{ item.name}}</span>
                                        <span class="ticket-date">{{ item.date}}</span>
                                        <span
                                            class="ticket-actual-name"
                                        >{{ item.price_type}} {{ item.price ? '-' : ''}} {{ item.price ? `${item.price} ${$config.CURRENCY}` : ''}}</span>
                                    </div>
                                    <div class="currently-selected-text">
                                        <span
                                            v-if="currentSelection === item.ticket_apiid || currentSelection === item.ticket_apiid"
                                        >Currently selected</span>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <span class="api-name" v-if="item.apiid">{{ item.apiid }}</span>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-lazy>
                </v-list-item-group>
            </v-list>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text small @click="closeTicketApiDialog">Close</v-btn>
                <v-btn
                    color="primary"
                    small
                    :disabled="noChangesMade"
                    :loading="isUpdateTicketApiIdPending"
                    @click="updateTicketApiId"
                    depressed
                >Apply</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Loader from "@/components/Loader";

export default {
    name: "ticketapidialog",
    components: {
        Loader,
    },
    props: {
        ticketApiDialog: Boolean,
        currentSelectedTicket: Object,
        currentSelectedConcert: Object,
        updateDone: Boolean,
    },

    data: () => ({
        items: [],
        minPrice: "",
        maxPrice: "",
        originaldata: [],
        menu1: false,
        menu2: false,
        search: "",
        dates: {
            startDate: "",
            endDate: "",
        },
        isPriceFilterActive: false,
        isDateFilterActive: false,
        chosenTicketApiId: "",
        chosenPrice: "",
        apiDataIsPending: true,
        currentSelection: "",
        selected: "",
        noChangesMade: true,
        isUpdateTicketApiIdPending: false,
        input: "",
        lazyIsActive: [],
    }),
    async created() {
        await this.getTicketApiData();
        this.apiDataIsPending = false;
        this.originaldata = JSON.parse(JSON.stringify(this.items));

        this.selected = this.currentSelectedTicket.ticket_apiid
            ? this.currentSelectedTicket.ticket_apiid.ticket_apiid
			: null;
        
        let i = 0;
        while (i++ < this.items.length) {
			this.lazyIsActive.push(i < 10 ? true : false);
		}

        this.currentSelection = this.selected;
        this.noChangesMade = true;
        this.getMaxAndMinPrice();
    },
    watch: {
        selected(v) {
            if (v === this.currentSelection) {
                this.noChangesMade = true;
            } else {
                this.noChangesMade = false;
            }
        },
        updateDone() {
            if (this.updateDone) {
                this.isUpdateTicketApiIdPending = false;
                this.closeTicketApiDialog();
            }
        },
        isPriceFilterActive() {
            if (this.isPriceFilterActive)
                this.items = this.items.filter(
                    (ticket) =>
                        +ticket.price > +this.minPrice &&
                        +ticket.price < +this.maxPrice
                );
            else {
				this.items = this.originaldata;
				if(this.isDateFilterActive)
					this.filterByDate()
			}
        },
        isDateFilterActive() {
            if (this.isDateFilterActive) this.filterByDate();
            else {
				this.items = this.originaldata;	
				if(this.isPriceFilterActive){
					this.items = this.items.filter(
                    (ticket) =>
                        +ticket.price > +this.minPrice &&
                        +ticket.price < +this.maxPrice
                	);
				}
			}
        },
        dates: {
            handler: function () {
                if (
                    this.dates.startDate === null ||
                    this.dates.endDate === null
                )
                    this.filterByDate();
                this.isDateFilterActive = true;
            },
            deep: true,
		},
		minPrice(){
			if(this.isPriceFilterActive)
                this.items = this.originaldata.filter(
                    (ticket) =>
                        +ticket.price > +this.minPrice &&
                        +ticket.price < +this.maxPrice
                );
		},
		maxPrice(){
			if(this.isPriceFilterActive)
                this.items = this.originaldata.filter(
                    (ticket) =>
                        +ticket.price > +this.minPrice &&
                        +ticket.price < +this.maxPrice
                );
		}
    },
    methods: {
        getMaxAndMinPrice() {
            const prices = this.items.map((v) =>
                !isNaN(+v.price) ? +v.price : 0
            );
            this.minPrice = Math.min(...prices);
            this.maxPrice = Math.max(...prices);
            // console.log(prices)
            // this.value = [minPrice, maxPrice]
            // this.selectedRange = [0, maxPrice]
        },
        filterByPrice() {
            this.isPriceFilterActive = !this.isPriceFilterActive;
        },
        apiSelected(name) {
            this.$emit("selectedApi", {
                name: name === "None" ? null : name,
                ticketid: this.currentTicketId,
                concertid: this.currentConcertId,
            });
        },
        filterByDate() {
            this.items = this.originaldata;
            let fromdate = this.dates.startDate;
            let todate = this.dates.endDate;
            let apiArray = this.items;

            //Convert dates from ISOStrings to date objects, set time to 12:00:00 to avoid daylight savings issues
            if (fromdate > "")
                fromdate = new Date(Date.parse(fromdate + " 12:00:00"));
            if (todate > "")
                todate = new Date(Date.parse(todate + " 12:00:00"));
            console.log("FILTER KÖRS");
            console.log(todate);
            var date = "";
            var returnArray = [];
            //Fill dateArray with existing dates
            for (let index = 0; index < apiArray.length; ++index) {
                date = new Date(Date.parse(apiArray[index].date + " 12:00:00"));
                if (fromdate > "" && todate > "") {
                    if (date >= fromdate && date <= todate) {
                        returnArray.push(apiArray[index]);
                    }
                } else if (fromdate > "") {
                    if (date >= fromdate) {
                        returnArray.push(apiArray[index]);
                    }
                } else if (todate > "") {
                    if (date <= todate) {
                        returnArray.push(apiArray[index]);
                    }
                } else {
                    returnArray.push(apiArray[index]);
                }
            }

            this.items = returnArray;
        },
        async getTicketApiData() {
            try {
                let response = await this.$GetService(
                    this.$getRoute("tickets").api
                );
                if (response.status === 200) {
                    this.items = response.data;
                    const selectedApi = this.items.find(api => {
                        return api.ticket_apiid === this.currentSelectedTicket.ticket_apiid?.ticket_apiid
                    })
                    if(selectedApi){
                        this.items.splice(this.items.indexOf(selectedApi), 1)
                        this.items.unshift(selectedApi)
                    }
                    this.items.unshift({
                        name: "None",
                        ticket_apiid: null,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        closeTicketApiDialog() {
            this.$emit("closeTicketApiDialog");
        },
        async updateTicketApiId() {
            this.isUpdateTicketApiIdPending = true;
            this.$emit("updateTicketApi", {
                ticket_apiid: this.chosenTicketApiId,
                ticketid: this.currentSelectedTicket.ticketid,
                price: this.chosenPrice,
            });
        },
    },
    computed: {
        filteredApis() {
            return this.items.filter((api) => {
                return api.name.toLowerCase().match(this.search.toLowerCase());
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    font-size: 15px !important;
    text-transform: uppercase;
    color: $accent-color;
    font-weight: 600 !important;
    border-bottom: 1px solid #cccccc;
    display: flex;
    padding-bottom: 20px !important;
}
.v-list {
    padding-top: 0px;
    max-height: 450px;
    min-height: 80px;
    padding-bottom: 0px;
    position: relative;
    border-radius: 0px;
    overflow: auto;
}

.active {
    background-color: #237ccfd3 !important;
	color:#fff!important;
}
.search-field {
    font-weight: 400;
    margin-bottom: 20px;
    &::v-deep .v-input__control {
        * {
            margin: 0;
        }
    }
}
.v-text-field.v-text-field--enclosed {
    &::v-deep .v-text-field__details {
        display: none !important;
    }
}

.filter-btn{
	height:100%!important;
	font-size:0.8rem;
}

.v-card__title {
    padding: 16px 16px !important;
}
.v-text-field {
    &::v-deep input {
        font-weight: 400;
    }
}
input {
    margin-left: 100px;
    border: 1px solid #ccc;
    flex: 1;
    border-radius: 3px;
    padding: 0px 10px;
    font-weight: 400 !important;
}
.v-list-item {
    border-bottom: 1px solid $border-color;
    &:first-child {
        border-top: none;
    }
    &:last-child {
        border-bottom: none;
    }
    .ticket-api-wrapper {
        font-size: 14px;
        min-height: 78px;
        .api-ticket-info {
            display: flex;
            margin-left: 20px;
            flex-direction: column;
            line-height: 1.3;
            max-width: 250px;
            .ticket-name {
                color: #222;
                flex: 1;
                font-weight: 500;
            }
            .ticket-date {
                color: #666;
                font-style: italic;
            }
            .ticket-actual-name {
                color: $accent-color;
            }
        }
        i {
            background: #237ccf24;
            color: #237bcf;
            font-size: 20px;
            padding: 7px;
            border-radius: 30px;
        }
    }
    .api-name {
        color: #e02020;
        border: 1px solid #e02020;
        background: #fff;
        padding: 6px 8px;
        font-size: 13px;
        border-radius: 3px;
        text-align: center;
        min-width: 120px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

.v-list-item-group .v-list-item--active {
    i {
        background: #237ccfd3;
        color: #fff;
    }
}
.v-card__actions {
    border-top: 1px solid #cccccc;
    box-shadow: 0px -3px 8px #00000030;
    position: relative;
    z-index: 1;
}
.currently-selected-text {
    color: #237bcf;
    font-size: 13px;
    text-transform: uppercase;
    margin-left: 100px;
    font-style: italic;
}

.v-card__title {
    box-shadow: 0px 3px 8px #00000030;
    position: relative;
    z-index: 1;
}

.no-data{
	text-align:center;
	margin-top:28px;
	color:$font-color;
}

.v-input.filterinputs{
	&::v-deep .v-input__slot{
		min-height:32px!important;
		.v-text-field__slot{
			font-size:0.9rem;
		}
		.v-label{
			font-size:0.9rem;
			top:5px;
		}
		.v-label--active{
			transform:translateY(-13px)scale(0.75)!important;
		}
		.v-input__append-inner{
			margin-top:4px!important;
		}
	}
}
</style>