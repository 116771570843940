<template>
    <div class="skeleton-wrapper">
        <div class="skeleton-field">
            <div style="float:left;width:100%;">
                <v-skeleton-loader
                    ref="skeleton"
                    :boilerplate="false"
                    :type="'heading'"
                    :tile="false"
                    style="margin-bottom:10px;width:200px;"
                ></v-skeleton-loader>
            </div>
            <v-row>
                <v-col cols="12" md="3" v-for="n in 4" :key="n">
                    <v-skeleton-loader
                        ref="skeleton"
                        :boilerplate="false"
                        :type="'text'"
                        :tile="false"
                        style="margin-bottom:10px;width:100%;float:left;"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <div  class="skeleton-field">
            <div style="float:left;width:100%;">
                <v-skeleton-loader
                    ref="skeleton"
                    :boilerplate="false"
                    :type="'heading'"
                    :tile="false"
                    style="margin-bottom:10px;width:200px;"
                ></v-skeleton-loader>
            </div>
            <v-row>
                <v-col cols="12" md="3" v-for="n in 3" :key="n">
                    <v-skeleton-loader
                        ref="skeleton"
                        :boilerplate="false"
                        :type="'text'"
                        :tile="false"
                        style="margin-bottom:10px;width:100%;float:left;"
                    ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="3" v-for="n in 4" :key="n">
                    <v-skeleton-loader
                        ref="skeleton"
                        :boilerplate="false"
                        :type="'text'"
                        :tile="false"
                        style="margin-bottom:10px;width:100%;float:left;"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <div  class="skeleton-field">
            <div style="float:left;width:100%;">
                <v-skeleton-loader
                    ref="skeleton"
                    :boilerplate="false"
                    :type="'heading'"
                    :tile="false"
                    style="margin-bottom:10px;width:200px;"
                ></v-skeleton-loader>
            </div>
            <v-row>
                <v-col cols="12" md="3" v-for="n in 3" :key="n">
                    <v-skeleton-loader
                        ref="skeleton"
                        :boilerplate="false"
                        :type="'text'"
                        :tile="false"
                        style="margin-bottom:10px;width:100%;float:left;"
                    ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="3" v-for="n in 4" :key="n">
                    <v-skeleton-loader
                        ref="skeleton"
                        :boilerplate="false"
                        :type="'text'"
                        :tile="false"
                        style="margin-bottom:10px;width:100%;float:left;"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <div class="skeleton-field">
            <div style="float:left;width:100%;">
                <v-skeleton-loader
                    ref="skeleton"
                    :boilerplate="false"
                    :type="'heading'"
                    :tile="false"
                    style="margin-bottom:10px;width:200px;"
                ></v-skeleton-loader>
            </div>
            <v-row>
                <v-col cols="12" md="3" v-for="n in 4" :key="n">
                    <v-skeleton-loader
                        ref="skeleton"
                        :boilerplate="false"
                        :type="'text'"
                        :tile="false"
                        style="margin-bottom:10px;width:100%;float:left;"
                    ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="3" v-for="n in 2" :key="n">
                    <v-skeleton-loader
                        ref="skeleton"
                        :boilerplate="false"
                        :type="'text'"
                        :tile="false"
                        style="margin-bottom:10px;width:100%;float:left;"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>