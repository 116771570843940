const state = {
   isDrawersDisabled: true,
   expandedHeader: true,
   companyColors: ['#4267b2', '#cf1d89', '#34a853', '#1da1f2', '#007bb6', '#fffc00', '#ffb7f3', '#ff3334', '#F8BBD0'],
   isMobile: false,
   view: ''
};

const getters = {
   isDrawersDisabled: state => state.isDrawersDisabled,
   expandedHeader: state => state.expandedHeader,
   companyColors: state => state.companyColors,
   isMobile: state => state.isMobile,
   view: state => state.view
};

const actions = {
   setDrawersDisabled({ commit }) {
      commit('setDrawersDisabled')
   },
   unSetDrawersDisabled({ commit }) {
      commit('unSetDrawersDisabled')
   },
   toggleExpandedHeader({ commit }) {
      commit('toggleExpandedHeader')
   },
   setIsMobile({ commit }, payload) {
      commit('setIsMobile', payload)
   },
   setExpandedHeader({ commit }) {
      commit('setExpandedHeader')
   },
   unsetExpandedHeader({ commit }) {
      commit('unsetExpandedHeader')
   }
};

const mutations = {
   setView: (state, payload) => state.view = payload,
   setDrawersDisabled: state => state.isDrawersDisabled = true,
   toggleExpandedHeader: state => state.expandedHeader = !state.expandedHeader,
   setExpandedHeader: state => state.expandedHeader = true,
   unsetExpandedHeader: state => state.expandedHeader = false,
   unSetDrawersDisabled: state => state.isDrawersDisabled = false,
   setIsMobile: (state, payload) => state.isMobile = payload
}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
};
