<template>
    <div style="width: 100%">
        <confirm-dialog
            v-if="isMissingPrerequisite"
            :options="dialogOptions"
            :dialog="isMissingPrerequisite"
            @confirmDialog="
                (response) => {
                    isMissingPrerequisite = false;
                    $router.go(-1);
                }
            "
        />
        <v-form
            ref="form"
            v-model.lazy="formIsValid"
            lazy-validation
            v-if="dataLoaded"
        >
            <div v-for="(fields, title) in metaData" :key="title">
                <v-row>
                    <v-col
                        v-if="title != '_other'"
                        cols="12"
                        md="12"
                        class="float-left"
                    >
                        <h1 class="header">{{ title }}</h1>
                    </v-col>
                </v-row>
                <v-row v-if="showFields">
                    <v-col
                        v-for="field of fields"
                        :key="field.COLUMN_NAME"
                        cols="12"
                        :md="field.DATA_TYPE == 'text' ? 12 : 3"
                    >
                        <general-form-field
                            :field="field"
                            :formItem="formItems[field.COLUMN_NAME]"
                            :fk_tables="field.DATA_TYPE == 'multiselect' ? fk_tables : null"
                            :isDisabled="options.isDisabled(field.COLUMN_NAME, formItems)"
                            @form_updated="(form_item) => formItems[field.COLUMN_NAME] = form_item"
                            @setFkTableAdd="(key, item) => addToFkTable(key, item)"
                            @setFkTableRemove="(key, item) => removeFromFkTable(key, item)"
                            @openCreateNewWindow="(field) => openCreateNewWindow(field)"
                        />
                    </v-col>
                </v-row>
            </div>
            <portable-edit-table-item 
                v-if="showPortableForm"
                :dialog="showPortableForm"
                :formtype="portableFormType"
                @closeForm="() => { showPortableForm = false; portableFormType = ''; }"
                @portableObjectCreated="handleNewPortableObject"
            />
        </v-form>
        
        
       
    </div>
</template>

<script>
import GeneralFormField from "./GeneralFormField"
import GeneralFormMixin from "./GeneralFormMixin.js"
import { mapGetters } from "vuex";
import ApiObject from "@/models/ApiObject";
import ObjectFactory from "@/models/ObjectFactory.js";
import Loader from "@/components/Loader";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import PortableEditTableItem from "./PortableEditTableItem"

export default {
    name: "generalform",
    mixins: [
        GeneralFormMixin
    ],
    components: {
        GeneralFormField,
        Loader,
        ConfirmDialog,
        PortableEditTableItem,
    },
    // props: {
    //     items: Object,
    //     objectRoute: String,
    //     metaParam: String,
    //     options: Object,
    //     dropdown_lists: Object,
    // },
    // data: () => ({
    //     dataLoaded: false,
    //     formItems: {},
    //     formIsValid: false,
    //     metaData: {},
    //     dateMenu: {},
    //     fk_table_selected_item: null,
    //     fk_tables: {},
    //     isMissingPrerequisite: false,
    //     dialogOptions: {},
    //     portableFormType: '',
    //     showPortableForm: false,
    //     portableOriginField: null,
    // }),
    // computed: {
    //     ...mapGetters({
    //         userId: "user/userId",
    //     }),
    // },
    // watch: {
    //     formItems: {
    //         handler: function () {
    //             if(this.dataLoaded){
    //                 if ((JSON.stringify(this.formItems) !== JSON.stringify(this.originalItems))) {
    //                     this.$emit("changesMade");
    //                 } else {
    //                     this.$emit("noChangesMade");
    //                 }
    //             }
    //         },
    //         deep: true,
    //     },
    // },
    async created() {
        console.log('GeneralForm Created');
        // this.dialogOptions = new ConfirmOptions({
        //     text: "Missing prerequisites",
        //     btnText: "OK",
        //     subText:
        //         "You need to create at least one organization/person > venue > artist > concert",
        // });
        // console.log('this.items');
        // console.log(this.items);
        // await this.prepareForm(this.items);
        // this.dataLoaded = true;
    },
    methods: {
        // async setFkTable(tableKey) {
        //     let selectedItem = this.fk_table_selected_item;
        //     const tableCol = this.fk_tables[tableKey];
        //     if (!this.fk_tables[tableKey].isInCollection(selectedItem)) 
        //         await this.fk_tables[tableKey].addToCollection(selectedItem);
        //     else{
        //         console.log('halskjdflakjsdfkljasdflkjhasdlfkhj')
        //         this.fk_table_selected_item = null;
        //     }
        //     console.log(this.fk_tables);
        //     this.fk_table_selected_item = selectedItem = null;
        // },
        // async prepareForm(itemsToPrepare, customOriginalItems = null) {
        //     this.formItems = itemsToPrepare;
        //     // fix fk_objects for form
        //     if (itemsToPrepare.fk_objects) {
        //         for (let obj of itemsToPrepare.fk_objects)
        //             if(itemsToPrepare[obj.table_fk_key]){
        //                 if(itemsToPrepare[obj.table_fk_key][obj.fk_key])
        //                     this.formItems[obj.table_fk_key] = itemsToPrepare[obj.table_fk_key][obj.fk_key];
        //             }
        //     }

        //     this.originalItems = JSON.parse(JSON.stringify(customOriginalItems == null ? this.formItems : customOriginalItems))

        //     let metaObj = await this.$ObjectFactory.BuildObject({
        //         endpoint: this.objectRoute,
        //     });
        //     metaObj = await metaObj.getMetaFields(this.metaParam);
        //     this.metaData = {};
        //     for (let item of metaObj) {
        //         var propkey =
        //             item.TITLE == ""
        //                 ? "_other"
        //                 : item.TITLE;
        //         if (!this.metaData[propkey])
        //             this.metaData[propkey] = [];

        //         if (item.DATA_TYPE == "date")
        //             this.dateMenu[item.COLUMN_NAME] = false;

        //         if (this.itemIsSelection(item.COLUMN_NAME))
        //             item.DATA_TYPE = item.COLUMN_NAME;

        //             // build lists and shit
        //         // autocomplete item
        //         if(this.showAutoComplete(item)){
        //             item['items'] = this.autoItems(item);
        //             console.log(item);
        //             if(item.DATA_TYPE == 'multiselect'){
        //                 this.fk_tables[item.FK_TABLE] = await this.setUpMultiSelect(item);
        //             } else
        //                 item['autocomplete'] = true;
        //             item['autoTextAndValue'] = this.autoTextAndValue(item);
        //             item['rules'] = this.options.getRules(item.COLUMN_NAME)
        //             item['isDisabled'] = this.options.isDisabled(item.COLUMN_NAME, this.formItems)
        //         }
        //         this.metaData[propkey].push(item);
        //         console.log('item done');
        //     }
        //     console.log('DONEZO');
        // },
        // async setUpMultiSelect(field){
        //     let apiCol = await this.$ObjectFactory.BuildCollection({
        //         endpoint: field.FK_TABLE,
        //         metaFields: [field.FK_NAME, field.FK_TABLE_PK_FIELD],
        //     });
        //     apiCol['FK_NAME'] = field.FK_NAME;
        //     return apiCol;
        // },
        // formatLabel(COLUMN_NAME) {
        //     return this.options.getLabelRule(COLUMN_NAME);
        // },
        // itemIsSelection(COLUMN_NAME) {
        //     return this.options.isSelect(COLUMN_NAME);
        // },
        // showAutoComplete(field) {
        //     return (
        //         field.DATA_TYPE == "fk_object" ||
        //         this.options.showAutoComplete(field)
        //     );
        // },
        // autoItems(field) {
        //     console.log('autoItems')
        //     if (field.DATA_TYPE == "fk_object" || field.DATA_TYPE == 'multiselect') {
        //         console.log(this.dropdown_lists[field.FK_TABLE]);
        //         return this.dropdown_lists[field.FK_TABLE]
        //         //return field.DROPDOWN;
        //     } else 
        //         return this.options.getFormList(field.COLUMN_NAME);
        // },
        // addCreateButton(field){
        //     if(field.DATA_TYPE == 'fk_object' && field.FK_TABLE != 'users')
        //         return true;
        // },
        // openCreateNewWindow(field){
        //     console.log('formItems');
        //     console.log(this.formItems);
        //     this.portableFormType = field.FK_TABLE;
        //     this.showPortableForm = true;
        //     this.portableOriginField = field;
        // },
        // async handleNewPortableObject(newid){
        //     this.dataLoaded = false;
        //     let value_key = this.portableOriginField.DROPDOWN_VALUE;
        //     this.formItems[this.portableOriginField.COLUMN_NAME] = newid
        //     console.log('formItems');
        //     console.log(this.formItems);
        //     await this.prepareForm(this.formItems, this.originalItems);
        // },
        // autoTextAndValue(field, customKey = "DATA_TYPE") {
        //     console.log(field);
        //     if (
        //         (field[customKey] == "fk_object" || field[customKey] == "multiselect") 
        //         &&
        //         (!this.dropdown_lists[field.FK_TABLE] || this.dropdown_lists[field.FK_TABLE].length === 0)
        //     )
        //         this.isMissingPrerequisite = true;

        //     return this.options.getAutoTextAndValue(field, customKey);
        // },
        // validate() {
        //     console.log('INNE')
        //     if (this.$refs.form.validate()) {
        //         if (this.items.fk_objects) {
        //             for (let obj of this.items.fk_objects)
        //                 if (this.formItems[obj.table_fk_key] == undefined)
        //                     this.formItems[obj.table_fk_key] = "";
        //         }
        //         this.originalItems = JSON.parse(JSON.stringify(this.formItems));
        //         return { valid: true, formItems: this.formItems, fk_tables: this.fk_tables };
        //     } else {
        //         this.$emit('requiredFieldsError')
        //         return { valid: false };
        //     }
        // },
        // getFormItems() {
        //     return this.formItems;
        // },
    },
};
</script>

<style lang="scss" scoped>
#linked-concerts-wrapper {
    float: left;
    margin-top: 15px;
    .linked-concert-wrapper {
        display: flex;
        .linked-concert {
            margin-bottom: 15px;
            border-radius: 30px;
            padding: 7px 7px 7px 20px;
            align-items: center;
            width: 100%;
            float: left;
            background: #f0f0f0;
            display: flex;
            justify-content: space-between;

            .concert-name {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    margin-bottom: 20px;
}
</style>