<template>
    <div>
        <ticketSalesDialog
            :currentSelectedConcert="[]"
            :ticketSalesDialog="ticketSalesDialog"
            :ticket="currentSelectedTicket"
            @closeTicketSalesDialog="ticketSalesDialog = false"
            @update-ticket-sale="updateTicketSales"
            @cancel="cancelSalesDialog"
            @saved="ticketSalesDialog = false"
        />
        <AddTicketDialog
            :addTicketDialog="addTicketDialog"
            :concert="currentSelectedConcert"
            @closeTicketDialog="addTicketDialog = false"
            @pushNewTicketsToArray="pushNewTicketsToArray"
        />
        <delete-dialog
            :name="ticketToBeRemoved ? ticketToBeRemoved.name : ''"
            :dialog="deleteTicketDialog"
            @deleteFromDialog="
                currentSelectedConcert.removeTicket(ticketToBeRemoved.ticketid);
                deleteTicketDialog = false;
            "
            @cancelDialog="deleteTicketDialog = false"
        />
        <ticketApiDialog
            v-if="ticketApiDialog"
            :ticketApiDialog="ticketApiDialog"
            :updateDone="updateApiDone"
            :currentSelectedTicket="currentSelectedTicket"
            :currentSelectedConcert="currentSelectedConcert"
            @closeTicketApiDialog="ticketApiDialog = false"
            @selectedApi="selectedApi"
            @updateTicketApi="updateTicketApi"
        />

        <v-data-table
            :headers="headers"
            :items="ticketingItems"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :search="search"
            item-key="concertid"
            show-expand
            class="pt-5 expandtable"
            :loading="!loadingDone && !noData"
            @click:row="
                (item, row) => {
                    row.expand(!row.isExpanded);
                    currentSelectedConcert = item;
                    setOriginalSelectedConcert();
                }
            "
        >
            <template v-slot:top>
                <v-col cols="12" md="4" class="pa-5">
                    <v-text-field v-model="search" label="Search" outlined hide-details dense :disabled="!loadingDone"></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="options-wrapper pt-0 pb-0">
                    <v-row>
                        <v-col cols="12" md="10" class="pa-5 d-flex filter-wrapper">
                            <div class="options-title">
                                <span>Filter options:</span>
                            </div>
                            <v-btn
                                depressed
                                small
                                :disabled="calculatingData || !loadingDone"
                                @click="
                                    activeFilters.onsale = !activeFilters.onsale;
                                    filter();
                                "
                                :class="{ active: activeFilters.onsale }"
                            >
                                <v-icon left small v-if="!activeFilters.onsale">mdi-checkbox-blank-circle-outline</v-icon>
                                <v-icon left small v-else>mdi-check-circle-outline</v-icon>on sale
                            </v-btn>
                            <v-btn
                                small
                                :disabled="calculatingData || !loadingDone"
                                depressed
                                @click="
                                    activeFilters.api = !activeFilters.api;
                                    filter();
                                "
                                :class="{ active: activeFilters.api }"
                            >
                                <v-icon left small v-if="!activeFilters.api">mdi-checkbox-blank-circle-outline</v-icon>
                                <v-icon left small v-else>mdi-check-circle-outline</v-icon>api connection
                            </v-btn>
                            <v-btn
                                small
                                :disabled="calculatingData || !loadingDone"
                                depressed
                                @click="
                                    activeFilters.event = !activeFilters.event;
                                    filter();
                                "
                                :class="{ active: activeFilters.event }"
                            >
                                <v-icon small v-if="!activeFilters.event">mdi-checkbox-blank-circle-outline</v-icon>
                                <v-icon small v-else>mdi-check-circle-outline</v-icon>event sales filter
                            </v-btn>
                            <v-autocomplete
                                outlined
                                v-model="department"
                                :rules="required"
                                :items="department_list"
                                dense
                                class="department-filter"
                                filled
                                label="Filter by department"
                                hide-details
                                clearable
                                @change="
                                    activeFilters.department = !activeFilters.department;
                                    filter();
                                "
                                :disabled="!loadingDone"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="2" class="pa-5 d-flex align-center">
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="exportTableToExcel(`${view}_data`, view)"
                                depressed
                                class="generate-report-btn"
                                color="info"
                                small
                                :block="isMobile"
                                :disabled="calculatingData || !loadingDone"
                            >
                                <v-icon left small>mdi-download</v-icon>Generate report
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template v-slot:item.data-table-expand="{ item, isExpanded }"> </template>
            <div slot="progress">
                <v-progress-linear color="primary" indeterminate></v-progress-linear>
            </div>
            <template v-slot:expanded-item="{ headers, item }">
                <td class="pa-0 inner-td" :colspan="headers.length">
                    <v-simple-table v-if="item.ticketObjects" dense>
                        <template v-slot:default>
                            <thead>
                                <tr v-if="item.ticketObjects.length > 0">
                                    <th class="text-center">Actions</th>
                                    <th>Ticket type</th>
                                    <th class="text-center">Platinum</th>
                                    <th class="text-right">Date -4 days</th>
                                    <th class="text-right">Date -3 days</th>
                                    <th class="text-right">Date -2 days</th>
                                    <th class="text-right">Date -1 day</th>
                                    <th class="text-right">Total sold</th>
                                    <th class="text-right">change</th>
                                    <th class="text-right">Gross sales</th>
                                    <th class="text-right">Unsold</th>
                                    <th class="text-right">Capacity</th>
                                    <th>Comment</th>
                                    <th class="text-center">Sold %</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="ticket in item.ticketObjects" :key="ticket.ticketid">
                                    <td colspan="1" style="text-align: center">
                                        <v-btn @click="openDeleteDialog(ticket, item)" icon class="mr-2" color="primary">
                                            <v-icon>remove_circle_outline</v-icon>
                                        </v-btn>
                                        <v-btn
                                            :class="{
                                                'api-connected': checkIfApi(ticket),
                                            }"
                                            @click="openTicketApiDialog(ticket, item)"
                                            icon
                                            class="mr-2"
                                            :color="!checkIfApi(ticket) ? 'primary' : ''"
                                        >
                                            <v-icon>mdi-antenna</v-icon>
                                        </v-btn>
                                        <v-btn @click="openTicketSalesDialog(ticket)" icon color="primary">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </td>
                                    <td colspan="1">
                                        <input v-model="ticket.name" class="ticket-name-input" />
                                    </td>
                                    <td style="text-align: center">
                                        <v-checkbox true-value="1" class="ml-4" false-value="0" v-model="ticket.platinum" color="primary"
                                            >check</v-checkbox
                                        >
                                    </td>
                                    <td colspan="1" style="text-align: right">
                                        {{ ticket.getSoldSum(4) }}
                                    </td>
                                    <td colspan="1" style="text-align: right">
                                        {{ ticket.getSoldSum(3) }}
                                    </td>
                                    <td colspan="1" style="text-align: right">
                                        {{ ticket.getSoldSum(2) }}
                                    </td>
                                    <td colspan="1" style="text-align: right">
                                        {{ ticket.getSoldSum(1) }}
                                    </td>
                                    <td colspan="1" style="text-align: right">
                                        <input
                                            type="number"
                                            class="ticket-sold-input"
                                            :class="checkIfApi(ticket) ? 'disabled-input' : ''"
                                            v-model="ticket.total_sold"
                                            @change="updateTotalTicketSales(ticket)"
                                            :disabled="checkIfApi(ticket) || !item.isOnSale(1)"
                                        />
                                    </td>
                                    <td colspan="1" style="text-align: right">
                                        {{ ticket.getChange() }}
                                    </td>
                                    <td colspan="1" style="text-align: right">
                                        {{ ticket.getGrossSales() }}
                                    </td>
                                    <td colspan="1" style="text-align: right">
                                        {{ ticket.available }}
                                    </td>
                                    <td colspan="1" style="text-align: right">
                                        {{ ticket.capacity }}
                                    </td>
                                    <td colspan="1" style="text-align: center">
                                        <comment-button
                                            :name="ticket.name"
                                            :route="'tickets'"
                                            :data="{
                                                comment: ticket.comment,
                                                ticketid: ticket.ticketid,
                                            }"
                                            @setComment="(comment) => (ticket.comment = comment)"
                                        />
                                    </td>
                                    <td>
                                        <v-progress-circular
                                            rotate="-90"
                                            size="40"
                                            :value="ticket.getSoldPercentage()"
                                            :color="getProgressColor(ticket.getSoldPercentage())"
                                        >
                                            <span class="progress-text">{{ ticket.getSoldPercentage() }}%</span>
                                        </v-progress-circular>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div class="button-wrapper pa-3">
                        <v-spacer></v-spacer>
                        <span class="ticket-alert d-flex align-center" v-if="!item.on_sale || !item.date">
                            Cannot add ticket, concert is missing ON SALE DATE and/or CONCERT DATE
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="openAddTicketDialog(item)"
                            :disabled="!(item.on_sale && item.date)"
                            depressed
                            class="mr-4"
                            color="primary"
                            small
                            >add ticket type</v-btn
                        >
                        <v-btn
                            @click="saveChanges(item.ticketObjects)"
                            depressed
                            color="primary"
                            small
                            :disabled="!changesMade"
                            :loading="isSaveChangesPending"
                            >save changes</v-btn
                        >
                    </div>
                </td>
            </template>
            <template v-slot:item.sold="{ item }">
                <v-progress-circular
                    rotate="-90"
                    size="40"
                    :value="item.getTotalSoldPercentage()"
                    :color="getProgressColor(item.getTotalSoldPercentage())"
                >
                    <span class="progress-text">{{ item.getTotalSoldPercentage() }}%</span>
                </v-progress-circular>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import dayjs from "dayjs";
import CommentButton from "@/components/Dialogs/Comment/CommentButton";
import { mapGetters } from "vuex";
import { exportTableToExcel } from "@/plugins/createExcellFile.js";
import TicketSalesDialog from "./TicketSalesDialog";
import TicketApiDialog from "./TicketApiDialog";
import DeleteDialog from "@/components/Dialogs/DeleteDialog";
import AddTicketDialog from "./AddTicketDialog";
import Loader from "@/components/Loader";
export default {
    name: "ticketingtable",
    components: {
        TicketSalesDialog,
        CommentButton,
        DeleteDialog,
        TicketApiDialog,
        AddTicketDialog,
        Loader,
    },
    data() {
        return {
            expanded: [],
            noData: false,
            view: "ticketing",
            search: "",
            department: "",
            salesArray: [],
            ticketingItems: [],
            originalData: [],
            singleExpand: true,
            updateApiDone: false,
            loadingDone: false,
            ticketSalesDialog: false,
            deleteTicketDialog: false,
            ticketApiDialog: false,
            addTicketDialog: false,
            ticketLengths: 0,
            activeFilters: {
                onsale: true,
                api: false,
                event: false,
                department: false,
            },
            department_list: ["Swedish", "Concert", "Luger", "Luger Promoter"],
            headers: [
                { text: "Name", align: "left", sortable: true, value: "title" },
                {
                    text: "On sale",
                    align: "left",
                    sortable: true,
                    value: "on_sale",
                },
                { text: "Date", align: "left", sortable: true, value: "date" },
                { text: "Day", value: "day" },
                { text: "Artist", value: "artistid" },
                { text: "City", value: "town" },
                { text: "Venue", value: "venueid" },
                {
                    text: "Yesterday",
                    value: "total_sold_yesterday",
                    align: "right",
                },
                { text: "Total sold", value: "total_sold", align: "right" },
                { text: "Gross income", value: "gross_sales", align: "right" },
                { text: "Change since last day", value: "change", align: "right" },
                { text: "Unsold", value: "available", align: "right" },
                { text: "Capacity", value: "capacity", align: "right" },
                { text: "Sold(%)", value: "sold", align: "center" },
            ],
            tempSold: 0,
            originalSelectedTicket: null,
            currentSelectedTicket: null,
            currentSelectedConcert: null,
            ticketToBeRemoved: null,
            originalSelectedConcert: null,
            changesMade: false,
            isSaveChangesPending: false,
        };
    },
    watch: {
        currentSelectedConcert: {
            handler(v) {
                const tempTickets = v.getDeepCopy();

                for (let ticket of tempTickets.ticketObjects) delete ticket["comment"];

                if (JSON.stringify(tempTickets.ticketObjects) != JSON.stringify(this.originalSelectedConcert.ticketObjects)) this.changesMade = true;
                else this.changesMade = false;
                console.log(this.changesMade);
            },
            deep: true,
        },
        changesMade(v) {
            console.log(v);
        },
    },

    async created() {
        let metaFields = null;
        const concerts = await this.getData("concerts?inc=assoc&exclude_fields=marketing,wall_events");
        if (!concerts) {
            this.noData = true;
            return;
        }
        let tempArr = [];
        let i = 0;
        for (let concert of concerts) {
            var newTicketing = await this.$ObjectFactory.BuildTicketing({
                concert: concert,
                metaFields: metaFields,
            });
            tempArr.push(newTicketing);
            if (metaFields == null) metaFields = newTicketing.metaFields;
        }
        this.ticketingItems = tempArr;
        this.originalData = tempArr;
        this.filter();
        this.loadingDone = true;
        console.log(this.ticketingItems);
    },
    methods: {
        exportTableToExcel,
        async getData(route) {
            try {
                const response = await this.$GetService(route);
                if (response.status === 200) return response.data;
            } catch (err) {
                console.log(err);
            }
        },
        checkIfApi(ticket) {
            if (!ticket.ticket_apiid || !ticket.ticket_apiid.ticket_apiid) return false;

            return true;
        },
        async updateTicketApi(obj) {
            if (await this.currentSelectedTicket.updateObject(obj)) {
                this.currentSelectedTicket.ticket_apiid = {
                    name: obj.name,
                    ticket_apiid: obj.ticket_apiid,
                };
                this.ticketApiDialog = false;
            }
        },
        getProgressColor(percentage) {
            return +percentage >= 100 ? "success" : "primary";
        },
        openDeleteDialog(ticket, concert) {
            this.ticketToBeRemoved = ticket;
            this.currentSelectedConcert = concert;
            this.deleteTicketDialog = true;
        },
        setOriginalSelectedConcert() {
            console.log("SET ORIGINAL");
            this.originalSelectedConcert = this.currentSelectedConcert.getDeepCopy();

            // we do not want to track comment changes
            for (let ticket of this.originalSelectedConcert.ticketObjects) delete ticket["comment"];
        },
        async saveChanges(tickets) {
            console.log(tickets);
            /**
             * The ticket_apiid in tickets param is an object.
             * We only need the id.
             */
            if (tickets.length > 0 && tickets[0].ticket_apiid && typeof tickets[0].ticket_apiid == "object") {
                const { ticket_apiid } = tickets[0].ticket_apiid;
                tickets[0].ticket_apiid = ticket_apiid;
            }

            this.isSaveChangesPending = true;
            for (let ticket of tickets) {
                console.log(ticket);
                await ticket.updateTicketSales();
                await ticket.updateObject(ticket);
            }
            this.changesMade = false;
            this.setOriginalSelectedConcert();
            this.isSaveChangesPending = false;
        },
        updateTicketSales(ticket) {
            this.currentSelectedTicket.ticket_sales = JSON.parse(JSON.stringify(ticket.ticket_sales));
            this.currentSelectedTicket.setSumSales();
            this.currentSelectedConcert.setTotalSumSales();
        },
        updateTotalTicketSales(ticket) {
            console.log(ticket);

            // old way, the last sale date is always updated
            //ticket.ticket_sales[0].amount = +ticket.ticket_sales[0].amount + +ticket.total_sold - +ticket.getSoldSum();
            let now = dayjs()
                .subtract(1, "day")
                .toISOString()
                .substr(0, 10);
            console.log(now);
            if (ticket.ticket_sales_with_date_keys[now]) {
                let index = ticket.ticket_sales_with_date_keys[now].index;
                ticket.ticket_sales[index].amount = +ticket.ticket_sales[index].amount + +ticket.total_sold - +ticket.getSoldSum();
                ticket.setSumSales();
            } else console.log("Try again tomorrow!");
        },
        openTicketApiDialog(ticket) {
            this.currentSelectedTicket = ticket;
            this.ticketApiDialog = true;
        },
        openTicketSalesDialog(ticket) {
            console.log(ticket)
            this.currentSelectedTicket = ticket;
            this.originalSelectedTicket = JSON.parse(JSON.stringify(ticket));
            this.ticketSalesDialog = true;
        },
        openAddTicketDialog(concert) {
            this.currentSelectedConcert = concert;
            this.addTicketDialog = true;
        },
        cancelSalesDialog() {
            console.log(this.originalSelectedTicket);
            this.currentSelectedTicket.ticket_sales = this.originalSelectedTicket.ticket_sales;
            this.currentSelectedTicket.setSumSales();
            this.ticketSalesDialog = false;
            console.log(this.currentSelectedTicket);
        },
        filterDepartment() {
            this.ticketingItems = this.ticketingItems.filter((concert) => concert.department === this.department);
        },
        filterOnSale() {
            this.ticketingItems = this.ticketingItems.filter(
                (ticket) => new Date(ticket.on_sale) <= new Date() && new Date(ticket.date) >= new Date() && ticket.on_sale && ticket.date
            );
        },
        filterApiConnection() {
            this.ticketingItems = this.ticketingItems.filter((concert) => concert.ticketObjects.some((ticket) => ticket.ticket_apiid > ""));
        },
        filterEvent() {
            let yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substr(0, 10);
            let concertIds = [];
            this.ticketingItems.map((concert) => {
                var showflag = false;
                let index = 0;
                let show = [];
                if (concert.ticketObjects === undefined) {
                } else {
                    concert.ticketObjects.map((ticket) => {
                        if (ticket.ticket_sales === undefined) {
                            showflag = true;
                        } else {
                            show.push(true);
                            ticket.ticket_sales.map((sale) => {
                                if (sale.date == yesterday) {
                                    show[index] = false;
                                }
                            });
                            index++;
                            show.map((show) => {
                                if (show === true) {
                                    showflag = true;
                                }
                            });
                        }
                    });
                    if (showflag === true) concertIds.push(concert.concertid);
                }
            });
            this.ticketingItems = this.ticketingItems.filter((concert) => concertIds.includes(concert.concertid));
        },
        filter() {
            this.ticketingItems = this.originalData;
            if (this.activeFilters.onsale) this.filterOnSale();
            if (this.activeFilters.api) this.filterApiConnection();
            if (this.activeFilters.department) this.filterDepartment();
            if (this.activeFilters.event) this.filterEvent();
            console.log(this.ticketingItems);
        },
        filterReset() {
            this.ticketingItems = this.originalData;
        },
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
.text-left {
    max-width: 200px;
}

.expandtable {
    &::v-deep .v-data-table__wrapper {
        .v-data-table__progress {
            th {
                padding: 0px !important;
            }
        }
    }
}

.mobile {
    .filter-wrapper {
        display: block !important;
        .options-title {
            margin-bottom: 15px;
        }
        .v-btn,
        .v-input {
            width: 100%;
            max-width: 100% !important;
            float: left;
            margin: 0px 0px 15px 0px !important;
        }
    }
}

.v-data-table {
    border-radius: 0px;
    box-shadow: none !important;
    &::v-deep .v-data-table__wrapper {
        tr {
            cursor: pointer;
            td {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 175px;
                font-size: 0.8rem !important;
            }
        }
        tr.v-data-table__selected {
            background: #fff !important;
        }
        tr.v-data-table__selected:hover {
            background: #dbedfc !important;
        }
    }
    .v-data-table__expanded {
        box-shadow: none !important;
        thead tr:hover {
            background: #f0f7fd !important;
        }
        tr {
            cursor: default !important;
        }
    }
}

.isExpanded {
    transition: all 0.2s ease;
    transform: rotate(-180deg);
    background: #e1f1ff;
}

.button-wrapper {
    width: 100%;
    float: left;
    display: flex;
    border-top: 1px solid $border-color;
    background: #f0f7fd;
    border-bottom: 1px solid $border-color !important;
}

.ticket-name-input,
.ticket-sold-input {
    background: #fff;
    border: 1px solid $border-color;
    border-radius: 2px;
    padding: 3px 7px;
    transition: all 0.2s ease;
    &:focus {
        outline: none !important;
        border: 1px solid $accent-color !important;
    }
}

.ticket-sold-input {
    max-width: 80px;
}

.disabled-input {
    background: #f7f7f7;
}

.api-connected {
    background: $accent-color;
    color: #fff !important;
}

.expandtable {
    .options-wrapper {
        border-bottom: 1px solid $border-color;
        border-top: 1px solid $border-color;
        .filter-wrapper {
            align-items: center;
            .options-title {
                display: flex;
                margin-right: 20px;
                text-transform: uppercase;
                font-size: 0.75rem;
                font-weight: 500;
                span {
                    margin: auto;
                }
            }
            .active {
                background-color: $accent-color !important;
                color: #fff !important;
            }
            button {
                &:not(.v-icon) {
                    padding-left: 15px;
                    margin-right: 20px;
                    padding-right: 15px;
                    i {
                        margin-right: 7px;
                        font-size: 20px;
                    }
                }
            }
            .department-filter {
                max-width: 400px;
                &::v-deep .v-input__slot {
                    margin: 0px;
                }
                &::v-deep .v-input__control {
                    margin: 0px;
                    .v-text-field__details {
                        display: none;
                    }
                }
            }
        }
        .generate-report-btn {
            float: right;
            margin-right: 0px;
            padding-left: 15px !important;
        }
    }
    tr {
        &:hover {
            background: rgba(0, 0, 0, 0.027) !important;
        }
    }

    table {
        .v-data-table {
            background: #f0f7fd;
            td,
            th {
                height: 35px !important;
            }
        }
    }

    .v-data-table__progress {
        th {
            padding: 0 !important;
        }
    }

    .inner-td {
        border-bottom: none !important;
    }

    tr.v-data-table__expanded.v-data-table__selected {
        background: #dbedfc !important;
    }
}
</style>
