

import { unemojify } from 'node-emoji'
import { PostService, GetService } from '@/api/apiServices.js'


export async function fetchDropdownList(meta) {
   try {
      let response = await GetService(`${meta.FK_TABLE}?fields=${meta.DROPDOWN_VALUE},${meta.DROPDOWN_LABEL},${meta.FK_NAME ? meta.FK_NAME : ''}&objfetch=obj`);
      if (response.status == 200)
         return response.data;
   } catch (error) {
      console.log(error);
   }
   return [];
}

export function emptyObject(obj) {
   for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
         obj[key] = ''
      }
   }
}

export function thousandSeperator(n) {
   if (n != undefined && !isNaN(n)) {
      var parts = n.toString().split(".")
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      return parts.join(".")
   }
   return 0;
}

export function getCurrency(name) {
   let currency = null;
   if (name) {
      name = name.toLowerCase()
      if (name == 'dollar' || name == '$' || name == 'usd')
         currency = '$'
      else if (name == 'euro' || name == '€')
         currency = '€'
      else if (name == 'gbp' || name == 'pound' || name.includes('british') || name == 'pund' || name == '£')
         currency = '£'
      else if (name == '')
         currency = ''
      else
         currency = name
      return currency
   }
   return ''
}
export function logger(str, obj = '') {
   str = `#### ${str} ####`;
   console.log(str);
   if (obj != '')
      console.log(obj);
   let endStr = '';
   let i = 0;
   while (i++ < str.split('').length)
      endStr += '#'
   console.log(endStr);
}

export function formatHeader(header) {
   return header.replace(/_/g, ' ').replace(/(^|\s)\S/g, l => l.toUpperCase()) // Format header received from object (Capitalize and remove underscore)
}

export function round(value, decimals) {
   var multiplier = Math.pow(10, decimals || 0);
   return Math.round(value * multiplier) / multiplier;
}
export async function wallHandler({ text = null, walltype = null, item = null, route = null, eventtype = null, id = null }) {

   const createTitle = (walltype, item) => {
      switch (walltype) {
         case 'comment':
            return 'added a comment'
         case 'checklist_task_done':
            return `marked ${item} as done`
         case 'checklist_task_not_done':
            return `unmarked ${item}`
         case 'budget_approved':
            return 'marked budget as approved'
         case 'budget_sent_for_approval':
            return 'sent budget for approval'
         case 'concert_status_hold':
            return 'changed status to hold'
         case 'concert_status_offer':
            return 'changed status to offer'
         case 'concert_status_confirmed':
            return 'changed status to confirmed'
         case 'concert_status_unavailable':
            return 'changed status to unavailable'
         case 'concert_status_declined':
            return 'changed status to declined'
         case 'concert_status_routing':
            return 'changed status to routing'
         case 'concert_status_repetition':
            return 'changed status to repetition'
         case 'changed_start_date':
            return `changed start date to ${item}`
         case 'changed_venue':
            return `changed venue to ${item}`
         // Generic status change
         case 'changed_status':
            return `changed status to ${item}`;
         default:
            return
      }
   }

   const findConcertId = (route) => {
      if (!route) return

      if (route.path.includes('concerts'))
         return route.params.id

      return null
   }
   const findTourId = (route) => {
      if (!route) return

      if (route.path.includes('tours'))
         return route.params.id

      return null
   }

   let postObj = {
      title: createTitle(walltype, item),
      text: text !== '' ? unemojify(text) : '',
      wall_type: walltype,
      concertid: eventtype === 'concert' ? id : findConcertId(route),
      tourid: eventtype === 'tour' ? id : findTourId(route)
   }
   try {
      let response = await PostService('wall_events', postObj)
      return response

   } catch (error) {
      console.log(error)
   }

}