<template>
    <v-dialog v-model="filterDialog" persistent max-width="880">
        <v-card>
            <v-alert type="error" v-model="noDataFoundAlert" v-if="noDataFoundAlert" class="no-data-found" dismissible>
                No data matched the conditions.
            </v-alert>
            <v-card-title class="header">Handle Filters</v-card-title>
            <v-container fluid class="pa-0 float-left" style="overflow: hidden">
                <div class="build-filter-header" style="">Build filter</div>
                <div class="built-filter-wrapper">
                    <v-form ref="form" v-model="validForm" lazy-validation>
                        <v-col cols="12" md="12" class="edited-filter-name pa-5">
                            <v-text-field
                                :rules="nameIsNeeded ? defaultRules : ''"
                                v-model="filterName"
                                filled
                                outlined
                                dense
                                hide-details
                                label="Filter name"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" v-for="(template, index) of filterChains" :key="index" class="py-0 float-left">
                            <v-row>
                                <v-col cols="1" md="1" class="d-flex" v-if="!isMobile">
                                    <div class="row-indicator">
                                        <span style="margin: auto">{{ index + 1 }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" :md="isMobile ? '12' : '11'" :class="isMobile ? 'px-6 py-2' : 'py-0'">
                                    <v-col cols="12" md="12" class="d-flex" v-if="isMobile">
                                        <div class="row-indicator">
                                            <span style="margin: auto">{{ index + 1 }}</span>
                                        </div>
                                    </v-col>
                                    <v-row>
                                        <v-col cols="12" md="2" class="px-2">
                                            <v-select
                                                :items="andOr"
                                                filled
                                                outlined
                                                dense
                                                hide-details
                                                v-if="index == 0"
                                                disabled
                                                label="And/Or"
                                                item-text="title"
                                                item-value="value"
                                                v-model="filterChains[index].andOr"
                                            >
                                            </v-select>
                                            <v-select
                                                :items="andOr"
                                                v-else
                                                filled
                                                outlined
                                                :rules="defaultRules"
                                                dense
                                                hide-details
                                                label="And/Or"
                                                item-text="title"
                                                item-value="value"
                                                v-model="filterChains[index].andOr"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3" class="px-2">
                                            <v-autocomplete
                                                :items="columns"
                                                required
                                                filled
                                                outlined
                                                dense
                                                :rules="defaultRules"
                                                @change="resetConditionValue(index)"
                                                hide-details
                                                label="Column"
                                                item-text="name"
                                                item-value="column_name"
                                                return-object
                                                v-model="filterChains[index].column"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="3" class="px-2">
                                            <v-autocomplete
                                                :items="equalToCondition"
                                                v-if="
                                                    filterChains[index].column.type == 'tinyint' ||
                                                        filterChains[index].column.type == 'fk_object' ||
                                                        filterChains[index].column.type == 'varchar'
                                                "
                                                required
                                                filled
                                                outlined
                                                :rules="defaultRules"
                                                dense
                                                hide-details
                                                label="Condition"
                                                item-text="title"
                                                item-value="value"
                                                v-model="filterChains[index].condition"
                                            >
                                            </v-autocomplete>
                                            <v-autocomplete
                                                :items="conditions"
                                                v-else
                                                required
                                                filled
                                                outlined
                                                :rules="defaultRules"
                                                dense
                                                hide-details
                                                label="Condition"
                                                item-text="title"
                                                item-value="value"
                                                v-model="filterChains[index].condition"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="3" class="px-2">
                                            <v-menu
                                                ref="menu1"
                                                v-if="filterChains[index].column.type === 'date'"
                                                v-model="menu1"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                                class="float-left"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="filterChains[index].conditionValue"
                                                        label="From"
                                                        filled
                                                        dense
                                                        outlined
                                                        hide-details
                                                        persistent-hint
                                                        v-on="on"
                                                        :dark="light"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="filterChains[index].conditionValue" no-title></v-date-picker>
                                            </v-menu>
                                            <v-select
                                                v-else-if="filterChains[index].column.type === 'tinyint'"
                                                :items="tinyIntValues"
                                                required
                                                filled
                                                outlined
                                                hide-details
                                                dense
                                                label="Yes/No"
                                                item-text="text"
                                                item-value="value"
                                                v-model="filterChains[index].conditionValue"
                                            >
                                            </v-select>
                                            <v-select
                                                v-else-if="filterChains[index].column.type === 'fk_object'"
                                                :items="dropDowns[filterChains[index].column.dropdown_value]"
                                                required
                                                filled
                                                outlined
                                                hide-details
                                                dense
                                                label="Dropdown"
                                                :item-text="getDropDownItems(filterChains[index].column).DROPDOWN_LABEL"
                                                :item-value="getDropDownItems(filterChains[index].column).DROPDOWN_LABEL"
                                                v-model="filterChains[index].conditionValue"
                                            >
                                            </v-select>
                                            <v-text-field
                                                v-else
                                                label="Value"
                                                filled
                                                outlined
                                                dense
                                                hide-details
                                                required
                                                v-model="filterChains[index].conditionValue"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="1" class="justify-center d-flex align-center px-2">
                                            <v-btn
                                                @click="removeFilterFromChain(index)"
                                                v-if="isMobile"
                                                block
                                                class="remove-filter-row"
                                                depressed
                                                color="primary"
                                                :disabled="filterChains.length == 1"
                                            >
                                                <v-icon left>remove_circle_outline</v-icon>
                                                Remove
                                            </v-btn>
                                            <v-btn
                                                @click="removeFilterFromChain(index)"
                                                v-else
                                                class="remove-filter-row"
                                                :disabled="filterChains.length == 1"
                                                depressed
                                                icon
                                                color="primary"
                                            >
                                                <v-icon>remove_circle_outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-form>
                </div>
                <v-container class="d-flex button-wrapper">
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        depressed
                        text
                        color="primary"
                        @click="
                            copyObjectToArray();
                            clearFilter();
                        "
                        >Add row</v-btn
                    >
                    <v-btn
                        color="primary"
                        class="ml-3"
                        @click="saveAsNew()"
                        :disabled="noDataFoundAlert"
                        :loading="isSaveAsNewPending"
                        depressed
                        small
                    >
                        Save as new
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="ml-3"
                        @click="saveChanges()"
                        v-if="filterBeingEdited"
                        :loading="isSaveChangesPending"
                        :disabled="noDataFoundAlert"
                        depressed
                        small
                    >
                        Save
                    </v-btn>
                    <v-btn color="green" class="ml-3" depressed small :disabled="noDataFoundAlert" @click="applyFilter()">Apply</v-btn>
                </v-container>
                <FilterList
                    :savedfilters="savedfilters"
                    :isSavedFiltersPending="isSavedFiltersPending"
                    @deleteFilter="deleteFilter"
                    @editFilter="editFilter"
                    @shareFilter="shareFilter"
                    @applyCustomFilter="applyCustomFilter"
                />
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" depressed small @click="closeFilterDialog">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FilterList from "./FilterList/";

export default {
    name: "filterDialog",
    components: {
        FilterList,
    },
    props: {
        filterDialog: Boolean,
        items: Array,
        metaFields: Array,
        view: String,
        primarykey: String,
    },
    watch: {
        filter: {
            handler: function(v) {
                console.log(v);
                if (this.filter.column !== "" && this.filter.condition !== "" && this.filter.conditionValue !== "") {
                    this.valid = true;
                } else {
                    this.valid = false;
                }
            },
            deep: true,
        },
        filterChains: {
            handler: function(v) {
                const typeWithSingleCondition = ["tinyint", "fk_object", "varchar"];
                const indexes = v
                    .reduce((acc, curr, i) => {
                        if (typeWithSingleCondition.includes(curr.column.type)) {
                            acc.push(i);
                        }
                        return acc;
                    }, [])
                    .forEach((index) => (this.filterChains[index].condition = "=="));
            },
            deep: true,
        },
        selectedDate(date) {
            console.log(date);
            this.filter.conditionValue = new Date(date).getTime() / 1000;
        },
    },
    data: () => ({
        validForm: false,
        nameIsNeeded: false,
        filterBeingEdited: false,
        filterIdBeingEdited: null,
        isSavedFiltersPending: true,
        filterName: "",
        highlightEditedFilter: null,
        originalFilterId: null,
        dropDowns: {},
        equalToCondition: [
            {
                title: "Equal to",
                value: "==",
            },
        ],
        tinyIntValues: ["Yes", "No"],
        timeRules: [
            (value) => {
                const pattern = /([0-1]{1}[0-9]{1}|20|21|22|23):[0-5]{1}[0-9]{1}/;
                return pattern.test(value) || "Invalid Timeformat(hh:mm)";
            },
        ],
        defaultRules: [(v) => !!v],
        filterChains: [
            {
                column: "",
                condition: "",
                conditionValue: "",
                andOr: "",
            },
        ],
        selectedDate: "",
        isTinyInt: false,
        selectionIsDate: false,
        noDataFoundAlert: false,
        isSaveAsNewPending: false,
        isSaveChangesPending: false,
        tableItems: [],
        valid: false,
        columns: [],
        conditionString: "",
        conditions: "",
        filteredArray: "",
        filterConditionsArray: [],
        filtername: "",
        savedfilters: [],
        conditions: [
            {
                title: "Bigger than",
                value: ">",
            },
            {
                title: "Smaller than",
                value: "<",
            },
            {
                title: "Equal to",
                value: "==",
            },
            {
                title: "Not equal to",
                value: "!=",
            },
        ],
        andOr: [
            {
                title: "And",
                value: "&&",
            },
            {
                title: "Or",
                value: "||",
            },
        ],
        filter: {
            column: "",
            condition: "",
            conditionValue: "",
            andOr: "",
        },
        filterStringConditionArray: [],
        dropdownValues: [],
        timeConditionValue: "",
    }),
    async created() {
        this.tableItems = JSON.parse(JSON.stringify(this.metaFields));
        this.getColumnsWithCorrectLabel();
        this.getFilters();
        this.$bus.$on("emptyFilteredArray", this.emptyFilteredArray);
        this.getDropDownValues();
    },
    methods: {
        resetConditionValue(index) {
            /**
             * This is used to reset the conditionValue if the column is changed
             */
            this.filterChains[index].conditionValue = "";
        },
        async getDropDownValues() {
            /**
             * 	This is the initial dropdownitems fetch.
             */
            console.log(this.metaFields)
            for (let key in this.metaFields) {
                if (this.metaFields[key].DATA_TYPE == "fk_object") {
                    const dropdownValue = this.metaFields[key].DROPDOWN_VALUE;
                    if (!this.dropDowns[dropdownValue]) this.dropDowns[dropdownValue] = await this.$fetchDropdownList(this.metaFields[key]);
                }
            }
            console.log(this.dropDowns)
        },
        buildConditionStringFromObjects(filterObjects) {
            /**
             *  If no errors in form. Build the conditionstring from each object
             * 	in the "filterChains" array.
             *
             * 	Example:
             *
             * 	{
             * 		column_name:title,
             * 		condition:"=="
             * 		conditionValue: "Test"
             * 		andOr: ""
             *  }
             *
             *	item.title == 'Test'
             */

            return filterObjects
                .reduce((string, stringPart) => {
                    const { andOr, condition, conditionValue } = stringPart;
                    console.log(stringPart)
                    /**
                     * Columns with dropdowns has their name inside an object.
                     * So we check if the stringPart is of type "string".
                     * If not get the name inside the object.
                     */
                    const columnName = typeof stringPart.column == "string" ? stringPart.column : stringPart.column.column_name;

                    /**
                     * Here we add the filterstring together and add the toLowerCase() to each column and conditionValue.
                     * We do this to make the filter non case-sensitive. We also check if the condition value is empty,
                     * if so, we convert the "" to null to match the empty data in the table.
                     * We also check for numbers, if found we do not wrap them inside ''.
                     * We do this to be able to use the  "> < ==" operators in the filter method.
                     */
                    string += ` ${andOr} item.${columnName}?.toLowerCase() ${condition} ${
                        +conditionValue ? +conditionValue : `${conditionValue == "" ? null : `'${conditionValue.toLowerCase()}'`}`
                    }`;
                    return string;
                }, "")
                .trim();
        },
        getDropDownItems(column) {
            /**
             *  This is used to get dropdown values directly in the html.
             *  Columns have different dropdown alternatives.
             * 	So we use this switch to check the type and
             * 	fetch different dropdownlists depending on the type.
             */
            switch (column.type) {
                case "tinyint":
                    this.isTinyInt = true;
                    return [
                        {
                            text: "Yes",
                            value: "Yes",
                        },
                        {
                            text: "No",
                            value: "No",
                        },
                    ];
                case "fk_object":
                    const { DROPDOWN_LABEL, DROPDOWN_VALUE } = this.metaFields[column.column_name];
                    const DROPDOWN = this.dropDowns[column.dropdown_value]
                    return {
                        DROPDOWN,
                        DROPDOWN_LABEL,
                        DROPDOWN_VALUE,
                    };
            }
        },
        getColumnsWithCorrectLabel() {
            /**
             * We do this to get the correct LABEL of each column
             */
            for (let key in this.tableItems) {
                this.columns.push({
                    name: this.tableItems[key].LABEL,
                    column_name: this.tableItems[key].COLUMN_NAME,
                    type: this.tableItems[key].DATA_TYPE,
                    dropdown_value: this.tableItems[key].DROPDOWN_VALUE,
                    fk_object: this.tableItems[key].FK_TABLE,
                });
            }
        },
        emptyFilteredArray() {
            this.filteredArray = [];
        },
        createDescription() {
            /**
             * This is used to parse the original filterstring to a readable string.
             * Which can be seen when a user applies a filter
             */
            return this.buildConditionStringFromObjects(this.filterChains)
                .replace(/>/gi, "bigger than")
                .replace(/</gi, "smaller than")
                .replace(/&&/gi, "and")
                .replace(/\|\|/g, "or")
                .replace(/==/gi, "equal to")
                .replace(/!=/gi, "not equal to")
                .replace(/item.|'|_|.toLowerCase\(\)|\?/gi, "")
                .replace(/equal to null/gi, "equal to empty");
        },
        editFilter(objects, index, filterName, filterString) {
            /**
             * This is used to keep track if the user has pushed the
             * edit button
             */
            if (this.originalFilterId == this.savedfilters[index].user_filterid) {
                this.clearEditFilter();
                this.nameIsNeeded = false;
            } else {
                this.nameIsNeeded = true;
                this.filterIdBeingEdited = this.savedfilters[index].user_filterid;
                this.filterBeingEdited = true;
                this.conditionString = filterString;
                this.filterName = filterName;
                this.originalFilterId = this.savedfilters[index].user_filterid;
                this.filterChains = JSON.parse(objects);
            }
        },
        clearEditFilter() {
            this.filterIdBeingEdited = null;
            this.filterBeingEdited = false;
            this.filterName = "";
            this.originalFilterId = null;
            this.clearFilterArray();
            this.$refs.form.resetValidation();
        },
        async getFilters() {
            this.isSavedFiltersPending = true;
            var decode = require("decode-html");
            try {
                const response = await this.$GetService(`${this.$getRoute("user_filters").main_route}?view=${this.view}`);
                console.log(response);
                if (response.status === 200) {
                    this.isSavedFiltersPending = false;
                    const encodedString = JSON.stringify(response.data);
                    const decodedString = JSON.parse(decode(encodedString));
                    this.savedfilters = decodedString;
                } else if (response.status === 204) {
                    this.isSavedFiltersPending = false;
                }
            } catch (error) {
                console.log(error);
            }
        },
        closeFilterDialog() {
            this.$emit("closeFilterDialog");
        },
        removeFilterFromChain(index) {
            this.$delete(this.filterChains, index);
            this.filterChains[0].andOr = " ";
        },
        async deleteFilter(id, index) {
            /**
             * If the deleted filter is being edited, we need to change the "filterBeingEdited" variable to false.
             * We do this to prevent the user from saving a filter that no longer exist and the user need to save the filter as new.
             */
            if (id == this.filterIdBeingEdited) this.filterBeingEdited = false;
            try {
                let response = await this.$DeleteService(this.$getRoute("user_filters").main_route, { user_filterid: id });
                if (response.status === 200) {
                    this.$bus.$emit("unSetLoading");
                    this.$delete(this.savedfilters, index);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async clearFilter() {
            for (let key in this.filter) this.filter[key] = "";
        },
        async clearFilterArray() {
            this.filterChains = [
                {
                    column: "",
                    condition: "",
                    conditionValue: "",
                    andOr: "",
                },
            ];
        },
        copyObjectToArray() {
            const copyfilter = Object.assign({}, this.filter);
            this.filterChains.push(copyfilter);
            this.filterChains[0].andOr = " ";
        },
        applyFilter() {
            console.log(this.items.filter((item) => item.town == "Stockholm"));
            console.log(this.buildConditionStringFromObjects(this.filterChains));
            this.nameIsNeeded = false;
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    const filterFunction = Function(
                        "items",
                        "vm",
                        "let filtered = items.filter(item => " +
                            this.buildConditionStringFromObjects(this.filterChains) +
                            " ); vm.filteredArray = filtered"
                    );
                    console.log(filterFunction);
                    filterFunction(this.items, this);

                    if (this.filteredArray.length === 0) {
                        this.noDataFoundAlert = true;
                    } else {
                        this.setActiveFilterName(this.createDescription());
                        this.pushFilterToArray();
                    }
                }
            });
        },
        applyCustomFilter(filterString, filtername) {
            const filterFunction = Function(
                "items",
                "vm",
                "let filtered = items.filter(item => " + filterString + " ); vm.filteredArray = filtered;"
            );
            filterFunction(this.items, this);
            if (this.filteredArray.length === 0) {
                this.noDataFoundAlert = true;
            } else {
                this.setActiveFilterConditions(filterString);
                this.setActiveFilterName(filtername);
                this.pushFilterToArray();
            }
        },
        getDate() {
            const dateString = new Date().toISOString().split("-");
            const year = dateString[0];
            const month = dateString[1];
            const day = dateString[2].split("T")[0];
            return `${year}-${month}-${day}`;
        },
        async saveChanges() {
            if (!this.$refs.form.validate()) return;
            this.isSaveChangesPending = true;
            try {
                const response = await this.$PutService(this.$getRoute("user_filters").main_route, {
                    table_name: this.view,
                    filter_name: this.filterName,
                    filter_str: this.buildConditionStringFromObjects(this.filterChains),
                    description: this.createDescription(),
                    filter_obj: JSON.stringify(this.filterChains),
                    user_filterid: this.filterIdBeingEdited,
                    ownerid: this.userInfo.userid,
                });
                if (response.status == 200) {
                    this.isSaveChangesPending = false;
                    const indexOfEditedFilter = this.savedfilters.findIndex((filter) => filter.user_filterid === this.filterIdBeingEdited);
                    this.savedfilters[indexOfEditedFilter].table_name = this.view;
                    this.savedfilters[indexOfEditedFilter].filter_name = this.filterName;
                    this.savedfilters[indexOfEditedFilter].filter_str = this.conditionString;
                    this.savedfilters[indexOfEditedFilter].description = this.createDescription();
                    this.savedfilters[indexOfEditedFilter].filter_obj = JSON.stringify(this.filterChains);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async saveAsNew() {
            this.nameIsNeeded = true;
            this.$nextTick(async () => {
                if (this.$refs.form.validate()) {
                    this.isSaveAsNewPending = true;
                    try {
                        const response = await this.$PostService(this.$getRoute("user_filters").main_route, {
                            table_name: this.view,
                            filter_name: this.filterName,
                            filter_str: this.buildConditionStringFromObjects(this.filterChains),
                            description: this.createDescription(),
                            filter_obj: JSON.stringify(this.filterChains),
                            ownerid: this.userInfo.userid,
                        });
                        console.log(response);
                        if (response.status === 200) {
                            this.isSaveAsNewPending = false;
                            this.savedfilters.push({
                                table_name: this.view,
                                filter_name: this.filterName,
                                filter_str: this.buildConditionStringFromObjects(this.filterChains),
                                date_created: this.getDate(),
                                filter_obj: JSON.stringify(this.filterChains),
                                description: this.createDescription(),
                                user_filterid: response.data.user_filterid,
                            });
                            console.log(this.savedfilters);
                            this.clearFilterArray();
                            this.clearEditFilter();
                            this.$bus.$emit("emptyInput");
                            this.$bus.$emit("clearEditFilterHighlight");
                            this.nameIsNeeded = false;
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            });
        },
        pushFilterToArray() {
            this.$emit("pushFiltered", this.filteredArray);
        },
        ...mapActions({
            setActiveFilterName: "filters/setActiveFilterName",
            setActiveFilterConditions: "filters/setActiveFilterConditions",
        }),
    },
    computed: {
        ...mapGetters({
            activeFilterConditions: "filters/activeFilterConditions",
            activeFiltersNames: "filters/activeFiltersNames",
            isMobile: "globalstates/isMobile",
            metaData: "metadata/meta_data",
            userInfo: "user/userInfo",
        }),
    },
};
</script>

<style lang="scss" scoped>
.theme--light.v-text-field.v-input--is-disabled > .v-input__control > .v-input__slot:before {
    border-image: none !important;
    border: 1px solid red !important;
}

.theme--light.v-input--is-disabled textarea {
    color: red !important;
}

.built-filter-wrapper {
    width: 100%;
    float: left;
    padding: 15px 0px 10px 0px;
    background: #00000008;
}

.edited-filter-name {
    width: 100%;
    float: left;
    color: $font-color-pale;
    border-bottom: 1px solid $border-color;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
    margin-top: -15px;
    display: flex;
    align-items: center;
    i {
        margin-right: 10px;
        color: $primary;
        font-size: 20px;
    }
}

.remove-filter-row {
    width: 27px;
    height: 27px;
}

.build-filter-header {
    padding: 10px 25px;
    color: $font-color-pale;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    border-top: 1px solid $border-color;
    color: #6a7a8c;
    width: 100%;
    float: left;
    box-shadow: 0px 0px 5px #00000026;
}

.button-wrapper {
    box-shadow: 0px 0px 5px #00000026;
    float: left;
}

.mobile .button-wrapper {
    display: block !important;
    .v-btn {
        width: 100%;
        margin: 0px 0px 15px 0px !important;
        max-width: 100%;
        color: #fff;
    }
}

.row-indicator {
    margin: auto;
    width: 25px;
    height: 25px;
    background: #237ccf24;
    border-radius: 30px;
    display: flex;
    font-size: 13px;
    font-weight: 700;
    color: $accent-color;
}

.no-data-found {
    position: absolute;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.theme--dark.v-card {
    background: #f3f3f3;
    color: #666;
}

.v-btn {
    color: #fff;
}
.primary {
    color: #fff !important;
    .date-created {
        font-size: 13px;
        font-weight: 600;
        color: #fff !important;
    }
}

.header {
    font-size: 16px !important;
    text-transform: uppercase;
    color: $accent-color;
    font-weight: 600 !important;
    display: flex;
    position: relative;
}

.v-item--active {
    background-color: $accent-color !important;
    color: #fff !important;
    * {
        color: #fff !important;
    }
}

.v-card__actions {
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 5px #00000024;
    border-top: none !important;
}
</style>
