var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0 filter-card",attrs:{"cols":"12","md":"12"}},[_c('div',{class:{ disabled: _vm.isFilterUsed }},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"filter-wrapper",class:_vm.isMobile ? 'pt-6 pb-3' : 'd-flex',staticStyle:{"position":"relative"}},[_c('div',{staticClass:"filter-btn d-flex flex-grow-1"},[_c('div',{staticClass:"filter-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v("filter_list")])]),_c('div',{staticClass:"filter-name"},[_c('span',[_vm._v(_vm._s(_vm.filter.filter_name))])]),(_vm.isFilterUsed)?_c('div',{staticClass:"currently-selected"},[_vm._v(" Currently Selected ")]):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"date-created"},[_c('span',[_vm._v(_vm._s(_vm.filter.date_created.substring(0, 10)))])]):_vm._e()]),_c('div',{staticClass:"actions-wrapper",class:_vm.isMobile ? 'mt-3' : ''},[(_vm.isMobile)?_c('div',{staticClass:"date-created"},[_c('span',[_vm._v(_vm._s(_vm.filter.date_created.substring(0, 10)))])]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","transition":"false"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-btn edit ml-4",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.editFilter(_vm.filter.filter_obj, _vm.filter.filter_name, _vm.filter.filter_str)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1)]}}])},[_c('span',[_vm._v("Edit filter")])]),_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","right":"","dense":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onShareFilter = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","transition":"false"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onShareFilterToolTip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"filter-btn ml-3",attrs:{"color":"primary","icon":"","x-small":""}},Object.assign({}, onShareFilter,
                                            onShareFilterToolTip)),[_c('v-icon',[_vm._v("share")])],1)]}}],null,true)},[_c('span',{staticClass:"padding-right:20px;float:left;"},[_vm._v("Share filter")])])]}}])},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('div',{staticClass:"header",staticStyle:{"background":"#fff","width":"100%","float":"left","font-size":"13px","padding":"10px"}},[_vm._v("Share to")]),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.showShareFilterDialog(_vm.filter.filter_obj, _vm.filter.filter_name, _vm.filter.filter_str, 'users')}}},[_c('v-icon',{staticClass:"mr-3 mdi mdi-account",attrs:{"small":""}}),_c('v-list-item-title',[_vm._v("Users")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showShareFilterDialog(_vm.filter.filter_obj, _vm.filter.filter_name, _vm.filter.filter_str, 'department')}}},[_c('v-icon',{staticClass:"mr-3 mdi mdi-account-group",attrs:{"small":""}}),_c('v-list-item-title',[_vm._v("Department")])],1)],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":"","transition":"false"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-btn remove ml-4",attrs:{"disabled":_vm.isFilterUsed,"loading":_vm.isRemoveFilterPending,"icon":"","despressed":"","color":"error","small":""},on:{"click":function($event){return _vm.deleteFilter(_vm.filter.user_filterid, _vm.index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("remove_circle_outline")])],1)]}}])},[_c('span',[_vm._v("Remove filter")])]),_c('v-tooltip',{attrs:{"bottom":"","transition":"false"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-btn apply ml-4",attrs:{"disabled":_vm.isFilterUsed,"icon":"","small":"","color":"success"},on:{"click":function($event){return _vm.applyCustomFilter(_vm.filter.filter_str, _vm.filter.filter_name)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mdi mdi-filter-variant-plus"})],1)]}}])},[_c('span',[_vm._v("Apply filter")])])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }