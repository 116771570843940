<template>
    <div class="page-container">
        <Loader :loaderStyle="'linear'" v-if="!dataReady" />
        <PageHeader
            v-if="dataReady"
            :items="items"
            :view="view"
            :username="userInfo.fullname"
            :enableOtherActions="false"
            :defaultDates="defaultDates"
            :datesCollected="true"
            :showDateButtons="true"
            :userDepartments="userDepartments"
            :newDataLoading="newDataLoading"
            :wrongBenchmarkValues="wrongBenchmarkValues"
            @setStartDate="setStartDate"
            @setEndDate="setEndDate"
            @getSalesData="getSalesData"
            @getDepartmentData="getDepartmentData"
        />
        <div id="pi-page" v-if="dataReady">
            <v-container class="float-left pa-0 pb-3 container--fluid">
                <v-col cols="12" md="12" class="pa-0">
                    <progression-cards
                        :dataObject="progressionCards.dataObjects"
                        :isLoading="true"
                    />
                </v-col>
            </v-container>
            <v-container class="float-left pa-0 container--fluid">
                <v-col
                    cols="12"
                    md="12"
                    class="elevation-1 pa-0 graph-container"
                >
                    <div class="card-title">Statistics</div>
                    <v-col cols="12" md="12" class="pa-5 pb-12">
                        <echart-card
                            style="height: 350px"
                            :options="graphOptions"
                            v-if="graphOptions.series.length > 0"
                        />
                    </v-col>
                </v-col>
            </v-container>
            <v-container class="pt-3 pr-0 pl-0 float-left container--fluid pb-0">
                <v-col cols="12" md="12" class="top-bottom-projects pa-0">
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <project-ranking-card
                                :title="'Top 5 projects'"
                                :ticketBars="topBottomProjects.concertsTickets"
                                :grossBars="topBottomProjects.concertsGross"
                                :predBars="topBottomProjects.concertsPred"
                                :sortType="'desc'"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            :class="{ test: !isMobile }"
                        >
                            <project-ranking-card
                                :title="'Bottom 5 projects'"
                                :ticketBars="topBottomProjects.concertsTickets"
                                :grossBars="topBottomProjects.concertsGross"
                                :predBars="topBottomProjects.concertsPred"
                                :sortType="'asc'"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-container>
            <v-container class="pt-0  pr-0 pl-0 float-left container--fluid">
                <v-col cols="12" md="12" class="pa-0">
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <SalesProgressCard
                                :ticketBars="salesProgressCard.ticketBars"
                                :grossBars="salesProgressCard.grossBars"
                                v-if="dataReady"
                                :donutData="donutData"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-col
                                cols="12"
                                md="12"
                                class="pa-0 elevation-1 float-left"
                                style="background: #fff"
                               
                            >
                                <div class="card-title">Marketing</div>

                                <MarketingInfoCard
                                    :marketingBars="marketingBars"
                                    v-if="dataReady"
                                    :budgetMarketingSum="budgetMarketingSum"
                                    :marketingCost="
                                        this.progressionCards.dataObjects
                                            .total_marketing.number
                                    "
                                />
                            </v-col>
                        </v-col>
                    </v-row>
                </v-col>
            </v-container>
            <v-container class="pa-0 float-left container--fluid">
                <v-col cols="12" md="12" class="pa-0">
                    <TypeCard
                        :concerts="concerts"
                        :isConcertInRange="isConcertInRange"
                    />
                </v-col>
            </v-container>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/components/Menu/PageHeader";
import ProgressionCards from "@/components/Cards/ProgressionCards";
import EchartCard from "@/components/Cards/ChartCards/EchartCard";
import ProjectRankingCard from "@/components/Cards/ProjectRankingCard.vue";
import Loader from "@/components/Loader";
import {
    initEchart,
    generateSerie,
    initEchartTwoX,
    generateSerieTwoX,
} from "@/components/Cards/ChartCards/EchartOptions.js";
import SalesProgressCard from "@/components/Cards/SalesProgressCard";
import { mapGetters, mapState, mapActions } from "vuex";
import MarketingInfoCard from "@/components/Cards/MarketingInfoCard";
import TypeCard from "@/components/Cards/TypeCard";
import ObjectFactory from "@/models/ObjectFactory";

export default {
    props: {
        insightView: String,
    },
    components: {
        PageHeader,
        ProgressionCards,
        EchartCard,
        ProjectRankingCard,
        SalesProgressCard,
        MarketingInfoCard,
        TypeCard,
        Loader,
    },
    data: () => ({
        view: "",
        departments: ["Swedish", "Concert", "Luger", "Luger Promoter"],
        userDepartments: [],
        budgetMarketingSum: 0,
        chosenDepartment: "",
        items: {
            enddate: new Date().toISOString().substring(0, 10),
        },
        concertsInRange: {
            concerts: [],
            benchmarks: [],
        },
        concerts: [],
        ticketSales: [],
        graphOptions: {
            series: [],
        },
        salesGraphData: [],
        benchmarkSalesGraphData: [],
        analyticsGraphData: [],
        benchmarkAnalyticsGraphData: [],
        defaultDates: {
            startDate: `${new Date().getFullYear()}-01-01`,
            endDate: new Date().toISOString().substring(0, 10),
        },
        benchmarkStartDate: "",
        benchmarkEndDate: "",
        datePicker: {
            startDate: {
                date: `${new Date().getFullYear()}-01-01`,
            },
            endDate: {
                date: new Date().toISOString().substring(0, 10),
            },
        },
        newDataLoading: false,
        progressionCards: {
            dataObjects: {
                total_sold: {
                    title: "Total sold",
                    number: 0,
                    subtext: "tickets",
                    percentage: 100,
                    progresstext: "of goal",
                },
                active_events: {
                    title: "Active events",
                    number: 0,
                    subtext: "Shows",
                    percentage: 0,
                    progresstext: "",
                },
                total_marketing: {
                    title: "Total marketing",
                    number: 0,
                    subtext: "",
                    percentage: 0,
                    progresstext: "of goal",
                },
            },
        },
        salesData: null,
        totalSold: null,
        activeEvents: null,
        graphDataLoaded: false,
        topBottomProjects: {
            concertsTickets: [],
            concertsGross: [],
            concertsPred: [],
        },
        ticketPriceRange: {
            freeTickets: {
                title: "Freetickets",
                available: 0,
                tickets: 0,
            },
            range_300: {
                title: "Price range 100-299",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
            range_500: {
                title: "Price range 300-499",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
            range_800: {
                title: "Price range 500-799",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
            range_1199: {
                title: "Price range 800-1199",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
            range_1599: {
                title: "Price range 1200-1599",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
            range_1999: {
                title: "Price range 1600-1999",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
            range_2999: {
                title: "Price range 2000-2999",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
            range_3999: {
                title: "Price range 3000-3999",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
            platinum: {
                title: "Platinum",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
            vip_packages: {
                title: "VIP Packages",
                available: 0,
                tickets: 0,
                gross_sales: 0,
            },
        },
        donutData: {
            ticket_sold_percentage: 0,
            tickets_sold: 0,
            tickets_available: 0,
            gross_sales_percentage: 0,
            gross_sales: 0,
            gross_available: 0,
        },
        salesProgressCard: {
            ticketBars: [],
            grossBars: [],
        },
        marketingBars: [],
        dataReady: false,
        types: {},
        wrongBenchmarkValues: false,
        ticketBarsTitleExcludes: ["Freetickets", "Platinum", "VIP Packages"],
    }),
    async created() {
        this.view = this.$getView(this.$route.path);
        this.progressionCards.dataObjects.total_marketing.subtext = this.$config.CURRENCY;

        for (const KEY in this.ticketPriceRange)
            if (
                !this.ticketBarsTitleExcludes.includes(
                    this.ticketPriceRange[KEY].title
                )
            )
                this.ticketPriceRange[KEY].title += ` ${this.$config.CURRENCY}`;

        await this.getUserDepartments();
        await this.getData();
        await this.getBenchmarkDefaultDates();
        await this.init();
        this.dataReady = true;
        this.graphDataLoaded = true;
    },
    watch: {
        benchmarkStartDate() {
            if (
                new Date(this.datePicker.startDate.date) <
                new Date(this.benchmarkEndDate)
            ) {
                console.log("första");
                this.wrongBenchmarkValues = true;
            } else {
                this.wrongBenchmarkValues = false;
            }
        },
        "datePicker.endDate.date": {
            handler: function () {
                let fromDate = new Date(this.datePicker.startDate.date);
                if (
                    new Date(this.datePicker.endDate.date) >
                    new Date(fromDate.setMonth(fromDate.getMonth() + 12))
                ) {
                    this.wrongBenchmarkValues = true;
                } else {
                    this.wrongBenchmarkValues = false;
                }
                deep: true;
            },
        },
    },
    methods: {
        async init() {
            await this.resetArrays();
            await this.getAndAssignAllDataInRange();
            await this.initGraph();
            await this.$bus.$emit("SortProjectRanking");
            await this.$bus.$emit("SortSalesProgressBars");
            await this.$bus.$emit("getTypes");
        },
        async getDepartmentData(department) {
            this.newDataLoading = true;
            this.chosenDepartment = department;
            await this.getData();
            await this.init();
            this.newDataLoading = false;
        },
        getMarketingBars() {
            // FIND UNIQUE MARKETING NAMES AND BUILD OBJECT
            const marketingBars = this.concerts
                .reduce((names, concert) => {
                    if (
                        this.isConcertInRange({
                            concert: concert,
                            benchmark: false,
                        })
                    ) {
                        if (concert.marketing) {
                            concert.marketing.forEach((marketdata) => {
                                if (
                                    !names.includes(
                                        marketdata.name.toLowerCase()
                                    )
                                ) {
                                    names.push(marketdata.name.toLowerCase());
                                }
                            });
                        }
                    }
                    return names;
                }, [])
                .reduce((template, name) => {
                    template.push({
                        title: name,
                        total: 0,
                        percentage: 0,
                        color: this.companyColors[name.toLowerCase()],
                    });
                    return template;
                }, []);

            // CHECK IF CONCERT IS IN DATE RANGE, IF SO, ADD VALUES TO OBJECT AND RETURN
            console.log(this.progressionCards.dataObjects);
            this.concerts.forEach((concert) => {
                if (
                    this.isConcertInRange({
                        concert: concert,
                        benchmark: false,
                    })
                ) {
                    marketingBars.forEach((bar) => {
                        if (concert.marketing) {
                            concert.marketing.forEach((market) => {
                                if (
                                    bar.title.toLowerCase() ===
                                    market.name.toLowerCase()
                                ) {
                                    if (market.marketing_data) {
                                        market.marketing_data.forEach(
                                            (data) => {
                                                bar.total += +data.amount;
                                            }
                                        );
                                    }
                                }
                            });
                        }
                        bar.percentage = (
                            (bar.total /
                                this.progressionCards.dataObjects
                                    .total_marketing.number) *
                            100
                        ).toFixed(0);
                    });
                }
            });

            return marketingBars;
        },
        getAndAssignAllDataInRange() {
            if (this.concerts.length === 0) {
                try {
                    throw Error(
                        "GET error or user are not assigned to any concerts"
                    );
                } catch (e) {
                    console.log(e);
                }
            }

            // ALL DATA IN RANGE
            const {
                concerts,
                sales,
                marketing,
                benchmarks,
                benchmarkSales,
                benchmarkMarketing,
                topBottomProjects,
                totalGrossSales,
                totalTicketsAvailable,
                totalGrossAvailable,
            } = this.getConcertDataInRange();

            // ASSIGN CONSTANT FOR READABILITY
            const P_CARD = this.progressionCards.dataObjects;

            // PROGRESSCARD ACTIVE EVENTS
            P_CARD.active_events.number = concerts;
            P_CARD.active_events.percentage = this.getPercentage(
                concerts,
                benchmarks
            );
            P_CARD.active_events.progresstext = `${
                P_CARD.active_events.percentage
                    ? P_CARD.active_events.percentage
                    : "0"
            }% of benchmark`;

            // PROGRESSCARD TOTAL SOLD
            P_CARD.total_sold.number = sales;
            P_CARD.total_sold.percentage = this.getPercentage(
                sales,
                benchmarkSales
            );
            P_CARD.total_sold.progresstext = `${
                P_CARD.total_sold.percentage
                    ? P_CARD.total_sold.percentage
                    : "0"
            } % of benchmark`;

            // PROGRESSCARD TOTAL SOLD
            P_CARD.total_marketing.number = marketing;
            P_CARD.total_marketing.percentage = this.getPercentage(
                marketing,
                benchmarkMarketing
            );
            P_CARD.total_marketing.progresstext = `${
                P_CARD.total_marketing.percentage
                    ? P_CARD.total_marketing.percentage
                    : "0"
            }% of benchmark`;

            // ASSIGN TOP BOTTOM PROJECTS WITHIN RANGE
            this.topBottomProjects = topBottomProjects;

            //ASSIGN MARKETING BARS
            this.marketingBars = this.getMarketingBars();

            // ASSIGN SALESPROGRESSCARD BARS
            const { ticketBars, grossBars } = this.getSalesProgressCardData();

            this.salesProgressCard.ticketBars = ticketBars;
            this.salesProgressCard.grossBars = grossBars;

            //ASSIGN DONUTDATA
            this.donutData.gross_sales = totalGrossSales;
            this.donutData.gross_available = totalGrossAvailable;
            this.donutData.gross_sales_percentage = Math.round(
                (totalGrossSales / totalGrossAvailable) * 100
            );
            this.donutData.tickets_available = totalTicketsAvailable;
            this.donutData.ticket_sold_percentage = Math.round(
                (sales / totalTicketsAvailable) * 100
            );
            this.donutData.tickets_sold = sales;
        },
        getSalesProgressCardData() {
            let ticketBars = [];
            let grossBars = [];
            Object.keys(this.ticketPriceRange).forEach((key) => {
                ticketBars.push({
                    title: this.ticketPriceRange[key].title,
                    percentage: this.getPercentage(
                        this.ticketPriceRange[key].tickets,
                        this.ticketPriceRange[key].available
                    ),
                    total: this.ticketPriceRange[key].tickets,
                });
            });
            Object.keys(this.ticketPriceRange).forEach((key) => {
                grossBars.push({
                    title: this.ticketPriceRange[key].title,
                    percentage: this.getPercentage(
                        this.ticketPriceRange[key].tickets,
                        this.ticketPriceRange[key].available
                    ),
                    total: this.ticketPriceRange[key].gross_sales,
                });
            });
            return {
                grossBars,
                ticketBars,
            };
        },
        getConcertDataInRange() {
            // OBJECT TO STORE DATA
            let totalAmounts = {
                sales: 0,
                totalMarketing: 0,
                benchmarkSales: 0,
                benchmarkMarketing: 0,
                totalGrossSales: 0,
                totalTicketsAvailable: 0,
                totalGrossAvailable: 0,
            };

            console.log(totalAmounts);

            // OBJECT FOR STORING CONCERTS IN RANGE AND BENCHMARKS IN RANGE
            this.concertsInRange = {
                concerts: [],
                benchmarks: [],
            };

            // TEMP ARRAY FOR STORING TOP BOTTOM PROJECTS
            let topBottomProjectsTempArray = {
                concertsTickets: [],
                concertsGross: [],
                concertsPred: [],
            };

            let marketingTypes = [];

            this.concerts.forEach((concert) => {
                if (concert.tickets) {
                    // PARAMS FOR FUNCTION 'calcConcertDataAndInitGraphData'
                    const SALES_PARAMS = {
                        concert: concert,
                        key: "tickets",
                        innerKey: "ticket_sales",
                    };
                    const MARKETING_PARAMS = {
                        concert: concert,
                        key: "marketing",
                        innerKey: "marketing_data",
                    };
                    const ANALYTICS_PARAMS = {
                        concert: concert,
                        key: "analytics",
                    };

                    // CHECK IF CONCERT IS IN RANGE, IF TRUE WE CALL function 'calcConcertDataAndInitGraphData' TO ADD ADD AMOUNTS TO TOTALAMOUNT WHICH IS LATER RETURNED TO BE USED BY PROGRESSCARDS
                    // FUNCTION 'calcConcertDataAndInitGraphData' IS ALSO USED TO INIT THE GRAPH DATA
                    if (
                        this.isConcertInRange({
                            concert: concert,
                            benchmark: false,
                        })
                    ) {
                        this.concertsInRange.concerts.push(concert);

                        const {
                            concertTickets,
                            concertGross,
                            concertPred,
                        } = this.getTopBottomBars(concert);

                        topBottomProjectsTempArray.concertsTickets.push(
                            concertTickets
                        );
                        topBottomProjectsTempArray.concertsGross.push(
                            concertGross
                        );
                        topBottomProjectsTempArray.concertsPred.push(
                            concertPred
                        );

                        // SORT ALL TICKETPRICES BY RANGE
                        this.getTicketPriceRange(concert.tickets);

                        // FOR DONUT DATA
                        const {
                            grossSales,
                            availableTickets,
                            totalGrossAvailable,
                        } = this.getTotalGrossAndAvailableTickets(
                            concert.tickets
                        );
                        totalAmounts.totalGrossSales += grossSales;
                        totalAmounts.totalTicketsAvailable += availableTickets;
                        totalAmounts.totalGrossAvailable += totalGrossAvailable;

                        // FOR PROGRESSCARDS
                        totalAmounts.sales += this.calcConcertDataAndInitGraphData(
                            SALES_PARAMS,
                            this.salesGraphData
                        );
                        totalAmounts.totalMarketing += this.calcConcertDataAndInitGraphData(
                            MARKETING_PARAMS
                        );

                        this.calcConcertDataAndInitGraphData(
                            ANALYTICS_PARAMS,
                            this.analyticsGraphData
                        );
                    }
                    if (
                        this.isConcertInRange({
                            concert: concert,
                            benchmark: true,
                        })
                    ) {
                        this.concertsInRange.benchmarks.push(concert);
                        totalAmounts.benchmarkSales += this.calcConcertDataAndInitGraphData(
                            SALES_PARAMS,
                            this.benchmarkSalesGraphData
                        );
                        totalAmounts.benchmarkMarketing += this.calcConcertDataAndInitGraphData(
                            MARKETING_PARAMS
                        );
                        this.calcConcertDataAndInitGraphData(
                            ANALYTICS_PARAMS,
                            this.benchmarkAnalyticsGraphData
                        );
                    }
                }
            });

            console.log(marketingTypes);

            // IF NO CONCERTS WERE FOUND RETURN 0
            if (this.concertsInRange.concerts.length === 0) {
                return {
                    concerts: 0,
                    sales: 0,
                    marketing: 0,
                    topBottomProjects: topBottomProjectsTempArray,
                };
            }

            const {
                sales,
                totalMarketing,
                benchmarkSales,
                benchmarkMarketing,
                totalGrossSales,
                totalTicketsAvailable,
                totalGrossAvailable,
            } = totalAmounts;

            // RETURN OBJECT WITH THE FINAL DATA
            return {
                concerts: this.concertsInRange.concerts.length,
                sales: sales,
                marketing: totalMarketing,
                benchmarks: this.concertsInRange.benchmarks.length,
                benchmarkSales: benchmarkSales,
                benchmarkMarketing: benchmarkMarketing,
                topBottomProjects: topBottomProjectsTempArray,
                totalGrossSales: totalGrossSales,
                totalTicketsAvailable: totalTicketsAvailable,
                totalGrossAvailable: totalGrossAvailable,
            };
        },

        getTotalGrossAndAvailableTickets(tickets) {
            if (!tickets) return;
            let sum = {
                grossSales: 0,
                availableGross: 0,
                availableTickets: 0,
            };

            tickets.forEach((ticket) => {
                if (ticket.ticket_sales) {
                    ticket.ticket_sales.forEach((sale) => {
                        sum.grossSales += +sale.amount * +ticket.price;
                    });
                }
                sum.availableTickets += +ticket.available;
                sum.availableGross += +ticket.available * +ticket.price;
            });

            return {
                grossSales: sum.grossSales,
                availableTickets: sum.availableTickets,
                totalGrossAvailable: sum.availableGross,
            };
        },
        isConcertInRange({ concert, benchmark }) {
            console.log(concert)
            const startDate = this.datePicker.startDate.date;
            const endDate = this.datePicker.endDate.date;
            let minDate = null;
            try {
                minDate = new Date(concert.dev_min_date);
            } catch (error) {
                minDate = "";
            }

            // CHECK IF DATE WITHING RANGE OF DATEPICKER
            if (!benchmark) {
                if (minDate) {
                    if (
                        minDate >= new Date(startDate) &&
                        minDate <= new Date(endDate)
                    )
                        return true;
                }
            }
            // CHECK IF DATE WITHING RANGE OF DATEPICKER MINUS 1 YEAR
            if (benchmark) {
                if (minDate) {
                    if (
                        minDate >= new Date(this.benchmarkStartDate) &&
                        minDate <= new Date(this.benchmarkEndDate)
                    )
                        return true;
                }
            }

            return false;
        },
        getTicketPriceRange(tickets) {
            if (!tickets) return;

            const getRange = (sales) => {
                let amount = 0;
                if (sales) {
                    sales.forEach((sale) => {
                        amount += +sale.amount;
                    });
                }
                return amount;
            };

            tickets.forEach((ticket) => {
                if (ticket.platinum == 1) {
                    this.ticketPriceRange.platinum.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.platinum.available += +ticket.available;
                    this.ticketPriceRange.platinum.gross_sales =
                        this.ticketPriceRange.platinum.tickets * ticket.price;
                }
                if (ticket.name.toLowerCase().includes("vip")) {
                    this.ticketPriceRange.vip_packages.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.vip_packages.available += +ticket.available;
                    this.ticketPriceRange.vip_packages.gross_sales =
                        this.ticketPriceRange.vip_packages.tickets *
                        ticket.price;
                }
                if (!(+ticket.price > 0) && ticket.price) {
                    console.log(ticket);
                    this.ticketPriceRange.freeTickets.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.freeTickets.available += +ticket.available;
                } else if (+ticket.price < 300) {
                    this.ticketPriceRange.range_300.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_300.available += +ticket.available;
                    this.ticketPriceRange.range_300.gross_sales =
                        this.ticketPriceRange.range_300.tickets * ticket.price;
                } else if (+ticket.price < 500) {
                    this.ticketPriceRange.range_500.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_500.available += +ticket.available;
                    this.ticketPriceRange.range_500.gross_sales =
                        this.ticketPriceRange.range_500.tickets * ticket.price;
                } else if (+ticket.price < 800) {
                    this.ticketPriceRange.range_800.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_800.available += +ticket.available;
                    this.ticketPriceRange.range_800.gross_sales =
                        this.ticketPriceRange.range_1599.tickets * ticket.price;
                } else if (+ticket.price < 1200) {
                    this.ticketPriceRange.range_1199.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_1199.available += +ticket.available;
                    this.ticketPriceRange.range_1199.gross_sales =
                        this.ticketPriceRange.range_1199.tickets * ticket.price;
                } else if (+ticket.price < 1600) {
                    this.ticketPriceRange.range_1599.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_1599.available += +ticket.available;
                    this.ticketPriceRange.range_1599.gross_sales =
                        this.ticketPriceRange.range_1599.tickets * ticket.price;
                } else if (+ticket.price < 2000) {
                    this.ticketPriceRange.range_1999.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_1999.available += +ticket.available;
                    this.ticketPriceRange.range_1999.gross_sales =
                        this.ticketPriceRange.range_1999.tickets * ticket.price;
                } else if (+ticket.price < 3000) {
                    this.ticketPriceRange.range_2999.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_2999.available += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_2999.gross_sales =
                        this.ticketPriceRange.range_2999.tickets * ticket.price;
                } else if (+ticket.price < 4000) {
                    this.ticketPriceRange.range_3999.tickets += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_3999.available += getRange(
                        ticket.ticket_sales
                    );
                    this.ticketPriceRange.range_3999.gross_sales =
                        this.ticketPriceRange.range_3999.tickets * ticket.price;
                } else {
                    console.log(ticket.price);
                }
            });
        },
        getTopBottomBars(concert) {
            console.log(concert);
            if (
                this.concertsInRange.concerts.length === 0 ||
                !concert ||
                !concert.tickets
            )
                return;

            const CAPACITY_FOR_EACH_CONCERT = concert.tickets
                .reduce((total, ticket) => {
                    total.push(+ticket.available);
                    return total;
                }, [])
                .reduce((a, b) => a + b);

            const TICKET_SALES_FOR_EACH_CONCERT = concert.tickets
                .reduce((total, sales) => {
                    if (sales.ticket_sales) {
                        sales.ticket_sales.forEach((sale) => {
                            total.push(+sale.amount);
                        });
                    } else {
                        total.push(0);
                    }
                    return total;
                }, [])
                .reduce((a, b) => a + b);

            const GROSS_SALES_FOR_EACH_CONCERT = concert.tickets
                .reduce((total, sales) => {
                    if (sales.ticket_sales) {
                        sales.ticket_sales.forEach((sale) => {
                            total.push(+sale.amount * sales.price);
                        });
                    } else {
                        total.push(0);
                    }
                    return total;
                }, [])
                .reduce((a, b) => a + b);

            const concertTickets = {
                title: concert.title,
                percentage: this.getPercentage(
                    TICKET_SALES_FOR_EACH_CONCERT,
                    CAPACITY_FOR_EACH_CONCERT
                ),
                total: TICKET_SALES_FOR_EACH_CONCERT,
            };

            const concertGross = {
                title: concert.title,
                percentage: this.getPercentage(
                    TICKET_SALES_FOR_EACH_CONCERT,
                    CAPACITY_FOR_EACH_CONCERT
                ),
                total: GROSS_SALES_FOR_EACH_CONCERT,
            };

            const concertPred = {
                title: concert.title,
                percentage: Math.round(concert.predicted_sales * 100),
                total: CAPACITY_FOR_EACH_CONCERT,
            };

            // goes to top ProjectRankingCard

            return {
                concertTickets: concertTickets,
                concertGross: concertGross,
                concertPred: concertPred,
            };
        },
        initGraph() {
            if (this.concerts.length === 0) {
                try {
                    throw Error(
                        "GET error or user are not assigned to any concerts"
                    );
                } catch (e) {
                    console.log(e);
                }
            }

            const START_DATE = this.datePicker.startDate.date;
            const END_DATE = this.datePicker.endDate.date;

            const XAXIS = this.getDateRangeArray(START_DATE, END_DATE);
            const XAXIS2 = this.getDateRangeArray(
                this.benchmarkStartDate,
                this.benchmarkEndDate
            );

            this.graphOptions = initEchartTwoX({
                xAxisData: XAXIS,
                xAxisData2: XAXIS2,
                title: "Statistics",
                yAxisLTitle: "Tickets",
                yAxisRTitle: "Visitors",
            });
            this.graphOptions.xAxis[1].axisLabel = { show: false };
            this.salesGraphData.sort((a, b) => new Date(a[0]) - new Date(b[0]));
            this.benchmarkSalesGraphData.sort(
                (a, b) => new Date(a[0]) - new Date(b[0])
            );

            if (this.salesGraphData.length > 0)
                this.setMissingDates(
                    this.salesGraphData[0][0],
                    this.salesGraphData[this.salesGraphData.length - 1][0],
                    this.salesGraphData
                );

            this.salesGraphData.sort((a, b) => new Date(a[0]) - new Date(b[0]));
            console.log("salesgraphdata");
            console.log(this.salesGraphData);

            this.graphOptions.series.push(
                generateSerieTwoX({
                    data: this.salesGraphData,
                    name: "Sold",
                    smooth: false,
                })
            );
            this.graphOptions.series.push(
                generateSerieTwoX({
                    data: this.benchmarkSalesGraphData,
                    name: "Benchmark Sold",
                    xAxis: 1,
                    smooth: false,
                    linestyle: "dotted",
                    lineWidth: 1,
                })
            );
            // this.graphOptions.series.push(
            //     generateSerieTwoX({
            //         data: this.analyticsGraphData,
            //         name: "Analytics",
            //         smooth: true,
            //         yAxis: 1,
            //     })
            // );
            // this.graphOptions.series.push(
            //     generateSerieTwoX({
            //         data: this.benchmarkAnalyticsGraphData,
            //         name: "Benchmark analytics",
            //         xAxis: 1,
            //         yAxis: 1,
            //         smooth: true,
            //         linestyle: "dotted",
            //         lineWidth: 1,
            //     })
            // );
        },
        setMissingDates(fromdate, enddate, salesArray) {
            //Convert dates from ISOStrings to date objects, set time to 12:00:00 to avoid daylight savings issues
            var date = new Date(Date.parse(fromdate + " 12:00:00"));
            var todate = new Date(Date.parse(enddate + " 12:00:00"));
            var isodate = "";
            var dateArray = [];

            //Fill dateArray with existing dates
            for (let index = 0; index < salesArray.length; ++index)
                dateArray.push(salesArray[index][0]);

            do {
                //convert date to ISOString
                isodate = date.toISOString().substring(0, 10);
                //If date is missing, push object to salesArray for missing date
                if (dateArray.indexOf(isodate) == -1)
                    salesArray.push([isodate, null]);

                //Add one day to date
                date.setDate(date.getDate() + 1);
            } while (date <= todate);
        },
        resetArrays() {
            this.salesGraphData = [];
            this.benchmarkSalesGraphData = [];
            this.analyticsGraphData = [];
            this.benchmarkAnalyticsGraphData = [];
            this.topBottomProjects = {
                concertsTickets: [],
                concertsGross: [],
                concertsPred: [],
            };
            this.salesProgressCard = {
                ticketBars: [],
                grossBars: [],
            };
            this.donutData = {
                ticket_sold_percentage: 0,
                tickets_sold: 0,
                tickets_available: 0,
                gross_sales_percentage: 0,
                gross_sales: 0,
                gross_available: 0,
            };
            for (let key in this.ticketPriceRange) {
                this.ticketPriceRange[key].tickets = 0;
                this.ticketPriceRange[key].available = 0;
                this.ticketPriceRange[key].gross_sales = 0;
            }
        },
        initDataForGraph(saleData, datekey, valuekey, salesArray) {
            let found = false;
            salesArray.forEach((sale) => {
                if (saleData[datekey] == sale[0]) {
                    found = true;
                    sale[1] += +saleData[valuekey];
                }
            });
            if (!found)
                salesArray.push([saleData[datekey], +saleData[valuekey]]); // Add new date with sales
        },
        async getData() {
            if (this.chosenDepartment === "") {
                try {
                    const response = await this.$GetService(`concerts?
							glue=or&
							settlement_representative=${this.userInfo.userid}&
							project_manager=${this.userInfo.userid}&
							booked_by=${this.userInfo.userid}&
							production_manager=${this.userInfo.userid}&
							prod_coordinator=${this.userInfo.userid}&
							artwork=${this.userInfo.userid}&
							pr=${this.userInfo.userid}&
							head_of_marketing=${this.userInfo.userid}&
							inc=assoc&
							exclude_fields=wall_events`);
                    console.log(response);
                    if (response.status === 200) {
                        for (let concert of response.data) {
                            this.concerts.push(
                                await this.$ObjectFactory.BuildConcert({ concert })
                            );
                        }
                        this.getTotalBudgetMarketingSum();
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                const response = await this.$GetService(
                    `concerts?department=${this.chosenDepartment}&inc=assoc&exclude_fields=wall_events`
                );
                if (response.status === 200) {
                    this.concerts = response.data;
                }
            }
        },
        getTotalBudgetMarketingSum() {
            this.budgetMarketingSum = this.concerts.reduce((acc, budget) => {
                let currentBudget = budget.getCurrentBudget();
                if (currentBudget && currentBudget.marketing_costs)
                    acc += +currentBudget.marketing_costs;
                return acc;
            }, 0);
            // for (let concert of this.concerts) {
            //     let budget = concert.getCurrentBudget();
            //     if (budget && budget.marketing_costs)
            //         total += +budget.marketing_costs;
            // }

            // this.budgetMarketingSum = total;
        },
        async getUserDepartments() {
            const response = await this.$GetService(`users?id=${this.userInfo.userid}`);
            console.log(response);
            const accessgroups = response.data.accessgroup.split`;`;
            for (let group of accessgroups) {
                if (this.departments.includes(group)) {
                    this.userDepartments.push(group);
                }
            }
        },
        getBenchmarkDefaultDates() {
            let benchDefaultStartDate = new Date(this.defaultDates.startDate);
            let benchDefaultEndDate = new Date(this.defaultDates.endDate);

            // BENCHMARKDATES ARE ALWAYS MINUS 1YEAR
            this.benchmarkStartDate = new Date(
                benchDefaultStartDate.setMonth(
                    benchDefaultStartDate.getMonth() - 12
                )
            )
                .toISOString()
                .substring(0, 10);
            this.benchmarkEndDate = new Date(
                benchDefaultEndDate.setMonth(
                    benchDefaultEndDate.getMonth() - 12
                )
            )
                .toISOString()
                .substring(0, 10);
        },
        setStartDate(date) {
            this.datePicker.startDate.date = date;
            const benchDate = new Date(date);
            this.benchmarkStartDate = new Date(
                benchDate.setMonth(benchDate.getMonth() - 12)
            )
                .toISOString()
                .substring(0, 10);
            console.log(this.benchmarkStartDate);
        },
        setEndDate(date) {
            this.datePicker.endDate.date = date;
            const benchDate = new Date(date);
            this.benchmarkEndDate = new Date(
                benchDate.setMonth(benchDate.getMonth() - 12)
            )
                .toISOString()
                .substring(0, 10);
            console.log(this.benchmarkEndDate);
        },
        getDateRangeArray(start, end) {
            start = new Date(start);
            end = new Date(end);
            let arr = [];
            while (start <= end) {
                arr.push(start.toISOString().substr(0, 10));
                start = this.getFinalDates(start, 1);
            }
            return arr;
        },
        getFinalDates(date, add) {
            let myDate = date;
            let day = 86400000; //number of milliseconds in a day
            return new Date(+myDate + add * day);
        },

        calcConcertDataAndInitGraphData(
            { concert, key, innerKey = null },
            graphData = []
        ) {
            let sum = 0;
            if (concert[key]) {
                concert[key].forEach((data) => {
                    if (data.price > 0 || !data.price) {
                        if (data[innerKey]) {
                            data[innerKey].forEach((innerData) => {
                                if (innerKey === "ticket_sales")
                                    this.initDataForGraph(
                                        innerData,
                                        "date",
                                        "amount",
                                        graphData
                                    );
                                sum += +innerData.amount;
                            });
                        } else {
                            this.initDataForGraph(
                                data,
                                "date",
                                "visitors",
                                graphData
                            );
                        }
                    }
                });
            }
            return sum;
        },
        getPercentage: (value1, value2) => {
            if (value1 === 0 || value2 === 0) {
                return 0;
            } else {
                return Math.round((value1 / value2) * 100);
            }
        },

        getSalesData() {
            this.init();
        },
    },
    computed: {
        ...mapGetters({
            userInfo: "user/userInfo",
            companyColors: "globalstates/companyColors",
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
.page-container {
    width: 100%;
}

.graph-container {
    background: #fff;
    border-radius: 3px;
}
</style>
 