import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import filters from './modules/filters';
import globalstates from './modules/globalstates';
import metadata from './modules/metadata';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    globalstates,
    filters,
    metadata,
  }
})
