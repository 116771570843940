<template>
    <div id="budget-button-container" style="float:left;">
        <v-col cols="12" md="12" class="pa-0">
            <v-row style class="pa-0 d-flex justify-center">
                <div v-if="exportEnabled" class="export-btn-wrapper">
                    <v-btn
                        color="info"
						depressed
                        @click="$bus.$emit('ExportBudgetToExcel', `${exportTitle.replace(/ /gi, '_')}-${budgetData.updated_date.split(' ')[0]}`)"
                    >Export</v-btn>
                </div>
                <div v-if="budgetData.status != -1" class="float-left d-flex budget-button-wrapper">
                    <div class="pa-1 float-left">
                        <v-btn
                            v-if="budgetData.status < 2 && (budgetData.approval_userid != null ? budgetData.approval_userid == userid : true)"
                            :disabled="!savingAllowed"
                            depressed
                            color="primary"
                            block
                            @click="$emit('saveBudget')"
                        >Save budget</v-btn>
                    </div>
                    <div class="pa-1 float-left">
                        <v-btn
                            v-if="budgetData.status != 1"
                            depressed
                            color="primary"
                            @click="openSaveNewDialog"
                        >Save new budget version</v-btn>
                    </div>
                    <div class="pa-1 float-left">
                        <v-btn
                            v-if="budgetData.status == 0"
                            depressed
                            color="success"
                            @click="startApprovalDialogFlow('saveChangesBeforeSending')"
                        >
                            Send for approval
                            <v-icon right style="font-size:18px;">mdi-arrow-right-bold</v-icon>
                        </v-btn>
                    </div>
                    <div class="pa-1 float-left">
                        <v-btn
                            v-if="budgetData.status == 1 && budgetData.approval_userid == userid"
                            depressed
                            color="primary"
                            @click="openBudgetDeclineDialog()"
                        >Decline</v-btn>
                    </div>
                    <div class="pa-1 float-left">
                        <v-btn
                            v-if="budgetData.status == 1 && budgetData.approval_userid == userid"
                            depressed
                            color="success"
                            @click="startApprovalDialogFlow('saveChangesBeforeApproval')"
                        >Approve</v-btn>
                    </div>
                </div>
            </v-row>
        </v-col>
        <confirm-dialog
            :options="confirmOptions"
            :text="confirmDialogText"
            :subtext="confirmDialogSubText"
            :confirmButtonText="confirmButtonText"
            :confirmSecondaryButtonText="confirmSecondaryButtonText"
            :confirmDenyButtonText="confirmDenyButtonText"
            :dialog="showConfirmDialog"
            @confirmDialog="confirmDialogResponse"
        />
        <list-selection-dialog
            text="Send for approval"
            subtext="Please select an indivudal in the list"
            :items="approvedUsers"
            :dialog="showApprovalDialog"
            @confirmDialog="(userid) => { showApprovalDialog = false; $emit('saveBudget'); $emit('sendForApproval', userid); }"
            @cancelDialog="showApprovalDialog = false"
        />
    </div>
</template>

<script>
class ConfirmOptions {
    constructor({
        type = "confirm",
        text = "",
        btnText = "Confirm",
        subText = "",
        secBtnText = "",
        denyBtnText = "Cancel",
    }) {
        this.type = type;
        this.text = text;
        this.subText = subText;
        this.btnText = btnText; // YES OPTION
        this.secBtnText = secBtnText; // NO OPTION
        this.denyBtnText = denyBtnText; // Cancel option
    }
}
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ListSelectionDialog from "@/components/Dialogs/ListSelectionDialog";
export default {
    name: "budgetbuttonmenu",
    components: {
        ConfirmDialog,
        ListSelectionDialog,
    },
    props: {
        budgetData: Array,
        exportTitle: String,
        userid: String,
        savingAllowed: Boolean,
        exportEnabled: Boolean,
    },
    data() {
        return {
            showConfirmDialog: false,
            showApprovalDialog: false,
            confirmOptions: {},
            approvedUsers: JSON.parse(
                JSON.stringify(this.budgetData.dropdowns.users)
            ),
        };
    },
    methods: {
        openSaveNewDialog() {
            this.confirmOptions = new ConfirmOptions({
                type: "saveAsNew",
                text: "Save as new budget",
                subText: "(Lock this and save to a new draft)",
                btnText: "Save with changes",
                secBtnText: "Save without changes",
            });
            this.showConfirmDialog = true;
        },

        startApprovalDialogFlow(type) {
            this.confirmOptions = new ConfirmOptions({
                type: type,
                text: "Save changes before approval?",
                btnText: "Yes",
                secBtnText: "No",
            });
            this.showConfirmDialog = true;
            //this.showApprovalDialog = true;
        },

        openBudgetApproveDialog() {
            this.confirmOptions = new ConfirmOptions({
                type: "approve",
                text: "Approve the budget",
                btnText: "Approve budget",
            });
            this.showConfirmDialog = true;
        },
        openBudgetDeclineDialog() {
            this.confirmOptions = new ConfirmOptions({
                type: "decline",
                text: "Decline the budget",
                subText:
                    "(Lock this version and copy its values to a new draft)",
                btnText: "Decline budget",
            });
            this.showConfirmDialog = true;
        },

        confirmDialogResponse({ cancel, response, type }) {
            if (!cancel) {
                switch (type) {
                    case "saveAsNew":
                        this.$emit("saveNewBudget", response);
                        this.showConfirmDialog = false;
                        break;
                    case "saveChangesBeforeSending":
                        this.showConfirmDialog = false;
                        if (response) this.$emit("saveBudget");

                        this.showApprovalDialog = true;
                        break;
                    case "saveChangesBeforeApproval":
                        if (response) this.$emit("saveBudget");
                        this.openBudgetApproveDialog();
                        break;
                    case "approve":
                        this.$emit("setApprovedOrDeclined", true);
                        break;
                    case "decline":
                        this.$emit("setApprovedOrDeclined", false);
                        break;
                }
            } else this.showConfirmDialog = false;
        },
    },
};
</script>

<style scoped lang="scss">

#budget-button-container{
	float:left;
	width:100%;
	margin-top:10px;
	.export-btn-wrapper{
		margin-top:4px;
		margin-right:4px;
	}
}

.mobile #budget-button-container {
	.export-btn-wrapper{
		width:100%;
		padding:0px 24px;
		float:left;
		margin:0!important;
		.v-btn{
			width:100%;
			max-width:100%;
		}
	}

	.budget-button-wrapper{
		display:block!important;
		width:100%;
		padding:3px 20px;
		div{
			width:100%;
			.v-btn{
				width:100%;
				max-width:100%;
				float:left;
			}
		}
	}
}
</style>