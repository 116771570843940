<template>
    <div>
        <v-col class="pa-5 float-left" cols="12" md="12">
            <h3 class="wizard-section-title">{{ header }} costs</h3>
        </v-col>
        <v-container class="wizard-budget-container container--fluid">
            <div class="budget-section-wrapper elevation-1 mb-5">
                <div class="wizard-container-title">Type of {{ header }} cost</div>
                <!-- <v-row class="wizard-form-title" >
				<v-col 
					class="pa-4 pb-0"
					v-for="(name, index) of colNames" :key="name" 
					cols="10" :md="index == 1 ? 1 : 5"
				>
					
					<span :class="name.includes('Type') ? 'float-left' : 'float-right' ">{{ name }} </span>
				</v-col>
                </v-row>-->

                <div class="wizard-form-text" v-for="(val, key) in stepData" :key="key">
                    <div v-for="item of stepData[key]" :key="item.budget_rowid">
                        <tour-fixed-cost-form :costItem="item" />
                    </div>
                </div>

                <v-col class="primary--text d-flex align-center pa-0 mt-3">
                    <v-btn
                        depressed
                        @click.stop="$bus.$emit('addBudgetItem', { step: step, header: header, typeid: 1 })"
                        color="primary"
                    >
                        <v-icon left>add</v-icon>
                        Add {{ header.toLowerCase() }}
                    </v-btn>
                </v-col>
            </div>
        </v-container>
    </div>
</template>

<script>
import TourFixedCostForm from "./TourFixedCostForm";

export default {
    name: "tourstepfixedcoststep",
    components: {
        TourFixedCostForm,
    },
    props: {
        stepData: Array,
        header: String,
        step: Number,
    },
    data: () => ({
        colNames: [],
    }),

    created() {
        //this.$logger(`stepData in step${this.step}`, this.stepData);
        this.colNames = [`Type`, "", "Cost"];
    },
};
</script>

<style>
</style>