<template>
    <div style="width: 100%; margin-top: 60px">
        <confirm-dialog
            v-if="isMissingPrerequisite"
            :options="dialogOptions"
            :dialog="isMissingPrerequisite"
            @confirmDialog="
                (response) => {
                    isMissingPrerequisite = false;
                    $router.go(-1);
                }
            "
        />
        <v-expansion-panels accordion v-if="dataLoaded">
            <v-form
                ref="form"
                style="width: 100%;"
                v-model.lazy="formIsValid"
                lazy-validation
            >
                <v-expansion-panel
                    v-for="(fields, title) in metaData"
                    :key="title"
                >
                    <v-expansion-panel-header v-if="title != '_other'">{{ title }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <!-- <v-row>
                                <v-col
                                    v-if="title != '_other'"
                                    cols="12"
                                    md="12"
                                    class="float-left"
                                >
                                    <h1 class="header">{{ title }}</h1>
                                </v-col>
                            </v-row> -->
                        <v-row>
                            <v-col
                                v-for="field of fields"
                                :key="field.COLUMN_NAME"
                                cols="12"
                                md="12"
                            >
                                <general-form-field
                                    :field="field"
                                    :formItem="formItems[field.COLUMN_NAME]"
                                    :fk_tables="field.DATA_TYPE == 'multiselect' ? fk_tables : null"
                                    @form_updated="(form_item) => formItems[field.COLUMN_NAME] = form_item"
                                    @setFkTableAdd="(key, item) => addToFkTable(key, item)"
                                    @setFkTableRemove="(key, item) => removeFromFkTable(key, item)"
                                    @openCreateNewWindow="(field) => openCreateNewWindow(field)"
                                />
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-form>
        </v-expansion-panels>
    </div>
</template>

<script>
import GeneralFormField from "./GeneralFormField"
import GeneralFormMixin from "./GeneralFormMixin.js"
import { mapGetters } from "vuex";
import ApiObject from "@/models/ApiObject";
import ObjectFactory from "@/models/ObjectFactory.js";
import Loader from "@/components/Loader";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";

class ConfirmOptions {
    constructor({
        type = "confirm",
        text = "",
        btnText = "Confirm",
        subText = "",
        secBtnText = "",
        denyBtnText = "Cancel",
    }) {
        this.type = type;
        this.text = text;
        this.subText = subText;
        this.btnText = btnText; // YES OPTION
        this.secBtnText = secBtnText; // NO OPTION
        this.denyBtnText = denyBtnText; // Cancel option
    }
}

export default {
    name: "generalform",
    props:{
        expandVersion:Boolean
    },
    mixins: [
        GeneralFormMixin
    ],
    components: {
        GeneralFormField,
        Loader,
        ConfirmDialog,
    },
    // props: {
    //     items: Object,
    //     objectRoute: String,
    //     metaParam: String,
    //     options: Object,
    //     isSliderDataSavePending: Boolean,
    // },
    // data: () => ({
    //     dataLoaded: false,
    //     concerts: [],
    //     formItems: {},
    //     formIsValid: false,
    //     metaFields: {},
    //     dateMenu: {},
    //     fk_table_selected_items: {},
    //     fk_tables: {},
    //     isMissingPrerequisite: false,
    //     dialogOptions: {},
    //     originalData: null,
    //     originalItems: null,
    //     noChangesMade: true,
    //     concertToLink: "",
    //     concertsToBeRemoved: [],
    //     concertsToBeAdded: [],
    //     originalConcerts: [],
    //     linkedConcerts: [],
    //     startCompare: false,
    // }),
    // computed: {
    //     ...mapGetters({
    //         userId: "user/userId",
    //     }),
    // },
    // watch: {
    //     formItems: {
    //         handler: function (v) {
	// 			console.log(v)
	// 			if (
	// 				JSON.stringify(this.formItems) != JSON.stringify(this.originalItems)
	// 			) {
	// 				console.log("changes made");
	// 				this.noChangesMade = false;
	// 			} else {
	// 				this.noChangesMade = true;
	// 				console.log("no changes made");
	// 			}
    //         },
	// 		deep: true,
    //     },
    // },
    async created() {
        // console.log("created");
        // this.dialogOptions = new ConfirmOptions({
        //     text: "Missing prerequisites",
        //     btnText: "OK",
        //     subText:
        //         "You need to create at least one organization/person > venue > artist > concert",
        // });
        // await this.prepareForm();
        // console.log(this.metaFields);
        // if (this.metaFields["Linked Concerts"]) {
        //     this.concerts = this.metaFields["Linked Concerts"][0].objects;
        // }

        // if (this.formItems.concerts) {
        //     this.originalConcerts = this.formItems.concerts.reduce((a, b) => {
        //         a.push(b.concertid);
        //         return a;
        //     }, []);
        // }

    },
    methods: {
        closeEditDrawer() {
            this.$emit("closeEditDrawer");
        },
    },
    //     async setData() {
    //         this.originalItems = JSON.parse(JSON.stringify(this.formItems));
    //         if (this.concertsToBeAdded.length !== 0)
    //             this.setLinkedConcert(this.concertsToBeAdded);
    //         if (this.concertsToBeRemoved.length !== 0)
    //             this.unSetLinkedConcerts(this.concertsToBeRemoved);
    //         this.fixDropDownValues();
    //         this.fixTickets();
    //         this.$emit("setData", this.formItems);
    //         this.noChangesMade = true;
    //     },
    //     async setLinkedConcert(concertids) {
    //         console.log("setlinkedconcert function");
    //         concertids.forEach(async (id) => {
    //             try {
    //                 const response = await this.$PutService(
    //                     this.$getRoute("concerts").main_route,
    //                     {
    //                         concertid: id,
    //                         tourid: this.$route.params.id,
    //                     }
    //                 );
    //                 console.log(response);
    //             } catch (err) {
    //                 console.log(err);
    //             }
    //         });
    //     },
    //     async unSetLinkedConcerts(concertids) {
    //         concertids.forEach(async (id) => {
    //             try {
    //                 const response = await this.$PutService(
    //                     this.$getRoute("concerts").main_route,
    //                     {
    //                         concertid: id,
    //                         tourid: "0",
    //                     }
    //                 );
    //                 console.log(response);
    //             } catch (err) {
    //                 console.log(err);
    //             }
    //         });
    //     },
    //     checkIfPrivateOrIfSold() {
    //         this.formItems.private_userid > ""
    //             ? (this.makePrivate = true)
    //             : (this.makePrivate = false);
    //         this.formItems.sold_status === "1"
    //             ? (this.sold = true)
    //             : (this.sold = false);
    //         this.aisle_seating =
    //             this.formItems.aisle_seating == "1" ? true : false;
    //         this.flex = this.formItems.flex == "1" ? true : false;
    //     },
    //     async fixDropDownValues() {
    //         for (let fk of this.formItems.fk_objects) {
    //             let table_fk_key = fk.table_fk_key;
    //             let fk_key = fk.fk_key;
    //             if (
    //                 typeof this.formItems[table_fk_key] === "object" &&
    //                 this.formItems[table_fk_key] !== null
    //             ) {
    //                 this.formItems[table_fk_key] = this.formItems[table_fk_key][
    //                     fk_key
    //                 ];
    //             }
    //         }
    //     },

    //     async linkConcert() {
    //         /**
    //          * this.concertToLink is link-to-concerts dropdown' v-model.
    //          * We get the concertid and title from that v-model whenever a concert is picked.
    //          */
    //         const { concertid, title } = this.concertToLink;

    //         /**
    //          * 	To see if the date of the concert added is longer than the actual tour
    //          * 	we need to GET the concert and look for the dev_min_date and dev_max_date.
    //          *	If it is passed the tour date, we need to prolong the tourdate.
    //          */
    //         const {
    //             data: { dev_min_date, dev_max_date },
    //         } = await this.$GetService(
    //             `${this.$getRoute("concerts").main_route}?id=${concertid}`
    //         );

    //         // ONLY PUSH ID IF ITS NOT ADDED AND IS NOT IN THE ORIGINAL DATA
    //         if (
    //             !this.concertsToBeAdded.includes(concertid) &&
    //             !this.originalConcerts.includes(concertid)
    //         ) {
    //             this.concertsToBeAdded.push(concertid);
    //         }

    //         /**
    //          * As mentioned above. Here we use the 'setTourDates' function to see if
    //          * the added concert date is longer than the tourdate.
    //          * If so, we change the dates of the tour which is saved when the user press
    //          * the save button.
    //          */
    //         this.setTourDates(dev_min_date, dev_max_date);

    //         /**
    //          * If the user has changed his/her mind and adds the tour after removed.
    //          * Remove the concert from the concertsToBeRemoved array.
    //          */
    //         this.concertsToBeRemoved.forEach((id, i) => {
    //             if (id === concertid) this.$delete(this.concertsToBeRemoved, i);
    //         });

    //         /**
    //          * If no concerts is added on tour, we need to create the concerts array,
    //          */
    //         if (!this.formItems.concerts) this.formItems.concerts = [];

    //         /**
    //          * Add the concert linked to the concertsarray. We do this so the user can see the updated list.
    //          */
    //         this.formItems.concerts.push({
    //             concertid: concertid,
    //             title: title,
    //             dev_min_date,
    //             dev_max_date,
    //         });
    //         /**
    //          * Remove the concert linked from the dropdown, so the user cant add duplicates.
    //          */
    //         this.concerts.forEach((concert, i) => {
    //             if (concert.concertid === concertid)
    //                 this.$delete(this.concerts, i);
    //         });
    //     },
    //     async unLinkConcert(concert, index) {
    //         // DESTRUCTURE OBJECT
    //         console.log(concert);

    //         const { concertid, title, dev_min_date, dev_max_date } = concert;

    //         // IF ARRAY DO NOT HAVE ID, PUSH ID
    //         if (!this.concertsToBeRemoved.includes(concertid))
    //             this.concertsToBeRemoved.push(concertid);

    //         // CHECK IF ID IS IN CONCERTSTOBEADDED ARRAY, IF SO, REMOVE
    //         this.concertsToBeAdded.forEach((id, i) => {
    //             if (id === concertid) this.$delete(this.concertsToBeAdded, i);
    //         });

    //         // ADD CONCERT TO DROPDOWN WHEN REMOVED
    //         this.concerts.push({
    //             concertid: concertid,
    //             title: title,
    //             dev_min_date,
    //             dev_max_date,
    //         });

    //         // REMOVE SELF FROM LINKED CONCERTS
    //         this.$delete(this.formItems.concerts, index);

    //         this.concertToLink = "";

    //         this.resetTourDates();
    //     },
    //     setTourDates(startDate, endDate) {
    //         if (
    //             startDate &&
    //             new Date(startDate) < new Date(this.formItems.startdate)
    //         )
    //             this.formItems.startdate = startDate?.substr(0, 10);
    //         if (endDate && new Date(endDate) > new Date(this.formItems.enddate))
    //             this.formItems.enddate = endDate?.substr(0, 10);
    //     },
    //     resetTourDates() {
    //         // FIND ALL MIN DATES FROM DATELIST AND REDUCE TO ONE ARRAY
    //         const MIN_DATES = this.formItems.concerts.reduce(
    //             (total, concert) => {
    //                 total.push(
    //                     new Date(
    //                         `${concert.dev_min_date.substr(0, 10)} 12:00:00`
    //                     )
    //                 );
    //                 return total;
    //             },
    //             []
    //         );

    //         // FIND ALL MAX DATES FROM DATELIST AND REDUCE TO ONE ARRAY
    //         const MAX_DATES = this.formItems.concerts.reduce(
    //             (total, concert) => {
    //                 total.push(
    //                     new Date(
    //                         `${concert.dev_max_date.substr(0, 10)} 12:00:00`
    //                     )
    //                 );
    //                 return total;
    //             },
    //             []
    //         );

    //         // GET EARLIEST DATE
    //         const MIN_DATE = new Date(Math.min(...MIN_DATES))
    //             .toISOString()
    //             .substring(0, 10);

    //         // GET LATEST DATES
    //         const MAX_DATE = new Date(Math.max(...MAX_DATES))
    //             .toISOString()
    //             .substring(0, 10);

    //         // ASSIGN DEFAULT DATES
    //         this.formItems.startdate = MIN_DATE;
    //         this.formItems.enddate = MAX_DATE;

    //         console.log("formItems.startdate", this.formItems.startdate);
    //         console.log("formItems.enddate", this.formItems.enddate);
    //     },
    //     async fixTickets() {
    //         const maxDatesArray = [
    //             this.formItems.date,
    //             this.formItems.date_end,
    //         ];
    //         const minDatesArray = [];

    //         const pushDateIfSet = (...dates) => {
    //             dates.map((date) => (date ? minDatesArray.push(date) : null));
    //         };

    //         pushDateIfSet(
    //             this.formItems.on_sale,
    //             this.formItems.startdate_spotify,
    //             this.formItems.startdate_fan_club,
    //             this.formItems.startdate_local_presale,
    //             this.formItems.startdate_amex
    //         );

    //         const minDate = new Date(
    //             Math.min(
    //                 ...minDatesArray.map((date) =>
    //                     new Date(date).setHours(0, 0, 0, 0)
    //                 )
    //             )
    //         );
    //         const maxDate = new Date(
    //             Math.max(
    //                 ...maxDatesArray.map((date) =>
    //                     new Date(date).setHours(0, 0, 0, 0)
    //                 )
    //             )
    //         );

    //         let ticketObjects = [];

    //         this.formItems.tickets.forEach((ticket) => {
    //             if (ticket.ticket_sales) {
    //                 ticket.ticket_sales.forEach((sales) => {
    //                     if (
    //                         new Date(sales.date).setHours(0, 0, 0, 0) >=
    //                             maxDate ||
    //                         new Date(sales.date).setHours(0, 0, 0, 0) <= minDate
    //                     )
    //                         ticketObjects.push(sales);
    //                 });
    //             }
    //         });
    //     },
    //     setFkTable(tableKey) {
    //         let selectedItems = this.fk_table_selected_items[tableKey];
    //         const tableCol = this.fk_tables[tableKey].tableCol;

    //         if (selectedItems.length == 0) tableCol.clear();
    //         else {
    //             if (tableCol.length() > selectedItems.length) tableCol.clear();
    //             for (let item of selectedItems) {
    //                 if (!tableCol.isInCollection(item)) {
    //                     let obj = {};
    //                     let table = this.fk_tables[tableKey];
    //                     obj[table.FK_NAME] = item[table.FK_NAME];
    //                     obj[table.fk_table_pk_field] =
    //                         item[table.fk_table_pk_field];
    //                     tableCol.addToCollection(obj);
    //                 }
    //             }
    //         }
    //         console.log(this.fk_tables);
    //     },
    //     async prepareForm() {
    //         this.formItems = JSON.parse(JSON.stringify(this.items));
    //         console.log("formitems set");

    //         // fix fk_objects for form
    //         if (this.items.fk_objects) {
    //             for (let obj of this.items.fk_objects) {
    //                 if (this.items[obj.table_fk_key]) {
    //                     this.formItems[obj.table_fk_key] = this.items[
    //                         obj.table_fk_key
    //                     ][obj.fk_key];
    //                 }
    //             }
    //         }

    //         this.originalItems = JSON.parse(JSON.stringify(this.formItems));
    //         console.log("original items set");
	// 		console.log(JSON.stringify(this.formItems) == JSON.stringify(this.originalItems))

    //         console.log(this.objectRoute);
    //         let metaObj = await this.$ObjectFactory.BuildObject({
    //             endpoint: this.objectRoute,
    //         });
    //         console.log(this.metaParam);
    //         metaObj = await metaObj.getMetaFields(this.metaParam);
    //         console.log("GENERAL FORM METAOBJ");
    //         console.log(metaObj);
    //         for (let key in metaObj) {
    //             if (key != "pk_field" && key != "fk_objects") {
    //                 if (key != "fk_tables") {
    //                     var propkey =
    //                         metaObj[key].TITLE == ""
    //                             ? "_other"
    //                             : metaObj[key].TITLE;
    //                     if (!this.metaFields[propkey] && propkey != '_other')
    //                         this.metaFields[propkey] = [];
    //                 }
    //                 if (metaObj[key].DATA_TYPE == "date")
    //                     this.dateMenu[metaObj[key].COLUMN_NAME] = false;

    //                 if (this.itemIsSelection(metaObj[key].COLUMN_NAME))
    //                     metaObj[key].DATA_TYPE = metaObj[key].COLUMN_NAME;

    //                 if (key == "fk_tables") {
    //                     this.fk_tables = {};
    //                     for (let table of metaObj[key]) {
    //                         this.metaFields[table.TITLE] = [];
    //                         let apiCol = await this.$ObjectFactory.BuildCollection({
    //                             endpoint: table.fk_table_name,
    //                             metaFields: {
    //                                 fields: [table.FK_NAME],
    //                                 pk_field: table.fk_table_pk_field,
    //                             },
    //                         });

    //                         this.fk_tables[table.fk_table_name] = {
    //                             FK_NAME: table.FK_NAME,
    //                             fk_table_pk_field: table.fk_table_pk_field,
    //                             tableCol: apiCol,
    //                         };

    //                         for (let item of table.objects)
    //                             item["fk_table_name"] = table.fk_table_name;
                           
    //                         this.metaFields[table.TITLE].push(table);
    //                     }
    //                 } else {
    //                     if(this.metaFields[propkey])
    //                         this.metaFields[propkey].push(metaObj[key]);
    //                 }
    //             }
    //         }
    //         console.log("GENERAL FORM METAFIELDS");
    //         console.log(this.metaFields);
    //         this.dataLoaded = true;
    //     },
    //     formatLabel(COLUMN_NAME) {
    //         return this.options.getLabelRule(COLUMN_NAME);
    //     },
    //     itemIsSelection(COLUMN_NAME) {
    //         return this.options.isSelect(COLUMN_NAME);
    //     },
    //     showAutoComplete(field) {
    //         return (
    //             field.DATA_TYPE == "fk_object" ||
    //             this.options.showAutoComplete(field)
    //         );
    //     },
    //     autoItems(field) {
    //         if (field.DATA_TYPE == "fk_object") return field?.DROPDOWN;
    //         else return this.options.getFormList(field.COLUMN_NAME);
    //     },
    //     autoTextAndValue(field, customKey = "DATA_TYPE") {
    //         // if (
    //         //     field[customKey] == "fk_object" &&
    //         //     (!field.DROPDOWN || field.DROPDOWN.length === 0)
    //         // )
    //         //     this.isMissingPrerequisite = true;

    //         return this.options.getAutoTextAndValue(field, customKey);
    //     },
    //     validate(emit = true) {
    //         console.log("INNE");
    //         if (this.$refs.form.validate()) {
    //             if (this.items.fk_objects) {
    //                 for (let obj of this.items.fk_objects)
    //                     if (this.formItems[obj.table_fk_key] == undefined)
    //                         this.formItems[obj.table_fk_key] = "";
    //             }
    //             this.originalItems = JSON.parse(JSON.stringify(this.formItems));
    //             if (emit) this.$emit("isValid", this.formItems, this.fk_tables);
    //             return true;
    //         } else {
    //             this.$emit("requiredFieldsError");
    //             return false;
    //         }
    //     },
    //     getFormItems() {
    //         return this.formItems;
    //     },
    // },
    beforeDestroy() {
        console.log("destroyed TEST");
        console.log(this.formItems);
        console.log(this.metaFields);
    },
};
</script>

<style lang="scss" scoped>
.close-drawer {
    i {
        transform: rotate(180deg);
        margin-right: 3px;
    }
    &:hover {
        background: $border-color !important;
    }
}

.expansion-panel-container {
    width: 100%;
    float: left;
}

.v-expansion-panel::before {
    box-shadow: none;
}

.v-item-group {
    border-bottom: 1px solid $border-color;
}

.v-expansion-panels {
    button {
        margin-left: auto !important;
    }
    border-radius: 0px;
    overflow: auto;
    max-height: calc(100vh - 60px);
    margin-top: 60px;
}

.v-expansion-panel-header {
    text-transform: uppercase;
    font-weight: 500;
    color: #666;
    height: 60px;
    transition: all 0.2s ease;
    &:hover {
        background:#f4f4f4;
    }
}

#linked-concerts-wrapper {
    float: left;
    margin-top: 15px;
    .linked-concert-wrapper {
        display: flex;
        .linked-concert {
            margin-bottom: 15px;
            border-radius: 30px;
            padding: 7px 7px 7px 20px;
            align-items: center;
            width: 100%;
            float: left;
            background: #f0f0f0;
            display: flex;
            justify-content: space-between;

            .concert-name {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    margin-bottom: 20px;
}
</style>