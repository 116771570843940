<template>
   <div class="summary-total">
      <v-col cols="12" md="12" class="pa-0 pt-0">
         <span>{{ title }}</span>
         <span class="float-right">{{ formattedValue(value) }}</span>
      </v-col>
   </div>
</template>

<script>
export default {
   name:'summarytotalrow',

   props: {
      revenue: Boolean,
      title: String,
      value: Number,
      currency: String,
   },

   methods: {
      formattedValue(value){
         let val = this.$thousandSeperator(Math.round(value))
         let presufVal = `${this.currency.match(/[$€£]/) ? this.currency : ''} ${val} ${this.currency.match(/[^$€£]/) ? this.currency : ''}`
         return !this.revenue ? `${value > 0 ? '-' : '' } ${presufVal}` : presufVal
      }
   }

}
</script>

<style>



</style>