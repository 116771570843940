<template>
    <v-dialog v-model="dialog" persistent min-width="400" max-width="500">
        <v-card>
            <v-card-title class="header">BPC Export</v-card-title>
            <v-col cols="12" md="12" class="px-7 float-left" style="font-weight:500;color:#666;">
                Export confirmed concerts within these dates
            </v-col>
            <v-card-text class="float-left px-3">

                <DatePicker
                    @setEndDate="setEndDate"
                    @setStartDate="setStartDate"
                    @getSalesData="getConcertData"
                    @setBpcType="setBpcType"
                    :defaultDates="{ startDate: null, endDate: null }"
                    :showBpcButtons="true"
                    :showButton="false"
                    :maxDate="maxDate"
                    class="float-left"
                    style="width:100%;justify-content:space-between;"

                />
            </v-card-text>
            <v-col cols="12" md="12" class="px-6 py-0 float-left result-wrapper"> 
                <Loader v-if="isLoading" :loaderStyle="'free'" />
                <div class="result not-found" v-if="concertsFoundText == '0' && showResult && !isLoading">
                    No shows found
                </div>
                <div class="result found"  v-if="concertsFoundText != '0' && showResult && !isLoading">
                    Shows (with a budget) found: {{ !isLoading ? concertsFoundText : "" }} 
                </div>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancelDialog">Cancel</v-btn>
                <v-btn color="primary" :disabled="!dataIsReady || collectionCount == 0" text @click="confirmDialog()">Download</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import ObjectFactory from "@/models/ObjectFactory";
import Loader from "@/components/Loader.vue";

export default {
    props: {
        dialog: Boolean,
    },
    components: {
        DatePicker,
        Loader,
    },
    data: () => ({
        maxDate: "",
        isLoading: false,
        dataIsReady: false,
        startDate: null,
        endDate: null,
        dummyConcert: null,
        json_data: null,
        collectionCount: 0,
        showResult:false,
        bpc_type: "FCST",
    }),

    computed: {
        concertsFoundText() {
            return !this.dataIsReady ? "Get data..." : this.collectionCount;
        },
    },

    async created() {
        this.dummyConcert = await this.$ObjectFactory.BuildConcert({ concertid: -1 });
        this.dummyConcert.budgets = null;
    },

    methods: {
        async getConcertData() {
            // collect data
            if (this.startDate && this.endDate) {
                this.isLoading = true;
                let concert = Object.assign(Object.create(Object.getPrototypeOf(this.dummyConcert)), this.dummyConcert);
                let concertCol = await this.$ObjectFactory.BuildCollection({ endpoint: "concerts", fromDummy: concert });
                await concertCol.fetchAllOneByOne({ filterRoute: `?status=Confirmed&fromdate=${this.startDate}&todate=${this.endDate}` });

                for (let concert of concertCol.collection)
                    if (!concert?.budgets || (!concert?.budgets?.budget_rows && !concert?.budgets?.budget_tickets && concert.budgets.status >= 0))
                        concertCol.removeFromCollection(concert);

                this.collectionCount = concertCol.collection.length;
                console.log(concertCol);

                this.json_data = concertCol.getCsvData(this.bpc_type);
                this.dataIsReady = true;
                this.isLoading = false;
                this.showResult = true
            }
        },

        resetData() {
            this.json_data = null;
            this.dataIsReady = false;
            this.collectionCount = 0;
        },

        setStartDate(payload) {
            this.resetData();
            this.startDate = payload;
        },
        setEndDate(payload) {
            this.resetData();
            this.endDate = payload;
        },

        setBpcType(payload) {
            this.bpc_type = payload == "forecast" ? "FCST" : "BUD";
            this.$nextTick(() => {
                this.getConcertData();
            });
        },

        confirmDialog() {
            // download
            let blob = new Blob([this.json_data], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "testing.csv";
            link.click();
        },

        cancelDialog() {
            this.$emit("cancelDialog");
        },
    },
};
</script>

<style lang="scss" scoped>
.header{
    font-size:17px!important;
}


.result-wrapper{
    padding:5px;
    float:left;
    width:100%;
    .result{
        text-align: center;
        padding:5px 10px;
        font-weight:500;
        font-size:14px;
        border-radius:5px;
        width:100%;
        text-transform: uppercase;
        &.not-found{
            background:#f561612e;
            color:#f56161fc;
        }
        &.found{
            background:#306aa138;
            color:#0060bc;
        }
    }
}
</style>
