<template>
  <div class="update-alert-wrapper" :class="{show: updateFound}">
   <div class="update-alert"
      type="info"
      dismissible
      icon="none"
    >
    <v-spacer></v-spacer>
    <v-icon>info</v-icon>
     New data found, press the refresh button to update
     <v-btn small color="info" class="refresh-btn" :loading="isRefreshPending" @click="refreshData">Refresh</v-btn>
     <v-spacer></v-spacer>
     <v-icon @click="closeUpdateAlert">close</v-icon>
    </div>
  </div>
</template>

<script>
   export default {
      name:'UpdateAlert',
      props:{
         updateFound:Boolean,
         isRefreshPending:Boolean
      },
      methods:{
         refreshData(){
            this.$emit('getTableData')
         },
         closeUpdateAlert(){
            this.$emit('closeUpdateAlert')
         }
      }
   }
</script>

<style lang="scss" scoped>

.update-alert-wrapper{
   position:fixed;
   width:100%;
   top:64px!important;
   left:0;
   z-index:2;
   transition: all 0.2s ease;
   opacity:0;
   .update-alert{
      height:50px;
      border-radius:0px;
      padding:20px 0px;
      display:flex;
      justify-content: center;
      color:#fff;
      align-items: center;
      background-color:#000000a8 !important;   
      i{
         color:#fff;
         margin-right:15px;
      }
      .refresh-btn{
         margin-left:20px;
      }
   }
}

.show{
   top:109px!important;
   opacity:1!important;
}

</style>