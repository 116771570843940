<template>
    <div id="search" v-if="showSearchField">
        <div :class="{ filler: isMobile }">
            <input
                v-model="input"
                placeholder="Search anything"
                @focus="getSearchResult"
                class="search-field"
                v-on-clickaway="hideResults"
                prepend-icon="search"
                hide-details
            />
            <v-icon class="search-icon">search</v-icon>
            <v-icon class="clear-icon" @click="clearInput" v-if="this.input.length > 0">clear</v-icon>
            <v-icon class="close-icon" @click="showSearchField = false" v-if="isMobile">clear</v-icon>
        </div>
        <div class="search-result-wrapper" v-show="Object.keys(result).length > 0">
            <div class="search-result" v-if="Object.keys(result).length > 0">
                <div v-for="(items, key) in result" :key="items">
                    <div class="result-item-wrapper">
                        <div class="type">
                            <div class="type-name">{{ key }}</div>
                        </div>
                        <div class="items">
                            <router-link
                                v-for="item in items"
                                v-ripple
                                :key="item"
                                style="width: 100%; float: Left"
                                :to="`/${item.endpoint}/edit/${item.id}`"
                            >
                                <div class="type-icon-wrapper">
                                    <v-icon v-if="item.type === 'concerts'">speaker</v-icon>
                                    <v-icon v-if="item.type === 'tours'">directions_bus</v-icon>
                                    <v-icon v-if="item.type.includes('artist')">mdi-account-music</v-icon>
                                    <v-icon v-if="item.type === 'people'">group</v-icon>
                                    <v-icon v-if="item.type === 'venues'">mdi-stadium</v-icon>
                                    <v-icon v-if="item.type === 'organizations'">mdi-account-group</v-icon>
                                </div>
                                <span v-if="item.type === 'artist in concert' || item.type === 'artist in tour'">
                                    {{ item.text }} - {{ item.before }}
                                    <span class="highlight">{{ item.src }}</span>
                                    {{ item.after }}
                                </span>
                                <span v-else>
                                    {{ item.before }}
                                    <span class="highlight">{{ item.src }}</span>
                                    {{ item.after }}
                                </span>
                                <v-spacer></v-spacer>
                                <v-icon>keyboard_arrow_right</v-icon>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
import { mapGetters } from "vuex";
export default {
    name: "search",
    directives: {
        onClickaway: onClickaway,
    },
    props: {
        showSearchField: Boolean,
    },
    data: () => ({
        input: "",
        result: {},
        prevResult: [],
    }),
    watch: {
        input(v) {
            if (v.length > 2) {
                this.getSearchResult(v);
            } else this.result = {};
        },
        "$route.path": {
            handler: function() {
                this.hideResults();
                if (this.isMobile) {
                    this.$emit("hideSearchField");
                }
            },
        },
    },
    created() {},

    methods: {
        async getSearchResult(searchInput) {
            if (this.input.length > 2 && this.result !== this.prevResult) {
                searchInput = this.input.replace(/\s+/g, "").trim();
            } else if (searchInput.length > 2) {
                searchInput = searchInput.replace(/\s+/g, "").trim();
            } else {
                return;
            }

            searchInput = searchInput.replace(/%/g, "");
            searchInput = encodeURIComponent(searchInput);

            if (searchInput.length > 2) {
                const response = await this.$GetService(`search?str=${searchInput}`);
                if (!response.data) return;
                this.result = {};
                for (let item of response.data) {
                    if (!this.result[item.type]) {
                        this.result[item.type] = [];
                    }
                    item = this.highlighSearch(item, searchInput);

                    this.result[item.type].push(item);
                }
                this.prevResult = JSON.parse(JSON.stringify(this.result));
            }
        },
        highlighSearch(item, v) {
            v = decodeURIComponent(v);
            v = v.toLowerCase();
            if (item.type != "artist in concert" && item.type != "artist in tour") {
                var startpos = item.text.toLowerCase().indexOf(v);
                item.before = item.text.substr(0, startpos);
                item.src = item.text.substr(startpos, v.length);
                item.after = item.text.replace(item.before + item.src, "");
            } else {
                var startpos = item.found.toLowerCase().indexOf(v);
                item.before = item.found.substr(0, startpos);
                item.src = item.found.substr(startpos, v.length);
                item.after = item.found.replace(item.before + item.src, "");
            }
            return item;
        },
        hideResults() {
            this.result = {};
        },
        clearInput() {
            this.input = "";
            this.result = {};
        },
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
#search {
    position: relative;
    z-index: 100;
    .search-icon,
    .clear-icon {
        position: absolute;
        z-index: 101;
        color: $accent-color;
    }
    .search-icon {
        left: 7px;
        top: 6px;
    }
    .clear-icon {
        right: 7px;
        top: 9px;
        font-size: 18px;
    }
}

.highlight {
    background-color: #5893cb21;
}

.search-field {
    border: 1px solid $border-color;
    padding: 5px 10px 5px 35px;
    min-width: 500px;
    border-radius: 3px;
    transition: all 0.2s ease;
    z-index: 99;
    position: relative;
    background: #f8f8f8;
    &:focus {
        outline: none;
        background-color: #f3f3f3;
    }
}

.mobile #search {
    position: fixed;
    top: 50px;
    left: 0px;
    z-index: 100;
    width: 100%;
}

.mobile #search {
    .search-icon {
        top: 21px !important;
        left: 20px !important;
    }
    .clear-icon {
        right: 55px !important;
        top: 23px !important;
    }
    .close-icon {
        z-index: 99;
        margin-left: 10px;
    }
}

.mobile .filler {
    background: #fff;
    padding: 10px;
    display: flex;
    border-radius: 0px;
    box-shadow: 0px 4px 5px #00000018;
    float: left;
    width: 100%;
}

.mobile .search-result-wrapper {
    width: 100%;
    z-index: 20;
    padding-top: 70px;
    margin: 0px;
    left: 0px;
    top: 0px;
    box-shadow: 0px 12px 12px #0000003b;
}

.mobile .search-field {
    flex: 1;
    max-width: 100%;
    min-width: 85%;
    padding: 10px 10px 10px 40px !important;
}

.result-item-wrapper {
    float: left;
    width: 100%;
    border-bottom: 1px solid $border-color;
    padding: 5px 0px;
}

.type {
    padding: 10px 10px 0px 20px;
    float: left;
    width: 100%;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    color: $font-color;
    display: flex;
    align-items: center;
    i {
        float: left;
        margin-right: 10px;
        font-size: 21px;
        color: $font-color;
    }
    .type-name {
        float: left;
    }
    &:nth-child(2) {
        border-top: none;
    }
}

.items {
    background: #fff;
    border: none;
    float: left;
    width: 100%;
    padding: 10px;
    a {
        padding: 15px 15px 15px 15px;
        font-size: 0.9rem;
        text-decoration: none;
        color: $font-color-pale;
        font-weight: 500;
        display: flex;
        align-items: center;
        border-radius: 10px;
        transition: all 0.2s ease;
        .type-icon-wrapper {
            min-width: 25px;
            min-height: 25px;
            background: $primary;
            border-radius: 30px;
            display: flex;
            float: left;
            margin-right: 10px;
            i {
                color: #fff;
                margin: auto;
                font-size: 15px;
            }
        }
        &:hover {
            background: #ebebeb;
        }
    }
}

.search-result-wrapper {
    position: absolute;
    width: 510px;
    border-radius: 3px;
    height: auto;
    overflow: hidden;
    background: #fff;
    margin-left: -5px;
    top: -5px;
    padding-top: 45px;
    box-shadow: 0px 0px 6px #00000030;
}

.search-result {
    max-height: 500px;
    float: left;
    width: 100%;
    overflow: auto;
    overscroll-behavior: contain;
}
</style>
