export const routes = {
   artists: { main_route: 'artists' },
   budgets: { main_route: 'budgets', tickets: 'budget_tickets', rows: 'budget_rows' },
   concerts: { main_route: 'concerts', label:'Shows', idPath:'concertid'  },
   favorites: { main_route: 'favorites' },
   organizations: { main_route: 'organizations', label:"Organizations" },
   people: { main_route: 'people',  label:'People' },
   todos: { main_route: 'todos' },
   user_filters: { main_route: 'user_filters' },
   user_selections: { main_route: 'user_selections' },
   users: { main_route: 'users' },
   tickets: { main_route: 'tickets', sales: 'ticket_sales', api: 'ticket_api' },
   ticketing: { main_route: 'ticketing'},
   venues: { tickets: 'venue_tickets', rows: 'venue_budget_rows', main_route: 'venues' },
   marketing: { main_route: 'marketing', marketing_data: 'marketing_data' },
   wall: { main_route: 'wall_events' },
   comparableprojects: { main_route: 'comparable_concerts', concerts: 'comparable_concerts_concerts', label:"Comparable projects" },
   tours: { main_route: 'tours' },
   invoices: { main_route: 'invoices' },
   sales: { main_route: 'sales', label:"Sales" }
}
export function getRoute(path) {
   return routes[getView(path)]
}

export function getSubRoute(path, subpath) {
   return routes[getView(path)][subpath]
}

export function getView(path) {
   const [route] = path.split`/`.filter(v => v)
   return route
}

export function getEditRoute(path) {
   return getView(path) + "?id=" + getRouteId(path)
}
export function getRouteId(path) {
   return path.substr(path.lastIndexOf('/') + 1)
}