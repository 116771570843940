<template>
    <div>
        <v-row>
            <v-col cols="12" md="4">
                <v-text-field class="" outlined dense label="Section name" hide-details v-model="ticketItem.section_name" required></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field
                    hide-details
                    outlined
                    type="number"
                    dense
                    label="Default price"
                    v-model="ticketItem.default_price"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field outlined type="number" hide-details dense label="Default capacity" v-model="ticketItem.default_capacity" required></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-checkbox
                    true-value="1"
                    class="pt-2 ma-0 ml-3"
                    false-value="0"
                    v-model="ticketItem.platinum"
                    color="primary"
                    hide-details
                    label="Platinum"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        ticketItem: Object,
    },

    created() {},

    data: () => ({}),
};
</script>

<style></style>
