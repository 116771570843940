<template>
    <div class="card elevation-1 float-left">
        <v-col cols="12" md="12" class="float-left pa-0">
            <div class="card-title">Select comparable events</div>
        </v-col>
        <v-col cols="12" md="12" class="float-left pa-0">
            <v-col cols="12" md="12" class="float-left d-flex align-center pa-5">
                <div class="pa-0 float-left">
                    <v-select
                        class="float-left pr-3"
                        label="Add event"
                        :items="events"
                        :item-text="valueKey"
                        :item-value="idKey"
                        v-model="selectedEvent"
                        hide-details
                        outlined
                        filled
                        dense
                    />
                </div>
                <div class="float-left pa-0">
                    <v-btn
                        icon
                        large
                        @click="() => { this.isLoading = true; $emit('addComparableEvent', selectedEvent); }"
                    >
                        <v-icon>add</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-progress-linear :active="isLoading" indeterminate />
            <v-col
                cols="12"
                md="12"
                class="float-left align-center"
                v-for="event in selectedEvents"
                :key="event.id"
            >
                <event-card
                    :event="event"
                    :titleKey="valueKey"
                    :progressValue="getTicketSoldPercentage(event)"
                    @removeEvent="$emit('removeComparableEvent', event)"
                />
            </v-col>
        </v-col>
    </div>
</template>

<script>
import EventCard from "./EventCard";
import { mapGetters } from 'vuex'

export default {
    name: "EventSelector",

    components: {
        EventCard,
    },

    props: {
        events: Array,
        idKey: String,
        valueKey: String,
        selectedEvents: [],
    },

    data: () => ({
        selectedEvent: {},
        isLoading: false,
    }),

    created() {
        console.log("EventSelector created");
        //this.$logger('events', this.events)
        //this.$logger('selectedEvents', this.selectedEvents)
    },

    watch: {
        selectedEvent(val) {
            console.log(val);
        },
        selectedEvents: {
            handler(val) {
                //console.log(val)
            },
            deep: true,
        },
    },

    methods: {
        getTicketSoldPercentage(event) {
            let totalAvailable = 0;
            let totalSold = 0;
            let percentage = 0;
            if (event.tickets) {
                event.tickets.map((ticket) => {
                    totalAvailable += +ticket.available;
                    if (ticket.ticket_sales) {
                        ticket.ticket_sales.map((sale) => {
                            totalSold += +sale.amount;
                        });
                    }
                });
            }

            setTimeout(() => {
                this.isLoading = false;
            }, 250);

            return totalAvailable > 0
                ? Math.round((totalSold / totalAvailable) * 100)
                : 0;
        },
    },
    computed:{
       ...mapGetters({
          isMobile:'globalstates/isMobile'
       })
    }
};
</script>

<style>
</style>