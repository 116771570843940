<template>
    <div id="calendar" class="elevation-1">
        <v-row class="fill-height">
            <v-col class="px-3 py-0">
                <v-sheet class="sheet">
                    <v-toolbar flat color="white">
                        <v-btn
                            small
                            class="mr-4"
                            color="primary"
                            @click="setToday"
                            depressed
                        >
                            Today
                        </v-btn>
                        <div
                            v-if="!isMobile"
                            style="display: flex; align-items: center"
                        >
                            <v-btn
                                fab
                                text
                                small
                                depressed
                                color="grey darken-2"
                                @click="prev"
                            >
                                <v-icon small>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-toolbar-title
                                :class="{ 'title-mobile': isMobile }"
                                >{{ title }}</v-toolbar-title
                            >
                            <v-btn
                                fab
                                text
                                small
                                depressed
                                color="grey darken-2"
                                @click="next"
                            >
                                <v-icon small>mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                        <v-menu bottom right>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="primary"
                                    v-on="on"
                                    depressed
                                >
                                    <span>{{ typeToLabel[type] }}</span>
                                    <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title>Day</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title>Week</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title>Month</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = '4day'">
                                    <v-list-item-title
                                        >4 days</v-list-item-title
                                    >
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-spacer></v-spacer>
                        <v-autocomplete
                            v-model="pickedDepartment"
                            :items="departments"
                            hide-details
                            filled
                            class="department-filter"
                            dense
                            clearable
                            item-text="name"
                            item-value="name"
                            outlined
                            label="Filter by department"
                        ></v-autocomplete>
                    </v-toolbar>
                    <div v-if="isMobile" class="date-selector">
                        <v-btn
                            fab
                            text
                            small
                            depressed
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-toolbar-title
                            :class="{ 'title-mobile': isMobile }"
                            >{{ title }}</v-toolbar-title
                        >
                        <v-btn
                            fab
                            text
                            small
                            depressed
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                    </div>
                </v-sheet>
                <v-sheet height="600">
                    <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="events"
                        :event-color="getEventColor"
                        :now="today"
                        :type="type"
                        @click:event="gotoEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="updateRange"
                    ></v-calendar>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    props: {
        items: Array,
    },
    data: () => ({
        originalEvents: [],
        pickedDepartment: null,

        focus: "",
        type: "month",
        typeToLabel: {
            month: "Month",
            week: "Week",
            day: "Day",
            "4day": "4 Days",
        },
        start: null,
        concerts: null,
        end: null,
        today: new Date().toISOString().substr(0, 10),
        events: [],
        departments: [],
    }),
    watch: {
        pickedDepartment(department) {
            if (department) {
                this.events = this.originalEvents.filter(
                    (concert) => concert.department === department
                );
                console.log(this.events);
            } else {
                this.events = this.originalEvents;
            }
        },
    },
    mounted() {
        /**
         *  If no concerts
         */
        if(!this.items) return

        this.departments = this.$config.DEPARTMENT_LIST;
        this.$refs.calendar.checkChange();
        this.concerts = JSON.parse(JSON.stringify(this.items));
        this.getEvents();
        this.originalEvents = JSON.parse(JSON.stringify(this.events));
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
        title() {
            const { start, end } = this;
            if (!start || !end) return ""
            const startMonth = this.monthFormatter(start);
            const endMonth = this.monthFormatter(end);
            const suffixMonth = startMonth === endMonth ? "" : endMonth;
            const startYear = start.year;
            const endYear = end.year;
            const suffixYear = startYear === endYear ? "" : endYear;
            const startDay = start.day;
            const endDay = end.day;
            switch (this.type) {
                case "month":
                    return `${startMonth} ${startYear}`;
                case "week":
                case "4day":
                    return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
                case "day":
                    return `${startMonth} ${startDay} ${startYear}`;
            }
            return "";
        },
        monthFormatter() {
            return this.$refs.calendar.getFormatter({
                timeZone: "UTC",
                month: "long",
            });
        },
    },
    methods: {
        gotoEvent({ event }) {
            console.log(event);
            this.$router.push(`/concerts/edit/${event.id}`);
        },
        getEvents() {
            if (!this.concerts) return;
            this.events = [];
            for (let event of this.concerts) {
                if (event.date) {
                    let eventObj = {
                        name: event.title,
                        department: event.department,
                        start: event.date,
                        status: event.status,
                        end: event.date,
                        id: this.$route.path.includes("artists")
                            ? event.rowid
                            : event.concertid,
                        color: "#666",
                    };
                    this.events.push(eventObj);
                }
            }
			console.log(this.events)
        },

        viewDay({ date }) {
            this.focus = date;
            this.type = "day";
        },
        getEventColor(event) {
            const status = event?.status?.toLowerCase();
            switch (status) {
                case "confirmed":
                    return "#55b54a";
                    break;
                case "hold":
                    return "#4a81b5";
                case "unavailable":
                    return "#b5804a";
                case "company gig":
                    return "#b54a99";
                case "offer":
                    return "#49b4ad";
                case "routing":
                    return "#7d49b5";
                case "repetition":
                    return "#aeb54a";
                case "no status": // should not be the case unless data is corrupt
                    return "#ccc";
                default:
                    // decline variations
                    return "#b54a4a";
            }
        },
        setToday() {
            this.focus = this.today;
        },
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        },
        updateRange({ start, end }) {
            this.start = start;
            this.end = end;
        },
    },
};
</script>

<style lang="scss" scoped>
#calendar {
    float: left;
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
}

.department-filter {
    max-width: 250px;
    min-width: 80px;
    margin-left: 10px;
}

.mobile {
    .date-selector {
        width: 100%;
        float: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        border-top: 1px solid #e2e2e2;
    }
}
</style>