export function Ticket(name = '', price = '', capacity = '', venueid = null, venue_ticketid = '', vat = 0, platinum = 0){
	this.section_name = name;
	this.default_price = price;
	this.default_capacity = capacity;
	this.venueid = venueid;
	this.venue_ticketid = venue_ticketid;
	this.vat = vat;
	this.platinum = platinum;
}

export function BudgetPostItem(
		display_name = '', name = '', active = 0, venueid = null, 
		step = '', header = '', sticky = false, venue_budget_rowid = '', 
		value = 0, defType = 1, currency_name = '', rate = '', currency_amount = '', vat = 0, commission = 0){
	this.display_name = display_name
	this.name = name
	this.active = active
	this.value = value
	this.budget_row_typeid = defType
	this.step = step
	this.header = header
	if(sticky == undefined) sticky = false
	this.sticky = sticky
	this.venueid = venueid
	this.venue_budget_rowid = venue_budget_rowid
	this.currency_name = currency_name
	this.currency_rate = rate
	this.currency_amount = currency_amount
	this.vat = vat
	this.commission = commission
	this.calculated_cost = 0;
}

export function CostStep(title = '', subTitle = '', arr = []){
	this.title = title
	this.subTitle = subTitle
	this.arr = arr
}