<template>
    <v-card class="eventcard elevation-1">
        <!-- <v-card-title class="event-title"></v-card-title> -->
        <v-card-text :class="isMobile ? 'pa-5 py-3' : 'py-1'">
            <v-row class="align-center">
                <v-col cols="12" md="3" class="event-title">{{ event[titleKey] }}</v-col>
                <v-col cols="12" md="1" class="event-text">{{ event.date }}</v-col>
                <v-col cols="12" md="2" class="event-text">{{ event.venueid.name }}</v-col>
                <v-col cols="12" md="4" class="event-text">
                    <v-progress-linear
                        rounded
                        height="10"
                        :value="progressValue"
                        :color="event.color"
                    />
                </v-col>
                <v-col cols="12" md="1" class="event-text">{{ progressValue }}% sold</v-col>
                <v-col cols="12" md="1" class="event-text pa-0">
                    <v-btn
                        v-if="isMobile"
                        depressed
                        block
                        color="primary"
                        @click="$emit('removeEvent', event)"
                    >Remove</v-btn>
                    <v-btn v-else fab x-small class="float-right elevation-0 mr-2" color="primary" @click="$emit('removeEvent', event)">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "eventcard",
    props: {
        event: Object,
        titleKey: String,
        progressValue: Number,
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
.eventcard {
    position: relative;
    width: 100%;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;

    .event-title {
        color: $font-color-pale;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 600;
    }
    .event-text {
        color: $font-color-pale;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 400;
    }
}
</style>