<template>
   <v-container class="wizard-budget-container container--fluid">
         <v-col cols="12" md="12" class="pa-0 pb-5">
            <h3 class="wizard-section-title">{{ stepHeader }} costs</h3>
         </v-col>
         <v-spacer/>

      <div class="budget-section-wrapper elevation-1">
         <div class="wizard-container-title">Type of variable cost</div>
         <div 
            class="wizard-form-text" 
            v-for="cost of stepData.costs" :key="cost.id"
         >
         
            <budget-cost-form
               v-if="cost.name.toLowerCase() != 'prs' && cost.name != 'vat' && cost.name != 'ticket_commission'"
               :step="step"
               :costItem="cost"
               @deleteCost="deleteCostItem"
            />
         </div>

         <v-col cols="12" md="12" class="primary--text d-flex align-center pa-0 mt-3">
            <v-btn 
               depressed
               @click.stop="$emit('addCostItem', step, 'Variable costs')"
               color="primary" 

            >
               <v-icon left>add</v-icon>
               Add {{ stepHeader }} cost
            </v-btn>
         </v-col>
      </div>
         <v-col cols="12" md="12" class="pa-0">
            <budget-prs-form
               class="budget-section-wrapper elevation-1"
               v-if="prsLoaded"
               :prs="prs"
               :calculated_cost="prs.calculated_cost"
               :prsTypes="prsTypes"
               @prsToggle="prsToggle"
               @prsChange="prsUpdate"
            />
         </v-col>
   </v-container>
</template>

<script>
import { BudgetPostItem } from '@/models/BudgetObjects.js'
import BudgetCostForm from '../Shared/ConcertCostForm'
import BudgetPrsForm from './BudgetPrsForm'

export default {
   name: 'BudgetStepTwo',

   components: {
      BudgetCostForm,
      BudgetPrsForm,
   },

   props: {
      stepData: Array,
   },

   computed: {
   },

   data: ()=>({
      stepHeader: 'Variable',
      colNames: [
         'Variable cost', 'Type', 'Value', 'Calculated costs'
      ],
      step: 2,
      prs: {},
      prsTypes: ['Sweden', 'Norway', 'Denmark', 'Finland'],
      prsLoaded: false,
      previousPrs: 0
   }),

   async created(){
      console.log('stepTwo')
      console.log(this.stepData)

      this.prs = this.stepData.costs.find( (c) => {
         return c.name.toLowerCase() == 'prs'
      })

      if(this.prs.value < 0)
         this.prs.value = 0
   },

   mounted(){
      console.log('step2 mounted')
      console.log(this.stepData.tickets)
      //this.prs.calculated_cost = calcPrs(this.prs.value, this.stepData.tickets)
      this.prsLoaded = true
      this.$emit('isLoaded')
   },

   watch: {
   },

   methods: {

      async deleteCostItem(costItem){
         if(costItem.budget_rowid != undefined)
            this.stepData.deletedCosts.push(costItem)

         this.$delete(this.stepData.costs, this.stepData.costs.indexOf(costItem))
      },

      // async addCostItem(){
      //    let costItem = new BudgetPostItem()
      //    costItem.step = this.step
      //    costItem.header = 'Variable costs'
      //    costItem.editableName = true
      //    this.stepData.costs.push(costItem)
      // },

      prsToggle(isActive){
         this.prs.active = isActive
         if(isActive)
            this.prs.value = this.previousPrs
         else
            this.prs.value = -1
      },

      prsUpdate(prsType){
         this.prs.value = prsType
         this.previousPrs = prsType
      },
   }
}

</script>

<style lang="scss">

</style>