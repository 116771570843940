<template>
    <v-col cols="12" md="12" class="pa-0 filter-card">
        <div :class="{ disabled: isFilterUsed }">
            <v-col cols="12" md="12" class="pa-0">
                <div class="filter-wrapper" :class="isMobile ? 'pt-6 pb-3' : 'd-flex'" style="position:relative;">
                    <div class="filter-btn d-flex flex-grow-1">
                        <div class="filter-icon">
                            <i class="material-icons">filter_list</i>
                        </div>
                        <div class="filter-name">
                            <span>{{ filter.filter_name }}</span>
                        </div>
                        <div class="currently-selected" v-if="isFilterUsed">
                            Currently Selected
                        </div>
                        <div class="date-created" v-if="!isMobile">
                            <span>{{ filter.date_created.substring(0, 10) }}</span>
                        </div>
                    </div>
                    <div class="actions-wrapper" :class="isMobile ? 'mt-3' : ''">
                        <div class="date-created" v-if="isMobile">
                            <span>{{ filter.date_created.substring(0, 10) }}</span>
                        </div>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom transition="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="filter-btn edit ml-4"
                                    @click="editFilter(filter.filter_obj, filter.filter_name, filter.filter_str)"
                                    v-on="on"
                                    v-bind="attrs"
                                    x-small
                                    icon
                                    color="primary"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit filter</span>
                        </v-tooltip>
                        <v-menu transition="slide-y-transition" bottom right dense min-width="150">
                            <template #activator="{ on: onShareFilter }">
                                <v-tooltip bottom transition="false">
                                    <template #activator="{ on: onShareFilterToolTip }">
                                        <v-btn
                                            v-on="{
                                                ...onShareFilter,
                                                ...onShareFilterToolTip,
                                            }"
                                            color="primary"
                                            class="filter-btn ml-3"
                                            icon
                                            x-small
                                        >
                                            <v-icon>share</v-icon>
                                        </v-btn>
                                    </template>

                                    <span class="padding-right:20px;float:left;">Share filter</span>
                                </v-tooltip>
                            </template>
                            <v-list-item-group color="primary">
                                <div class="header" style="background:#fff;width:100%;float:left;font-size:13px;padding:10px;">Share to</div>
                                <v-list dense>
                                    <v-list-item @click="showShareFilterDialog(filter.filter_obj, filter.filter_name, filter.filter_str, 'users')">
                                        <v-icon small class="mr-3 mdi mdi-account"></v-icon>
                                        <v-list-item-title>Users</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="showShareFilterDialog(filter.filter_obj, filter.filter_name, filter.filter_str, 'department')"
                                    >
                                        <v-icon small class="mr-3 mdi mdi-account-group"></v-icon>
                                        <v-list-item-title>Department</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-list-item-group>
                        </v-menu>
                        <v-tooltip bottom transition="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="filter-btn remove ml-4"
                                    :disabled="isFilterUsed"
                                    :loading="isRemoveFilterPending"
                                    @click="deleteFilter(filter.user_filterid, index)"
                                    v-on="on"
                                    v-bind="attrs"
                                    icon
                                    despressed
                                    color="error"
                                    small
                                >
                                    <v-icon>remove_circle_outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove filter</span>
                        </v-tooltip>
                        <v-tooltip bottom transition="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="filter-btn apply ml-4"
                                    :disabled="isFilterUsed"
                                    @click="applyCustomFilter(filter.filter_str, filter.filter_name)"
                                    v-on="on"
                                    v-bind="attrs"
                                    icon
                                    small
                                    color="success"
                                    ><v-icon class="mdi mdi-filter-variant-plus"></v-icon
                                ></v-btn>
                            </template>
                            <span>Apply filter</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-col>
        </div>
    </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "CustomFilter",
    props: {
        filter: Object,
        index: Number,
        activeFiltersNames: Array,
    },
    data: () => ({
        isRemoveFilterPending: false,
        isFilterUsed: false,
        sharedIndex: null,
        shareFilter: false,
    }),
    created() {
        this.$bus.$on("unSetLoading", this.unSetLoading);
        this.checkIfFilterIsUsed();
    },
    methods: {
        checkIfFilterIsUsed() {
            if (this.activeFiltersNames.length !== 0) {
                this.activeFiltersNames.forEach((filter) => {
                    if (this.filter.filter_name === filter) {
                        this.isFilterUsed = true;
                    }
                });
            }
        },
        editFilter(objects, filterName, filterString) {
            this.$emit("editFilter", objects, this.index, filterName, filterString);
        },
        showShareFilterDialog(objects, filterName, filterString, shareTo) {
            this.$emit("showShareFilterDialog", objects, filterName, filterString, shareTo);
        },
        applyCustomFilter(selectedFilter, filtername) {
            this.$emit("applyCustomFilter", selectedFilter, filtername);
        },
        deleteFilter(filterid, index) {
            this.$emit("deleteFilter", filterid, index);
            this.setLoading();
        },
        setLoading() {
            this.isRemoveFilterPending = true;
        },
        unSetLoading() {
            this.isRemoveFilterPending = false;
        },
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
        }),
    },
};
</script>

<style lang="scss" scoped>
.actions-wrapper {
    display: flex;
    align-items: center;
}

.v-item--active {
    &:before {
        opacity: 0 !important;
    }
}

.share-active {
    right: 0px !important;
}

.filter-card {
    max-width: 100% !important;
    float: left;
}

.disabled {
    background: #d3dae1;
    .filter-icon {
        i {
            background: #237ccfd3 !important;
            color: #fff !important;
        }
    }
}

.being-edited {
    background: #f0f0f0ad;
    .edit {
        background: $primary;
        color: #fff !important;
    }
}

.filter-container {
    .filter-card {
        border-bottom: 1px solid #e2e2e2;
    }
    .filter-card:last-child {
        border-bottom: none;
    }
}

.filter-wrapper {
    padding: 20px 20px;
    border-radius: 3px;
    overflow: hidden;
    .filter-btn {
        min-height: 30px !important;
        min-width: 30px !important;
        .filter-icon {
            display: flex;
            width: 30px;
            height: 30px;
            margin: auto;
            i {
                background: #237ccf24;
                color: #237bcf;
                font-size: 18px;
                padding: 7px;
                border-radius: 30px;
            }
        }
        .filter-name {
            font-size: 14px;
            margin-left: 15px;
            font-weight: 500;
            flex: 1;
            display: flex;
            align-items: center;
            span {
                margin-right: auto;
                max-width: 400px;
            }
        }
        .currently-selected {
            display: flex;
            align-items: center;
            margin-right: 100px;
            font-size: 14px;
            color: #237bcf;
            font-style: italic;
        }
    }
    &:hover {
        background: #f0f0f0;
    }
    .date-created {
        font-size: 13px;
        font-weight: 400;
        color: #656565;
        margin-right: 10px;
        display: flex;
        span {
            margin: auto;
        }
    }
}
</style>
