<template>
	<div class="page-container">
		<Loader :loaderStyle="'linear'" v-if="!loadingDone"/>
		<div
			class="fadein-container"
			:class="loadingDone ? 'elevation-2 fade-in' : ''"
		>
			<PageHeader 
				:items="artistObj"
				:status="status"
				:defaultDates="defaultDates"
				:datePicker="datePicker"
				:datesCollected="datesCollected"
				:view="view"
				:budgetid="budgetid"
				:enableOtherOptions="false"
				:newDataLoading="newDataLoading"
				:enableOtherActions="true"
				@getSalesData="initComponentData"
				@setStartDate="date => this.datePicker.startDate.date = date"
				@setEndDate="date => this.datePicker.endDate.date = date"
			/>
			<div
				id="artistpage"
				class="elevation-1"
				v-if="loadingDone"
			>
				<v-container
					class="float-left pb-0"
					container--fluid
				>
					<ProgressionCards :dataObject="progressionCard.dataObject"/>
				</v-container>
				<v-container class="float-left container--fluid pt-0" >
					<v-row>
						<v-col cols="12" md="8" class="pb-0">
							<SalesProgressCard 
								:ticketBars="chosenConcertsTickets" 
								:grossBars="chosenConcertsGross" 
								:donutData="chosenConcertsDonut"
								:dataFound="ticketDataFound"
							/>
						</v-col>
						<v-col cols="12" md="4" class="pa-0">
							<!-- <Checklist/> -->
							<v-col cols="12" md="12" class="float-left pb-0">
								<TodoList/>
							</v-col>
						</v-col>
					</v-row>
				</v-container>
				<v-container class="float-left pb-0 container--fluid">
					<v-col
						cols="12"
						md="12"
						class="float-left pa-0 elevation-1 graph-container"
					>
						<div class="card-title">Sales</div>
						<v-col
							cols="12"
							md="12"
							class="float-left pa-0 pb-8"
						>
							<NoDataPlaceholder
								:text="'No data for graph'"
								v-if="salesGraphOptions.series.length === 0"
							/>
							<echart-card
								v-if="salesGraphOptions.series.length > 0"
								style="height: 400px;"
								:options="salesGraphOptions"
							/>
						</v-col>
					</v-col>
				</v-container>
				<v-container class="float-left container--fluid">
					<v-row>
						<v-col cols="12" md="6">
							<project-ranking-card
								:title="'Top 5 projects'"
								:ticketBars="topConcertsTickets"
								:grossBars="topConcertsGross"
								:predBars="topConcertsPred"
								:sortType="'desc'"
							></project-ranking-card>
						</v-col>
						<v-col cols="12" md="6">
							<project-ranking-card
								:title="'Bottom 5 projects'"
								:ticketBars="bottomConcertsTickets"
								:grossBars="bottomConcertsGross"
								:predBars="bottomConcertsPred"
								:sortType="'asc'"
							></project-ranking-card>
						</v-col>
					</v-row>
				</v-container>
				<v-container class="float-left pt-0 container--fluid">
					<TabBrowser 
						v-if="Object.entries(tabItems).length > 0"
						:items="tabItems" 
						:tabnames="tabBrowser.tabnames"
					
					/>
				</v-container>
				<v-container class="float-left pa-0 container--fluid" >
					<v-col cols="12" md="12">
						<Calendar v-if="Object.entries(artistObj).length > 0" :items="artistObj.concerts"/>
					</v-col>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'
	import PageHeader from '@/components/Menu/PageHeader/'
	import ObjectFactory from '@/models/ObjectFactory';
	import ProgressionCards from '@/components/Cards/ProgressionCards/'
	import TabBrowser from '@/components/Tables/TabBrowser/'
	import SalesProgressCard from '@/components/Cards/SalesProgressCard/'
	import Checklist from '@/components/Checklist/'
	import TodoList from '@/components/TodoList'
	import TwoAxisLineChart from '@/components/Cards/ChartCards/TwoAxisLineChart'
	import DatePicker from '@/components/DatePicker'
	import ProjectRankingCard from '@/components/Cards/ProjectRankingCard.vue'
	import Calendar from '@/components/Calendar'
	import Loader from '@/components/Loader'
	import NoDataPlaceholder from '@/components/NoDataPlaceholder'
	import EchartCard from '@/components/Cards/ChartCards/EchartCard';
	import { initEchart, generateSerie } from '@/components/Cards/ChartCards/EchartOptions.js';

	export default {
		name:'artistpage',
		components:{
			PageHeader,
			ProgressionCards,
			TabBrowser,
			SalesProgressCard,
			Checklist,
			TodoList,
			TwoAxisLineChart,
			DatePicker,
			ProjectRankingCard,
			Calendar,
			Loader,
			NoDataPlaceholder,
			EchartCard
		},
		data:()=>({
			artistObj:{},
			ready:false,
			test:'23',
			loadingDone:false,
			tabBrowser:{
				tabnames:['concerts', 'tours', 'people'],
			},
			ticketDataFound:false,
			datesCollected:true,
			datePicker:{
				startDate:{
					date:'',
				},
				endDate:{
					date:'',
				}
			},
			defaultDates:{
				startDate:'',
				endDate:'',
			},

			salesGraphOptions: { series: [] },

			ticketSalesData: [],
			analyticsData: [],
			progressionCard:{
				dataObject:{
					gigs:{
						title:'Gigs',
						number:0,
						subtext:'Shows',
						percentage:100,
						progresstext:'',
					},
					sold:{
						title:'Sold',
						number:0,
						subtext:'Tickets',
						percentage:76,
						progresstext:'',
					},
					pending_concerts:{
						title:'Pending shows',
						number:0,
						subtext:'concerts',
						percentage:100,
						progresstext:'',

					},
				}
			},
			chartLoaded: false,
			zoomBtnText: 'Enable zoom',
			chosenConcerts: [],
			chosenConcertsTickets: [],
			chosenConcertsGross: [],
			topConcertsTickets: [],
			topConcertsGross: [],
			topConcertsPred: [],
			bottomConcertsTickets: [],
			bottomConcertsGross: [],
			bottomConcertsPred: [],
			

			chosenConcertsDonut: {
				ticket_sold_percentage: 0,
				tickets_sold: 0,
				tickets_available: 0,
				gross_sales_percentage: 0,
				gross_sales: 0,
				gross_available: 0,
			},
			newDataLoading: false,

			chosenStartDate: '',
			chosenEndDate: '',

			tabItems: [],

		}),
		
		async created(){
			this.view = this.$getView(this.$route.path);
			this.unSetDrawersDisabled();
			this.setDefaultStartDate();
			await this.initArtistObj();
			await this.initComponentData();
			this.checkIfData();
			this.loadingDone = true;
			this.$bus.$on('updateDataFromSlider', this.updateDataFromSlider);
			this.chartLoaded = true;
			this.$bus.$emit('setHistoryView', { display:this.artistObj.name , route:this.$route.path } );
		},

		computed: {
			...mapGetters({
				meta_data: "metadata/meta_data",
			}),
		},

		methods:{
			async initArtistObj(){
				let id = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1);
				this.artistObj = await this.$ObjectFactory.BuildArtist( { artistid: id } );
				this.$logger('artistObj', this.artistObj);
			},

			/**
			 *  Function to get data and caclulate data for progressioncard component
			 */
			async initComponentData(){
				this.newDataLoading = true;
				await this.initDates()

				this.chosenConcerts = []

				// reset chart data
				this.ticketSalesData = [];
				this.analyticsData = [];

				if(this.artistObj.concerts){
					this.tabItems.concerts = [];
					for(let [i, concert] of this.artistObj.concerts.entries()){

						if(concert.getTotalSoldTickets() > 0)
							this.chosenConcerts.push(concert)

						this.tabItems.concerts.push(concert.getConcertTabItem());
					}
					
					await this.initGraph();
					this.progressionCard.dataObject.gigs.number = this.chosenConcerts.length                // Get gigs number
					
					this.progressionCard.dataObject.pending_concerts.number = this.artistObj.pending_concerts
					this.$logger('chosenConcerts', this.chosenConcerts);
				}

				// for tab browser
				this.tabItems.tours = Object.assign([], this.artistObj.tours);
				this.tabItems.people = [];
				let artistCopy = JSON.parse(JSON.stringify(this.artistObj))
				for(let key in artistCopy){            // Find people/organization data by iterating through artist object and look for objects containing value 'personid'
					for(let innerkey in artistCopy[key]){
						if(innerkey == 'personid'){
							artistCopy[key].Role = `${this.formatHeader(key)}`;
							artistCopy[key].rowid = artistCopy[key][innerkey];
							// delete id key
							delete artistCopy[key][innerkey];

							// delete unwanted keys
							if(artistCopy[key]['adress']) delete artistCopy[key]['adress'];

							let index = 'people';
							this.tabItems[index].push(artistCopy[key]);
						}
					}
				}

				let metaFields = {}

				console.log('TABLE METABROWSE')
				for(let table in this.tabItems){
					metaFields[table] = this.meta_data[table].browse;
				}

				for(let table in this.tabItems){
					for(let metaItem in metaFields[table]){
						for(let tabItem of this.tabItems[table]){
							for(let key in tabItem){
								if(key == metaFields[table][metaItem].COLUMN_NAME){
									tabItem[metaFields[table][metaItem].LABEL] = tabItem[key]
									delete tabItem[key]
								}
							}
						}
					}
				}

				console.log('META FIELDS')
				console.log(metaFields)
				console.log(this.tabItems)

				await this.initConcertProps()

				await this.$bus.$emit('SortSalesProgressBars', this.chosenConcertsTickets)
				await this.$bus.$emit('SortSalesProgressBars', this.chosenConcertsGross)
				await this.$bus.$emit('SortProjectRanking')

				
				this.newDataLoading = false;	
			},

			checkIfData(){
				if(this.chosenConcertsTickets.length > 0 ){
					this.ticketDataFound = true
					this.loader = false;
				} else {
					this.ticketDataFound = false
					this.loader = false;
				}
			},
			getDate(){
				const dateString = new Date().toISOString().split('-');
				const year = dateString[0];
				const month = dateString[1];
				const day = dateString[2].split('T')[0];
				return `${year}-${month}-${day}`
			},
			setDefaultStartDate(){
				let d = new Date()
				d.setMonth(d.getMonth()-3)
				this.defaultDates.endDate = new Date().toISOString().substr(0,10)
				this.defaultDates.startDate = d.toISOString().substr(0,10)
				this.datePicker.endDate.date = this.defaultDates.endDate;
				this.datePicker.startDate.date = this.defaultDates.startDate
			},
			setStartDate(payload){
				this.datePicker.startDate.date = payload
			},
			setEndDate(payload){
				this.datePicker.endDate.date = payload
				this.$logger('payload', payload);
			},

			formatHeader(header){
				return header.replace(/_/g, ' ').replace(/(^|\s)\S/g, l => l.toUpperCase()) // Format header received from object (Capitalize and remove underscore)
			},

			/**
			 * Initializes the start and end dates that are chosen from the datepicker
			 */
			async initDates(){
				let start_date = ''
				let end_date = ''

				if(this.datePicker.startDate.date > ''){
					start_date = new Date(this.datePicker.startDate.date)
				} else {
					start_date = new Date()
				}
				if(this.datePicker.endDate.date > ''){
					end_date = new Date(this.datePicker.endDate.date)
				}  else {
					let d = new Date()
					d.setMonth(d.getMonth()+3)
					end_date = d
				}

				this.chosenStartDate = start_date
				this.chosenEndDate = end_date
			},

			async initGraph(){
				this.ticketSalesData = this.artistObj.getArtistSalesDataForGraph(this.chosenStartDate, this.chosenEndDate);
				
				if(this.ticketSalesData.length == 0) return;

				// sort graphData
				this.fixArtistGraphData(this.ticketSalesData);

				// analytics
				for(let concert of this.artistObj.concerts){
					if(concert.analytics)
						this.initAnalyticsDataForGraph(concert.analytics);
				}

				if(this.analyticsData.length > 0)
					this.fixArtistGraphData(this.analyticsData);

				// collect x axis
				let selectedDateRange = this.getDateRangeArray(new Date(this.datePicker.startDate.date), new Date(this.datePicker.endDate.date));

				this.salesGraphOptions = initEchart( { xAxisData: selectedDateRange, title: 'Sales and analytics', yAxisLTitle: 'Tickets', yAxisRTitle: 'Visitors' } )

				if(this.ticketSalesData.length > 0)
					this.salesGraphOptions.series.push(generateSerie( { data: this.ticketSalesData, name: 'Sold', smooth: false }))

				if(this.analyticsData.length > 0)
					this.salesGraphOptions.series.push(generateSerie({ data: this.analyticsData, name: 'Google analytics', yAxis: 1, smooth: false }))
			},

			fixArtistGraphData(salesArray){
				salesArray.sort((a,b) => new Date(a[0]) - new Date(b[0]));
				this.setMissingDates(salesArray[0][0], salesArray[salesArray.length-1][0], salesArray);
				salesArray.sort((a,b) => new Date(a[0]) - new Date(b[0]));
			},

			/**
			 * Initializes the props that are sent to the various concert card components
			 */
			async initConcertProps(){
				
				this.chosenConcertsDonut = {
					ticket_sold_percentage: 0,
					tickets_sold: 0,
					tickets_available: 0,
					gross_sales_percentage: 0,
					gross_sales: 0,
					gross_available: 0,
				}
				this.chosenConcertsTickets = []
				this.chosenConcertsGross = []
				this.topConcertsTickets = []
				this.topConcertsGross = []
				this.topConcertsPred = []
				this.bottomConcertsTickets = []
				this.bottomConcertsGross = []
				this.bottomConcertsPred = []

				this.chosenConcerts.forEach(concert => {
					let tickets_sold = concert.getTotalSoldTickets(this.chosenStartDate, this.chosenEndDate);
					let gross_sales =  concert.getTotalGross(this.chosenStartDate, this.chosenEndDate);
					let sold_percentage = concert.getTotalSoldPercentage(this.chosenStartDate, this.chosenEndDate);

					this.chosenConcertsDonut.tickets_sold += +tickets_sold;
					this.chosenConcertsDonut.tickets_available += +concert.getTotalTicketsAvailable();
					this.chosenConcertsDonut.gross_sales += +gross_sales;
					this.chosenConcertsDonut.gross_available += +concert.getTotalGross()

					let concertTickets = {
						title: concert.title,
						percentage: sold_percentage,
						total: tickets_sold
					}

					let concertGross = {
						title: concert.title,
						percentage: sold_percentage,
						total: gross_sales
					}

					// Goes to SalesProgressCard
					this.chosenConcertsTickets.push(Object.assign({}, concertTickets))
					// Goes to SalesProgressCard
					this.chosenConcertsGross.push(Object.assign({}, concertGross))

					// goes to top ProjectRankingCard
					this.topConcertsTickets.push(Object.assign({}, concertTickets))

					this.topConcertsGross.push(Object.assign({}, concertGross))

					this.topConcertsPred.push({
						title: concert.title,
						percentage: Math.round(concert.predicted_sales  * 100),
						total: concert.tickets_available
					})

					// Goes to bottom ProjectRankingCard
					this.bottomConcertsTickets.push(Object.assign({}, concertTickets))

					this.bottomConcertsGross.push(Object.assign({}, concertGross))

					this.bottomConcertsPred.push({
						title: concert.title,
						percentage: Math.round(concert.predicted_sales  * 100),
						total: concert.tickets_available
					})

				});
				this.progressionCard.dataObject.sold.number = this.chosenConcertsDonut.tickets_sold;                                       // Get sold tickets of chosen concerts
				this.progressionCard.dataObject.sold.percentage = this.chosenConcertsDonut.tickets_available != 0 ? Math.round(this.chosenConcertsDonut.tickets_sold /  this.chosenConcertsDonut.tickets_available * 100) : 0;  // Calculate percentage of sold tickets
				this.progressionCard.dataObject.sold.progresstext = this.progressionCard.dataObject.sold.percentage + '% of total'

				this.chosenConcertsDonut.ticket_sold_percentage = this.progressionCard.dataObject.sold.percentage
				this.chosenConcertsDonut.gross_sales_percentage = this.chosenConcertsDonut.gross_available != 0 ? Math.round(this.chosenConcertsDonut.gross_sales / this.chosenConcertsDonut.gross_available * 100) : 0;
			},

			getDateRangeArray(start, end){
				start = new Date(start);
				end = new Date(end);
				let arr = [];
				while(start <= end){
					arr.push(start.toISOString().substr(0, 10));
					start = this.getFinalDates(start, 1)
				}
				return arr;
			},
			getFinalDates(date, add){
				let myDate = date;
				let day = 86400000 //number of milliseconds in a day
				return new Date(+myDate + (add * day));
			},

			/**
			 * Collects the analytics data for the graph
			 */
			initAnalyticsDataForGraph(analyticsData){
				let tmpDate = new Date(this.datePicker.startDate.date)

				while(tmpDate <= new Date(this.datePicker.endDate.date)){
					let isoDate = tmpDate.toISOString().substr(0, 10)
					let analytic = analyticsData.find((datapoint)=>{
						return datapoint.date === isoDate
					})

					if(analytic != undefined)
						this.analyticsData.push( [ analytic.date, analytic.visitors ] )
					
					tmpDate.setDate(tmpDate.getDate() + 1)
				}
			},

			setMissingDates(fromdate, enddate, salesArray) {
				//Convert dates from ISOStrings to date objects, set time to 12:00:00 to avoid daylight savings issues
				var date = new Date(Date.parse(fromdate + " 12:00:00"));
				var todate = new Date(Date.parse(enddate + " 12:00:00"));
				var isodate = "";
				var dateArray = [];

				//Fill dateArray with existing dates
				for (let index = 0; index < salesArray.length; ++index)
					dateArray.push(salesArray[index][0]);

				do {
					//convert date to ISOString
					isodate = date.toISOString().substring(0, 10);
					//If date is missing, push object to salesArray for missing date
					if (dateArray.indexOf(isodate) == -1) salesArray.push([isodate, null]);

					//Add one day to date
					date.setDate(date.getDate() + 1);
				} while (date <= todate);
			},

			async updateDataFromSlider(){
				let route = this.$route.path.substr(1).replace(/\/edit\//g, '?id=')
				try{
					let response = await this.$GetService(this.$getEditRoute(this.$route.path))
					if(response.status === 200){
						this.artistObj = response.data
					}
				}catch(error){
					console.log(error)
				}
			},
			...mapActions({
				setDrawersDisabled:'globalstates/setDrawersDisabled',
				unSetDrawersDisabled:'globalstates/unSetDrawersDisabled'
			}),
		},
		beforeDestroy() {
			this.setDrawersDisabled()
		},
	}
</script>

<style lang="scss" scoped>

.fadein-container{
	min-height:calc(100vh - 170px);
	position:relative;
	width:100%;
	float:left;
	opacity:0;
	transition:all 0.5s ease-out;
	margin-top:-20px;
}

	
.fade-in{
	opacity:1!important;
	margin-top:0px!important;
}

.card-title{
	padding:9px;
	font-size:1rem;
	float:left;
	width:100%;
	font-weight:600;
	text-align:center;
	color:$font-color-pale;
	text-transform: uppercase;
	border-bottom:1px solid $border-color;
	background:$card-title-bg
}

.project-ranking{
	position:relative;
}

.graph-container{
	position:relative;
	border:1px solid $border-color;
	border-radius:3px;
	background:#fff;
}


@media only screen and (max-width: 1264px) {
	#artistpage{
		padding: 66px 0px 0px 0px !important;
	}
}


</style>