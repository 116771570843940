<template>
    <div id="ticketing">
        <v-col cols="12" md="12" class="elevation-1 pa-0">
            <ViewHeader :header="header" />
            <TicketingTable />
        </v-col>
    </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader/";
import TicketingTable from "@/components/Tables/TicketingTable/";

export default {
    name: "tickets",
    components: {
        ViewHeader,
        TicketingTable,
    },
    data: () => ({
        header: "Ticketing",
    }),
};
</script>

<style scoped>

</style>