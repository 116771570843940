<template>
   <div id="comparable-projects" class="float-left" >

      <v-progress-linear :active="!handlerInitialized" indeterminate/>

      <!-- SAVE OVERLAY -->
      <v-overlay v-if="isCurrentlyUpdating">
         <v-card color="" min-width="250">
            <v-card-text class="overlay-title pa-5">
               {{ savingText }}
               <v-icon color="success" v-if="savingText.includes('saved')">done</v-icon>
            </v-card-text>
            <v-progress-linear v-if="savingText.includes('saving')" indeterminate></v-progress-linear>
         </v-card>
      </v-overlay>

      <PageHeader
         :benchmarkTitle="title"
         :view="'comparableprojects'"
         :selectedConcerts="selectedConcerts"
         :saveValid="saveValid"
         @saveBenchmark="saveBenchmark"
         @showDeleteDialog="showDeleteDialog = true"
         v-if="handlerInitialized"
      />
      <div id="comparable-projects-wrapper">
         <event-selector
            v-if="handlerInitialized"
            :events="concertsData"
            :idKey="'concertid'"
            :valueKey="'title'"
            :selectedEvents="selectedConcerts"
            @addComparableEvent="addComparableConcert"
            @removeComparableEvent="removeComparableConcert"
         /> 

         <sales-graph
            v-if="handlerInitialized && selectedConcerts.length > 0"
            :options="graphOptions"
         />

         <average-info-cards
            v-if="handlerInitialized && selectedConcerts.length > 0"
            :ticketsSold="avgTicketsSold"
            :ticketsSoldPercentage="avgTicketsSoldPercentage"
            :ticketPrice="avgTicketPrice"
         />
         
         <compare-ticket-prices
            v-if="handlerInitialized && selectedConcerts.length > 0"
            :ticketTypes="ticketTypes"
            :concerts="selectedConcerts"
         />

         <delete-dialog 
            @deleteFromDialog="deleteBenchmark"
            @cancelDialog="showDeleteDialog = false"
            :item="comparable_item"
            :name="title"
            :dialog="showDeleteDialog"
         />

      </div>
   </div>
</template>

<script>
import EventSelector from './EventSelector';
import PageHeader from '@/components/Menu/PageHeader'
import SalesGraph from './SalesGraph';
import AverageInfoCards from './AverageInfoCards';
import CompareTicketPrices from './CompareTicketPrices';
import { initEchart, generateSerie } from '@/components/Cards/ChartCards/EchartOptions.js';
import DeleteDialog from '@/components/Dialogs/DeleteDialog';

export default {
   name: 'CompProjHandler',

   components: {
      EventSelector,
      SalesGraph,
      AverageInfoCards,
      CompareTicketPrices,
      DeleteDialog,
      PageHeader
   },

   data: () => ({
      showDeleteDialog: false,
      stretchHeader:false,
      isCurrentlyUpdating: false,
      savingText: '',
      handlerInitialized: false,
      concertsData: [],

      saveValid: false,
      nameRules: [
         v => !!v || 'Name is required',
         v => (v && v.length <= 30) || 'Name must be less than 30 characters',
      ],
      
      // already made comparable project
      comparable_item: [],
      comparable_concertid: '',
      title: '',
      selectedConcerts: [],
      colors: [
         "#3e5894",
         "#31bd56",
         "#9531bd",
         "#bd3136",
         "#b39b3e",
         "#bd6231"
      ],

      graphOptions: [],
      graphData: [],

      avgTicketsSold: 0,
      avgTicketsSoldPercentage: 0,
      avgTicketPrice: 0,

      ticketTypes: [],
   }),

   async created() {
      this.$bus.$on('expandHeader', this.expandHeader)
      await this.initConcerts();
      this.initTheRest();
      this.handlerInitialized = true;
      this.$bus.$emit('setHistoryView', { display:`${this.title ? this.title : 'Create'} Benchmark` , route:this.$route.path } )
   },

   methods: {

      async initTheRest(){
         this.graphData = [];
         this.avgTicketsSold = 0;
         this.avgTicketsSoldPercentage = 0;
         this.avgTicketPrice = 0;
         this.ticketTypes = [];
         this.getAverages();
         this.initGraph();
         this.getTicketTypes();
      },
      expandHeader(){
         this.stretchHeader = !this.stretchHeader
      },
      /**
       * Initalize the relevent objects
       */
      async initConcerts(){
         let currentDate = new Date().toISOString().substr(0,10);
         this.concertsData = await this.getData(`${this.$getRoute('concerts').main_route}?date=${currentDate}&fields=title,date,venueid,tickets,ticket_sales`);
         if(this.concertsData)
            this.concertsData.map(c => c.venueid = { name: c.venueid });
         else
            this.concertsData = [];

         // edit comparable project
         if(this.$route.path.includes('edit')){
            this.comparable_concertid = this.$getRouteId(this.$route.path);
            this.comparable_item = await this.getData(`${this.$getRoute('comparableprojects').main_route}?id=${this.comparable_concertid}`);
            
            // collect actual concert items
            for(let i = 0; i < this.comparable_item.concerts.length; i++)
               await this.addComparableConcert(this.comparable_item.concerts[i].concertid, false, this.comparable_item.concerts[i].mapid);

            this.title = this.comparable_item.title;
         }
      },

      initGraph(){
         if(this.selectedConcerts.length > 0){
            let x = []
            for(let i = 1; i <= 100; i++) x.push(i);

            this.graphOptions = initEchart( { title: 'Sales pattern', zoom: false, yAxisLTitle: 'Tickets' } )
            this.graphOptions.yAxis[0].name = 'Tickets'
            this.graphOptions.xAxis.axisLabel.show = false;
            this.graphOptions.xAxis.axisTick.show = false;

            let sets = [], trimSets = [];
            this.selectedConcerts.map(concert => {
               let data = [];
               let totalSold = 0;

               if(concert.tickets){
                  concert.tickets.map(ticket => {
                     if(ticket.ticket_sales){
                        ticket.ticket_sales.map(sale => {
                           let found = false
                           totalSold += +sale.amount;
                           data.forEach((saleData, index) => {
                              if(saleData == sale.date) {
                                 saleData.y += +sale.amount
                                 found = true
                              }
                           });
                           if(!found)
                              data.push({ x: sale.date, y: +sale.amount })  // Add new date with sales
                        })
                     }
                  })
               }
               data.sort((a,b) => { return new Date(a.x) - new Date(b.x) })
               let trimmed = [];
              // let y = [];
               for(let i = 0; i < data.length; i++){
                  let j = 1;
                  let tmp = data[i].y;
                 
                  while(i+j < data.length && data[i].x == data[i+j].x){
                     tmp += data[i + j++].y;
                  }
                  trimmed.push({ x: data[i].x, y: tmp / (totalSold != 0 ? totalSold : 1) });
                 // y.push(tmp)
                  i = i+j-1;
               }
               trimSets.push(trimmed);
               sets.push(data);
            })

            let combinedData = [];
            trimSets.map(set => {
               for(let i = 0; i < set.length; i++){
                  i == 0 ? set[i].x = 1/set.length : set[i].x = (1/set.length + set[i-1].x);
               }
               set.map(data => data.x = Math.round(data.x * 100));
               combinedData = combinedData.concat(set);
            })

            combinedData.sort((a,b) => { return a.x - b.x })
            let t = 0;
            for(let i = 0; i < combinedData.length; i++){
               let j = 1;
               let tmp = combinedData[i].y;
               
               while(i+j < combinedData.length && combinedData[i].x == combinedData[i+j].x){
                  tmp += combinedData[i + j++].y;
               }
               tmp = Math.round(tmp * this.avgTicketsSold);
               this.graphData.push([combinedData[i].x, tmp]);
               i = i+j-1;
            }
            this.graphOptions.series.push(generateSerie( { data: this.graphData, name: 'Combined sales' } ));
         }
      },


      async addComparableConcert(concertid, updateAvg = true, mapid = ''){
         let concert = await this.getData(`${this.$getRoute('concerts').main_route}?id=${concertid}&fields=title,date,venueid,tickets,ticket_sales`);
         concert.color = this.getConcertColor();

         if(mapid != '')
            concert.mapid = mapid;

         this.selectedConcerts.push(concert);
         if(updateAvg)
            this.initTheRest();
      },

      removeComparableConcert(event){
         this.$delete(this.selectedConcerts, this.selectedConcerts.indexOf(event));
         this.initTheRest();
      },


      getAverages(){
         let totalAvailable = 0, totalSold = 0;
         let sales = [], prices = [], tickets = [];

         if(this.selectedConcerts.length > 0){

            this.selectedConcerts.map(concert => {
               let totalAvailable = 0;
               let totalSold = 0;
               //compare tickets
               concert.ticketInfo = []
   
               if(concert.tickets){
                  concert.tickets.map(ticket => {
                     // compare tickets
                     let ticketSold = 0;
   
                     totalAvailable += +ticket.available; 
                     prices.push(ticket.price);
   
                     if(ticket.ticket_sales){
                        ticket.ticket_sales.map(sale => {
                           totalSold += +sale.amount;
                           //compare tickets
                           ticketSold += +sale.amount
                        })
                     }
                     //compare tickets
                     concert.ticketInfo.push({
                        name: ticket.name, 
                        price: ticket.price, 
                        sold: Math.round((ticketSold / ticket.available) * 100),
                        platinum: +ticket.platinum,
                     })
   
                  })
                  sales.push({ 
                     available: totalAvailable, 
                     sold: totalSold, 
                     percentage: (totalSold / totalAvailable) * 100
                  })
               }
            })
   
            this.avgTicketsSold = 0;
            this.avgTicketsSoldPercentage = 0;
            if(sales.length > 0) {
               this.avgTicketsSold = Math.round( sales.reduce( (total, sale) => total += +sale.sold , 0) / sales.length);
               this.avgTicketsSoldPercentage = Math.round( sales.reduce( (total, sale) => total += sale.percentage, 0) / sales.length);
            }
   
            this.avgTicketPrice = Math.round( prices.reduce( (total, price) => total += +price, 0) / (prices.length > 0 ? prices.length : 1));
         }

      },

      getTicketTypes(){
         let tempTypes = [];
         let max = 0;
         if(this.selectedConcerts.length > 0){

            this.selectedConcerts.map(concert => {
               concert.ticketInfo.sort((a,b) => {return a.price - b.price})
               let i = 1;

               concert.ticketInfo.map(type => {
                  console.log(type)
                  if(type.platinum)
                     type.name = 'Platinum'
                  else
                     type.name = `Tier ${i++}`
                  
                  if(!tempTypes[type.name])
                     tempTypes[type.name] = []
   
                  tempTypes[type.name].push({price: type.price, percentage: type.sold, color: concert.color})
                  
                  if(max == 0 || max < +type.price)
                     max = +type.price;
                     
               })
            })
   
            if(Object.keys(tempTypes).length === 0) return
            // sort keys
            let keys = Object.keys(tempTypes)
            keys.push(keys.splice(keys.indexOf('Platinum'), 1)[0]);
            
            this.ticketTypes = [];

            for(let i = 0; i < keys.length; i++){
               name = keys[i]
               let localMax = 0
               tempTypes[name].map(type => localMax < type.price ? localMax = type.price : localMax = localMax)
               tempTypes[name].map(type => {
                  type.size = Math.round((100 - (+(type.price) / max) * 100 ))
               })
               tempTypes[name].sort((a,b) => {return a.price - b.price})
               this.ticketTypes.push({name: name, tickets: tempTypes[name]})
            }
         }
      },

      // fix edit save, PUT, remove concerts/add new ones
      async saveBenchmark(title){
         this.toggleSavingOverlay(true);
         if(this.selectedConcerts.length > 0){
            let x = '', y = '';
            this.graphData.map(point => {
               x += `${point[0]};`
               y += `${point[1]};`
            })
            x = x.slice(0,-1)
            y = y.slice(0,-1)

            let data = {
               title: title,
               graph_x: x,
               graph_y: y,
            };
            
            let route = this.$getRoute('comparableprojects');

            if(this.$route.path.includes('edit')){
               // update name/graph
               data.comparable_concertid = this.comparable_concertid;
               if(await this.putData(route.main_route, data)){

                  // remove old concerts
                  let concertToRemove = { mapid: '', comparable_concertid: this.comparable_concertid };
                  for(let i = 0; i < this.comparable_item.concerts.length; i++){

                     let exists = this.selectedConcerts.find((concert)=>{
                        return concert.mapid == this.comparable_item.concerts[i].mapid;
                     })

                     if(!exists){
                        concertToRemove.mapid = this.comparable_item.concerts[i].mapid;
                        await this.deleteData(route.concerts, concertToRemove)
                     }
                  }
               }

            } else {
               this.comparable_concertid = await this.postData(route.main_route, data);

            }

            // post selected concerts 
            this.selectedConcerts.map(async concert => {
               if(!concert.mapid)
                  await this.postData(route.concerts, { concertid: concert.concertid, comparable_concertid: this.comparable_concertid });
            })

            await this.toggleSavingOverlay(false);
         }
      },

      async deleteBenchmark(benchmark){
         this.showDeleteDialog = false;
         this.handlerInitialized = false;

         let route = this.$getRoute('comparableprojects');
          // remove concerts
         let concertToRemove = { mapid: '', comparable_concertid: this.comparable_concertid };
         for(let i = 0; i < this.comparable_item.concerts.length; i++){
            concertToRemove.mapid = this.comparable_item.concerts[i].mapid;
            await this.deleteData(route.concerts, concertToRemove)
         }

         // delete benchmark
         await this.deleteData(route.main_route, { comparable_concertid: benchmark.comparable_concertid })
         this.$router.push('/comparableprojects');
      },

      getConcertColor(){
         let c = this.colors.pop();
         this.colors.unshift(c);
         return c;
      },

      async toggleSavingOverlay(val){
         if(val){
            this.isCurrentlyUpdating = true
            this.savingText = 'Benchmark is saving...'
         }
         else{
            this.savingText = 'Benchmark saved!'
            // 1500 delay to show user it actually saved
            await setTimeout(() => {
               this.isCurrentlyUpdating = false
               this.$router.push('/comparableprojects');
            }, 1500);
         }
      },

      /**
       * Generic get function
       */
      async getData(route){
         try{
            let response = await this.$GetService(route)
            if(response.status == 200){
               return response.data;
            }
         }catch(error){
            console.log(error);
         }
      },

      /**
       * Generic post function
       */
      async postData(route, data){
         try{
            let response = await this.$PostService(route, data);
            if(response.status == 200){
               return response.data.comparable_concertid;
            }
         }catch(error){
            console.log(error);
         }
      },

      async putData(route, data){
         try{
            let response = await this.$PutService(route, data);
            if(response.status == 200){
               return true;
            }
         }catch(error){
            console.log(error);
         }
         return false;
      },

      async deleteData(route, data){
         try{
            let response = await this.$DeleteService(route, data);
            if(response.status == 200){
               return true;
            }
         }catch(error){
            console.log(error);
         }
         return false;
      },
   }
}
</script>

<style lang="scss">

   #comparable-projects{
      height:100%;
      width:100%;
      float:left;
   }

   #comparable-projects .benchmark-title{
      font-weight:500;
      font-size:1.2rem;
      max-width:450px;
      float:left;
      text-align:center;
      align-items:center;
      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
      padding:0px 15px;
      i{
         color:$accent-color;
         margin-right:10px;
      }
   }

   #comparable-projects .card{
      float:left;
      width:100%;
      background:#fff;
      border:1px solid #e2e2e2;
      margin-bottom:20px;
      border-radius:3px;
   }

   #comparable-projects .button-wrapper{
		margin-left:15px;
		margin:auto;
		display:flex;
      padding-right:15px;
   }
   
   @media only screen and (max-width: 1264px) {
		.button-wrapper{
			display:block!important;
			button{
			}
		}
	}
</style>