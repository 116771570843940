import TabRow from './TabRow'
import { GetDocService } from '@/api/apiServices.js';

export default class TabRowFile extends TabRow {

    async getDocument(contentid, sign = false) {
        try {

            let route = `generateDocument?documentid=${this.documentid}&id=${contentid}`;
            if (sign)
                route += `&sign=${sign}`;

            let response = await GetDocService(route);
            if (response.status == 200) {
                if (sign)
                    return { blob: response.data, token: response.headers['getaccept-token'] };
                else 
                    return response
            }

        } catch (error) {
            console.log(error);
        }
    }

    downloadDocument(response) {
        const FileDownload = require("js-file-download");

        let date = new Date();
        let fName = `${date.toISOString().substr(0, 10)}${date.toTimeString().substr(0, 8)}_${this.type}.${this.file_ending}`
        fName = fName.replace(/-|:|/g, '').replace(/ /g, '-');
        // fNameHeader = 'content-disposition';

        // if(!response.headers[fNameHeader])
        //     fNameHeader = 'Content-Disposition';

        // let fName = response.headers[fNameHeader]
        //             .replace('attachment; filename="', "")
        //             .slice(0, -1);

        FileDownload(response.data, fName);
    }

}