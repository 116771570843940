<template>
    <div class="release-data-table elevation-1">
        <div class="card-title">Your upcoming releases</div>
        <v-data-table
            :headers="headers"
            :items="filteredConcerts"
            :items-per-page="5"
            item-key="name"
            @click:row="(item) => goTo(item)"
        >
            <template v-slot:item.artistid="{ item }">
                {{ item.artistid.name }}
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {
    name: "releasedatatable",
    props: {
        items: Object,
    },
    data: () => ({
        headers: [
            { text: "On sale date", value: "on_sale" },
            { text: "Time", value: "on_sale_time" },
            { text: "Artist", value: "artistid" },
            { text: "Show name", value: "title" },
            { text: "City", value: "town" },
        ],
        filteredConcerts: [],
    }),
    created() {
        this.getConcertsNotOnSaleAndConfirmed();
        this.sortConcerts();
    },
    methods: {
        getConcertsNotOnSaleAndConfirmed() {
            console.log(this.items);
            this.filteredConcerts = this.items.filter(
                (concert) =>
                    new Date(concert.on_sale) > new Date() &&
                    concert.status === "Confirmed"
            );
        },
        sortConcerts() {
            this.filteredConcerts.sort(
                (a, b) => new Date(a.on_sale) - new Date(b.on_sale)
            );
        },
        goTo(item) {
            this.$router.push({
                path: `concerts/edit/${item.concertid}`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.v-data-table td,
.v-data-table th {
    padding: 16px !important;
}

.release-data-table {
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
}

.text-start {
    max-width: 236px;
}
</style>