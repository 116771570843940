<template>
    <div id="generic-data-table">
        <v-data-table
            v-model="selectedItems"
            :headers="formattedHeaders"
            :items="items"
            :single-select="singleSelect"
            item-key="title"
            show-select
            class="elevation-0"
            @click:row="(item, row) => row.select(!row.isSelected)"
        >

        </v-data-table>
    </div>
</template>

<script>
export default {
    name: 'generic-data-table',
    props: {
        headers: Array,
        items: Array,
        singleSelect: Boolean,
        clear: Boolean,
    },
    data: ()=>({
        formattedHeaders: [],
        selectedItems: [],
    }),
    watch: {
        selectedItems(items){
            console.log(items)
            this.$emit('itemsSelected', this.singleSelect ? items[0] : items);
        },
        clear(v){
            if(v)
                this.selectedItems = [];
        }
    },
    created(){
        
        for(let header of this.headers){
            this.formattedHeaders.push({
                text: this.$formatHeader(header),
                value: header.toLowerCase(),
            })
        }
        this.$logger('headers in datatable', this.headers);
        this.$logger('items in datatable', this.items);
    },
}
</script>

<style lang="scss" scoped>
    #generic-data-table{
        &::v-deep td{
            cursor: pointer;
        }
        &::v-deep tr.v-data-table__selected {
            background: #306aa11c !important;
                
        }
        &::v-deep tr.v-data-table__selected td {
            background: #306aa11c !important;
                
        }
  }
    
</style>