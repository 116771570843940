var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progressioncard elevation-1",staticStyle:{"position":"relative"}},[(_vm.isLoading)?_c('v-progress-linear',{staticStyle:{"position":"absolute","width":"100%","height":"2px"},attrs:{"indeterminate":"","active":""}}):_vm._e(),_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.$thousandSeperator(_vm.number))),_c('span',[_vm._v(_vm._s(_vm.subtext))])]),_c('div',{staticClass:"progresstext"},[_vm._v(_vm._s(_vm.progresstext))]),_c('v-progress-linear',{attrs:{"rounded":"","height":_vm.height,"value":_vm.percentage > 100 && _vm.percentage < 201
                    ? _vm.percentage % 100
                    : _vm.percentage,"color":!_vm.redOverLimit
                    ? this.percentage > 80
                        ? '#56b54a'
                        : 'primary'
                    : this.percentage > 100
                    ? '#b54a4a'
                    : 'primary'}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }