<template>
	<v-row justify="center">
		<v-dialog v-model="addTicketDialog" persistent max-width="890">
			<v-card>
			<v-card-title class="header">Tickets in budget</v-card-title>
				<v-col cols="12" md="12" class="pt-0 pb-0">
					<v-row>
						<v-simple-table>
							<template v-slot:default>
								<thead class="thead" v-if="ticketData">
									<tr class="tr-bg">
										<th class="text-left">Copy</th>
										<th class="text-left">Name</th>
										<th class="text-center">Platinum</th>
										<th class="text-right">Price</th>
										<th class="text-right">Capacity</th>
										<th class="text-right">Available</th>
										<th class="text-right">Show number</th>
									</tr>
								</thead>

								<tbody>
									<tr v-for="item in ticketData" :key="item.budget_ticketid" class="tr-bg">
										<td v-if="ticketData.length > 0">
											<v-btn color="primary" icon @click="copyToInput(item)">
												<v-icon>mdi-content-copy</v-icon>
											</v-btn>
										</td>
										<td>{{ item.section_name }}</td>
										<td class="text-center"><v-icon v-if="item.platinum === '1'">check</v-icon></td>
										<td class="text-right">{{ item.price }}</td>
										<td class="text-right">{{ item.capacity }}</td>
										<td class="text-right">{{ item.available }}</td>
										<td class="text-right">{{ item.show_number }}</td>
									</tr>
								</tbody>
								<tr  class="tr-bg" v-if="ticketData.length === 0">
									<td colspan="14" class="text-center">No data available</td>
								</tr>
							</template>
						</v-simple-table>
					</v-row>
				</v-col>
				<v-card-title class="header">Add new ticket type</v-card-title>
				<v-container fluid class="pa-7 pb-0 pt-0">
					<v-row>
						<v-col cols="12" md="2" class="pa-2">
							<v-checkbox true-value="1" false-value="0" label="Platinum" v-model="form.platinum"></v-checkbox>
						</v-col>
						<v-col cols="12" md="3" class="pa-2">
							<v-text-field v-model="form.name" label="Ticket name" outlined dense></v-text-field>
						</v-col>
						<v-col cols="12" md="2" class="pa-2">
							<v-text-field v-model="form.price" type="number" label="Ticket price" outlined dense></v-text-field>
						</v-col>
						<v-col cols="12" md="2" class="pa-2">
							<v-text-field v-model="form.capacity" type="number" label="Capacity" outlined dense></v-text-field>
						</v-col>
						<v-col cols="12" md="2" class="pa-2">
							<v-text-field v-model="form.available" type="number" label="Available" outlined dense></v-text-field>
						</v-col>
						<v-col cols="12" md="1" class="pa-2 d-flex justify-center">
							<v-btn  v-if="isMobile" block :disabled="invalidInput" @click="addTicket" color="primary">
								<v-icon  left>add_circle_outline</v-icon>
								Add Ticket
							</v-btn>
							<v-btn v-else icon :disabled="invalidInput" @click="addTicket" color="primary">
								<v-icon>add_circle_outline</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<v-container class="pa-3 pt-0">
					<v-card-title class="sub-header">Tickets to be added</v-card-title>
					<v-col cols="12" md="12" class="pa-4 pt-0">
						<span v-if="potentialTickets.length === 0" class="no-tickets-message">No tickets added</span>
						<div v-for="(form,index) in potentialTickets" :key="form.id">
							<v-row>
								<v-col cols="12" md="2" class="pa-2">
									<v-checkbox label="Platinum" true-value="1" false-value="0"  v-model="form.platinum" disabled></v-checkbox>
								</v-col>
								<v-col cols="12" md="3" class="pa-2">
									<v-text-field :value="form.name" label="Ticket name" outlined dense disabled></v-text-field>
								</v-col>
								<v-col cols="12" md="2" class="pa-2">
									<v-text-field :value="form.price" label="Ticket price" outlined dense disabled></v-text-field>
								</v-col>
								<v-col cols="12" md="2" class="pa-2">
									<v-text-field :value="form.capacity" label="Capacity" outlined dense disabled></v-text-field>
								</v-col>
								<v-col cols="12" md="2" class="pa-2">
									<v-text-field :value="form.available" label="Available" outlined dense disabled></v-text-field>
								</v-col>
								<v-col cols="12" md="1" class="pa-2 d-flex justify-center">
									<v-btn v-if="isMobile" block @click="removeTicketFromRow(index)" depressed color="error">
										<v-icon left >remove_circle_outline</v-icon>
										Remove ticket
									</v-btn>
									<v-btn v-else @click="removeTicketFromRow(index)" icon color="error">
										<v-icon>remove_circle_outline</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-container>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text small @click="closeTicketDialog">Cancel</v-btn>
					<v-btn
						color="primary"
						:loading="isSaveTicketsPending"
						:disabled="potentialTickets.length === 0"
						small
						depressed
						@click="saveTicket"
					>Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>


<script>
import Loader from "@/components/Loader";
import TicketingObject from '@/models/TicketingObject.js';
import { mapGetters } from 'vuex'

export default {
	name: "addticketdialog",
	components: {
		Loader
	},
	props: {
		addTicketDialog: Boolean,
		concert: TicketingObject,
	},
	data: () => ({
		isSaveTicketsPending: false,
		noDataFound: false,
		currentConcertId: "",
		ticketData: [],
		invalidInput: true,
		form: {
			name: "",
			price: "",
			capacity: "",
			available: "",
			concertid: "",
			comment: "",
			total_sold: 0,
			gross_sales: 0,
			total_sold_yesterday: 0,
			change: 0,
			day1: 0,
			day2: 0,
			day3: 0,
			day4: 0,
			sold: 0,
			platinum:0
		},
		potentialTickets: [],
		isPlatinum:false
	}),
	watch: {
		form: {
			handler: function(v) {
				if (
					this.form.name === "" ||
					this.form.price === "" ||
					this.form.capacity === "" ||
					this.form.available === ""
				) {
					this.invalidInput = true;
				} else {
					this.invalidInput = false;
				}
			},
			deep: true
		},
		concert(){
			this.ticketData = []
			this.findRelevantBudget(this.concert?.budgets);
			
		}
	},
	created() {
		
	},
	methods: {
		async saveTicket() {
			this.isSaveTicketsPending = true;
			for(let ticket of this.potentialTickets)
				await this.concert.addTicket(ticket.name, ticket.price, ticket.capacity, ticket.available, ticket.platinum);

			this.isSaveTicketsPending = false
			this.closeTicketDialog();
			this.potentialTickets = [];
		},

		addTicket() {
			let newObj = Object.assign({}, this.form);
			newObj.concertid = this.concert?.concertid;
			this.potentialTickets.push(newObj);
			console.log(this.potentialTickets)
			this.clearForm();
		},
		removeTicketFromRow(index) {
			this.$delete(this.potentialTickets, index);
		},
		clearForm() {
			for (let key in this.form) {
				this.form[key] = "";
			}
			this.form.platinum = 0
		},
		copyToInput(item) {
			this.form.name = item.section_name;
			this.form.price = item.price;
			this.form.capacity = item.capacity;
			this.form.available = item.available;
			this.form.platinum = item.platinum;

		},
		closeTicketDialog() {
			this.potentialTickets = [];
			this.clearForm();
			this.$emit("closeTicketDialog");
		},

		findRelevantBudget(budgets) {
			this.ticketData = [];
			const budget = this.concert.getCurrentBudget();

			if(budget){
				this.ticketData = budget.budget_tickets;
				this.calcAvailable();
			}
		},

		calcAvailable() {
			console.log(this.ticketData)
			this.ticketData?.map(v => (v.available = v.capacity - v.comps - v.kills - v.other));
		}
	},
	computed:{
		...mapGetters({
			isMobile:'globalstates/isMobile'
		})
	}
};
</script>

<style lang="scss" scoped>
.header {
	font-size: 15px !important;
	text-transform: uppercase;
	color: $accent-color;
	font-weight: 600 !important;
	padding: 16px 24px !important;
}

.v-data-table {
	&::v-deep .v-data-table__wrapper {
		.v-data-table__progress th {
			padding: 0px !important;
		}
	}
}

.v-input.v-input--checkbox{
	margin-top:5px;
}
.v-card__title.header:first-child {
	box-shadow: 0px 2px 8px #00000030;
	position: relative;
	z-index: 1;
}
.v-card__title.header:nth-child(3) {
	box-shadow: 0px -5px 5px 0px #00000011;
	position: relative;
	z-index: 12;
}
.sub-header {
	font-size: 14px !important;
	text-transform: uppercase;
	color: #666;
	font-weight: 600 !important;
}

.v-data-table {
	width: 100%;
	padding-top: 0px !important;
	max-height: 400px;
	overflow:auto;
}

.thead div {
	position: relative;
	width: 100%;
	float: left;
}

.no-tickets-message {
	font-size: 13px;
}

.tr-bg {
	&:hover {
		background: #e5e5e5 !important;
	}
}

.v-data-table {
	border-radius: 0px !important;
}
</style>