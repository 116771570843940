<template>
   <div>
      <v-container class="pa-3 border-bottom container--fluid" >
         <v-col cols="12" md="4">
            <v-text-field outlined v-model="search" hide-details label="Search" dense></v-text-field>
         </v-col>
      </v-container>
      <v-data-table
         :headers="tableHeaders[activeTab]"
         :items="tableData[activeTab]"
         :search="search"
		 @click:row="(item) => goTo(item, activeTab)"
         
      >
         <!-- <template v-slot:item.confirmation="{ item }">
            <v-chip class="statuschip" :color="setChipColor(item.confirmation)">{{ item.confirmation === '1' ?  'confirmed' : 'pending' }}</v-chip>
         </template> -->
         <template v-slot:item.status="{ item }">
            <v-chip class="statuschip" :color="setChipColor(item.status)">{{ item.status ? item.status : 'NO STATUS' }}</v-chip>
         </template>
         <template v-slot:item.download="{ item }">
            <v-btn :href="item.link" class="edit-btn" icon>
               <v-icon>cloud_download</v-icon>
            </v-btn>
         </template>
         <template v-slot:item.sold="{ item }">
            <v-progress-circular rotate="-90" size="35" color="primary" :value="item.sold">
               <span class="progress-text"> {{ item.sold}}%</span>
            </v-progress-circular>
         </template>
      </v-data-table>
   </div>
</template>

<script>
   export default {
      name:'datatable',
      props:{
         activeTab:String,
         tableHeaders:Object,
         tableData:Object
      },
      data:()=>({
         search:'',
         data: {},
      }),
      methods:{
         setChipColor(status){
            if(!status) return
            switch(status.toLowerCase()) {
               case 'confirmed':
                  return '#55b54a';
               case 'hold':
                  return '#4a81b5';
               case 'unavailable':
                  return '#b5804a';
               case 'company gig':
                  return '#b54a99';
               case 'offer':
                  return '#49b4ad';
               case 'routing':
                  return '#7d49b5';
               case 'repetition':
                  return '#aeb54a';
               case 'no status':   // should not be the case unless data is corrupt
                  return '#ccc';
               default:                // decline variations
                  return '#b54a4a';
            }
         },

      },
      created(){
	  },
	  methods:{
		  goTo(item, activeTab){
			  this.$router.push({ path: `/${activeTab}/edit/${item.rowid}` })
		  }
	  }
   }
</script>

<style lang="scss" scoped>

   .statuschip{
      text-transform: uppercase;
      min-width:164px;
      font-size:12px;
      display:flex;
      float:left;
      justify-content: center;
      padding:0px 25px;
      font-weight:600;
      letter-spacing:1px;
      color: #ffffff;
   }
   .v-data-table{
      &::v-deep td{
         padding-right:35px!important;
      }

   }
   .border-bottom{
      border-bottom:1px solid $border-color;
      border-top:1px solid $border-color ;
   }
   .progress-text{
		font-size:12px;
		font-weight:600;
		color:#666
	}

</style>