<template>
    <!-- <div style="border:1px solid red;">

        <select @change="updateValue($event.target.value)" style="max-width:100px">
            <option style="font-size:13px;" v-for="country in countries" :key="country">{{ country.name }} {{ country.dial_code }}</option>
        </select>
    </div> -->
    <div>
        <v-select
            hide-details
            @change="updateValue(localDialCode)"
            class="dial-code-dropdown"
            label="Select country"
            v-model="localDialCode"
            filled
            :items="raw_country_list"
            dense
            outlined
        >
        
            <template v-slot:item="data">
                <country-flag :country="data.item.country_code" size="small" class="mr-0" />
                {{ data.item.name }} ({{ data.item.country_code }})
            </template>
            <template v-slot:selection="data">
                <div style="display:flex;float:left;width:100%;align-items:center">
                    <v-spacer></v-spacer>
                    <country-flag :country="data.item.country_code" :size="$vuetify.breakpoint.lgAndDown ? 'small': 'medium'" class="mr-0" style="position:absolute"/>
                    <div :style="isMobile ? 'font-size:15px;' : 'font-size:0.9vw'">{{ data.item.dial_code }}</div>
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import { mapGetters } from "vuex";

export default {
    name: "nationalcodesdropdowns",
    components: {
        CountryFlag,
    },
    props: {
        value: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        dialCode: String,
    },
    data: () => ({
        localDialCode: "",
    }),
    watch:{
        '$vuetify.breakpoint.lgAndDown':{
            handler:function(v){
                console.log(v)
            },
            deep:true
        }
    },
    created() {
        /**
         * If a dial code already exists.
         */
        if (this.dialCode) {
            /**
             * In order to show the correct dial code in the dropdown we get the dialcode  saved
             * and search through the dialcodes array to find the correct one.
             */
            this.localDialCode = this.raw_country_list.find((code) => code.dial_code == this.dialCode);
        }

    },
    methods: {
        updateValue: function(value) {
            this.localValue = value.dial_code;
            this.localCode = value.code;
            this.$emit("input", value.dial_code);
        },
    },
    computed: {
        ...mapGetters({
            isMobile: "globalstates/isMobile",
            raw_country_list: "metadata/raw_country_list",
        }),
    },
};
</script>

<style lang="scss">
.dial-code-dropdown {
    max-width: 155px;
    border-right: none !important;
    .v-input__control {
        fieldset {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: none;
        }
    }
}
</style>
