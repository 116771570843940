<template>
   <div class="wall-item"  :class="comment ? 'border-fix' : ''">
      <div class="wall-item-top">
         <div class="wall-init-icon-wrapper">
            <div class="wall-init-icon">
               <span>{{ getInitials() }}</span>
            </div>
            <div class="type-of-item-wrapper">
               <div class="type-of-item d-flex align-center justify-center" :class="colorByType()">
                  <v-icon v-if="walltype === 'comment'">mdi-comment-text</v-icon> 
                  <v-icon v-if="walltype === 'checklist_task_done' || walltype === 'checklist_task_not_done' ">done</v-icon>
                  <v-icon v-if="walltype === 'budget_approved'">thumb_up</v-icon>
                  <v-icon v-if="walltype === 'budget_sent_for_approval'">mdi-file-move</v-icon>
                  <v-icon v-if="walltype === 'changed_start_date'">mdi-calendar-refresh-outline</v-icon>
                  <v-icon v-if="walltype === 'changed_venue'">mdi-warehouse</v-icon>
                  <v-icon v-if="walltype.includes('concert_status')">mdi-information-variant</v-icon>
               </div>
            </div>
         </div>
         <div class="wall-info-header">
            <span class="name-action"><b>{{ fullname }}</b> {{ title }}</span>
            <span class="time-ago">{{ date }} </span>
         </div>
         <v-spacer></v-spacer>
      </div>
      <div class="comment" v-if="walltype === 'comment'">
         <span>{{ comment }}</span>
      </div>
   </div>
</template>

<script>
   export default {
      name:'Wallitem',
      props:{
         title:String,
         comment:String,
         fullname:String,
			date:String,
			walltype:String
      },
      created(){
         this.getInitials()
      },
      methods:{
         colorByType(){
				switch(this.walltype){
					case 'comment':
						return 'comment-icon'
						break
					case 'checklist_task_done':
						return 'checklist-task-done-icon'
					case 'checklist_task_not_done':
						return 'checklist-task-not-done-icon'
						break
					case 'budget_approved':
						return 'budget-approved-icon'
						break
					case 'budget_sent_for_approval':
						return 'budget-sent-for-approval-icon'
						break
					case 'changed_start_date':
						return 'changed-start-date-icon'
						break
					case 'changed_venue':
						return 'changed-venue-icon'
						break
               case 'concert_status_hold':
               case 'concert_status_offer':
               case 'concert_status_confirmed':
               case 'concert_status_unavailable':
               case 'concert_status_declined':
               case 'concert_status_routing':
               case 'concert_status_repetition':
						return 'concert-status-icon'
						break
					default:
						return 
				}
         },
         getInitials(){
            if(this.fullname){
               let fullname = this.fullname.split` `
               let first = fullname[0][0]
               let second = fullname[1][0]
               let initials = `${first}${second}`
               return initials
            }
         }
      },
   }
</script>

<style lang="scss" scoped>

.comment-icon{
   background:#ffa500!important;
}
.checklist-task-done-icon{
   background:#8ed136!important;
}
.checklist-task-not-done-icon{
   background:#e75529!important;
}
.budget-approved-icon{
   background:#64dfee!important;
}
.budget-sent-for-approval-icon{
   background:#64dfee!important;
}
.concert-status-icon{
   background:#a930b8!important;
}
.changed-start-date-icon{
   background:#609ae1!important;
}
.changed-venue-icon{
   background:#ee76d4!important;
}

.comment{
   float:left;
   padding:10px 15px;
   font-size:13px;
   background:#f8f8f8;
   border:1px solid $border-color;
   border-radius:10px;
   margin-top:20px;
   position:relative;
}

.comment:after, .comment:before {
	bottom: 100%;
	left: 26px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.comment:before {
	border-bottom-color: #7e7e7e;
	border-width: 8px;
	margin-left: -8px;
}

.comment:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: #f8f8f8;
	border-width: 8px;
	margin-left: -8px;
}


.wall-init-icon-wrapper{
   position:relative;
}

.type-of-item-wrapper{
   position:absolute;
   bottom:-10px;
   right:0px;
   .type-of-item{
      width:100%;
      height:100%;
      background:#64dfee;
      border-radius:60px;
      float:right;
      i{
         color:#fff;
         font-size:16px;
      }
   }
}

.wall-item-container{
   overflow:auto;
   padding:10px;
   position:relative;
}

.wall-item{
   width:100%;
   float:left;
   padding:10px;
   align-items: center;
   padding:4px;
   background:#fff;
   border-radius:10px;
   margin-bottom:30px;
}

.wall-item-top{
   display:flex;
   align-items:center;
}


.wall-init-icon{
   width:100%;
   height:100%;
   background:#11283e;
   border-radius:60px;
   float:left;
   margin-right:20px;
   display:flex;
   text-transform: uppercase;
   span{
      margin:auto;
      font-size:1.2rem;
      color:#fff;
      font-weight:400;
   }
}

.wall-info-header{
   float:left;
   font-size:14px;
   padding:0px 0px 0px 15px;
   .name-action, .time-ago, .category{
      float:left;
      width:100%;
      line-height:1.24;
   }
   .time-ago{
      color:#838383;
      font-size:0.8rem;
   }

}

.wall-init-icon-wrapper{
   width:40px;
   height:40px;
   flex:none;

}
.type-of-item-wrapper{
   width:22px;
   height:22px;
   flex:none;

}


</style>