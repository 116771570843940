<template>
   <v-row>
      <v-col cols="12" md="3">
         <v-text-field class=""
            dense
            required
            v-model="revenue.name"
            label="Type"
            filled
            hide-details
         />
      </v-col>
      <v-col cols="12" md="3">
         <v-text-field class=""
            dense
            required
            v-model="revenue.account_code"
            label="Account code"
            filled
            hide-details
         />
      </v-col>
      <v-col cols="12" md="2">
         <v-text-field
            type="number"
            class="number"
            label="VAT"
            dense
            required
            suffix="%"
            v-model="revenue.vat"
            filled
            hide-details
         />
      </v-col>
      <v-col cols="12" md="3">
         <v-text-field
            type="number"
            class="number"
            label="ESTIMATED REVENUES INC VAT"
            dense
            required
            :suffix="$config.CURRENCY"
            v-model="revenue.value"
            filled
            hide-details
         />
      </v-col>

      <v-col cols="12" md="1" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
               small
               icon class="mt-4"
               color="error"
               @click.stop="openRevenueDialog"
            >
               <v-icon>remove_circle_outline</v-icon>
            </v-btn>
            <comment-button
               class="pt-3"
               :name="revenue.name"
               :data="{ comment: revenue.comment, budget_rowid: revenue.budget_rowid }"
               :route="this.$getSubRoute('budgets','rows')"
               @setComment="(comment) => revenue.comment = comment"
            />
      </v-col>

      <delete-dialog 
         @deleteFromDialog="deleteOtherRevenue"
         @cancelDialog="cancelRevenueDialog"
         :dialog="showRevenueDialog"
         :item="revenue"
         :name="revenue.name"
      />
   </v-row>
</template>

<script>
import DeleteDialog from '@/components/Dialogs/DeleteDialog'
import CommentButton from '@/components/Dialogs/Comment/CommentButton'

export default {

   components: {
      DeleteDialog,
      CommentButton,
   },

   props:{
      revenue: Object,
   },

   data: ()=> ({
      showRevenueDialog: false,
   }),

   async created(){
      if(this.revenue.comment == undefined)
         this.revenue.comment = ''
   },

   watch:{
      'revenue.value': {
         handler: function(val){
            //console.log(val)
            this.$bus.$emit('revenuesChange')
         },
         deep: true,
      },
   },

   methods: {

      openRevenueDialog(){
         this.showRevenueDialog = true
      },

      cancelRevenueDialog(){
         this.showRevenueDialog = false
      },

      deleteOtherRevenue(revenue){
         this.$emit('deleteOtherRevenue', revenue)
         this.cancelRevenueDialog()
      },

      roundToDec(val, dec) {    
         return +(Math.round((val + Number.EPSILON) * Math.pow(10,dec)) / Math.pow(10,dec))
      },
   }


}
</script>

<style >

</style>