<template>
    <v-dialog
        scrollable
        v-model="seasonDialog"
        v-if="seasonDialog"
        persistent
        max-width="1000"
        @keydown.esc="$emit('closeSeasonDialog')"
        style="background:#f8f8f8;"
    >
        <v-card>
            <v-card-title class="header">
                {{ tourSelected.name }} <span>{{ tourSelected.startdate }}</span>
            </v-card-title>
            <div class="card-container">
                <v-col cols="12" md="12" class="tour-info-wrapper pa-2 pb-0">
                    <v-col class="px-5 py-1 elevation-1 tour-info-card">
                        <v-row>
                            <v-col cols="6" md="3" class="pa-1"> <span class="tour-info-header">Name</span> {{ tourSelected.name }} </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header">Target show count</span>
                                {{ tourSelected.estimate_number_gigs }}
                            </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header">Confirmed show count</span> {{ tourSelected.confirmed_show_count }}
                            </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header"> % of target show count</span> {{ tourSelected.percent_target_show_count }}
                            </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header"> Target fee per show </span> {{ tourSelected.estimate_wage_per_gig }}
                            </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header"> Target total fee</span> {{ tourSelected.target_total_fee }}
                            </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header"> Confirmed total fee</span> {{ tourSelected.confirmed_total_fee }}
                            </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header"> % of target total fee</span> {{ tourSelected.percent_target_total_fee }}
                            </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header"> Target commission</span> {{ tourSelected.target_commission }}
                            </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header"> Confirmed total commission</span> {{ tourSelected.confirmed_total_commission }}
                            </v-col>
                            <v-col cols="6" md="3" class="pa-1">
                                <span class="tour-info-header"> Agent</span> {{ tourSelected.our_agent.fullname }}
                            </v-col>
                            <v-col cols="12" md="12" class="pa-1"> <span class="tour-info-header"> Info</span> {{ tourSelected.tour_info }} </v-col>
                        </v-row>
                    </v-col>
                </v-col>
                <v-col cols="12" md="12" class="py-1 px-4">
                    <v-row>
                        <!-- months in the calander offset 0-3 from selected date -->
                        <v-col v-for="offset in 4" :key="offset" cols="12" md="3" class="pa-1">
                            <tour-calendar
                                class="elevation-1"
                                style="border-radius:3px;overflow:hidden;"
                                :dateSelected="dateSelected"
                                :tourSelected="tourSelected"
                                :monthOffset="calendaroffset + offset - 1"
                                @newConcert="(concert) => $emit('newConcert', concert)"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </div>

            <v-card-actions style="border-top:1px solid #e2e2e2">
                <v-spacer></v-spacer>
                <v-btn class="close-bot" small depressed color="primary" @click="$emit('closeSeasonDialog')">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import TourCalendar from "./TourCalendar.vue";

export default {
    name: "seasondialog",
    components: {
        TourCalendar,
    },

    props: {
        seasonDialog: Boolean,
        dateSelected: Object,
        tourSelected: Array,
        tourRangeSelected: Array,
    },

    data: () => ({
        calendaroffset: 0,
        tourInfoData: [],
        validKeys: [
            "name",
            "estimate_number_gigs",
            "confirmed_show_count",
            "percent_target_show_count",
            "estimate_wage_per_gig",
            "target_total_fee",
            "confirmed_total_fee",
            "percent_target_total_fee",
            "target_commission",
            "confirmed_total_commission",
            "our_agent.fullname",
            "tour_info",
        ],
    }),
    created() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.header {
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    align-items: center;
    line-height: 1.2;
    font-size: 15px !important;
    padding: 15px !important;
    color: $primary;
    span {
        font-size: 13px !important;
        color: $font-color-pale;
        font-weight: 600;
        margin-left: 10px;
    }
}

.card-container {
    max-height: 700px;
    overflow: auto;
    background: rgb(210 210 210);
}

.tour-info-wrapper {
    font-size: 13px;
    .tour-info-card {
        background: #fff;
        border-radius: 3px;
        border-left: 3px solid $primary;
    }

    .tour-info-header {
        width: 100%;
        float: left;
        font-weight: 600;
        color: $font-color-pale;
    }
}
</style>
