<template>
   <div>
      <v-row class="row-wrapper">
         <v-col cols="12" md="5" class="d-flex align-center">
            <v-text-field 
               disabled
               dense
               required
               label="Type"
               v-model="name"
               hide-details
               filled
            />
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field 
               style="text-align:right"
               type="number"
               dense
               required
               label="Value"
               :suffix="valueSuffix"
               v-model="value" 
               hide-details
               filled
            />
         </v-col>

         <v-col cols="12" md="3">
            <v-text-field
               readonly
               type="number"
               dense
               required
               :suffix="currency"
               v-model="calculated_cost"
               hide-details
               filled
               label="Cost"
            />
         </v-col>

         <v-col cols="12" md="1" class="d-flex align-center" >
            <v-spacer></v-spacer>
            <v-btn
               v-if="false"
               small
               icon 
               color="error"
               @click.stop="$bus.$emit('deleteBudgetItem', item)"
            >
               <v-icon>remove_circle_outline</v-icon>
            </v-btn>
            <comment-button
               class="ml-1"
               :name="costItem.name"
               :comment="!costItem.comment ? '' : costItem.comment"
               :idObj="{ budget_rowid: costItem.budget_rowid }"
               :route="$getSubRoute('budgets','rows')"
               @commentSaved="emitUpdate"
            />
         </v-col>
      </v-row>
   </div>
</template>

<script>
import CommentButton from '@/components/Dialogs/Comment/CommentButtonPropSafe'

export default {
   name: 'tourgageform',

   components: {
      CommentButton,
   },

   props: {
      costItem: Object,
      ticketCapacity: Number,
   },

   data: ()=>({
      currency: '',
      valueSuffix: '',
      value: 0,
      dataCreated: false,
   }),

   created() {
      this.currency = this.$config.CURRENCY;
      this.name = this.costItem.name;

      switch(this.costItem.budget_row_typeid){
         case '1':
            this.valueSuffix = this.currency;
            break;
         case '2': 
            this.valueSuffix = `${this.currency}/ticket`;
            break;
      }

      // wait until data is set, then set value to trigger an global update of calculated_cost
      setTimeout(()=>{
         this.dataCreated = true;
         this.value = this.costItem.value;
      }, 1)
   },

   computed: {
      calculated_cost(){
         //return !this.costItem.calculated_cost ? '' : this.costItem.calculated_cost;
         switch(this.costItem.budget_row_typeid){
            case '1':
               return this.value;
            case '2':
               return this.value * this.ticketCapacity;
         }
      }
   },
   
   watch: {
      value(val){
         this.emitUpdate( { value: val, calculated_cost: this.calculated_cost } )
      }
   },

   methods: {

      emitUpdate(item){
         if(!this.dataCreated) return;

         item.budget_rowid = this.costItem.budget_rowid;
         item.step = this.costItem.step;

         this.$bus.$emit('tourBudgetUpdatedData', item)
      },
   },

}
</script>

<style lang="scss" scoped>

.row-wrapper{

}

</style>