<template>
   <v-dialog v-model="dialog" persistent width="650">

      <v-card>

         <v-col cols="12" md="12" class="pa-7 confirm-text">
           <h1 class="text-center title"> Confirm that you want to permanently delete {{ name }}</h1>
         </v-col>

         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDialog">Cancel</v-btn>
            <v-btn text color="error" @click="deleteItem">Delete</v-btn>
         </v-card-actions>
      </v-card>

   </v-dialog>
</template>

<script>

export default {
   props: {
      item: Object,
      dialog: Boolean,
      name: String
   },

   methods:{

      deleteItem(){
         console.log('delete item')
			this.$emit('deleteFromDialog', this.item)
      },

      cancelDialog(){
         console.log('cancel dialog')
         this.$emit('cancelDialog')
      }
   }
}
</script>

<style lang="scss" scoped>

.confirm-text{
   border-bottom:1px solid $border-color;
}

</style>