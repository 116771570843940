<template>
	<v-dialog
		v-model="dialog"
		fullscreen hide-overlay 
		transition="dialog-bottom-transition"
	>
		<v-card class="float-left">
			<v-toolbar dark color="primary">
				<v-toolbar-title>Create new {{ formtype == 'people' ? 'person' : formtype.substr(0, formtype.length-1) }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn dark text @click="hideDialog()">
						Close
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<edit-table-item 
				:v-if="dialog" 
				:portableFormView="formtype == '' ? 'people' : formtype"
				:isPortable="true"
				:emitObject="true"
				:fromApiObj="fromApiObj"
				@objectCreated="portableObjectCreated"
			></edit-table-item>
		</v-card>
	</v-dialog>
</template>

<script>
/**
 * Portable version of EditTableItem a.k.a General Form o7
 * This component can be imported anywhere, anytime. It just needs a few props to get going.
 * Ex on how to declare the component:
 * 
	<portable-edit-table-item 
		v-if="showPortableForm"			
		:dialog="showPortableForm"
		:formtype="portableFormType"						// What type of form (endpoint) to use (concerts, artists, ...)
		:fromApiObj="apiObject"  							// Optional param if you have pre-data that should already filled in the form
		@closeForm="() => { showPortableForm = false; }"	
		@portableObjectCreated="handleNewPortableObject"	// This event is what is triggered when creation is done. The event sends out the new obj id, and also the obj itself.
	/>
 *	
 */
export default {
	props:{
		dialog: Boolean,
		formtype: String,
		fromApiObj: Object,
	},
	beforeCreate() {
		// Since this is a circular import, we have to declare it in beforeCreate
		this.$options.components.EditTableItem = require('../../EditTableItem/index.vue').default
	},
	methods:{
		hideDialog(){
			this.$emit('closeForm');
		},
		portableObjectCreated(newid, apiObj){
			console.log('PortableObjectCreated', newid);
			console.log(apiObj);
			this.$emit('portableObjectCreated', newid, apiObj);
			this.hideDialog();
		}
	}
	
};
</script>

<style></style>
