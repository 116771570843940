import ApiObject from './ApiObject';
import store from './../store/modules/globalstates'
import ObjectFactory from './ObjectFactory';

export default class ArtistObject extends ApiObject {
    constructor() {
        super('artists');
        this.pending_concerts = 0;
        this.salesGraphData = [];
    }

    async initConcerts(){
        let concertObjects = [];
        const today = new Date();
        if(this.concerts){
            for(let concert of this.concerts){
                concertObjects.push(await this.objFactory.BuildConcert( { concert: concert } ));
                if(new Date(concert.date) >= today)
                    this.pending_concerts++;
            }
        }
        this.concerts = concertObjects;
    }

    getArtistSalesDataForGraph(minDate, maxDate){
        this.salesGraphData = [];
        for(let concert of this.concerts){
            concert.getConcertSalesGraphData(minDate, maxDate, this.salesGraphData);
        }
        return this.salesGraphData;
    }

}