<template>
   <div class="pa-0">
      <v-row class="">
         
         <!-- <v-btn
            class="btn-text"
            block
            icon-left
            text
            @click="show = !show"
         > -->
            <v-col cols="3" md="3" class="py-2 summary-text">
               <!-- <v-icon >{{ show ? 'expand_less' : 'expand_more' }}</v-icon> -->
               {{ title }}
            </v-col>

            <v-col 
               cols="3" md="3" 
               class="py-2 summary-value"
               v-for="value of values" :key="value.id" 
            >
               <v-spacer/>
               {{ formattedValue(value) }}
            </v-col>
         <!-- </v-btn> -->
      </v-row>

      <!-- <div v-show="show"  class="items summary-text">
         <div v-for="item of items" :key="item.id">

            <v-row class="ma-0"  >

               <v-col cols="12" md="3" class="" >
                  <span>{{ item.name }}</span>
               </v-col>

               <v-col cols="12" md="3">
                  <span class="float-right">{{ formattedValue(item.actualValue) }}</span>
               </v-col> 
               <v-col cols="12" md="3">
                  <span class="float-right">{{ formattedValue(item.budgetValue) }}</span>
               </v-col> 
               <v-col cols="12" md="3">
                  <span class="float-right">{{ formattedValue(item.diffValue) }}</span>
               </v-col> 
            </v-row>
            <v-divider v-if="i < items.length-1 " class="mx-2"/> 

         </div>
      </div> -->
   </div>
</template>

<script>
export default {
   name: 'summaryitem',

   props: {
      title: String,
      values: Array,
      currency: String,
      items: Array,
   },

   data: ()=>({
      show: false,
   }),

   created(){
      //console.log('item created')
   },

   methods: {
      formattedValue(value){
         let val = this.$thousandSeperator(Math.round(value))
         let presufVal = `${this.currency.match(/[$€£]/) ? this.currency : ''} ${val} ${this.currency.match(/[^$€£]/) ? this.currency : ''}`
         return presufVal
      }
   }
}
</script>

<style lang="scss">

.btn-text{
   font-size: 0.8remdropdown-items!important;
   font-weight: 350;
   text-transform: none;
   letter-spacing: 0px;
   text-align: left;
}

.summary-value{
   font-size: 14px;
	font-weight:300;
	color:#fff;
   text-align: right;
   display: flex;
   align-items: center;
   input[type="number"]{
      text-align:right
   }
   .number-text{
      input{
         text-align:right
      }
   }
}

</style>