<template>
    <div>
        <v-col class="pa-0">
            <h3 class="wizard-container-title">{{ header }} costs</h3>
        </v-col>
        <div class="wizard-form-text" v-for="item of items" :key="item.budget_rowid">
            <tour-cost-form :costItem="item" />
        </div>
        <v-col class="primary--text d-flex align-center pa-0 pt-3">
            <v-btn
                depressed
                @click.stop="$bus.$emit('addBudgetItem', { step: 2, header: header, typeid: 3 })"
                color="primary"
            >
                <v-icon left>add</v-icon>
                Add {{ header.toLowerCase() }} cost
            </v-btn>
        </v-col>
    </div>
</template>

<script>
import TourCostForm from "../Shared/TourCostForm";

export default {
    name: "tourtwocostcomp",
    components: {
        TourCostForm,
    },
    props: {
        header: String,
        items: Array,
        colNames: Array,
    },
};
</script>

<style>
</style>