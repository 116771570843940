<template>
    <div id="comparableprojects">
        <v-col cols="12" md="12" class="elevation-1 pa-0">
            <ViewHeader :header="header" />
            <simple-browse-table :idpath="idpath" :view="this.$options.name" :itemKey="itemKey" />
        </v-col>
    </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import SimpleBrowseTable from "@/components/Tables/SimpleBrowseTable"
import { mapGetters } from "vuex";;

export default {
    name: "comparableprojects",
    components: {
        ViewHeader,
        SimpleBrowseTable,
    },
    data: () => ({
        idpath: "comparable_concertid",
        itemKey: "title",
    }),
    computed: {
        ...mapGetters({
            view: "globalstates/view",
        }),
        header() {
			console.log(this.view)
            return `Browse Comparable projects`;
        },
    },
};
</script>

<style scoped></style>
