<template>
    <div class="page-container">
        <Loader :loaderStyle="'linear'" v-if="!loadingDone" />
        <div id="landingpage" v-if="loadingDone">
            <v-container class="container--fluid float-left">
                <GreetingCard />
            </v-container>
            <v-container class="container--fluid pb-0 float-left">
                <ProgressionCards :dataObject="progressionCard.dataObject" />
            </v-container>
            <v-container class="container--fluid float-left pb-0">
                <v-row>
                    <v-col cols="12" md="4" class="pb-0">
                        <TodoList />
                    </v-col>
                    <v-col cols="12" md="8" class="pb-0">
                        <Calendar :items="items" />
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="container--fluid float-left">
                <v-row>
                    <v-col cols="12" md="6">
                        <ReleaseDataTable v-if="personalItems.length !== 0" :items="personalItems" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <EventsDataTable v-if="personalItems.length !== 0" :items="personalItems" />
                    </v-col>
                </v-row>
            </v-container>
            <v-col cols="12" md="12">
                <div id="favorites" class="elevation-1">
                    <div class="card-title">Your Favorites</div>
                    <v-container class="container--fluid float-left pa-0">
                        <GenericTabBrowser :key="favBrowserChange" :tabData="favBrowser" :type="'favorite'" />
                    </v-container>
                </div>
            </v-col>
        </div>
    </div>
</template>

<script>
/* MODELS */
import TabBrowser from "@/models/TabBrowser.js";
import { mapActions, mapGetters } from "vuex";
import TodoList from "@/components/TodoList";
import ProgressionCards from "@/components/Cards/ProgressionCards";
import Loader from "@/components/Loader";
import ReleaseDataTable from "@/components/Tables/ReleaseDataTable";
import EventsDataTable from "@/components/Tables/EventsDataTable";
import Calendar from "@/components/Calendar";
import GenericTabBrowser from "@/components/Tables/GenericTabBrowser/";
import GreetingCard from "@/components/Cards/GreetingCard";
import ObjectFactory from "@/models/ObjectFactory.js";
export default {
    name: "landingpage",
    components: {
        TodoList,
        Loader,
        ProgressionCards,
        ReleaseDataTable,
        EventsDataTable,
        GenericTabBrowser,
        Calendar,
        GreetingCard,
    },
    data: () => ({
        loadingDone: false,
        username: "",
        items: [],
        personalItems: [],
        view: "landingpage",
        oneYearBack: "",
        progressionCard: {
            dataObject: {
                events_on_sale: {
                    title: "Events on sale",
                    number: 0,
                    subtext: "events",
                    percentage: 100,
                    progresstext: "",
                },
                tickets_sold_yesterday: {
                    title: "Tickets sold yesterday",
                    number: 0,
                    subtext: "Tickets",
                    percentage: 76,
                    progresstext: "",
                },
                enjoyed_event_yesterday: {
                    title: "Enjoyed a LiveNation event yesterday",
                    number: 0,
                    subtext: "people",
                    percentage: 100,
                    progresstext: "",
                },
            },
        },
        tabItems: {
            concerts: {},
            tours: {},
            artists: {},
            people: {},
            organizations: {},
            venues: {},
        },
        favItems: {},
        favBrowser: {},
        favBrowserChange: false,
        uniqueUserIds: [],
    }),
    async created() {
        // console.log(await this.$auth.oktaAuth.tokenManager.get('token'))
        this.$logger("tokenManager", await this.$auth.oktaAuth.tokenManager.get("accessToken"));
        this.$logger("tokenManager", await this.$auth.oktaAuth.tokenManager.get("idToken"));
        this.$logger("tokenManager", this.$auth.oktaAuth);
        this.$bus.$emit("setHistoryView", {
            display: "Home",
            route: this.$route.path,
            icon: "home",
        });
        this.$bus.$on("deleteLandingPageFavorite", this.deleteLandingPageFavorite);
        await this.getConcertData();
        await this.getFavorites();
        await this.getDateOneYearBack();
        this.getProgressionCardData();
        this.favBrowser = new TabBrowser({ title: "Your favorites" });
        this.createTables();
        this.loadingDone = true;
    },
    methods: {
        async getConcertData() {
            try {
                const response = await this.$GetService(
                    `${
                        this.$getRoute("concerts").main_route
                    }?inc=assoc&exclude_fields=budgets&fromdate=1970-01-01&objfetch=obj`
                );
                if (response.status === 200) {
                    for (let concert of response.data) {
                        const newConcert = await this.$ObjectFactory.BuildConcert({ concert: concert });
                        this.items.push(newConcert);
                        if (newConcert.getValuesInObjects("userid").includes(this.userInfo.userid))
                            this.personalItems.push(newConcert);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getFavorites() {
            console.log("getFavorites");
            try {
                const response = await this.$GetService(`${this.$getRoute("favorites").main_route}?inc=assoc`);
                if (response.status === 200 || response.status == 204) {
                    for (let key in this.tabItems) {
                        this.flattenDataForTabItems(response.data, key === "people" ? "persons" : key);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        createTables() {
            for (let key in this.tabItems) {
                this.favBrowser.addTable({
                    tabName: key,
                    rows: this.tabItems[key].rows,
                    inclHeaders: this.tabItems[key].includedHeaders,
                });
            }
            console.log(this.favBrowser);
        },
        deleteLandingPageFavorite(payload, i) {
            const keys = Object.keys(this.tabItems);
            this.favBrowser.deleteTableRow(payload.replace(/id/, "s"), i);
            console.log(this.favBrowser);
            this.favBrowserChange = !this.favBrowserChange;
        },
        createTabItems(data) {
            return data.reduce((acc, item) => {
                acc[item.type.substring(0, item.type.length - 2)] = [];
                return acc;
            }, {});
        },
        flattenDataForTabItems(data, name) {
            let temp = [];
            for (let item of data) {
                let tabsData = {};
                for (let key in item) {
                    if (!Array.isArray(item[key]) && item[key]) {
                        tabsData[key] = item[key];
                    }
                }
                if (item.type === name.substring(0, name.length - 1) + "id") {
                    let key = name.substring(0, name.length - 1) + "id";
                    let inneritem = item[key];
                    for (let innerKey in inneritem) {
                        if (!Array.isArray(inneritem[innerKey])) {
                            tabsData[innerKey] = inneritem[innerKey];
                        } else {
                            tabsData[`${key.replace(/id/, "")}_${innerKey}`] = inner[innerKey];
                        }
                    }
                    temp.push(tabsData);
                }
            }
            if (name === "persons") {
                name = "people";
            }
            if (!this.tabItems[name]["rows"]) {
                this.tabItems[name]["rows"] = [];
                this.tabItems[name]["includedHeaders"] = [
                    "title",
                    "town",
                    "status",
                    "venue_name",
                    "venue_total_capacity",
                    "artist_name",
                    "name",
                    "email",
                    "category",
                    "position",
                    "tel_cell",
                    "startdate",
                    "enddate",
                    "country",
                    "artist_homepage",
                    "total_capacity",
                    "postal_adress",
                    "postal_town",
                    "telephone",
                ];
            }

            this.tabItems[name].rows.push(...temp);
            console.log(this.tabItems);
        },
        getDateOneYearBack() {
            let date = new Date();
            date.setFullYear(date.getFullYear(), date.getMonth() - 12);
            this.oneYearBack = date;
        },
        getProgressionCardData() {
            const P_CARD = this.progressionCard.dataObject;

            // EVENTS ON SALE
            P_CARD.events_on_sale.number = this.getEventsOnSale();
            P_CARD.events_on_sale.percentage = this.getEventsOnSaleAverage(P_CARD.events_on_sale.number);
            P_CARD.events_on_sale.progresstext = `${P_CARD.events_on_sale.percentage}% of average`;

            // TICKETS SOLD YESTERDAY
            P_CARD.tickets_sold_yesterday.number = this.getTicketsSoldYesterday();
            P_CARD.tickets_sold_yesterday.percentage = this.getTicketsSoldAverage(P_CARD.tickets_sold_yesterday.number);
            P_CARD.tickets_sold_yesterday.progresstext = `${P_CARD.tickets_sold_yesterday.percentage}% of average`;

            // ENJOYED A LIVENATION EVENT YESTERDAY
            P_CARD.enjoyed_event_yesterday.number = this.getTotalTicketSoldToYesterday();
            P_CARD.enjoyed_event_yesterday.percentage = Math.round(
                (this.getTotalTicketSoldToYesterday() / this.getTotalTicketSoldAverage()) * 100
            );
            P_CARD.enjoyed_event_yesterday.progresstext = `${P_CARD.enjoyed_event_yesterday.percentage}% of average`;
        },
        getEventsOnSale() {
            return this.items.filter(
                (concert) => new Date(concert.on_sale) < new Date() && new Date() < new Date(concert.date)
            ).length;
        },
        getEventsOnSaleAverage(events) {
            const on_sale_last_year = this.items.reduce((acc, concert) => {
                if (new Date(concert.on_sale) < this.oneYearBack && new Date(concert.date) > this.oneYearBack)
                    acc.push(concert);
                return acc;
            }, []).length;

            // PREVENT DIVIDING BY 0, WHICH RETURNS INIFINITY IN PROGRESSTEXT IN PROGRESSIONCARDS
            if (!on_sale_last_year) return 0;

            return Math.round((events / on_sale_last_year) * 100);
        },

        getTicketsSoldYesterday() {
            const yesterday = new Date(Date.now() - 86400000).toISOString().substring(0, 10);
            return this.items
                .reduce(
                    (acc, concert) => {
                        if (concert.tickets) {
                            concert.tickets.forEach((ticket) => {
                                if (ticket.ticket_sales && ticket.price > 0) {
                                    ticket.ticket_sales.forEach((sale) => {
                                        if (sale.date === yesterday) {
                                            acc.push(+sale.amount);
                                        }
                                    });
                                }
                            });
                        }
                        return acc;
                    },
                    [0]
                )
                .reduce((a, b) => a + b);
        },
        getTicketsSoldAverage(tickets) {
            const average_tickets_sold_per_day =
                this.items
                    .reduce(
                        (acc, concert) => {
                            if (new Date(concert.date) > this.oneYearBack) {
                                if (concert.tickets) {
                                    concert.tickets.forEach((ticket) => {
                                        if (ticket.ticket_sales) {
                                            ticket.ticket_sales.forEach((sale) => {
                                                acc.push(+sale.amount);
                                            });
                                        }
                                    });
                                }
                            }
                            return acc;
                        },
                        [1]
                    )
                    .reduce((a, b) => a + b) / 365;

            return Math.round((tickets / average_tickets_sold_per_day) * 100);
        },
        getTotalTicketSoldToYesterday() {
            const yesterday = new Date(Date.now() - 86400000).toISOString().substring(0, 10);
            return this.items
                .reduce(
                    (acc, concert) => {
                        if (concert.date === yesterday) {
                            if (concert.tickets) {
                                concert.tickets.forEach((ticket) => {
                                    if (ticket.ticket_sales) {
                                        ticket.ticket_sales.forEach((sale) => {
                                            acc.push(+sale.amount);
                                        });
                                    }
                                });
                            }
                        }
                        return acc;
                    },
                    [0]
                )
                .reduce((a, b) => a + b);
        },
        getTotalTicketSoldAverage() {
            let date = new Date(Date.now() - 86400000);
            date.setFullYear(date.getFullYear(), date.getMonth() - 12);
            const oneYearBack = date.toISOString().substring(0, 10);

            return this.items
                .reduce(
                    (acc, concert) => {
                        if (concert.date === oneYearBack) {
                            if (concert.tickets) {
                                concert.tickets.forEach((ticket) => {
                                    if (ticket.ticket_sales) {
                                        ticket.ticket_sales.forEach((sale) => {
                                            acc.push(+sale.amount);
                                        });
                                    }
                                });
                            }
                        }
                        return acc;
                    },
                    [1]
                )
                .reduce((a, b) => a + b);
            },
    },
    computed: {
        ...mapGetters({
            userInfo: "user/userInfo",
        }),
    },
};
</script>
<style scoped lang="scss">
#favorites {
    background: #fff;
    float: left;
    width: 100%;
    border-radius: 3px;
    * {
        box-shadow: none !important;
    }
}
</style>
