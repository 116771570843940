<template>
    <div>
        <v-data-table :headers="formattedHeaders" :items="items" :items-per-page="10" class="elevation-1" @click:row="(item) => goTo(item)">
            <template v-slot:item.actions="{ item }">
                <Favorite :item="item" @addFavorite="addFavorite(item)" :favorites="items" :isFavoriteTable="true" />
                <router-link
                    style="text-decoration: none"
                    :to="{
                        path: `${item.type.replace(/id/, 's') === 'persons' ? 'people' : item.type.replace(/id/, 's')}/edit/${item[item.type]}`,
                    }"
                >
                </router-link>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip class="statuschip" :color="setChipColor(item.status)">{{ item.status ? item.status : "NO STATUS" }}</v-chip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Favorite from "@/components/Tables/BrowseTable/Favorite.vue";
export default {
    name: "favoritedatatable",
    components: {
        Favorite,
    },
    props: {
        items: Object,
        headers: Array,
        reactive: String,
    },
    data: () => ({
        formattedHeaders: [],
    }),
    watch: {
        items: {
            handler: function(v) {
                console.log(v);
            },
            deep: true,
        },
    },
    created() {
        console.log(this.items);
        this.formatHeaders();
    },
    methods: {
        goTo(item) {
            const viewPath = item.pk_field != "personid" ? item.pk_field.replace(/id/g, "s") : "people";
            this.$router.push({
                path: `${viewPath}/edit/${item[item.pk_field]}`,
            });
        },
        setChipColor(status) {
            if (!status) return;
            switch (status.toLowerCase()) {
                case "confirmed":
                    return "#55b54a";
                    break;
                case "hold":
                    return "#4a81b5";
                case "unavailable":
                    return "#b5804a";
                case "company gig":
                    return "#b54a99";
                case "offer":
                    return "#49b4ad";
                case "routing":
                    return "#7d49b5";
                case "repetition":
                    return "#aeb54a";
                case "no status": // should not be the case unless data is corrupt
                    return "#ccc";
                default:
                    // decline variations
                    return "#b54a4a";
            }
        },
        async addFavorite(payload) {
            this.$emit("change");
            let favoritetype = payload.type;
            this.items.forEach(async (fav, i) => {
                if (fav.itemid === payload[favoritetype]) {
                    let favoriteid = fav.favoriteid;
                    try {
                        let response = await this.$DeleteService(this.$getRoute("favorites").main_route, { favoriteid: favoriteid });
                        console.log(response);
                        if (response.status === 200) {
                            this.$bus.$emit("deleteLandingPageFavorite", this.items[i].type, i);
                            this.$bus.$emit("unSetLoading");
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            });
        },
        formatHeaders() {
            this.formattedHeaders.push({
                text: "Actions",
                value: "actions",
            });
            for (let header of this.headers) {
                this.formattedHeaders.push({
                    text: this.$formatHeader(header),
                    value: header.toLowerCase(),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.statuschip {
    text-transform: uppercase;
    min-width: 164px;
    font-size: 12px;
    display: flex;
    float: left;
    justify-content: center;
    padding: 0px 25px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #ffffff;
}
</style>
